import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Tab = () => {
  const [tabList, setTabList] = useState([
    { path: "/dashboard/devices", name: "Devices" },
    { path: "/dashboard/energy", name: "Energy" },
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Fragment></Fragment>
    // 특정 아이디만 해당 탭을 보여주게 해야함
    // <div className="tab-list" role="tablist">
    //   {tabList?.map((x, i) => (
    //     <div
    //       key={i}
    //       className={`tab-item ${x.path === location.pathname && "active"}`}
    //     >
    //       <a
    //         href={void 0}
    //         id={`select-${x.name.toLowerCase()}`}
    //         role="tab"
    //         aria-selected={x.path === location.pathname}
    //         onClick={() => navigate(x.path)}
    //         style={{
    //           width: "100%",
    //           height: "100%",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         {x.name}
    //       </a>
    //     </div>
    //   ))}
    // </div>
  );
};

export default Tab;
