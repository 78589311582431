import React, { Fragment, useEffect, useState, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

import { AppContext } from "@component/AppProvider";
import useCss from "@hook/useCss";
import Modal from "@component/UI/Modal";
import { SelectBox } from "@component/UI/Form";
import Dialog from "@component/UI/Dialog";
import _API from "@util/api";
import TargetModelnGroup from "@component/Device/ModelnGroup";
import Loading from "@component/UI/Loading";
import _U from "@util/utilities";

export default (props) => {
    const { t } = useTranslation();
    const { saveTargetModel, rolePermission, auth, workspace } = useContext(AppContext);

    const [registerModal, setRegisterModal] = useState(false);
    const [groupModal, setGroupModal] = useState(null);
    const [resultModal, setResultModal] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [paging, setPaging] = useState(null);

    const [expand, setExpand] = useState(false);

    const onDelete = (item) => {
        Dialog({ title: t("button.delete"), text: t("device.msg.deletetheitem"), button: [t("button.cancel"), t("button.delete")] }).then((id) => {
            if (id) {
                _API.delete({ path: `/device/devices/auto-cloning/${item.id}`, data: {} })
                    .then((res) => {
                        onLoadData(1);
                    })
                    .catch((e) => {
                        console.log("api error /device/devices/auto-cloning", e?.response);
                    });
            }
        });
    };

    const onLoadData = (page, append) => {
        _API.get({
            path: "/device/devices/auto-cloning",
            data: { page, per_page: 10, last_id_key: "id", last_id_value: page == 1 ? null : dataList?.length > 0 ? dataList[dataList.length - 1].id : null },
        })
            .then((res) => {
                console.log("api /device/devices/auto-cloning", res);
                // setDataList(res?.data?.result);
                append && setDataList([...dataList, ...res?.data?.result?.data]);
                !append && setDataList(res?.data?.result?.data);
                setPaging(res?.data?.result?.paging);
            })
            .catch((e) => {
                console.log("api error /device/devices/auto-cloning", e?.response);
            });
    };

    const nextPage = () => {
        onLoadData(paging?.next_page || 1, true);
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    useEffect(() => {
        onLoadData(1);
    }, []);

    return (
        <Fragment>
            <Modal title={t("dv.auto.cloning")} id="popup-auto-cloning" className="popup-alert-message popup-auto-cloning" onClose={props.onClose} noFooter close>
                <div className="tablist-content is-tab" role="tabpanel">
                    <div className="tab-panel fixed-panel" role="tabpanel">
                        <div className="data-state">
                            <div className="col">
                                <button type="button" className="btn btn-tertiary" onClick={() => setRegisterModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                    {t("main.btnRegister.title")}
                                </button>
                            </div>
                            <div className="col">
                                <div className="total-count">
                                    {t("common.total")} : <strong>{paging?.total_count || 0}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="field field-type-table">
                            <div className="field-form">
                                <div className="field-box">
                                    <div className="table">
                                        <div className="table-wrapper AutoCloning-table">
                                            <div className="table-box">
                                                <InfiniteScroll
                                                    style={{ overflow: "hidden" }}
                                                    dataLength={dataList?.length}
                                                    next={nextPage}
                                                    hasMore={paging && paging.has_next && windowWidth > 991}
                                                    loader={""}
                                                    scrollableTarget="scrollableTbodyFwUpdate"
                                                >
                                                    <table>
                                                        <caption>{t("dv.auto.cloning")}</caption>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("table.head.filename")}</div>
                                                                </th>
                                                                <th scope="col" className="width-12-percent">
                                                                    <div className="text-block">{t("table.head.group")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("player.label.modelName")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("admin.label.registerationdate")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("result.main.title")}</div>
                                                                </th>
                                                                <th scope="col" className="width-8-percent">
                                                                    <div className="text-block">{t("button.delete")}</div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="scrollableTbodyFwUpdate">
                                                            {dataList?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td id={`autocloning-td1-${index}`}>{item?.file_name}</td>
                                                                    <td className="width-12-percent" id={`autocloning-td2-${index}`}>
                                                                        {/* <a href="#!" className="link-target" onClick={() => setGroupModal({ open: true, data: item?.group })}>
                                                                            {item?.group?.length || 0}
                                                                        </a> */}
                                                                        {item?.group?.length || 0}
                                                                    </td>
                                                                    <td id={`autocloning-td3-${index}`}>{item?.model}</td>
                                                                    <td id={`autocloning-td4-${index}`}>{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : ""}</td>
                                                                    <td>
                                                                        <div className="result-box">
                                                                            <button
                                                                                type="button"
                                                                                id=""
                                                                                className="btn btn-data-report"
                                                                                onClick={() => setResultModal({ open: true, data: item })}
                                                                                aria-describedby={`autocloning-td1-${index} autocloning-td2-${index} autocloning-td3-${index} autocloning-td4-${index}`}
                                                                            >
                                                                                {t("common.label.report")}
                                                                            </button>
                                                                            {/* <div className="result-value">{t("common.label.success")} : n</div>
                                                                            <div className="result-value">{t("common.total")} : n</div> */}
                                                                        </div>
                                                                    </td>
                                                                    <td className="center width-8-percent">
                                                                        <button
                                                                            type="button"
                                                                            id=""
                                                                            className="btn btn-data-delete"
                                                                            onClick={() => onDelete(item)}
                                                                            aria-describedby={`autocloning-td1-${index} autocloning-td2-${index} autocloning-td3-${index} autocloning-td4-${index}`}
                                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                        >
                                                                            {t("button.delete")}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            {(!dataList || dataList.length === 0) && (
                                                                <tr>
                                                                    <td colSpan={6} className="align-center">
                                                                        {t("devicemanagement.search.noresult")}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </div>

                                        <div className="convert-table-to-list">
                                            <div className="table-header">
                                                <div className="row">
                                                    <div className="thead">{t("table.head.filename")}</div>
                                                </div>
                                            </div>
                                            <InfiniteScroll
                                                style={{ overflow: "hidden" }}
                                                dataLength={dataList?.length}
                                                next={nextPage}
                                                hasMore={paging && paging.has_next && windowWidth <= 991}
                                                loader={""}
                                            >
                                                <ul className="table-body">
                                                    {dataList?.map((item, index) => (
                                                        <li className="table-row" key={`convert-${index}`}>
                                                            <div className="summary-data">
                                                                <div className="row">
                                                                    <div className="table-cell alias">
                                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                                            {t("common.label.expand")}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="all-data-box">
                                                                <ul className="all-data">
                                                                    <li>
                                                                        <span className="field-label">{t("table.head.filename")}</span>
                                                                        <span className="field-content">{item?.file_name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("table.head.group")}</span>
                                                                        <span className="field-content">
                                                                            {/* <a href="#!" className="link-target" onClick={() => setGroupModal({ open: true, data: item?.group })}>
                                                                                {item?.group?.length || 0}
                                                                            </a> */}
                                                                            {item?.group?.length || 0}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("player.label.modelName")}</span>
                                                                        <span className="field-content">{item?.model}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("admin.label.registerationdate")}</span>
                                                                        <span className="field-content">{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : ""}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("result.main.title")}</span>
                                                                        <span className="field-content">
                                                                            <div className="result-box">
                                                                                <button type="button" id="" className="btn btn-data-report" onClick={() => setResultModal({ open: true, data: item })}>
                                                                                    {t("result.main.title")}
                                                                                </button>
                                                                                {/* <div className="result-value">{t("common.label.success")} : n</div>
                                                                                <div className="result-value">{t("common.total")} : n</div> */}
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("button.delete")}</span>
                                                                        <span className="field-content">
                                                                            <button
                                                                                type="button"
                                                                                id=""
                                                                                className="btn btn-data-delete"
                                                                                onClick={() => onDelete(item)}
                                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                            >
                                                                                {t("button.delete")}
                                                                            </button>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    ))}
                                                    {(!dataList || dataList.length === 0) && (
                                                        <li className="table-row">
                                                            <div className="summary-data">
                                                                <div className="row">{t("devicemanagement.search.noresult")}</div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {registerModal && (
                <RegisterModal
                    onClose={() => {
                        onLoadData(1);
                        setRegisterModal(false);
                        saveTargetModel({ open: false });
                    }}
                />
            )}
            {resultModal?.open && <ResultModal data={resultModal?.data} onClose={() => setResultModal({ open: false })} />}
            {groupModal?.open && <GroupModal data={groupModal?.data || []} onClose={() => setGroupModal({ open: false })} />}
        </Fragment>
    );
};

const RegisterModal = (props) => {
    const { t } = useTranslation();
    const { saveInnerLoading } = useContext(AppContext);
    const [is_gscs, setIs_gscs] = useState(true);
    const [targetInfo, setTargetInfo] = useState({ model_name: "", groupList: [] });
    const [fileInfo, setFileInfo] = useState(null);

    const [fileList, setFileList] = useState([
        { value: 1, label: "file name 1" },
        { value: 2, label: "file name 2" },
        { value: 3, label: "file name 3" },
    ]);
    const [file, setFile] = useState(null);

    const onClose = () => {
        Dialog({ title: t("button.cancel"), text: t("subtext.message.leavepage"), button: [t("common.button.stay"), t("common.button.leave")] }).then((id) => {
            if (id) {
                props.onClose();
            }
        });
    };

    const onClickDone = () => {
        if (fileInfo && targetInfo?.model_name && targetInfo?.groupList?.length > 0) {
            const params = {
                file_name: fileInfo.fileName,
                uuid: fileInfo.uuid,
                groups: targetInfo.groupList.map((m) => m.group_id),
                is_gscs,
                model_name: targetInfo.model_name,
                enable: true,
            };
            _API.post({ path: `/device/devices/auto-cloning`, data: { ...params } })
                .then((res) => {
                    console.log("api /device/devices/auto-cloning", res);
                    props.onClose();
                })
                .catch((e) => {
                    console.log("api error /device/devices/auto-cloning", e?.response);
                });
        } else {
            let failCnt = 0;
            if (!fileInfo) failCnt++;
            if (!targetInfo?.model_name) failCnt++;
            if (!targetInfo?.groupList || targetInfo?.groupList?.length === 0) failCnt++;
            let message = "";
            if (failCnt < 2) {
                message = t("popup.inform.sigleItem", { n: !fileInfo ? t("filebox.label.file") : t("common.target") });
            } else {
                message = t("popup.inform.multiItem");
            }
            Dialog({
                title: t("popup.title.inform"),
                text: message,
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        }
    };

    const fileSelected = (e) => {
        if (e?.target?.files?.length > 0) {
            const checkFile = e?.target?.files[0];
            const acceptFileFormat = ["TLL"];
            const fileName = checkFile?.name || "";
            const ext = fileName?.split(".")?.pop()?.toUpperCase();
            if (!acceptFileFormat?.includes(ext)) {
                //
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("subtext.error.autoCloning.notSupportedFileFormat"),
                    button: t("common.ok"),
                }).then((id) => {
                    if (id) {
                    }
                });
            } else {
                const check = _U.checkFileName(e?.target?.files[0]?.name);
                if (check === -2) {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.over100.filename"),
                        button: t("common.ok"),
                    }).then((id) => {});
                } else if (check === -1) {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.NoSpecialCharacters"),
                        button: t("common.ok"),
                    }).then((id) => {});
                } else {
                    setFile(e?.target?.files[0]);
                }
            }
        }
    };

    const upload = () => {
        if (file) {
            saveInnerLoading(true);
            let info = { upload_url: "", uuid: "" };
            const fileName = file.name;
            _API.get({ path: `/device/devices/auto-cloning/upload-url/${fileName}`, data: {} })
                .then((res) => {
                    console.log("api /device/devices/auto-cloning", res);
                    info.upload_url = res?.data?.result?.upload_url;
                    info.uuid = res?.data?.result?.uuid;
                    _API.uploadS3File({ url: info.upload_url, file })
                        .then((rs) => {
                            setFileInfo({ fileName, uuid: info.uuid, url: info.upload_url });
                            saveInnerLoading(false);
                        })
                        .catch((e) => {
                            console.log("file upload fail", e?.response);
                            saveInnerLoading(false);
                        });
                })
                .catch((e) => {
                    console.log("api error /device/devices/auto-cloning", e?.response);
                    saveInnerLoading(false);
                });
        }
    };

    useEffect(() => {
        if (file) {
            upload();
        }
    }, [file]);

    return (
        <Modal title={t("main.btnRegister.title")} id="popup-register" className="popup-register" onClose={onClose} buttonOkTitle={t("main.btnRegister.title")} onClick={onClickDone}>
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <TargetModelnGroup targetInfo={{ ...targetInfo, setTarget: true, headLabel: "modelNgroup", model: true, group: true }} onChange={setTargetInfo} />

            {is_gscs && (
                <div className="field field-type-file required">
                    <div className="field-header">
                        <label className="field-title">{t("table.head.filename")}</label>
                    </div>
                    <div className="field-form">
                        <div className="field-box">
                            <input type="text" placeholder="File Name" value={fileInfo?.fileName || ""} readonly />
                            <input type="file" id="ip-file-upload" accept=".tll" onChange={fileSelected} />
                            <label htmlFor="ip-file-upload" className="btn btn-tertiary">
                                {t("popup.title.fileupload")}
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

const ResultModal = (props) => {
    const { t } = useTranslation();
    const { data } = props;
    const { auth, workspace } = useContext(AppContext);
    const [dataList, setDataList] = useState([]);

    const onLoadData = (id) => {
        _API.get({ path: `/device/devices/auto-cloning/${id}/result`, data: {} })
            .then((res) => {
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    setDataList(res?.data?.result);
                }
            })
            .catch((e) => {
                console.log("api error /device/devices/auto-cloning", e?.response);
            });
    };

    useEffect(() => {
        data?.id && onLoadData(data?.id);
    }, [data]);

    return (
        <Modal title={t("result.main.title")} id="popup-result-list" className="popup-result-list" onClose={props.onClose} noFooter close>
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        {/* <div className="total-count">
                            {t("common.label.success")} <span className="count">{dataList?.filter((f) => f.result)?.length || 0}</span>
                        </div> */}
                        <div className="total-count">
                            {t("common.total")} : <span className="count">{dataList?.length || 0}</span>
                        </div>
                    </div>
                </div>
                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper Result-table no-scroll">
                            <div className="table-box">
                                <table id="Result-table">
                                    <caption>{t("result.main.title")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.serialnumber")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("devicemanagement.title.deviceName")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("player.label.modelName")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("editor.label.date")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.resultvalue")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item?.serial_number}</td>
                                                <td>{item?.device_name}</td>
                                                <td>{item?.model_name}</td>
                                                <td>{item?.date ? _U.getOffsetTime(auth, workspace, item?.date) : ""}</td>
                                                <td>{item?.result === true ? "true" : item?.result}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">{t("common.label.serialnumber")}</div>
                                </div>
                            </div>
                            <ul className="table-body">
                                {dataList?.map((item, index) => (
                                    <li className={cx({ "table-row": true })} key={`convert-${index}`}>
                                        <div className="summary-data">
                                            <div className="row">
                                                <div className="table-cell alias">
                                                    <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                        {item?.serial_number}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="all-data-box">
                                            <ul className="all-data">
                                                <li>
                                                    <span className="field-label">{t("common.label.serialnumber")}</span>
                                                    <span className="field-content">{item?.serial_number}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("devicemanagement.title.deviceName")}</span>
                                                    <span className="field-content">{item?.device_name}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("player.label.modelName")}</span>
                                                    <span className="field-content">{item?.model_name}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("editor.label.date")}</span>
                                                    <span className="field-content">{item?.date ? _U.getOffsetTime(auth, workspace, item?.date) : ""}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("common.label.resultvalue")}</span>
                                                    <span className="field-content">{item?.result === true ? "true" : item?.result}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const GroupModal = (props) => {
    const { data: groupList } = props;
    const { t } = useTranslation();
    console.log("groupList", groupList);

    return (
        <Modal title={t("table.head.group")} id="popup-assigned-user" className="popup-assigned-user" onClick={props.onClick} onClose={props.onClose} noFooter close>
            <div className="inner-header-box">
                <div className="left-side"></div>
                <div className="right-side">
                    <div className="table-state">
                        {t("common.total")} : <strong>{groupList?.length || 0}</strong>
                    </div>
                </div>
            </div>
            <div className="email-recipients-box">
                <div className="field field-type-email">
                    <div className="field-form">
                        <ul className="email-list">
                            {groupList?.map((item, index) => (
                                <li key={index}>
                                    <span>{item?.group_name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
