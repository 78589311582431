import React from "react";
import moment from "moment";
import _API from "@util/api";
import ApkParser from "app-info-parser/src/apk";

const utils = {};

utils.encode = (o) => {
    return btoa(encodeURIComponent(JSON.stringify(o)));
};

utils.decode = (str) => {
    return JSON.parse(decodeURIComponent(atob(str)));
};

utils.hightlight = (str, keyword) => {
    if (keyword) {
        //하이라이트 처리
        return str;
    }
    return str;
};

utils.kbSizeParse = (kb) => {
    if (kb >= 1024 * 1024) {
        // GB로 변환
        const gbSize = (kb / (1024 * 1024)).toFixed(2);
        return `${gbSize}GB`;
    } else if (kb >= 1024) {
        // MB로 변환
        const mbSize = (kb / 1024).toFixed(2);
        return `${mbSize}MB`;
    } else {
        // KB로 그대로 표시
        return `${kb}KB`;
    }
};

utils.byteSizeParse = (bytes) => {
    if (bytes >= 1024 * 1024 * 1024) {
        // GB로 변환
        const gbSize = (bytes / (1024 * 1024 * 1024)).toFixed(2);
        return `${gbSize}GB`;
    } else if (bytes >= 1024 * 1024) {
        // MB로 변환
        const mbSize = (bytes / (1024 * 1024)).toFixed(2);
        return `${mbSize}MB`;
    } else if (bytes >= 1024) {
        // KB로 변환
        const kbSize = (bytes / 1024).toFixed(2);
        return `${kbSize}KB`;
    } else {
        // 바이트로 그대로 표시
        return `${bytes} bytes`;
    }
};

utils.getCurrentYearAndMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더합니다.
    return { year, month };
};

utils.formatUnixTimeDate = (number, type) => {
    const date = new Date(number * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    if (type === 0) {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else if (type === 1) {
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    } else {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
};

utils.formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

utils.formatDateByType = (dateString, type) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    if (type === 0) {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else if (type === 1) {
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    } else {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
};

utils.formatYYYYMMDD = (dateString, splitS = ".") => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${splitS}${month}${splitS}${day}`;
};

utils.postMessage = (code, data) => {
    window.postMessage(JSON.stringify({ code: code, data: data }), window.location.origin);
};

utils.parseMessage = (e) => {
    return new Promise((resolve, reject) => {
        if (!e.data) {
            reject && reject();
            return;
        }
        let data = null;
        try {
            data = typeof e.data === "string" && e.data.startsWith("{") ? JSON.parse(e.data) : e.data;
        } catch (e) {
            reject && reject();
            return;
        }
        try {
            if (data && data.code) {
                resolve && resolve(data);
            }
        } catch (e) {
            reject && reject(e);
        }
    });
};

utils.getExpireTime = (unix) => {
    const date = moment.duration(moment.unix(unix).diff(moment()));
    return { min: date.hours() === 1 ? 60 : date.minutes(), sec: date.hours() === 1 ? 0 : date.seconds() };
};

utils.checkEmailAddress = (address) => {
    if (!address) return false;
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    return regex.test(address);
};

utils.number3Comma = (val) => {
    if (val || val === 0) {
        val = (val + "").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    return val;
};

utils.getFilename = (file) => {
    if (!file) return "";
    const fileKeyParts = file.split("/");
    const name = fileKeyParts[fileKeyParts.length - 1];
    return name;
};

utils.getDday = (date) => {
    let days = moment.duration(moment(date).diff(moment())).asDays();
    days = Math.ceil(days);
    if (days > 0) {
        days = "-" + utils.number3Comma(days);
    } else {
        days = "+" + utils.number3Comma(days);
    }

    if (isNaN(days)) {
        return "";
    }

    return `(D${days})`;
};

utils.getConnectionStatusList = (t) => {
    return [
        { label: t("common.label.connected"), value: "connected" },
        { label: t("setup.troubleNotice.label.nosignal"), value: "connected_no_signal" },
        { label: t("player.label.energySaving.screenOff"), value: "disconnected_screen_off" },
        { label: t("common.label.disconnected"), value: "disconnected" },
    ];
};

utils.downloadRemoteAccessFile = async (os_type, idx, t) => {
    const currentDate = moment().format("HHmmssSS");
    let sessionKey = "";
    if (os_type === "ANDROID") {
        sessionKey = "DIGITAL_SIGNAGE_TERMINAL:" + currentDate;
    } else {
        sessionKey = "DIGITAL_SIGNAGE_REMOTE_CONTROL:" + currentDate;
    }
    document.title = sessionKey;
    const params = [{ remoteControl: { data: { value: currentDate } } }];
    let res = await _API.onDeviceControl2([idx], params, null, t);
    const url = `${process.env.STATIC_URL}/download/${_.get(process, "env.LAUNCHERV", "LauncherV_kr.exe")}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "LauncherV.exe");
    document.body.appendChild(link);
    link.click();

    //Dialog({ title: t("popup.title.inform"), text: obj[0]?.result ? t("common.msg.apply.success") : t("common.msg.apply.fail"), button: "Close" });
    // setTimeout(() => {
    //     document.title = "LG ConnectedCare";
    // }, [1000]);
};

utils.getOnlyNumber = (val) => {
    if (!val) return val;
    return (val + "").replace(/[^0-9]/g, "");
};

utils.getOnlyNumberDot = (val) => {
    if (!val) return val;
    const newVal = (val + "").replace(/[^0-9.]/g, "");
    if (newVal.startsWith(".")) {
        return "0" + newVal;
    } else {
        return newVal;
    }
};

utils.searchTextBold = (searchText, data) => {
    let result = data;
    if (searchText && typeof data !== "object" && data) {
        const replaceValue = searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(`(${replaceValue})`, "igm");
        result = <span dangerouslySetInnerHTML={{ __html: data.toString().replace(regex, "<b class='color-red'>$1</b>") }} />;
    }
    return result;
};

utils.getOsType = (t, code) => {
    let result = data;
    if (searchText && typeof data !== "object" && data) {
        const replaceValue = searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(`(${replaceValue})`, "igm");
        result = <span dangerouslySetInnerHTML={{ __html: data.toString().replace(regex, "<b class='color-red'>$1</b>") }} />;
    }
    return result;
};

utils.getAuthWriteDisable = (rolePermission, service) => {
    const exceptRoleList = ["/redirect", "/nolicense", "/qr_regist", "/administration/license"];
    let disabled = true;
    if (rolePermission) {
        let myPath = window.location.pathname;
        if (!myPath) {
            myPath = "/dashboard/devices";
        }
        let checkRole = service || myPath.split("/")[1];
        if (!checkRole) {
            checkRole = "dashboard";
        }
        // console.log("service", service, myPath, rolePermission[checkRole], !exceptRoleList.includes(myPath), !rolePermission[checkRole] || rolePermission[checkRole] < 2);
        // !exceptRoleList.includes(myPath) &&
        if (rolePermission[checkRole] && rolePermission[checkRole] > 1) {
            disabled = false;
        }
    }
    return disabled;
};

utils.toHex = (num) => {
    const hexArray = [];
    while (num > 0) {
        const digit = num % 16;
        hexArray.unshift(digit >= 10 ? String.fromCharCode(digit + 55) : String(digit));
        num = Math.floor(num / 16);
    }
    return hexArray.join("");
};

utils.getOffsetTime = (auth, workspace, time, format) => {
    try {
        const dateFormat = auth?.user_info?.datetime_format;
        const addType = workspace?.utc_offset?.substring(0, 1);
        const minutes = parseInt(workspace?.utc_offset?.substring(1, 3)) * 60 + parseInt(workspace?.utc_offset?.substring(4, 6));
        const addMinutes = minutes * (addType === "+" ? 1 : -1);
        const localOffsetMinute = new Date().getTimezoneOffset();
        // moment를 쓰면 기본적으로 local time zone 으로 변환 되므로
        // local 시간을 utc0로 변환 후 workspace offset 값으로 변환
        return moment(time)
            .add(localOffsetMinute, "minutes")
            .add(addMinutes, "minutes")
            .format(format || dateFormat);
    } catch (e) {
        console.log("convertPropertyTimeOffset error", e);
        return time;
    }
};

utils.getOffsetTimeUnix = (auth, workspace, time) => {
    try {
        const addType = workspace?.utc_offset?.substring(0, 1);
        const minutes = parseInt(workspace?.utc_offset?.substring(1, 3)) * 60 + parseInt(workspace?.utc_offset?.substring(4, 6));
        const addMinutes = minutes * (addType === "+" ? 1 : -1);
        const localOffsetMinute = new Date().getTimezoneOffset();
        // moment를 쓰면 기본적으로 local time zone 으로 변환 되므로
        // local 시간을 utc0로 변환 후 workspace offset 값으로 변환
        return moment(time).add(localOffsetMinute, "minutes").add(addMinutes, "minutes").valueOf();
    } catch (e) {
        console.log("convertPropertyTimeOffset error", e);
        return time;
    }
};

utils.getLocalTimeToUtc0Time = (auth, time, format) => {
    try {
        const dateFormat = auth?.user_info?.datetime_format;
        const localOffsetMinute = new Date().getTimezoneOffset();
        // moment를 쓰면 기본적으로 local time zone 으로 변환 되므로
        // local 시간을 utc0로 변환
        return moment(time)
            .add(localOffsetMinute, "minutes")
            .format(format || dateFormat);
    } catch (e) {
        console.log("convertPropertyTimeOffset error", e);
        return time;
    }
};

utils.getOffsetTimeToUtc = (workspace, time, format) => {
    try {
        // moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(540, "minute").add(-120, "minute").valueOf();
        const addType = workspace?.utc_offset?.substring(0, 1);
        const minutes = parseInt(workspace?.utc_offset?.substring(1, 3)) * 60 + parseInt(workspace?.utc_offset?.substring(4, 6));
        const addMinutes = minutes * (addType === "+" ? -1 : 1);
        const localOffsetMinute = new Date().getTimezoneOffset();
        // moment를 쓰면 기본적으로 local time zone 으로 변환 되므로
        // local 시간을 utc0로 변환 후 workspace offset 값으로 변환
        return moment(time)
            .add(-localOffsetMinute, "minutes")
            .add(addMinutes, "minutes")
            .utc()
            .format(format || "");
    } catch (e) {
        console.log("convertPropertyTimeOffset error", e);
        return time;
    }
};

utils.checkFileName = (fileName) => {
    // ASCII character ranges 00–1F hex (0–31 decimal) and 7F (127 decimal)
    // * Non-printable ASCII characters (128–255 decimal characters)
    const reg = /[ \{\}\[\]\/?,;:|\*~`^\+<>@\#$%&\\\=\'\"]/gi;
    const regex = /^[a-zA-Z0-9_\-().]*$/;
    if (fileName && fileName.length > 0) {
        if (fileName.length > 100) {
            return -2;
        }
        if (!regex.test(fileName)) {
            return -1;
        }
        if (fileName.length !== fileName.replace(reg, "").length) {
            return -1;
        }
        for (let i = 0; i < fileName.length; i++) {
            const chrTemp = fileName.charCodeAt(i);
            if ((chrTemp >= 0 && chrTemp <= 31) || (chrTemp >= 128 && chrTemp <= 255) || chrTemp === 127) {
                return -1;
            }
        }
    }
    return 1;
};

/**
 * 파일 다운로드 url 조회
 *
 * @author 오승현
 * @param {string} fileKey - 파일 고유 Key값
 * @param {number} expires_in_minutes - 다운로드 링크 유효시간 (default: 5)
 * @return {string} 파일 다운로드 url
 */
utils.getFileDownloadUrl = async (fileKey, expires_in_minutes = 5) => {
    try {
        const res = await _API.get({
            path: "/content/filebox/files/download-token",
            data: { file_path: fileKey, expires_in_minutes },
        });
        const {
            result: { url },
        } = res?.data;
        if (url) return url;
        else return "";
    } catch (error) {
        console.error("Error downloading file:", error);
        return "";
    }
};

/**
 * 오디오 파일의 재생시간을 반환
 *
 * @author 오승현
 * @param {string} fileKey - 파일 고유 Key값
 * @return {number} 오디오 재생시간
 */
utils.getDuration = async (fileKey) => {
    try {
        const url = await utils.getFileDownloadUrl(fileKey);
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        const durationInSeconds = parseInt(audioBuffer.duration);
        return durationInSeconds;
    } catch (error) {
        console.error("Error downloading or processing audio file:", error);
        throw error;
    }
};

/**
 * 해당 APK 파일의 Package Name 반환
 *
 * @author 오승현
 * @param {File} file - apk 파일
 * @return {string} 패키지명
 */
utils.getPackageName = async (file) => {
    try {
        const parser = await new ApkParser(file);
        const apkInfo = await parser.parse();
        console.log(apkInfo);
        return apkInfo.package;
    } catch (error) {
        console.error("Error parsing APK:", error);
        return "";
    }
};

utils.checkIpForm = (ip_addr) => {
    const filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

    if (!ip_addr || filter.test(ip_addr) == true) {
        return false;
    } else {
        return true;
    }
};

export default utils;
