import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import useCss from "@hook/useCss";

import Modal from "@component/UI/Modal";
import { SelectBox } from "@component/UI/Form";
import _API from "@util/api";
import _U from "@util/utilities";
import _CK from "@util/cookie";

import Dialog from "@component/UI/Dialog";
import { AppContext } from "@component/AppProvider";

export const SideMenu = (props) => {
    const { t } = useTranslation();
    const { sideMenu, onClose } = props;
    const { auth, workspace } = useContext(AppContext);

    const navigate = useNavigate();
    const location = useLocation();

    const tabRef = useRef(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowDown" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    useEffect(() => {
        if (
            auth?.portal_user_roles?.filter(
                (f) => "PlatformAdministrator" === f.common_role_name || (["BusinessAdministrator"].includes(f.common_role_name) && f.business_id === workspace?.business_id)
            )?.length > 0
        ) {
            setIsAdmin(true);
        }
    }, [workspace]);

    return (
        <div className={cx({ "site-group-list-container": true, active: sideMenu })}>
            <div className="header header-site">
                <button type="button" className="btn btn-back" onClick={onClose}>
                    <span className="ir">{t("subtext.button.Foldingthemenu")}</span>
                </button>
                <div className="title">{t("common.label.administration")}</div>
            </div>

            <div className="child-menu-container">
                <div className="category-menu">
                    <ul aria-orientation="vertical" ref={tabRef}>
                        <li id="profile" className={cx({ active: location.pathname.includes("profile") })} role="none presentation">
                            <a
                                href="#!"
                                onClick={() => navigate("/administration/profile")}
                                // onKeyDown={onTabAreaKeyDown}
                                aria-current={location.pathname.includes("profile")}
                                // tabIndex={location.pathname.includes("profile") ? 0 : -1}
                            >
                                {t("common.profile")}
                            </a>
                        </li>
                        <li id="user_role" className={cx({ active: location.pathname.includes("role") })} role="none presentation">
                            <a
                                href="#!"
                                onClick={() => navigate("/administration/role")}
                                // onKeyDown={onTabAreaKeyDown}
                                aria-current={location.pathname.includes("role")}
                                // tabIndex={location.pathname.includes("role") ? 0 : -1}
                            >
                                {t("common.label.user.role")}
                            </a>
                        </li>
                        {isAdmin && (
                            <li id="device_license" className={cx({ active: location.pathname.includes("license") })} role="none presentation">
                                <a
                                    href="#!"
                                    onClick={() => navigate("/administration/license")}
                                    // onKeyDown={onTabAreaKeyDown}
                                    aria-current={location.pathname.includes("license")}
                                    // tabIndex={location.pathname.includes("license") ? 0 : -1}
                                >
                                    {t("common.label.WorkspaceLicense")}
                                </a>
                            </li>
                        )}
                        <li id="agent_settings" className={cx({ active: location.pathname.includes("agent") })} role="none presentation">
                            <a
                                href="#!"
                                onClick={() => navigate("/administration/agent")}
                                // onKeyDown={onTabAreaKeyDown}
                                aria-current={location.pathname.includes("agent")}
                                // tabIndex={location.pathname.includes("agent") ? 0 : -1}
                            >
                                {t("common.agent")}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export const WorkspaceModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal title="Available License For Workspace" id="popup-select-file" className="popup-select-device large" onClose={props.onClose} noFooter close>
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        <div className="total-count">
                            {t("common.total")} : <span className="count">N</span>
                        </div>
                    </div>
                </div>

                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper device-table no-scroll">
                            <div className="table-box">
                                <table className="">
                                    <caption>Available License For Workspace list</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">Purchase ID</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Purchase Date</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Invalid Date</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">In Use</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">
                                                    Available
                                                    <br />
                                                    (Non-Activated)
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Total License</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Data</td>
                                            <td>YYYY.MM.DD</td>
                                            <td>YYYY.MM.DD</td>
                                            <td>Data</td>
                                            <td>Data(Data)</td>
                                            <td>Data</td>
                                        </tr>
                                        <tr>
                                            <td>Data</td>
                                            <td>YYYY.MM.DD</td>
                                            <td>YYYY.MM.DD</td>
                                            <td>Data</td>
                                            <td>Data(Data)</td>
                                            <td>Data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <div className="table-header">
                            <div className="row">
                                <div className="thead">Purchase ID</div>
                            </div>
                        </div>

                        <ul className="table-body">
                            <li className="table-row">
                                <div className="summary-data">
                                    <div className="row">
                                        <div className="table-cell issue-level">
                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false">
                                                Data
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-data-box">
                                    <ul className="all-data">
                                        <li>
                                            <span className="field-label">Purchase Date</span>
                                            <span className="field-content">YYYY.MM.DD</span>
                                        </li>
                                        <li>
                                            <span className="field-label">Invalid Date</span>
                                            <span className="field-content">YYYY.MM.DD</span>
                                        </li>
                                        <li>
                                            <span className="field-label">In Use</span>
                                            <span className="field-content">Data</span>
                                        </li>
                                        <li>
                                            <span className="field-label">
                                                Available
                                                <br />
                                                (Non-Activated)
                                            </span>
                                            <span className="field-content">Data(Data)</span>
                                        </li>
                                        <li>
                                            <span className="field-label">Total License</span>
                                            <span className="field-content">Data</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="table-row">
                                <div className="summary-data">
                                    <div className="row">
                                        <div className="table-cell issue-level">
                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false">
                                                Data
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-data-box">
                                    <ul className="all-data">
                                        <li>
                                            <span className="field-label">Purchase Date</span>
                                            <span className="field-content">YYYY.MM.DD</span>
                                        </li>
                                        <li>
                                            <span className="field-label">Invalid Date</span>
                                            <span className="field-content">YYYY.MM.DD</span>
                                        </li>
                                        <li>
                                            <span className="field-label">In Use</span>
                                            <span className="field-content">Data</span>
                                        </li>
                                        <li>
                                            <span className="field-label">
                                                Available
                                                <br />
                                                (Non-Activated)
                                            </span>
                                            <span className="field-content">Data(Data)</span>
                                        </li>
                                        <li>
                                            <span className="field-label">Total License</span>
                                            <span className="field-content">Data</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const Filter = (props) => {
    const { t } = useTranslation();
    const [license, setLicense] = useState([]);

    const onLicenseChange = (e) => {
        const tmp = JSON.parse(JSON.stringify(license));
        if (e.target.checked) {
            tmp.push(e.target.value);
        } else {
            tmp.splice(tmp.indexOf(e.target.value), 1);
        }
        setLicense(tmp);
    };

    const onReset = () => {
        setLicense([]);
    };

    const onApply = () => {
        props.onSetFilter && props.onSetFilter({ license });
    };

    useEffect(() => {
        if (props.filter) {
            setLicense(props.filter?.license);
        }
    }, [props.filter]);

    return (
        <div id="popup-filter-option" className={cx({ "filter-options": true, expand: props.show, show: props.show })} role="dialog">
            <div className="filter-options-top">
                <h2 className="title-pc">Filter</h2>
                <button type="button" className="btn-close" onClick={props.onClose}>
                    <span className="ir">{t("button.close")}</span>
                </button>
            </div>

            <div className="filter-options-middle">
                <div className="option-box">
                    <Fragment>
                        <div className="field field-type-checkbox field-connection-status">
                            <h3 className="field-label">License Status</h3>
                            <div className="field-form">
                                <div className="field-box not-flex">
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkbox-connection-status"
                                            id="checkbox-connection-status-1"
                                            value="IN_USED"
                                            checked={license?.includes("IN_USED")}
                                            onChange={onLicenseChange}
                                        />
                                        <label htmlFor="checkbox-connection-status-1">In Used</label>
                                    </div>
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkbox-connection-status"
                                            id="checkbox-connection-status-2"
                                            value="IN_USED_EXPIRED"
                                            checked={license?.includes("IN_USED_EXPIRED")}
                                            onChange={onLicenseChange}
                                        />
                                        <label htmlFor="checkbox-connection-status-2">In Used(Expire Soon)</label>
                                    </div>
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkbox-connection-status"
                                            id="checkbox-connection-status-3"
                                            value="AVAILABLE"
                                            checked={license?.includes("AVAILABLE")}
                                            onChange={onLicenseChange}
                                        />
                                        <label htmlFor="checkbox-connection-status-3">Available</label>
                                    </div>
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkbox-connection-status"
                                            id="checkbox-connection-status-4"
                                            value="AVAILABLE_INVALID"
                                            checked={license?.includes("AVAILABLE_INVALID")}
                                            onChange={onLicenseChange}
                                        />
                                        <label htmlFor="checkbox-connection-status-4">Available(Invalid Soon)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>

                <div className="button-box apply">
                    <button type="button" className="btn btn-dxp btn-reflash" onClick={onReset}>
                        Reset
                    </button>
                    <button type="button" className="btn btn-dxp btn-apply" onClick={onApply}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export const CreateModal = (props) => {
    const { t } = useTranslation();
    const { workspace } = useContext(AppContext);
    const [step, setStep] = useState(1);
    const [users, setUsers] = useState([]);
    const [roleName, setRoleName] = useState("");
    const [permissionList, setPermissionList] = useState([]);

    const onClickDone = () => {
        if (step === 1) {
            if (!roleName) {
                Dialog({
                    title: t("common.error"),
                    text: t("common.message.enterrolename"),
                    button: t("common.ok"),
                }).then((id) => {});
            } else if (permissionList?.filter((f) => f.value || f.value == "0")?.length !== permissionList?.length) {
                Dialog({
                    title: t("common.error"),
                    text: t("common.message.selectpermissions"),
                    button: t("common.ok"),
                }).then((id) => {});
            } else {
                if (props?.data?.role_name === roleName) {
                    setStep(2);
                } else {
                    _API.get({ path: "/user/roles/check", data: { name: roleName } })
                        .then((res) => {
                            const { result } = res.data;
                            if (result.result.is_exist) {
                                Dialog({
                                    title: t("popup.title.inform"),
                                    text: t("common.message.userrole.dup"),
                                    button: t("common.ok"),
                                }).then((id) => {});
                            } else {
                                setStep(2);
                            }
                        })
                        .catch((e) => {
                            console.log("api error /roles/check", e);
                        });
                }
            }
        } else if (step === 2) {
            // props.onClose();
            saveRoles();
        }
    };

    const saveRoles = () => {
        let params = {
            role_name: roleName,
            property_id: workspace?.property_id,
            permission: {},
            users: [],
        };

        const savePermission = permissionList?.filter((f) => f?.value || f?.value == "0");
        savePermission?.forEach((f) => {
            params.permission[f.key] = Number(f?.value);
        });
        users?.forEach((f) => {
            params?.users?.push({ user_id: f.user_id, property_id: workspace?.property_id });
        });

        //중복제거
        const tmpUsers = params?.users?.filter((obj, idx) => {
            const isFirstFindIdx = params?.users?.findIndex((obj2) => obj2["user_id"] === obj["user_id"]);
            return isFirstFindIdx === idx;
        });

        params["users"] = tmpUsers;

        if (props?.data) {
            params["role_id"] = props?.data?.role_id;
            params["users"] = tmpUsers;
            params["changed_role_permission"] = params?.permission;
            delete params?.permission;
            delete params?.property_id;

            _API.put({ path: "/user/roles", data: { ...params } })
                .then((res) => {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.assigned.user.modified"),
                        button: t("common.ok"),
                    }).then((id) => {
                        props.onClose();
                    });
                })
                .catch((e) => {
                    console.log("api error /user/roles", e);
                });
        } else {
            _API.post({ path: "/user/roles", data: { ...params } })
                .then((res) => {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.create.success.name", { name: `[${roleName}]` }),
                        button: t("common.ok"),
                    }).then((id) => {
                        props.onClose();
                    });
                })
                .catch((e) => {
                    console.log("api error /user/roles", e);
                    if (e?.response?.data?.status?.code === "00-007") {
                        Dialog({
                            title: t("popup.title.inform"),
                            text: t("common.message.userrole.dup"),
                            button: t("common.ok"),
                        }).then((id) => {
                            setStep(1);
                        });
                    }
                });
        }
    };

    const onSelectUser = (users) => {
        setUsers(users);
    };

    const isDisabled = () => {
        if (step === 2 && (!users || users.length === 0)) {
            return true;
        }
        return false;
    };

    const loadPermissionList = () => {
        _API.get({ path: "/business/menus", data: {} })
            .then((res) => {
                if (res?.data?.result) {
                    const newPermissionList = Object.entries(res?.data?.result).map(([key, value]) => ({ key: value, value: "" }));
                    if (props.data) {
                        newPermissionList?.forEach((f) => {
                            if (props.data?.role_permission[f.key] || props.data?.role_permission[f.key] == 0) {
                                f.value = props.data?.role_permission[f.key];
                            }
                        });
                        setRoleName(props.data?.role_name);
                    }
                    setPermissionList(newPermissionList);
                }
            })
            .catch((e) => {
                console.log("api error /business/menus", e);
                setPermissionList([]);
            });
    };

    const onChangePermission = (item, value) => {
        const newPermissionList = JSON.parse(JSON.stringify(permissionList));
        newPermissionList?.forEach((f) => {
            if (f.key === item.key) {
                f.value = value;
            }
        });

        console.log("newPermissionList", newPermissionList);
        setPermissionList(newPermissionList);
    };

    useEffect(() => {
        loadPermissionList();
    }, []);

    return (
        <Modal
            title={props.data ? t("common.label.EditRole") : t("common.button.CreateRole")}
            id="popup-create-role"
            className="popup-create-role"
            onClose={props.onClose}
            onClick={onClickDone}
            buttonOkTitle={step === 1 ? `Next` : `Done (${users?.length})`}
            butonOkariaLive={step === 1 ? "" : "polite"}
            // buttonOkDisabled={isDisabled()}
            popupContentHeader={
                <div className="popup-content-top">
                    <div className="step-title">
                        <h2 className={cx({ title: true, "tab-title": true, "title-role-assignment": true, hide: step === 2 })}>
                            <span>{t("common.label.Step")} 1.</span> {t("subtext.desc.role.edit.step1")}
                        </h2>
                        <h2 className={cx({ title: true, "tab-title": true, "title-manager-account": true, hide: step === 1 })}>
                            <span>{t("common.label.Step")} 2.</span> {t("common.label.Selectassignrole")}
                        </h2>
                    </div>

                    {/* <div className="tab-group" role="tablist">
                        <div className={cx({ tab: true, "tab-1": true, active: step === 1 })}>
                            <a href="#!" id="role-assignment" role="tab" aria-selected={step === 1 ? "true" : "false"} aira-label="첫 번째 탭">
                                1
                            </a>
                        </div>
                        <div className={cx({ tab: true, "tab-2": true, active: step === 2 })}>
                            <a href="#!" id="manager-account" role="tab" aria-selected={step === 2 ? "true" : "false"} aira-label="두 번째 탭">
                                2
                            </a>
                        </div>
                    </div> */}
                    <div className="step-group" role="img" aria-label={`${t("common.label.Step")} ${step} of 2`}>
                        <div className={cx({ step: true, "step-1": true, active: step === 1 })}>
                            <a href="#!" id="role-assignment">
                                1
                            </a>
                        </div>
                        <div className={cx({ step: true, "step-2": true, active: step === 2 })}>
                            <a href="#!" id="manager-account">
                                2
                            </a>
                        </div>
                    </div>
                </div>
            }
        >
            {step === 1 && <CreateModalStep1 permissionList={permissionList} onChange={onChangePermission} roleName={roleName} setRoleName={setRoleName} />}
            {step === 2 && <CreateModalStep2 onSelectUser={onSelectUser} role_id={props.data?.role_id} />}
        </Modal>
    );
};

const CreateModalStep1 = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        resizeTable();
    }, [props.permissionList]);
    return (
        <Fragment>
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <div className="field popup-create-role required">
                <div className="field-header">
                    <label htmlFor="ip-message-type" className="field-title">
                        {t("common.label.role.name")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="input-box">
                        <input
                            type="text"
                            id="ip-message-type"
                            name=""
                            placeholder="Title"
                            autoComplete="false"
                            aria-required="true"
                            value={props.roleName}
                            onChange={(e) => props.setRoleName(e.target.value)}
                        />
                        <button type="button" className={cx({ "btn-input-remove": true, hide: props.roleName ? false : true })} onClick={() => props.setRoleName("")}>
                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="field popup-create-role required">
                <div className="field-header">
                    <label htmlFor="ip-message-type" className="field-title">
                        {t("common.label.permission")} {t("common.setup")}
                    </label>
                </div>

                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper device-table no-scroll">
                            <div className="table-box">
                                <table className="">
                                    <caption>
                                        {t("common.label.permission")} {t("subtext.list")}
                                    </caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">{t("subtext.dashboard.menu")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.permission")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.permissionList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.key}</td>
                                                <td>
                                                    <div className="select-box">
                                                        <select
                                                            id=""
                                                            value={item.value}
                                                            onChange={(e) => props.onChange(item, e.target.value)}
                                                            aria-label={`${item.key} ${t("common.label.permission")}`}
                                                        >
                                                            <option value="">Select an Item</option>
                                                            <option value={"0"}>{t("common.code.role.permission.0")}</option>
                                                            <option value={"1"}>{t("common.code.role.permission.1")}</option>
                                                            <option value={"2"}>{t("common.code.role.permission.2")}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {(!props.permissionList || props.permissionList?.length === 0) && (
                                            <tr>
                                                <td colSpan={2} className="align-center">
                                                    {t("devicemanagement.search.noresult")}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <div className="table-header">
                            <div className="row">
                                <div className="thead">{t("subtext.dashboard.menu")}</div>
                            </div>
                        </div>

                        <ul className="table-body">
                            {props.permissionList?.map((item, index) => (
                                <li className="table-row" key={index}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell issue-level">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                    {item.key}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">{t("common.label.permission")}</span>
                                                <span className="field-content">
                                                    <select id="" value={item.value} onChange={(e) => props.onChange(item, e.target.value)}>
                                                        <option value="">Select an Item</option>
                                                        <option value={"0"}>{t("common.code.role.permission.0")}</option>
                                                        <option value={"1"}>{t("common.code.role.permission.1")}</option>
                                                        <option value={"2"}>{t("common.code.role.permission.2")}</option>
                                                    </select>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                            {(!props.permissionList || props.permissionList?.length === 0) && (
                                <li className="table-row">
                                    <div className="summary-data" role="rowgroup">
                                        <div className="row">{t("devicemanagement.search.noresult")}</div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const CreateModalStep2 = (props) => {
    const { role_id } = props;
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState("");
    const [view, setView] = useState(false);

    const [list, setList] = useState([]);
    const [data, setData] = useState(null);
    const [init, setInit] = useState(false);

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            generateList();
        }
    };

    const onAllCheckClick = (e) => {
        if (e.target.checked) {
            setList(list.map((m) => ({ ...m, checked: true })));
        } else {
            setList(list.map((m) => ({ ...m, checked: false })));
        }
    };

    const isAllChecked = () => {
        if (list?.length === list?.filter((f) => f.checked)?.length) {
            return true;
        }

        return false;
    };

    const onCheckClick = (e, idx) => {
        console.log("onCheckClick", e, idx);
        const tmpList = JSON.parse(JSON.stringify(list));
        if (e.target.checked) {
            tmpList?.forEach((f) => {
                if (f.lge_user_id == idx) {
                    f.checked = true;
                }
            });
        } else {
            tmpList?.forEach((f) => {
                if (f.lge_user_id == idx) {
                    f.checked = false;
                }
            });
        }
        console.log("tmpList", e.target.checked, tmpList);
        setList(tmpList);
    };

    const onClickDone = async () => {
        try {
            const users = list?.filter((f) => f.checked)?.map((m) => ({ user_id: m.user_id, property_id: props.data?.property_id }));
            const res = await _API.put({ path: "/user/roles", data: { role_id: props.data?.role_id, users } });
        } catch (e) {
            console.log("error put user/roles", e);
        }
        props.onClose();
    };

    const loadList = () => {
        _API.get({ path: "/user/users", data: {} })
            .then((res) => {
                if (res?.data?.result) {
                    setData(res?.data?.result);
                }
            })
            .catch((e) => {
                console.log("api error /user/users", e);
                setData(null);
            });
    };

    const generateList = (code) => {
        if (data) {
            let newList = [...data?.users, ...data?.unassigned_users];
            if (view) {
                newList = newList?.filter((f) => !f.role_id);
            }
            if (keyword && code !== "keyword") {
                newList = newList?.filter((f) => f.lge_user_id.indexOf(keyword) > -1);
            }
            if (init) {
                newList = newList.map((m) => ({ ...m, checked: m.role_id === role_id ? true : false }));
                setInit(false);
            } else {
                newList = newList.map((m) => ({ ...m, checked: false }));
            }
            setList(newList);
        }
    };

    const removeInput = () => {
        setKeyword("");
        generateList("keyword");
    };

    useEffect(() => {
        setInit(true);
        loadList();
    }, []);

    useEffect(() => {
        generateList();
    }, [data, view]);

    useEffect(() => {
        props.onSelectUser && props.onSelectUser(list?.filter((f) => f.checked));
    }, [list]);

    return (
        <Fragment>
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <div className="field popup-modify required">
                <div className="field-header">
                    <label htmlFor="ip-message-type" className="field-title">
                        {t("user.label.userList")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="field-box flex">
                        <span className="unit">{t("common.message.Viewonlyunassignedusers")}</span>
                        <div className="switch">
                            <label htmlFor="two-step-verification" className="switch-controller">
                                {t("common.message.Viewonlyunassignedusers")}
                            </label>
                            <input type="checkbox" id="two-step-verification" name="two-step-verification" role="switch" value="Y" checked={view} onChange={() => setView(!view)} />
                            <span className="slider"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-header-box">
                <div className="left-side">
                    <div className="checkbox">
                        <input type="checkbox" name="checkbox-all-1" id="checkbox-all-1" value="ALL" onChange={onAllCheckClick} checked={isAllChecked()} />
                        <label htmlFor="checkbox-all-1">{t("common.selectAll")}</label>
                    </div>
                </div>
                <div className="right-side">
                    <div className="state-box">
                        <div className="selected-state">
                            {t("dv.table.selected")} <strong>{list?.filter((f) => f.checked)?.length || 0}</strong>
                        </div>
                        <div className="table-state">
                            {t("common.total")} : <strong>{list?.length || 0}</strong>
                        </div>
                    </div>
                    {/* <div className="search-box2">
                        <div className="search-inner-box">
                            <input
                                type="text"
                                id="search"
                                className="search search_b"
                                name="search"
                                placeholder="Search"
                                autoComplete="true"
                                aria-label="Search"
                                aria-describedby="description-id"
                                onKeyDown={onKeyDown}
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                            <button type="button" className={cx({ "btn-input-remove": true, hide: keyword ? false : true })} onClick={() => removeInput()}>
                                <span className="ir">delete input</span>
                            </button>
                            <button type="button" className="btn-search">
                                <span className="ir">Search</span>
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="email-recipients-box">
                <div className="field field-type-email">
                    <div className="field-form">
                        <ul className="email-list mt0">
                            {list?.map((item, index) => (
                                <li key={index}>
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkbox-model2"
                                            id={`checkbox-model2-${item.lge_user_id}`}
                                            value={item.lge_user_id}
                                            checked={item.checked}
                                            onChange={(e) => onCheckClick(e, item.lge_user_id)}
                                        />
                                        <label htmlFor={`checkbox-model2-${item.lge_user_id}`}>{item.lge_user_id}</label>
                                    </div>
                                    {item.role_name && (
                                        <div className="role-name">
                                            <span>{item.role_name}</span>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export const UserModal = (props) => {
    const { t } = useTranslation();
    const { rolePermission } = useContext(AppContext);
    const [email, setEmail] = useState("");
    const [role, setRole] = useState(null);

    const onAdd = () => {
        alert("Add");
    };

    const loadList = () => {
        _API.get({ path: "/user/roles", data: { role_ids: props.data?.role_id } })
            .then((res) => {
                if (res?.data?.result) {
                    const role = res?.data?.result?.roles[0];
                    setRole(role);
                }
            })
            .catch((e) => {
                console.log("api error /user/roles/property", e);
                setRole(null);
            });
    };

    useEffect(() => {
        loadList();
    }, [props.data]);

    return (
        <Modal
            title={t("common.label.assigneduser")}
            id="popup-assigned-user"
            className="popup-assigned-user"
            onClick={props.onClick}
            onClose={props.onClose}
            noFooter
            modify
            buttonOkDisabled={_U.getAuthWriteDisable(rolePermission)}
        >
            <div className="inner-header-box">
                <div className="left-side"></div>
                <div className="right-side">
                    <div className="table-state">
                        {t("common.total")} : <strong>{role?.assigned_user_ids?.length || 0}</strong>
                    </div>
                </div>
            </div>
            <div className="email-recipients-box">
                <div className="field field-type-email">
                    {/* <div className="field-form">
                        <div className="field-box">
                            <input type="text" id="ip-sound-file" placeholder="Enter the e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <button type="button" className="btn btn-tertiary" onClick={onAdd}>
                                Add
                            </button>
                        </div>
                    </div> */}

                    <div className="field-form">
                        <ul className="email-list">
                            {role?.assigned_user_ids?.map((item, index) => (
                                <li key={index}>
                                    <span>{item?.lge_user_id}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const EditModal = (props) => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState("");
    const [view, setView] = useState(false);

    const [list, setList] = useState([]);
    const [data, setData] = useState(null);
    const [init, setInit] = useState(false);

    const onSearch = () => {
        generateList();
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    };

    const onAllCheckClick = (e) => {
        if (e.target.checked) {
            setList(list.map((m) => ({ ...m, checked: true })));
        } else {
            setList(list.map((m) => ({ ...m, checked: false })));
        }
    };

    const isAllChecked = () => {
        if (list?.length === list?.filter((f) => f.checked)?.length) {
            return true;
        }

        return false;
    };

    const onCheckClick = (e, idx) => {
        const tmpList = JSON.parse(JSON.stringify(list));
        if (e.target.checked) {
            tmpList?.forEach((f) => {
                if (f.lge_user_id == idx) {
                    f.checked = true;
                }
            });
        } else {
            tmpList?.forEach((f) => {
                if (f.lge_user_id == idx) {
                    f.checked = false;
                }
            });
        }
        setList(tmpList);
    };

    const onClickDone = async () => {
        try {
            const users = list?.filter((f) => f.checked)?.map((m) => ({ user_id: m.user_id, property_id: props.data?.property_id }));
            const res = await _API.put({ path: "/user/roles", data: { role_id: props.data?.role_id, users } });
            if (res) {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.message.assigned.user.modified"),
                    button: t("subtext.button.ok"),
                }).then((id) => {
                    props.onClose();
                });
            }
        } catch (e) {
            console.log("error put user/roles", e);
            props.onClose();
        }
    };

    const isDisabled = () => {
        if (list?.filter((f) => f.checked)?.length === 0) {
            return true;
        }
        return false;
    };

    const loadList = () => {
        _API.get({ path: "/user/users", data: {} })
            .then((res) => {
                if (res?.data?.result) {
                    setData(res?.data?.result);
                }
            })
            .catch((e) => {
                console.log("api error /user/users", e);
                setData(null);
            });
    };

    const generateList = () => {
        if (data) {
            let newList = [...data?.users, ...data?.unassigned_users];
            if (view) {
                newList = newList?.filter((f) => !f.role_id);
            }
            if (keyword) {
                newList = newList?.filter((f) => f.lge_user_id.indexOf(keyword) > -1);
            }
            if (init) {
                newList = newList.map((m) => ({ ...m, checked: m.role_id ? true : false }));
                setInit(false);
            } else {
                newList = newList.map((m) => ({ ...m, checked: false }));
            }
            setList(newList);
        }
    };

    useEffect(() => {
        setInit(true);
        loadList();
    }, [props.data]);

    useEffect(() => {
        generateList();
    }, [data, view]);

    return (
        <Modal
            title={t("common.label.modify")}
            id="popup-modify"
            className="popup-modify"
            onClose={props.onClose}
            onClick={onClickDone}
            buttonOkTitle={`${t("distribution.label.completed")} (${list?.filter((f) => f.checked)?.length})`}
            buttonOkDisabled={isDisabled()}
        >
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <div className="field popup-modify required">
                <div className="field-header">
                    <label htmlFor="ip-message-type" className="field-title">
                        {t("user.label.userList")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="field-box flex">
                        <span className="unit">{t("common.message.Viewonlyunassignedusers")}</span>
                        <div className="switch">
                            <label htmlFor="two-step-verification" className="switch-controller">
                                {t("common.message.Viewonlyunassignedusers")}
                            </label>
                            <input type="checkbox" id="two-step-verification" name="two-step-verification" role="switch" value="Y" checked={view} onChange={() => setView(!view)} />
                            <span className="slider"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-header-box">
                <div className="left-side">
                    <div className="checkbox">
                        <input type="checkbox" name="checkbox-all-1" id="checkbox-all-1" value="ALL" onChange={onAllCheckClick} checked={isAllChecked()} />
                        <label htmlFor="checkbox-all-1">{t("common.selectAll")}</label>
                    </div>
                </div>
                <div className="right-side">
                    <div className="state-box">
                        <div className="selected-state">
                            {t("dv.table.selected")} <strong>{list?.filter((f) => f.checked)?.length || 0}</strong>
                        </div>
                        <div className="table-state">
                            {t("common.total")} : <strong>{list?.length || 0}</strong>
                        </div>
                    </div>
                    <div className="search-box2">
                        <div className="search-inner-box">
                            <input
                                type="text"
                                id="search"
                                className="search search_b"
                                name="search"
                                placeholder="Search"
                                autoComplete="true"
                                aria-label="Search"
                                onKeyDown={onKeyDown}
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                            <button type="button" className={cx({ "btn-input-remove": true, hide: keyword ? false : true })} onClick={() => setKeyword("")}>
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                            {/* <button type="button" className="btn-search">
                                <span className="ir">Search</span>
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="email-recipients-box">
                <div className="field field-type-email">
                    <div className="field-form">
                        <ul className="email-list mt0">
                            {list?.map((item, index) => (
                                <li key={index}>
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkbox-model"
                                            id={`checkbox-model-${item.lge_user_id}`}
                                            value={item.lge_user_id}
                                            checked={item.checked}
                                            onChange={(e) => onCheckClick(e, item.lge_user_id)}
                                        />
                                        <label htmlFor={`checkbox-model-${item.lge_user_id}`}>{item.lge_user_id}</label>
                                    </div>
                                    {item.role_name && (
                                        <div className="role-name">
                                            <span>{item.role_name}</span>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const ViewModal = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        resizeTable();
    }, []);

    return (
        <Modal title={`${t("button.show")} [${props.data?.role_name}]`} id="popup-view" className="popup-view middle" onClose={props.onClose} noFooter ok>
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side">{t("common.label.permission")}</div>
                    {/* <!-- <div className="right-side"></div> --> */}
                </div>

                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper device-table no-scroll">
                            <div className="table-box">
                                <table className="">
                                    <caption>{t("common.label.permission")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">{t("subtext.dashboard.menu")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.permission")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data &&
                                            Object.entries(props.data?.role_permission).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td>{key}</td>
                                                    <td>{t("common.code.role.permission." + value)}</td>
                                                </tr>
                                            ))}
                                        {(!props.data || Object.entries(props.data?.role_permission)?.length === 0) && (
                                            <tr>
                                                <td colSpan={2} className="align-center">
                                                    {t("devicemanagement.search.noresult")}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <div className="table-header">
                            <div className="row">
                                <div className="thead">{t("subtext.dashboard.menu")}</div>
                            </div>
                        </div>

                        <ul className="table-body">
                            {props.data &&
                                Object.entries(props.data?.role_permission).map(([key, value]) => (
                                    <li className="table-row" key={`convert-${key}`}>
                                        <div className="summary-data">
                                            <div className="row">
                                                <div className="table-cell issue-level">
                                                    <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                        {key}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="all-data-box">
                                            <ul className="all-data">
                                                <li>
                                                    <span className="field-label">{t("common.label.permission")}</span>
                                                    <span className="field-content">{t("common.code.role.permission." + value)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                ))}
                            {(!props.data || Object.entries(props.data?.role_permission)?.length === 0) && (
                                <li className="table-row">
                                    <div className="summary-data">
                                        <div className="row">{t("devicemanagement.search.noresult")}</div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const AssignModal = (props) => {
    const { days, serviceType, productCode, headerServiceType } = props;
    const { t } = useTranslation();

    const [assign, setAssign] = useState("");
    const [active, setActive] = useState("W");

    const onDayChange = (e) => {
        const day = e.target.value;

        if (day > days) {
            setActive("N");
            setAssign("");
        } else {
            if (!day) {
                setActive("W");
            } else {
                setActive("Y");
            }
        }

        setAssign(e.target.value);
    };

    const onSave = () => {
        if (active !== "Y") {
            return;
        }

        _API.post({
            path: `/license/days/allocation`,
            data: { service_type: serviceType, property_id: _CK.get("property-id"), product_code: productCode, day: assign },
            serviceType: headerServiceType,
        })
            .then((res) => {
                Dialog({
                    title: t("popup.title.inform"),
                    text: `${assign} ${t("common.message.Daysassignedsuccessfully")}`,
                    button: t("common.ok"),
                }).then((id) => {
                    props.onClose();
                });
            })
            .catch((e) => {
                console.log("api error /license/days/allocation", e);
            });
    };

    return (
        <Modal
            title={t("common.label.AssigntoWorkspace")}
            buttonOkDisabled={active !== "Y"}
            buttonOkTitle="Assign"
            id="popup-add-template"
            className="popup-add-template"
            onClose={props.onClose}
            onClick={onSave}
        >
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>

            <div className="field field-type-message required">
                <div className="field-header">
                    <label for="ip-message-type" className="field-title">
                        {t("common.label.DaystoAssign")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="input-box">
                        <input type="number" id="ip-message-type" name="assign" value={assign} placeholder="Days to Assign" autocomplete="false" aria-required="true" onChange={onDayChange} />
                        <button type="button" className="btn-input-remove hide">
                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                        </button>
                    </div>
                    <div className="word-count">
                        {active === "N" && (
                            <span id="description-id" className="field-description">
                                {t("common.label.Rangeexceeded")}
                            </span>
                        )}
                        {t("common.label.Assignable")} : {_U.number3Comma(days)} {t("common.label.Days")}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const WithdrawModal = (props) => {
    const { serviceType, productCode, headerServiceType } = props;
    const [days, setDays] = useState(0);

    const { t } = useTranslation();

    const [withdraw, setWithdraw] = useState("");
    const [active, setActive] = useState("W");

    const loadDays = () => {
        _API.get({ path: "/license/days/remaining", serviceType: headerServiceType })
            .then((res) => {
                console.log(res);
                const { result } = res.data;
                setDays(result.total_remaining_day);
            })
            .catch((e) => {
                console.log("api error /license/workspace", e);
                setData({});
            });
    };

    useEffect(() => {
        loadDays();
    }, []);

    const onDayChange = (e) => {
        const day = e.target.value;

        if (day > days) {
            setActive("N");
            setWithdraw("");
        } else {
            if (!day) {
                setActive("W");
            } else {
                setActive("Y");
            }
        }

        setWithdraw(e.target.value);
    };

    const onSave = () => {
        if (active !== "Y") {
            return;
        }

        _API.post({
            path: `/license/days/deallocation`,
            data: { service_type: serviceType, property_id: _CK.get("property-id"), product_code: productCode, day: withdraw },
            serviceType: headerServiceType,
        })
            .then((res) => {
                Dialog({
                    title: t("popup.title.inform"),
                    text: `${withdraw} ${t("common.message.Dayswithdrawnsuccessfully")}`,
                    button: t("common.ok"),
                }).then((id) => {
                    props.onClose();
                });
            })
            .catch((e) => {
                console.log("api error /license/days/deallocation", e);
            });
    };

    return (
        <Modal
            title={t("common.label.WithdrawfromWorkspace")}
            buttonOkDisabled={active !== "Y"}
            buttonOkTitle="Withdraw"
            id="popup-add-template"
            className="popup-add-template"
            onClose={props.onClose}
            onClick={onSave}
        >
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>

            <div className="field field-type-message required">
                <div className="field-header">
                    <label htmlFor="ip-message-type" className="field-title">
                        {t("common.label.DaystoWithdraw")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="input-box">
                        <input
                            type="number"
                            id="ip-message-type"
                            name="withdraw"
                            value={withdraw}
                            placeholder={t("common.label.DaystoWithdraw")}
                            autocomplete="false"
                            aria-required="true"
                            onChange={onDayChange}
                        />
                        <button type="button" className="btn-input-remove hide">
                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                        </button>
                    </div>
                    <div className="word-count">
                        {active === "N" && (
                            <span id="description-id" className="field-description">
                                {t("common.label.Rangeexceeded")}
                            </span>
                        )}
                        {t("common.label.Withdrawable")} : {_U.number3Comma(days)} {t("common.label.Days")}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
