import Modal from "@component/UI/Modal";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _API from "@util/api";
import utils from "@util/utilities";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";

const SelectFile = ({ onClose, onClick, fileType = "image", isMultiple = false, fileFilter = [], selectedFiles, maxSelect = 99 }) => {
    const {
        workspace: { property_id },
        workspace,
        auth,
    } = useContext(AppContext);

    const [list, setList] = useState([]);
    const [checkedFileList, setCheckedFileList] = useState([]);
    const { t } = useTranslation();

    const clickDone = () => {
        if (!checkedFileList.length) {
            onClick([]);
        } else {
            const fileList = [];
            const listConcat = list.concat();
            checkedFileList.forEach((v, i) => fileList.push(listConcat[v]));
            onClick(fileList);
        }
    };

    const toggleShow = (index) => {
        const updatedList = [...list];
        updatedList[index].isShow = !updatedList[index].isShow;
        setList(updatedList);
    };

    const fileCheck = (index) => {
        if (isMultiple) {
            const updatedList = checkedFileList.includes(index) ? checkedFileList.filter((item) => item !== index) : [...checkedFileList, index];
            if (updatedList?.length > maxSelect) {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.overSelect10", { 0: maxSelect }),
                    button: t("common.ok"),
                });
                return;
            }
            setCheckedFileList(updatedList);
        } else {
            setCheckedFileList([index]);
        }
    };

    const handleCheckAll = () => {
        if (checkedFileList?.length === list?.length || checkedFileList?.length >= maxSelect) {
            setCheckedFileList([]);
        } else {
            if (list?.length > maxSelect) {
                const limitedSelection = Array.from({ length: maxSelect }, (_, i) => i);
                setCheckedFileList(limitedSelection);
            } else {
                setCheckedFileList([...Array(list?.length).keys()]);
            }
        }
    };

    const fetchFileData = () => {
        _API.get({
            path: `/content/filebox/files?type=${fileType.toLowerCase()}&property_id=${property_id}`,
        })
            .then((res) => {
                let {
                    result: { Contents },
                } = res?.data;
                const fileList = [];
                Contents?.forEach((content) => {
                    if (!content.hasOwnProperty("isShow")) {
                        content.isShow = false;
                    }
                    const { Key } = content;
                    const fileExtension = Key.split(".").pop().toLowerCase();
                    if (!fileFilter.includes(fileExtension)) fileList.push(content);
                });
                setList(fileList);
                if (selectedFiles?.length) {
                    const selectedIndexes = [];
                    selectedFiles?.forEach((selectedFile) => {
                        Contents?.forEach((content, contentIndex) => {
                            if (content?.Key === selectedFile) {
                                selectedIndexes.push(contentIndex);
                            }
                        });
                    });
                    setCheckedFileList(selectedIndexes);
                }
            })
            .catch((e) => {
                console.log("api error /content/filebox/files", e);
            });
    };

    useEffect(() => {
        fetchFileData();
    }, []);

    return (
        <Modal id="popup-select-file" className="popup-select-file" title={t("alertmsg.popup.select_file")} onClose={onClose} onClick={clickDone} buttonOkTitle={t("device.label.select")}>
            <div className="selected-files">
                ({checkedFileList?.length || 0} / {maxSelect})
            </div>
            <div className="table-state">
                {t("player.label.total")} <strong>{list?.length || 0}</strong>
            </div>
            <div className="field field-type-table">
                <div className="field-form">
                    <div className="field-box">
                        <div className="table">
                            <div className="table-wrapper select-file-table">
                                <div className="table-box">
                                    <table>
                                        <caption>{t("alertmsg.popup.select_file")}</caption>
                                        <thead>
                                            <tr>
                                                {isMultiple ? (
                                                    <th scope="col" className="width-6-rem">
                                                        <div className="field field-type-checkbox">
                                                            <div className="field-form">
                                                                <div className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        aria-label={t("tableSelectAll")}
                                                                        name="chk-all"
                                                                        id="chk-all"
                                                                        checked={list.every((_, i) => checkedFileList.includes(i)) || checkedFileList.length >= maxSelect}
                                                                        readOnly
                                                                        onClick={handleCheckAll}
                                                                    />
                                                                    <label htmlFor="chk-all"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                ) : (
                                                    <td scope="col" className="width-6-rem">
                                                        {" "}
                                                    </td>
                                                )}
                                                <th scope="col">{t("remote.label.highlight")}</th>
                                                <th scope="col">{t("table.head.filesize")}</th>
                                                <th scope="col">{t("table.head.date")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list?.map(
                                                (v, i) =>
                                                    v && (
                                                        <tr key={i}>
                                                            <td className="text-center-style width-6-rem">
                                                                <div className="field field-type-checkbox">
                                                                    <div className="field-form">
                                                                        <div className="checkbox">
                                                                            <input
                                                                                type={isMultiple ? "checkbox" : "radio"}
                                                                                name={`chk-sf01-${i}`}
                                                                                id={`chk-sf01-${i}`}
                                                                                checked={checkedFileList.includes(i)}
                                                                                onClick={() => fileCheck(i)}
                                                                                readOnly
                                                                            />
                                                                            <label htmlFor={`chk-sf01-${i}`}></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{v?.Key?.split("/")?.pop()}</td>
                                                            <td>{utils.byteSizeParse(v.Size)}</td>
                                                            <td>{v?.LastModified ? utils.getOffsetTime(auth, workspace, v.LastModified) : ""}</td>
                                                        </tr>
                                                    )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="field-type-mo mobile-only">
                <div className="file-header">
                    <div className="checkbox">
                        {isMultiple && (
                            <>
                                <input
                                    type="checkbox"
                                    aria-label={t("tableSelectAll")}
                                    name="chk-all-2"
                                    id="chk-all-2"
                                    checked={list.every((_, i) => checkedFileList.includes(i)) || checkedFileList.length >= maxSelect}
                                    onClick={handleCheckAll}
                                    readOnly
                                />
                                <label htmlFor="chk-all-2"></label>
                            </>
                        )}
                    </div>
                    <div className="name">{t("table.head.filename")}</div>
                </div>
                <div className="message-detail">
                    {list?.map(
                        (v, i) =>
                            v && (
                                <div className="file-item" key={i}>
                                    <div className="title-area" onClick={() => toggleShow(i)}>
                                        <div className="checkbox">
                                            <input
                                                type={isMultiple ? "checkbox" : "radio"}
                                                name={`chk-sf-type-${i}`}
                                                aria-label={t("tableSelectAll")}
                                                id={`chk-sf-type-${i}`}
                                                checked={checkedFileList.includes(i)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    fileCheck(i);
                                                }}
                                                readOnly
                                            />
                                            <label htmlFor={`chk-sf-type-${i}`}></label>
                                        </div>
                                        <div className="name">
                                            <span>{v?.Key?.split("/")?.pop()}</span>
                                            <span className="arrow is-opened">
                                                <img src={`/assets/images/ic_arrow_${v.isShow ? "up" : "down"}.svg`} alt={`Arrow ${v.isShow ? "Up" : "Down"}`} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="contents">
                                        {v.isShow && (
                                            <>
                                                <div className="size">
                                                    <span>{t("table.head.filesize")}</span>
                                                    <span>{utils.byteSizeParse(v.Size)}</span>
                                                </div>
                                                <div className="date">
                                                    <span>{t("table.head.date")}</span>
                                                    <span>{v?.LastModified ? utils.getOffsetTime(auth, workspace, v.LastModified) : ""}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default SelectFile;
