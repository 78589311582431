import React, { Fragment, useEffect } from "react";
import styled from "styled-components";

import Dialog from "@component/UI/Dialog";

import _API from "@util/api";
import _CK from "@util/cookie";

export default () => {
    const loadData = () => {
        _API.get({ path: "/device/devices" })
            .then((res) => {
                console.log(res);
            })
            .catch((e) => {
                console.log(e);
                Dialog({ title: "LGE", text: e.message, button: "확인" }).then((idx) => console.log(idx));
            });
    };

    useEffect(() => {
        loadData();
    }, []);

    const handlePopupClicked = (type) => {
        switch (type) {
            case "FULL":
                Dialog({ title: "LGE", text: "test", button: "확인", isFull: true });
                break;
            case "HTML":
                Dialog({ title: "LGE", html: '<p>TEST : <a href="https://www.lge.co.kr" target="_blank">LGE 홈페이지</a></p>', button: "확인" });
                break;
            case "CUSTOM":
                Dialog({ title: "LGE", body: <DialogCustomView /> })
                    .then((data) => alert(`RESOLVE : ${JSON.stringify(data)}`))
                    .catch((e) => alert(`REJECT : ${JSON.stringify(e)}`));
                break;
            default:
                Dialog({ title: "LGE", text: "test", button: ["Cancel", "aaa", "Done"] }).then((idx) => console.log(idx));
                break;
        }
    };

    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>Popup</h1>

                    <div className="breadcrumb">
                        <span className="bread first">Account name</span>
                        <span className="bread">Site name</span>
                        <span className="bread">Location name</span>
                        <span className="bread last">Shop name</span>
                    </div>
                </div>

                {/* <div className="last-update">
                    <span className="field-label">Last Update</span>
                    <span className="field-content">HH:MM:SS</span>
                    <p id="updated-time" className="hide">
                        데이터를 마지막으로 업데이트한 시각은 HH:MM:SS 입니다.
                    </p>
                </div> */}
            </div>

            <div className="content-middle">
                <div className="content-middle-box">
                    <ButtonWrapper>
                        <button type="submit" id="btn-default" className="btn btn-tertiary" onClick={handlePopupClicked.bind(this, "DEFAULT")}>
                            Default Popup
                        </button>
                        <button type="submit" id="btn-default" className="btn btn-tertiary" onClick={handlePopupClicked.bind(this, "HTML")}>
                            HTML Popup
                        </button>
                        <button type="submit" id="btn-default" className="btn btn-tertiary" onClick={handlePopupClicked.bind(this, "FULL")}>
                            Full Popup
                        </button>
                        <button type="submit" id="btn-default" className="btn btn-tertiary" onClick={handlePopupClicked.bind(this, "CUSTOM")}>
                            Custom View Popup
                        </button>
                    </ButtonWrapper>
                </div>
            </div>
        </Fragment>
    );
};

const DialogCustomView = ({ resolve, reject }) => {
    const handleResolveClicked = () => {
        resolve && resolve({ test: "test 1", test2: "test 2" });
    };

    const handleRejectClicked = () => {
        reject && reject({ message: "test" });
    };

    return (
        <div>
            <div>test</div>
            <button type="button" onClick={handleResolveClicked} className="btn btn-tertiary">
                Resolve
            </button>
            <button type="button" onClick={handleRejectClicked} className="btn btn-secondary">
                Reject
            </button>
        </div>
    );
};

const ButtonWrapper = styled.div`
    button {
        margin-right: 15px;
        :last-child {
            margin-right: 0px;
        }
    }
`;
