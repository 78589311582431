import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import cx from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";

import useCss from "@hook/useCss";
import Modal from "@component/UI/Modal";
import TargetModelnGroup from "@component/Device/ModelnGroup";
import { AppContext } from "@component/AppProvider";

import _API from "@util/api";
import Dialog from "@component/UI/Dialog";
import { SelectBox } from "@component/UI/Form";
import _U from "@util/utilities";

import AddEmail from "./addEmail";
import EditThreshold from "./editThreshold";

const MonitoringSetting = (props) => {
    useCss("/assets/css/popup_pannels.css");
    useCss("/assets/css/popup_device_list.css");

    const { t } = useTranslation();
    const { saveTargetModel, rolePermission } = useContext(AppContext);
    const [tabId, setTabId] = useState("email");
    const [addEmail, setAddEmail] = useState({ open: false, data: null });
    const [confirmInfo, setConfirmInfo] = useState({ open: false });
    const [editThreshold, setEditThreshold] = useState({ open: false });
    const [deferenceSettingInfo, setDeferenceSettingInfo] = useState({ open: false });
    const [data, setData] = useState({});

    const onDeferenceSettingData = (e) => {
        setDeferenceSettingInfo(e);
        if (e?.open === false) {
            saveTargetModel({ open: false });
            onLoadData();
        }
        if (e?.groupList?.length > 0 || e?.model_name) {
            console.log("api action area", e);
        }
    };

    const onLoadData = () => {
        _API.get({ path: "/issue/monitoring", data: {} })
            .then((res) => {
                console.log("api /monitoring", res);
                setData(res?.data?.result);
            })
            .catch((e) => {
                console.log("api error /monitoring", e);
            });
    };

    useEffect(() => {
        onLoadData();
    }, []);

    useEffect(() => {
        props.tabId && setTabId(props.tabId);
    }, [props.tabId]);

    const items = [
        { label: t("common.label.email.settings"), value: "email" },
        { label: t("dv.issue.threshold"), value: "threshold" },
        { label: t("common.label.comparison"), value: "comparison" },
    ];

    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowRight" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    return (
        <Fragment>
            <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
                <div id={props.id} className="popup-wrapper show">
                    <div className={`popup ${props.className}`} role="dialog" aria-labelledby="popup-label" aria-modal="true">
                        <div className="popup-container">
                            <div className="popup-header">
                                <h1 id="popup-label">{props.title}</h1>
                                {props.topClose && (
                                    <button type="button" className="btn-close" onClick={props.onClose}>
                                        <span className="ir">{t("button.close")}</span>
                                    </button>
                                )}
                            </div>
                            {props.popupContentHeader || ""}

                            <div className="popup-content">
                                <div className="tablist-content is-tab" role="tabpanel">
                                    <div className="tab-group type-default pc-only" role="tablist" ref={tabRef}>
                                        <div className={cx({ tab: true, active: tabId === "email" })}>
                                            <a
                                                id="monitor-email"
                                                href="#!"
                                                role="tab"
                                                aria-selected={tabId === "email"}
                                                onClick={() => setTabId("email")}
                                                onKeyDown={onTabAreaKeyDown}
                                                tabIndex={tabId === "email" ? 0 : -1}
                                            >
                                                {t("common.label.email.settings")}
                                            </a>
                                        </div>
                                        <div className={cx({ tab: true, active: tabId === "threshold" })}>
                                            <a
                                                id="monitor-threshold"
                                                href="#!"
                                                role="tab"
                                                aria-selected={tabId === "threshold"}
                                                onClick={() => setTabId("threshold")}
                                                onKeyDown={onTabAreaKeyDown}
                                                tabIndex={tabId === "threshold" ? 0 : -1}
                                            >
                                                {t("dv.issue.threshold")}
                                            </a>
                                        </div>
                                        <div className={cx({ tab: true, active: tabId === "comparison" })}>
                                            <a
                                                id="monitor-comparison"
                                                href="#!"
                                                role="tab"
                                                aria-selected={tabId === "comparison"}
                                                onClick={() => setTabId("comparison")}
                                                onKeyDown={onTabAreaKeyDown}
                                                tabIndex={tabId === "comparison" ? 0 : -1}
                                            >
                                                {t("common.label.comparison")}
                                            </a>
                                        </div>
                                    </div>
                                    <SelectBox
                                        id={`convert-TabSelect`}
                                        className="mobile-only"
                                        items={items}
                                        value={items?.filter((f) => f.value == tabId)[0]?.value || ""}
                                        selectLabel={items?.filter((f) => f.value == tabId)[0]?.label || ""}
                                        onChange={(item) => setTabId(item.value)}
                                    />

                                    {tabId === "email" && <EmailSetting data={data} addEmail={(data) => setAddEmail({ open: true, data })} onLoadData={onLoadData} />}
                                    {tabId === "threshold" && <Threshold data={data} onEdit={() => setEditThreshold({ open: true, data })} />}
                                    {tabId === "comparison" && <Comparison data={data} onDeferenceSettingData={onDeferenceSettingData} onLoadData={onLoadData} />}
                                </div>
                            </div>
                            {!props.noFooter && (
                                <div className="popup-footer">
                                    <div className="button-box double-button">
                                        <button type="button" id="btn-sm-cancel" className="btn btn-huge btn-tertiary" onClick={props.onClose}>
                                            {t("button.cancel")}
                                        </button>
                                        <button type="button" id="btn-sm-done" className="btn btn-huge btn-secondary " onClick={props.onClick} disabled={props.buttonOkDisabled}>
                                            {props.buttonOkTitle || t("distribution.label.completed")}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {props.ok && (
                                <div className="popup-footer">
                                    <div className="button-box">
                                        <button type="button" id="btn-fb-close" className="btn btn-huge btn-tertiary" onClick={props.onClose}>
                                            {t("common.ok")}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {props.close && (
                                <div className="popup-footer">
                                    <div className="button-box">
                                        <button type="button" id="btn-fb-close" className="btn btn-huge btn-tertiary" onClick={props.onClose}>
                                            {t("button.close")}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </FocusTrap>
            {addEmail?.open && (
                <AddEmail
                    id="popup-add-email"
                    className="popup-add-email"
                    data={addEmail?.data}
                    onClose={() => {
                        onLoadData();
                        setAddEmail({ open: false, data: null });
                    }}
                />
            )}
            {editThreshold?.open && (
                <EditThreshold
                    id="popup-edit-threshold"
                    className="popup-edit-threshold"
                    title={t("dv.edit.threshold")}
                    data={editThreshold?.data}
                    onClose={() => {
                        onLoadData();
                        setEditThreshold({ open: false });
                    }}
                />
            )}
            {deferenceSettingInfo?.open && <ComparisonRegister info={deferenceSettingInfo} />}
        </Fragment>
    );
};

const EmailSetting = (props) => {
    const { t } = useTranslation();
    const { data } = props;
    const { rolePermission } = useContext(AppContext);

    const addEmail = () => {
        // alert("준비중입니다.");
        props.addEmail && props.addEmail();
    };
    const onEdit = (item) => {
        // alert("준비중입니다.");
        props.addEmail && props.addEmail(item);
    };
    const onDelete = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("device.message.delete.email"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                _API.delete({ path: `/issue/monitoring/recipient/${item?.recipient_id}`, data: {} })
                    .then((res) => {
                        props.onLoadData && props.onLoadData();
                    })
                    .catch((e) => {
                        console.log(`api error /monitoring/recipient/${item?.recipient_id}`, e);
                    });
            } else {
                props.onClose && props.onClose();
            }
        });
    };

    return (
        <div className="tab-panel panel-monitor-email" role="tabpanel">
            <div className="alert-guide-txt">{t("subtext.guid.add.email")}.</div>
            <div className="data-state">
                <div className="col">
                    <button type="button" className="btn btn-tertiary" onClick={addEmail} disabled={_U.getAuthWriteDisable(rolePermission)}>
                        {t("common.button.add.email")}
                    </button>
                </div>
                <div className="col">
                    <div className="total-count">
                        {t("common.total")} : <strong>{data?.email_setting?.length || 0}</strong>
                    </div>
                </div>
            </div>
            <div className="field field-type-table">
                <div className="field-form">
                    <div className="field-box">
                        <div className="table">
                            <div className="table-wrapper email-set-table">
                                <div className="table-box">
                                    <table className="height-100vh-55rem">
                                        <caption>{t("common.label.email.settings")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col" className="width-14-percent">
                                                    <div className="text-block">{t("dg.popup.addTrouble")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.table.header.time.available.receive.mail")}</div>
                                                </th>
                                                <th scope="col" className="width-8-percent">
                                                    <div className="text-block">{t("common.edit")}</div>
                                                </th>
                                                <th scope="col" className="width-8-percent">
                                                    <div className="text-block">{t("button.delete")}</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="height-100vh-58-6-rem">
                                            {data?.email_setting?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="width-14-percent" id={`emailsetting-td1-${index}`}>
                                                        {item?.email}
                                                    </td>
                                                    <td id={`emailsetting-td2-${index}`}>
                                                        <dl className="time-info">
                                                            <dt>{t("admin.label.monday")}</dt>
                                                            <dd>{item?.time?.mon?.activation ? item?.time?.mon.start + " ~ " + item?.time?.mon?.end : t("subtext.unable.receive.mail")}</dd>
                                                            <dt>{t("admin.label.tuesday")}</dt>
                                                            <dd>{item?.time?.tue?.activation ? item?.time?.tue.start + " ~ " + item?.time?.tue?.end : t("subtext.unable.receive.mail")}</dd>
                                                            <dt>{t("admin.label.wednesday")}</dt>
                                                            <dd>{item?.time?.wed?.activation ? item?.time?.wed.start + " ~ " + item?.time?.wed?.end : t("subtext.unable.receive.mail")}</dd>
                                                            <dt>{t("admin.label.thursday")}</dt>
                                                            <dd>{item?.time?.thu?.activation ? item?.time?.thu.start + " ~ " + item?.time?.thu?.end : t("subtext.unable.receive.mail")}</dd>
                                                            <dt>{t("admin.label.friday")}</dt>
                                                            <dd>{item?.time?.fri?.activation ? item?.time?.fri.start + " ~ " + item?.time?.fri?.end : t("subtext.unable.receive.mail")}</dd>
                                                            <dt>{t("admin.label.saturday")}</dt>
                                                            <dd>{item?.time?.sat?.activation ? item?.time?.sat.start + " ~ " + item?.time?.sat?.end : t("subtext.unable.receive.mail")}</dd>
                                                            <dt>{t("admin.label.sunday")}</dt>
                                                            <dd>{item?.time?.sun?.activation ? item?.time?.sun.start + " ~ " + item?.time?.sun?.end : t("subtext.unable.receive.mail")}</dd>
                                                        </dl>
                                                    </td>
                                                    <td className="center width-8-percent">
                                                        <button
                                                            type="button"
                                                            id=""
                                                            className="btn btn-data-edit"
                                                            onClick={() => onEdit(item)}
                                                            aria-describedby={`emailsetting-td1-${index} emailsetting-td2-${index}`}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        >
                                                            {t("common.edit")}
                                                        </button>
                                                    </td>
                                                    <td className="center width-8-percent">
                                                        <button
                                                            type="button"
                                                            id=""
                                                            className="btn btn-data-delete"
                                                            onClick={() => onDelete(item)}
                                                            aria-describedby={`emailsetting-td1-${index} emailsetting-td2-${index}`}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        >
                                                            {t("button.delete")}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="convert-table-to-list">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="thead">{t("dg.popup.addTrouble")}</div>
                                    </div>
                                </div>
                                <ul className="table-body">
                                    {data?.email_setting?.map((item, index) => (
                                        <li className="table-row" key={`convert-table-${index}`}>
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell alias">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {item?.email}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li>
                                                        <span className="field-label">{t("dg.popup.addTrouble")}</span>
                                                        <span className="field-content">{item?.email}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("common.table.header.time.available.receive.mail")}</span>
                                                        <span className="field-content">
                                                            <dl className="time-info">
                                                                <dt>{t("admin.label.monday")}</dt>
                                                                <dd>{item?.time?.mon?.activation ? item?.time?.mon.start + " ~ " + item?.time?.mon?.end : t("subtext.unable.receive.mail")}</dd>
                                                                <dt>{t("admin.label.tuesday")}</dt>
                                                                <dd>{item?.time?.tue?.activation ? item?.time?.tue.start + " ~ " + item?.time?.tue?.end : t("subtext.unable.receive.mail")}</dd>
                                                                <dt>{t("admin.label.wednesday")}</dt>
                                                                <dd>{item?.time?.wed?.activation ? item?.time?.wed.start + " ~ " + item?.time?.wed?.end : t("subtext.unable.receive.mail")}</dd>
                                                                <dt>{t("admin.label.thursday")}</dt>
                                                                <dd>{item?.time?.thu?.activation ? item?.time?.thu.start + " ~ " + item?.time?.thu?.end : t("subtext.unable.receive.mail")}</dd>
                                                                <dt>{t("admin.label.friday")}</dt>
                                                                <dd>{item?.time?.fri?.activation ? item?.time?.fri.start + " ~ " + item?.time?.fri?.end : t("subtext.unable.receive.mail")}</dd>
                                                                <dt>{t("admin.label.saturday")}</dt>
                                                                <dd>{item?.time?.sat?.activation ? item?.time?.sat.start + " ~ " + item?.time?.sat?.end : t("subtext.unable.receive.mail")}</dd>
                                                                <dt>{t("admin.label.sunday")}</dt>
                                                                <dd>{item?.time?.sun?.activation ? item?.time?.sun.start + " ~ " + item?.time?.sun?.end : t("subtext.unable.receive.mail")}</dd>
                                                            </dl>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("common.edit")}</span>
                                                        <span className="field-content">
                                                            <button type="button" id="" className="btn btn-data-edit" onClick={() => onEdit(item)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                                {t("common.edit")}
                                                            </button>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("button.delete")}</span>
                                                        <span className="field-content">
                                                            <button
                                                                type="button"
                                                                id=""
                                                                className="btn btn-data-delete"
                                                                onClick={() => onDelete(item)}
                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                            >
                                                                {t("button.delete")}
                                                            </button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Threshold = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const { rolePermission } = useContext(AppContext);

    const onEdit = () => {
        // alert("준비중입니다.");
        props.onEdit && props.onEdit();
    };

    useEffect(() => {
        setData(props.data?.threshold);
    }, [props.data]);
    return (
        <div className="tab-panel panel-monitor-threshold" role="tabpanel">
            <div className="alert-guide-txt whitespace-pre-line">{t("subtext.monitoringsettings.threshold")}</div>
            <div className="data-state">
                <div className="col">
                    <button type="button" className="btn btn-tertiary" onClick={onEdit} disabled={_U.getAuthWriteDisable(rolePermission)}>
                        {t("common.edit")}
                    </button>
                </div>
                <div className="col">
                    <div className="total-count">
                        {t("common.enable")} <strong>{data?.filter((f) => f.activation)?.length || 0}</strong>
                    </div>
                    <div className="total-count">
                        {t("common.disable")} <strong>{data?.filter((f) => !f.activation)?.length || 0}</strong>
                    </div>
                </div>
            </div>
            <div className="field field-type-table">
                <div className="field-form">
                    <div className="field-box">
                        <div className="table">
                            <div className="table-wrapper Threshold-table">
                                <div className="table-box">
                                    <table className="height-100vh-55rem">
                                        <caption>{t("dv.issue.threshold")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.label.category")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("report.issuetype")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("editor.label.code")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("editor.label.item")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.error")}</div>
                                                </th>
                                                <th scope="col" className="width-12-percent">
                                                    <div className="text-block">{t("schedule.active.state.active")}</div>
                                                </th>
                                                <th scope="col" className="width-12-percent">
                                                    <div className="text-block">{t("dg.email.notice")}</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="height-100vh-58-6-rem">
                                            {data?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.category}</td>
                                                    <td>{item?.issue_type}</td>
                                                    <td>{item?.issue_code}</td>
                                                    <td>{item?.item}</td>
                                                    <td>{item?.error}</td>
                                                    <td className="width-12-percent">{item?.activation ? t("common.enable") : t("common.disable")}</td>
                                                    <td className="width-12-percent">{item?.email_notice ? t("common.enable") : t("common.disable")}</td>
                                                </tr>
                                            ))}
                                            {(!data || data.length === 0) && (
                                                <tr>
                                                    <td colSpan={7} className="align-center">
                                                        {t("devicemanagement.search.noresult")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="convert-table-to-list">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="thead">{t("common.label.category")}</div>
                                    </div>
                                </div>
                                <ul className="table-body">
                                    {data?.map((item, index) => (
                                        <li className="table-row" key={`convert-table-${index}`}>
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell alias">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {item?.category}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li>
                                                        <span className="field-label">{t("report.issuetype")}</span>
                                                        <span className="field-content">{item?.issue_type}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("editor.label.code")}</span>
                                                        <span className="field-content">{item?.issue_code}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("editor.label.item")}</span>
                                                        <span className="field-content">{item?.item}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("common.error")}</span>
                                                        <span className="field-content">{item?.error}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("schedule.active.state.active")}</span>
                                                        <span className="field-content">{item?.activation ? t("common.enable") : t("common.disable")}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("dg.email.notice")}</span>
                                                        <span className="field-content">{item?.email_notice ? t("common.enable") : t("common.disable")}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Comparison = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const { rolePermission, auth, workspace } = useContext(AppContext);

    const onChange = (uCode, code, val) => {
        setData({
            ...data,
            [uCode]: { ...data[uCode], [code]: val },
            [uCode + "Apply"]: true,
        });
    };

    const onChange2Depth = (tCode, uCode, code, val) => {
        val = (val + "").replace(/[^0-9]/g, "");
        if (isNaN(val) || val == "") {
            val = code === "start" ? 0 : 100;
        }
        if (code === "start" && (parseInt(val) > 99 || parseInt(val) < 0)) {
            val = 0;
        } else if (code === "end" && parseInt(val) > 100) {
            // || parseInt(val) < parseInt(data?.volume?.data?.start))
            val = 100;
        } else {
            val = parseInt(val);
            console.log("vvvv", val);
        }
        setData({
            ...data,
            [tCode]: { ...data[tCode], [uCode]: { ...data[tCode][uCode], [code]: val } },
            [tCode + "Apply"]: true,
        });
        // console.log(">>>", { ...data, [tCode]: { ...data[tCode], [uCode]: { ...data[tCode][uCode], [code]: val } }, [tCode + "Apply"]: true });
    };

    const onApply = (code) => {
        const params = { volume: data?.volume, usb_lock: data?.usb_lock };
        if (data?.volume?.data?.start || data?.volume?.data?.start == 0) {
            params.volume.data.start = data?.volume?.data?.start + "";
        }
        if (data?.volume?.data?.end || data?.volume?.data?.end == 0) {
            params.volume.data.end = data?.volume?.data?.end + "";
        }
        if (data?.volume?.activation) {
            params.volume.activation = true;
        } else {
            params.volume.activation = false;
        }
        if (data?.usb_lock?.activation) {
            params.usb_lock.activation = true;
        } else {
            params.usb_lock.activation = false;
        }
        _API.put({ path: `/issue/monitoring/compare-setting`, data: params })
            .then((res) => {
                props.onLoadData && props.onLoadData();
            })
            .catch((e) => {
                console.log(`api error /monitoring/compare-setting`, e);
            });
    };

    const onDelete = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("device.msg.deletetheitem"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                _API.delete({ path: `/issue/monitoring/difference-setting/${item?.difference_setting_id}`, data: {} })
                    .then((res) => {
                        props.onLoadData && props.onLoadData();
                    })
                    .catch((e) => {
                        console.log(`api error /monitoring/recipient`, e);
                    });
            } else {
            }
        });
    };

    const onEdit = (info) => {
        const params = {
            open: true,
            title: t("main.btnRegister.title"),
            id: "popup-register",
            className: "popup-register",
            okTitle: t("main.btnRegister.title"),
            onClose: () => props.onDeferenceSettingData({ open: false }),
            onClick: (e) => onDeferenceSettingDataConfirm(e, info),
            info: info,
        };
        if (info?.group?.group_id) {
            params.groupList = [{ group_id: info?.group?.group_id, group_name: info?.hierarchy_group, hierarchy_group: info?.group?.hierarchy_group }];
            params.model_name = info?.model_name;
        }
        props?.onDeferenceSettingData && props.onDeferenceSettingData(params);
    };

    const onDeferenceSettingDataConfirm = (e, info) => {
        if (!e.groupList || e.groupList?.length === 0) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("devicemanagement.tree.group") }),
                button: t("common.ok"),
            }).then((id) => {});
            return;
        } else if (!e.model_name) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("player.label.model") }),
                button: t("common.ok"),
            }).then((id) => {});
            return;
        }
        const params = {
            group_id: e.groupList[0]?.group_id,
            model_name: e.model_name || null,
        };

        console.log("onDeferenceSettingDataConfirm", params, info, e);
        if (info?.difference_setting_id) {
            //수정
            _API.put({ path: `/issue/monitoring/difference-setting/${info?.difference_setting_id}`, data: params })
                .then((res) => {
                    props.onLoadData && props.onLoadData();
                    props.onDeferenceSettingData({ open: false });
                })
                .catch((e) => {
                    console.log(`api error /monitoring/difference-setting`, e);
                    props.onDeferenceSettingData({ open: false });
                });
        } else {
            _API.post({ path: `/issue/monitoring/difference-setting`, data: params })
                .then((res) => {
                    props.onLoadData && props.onLoadData();
                    props.onDeferenceSettingData({ open: false });
                })
                .catch((e) => {
                    console.log(`api error /monitoring/difference-setting`, e);
                    props.onDeferenceSettingData({ open: false });
                });
        }
    };

    useEffect(() => {
        if (props.data?.comparison) {
            setData(props.data?.comparison);
        } else {
            setData({
                volume: {
                    activation: false,
                    data: {
                        start: "",
                        end: "",
                    },
                },
                usb_lock: {
                    activation: false,
                },
                difference_setting: {
                    data: [],
                },
            });
        }
    }, [props.data]);

    useEffect(() => {
        resizeTable();
    }, [data]);

    return (
        <div className="tab-panel panel-monitor-comparison" role="tabpanel">
            <h2>{t("player.label.volume")}</h2>
            <div className="alert-guide-txt">{t("subtext.monitoringsettings.comparison.volume")}</div>
            <div className="field field-type-radio">
                <div className="field-form">
                    <div className="radio">
                        <input
                            type="radio"
                            name="rad-tem-type01"
                            id="rad-tem-type01-1"
                            checked={data?.volume?.activation ? true : false}
                            onChange={(e) => onChange("volume", "activation", true)}
                            disabled={_U.getAuthWriteDisable(rolePermission)}
                        />
                        <label htmlFor="rad-tem-type01-1">{t("subtext.monitoringsettings.comparison.notification")}</label>
                        <div className="field field-type-input field-flex">
                            <div className="field-header">
                                <label htmlFor="ip-volume-min" className="field-title" id="volumeLabel">
                                    {t("common.label.range.100")}
                                </label>
                            </div>
                            <div className="field-form">
                                <div className="field-box">
                                    <div className="input-box">
                                        <input
                                            type="text"
                                            id="ip-volume-min"
                                            name="ip-volume-min"
                                            placeholder="1"
                                            min={0}
                                            max={99}
                                            autoComplete="false"
                                            value={data?.volume?.data?.start}
                                            onChange={(e) => onChange2Depth("volume", "data", "start", e.target.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                        <button
                                            type="button"
                                            className={cx({ "btn-input-remove": true, hide: data?.volume?.data?.start || data?.volume?.data?.start == 0 ? false : true })}
                                            onClick={(e) => onChange2Depth("volume", "data", "start", "")}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        >
                                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                        </button>
                                    </div>
                                    <span className="unit">~</span>
                                    <div className="input-box">
                                        <input
                                            type="text"
                                            id="ip-volume-max"
                                            name="ip-volume-max"
                                            placeholder="100"
                                            min={1}
                                            max={100}
                                            autoComplete="false"
                                            value={data?.volume?.data?.end}
                                            onChange={(e) => onChange2Depth("volume", "data", "end", e.target.value)}
                                            aria-labelledby="volumeLabel"
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                        <button
                                            type="button"
                                            className={cx({ "btn-input-remove": true, hide: data?.volume?.data?.end || data?.volume?.data?.end == 0 ? false : true })}
                                            onClick={(e) => onChange2Depth("volume", "data", "end", "")}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        >
                                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="radio">
                        <input
                            type="radio"
                            name="rad-tem-type01"
                            id="rad-tem-type01-2"
                            checked={data?.volume?.activation ? false : true}
                            onChange={(e) => onChange("volume", "activation", false)}
                            disabled={_U.getAuthWriteDisable(rolePermission)}
                        />
                        <label htmlFor="rad-tem-type01-2">{t("subtext.monitoringsettings.comparison.notification.not")}</label>
                    </div>
                    <div className="data-state">
                        <div className="col">
                            <button type="button" className="btn btn-tertiary" onClick={() => onApply("volume")} disabled={!data?.volumeApply || _U.getAuthWriteDisable(rolePermission)}>
                                {t("player.button.apply")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <h2>{t("device.label.usblock")}</h2>
            <div className="alert-guide-txt">{t("subtext.monitoringsettings.comparison.usblock")}</div>
            <div className="field field-type-radio">
                <div className="field-form">
                    <div className="radio">
                        <input
                            type="radio"
                            name="rad-tem-type02"
                            id="rad-tem-type02-1"
                            // defaultChecked={data?.usb_lock?.activation || false}
                            checked={data?.usb_lock?.activation ? true : false}
                            onChange={(e) => onChange("usb_lock", "activation", true)}
                            disabled={_U.getAuthWriteDisable(rolePermission)}
                        />
                        <label htmlFor="rad-tem-type02-1">{t("subtext.monitoringsettings.comparison.notification")}</label>
                    </div>
                    <div className="radio">
                        <input
                            type="radio"
                            name="rad-tem-type02"
                            id="rad-tem-type02-2"
                            // defaultChecked={!data?.usb_lock?.activation || false}
                            checked={data?.usb_lock?.activation ? false : true}
                            onChange={(e) => onChange("usb_lock", "activation", false)}
                            disabled={_U.getAuthWriteDisable(rolePermission)}
                        />
                        <label htmlFor="rad-tem-type02-2">{t("subtext.monitoringsettings.comparison.notification.not")}</label>
                    </div>
                    <div className="data-state">
                        <div className="col">
                            <button type="button" className="btn btn-tertiary" onClick={() => onApply("usb_lock")} disabled={!data?.usb_lockApply || _U.getAuthWriteDisable(rolePermission)}>
                                {t("player.button.apply")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <h2>{t("common.label.deference.settings.data")}</h2>
            <div className="alert-guide-txt">
                {t("subtext.desc.monitoringsettings.comparison.deferencesettings")}
                <br />
                {t("subtext.message.view.issueHistory")}
                <span>{t("subtext.device.message.mp.auto")}</span>
            </div>
            <div className="data-state">
                <div className="col">
                    <button type="button" className="btn btn-tertiary" onClick={onEdit} disabled={_U.getAuthWriteDisable(rolePermission)}>
                        {t("main.btnRegister.title")}
                    </button>
                </div>
                <div className="col">
                    <div className="total-count">
                        {t("common.total")} : <strong>{data?.difference_setting?.data?.length || 0}</strong>
                    </div>
                </div>
            </div>
            <div className="field field-type-table">
                <div className="field-form">
                    <div className="field-box">
                        <div className="table">
                            <div className="table-wrapper def-set-table">
                                <div className="table-box">
                                    <table>
                                        <caption>{t("common.label.deference.settings.data")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="text-block">{t("devicemanagement.tree.group")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("player.label.modelName")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.table.header.maindevicename")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("admin.label.registerationdate")}</div>
                                                </th>
                                                <th scope="col" className="width-8-percent">
                                                    <div className="text-block">{t("common.edit")}</div>
                                                </th>
                                                <th scope="col" className="width-8-percent">
                                                    <div className="text-block">{t("button.delete")}</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.difference_setting?.data?.map((item, index) => (
                                                <tr key={index}>
                                                    <td id={`comparison-td1-${index}`}>{item?.group?.hierarchy_group}</td>
                                                    <td id={`comparison-td2-${index}`}>{item?.model_name}</td>
                                                    <td id={`comparison-td3-${index}`}>{item?.main_device?.device_name}</td>
                                                    <td id={`comparison-td4-${index}`}>{item?.registration_date ? _U.getOffsetTime(auth, workspace, item?.registration_date) : ""}</td>
                                                    <td className="center width-8-percent">
                                                        <button
                                                            type="button"
                                                            id=""
                                                            className="btn btn-data-edit"
                                                            onClick={() => onEdit(item)}
                                                            aria-describedby={`comparison-td1-${index} comparison-td2-${index} comparison-td3-${index} comparison-td4-${index}`}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        >
                                                            {t("common.edit")}
                                                        </button>
                                                    </td>
                                                    <td className="center width-8-percent">
                                                        <button
                                                            type="button"
                                                            id=""
                                                            className="btn btn-data-delete"
                                                            onClick={() => onDelete(item)}
                                                            aria-describedby={`comparison-td1-${index} comparison-td2-${index} comparison-td3-${index} comparison-td4-${index}`}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        >
                                                            {t("button.delete")}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {(!data?.difference_setting?.data || data?.difference_setting?.data?.length === 0) && (
                                                <tr>
                                                    <td colSpan={6} className="align-center">
                                                        {t("common.label.noitems")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="convert-table-to-list">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="thead">{t("devicemanagement.tree.group")}</div>
                                    </div>
                                </div>
                                <ul className="table-body">
                                    {data?.difference_setting?.data?.map((item, index) => (
                                        <li className="table-row" key={`convert-table-${index}`}>
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell alias">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {item?.group?.hierarchy_group}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li>
                                                        <span className="field-label">{t("player.label.modelName")}</span>
                                                        <span className="field-content">{item?.model_name}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("common.table.header.maindevicename")}</span>
                                                        <span className="field-content">{item?.main_device?.device_name}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("admin.label.registerationdate")}</span>
                                                        <span className="field-content">{item?.registration_date ? _U.getOffsetTime(auth, workspace, item?.registration_date) : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("common.edit")}</span>
                                                        <span className="field-content">
                                                            <button type="button" id="" className="btn btn-data-edit" onClick={() => onEdit(item)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                                {t("common.edit")}
                                                            </button>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("button.delete")}</span>
                                                        <span className="field-content">
                                                            <button
                                                                type="button"
                                                                id=""
                                                                className="btn btn-data-delete"
                                                                onClick={() => onDelete(item)}
                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                            >
                                                                {t("button.delete")}
                                                            </button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                    {(!data?.difference_setting?.data || data?.difference_setting?.data?.length === 0) && (
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell alias">{t("common.label.noitems")}</div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ComparisonRegister = (props) => {
    const { t } = useTranslation();
    const { targetModel } = useContext(AppContext);
    const { info: infoProps } = props;
    const [info, setInfo] = useState({});

    const onClose = () => {
        console.log(info.model_name, targetModel.model_name, targetModel);
        if (targetModel.id && (info.model_name != targetModel.model_name || info.groupList?.map((m) => m.group_id)?.join("") != targetModel.groupList?.map((m) => m.group_id)?.join(""))) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("proxy.popup.exitdetail"),
                button: [t("popup.cancel.exit"), t("popup.cancel.saveExit")],
            }).then((id) => {
                if (id) {
                    props?.info?.onClick(targetModel);
                } else {
                    props?.info?.onClose && props?.info?.onClose();
                }
            });
        } else {
            props?.info?.onClose && props?.info?.onClose();
        }
    };

    useEffect(() => {
        infoProps && setInfo(infoProps);
    }, [infoProps]);

    return (
        <Modal
            title={props?.info?.title}
            id={props?.info?.id}
            className={props?.info?.className}
            // onClose={props?.info?.onClose}
            onClose={onClose}
            buttonOkTitle={props?.info?.okTitle}
            onClick={() => props?.info?.onClick(targetModel)}
        >
            <TargetModelnGroup
                targetInfo={{
                    ...info,
                    setTarget: true,
                    headLabel: "modelNgroup",
                    groupRadio: true,
                    model: true,
                    group: true,
                }}
            />
        </Modal>
    );
};

export default MonitoringSetting;
