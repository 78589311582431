import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import cx from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useCss from "@hook/useCss";
import validator from "validator";

import _U from "@util/utilities";

import Modal from "@component/UI/Modal";
import _API from "@util/api";
import Dialog from "@component/UI/Dialog";
import { AppContext } from "@component/AppProvider";
import _Device from "@util/devices";
import { SelectBox } from "@component/UI/Form";

export const EZVideoWall = (props) => {
    const { idx } = props;
    const { t } = useTranslation();
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);
    const [title, setTitle] = useState(false);
    const [natural, setNatural] = useState(false);
    const [data, setData] = useState([]);
    const [loadCnt, setLoadCnt] = useState(0);

    const onChangeTile = async () => {
        if (!title) {
            const feachres = [
                {
                    tileModeOn: {
                        data: {
                            value: true,
                            row: data?.filter((f) => f["tileMode"])[0]?.["tileMode"]?.data?.row,
                            column: data?.filter((f) => f["tileMode"])[0]?.["tileMode"]?.data?.column,
                        },
                    },
                },
            ];
            let res = await _API.onDeviceControl2([idx], feachres, saveInnerLoading, t);
            if (res && res.status === "true") {
                setTitle(!title);
            }
        } else {
            const feachres = [
                {
                    tileModeOff: {
                        data: {
                            value: false,
                        },
                    },
                },
            ];
            let res = await _API.onDeviceControl2([idx], feachres, saveInnerLoading, t);
            if (res && res.status === "true") {
                setTitle(!title);
            }
        }
    };
    const onChangeNatural = async () => {
        const feachres = [
            {
                naturalMode: {
                    data: {
                        value: !natural,
                    },
                },
            },
        ];
        let res = await _API.onDeviceControl2([idx], feachres, saveInnerLoading, t);
        if (res && res.status === "true") {
            setNatural(!natural);
        }
    };

    const onLoadData = () => {
        saveInnerLoading(true);
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: ["tileMode", "tileId", "naturalMode", "last_updated_date"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                setTitle(result?.filter((f) => f["tileMode"])[0]?.["tileMode"]?.data?.onOff || false);
                setNatural(result?.filter((f) => f["naturalMode"])[0]?.["naturalMode"]?.data?.value || false);
                setLoadCnt(loadCnt + 1);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
                setLoadCnt(loadCnt + 1);
            });
    };

    const changeDataRowColumn = async (key, value) => {
        const newData = JSON.parse(JSON.stringify(data));
        newData?.forEach((f) => {
            if (f["tileMode"]) {
                f["tileMode"]["data"][key] = value;
            }
        });

        const feachres = [
            {
                tileMode: {
                    data: {
                        onOff: title,
                        row: newData?.filter((f) => f["tileMode"])[0]?.["tileMode"]?.data?.row,
                        column: newData?.filter((f) => f["tileMode"])[0]?.["tileMode"]?.data?.column,
                    },
                },
            },
        ];
        let res = await _API.onDeviceControl2([idx], feachres, saveInnerLoading, t);
        if (res && res.status === "true") {
            setData(newData);
            onLoadData();
        }
    };

    const changeData = (key, value) => {
        setDeviceControl(key, value);
    };

    const setDeviceControl = async (key, value) => {
        const feachres = [
            {
                [key]: {
                    data: {
                        value: value,
                    },
                },
            },
        ];
        let res = await _API.onDeviceControl2([idx], feachres, saveInnerLoading, t);
        if (res && res.status === "true") {
            const newData = JSON.parse(JSON.stringify(data));
            newData.forEach((f) => {
                if (f[key]) {
                    f[key]["data"]["value"] = value;
                }
            });
            setData(newData);
            onLoadData();
        }
    };

    useEffect(() => {
        onLoadData();
    }, [idx, lastUpdateTime]);

    useEffect(() => {
        //rendering 완료 후 로딩 닫기
        loadCnt > 0 && saveInnerLoading(false);
    }, [loadCnt]);

    return (
        <Fragment>
            <div className="tab-panel panel-videowall" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        {_Device.getSupported("tileMode", data) && (
                            <div className="field field-flex field-no-ir-power-off">
                                <span className="field-label">{t("player.label.tileMode")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <div className="switch">
                                            <label htmlFor="default-switch-1" className="switch-controller">
                                                {t("player.label.tileMode")}
                                            </label>
                                            <input
                                                type="checkbox"
                                                id="default-switch-1"
                                                name="default-switch-1"
                                                role="switch"
                                                aria-checked="true"
                                                checked={title}
                                                onChange={() => onChangeTile()}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                            <span className="slider"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {title && _Device.getSupported("tileId", data) && (
                            <div className="field field-flex field-dpm">
                                <span className="field-label" id="tileIdLabel">
                                    {t("common.label.tileid.255")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="tileId"
                                            items={
                                                parseInt(_Device.getHexRangeValue(t, "tileMode", data, null, "row") || 0) * parseInt(_Device.getHexRangeValue(t, "tileMode", data, null, "column")) > 0
                                                    ? [
                                                          ...Array(
                                                              parseInt(_Device.getHexRangeValue(t, "tileMode", data, null, "row") || 0) *
                                                                  parseInt(_Device.getHexRangeValue(t, "tileMode", data, null, "column"))
                                                          ),
                                                      ]?.map((item, index) => ({ label: index + 1, value: index + 1 }))
                                                    : [...Array(225)]?.map((item, index) => ({ label: index + 1, value: index + 1 }))
                                            }
                                            value={_Device.getHexRangeValue(t, "tileId", data)}
                                            ariaLabelId={"tileIdLabel"}
                                            selectLabel={_Device.getHexRangeValue(t, "tileId", data)}
                                            onChange={(item) => changeData("tileId", item?.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {title && _Device.getSupported("tileMode", data) && (
                            <div className="field field-flex field-dpm">
                                <span className="field-label" id="tileModeRowLabel">
                                    {t("common.label.tilerow.15")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="tileModeRow"
                                            items={
                                                parseInt(_Device.getHexRangeValue(t, "tileMode", data, null, "column") || 0) === 1
                                                    ? [...Array(14)]?.map((item, index) => ({ label: index + 2, value: index + 2 }))
                                                    : [...Array(15)]?.map((item, index) => ({ label: index + 1, value: index + 1 }))
                                            }
                                            value={_Device.getHexRangeValue(t, "tileMode", data, null, "row")}
                                            ariaLabelId={"tileModeRowLabel"}
                                            selectLabel={_Device.getHexRangeValue(t, "tileMode", data, null, "row")}
                                            onChange={(item) => changeDataRowColumn("row", item?.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {title && _Device.getSupported("tileMode", data) && (
                            <div className="field field-flex field-dpm">
                                <span className="field-label" id="tileModeColumnLabel">
                                    {t("common.label.tilecolumn.15")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="tileModeColumn"
                                            items={
                                                parseInt(_Device.getHexRangeValue(t, "tileMode", data, null, "row") || 0) === 1
                                                    ? [...Array(14)]?.map((item, index) => ({ label: index + 2, value: index + 2 }))
                                                    : [...Array(15)]?.map((item, index) => ({ label: index + 1, value: index + 1 }))
                                            }
                                            value={_Device.getHexRangeValue(t, "tileMode", data, null, "column")}
                                            ariaLabelId={"tileModeColumnLabel"}
                                            selectLabel={_Device.getHexRangeValue(t, "tileMode", data, null, "column")}
                                            onChange={(item) => changeDataRowColumn("column", item?.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {title && _Device.getSupported("naturalMode", data) && (
                            <div className="field field-flex field-no-ir-power-off">
                                <span className="field-label">{t("dv.videowall.naturalMode")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <div className="switch">
                                            <label htmlFor="default-switch-2" className="switch-controller">
                                                {t("dv.videowall.naturalMode")}
                                            </label>
                                            <input
                                                type="checkbox"
                                                id="default-switch-2"
                                                name="default-switch-2"
                                                role="switch"
                                                aria-checked="true"
                                                checked={natural}
                                                onChange={() => onChangeNatural()}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                            <span className="slider"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export const EZSi = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);
    const [autoSet, setAutoSet] = useState(false);
    const [proxyModal, setProxyModal] = useState(false);
    const [data, setData] = useState([]);
    const [oriData, setOriData] = useState();

    const onLoadData = () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: ["siServerSetting", "siServerSettingReset", "__proxySupport", "__proxyBypassList", "last_updated_date"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                setOriData(result);
                setAutoSet(result?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.autoSet || false);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    const changeData = (pKey, key, value) => {
        setDeviceControl(pKey, key, value);
    };

    const changeDataOnOffReverse = (pKey, key) => {
        const value = data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.autoSet || false;
        setDeviceControl(pKey, key, !value);
    };

    const setDeviceControl = (pKey, key, value) => {
        if (key === "domainName" && (value || value == 0)) {
            if (value.length > 512) {
                value = value.substring(0, 512);
            }
        }
        const newData = JSON.parse(JSON.stringify(data));
        newData.forEach((f) => {
            if (f[pKey]) {
                f[pKey]["data"][key] = value;
            }
        });
        setData(newData);
    };

    const changeDataProxy = (proxy) => {
        setProxyModal(false);
        setData(proxy);
    };

    const onApply = async () => {
        const domain = data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.domainName || "";
        if (domain && validator.isURL(domain)) {
            const features = data?.filter((f) => f["siServerSetting"])?.map((m) => ({ siServerSetting: { data: m.siServerSetting.data } }));
            delete features[0].siServerSetting.data.proxyExceptionAddressAdd;
            let res = await _API.onDeviceControl2([idx], features, saveInnerLoading, t);
            if (!res || res.status !== "true") {
                const newData = JSON.parse(JSON.stringify(oriData));
                setData(newData);
            }
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("dv.url.invalid"),
                button: t("common.ok"),
            }).then((id) => {});
        }
    };

    const onReset = () => {
        Dialog({
            title: t("main.settingsPage.reset"),
            text: t("device.label.reset"),
            button: [t("button.cancel"), t("main.settingsPage.reset")],
        }).then(async (id) => {
            if (id) {
                const features = [{ siServerSettingReset: { data: null } }];
                let res = await _API.onDeviceControl2([idx], features, saveInnerLoading, t);
                if (res && res.status === "true") {
                    onLoadData();
                }
            }
        });
    };

    useEffect(() => {
        onLoadData();
        resizeTable();
    }, [idx, lastUpdateTime]);

    return (
        <Fragment>
            <div className="tab-panel panel-si-server-setting" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    {_Device.getSupported("siServerSetting", data) && (
                        <div className="list-box aligned-list-box">
                            <div className="field field-flex">
                                <span className="field-label" id="domainName">
                                    {t("device.label.domainname")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <input
                                            type="text"
                                            className="input-txt"
                                            placeholder="https://"
                                            maxLength={512}
                                            value={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.domainName || ""}
                                            onChange={(e) => changeData("siServerSetting", "domainName", e.target.value)}
                                            // onPaste={(e) => {
                                            //     e.preventDefault();
                                            //     return false;
                                            // }}
                                            aria-labelledby="domainName"
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="field field-flex">
                                <span className="field-label" id="applicationmodeLabel">
                                    {t("device.label.applicationmode")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <ul role="radiogroup" aria-labelledby="applicationmodeLabel">
                                            <li className="radio">
                                                <input
                                                    type="radio"
                                                    name="rad-tem-type01"
                                                    id="rad-tem-type01-1"
                                                    checked={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationLaunchMode == "local"}
                                                    onChange={(e) => changeData("siServerSetting", "applicationLaunchMode", "local")}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                                <label htmlFor="rad-tem-type01-1">{t("device.label.local")}</label>
                                            </li>
                                            <li className="radio">
                                                <input
                                                    type="radio"
                                                    name="rad-tem-type01"
                                                    id="rad-tem-type01-2"
                                                    checked={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationLaunchMode == "remote"}
                                                    onChange={(e) => changeData("siServerSetting", "applicationLaunchMode", "remote")}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                                <label htmlFor="rad-tem-type01-2">{t("device.label.remote")}</label>
                                            </li>
                                            <li className="radio">
                                                <input
                                                    type="radio"
                                                    name="rad-tem-type01"
                                                    id="rad-tem-type01-3"
                                                    checked={
                                                        !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationLaunchMode ||
                                                        data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationLaunchMode == "none"
                                                    }
                                                    onChange={(e) => changeData("siServerSetting", "applicationLaunchMode", "none")}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                                <label htmlFor="rad-tem-type01-3">{t("troubleNotice.label.none")}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="field field-flex">
                                <span className="field-label" id="applicationtypeLabel">
                                    {t("device.label.applicationtype")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <ul role="radiogroup" aria-labelledby="applicationtypeLabel">
                                            <li className="radio">
                                                <input
                                                    type="radio"
                                                    name="rad-tem-type02"
                                                    id="rad-tem-type02-1"
                                                    checked={
                                                        !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationType ||
                                                        data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationType == "zip"
                                                    }
                                                    onChange={(e) => changeData("siServerSetting", "applicationType", "zip")}
                                                    disabled={
                                                        data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationLaunchMode !== "local" ||
                                                        _U.getAuthWriteDisable(rolePermission)
                                                    }
                                                />
                                                <label htmlFor="rad-tem-type02-1">{t("common.code.applicationtype.zip")}</label>
                                            </li>
                                            <li className="radio">
                                                <input
                                                    type="radio"
                                                    name="rad-tem-type02"
                                                    id="rad-tem-type02-2"
                                                    checked={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationType == "ipk"}
                                                    onChange={(e) => changeData("siServerSetting", "applicationType", "ipk")}
                                                    disabled={
                                                        data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.applicationLaunchMode !== "local" ||
                                                        _U.getAuthWriteDisable(rolePermission)
                                                    }
                                                />
                                                <label htmlFor="rad-tem-type02-2">{t("common.code.applicationtype.ipk")}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="field field-flex">
                                <span className="field-label">{t("device.label.autoset")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <div className="switch">
                                            <label htmlFor="default-switch-3" className="switch-controller">
                                                {t("device.label.autoset")}
                                            </label>
                                            <input
                                                type="checkbox"
                                                id="default-switch-3"
                                                name="default-switch-3"
                                                role="switch"
                                                aria-checked="true"
                                                checked={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.autoSet || false}
                                                onChange={() => changeDataOnOffReverse("siServerSetting", "autoSet")}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                            <span className="slider"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field field-flex field-reboot">
                                <span className="field-label">{t("dv.serverSetting.proxy")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-reboot" onClick={() => setProxyModal(true)}>
                                            {t("common.setup")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="field field-flex field-reboot">
                                <span className="field-label">{t("player.button.apply")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-reboot" onClick={onApply} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("player.button.apply")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="field field-flex field-reboot">
                                <span className="field-label">{t("main.settingsPage.reset")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-reboot" onClick={onReset} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("main.settingsPage.reset")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {proxyModal && <ProxyModal data={data} onChangeDone={changeDataProxy} onClose={() => setProxyModal(false)} />}
        </Fragment>
    );
};

const ProxyModal = (props) => {
    const { t } = useTranslation();
    const { data: dataProps, onChangeDone } = props;
    const { saveInnerLoading, rolePermission } = useContext(AppContext);
    const [data, setData] = useState([]);

    const onClickDone = () => {
        // if (
        //     data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff &&
        //     ((data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyPortNumber != "0" &&
        //         (!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyPortNumber ||
        //             _U.getOnlyNumber(data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyPortNumber).length === 0)) ||
        //         !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyName ||
        //         !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyPassword)
        // ) {
        //     Dialog({
        //         title: t("popup.title.inform"),
        //         text: t("common.message.no.required.value"),
        //         button: t("common.ok"),
        //     }).then((id) => {});
        // } else {
        if (
            data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerIp &&
            _U.checkIpForm(data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerIp)
        ) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("dv.ip.invalid"),
                button: t("common.ok"),
            }).then((id) => {});
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.apply"),
                button: t("common.ok"),
            }).then((id) => {
                const newData = JSON.parse(JSON.stringify(data));
                if (!newData?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerIp) {
                    newData?.forEach((f) => {
                        if (f["siServerSetting"]) {
                            f["siServerSetting"]["data"]["proxyServerIp"] = "0.0.0.0";
                        }
                    });
                }
                if (!newData?.filter((f) => f["siServerSetting"])?.[0]?.["siServerSetting"]?.data?.proxyExceptionAddress) {
                    newData?.forEach((f) => {
                        if (f["siServerSetting"]) {
                            f["siServerSetting"]["data"]["proxyExceptionAddress"] = [];
                        }
                    });
                }
                onChangeDone(newData);
            });
        }
        // }
    };

    const onClickCancel = () => {
        if (JSON.stringify(data) != JSON.stringify(dataProps)) {
            Dialog({
                title: t("button.cancel"),
                text: t("subtext.message.leavepage"),
                button: [t("common.button.stay"), t("common.button.leave")],
            }).then((id) => {
                if (id) {
                    props.onClose();
                } else {
                }
            });
        } else {
            props.onClose();
        }
    };

    const onAdd = () => {
        const addData = data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddressAdd || "";
        if (addData) {
            if (data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data["proxyExceptionAddress"]?.length >= 20) {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.error.limit.item", { n: 20 }),
                    button: t("common.ok"),
                }).then((id) => {
                    if (id) {
                    }
                });
            } else {
                if (validator.isURL(addData)) {
                    const newData = JSON.parse(JSON.stringify(data));
                    newData.forEach((f) => {
                        if (f["siServerSetting"]) {
                            if (f["siServerSetting"]["data"]["proxyExceptionAddress"]) {
                                f["siServerSetting"]["data"]["proxyExceptionAddress"].push(addData);
                            } else {
                                f["siServerSetting"]["data"]["proxyExceptionAddress"] = [addData];
                            }
                            f["siServerSetting"]["data"]["proxyExceptionAddressAdd"] = "";
                        }
                    });
                    setData(newData);
                } else {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("dv.url.invalid"),
                        button: t("common.ok"),
                    }).then((id) => {});
                }
            }
        }
    };

    const onDeleteAll = () => {
        Dialog({
            title: t("player.button.deleteAll"),
            text: t("device.msg.deleteallitems"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                const newData = JSON.parse(JSON.stringify(data));
                newData.forEach((f) => {
                    if (f["siServerSetting"]) {
                        f["siServerSetting"]["data"]["proxyExceptionAddress"] = [];
                    }
                });
                setData(newData);
            }
        });
    };

    const onDelete = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("device.msg.deleteitem"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                const newData = JSON.parse(JSON.stringify(data));
                newData.forEach((f) => {
                    if (f["siServerSetting"]) {
                        f["siServerSetting"]["data"]["proxyExceptionAddress"] = f["siServerSetting"]["data"]["proxyExceptionAddress"]?.filter((f) => f != item);
                    }
                });
                setData(newData);
            }
        });
    };

    const changeData = (pKey, key, value) => {
        if (key === "proxyPortNumber" && (value || value == 0)) {
            value = parseInt(_U.getOnlyNumber(value) || 0);
            if (value > 65535) {
                value = 65535;
            }
        } else if (key === "proxyName" && (value || value == 0)) {
            if (value.length > 512) {
                value = value.substring(0, 512);
            }
        } else if (key === "proxyServerIp" && (value || value == 0)) {
            value = _U.getOnlyNumberDot(value);
        } else if (key === "proxyPassword" && (value || value == 0)) {
            if (value.length > 27) {
                value = value.substring(0, 27);
            }
        } else if (key === "proxyExceptionAddressAdd" && value?.length > 64) {
            value = value.substring(0, 64);
        }
        setDeviceControl(pKey, key, value);
    };

    const changeDataOnOffReverse = (pKey, key) => {
        const value = data?.filter((f) => f[pKey])[0]?.[pKey]?.data[key] || false;
        setDeviceControl(pKey, key, !value);
    };

    const setDeviceControl = (pKey, key, value) => {
        const newData = JSON.parse(JSON.stringify(data));
        newData.forEach((f) => {
            if (f[pKey]) {
                f[pKey]["data"][key] = value;
            }
        });
        setData(newData);
    };

    useEffect(() => {
        setData(dataProps);
    }, [dataProps]);

    return (
        <Modal title={t("common.label.title.proxy.server.settings")} id="popup-select-file" className="popup-proxy-server-settings popup-type" onClose={onClickCancel} onClick={onClickDone}>
            <div className="field field-type-input field-unique-class">
                <label htmlFor="proxy-server-onoff" className="field-label pop-label">
                    {t("dv.serverSetting.proxy")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="switch">
                            <label htmlFor="proxy-server-onoff" className="switch-controller">
                                {t("dv.serverSetting.proxy")}
                            </label>
                            <input
                                type="checkbox"
                                id="proxy-server-onoff"
                                name="title-mode"
                                role="switch"
                                aria-checked={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || false}
                                checked={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || false}
                                onChange={() => changeDataOnOffReverse("siServerSetting", "proxyServerOnOff")}
                                disabled={_U.getAuthWriteDisable(rolePermission)}
                            />
                            <span className="slider"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field field-type-input field-unique-class">
                <label htmlFor="proxy-server-ip" className="field-label pop-label">
                    {t("device.label.proxyserverip")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="input-box pop-input">
                            <input
                                type="text"
                                id="proxy-server-ip"
                                name="proxy-server-ip"
                                placeholder="0.0.0.0"
                                autoComplete="false"
                                maxLength={15}
                                value={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerIp || ""}
                                onChange={(e) => changeData("siServerSetting", "proxyServerIp", e.target.value)}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            />
                            <button
                                type="button"
                                className={cx({ "btn-input-remove": true, hide: !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerIp })}
                                onClick={() => changeData("siServerSetting", "proxyServerIp", "")}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            >
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field field-type-input field-unique-class">
                <label htmlFor="port-number" className="field-label pop-label">
                    {t("device.label.portnum")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="input-box pop-input">
                            <input
                                type="text"
                                id="port-number"
                                name="port-number"
                                autoComplete="false"
                                value={
                                    data?.filter((f) => f["siServerSetting"])?.[0]?.["siServerSetting"]?.data?.proxyPortNumber ||
                                    data?.filter((f) => f["siServerSetting"])?.[0]?.["siServerSetting"]?.data?.proxyPortNumber == 0
                                        ? data?.filter((f) => f["siServerSetting"])?.[0]?.["siServerSetting"]?.data?.proxyPortNumber
                                        : ""
                                }
                                onChange={(e) => changeData("siServerSetting", "proxyPortNumber", e.target.value)}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            />
                            {/* <button
                                type="button"
                                className={cx({ "btn-input-remove": true, hide: !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyPortNumber })}
                                onClick={() => changeData("siServerSetting", "proxyPortNumber", "")}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            >
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="field field-type-input field-unique-class">
                <label htmlFor="name" className="field-label pop-label">
                    {t("common.name")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="input-box pop-input">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                                autoComplete="false"
                                maxLength={512}
                                value={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyName || ""}
                                onChange={(e) => changeData("siServerSetting", "proxyName", e.target.value)}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            />
                            <button
                                type="button"
                                className={cx({ "btn-input-remove": true, hide: !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyName })}
                                onClick={() => changeData("siServerSetting", "proxyName", "")}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            >
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field field-type-input field-unique-class">
                <label htmlFor="password" className="field-label pop-label">
                    {t("common.passwd")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="input-box pop-input">
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                autoComplete="false"
                                maxLength={27}
                                value={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyPassword || ""}
                                onChange={(e) => changeData("siServerSetting", "proxyPassword", e.target.value)}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            />
                            <button
                                type="button"
                                className={cx({ "btn-input-remove": true, hide: !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyPassword })}
                                onClick={() => changeData("siServerSetting", "proxyPassword", "")}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            >
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field field-type-input field-exceptions-address">
                <label htmlFor="exceptions-address" className="field-label">
                    {t("device.label.exceptionaddress")}
                </label>
                <div className="field-form">
                    <div className="field-box http-box">
                        <div className="input-box pop-input">
                            <input
                                type="text"
                                id="exceptions-address"
                                name="exceptions-address"
                                placeholder="https://"
                                autoComplete="false"
                                value={data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddressAdd || ""}
                                onChange={(e) => changeData("siServerSetting", "proxyExceptionAddressAdd", e.target.value)}
                                maxLength={64}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            />
                            <button
                                type="button"
                                className={cx({ "btn-input-remove": true, hide: !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddressAdd })}
                                onClick={() => changeData("siServerSetting", "proxyExceptionAddressAdd", "")}
                                disabled={!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)}
                            >
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                        <button
                            type="button"
                            className="btn btn-Add"
                            onClick={onAdd}
                            disabled={
                                !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddressAdd ||
                                !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff ||
                                _U.getAuthWriteDisable(rolePermission)
                            }
                        >
                            {t("common.add")}
                        </button>
                    </div>
                    <div className="table">
                        <div className="table-wrapper proxy-table">
                            <div className="table-box">
                                <table>
                                    <caption>{t("device.label.exceptionslist")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">{t("table.head.no")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("device.label.sites")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">
                                                    <button
                                                        type="button"
                                                        className="btn btn-small"
                                                        onClick={onDeleteAll}
                                                        disabled={
                                                            _U.getAuthWriteDisable(rolePermission) ||
                                                            !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff ||
                                                            !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddress ||
                                                            data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddress?.length === 0
                                                        }
                                                    >
                                                        {t("player.button.deleteAll")}
                                                    </button>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data
                                            ?.filter((f) => f["siServerSetting"])[0]
                                            ?.["siServerSetting"]?.data?.proxyExceptionAddress?.map((item, index) => (
                                                <tr key={index}>
                                                    <td id={`siServerSetting-td1-${index}`}>{index + 1}</td>
                                                    <td className="align-left" id={`siServerSetting-td2-${index}`}>
                                                        {item}
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            id="btn-delete-1"
                                                            className="btn btn-delete btn-small"
                                                            onClick={() => onDelete(item)}
                                                            disabled={
                                                                !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)
                                                            }
                                                            aria-describedby={`siServerSetting-td1-${index} siServerSetting-td2-${index}`}
                                                        >
                                                            {t("button.delete")}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        {(!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddress ||
                                            data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddress?.length === 0) && (
                                            <tr>
                                                <td colSpan={3} className="align-center">
                                                    {t("common.label.noitems")}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">{t("table.head.no")}</div>
                                    <div className="thead">{t("device.label.sites")}</div>
                                </div>
                            </div>
                            <ul className="table-body">
                                {data
                                    ?.filter((f) => f["siServerSetting"])[0]
                                    ?.["siServerSetting"]?.data?.proxyExceptionAddress?.map((item, index) => (
                                        <li className="table-row" key={index}>
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell number center">{index + 1}</div>
                                                    <div className="table-cell alias">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                            {item}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li>
                                                        <span
                                                            className="field-label"
                                                            onClick={() => onDelete(item)}
                                                            disabled={
                                                                !data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyServerOnOff || _U.getAuthWriteDisable(rolePermission)
                                                            }
                                                        >
                                                            {t("button.delete")}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                {(!data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddress ||
                                    data?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.proxyExceptionAddress?.length === 0) && (
                                    <li className="table-row">{t("common.label.noitems")}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const EZFail = (props) => {
    const { t } = useTranslation();
    const { idx, deviceInfo } = props;
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);

    const [data, setData] = useState([]);
    const [editModal, setEditModal] = useState(false);

    const [failOver, setFailOver] = useState(false);
    const [playBackup, setPlayBackup] = useState(false);
    const [failOverData, setFailOverData] = useState();
    //     {
    //     failoverPriority1: {
    //         key: "hdmi1",
    //         name: "HDMI 1",
    //     },
    //     failoverPriority2: {
    //         key: "hdmi2",
    //         name: "HDMI 2",
    //     },
    //     failoverPriority3: {
    //         key: "hdmi3",
    //         name: "HDMI 3",
    //     },
    //     failoverPriority4: {
    //         key: "hdmi4",
    //         name: "DISPLAYPORT",
    //     },
    //     failover: "manual",
    //     backupViaStorage: "siapp",
    //     backupViaStorageInterval: 60,
    //     backupViaStorageFile: "/mnt/lg/appstore/signage/.failover/test.png",
    //     failoverCount: 4,
    //     device_list: [
    //         {
    //             deviceId: "INTERNAL_STORAGE_SIGNAGE",
    //             deviceUri: "/mnt/lg/appstore/signage",
    //             deviceType: "internal signage",
    //             deviceName: "SIGNAGE_INTERNAL STORAGE",
    //             content_list: [
    //                 {
    //                     size: 446238,
    //                     itemName: "16_9.jpg",
    //                     itemType: "image",
    //                     itemPath: "/mnt/lg/appstore/signage/16_9.jpg",
    //                     thumbnailUri: "/mnt/lg/appstore/signage/16_9.jpg",
    //                 },
    //             ],
    //         },
    //         {
    //             deviceId: "INTERNAL_STORAGE_WELCOMEVIDEO",
    //             deviceUri: "/mnt/lg/appstore/welcomevideo",
    //             deviceType: "internal welcomevideo",
    //             deviceName: "WELCOMEVIDEO_INTERNAL STORAGE",
    //             content_list: [],
    //         },
    //         {
    //             deviceId: "INTERNAL_STORAGE_HOTELVIDEO",
    //             deviceUri: "/mnt/lg/appstore/hotelvideo",
    //             deviceType: "internal hotelvideo",
    //             deviceName: "HOTELVIDEO_INTERNAL STORAGE",
    //             content_list: [],
    //         },
    //     ],
    // }
    const [storageDeviceList, setStorageDeviceList] = useState([]);
    const [backupMediaFileList, setBackupMediaFileList] = useState([]);
    const fRef = useRef(null);
    // const [fileInfo, setFileInfo] = useState(null);
    const [file, setFile] = useState(null);
    const [viewPreview, setViewPreview] = useState(false);

    const onLoadData = async () => {
        try {
            const res = await _API.post({
                path: `/device/devices/read`,
                data: {
                    device_id: `${idx}`,
                    features: [
                        "failOver",
                        "failOverReset",
                        "__failOverUpload",
                        "__failOverPreview",
                        "__failOverPreviewCancel",
                        "__backupViaStorage",
                        "__siAppPlayViaURL",
                        "__autoCaptureImage",
                        "__inputPriority",
                        "__contentSource",
                        "__captureTimeInterval",
                    ],
                },
            });

            const result = res?.data?.result;
            setData(result);

            const nFailover = result?.filter((f) => f["failOver"])[0]?.["failOver"]?.data || {};
            if (nFailover?.failover !== "off") {
                setFailOver(true);
            } else {
                setFailOver(false);
            }
            if (nFailover?.backupViaStorage !== "off") {
                setPlayBackup(true);
            } else {
                setPlayBackup(false);
            }

            const sDeviceList = [{ label: t("device.label.cloudtostorage"), value: "cloudtostorage" }];
            if (nFailover?.deviceList?.length > 0) {
                sDeviceList.push(...nFailover?.deviceList?.map((m) => ({ ...m, label: m.deviceName, value: m.deviceId })));
            }
            setStorageDeviceList(sDeviceList);

            nFailover["storageDevice"] = sDeviceList[0];
            const list = await setCloudBackupMediaFileList();
            if (list && list.length > 0) {
                nFailover.backupMediaFile = list[0];
            }
            setFailOverData(nFailover);
        } catch (e) {
            console.log(`api error /device/devices/read ${idx}`, e);
        }
    };

    const changeData = async (key, value) => {
        const newData = JSON.parse(JSON.stringify(failOverData));
        newData[key] = value;

        if (key === "storageDevice") {
            newData.backupMediaFile = null;
            if (value.value === "cloudtostorage") {
                const list = await setCloudBackupMediaFileList();
                if (list && list.length > 0) {
                    newData.backupMediaFile = list[0];
                }
            } else {
                let dFileList = [];
                if (failOverData?.deviceList?.length > 0 && deviceInfo?.device_sub_type?.toUpperCase() === "OLED") {
                    for (let m of failOverData?.deviceList) {
                        if (m) {
                            const imageTypes = ["JPEG", "JPG", "PNG", "BMP", "GIF", "RAW"];
                            const ext = m?.itemPath?.substring(m?.itemPath?.lastIndexOf(".") + 1);
                            if (!imageTypes.includes(ext)) {
                                dFileList.push({ ...m, label: decodeURIComponent(m.itemName), value: m.itemPath });
                            }
                        }
                    }
                } else {
                    dFileList = failOverData?.deviceList?.filter((f) => f.deviceId === value.value)[0]?.content_list?.map((m) => ({ ...m, label: decodeURIComponent(m.itemName), value: m.itemPath }));
                }
                if (dFileList?.length > 0) {
                    setBackupMediaFileList(dFileList);
                    newData.backupMediaFile = dFileList[0];
                } else {
                    setBackupMediaFileList([]);
                }
            }
        } else if (key === "backupMediaFile") {
            setViewPreview(false);
        }
        setFailOverData(newData);
    };

    const setCloudBackupMediaFileList = async () => {
        try {
            const res = await _API.get({
                path: `/device/devices/files/lgcc_failover/${deviceInfo?.model_name}`,
                data: {},
            });
            const result = res?.data?.result?.Contents;
            if (result?.length > 0) {
                let list = [];
                if (deviceInfo?.device_sub_type?.toUpperCase() === "OLED") {
                    for (let m of result) {
                        if (m && m.Key) {
                            const imageTypes = ["JPEG", "JPG", "PNG", "BMP", "GIF", "RAW"];
                            const ext = m?.Key?.substring(m?.Key?.lastIndexOf(".") + 1);
                            if (!imageTypes.includes(ext)) {
                                list.push({ ...m, label: decodeURIComponent(m.Key.substring(m.Key.lastIndexOf("/") + 1)), value: m.Key });
                            }
                        }
                    }
                } else {
                    list = result.map((m) => ({ ...m, label: decodeURIComponent(m.Key.substring(m.Key.lastIndexOf("/") + 1)), value: m.Key }));
                }
                setBackupMediaFileList(list);
                return list;
            } else {
                setBackupMediaFileList([]);
            }
        } catch (e) {
            console.log(`api error /device/devices/read ${idx}`, e);
            setBackupMediaFileList([]);
        }
        return [];
    };

    const onApply = async () => {
        const params = [
            {
                failOver: {
                    data: {
                        priority1: failOverData?.failoverPriority1 || null,
                        priority2: failOverData?.failoverPriority2 || null,
                        priority3: failOverData?.failoverPriority3 || null,
                        priority4: failOverData?.failoverPriority4 || null,
                        priority5: failOverData?.failoverPriority5 || null,
                        priority6: failOverData?.failoverPriority6 || null,
                        onOff: failOver === false ? "off" : failOverData?.failover,
                        backupViaStorage: failOver ? failOverData?.backupViaStorage : "off",
                        captureTimeInterval: !failOver || !failOverData?.backupViaStorage || failOverData?.backupViaStorage === "off" ? null : failOverData?.backupViaStorageInterval,
                        backupMedia: !failOver || !failOverData?.backupViaStorage || failOverData?.backupViaStorage === "off" ? null : failOverData?.backupMedia || null,
                    },
                },
            },
        ];
        let res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
    };

    const onReset = () => {
        Dialog({
            title: t("main.settingsPage.reset"),
            text: t("device.label.reset"),
            button: [t("button.cancel"), t("main.settingsPage.reset")],
        }).then(async (id) => {
            if (id) {
                const params = [{ failOverReset: { data: "" } }];
                let res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                if (res && res.status === "true") {
                    onLoadData();
                }
            }
        });
    };

    const fileSelected = (e) => {
        if (e?.target?.files?.length > 0) {
            const checkFile = e?.target?.files[0];
            const oledTypes = ["MP4", "AVI", "WMV", "MPEG", "MKV", "MOV", "WEBM", "3GPP"];
            // const acceptFileFormat = ["JPEG", "JPG", "PNG", "BMP", "GIF", "RAW", "MP4", "AVI", "WMV", "MPEG", "MKV", "MOV", "WEBM", "3GPP"];
            const acceptFileFormat =
                deviceInfo?.device_sub_type?.toUpperCase() === "OLED" ? oledTypes : ["JPEG", "JPG", "JPE", "PNG", "BMP", "MP4", "AVI", "WMV", "MPEG", "MKV", "MOV", "WEBM", "3GPP"];
            const fileName = checkFile?.name;
            const ext = fileName?.split(".")?.pop()?.toUpperCase();
            if (!acceptFileFormat.includes(ext)) {
                //
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("content.msg.fileformat"),
                    button: t("common.ok"),
                }).then((id) => {
                    if (id) {
                    }
                });
            } else {
                const check = _U.checkFileName(e?.target?.files[0]?.name);
                if (check === -2) {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.over100.filename"),
                        button: t("common.ok"),
                    }).then((id) => {});
                } else if (check === -1) {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.NoSpecialCharacters"),
                        button: t("common.ok"),
                    }).then((id) => {});
                } else {
                    setFile(checkFile);
                }
            }
        }
    };

    const upload = () => {
        if (file) {
            let info = { object_key: null, upload_url: null };
            const fileName = file.name;
            saveInnerLoading(true);
            _API.post({ path: `/device/devices/files/lgcc_failover/${deviceInfo?.model_name}/${fileName}`, data: {} })
                .then((res) => {
                    info.object_key = res?.data?.result?.object_key;
                    info.upload_url = res?.data?.result?.upload_url;
                    _API.uploadS3File({ url: info.upload_url, file: file, type: file.type })
                        .then((rs) => {
                            // setFileInfo({ fileName, object_key: info.object_key, url: info.upload_url });
                            setCloudBackupMediaFileList();
                            saveInnerLoading(false);
                            Dialog({
                                title: t("popup.title.inform"),
                                text: t("dv.failover.uploadCloud"),
                                button: t("common.ok"),
                            }).then((id) => {});
                        })
                        .catch((e) => {
                            console.log("file upload fail", e?.response);
                            saveInnerLoading(false);
                        });
                })
                .catch((e) => {
                    console.log("api error /device/devices/auto-cloning", e?.response);
                    saveInnerLoading(false);
                });
        }
    };

    const backupMediaFileControl = async (key) => {
        // const imagTypes = ["JPEG", "JPG", "PNG", "BMP", "GIF", "RAW"];
        const imagTypes = ["JPEG", "JPG", "JPE", "PNG", "BMP"];
        if (key === "file") {
            if (failOverData?.backupMediaFile && failOverData?.storageDevice?.value === "cloudtostorage") {
                //cloud
                _API.post({ path: `/device/devices/files/lgcc_failover/${deviceInfo?.model_name}`, data: { objectKey: failOverData?.backupMediaFile?.value } })
                    .then(async (res) => {
                        const source = `${res?.data?.result?.url}`;

                        const ext = failOverData?.backupMediaFile?.label.substring(failOverData?.backupMediaFile?.label.lastIndexOf(".") + 1);
                        const fileType = imagTypes.includes(ext?.toUpperCase()) ? "image" : "video";
                        const params = [{ failOverBackupMediaCloud: { data: { itemName: failOverData?.backupMediaFile?.label, source, type: fileType } } }];
                        let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                        if (controlRes && controlRes.status === "true") {
                            onFileUploadSuccess();
                        }
                    })
                    .catch((e) => {
                        console.log("api error /device/devices/lgcc_failover", e?.response);
                    });
            } else if (failOverData?.backupMediaFile) {
                //local
                const params = [
                    {
                        failOverBackupMediaLocal: {
                            data: {
                                sourceDeviceId: failOverData?.storageDevice?.deviceId,
                                sourceSubDeviceId: failOverData?.storageDevice?.subDevices ? failOverData?.storageDevice?.subDevices[0]?.deviceId : "",
                                sourcePath: failOverData?.backupMediaFile?.itemPath,
                                sourceFile: failOverData?.backupMediaFile?.itemName,
                                itemType: failOverData?.backupMediaFile?.itemType,
                            },
                        },
                    },
                ];
                let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                if (controlRes && controlRes.status === "true") {
                    onFileUploadSuccess();
                }
            }
        } else if (key === "failOverPreview") {
            //TODO cloud경우 item type을 어떻게 입력 하는지 확인 필요 : 일단 이미지만 알고 있어 필터링
            const ext = failOverData?.backupMediaFile?.label.substring(failOverData?.backupMediaFile?.label.lastIndexOf(".") + 1);
            const fileType = failOverData?.backupMediaFile?.itemType ? failOverData?.backupMediaFile?.itemType : imagTypes.includes(ext?.toUpperCase()) ? "image" : "video";
            const params = [{ failOverPreview: { data: { source: failOverData?.backupMediaFile?.label, type: fileType || "" } } }];
            let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        } else {
            const params = [{ failOverPreviewCancel: { data: "" } }];
            let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
    };

    const onFileUploadSuccess = (e) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("dv.failover.upload"),
            button: t("common.ok"),
        }).then((id) => {
            setViewPreview(true);
        });
    };

    const delteBackupMediaFile = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("dv.failover.delete"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                if (item && failOverData?.storageDevice?.value === "cloudtostorage") {
                    const params = { key: item?.value };
                    _API.delete({ path: `/device/devices/files`, data: params })
                        .then((res) => {
                            setCloudBackupMediaFileList();
                        })
                        .catch((e) => {
                            console.log("api error /device/devices/control", e?.response);
                        });
                }
            }
        });
    };

    const editInputPriority = (priorityList) => {
        const newData = JSON.parse(JSON.stringify(failOverData));
        for (let i = 0; i < 6; i++) {
            if (i < priorityList?.length) {
                const item = priorityList[i];
                newData[`failoverPriority${i + 1}`] = item;
            } else {
                newData[`failoverPriority${i + 1}`] = null;
            }
        }
        setFailOverData(newData);
    };

    useEffect(() => {
        onLoadData();
        resizeTable();
    }, [idx, lastUpdateTime]);

    useEffect(() => {
        if (file) {
            upload();
        }
    }, [file]);

    return (
        <Fragment>
            <div className="tab-panel panel-fail-over" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        {_Device.getSupported("failOver", data) && (
                            <Fragment>
                                <div className="field field-flex field-no-ir-power-off">
                                    <span className="field-label">{t("device.label.failover")}</span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="switch">
                                                <label htmlFor="default-switch-4" className="switch-controller">
                                                    {t("device.label.failover")}
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    id="default-switch-4"
                                                    name="default-switch-4"
                                                    role="switch"
                                                    aria-checked="true"
                                                    checked={failOver}
                                                    onChange={() => {
                                                        setFailOver(!failOver);
                                                        if (!failOver && failOverData?.failover === "off") {
                                                            changeData("failover", "auto");
                                                        }
                                                        if (failOver) {
                                                            setPlayBackup(false);
                                                        }
                                                    }}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                                <span className="slider"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field field-flex">
                                    <span className="field-label" id="inputpriorityLabel">
                                        {t("device.label.inputpriority")}
                                    </span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <ul role="radiogroup" aria-labelledby="inputpriorityLabel">
                                                <li className="radio">
                                                    <input
                                                        type="radio"
                                                        name="rad-tem-type03"
                                                        id="rad-tem-type03-1"
                                                        checked={failOverData?.failover === "auto"}
                                                        onChange={(e) => changeData("failover", "auto")}
                                                        disabled={!failOver || _U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <label htmlFor="rad-tem-type03-1">{t("player.label.auto")}</label>
                                                </li>
                                                <li className="radio">
                                                    <input
                                                        type="radio"
                                                        name="rad-tem-type03"
                                                        id="rad-tem-type03-2"
                                                        checked={failOverData?.failover === "manual"}
                                                        onChange={(e) => changeData("failover", "manual")}
                                                        disabled={!failOver || _U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <label htmlFor="rad-tem-type03-2">
                                                        <span>{t("setup.setting.label.manual")}</span>
                                                        <div className="label-item">
                                                            {failOverData &&
                                                                [...Array(6)].map((m, index) => {
                                                                    if (failOverData[`failoverPriority${index + 1}`]) {
                                                                        return <span key={index}>{failOverData[`failoverPriority${index + 1}`]?.name || ""}</span>;
                                                                    }
                                                                    return "";
                                                                })}
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                            <button
                                                type="button"
                                                className="btn small-btn"
                                                onClick={() => setEditModal({ open: true, data: failOverData })}
                                                disabled={!failOver || failOverData?.failover !== "manual" || _U.getAuthWriteDisable(rolePermission)}
                                            >
                                                {t("common.edit")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {_Device.getSupported("__backupViaStorage", data) && (
                                    <div>
                                        <div className="details-tit">{t("dv.failover.title")}</div>
                                        <div className="field field-flex">
                                            <span className="field-label">{t("dv.failover.content")}</span>
                                            <div className="field-form">
                                                <div className="field-box">
                                                    <div className="switch">
                                                        <label htmlFor="default-switch-5" className="switch-controller">
                                                            {t("dv.failover.content")}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            id="default-switch-5"
                                                            name="default-switch-5"
                                                            role="switch"
                                                            aria-checked={playBackup}
                                                            checked={playBackup}
                                                            onChange={() => {
                                                                if (failOver) {
                                                                    setPlayBackup(!playBackup);
                                                                    if (!failOverData?.backupViaStorage || failOverData?.backupViaStorage === "off") {
                                                                        if (_Device.getSupported("__autoCaptureImage", data)) {
                                                                            changeData("backupViaStorage", "auto");
                                                                        } else {
                                                                            changeData("backupViaStorage", "manual");
                                                                        }
                                                                    } else {
                                                                        changeData("backupViaStorage", "off");
                                                                    }
                                                                }
                                                            }}
                                                            disabled={!failOver || _U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <span className="slider"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Fragment>
                                            <div className="field field-flex">
                                                <span className="field-label" id="applicationtypeLabel">
                                                    {t("device.label.contentSource")}
                                                </span>
                                                <div className="field-form">
                                                    <div className="field-box">
                                                        <ul role="radiogroup" aria-labelledby="applicationtypeLabel">
                                                            {_Device.getSupported("__autoCaptureImage", data) && (
                                                                <li className="radio">
                                                                    <input
                                                                        type="radio"
                                                                        name="rad-tem-type04"
                                                                        id="rad-tem-type04-1"
                                                                        checked={failOverData?.backupViaStorage === "auto"}
                                                                        onChange={(e) => changeData("backupViaStorage", "auto")}
                                                                        disabled={!playBackup || _U.getAuthWriteDisable(rolePermission)}
                                                                    />
                                                                    <label htmlFor="rad-tem-type04-1">{t("dv.failover.capture")}</label>
                                                                </li>
                                                            )}
                                                            <li className="radio">
                                                                <input
                                                                    type="radio"
                                                                    name="rad-tem-type04"
                                                                    id="rad-tem-type04-2"
                                                                    checked={failOverData?.backupViaStorage === "manual"}
                                                                    onChange={(e) => changeData("backupViaStorage", "manual")}
                                                                    disabled={!playBackup || _U.getAuthWriteDisable(rolePermission)}
                                                                />
                                                                <label htmlFor="rad-tem-type04-2">{t("dv.failover.storage")}</label>
                                                            </li>
                                                            <li className="radio">
                                                                <input
                                                                    type="radio"
                                                                    name="rad-tem-type04"
                                                                    id="rad-tem-type04-3"
                                                                    checked={failOverData?.backupViaStorage === "supersign"}
                                                                    onChange={(e) => changeData("backupViaStorage", "supersign")}
                                                                    disabled={!playBackup || _U.getAuthWriteDisable(rolePermission)}
                                                                />
                                                                <label htmlFor="rad-tem-type04-3">{t("device.label.supersigncontent")}</label>
                                                            </li>
                                                            {_Device.getSupported("__siAppPlayViaURL", data) && (
                                                                <li className="radio">
                                                                    <input
                                                                        type="radio"
                                                                        name="rad-tem-type04"
                                                                        id="rad-tem-type04-4"
                                                                        checked={failOverData?.backupViaStorage === "siapp"}
                                                                        onChange={(e) => changeData("backupViaStorage", "siapp")}
                                                                        disabled={!playBackup || _U.getAuthWriteDisable(rolePermission)}
                                                                    />
                                                                    <label htmlFor="rad-tem-type04-4">{t("device.label.siapp")}</label>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {failOverData?.backupViaStorage === "auto" && (
                                                <div className="field field-flex field-dpm">
                                                    <span className="field-label" id="captureTimeIntervalLabel">
                                                        {t("device.label.captureinterval")}
                                                    </span>
                                                    <div className="field-form">
                                                        <div className="field-box">
                                                            <SelectBox
                                                                id="captureTimeInterval"
                                                                items={[
                                                                    { label: "30 " + t("common.label.min"), value: 30 },
                                                                    { label: "1 " + t("common.label.hour"), value: 60 },
                                                                    { label: "2 " + t("common.label.hours"), value: 120 },
                                                                    { label: "3 " + t("common.label.hours"), value: 180 },
                                                                ]}
                                                                value={failOverData?.backupViaStorageInterval || ""}
                                                                ariaLabelId={"captureTimeIntervalLabel"}
                                                                selectLabel={
                                                                    failOverData?.backupViaStorageInterval == 30
                                                                        ? `30 ${t("common.label.min")}`
                                                                        : failOverData?.backupViaStorageInterval == 60
                                                                        ? `1 ${t("common.label.hour")}`
                                                                        : failOverData?.backupViaStorageInterval == 120
                                                                        ? `2 ${t("common.label.hours")}`
                                                                        : failOverData?.backupViaStorageInterval == 180
                                                                        ? `3 ${t("common.label.hours")}`
                                                                        : "ㅤ"
                                                                }
                                                                onChange={(e) => changeData("backupViaStorageInterval", e.value)}
                                                                title={t("subtext.title.select.option")}
                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {playBackup && failOverData?.backupViaStorage === "manual" && (
                                                <Fragment>
                                                    <div className="field field-flex">
                                                        <span className="field-label">{t("device.label.backupmedia")}</span>
                                                        <span className="field-content">{failOverData?.backupMedia || t("device.label.nofiles")}</span>
                                                    </div>
                                                    <div className="field field-flex field-dpm">
                                                        <span className="field-label" id="storagedeviceLabel">
                                                            {t("device.label.storagedevice")}
                                                        </span>
                                                        <div className="field-form">
                                                            <div className="field-box">
                                                                <SelectBox
                                                                    id="storagedevice"
                                                                    items={storageDeviceList}
                                                                    value={failOverData?.storageDevice?.value || ""}
                                                                    ariaLabelId={"storagedeviceLabel"}
                                                                    selectLabel={failOverData?.storageDevice?.label || "ㅤ"}
                                                                    onChange={(e) => changeData("storageDevice", e)}
                                                                    title={t("subtext.title.select.option")}
                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                />
                                                                {failOverData?.storageDevice?.value === "cloudtostorage" && (
                                                                    <Fragment>
                                                                        <input
                                                                            type="file"
                                                                            id="ip-file-upload"
                                                                            // accept="video/*, image/*"
                                                                            accept={
                                                                                deviceInfo?.device_sub_type?.toUpperCase() === "OLED"
                                                                                    ? ".MP4, .AVI, .WMV, .MPEG, .MKV, .MOV, .WEBM, .3GPP"
                                                                                    : ".JPEG, .JPG, .PNG, .BMP, .GIF, .RAW, .MP4, .AVI, .WMV, .MPEG, .MKV, .MOV, .WEBM, .3GPP"
                                                                            }
                                                                            ref={fRef}
                                                                            className="display-none-style"
                                                                            onChange={fileSelected}
                                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                        />
                                                                        <button type="button" className="btn" onClick={() => fRef.current.click()} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                                            {t("device.label.uploadtocloud")}
                                                                        </button>
                                                                    </Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="field field-flex field-dpm">
                                                        <span className="field-label" id="backupmediafileLabel">
                                                            {t("device.label.backupmediafile")}
                                                        </span>
                                                        <div className="field-form">
                                                            <div className="field-box">
                                                                <SelectBox
                                                                    id="backupmediafile"
                                                                    items={backupMediaFileList}
                                                                    value={failOverData?.backupMediaFile?.value || ""}
                                                                    ariaLabelId={"backupmediafileLabel"}
                                                                    selectLabel={
                                                                        failOverData?.backupMediaFile?.label
                                                                            ? failOverData?.backupMediaFile?.label
                                                                            : backupMediaFileList?.length > 0
                                                                            ? "ㅤ"
                                                                            : t("device.label.nofiles")
                                                                    }
                                                                    onChange={(e) => changeData("backupMediaFile", e)}
                                                                    deleteBtn={failOverData?.storageDevice?.value === "cloudtostorage"}
                                                                    onDelete={(item) => delteBackupMediaFile(item)}
                                                                    title={t("subtext.title.select.option")}
                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                    noItemText={t("device.label.nofiles")}
                                                                />

                                                                {_Device.getSupported("__failOverUpload", data) && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn"
                                                                        onClick={() => backupMediaFileControl("file")}
                                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                    >
                                                                        {t("upload.label.upload")}
                                                                    </button>
                                                                )}
                                                                {viewPreview && (
                                                                    <Fragment>
                                                                        {_Device.getSupported("__failOverPreview", data) && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn"
                                                                                onClick={() => backupMediaFileControl("failOverPreview")}
                                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                            >
                                                                                {t("common.preview")}
                                                                            </button>
                                                                        )}
                                                                        {_Device.getSupported("__failOverPreviewCancel", data) && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn"
                                                                                onClick={() => backupMediaFileControl("failOverPreviewCancel")}
                                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                            >
                                                                                {t("common.label.previewcancel")}
                                                                            </button>
                                                                        )}
                                                                    </Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    </div>
                                )}
                                <div className="field field-flex">
                                    <span className="field-label">{t("player.button.apply")}</span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <button type="button" className="btn" onClick={onApply} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                {t("player.button.apply")}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="field field-flex">
                                    <span className="field-label">{t("main.settingsPage.reset")}</span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <button type="button" className="btn" onClick={onReset} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                {t("main.settingsPage.reset")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
            {editModal?.open && (
                <EditModal
                    data={editModal?.data}
                    onDone={(e) => {
                        editInputPriority(e);
                        setEditModal({ open: false });
                    }}
                    onClose={() => setEditModal({ open: false })}
                />
            )}
        </Fragment>
    );
};

const EditModal = (props) => {
    const { t } = useTranslation();
    const { data } = props;
    const [list, setList] = useState([]);
    const onClickDone = () => {
        props.onDone && props.onDone(list);
    };

    const onChangePriority = (action, index) => {
        let newList = JSON.parse(JSON.stringify(list));
        if (action === "up") {
            if (index !== 0) {
                const item = newList.splice(index - 1, 1, newList[index]);
                newList.splice(index, 1, item[0]);
            } else {
                const item = newList.splice(0, 1);
                newList.push(item[0]);
            }
        } else if (action === "down") {
            if (index !== newList.length - 1) {
                const item = newList.splice(index + 1, 1, newList[index]);
                newList.splice(index, 1, item[0]);
            } else {
                const item = newList.splice(index, 1);
                newList.unshift(item[0]);
            }
        }
        setList(newList);
    };

    useEffect(() => {
        if (data) {
            const newList = [];
            for (let i = 1; i <= 6; i++) {
                if (data["failoverPriority" + i]) {
                    newList.push(data["failoverPriority" + i]);
                }
            }
            setList(newList);
        }
    }, [data]);

    return (
        <Modal title={t("common.label.editinputpriority")} buttonOkTitle={t("button.save")} id="popup-select-file" className="popup-edit-input-priority" onClose={props.onClose} onClick={onClickDone}>
            <ul className="priority-list">
                {list?.map((item, index) => (
                    <li key={index}>
                        <div className="priority-con">
                            <span>
                                {t("device.label.priority")} {index + 1}
                            </span>
                            <span>{item?.name || ""}</span>
                        </div>
                        <div className="priority-btn">
                            <button type="button" className="btn-down" onClick={() => onChangePriority("down", index)}>
                                <span className="ir">{t("subtext.label.input.priority.down")}</span>
                            </button>
                            <button type="button" className="btn-up" onClick={() => onChangePriority("up", index)}>
                                <span className="ir">{t("subtext.label.input.priority.up")}</span>
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </Modal>
    );
};

export const EZPlay = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);
    const [data, setData] = useState([]);

    const onPreview = async () => {
        const url = data?.filter((f) => f["playViaUrlSetUrl"])[0]?.["playViaUrlSetUrl"]?.data?.value;

        if (validator.isURL(url)) {
            const params = [{ playViaUrlPreview: { data: { value: url } } }];
            let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("dv.url.invalid"),
                button: t("common.ok"),
            }).then((id) => {});
        }
    };
    const onPreviewCancel = async () => {
        const params = [{ playViaUrlPreviewCancel: { data: { value: null } } }];
        let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
    };
    const onUrlSave = async () => {
        const url = data?.filter((f) => f["playViaUrlSetUrl"])[0]?.["playViaUrlSetUrl"]?.data?.value;
        if (validator.isURL(url)) {
            const params = [{ playViaUrlSetUrl: { data: { value: url } } }];
            let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
            if (controlRes && controlRes.status === "true") {
                saveCallBack();
            }
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("dv.url.invalid"),
                button: t("common.ok"),
            }).then((id) => {});
        }
    };

    const saveCallBack = (result) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("dv.playURL.save"),
            button: t("common.ok"),
        }).then((id) => {});
    };

    const onReset = () => {
        Dialog({
            title: t("main.settingsPage.reset"),
            text: t("device.label.reset"),
            button: [t("button.cancel"), t("main.settingsPage.reset")],
        }).then(async (id) => {
            if (id) {
                const params = [{ playViaUrlReset: { data: { value: null } } }];
                let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                if (controlRes && controlRes.status === "true") {
                    onLoadData();
                }
            }
        });
    };

    const onReboot = () => {
        Dialog({
            title: t("device.label.reboottoapply"),
            text: t("device.msg.reboot2"),
            button: [t("button.cancel"), t("player.label.reboot")],
        }).then(async (id) => {
            if (id) {
                const url = data?.filter((f) => f["playViaUrlSetUrl"])[0]?.["playViaUrlSetUrl"]?.data?.value;
                if (url) {
                    let params = [{ playViaUrlSetUrl: { data: { value: url } } }];
                    let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                    params = [{ playViaUrlReboot2Apply: { data: { value: null } } }];
                    controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                }
            }
        });
    };

    const onLoadData = () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: ["playViaUrlLoader", "playViaUrlReset", "playViaUrlSetUrl", "playViaUrlPreview", "playViaUrlPreviewCancel", "playViaUrlReboot2Apply", "last_updated_date"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                // setAutoSet(result?.filter((f) => f["siServerSetting"])[0]?.["siServerSetting"]?.data?.autoSet || false);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    const changeData = (key, value, saveFlag) => {
        setDeviceControl(key, value, saveFlag);
    };

    const setDeviceControl = async (key, value, saveFlag) => {
        const newData = JSON.parse(JSON.stringify(data));
        newData.forEach((f) => {
            if (f[key]) {
                f[key]["data"]["value"] = value;
            }
        });
        setData(newData);
        if (saveFlag == "save") {
            const params = [{ [key]: { data: { value: value } } }];
            let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
    };

    useEffect(() => {
        onLoadData();
    }, [idx, lastUpdateTime]);

    return (
        <Fragment>
            <div className="tab-panel panel-play-via-url" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        {_Device.getSupported("playViaUrlLoader", data) && (
                            <>
                                <div className="field field-flex">
                                    <span className="field-label" htmlFor="default-switch-6">
                                        {t("device.label.urlloader")}
                                    </span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="switch">
                                                <label htmlFor="default-switch-6" className="switch-controller">
                                                    {t("device.label.urlloader")}
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    id="default-switch-6"
                                                    name="default-switch-6"
                                                    role="switch"
                                                    aria-checked="true"
                                                    checked={data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value == "on"}
                                                    onChange={() =>
                                                        changeData("playViaUrlLoader", data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value == "on" ? "off" : "on", "save")
                                                    }
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                                <span className="slider"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field field-flex">
                                    <label htmlFor="urlInput" className="field-label">
                                        {t("player.label.seturl")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <input
                                                type="text"
                                                id="urlInput"
                                                className="input-txt"
                                                placeholder="https://"
                                                maxLength={512}
                                                value={data?.filter((f) => f["playViaUrlSetUrl"])[0]?.["playViaUrlSetUrl"]?.data?.value}
                                                onChange={(e) => changeData("playViaUrlSetUrl", e.target.value)}
                                                disabled={data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value != "on" || _U.getAuthWriteDisable(rolePermission)}
                                            />
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={onPreview}
                                                disabled={data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value != "on" || _U.getAuthWriteDisable(rolePermission)}
                                            >
                                                {t("common.preview")}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={onPreviewCancel}
                                                disabled={data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value != "on" || _U.getAuthWriteDisable(rolePermission)}
                                            >
                                                {t("common.label.previewcancel")}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={onUrlSave}
                                                disabled={data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value != "on" || _U.getAuthWriteDisable(rolePermission)}
                                            >
                                                {t("button.save")}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="field field-flex">
                                    <span className="field-label">{t("main.settingsPage.reset")}</span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={onReset}
                                                disabled={data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value != "on" || _U.getAuthWriteDisable(rolePermission)}
                                            >
                                                {t("main.settingsPage.reset")}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="field field-flex">
                                    <span className="field-label">{t("device.label.reboottoapply")}</span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={onReboot}
                                                disabled={data?.filter((f) => f["playViaUrlLoader"])[0]?.["playViaUrlLoader"]?.data?.value != "on" || _U.getAuthWriteDisable(rolePermission)}
                                            >
                                                {t("device.label.reboottoapply")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export const EZCloning = (props) => {
    const { t } = useTranslation();
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);
    const { idx, deviceInfo } = props;

    const [fileList, setFileList] = useState([]);
    const [fileInfo, setFileInfo] = useState(null);
    const [data, setData] = useState([]);

    const getFileList = () => {
        saveInnerLoading(true);
        _API.get({
            path: `/device/devices/files/lgcc_cloning/${deviceInfo?.model_name}`,
            data: {},
        })
            .then((res) => {
                const result = res?.data?.result?.Contents;
                if (result?.length > 0) {
                    const list = result.map((m) => ({ ...m, label: m.Key.substring(m.Key.lastIndexOf("/") + 1), value: m.Key }));
                    setFileList(list);
                    setFileInfo(list[0]);
                } else {
                    setFileList([]);
                }
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
                setFileList([]);
                saveInnerLoading(false);
            });
    };

    const onLoadData = () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: ["settingDataCloningExport"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                console.log("result", result);
                setData(result);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    const onExport = async () => {
        if (deviceInfo) {
            // webos : tll, tlx 두개를 요청하여 onDeviceControl2 요청
            // android xml 요청하여 onDeviceControl2 요청
            try {
                let params = null;
                const reqDate = moment().format("YYYY-MM-DD_HH_mm_ss");
                if (deviceInfo?.os_type === "WEBOS") {
                    const res1 = await _API.post({
                        path: `/device/devices/files/lgcc_cloning/${deviceInfo?.model_name}/${deviceInfo?.model_name}_${reqDate}.tll`,
                        data: {},
                    });
                    const res2 = await _API.post({
                        path: `/device/devices/files/lgcc_cloning/${deviceInfo?.model_name}/${deviceInfo?.model_name}_${reqDate}.tlx`,
                        data: {},
                    });
                    const tll_file_path = res1?.data?.result?.upload_url;
                    const tlx_file_path = res2?.data?.result?.upload_url;
                    params = [{ settingDataCloningExport: { data: { tll_file_path, tlx_file_path, xml_file_path: "" } } }];
                } else if (deviceInfo?.os_type === "ANDROID") {
                    const res1 = await _API.post({
                        path: `/device/devices/files/lgcc_cloning/${deviceInfo?.model_name}/${deviceInfo?.model_name}_${reqDate}.xml`,
                        data: {},
                    });
                    const xml_file_path = res1?.data?.result?.upload_url;
                    params = [{ settingDataCloningExport: { data: { tll_file_path: "", tlx_file_path: "", xml_file_path } } }];
                }
                if (params) {
                    let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                    if (controlRes && controlRes.status === "true") {
                        onExportAfter();
                    }
                } else {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.msg.apply.fail"),
                        button: t("common.ok"),
                    }).then((id) => {});
                }
            } catch (e) {
                console.log("api error /device/devices/lgcc_cloning", e?.response);
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.msg.apply.fail"),
                    button: t("common.ok"),
                }).then((id) => {});
            }

            // _API.post({
            //     path: `/device/devices/files/lgcc_cloning/${deviceInfo?.model_name}/${deviceInfo?.model_name}_${moment().format("YYYY-MM-DD_HH_mm_ss")}.tll`,
            //     data: {},
            // })
            //     .then(async (res) => {
            //         const source = res?.data?.result?.upload_url;

            //         const params = [{ settingDataCloningExport: { data: { file_path: source } } }];
            //         let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
            //         if (controlRes && controlRes.status === "true") {
            //             onExportAfter();
            //         }
            //     })
            //     .catch((e) => {
            //         console.log("api error /device/devices/lgcc_cloning", e?.response);
            //     });
        }
    };

    const onExportAfter = (e) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("dv.settingData.export"),
            button: t("common.ok"),
        }).then((id) => {
            setFileInfo(null);
            getFileList();
        });
    };
    const onImport = () => {
        if (fileInfo?.label) {
            _API.post({ path: `/device/devices/files/lgcc_cloning/${deviceInfo?.model_name}`, data: { objectKey: fileInfo?.value } })
                .then(async (res) => {
                    const source = `${res?.data?.result?.url}`;

                    const params = [{ settingDataCloningImport: { data: { file_path: source } } }];
                    let controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                    if (controlRes && controlRes.status === "true") {
                        onImportAfter();
                    }
                })
                .catch((e) => {
                    console.log("api error /device/devices/lgcc_cloning", e?.response);
                });
        }
    };
    const onImportAfter = (e) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("dv.settingData.import"),
            button: t("common.ok"),
        }).then((id) => {});
    };

    const onDownload = () => {
        if (fileInfo?.label) {
            _API.post({ path: `/device/devices/files/lgcc_cloning/${deviceInfo?.model_name}`, data: { objectKey: fileInfo?.value } })
                .then((res) => {
                    console.log("api /device/devices/files/lgcc_cloning", res);
                    const source = `${res?.data?.result?.url}`;

                    window.open(source);
                    // handleDownload(source, fileInfo?.label);
                })
                .catch((e) => {
                    console.log("api error /device/devices/lgcc_cloning", e?.response);
                });
        }
    };

    const onDelete = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("dv.failover.delete"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                const params = { key: item?.value };
                _API.delete({ path: `/device/devices/files`, data: params })
                    .then((res) => {
                        getFileList();
                    })
                    .catch((e) => {
                        console.log("api error /device/devices/control", e?.response);
                    });
            }
        });
    };

    const handleDownload = async (fileUrl, fileName) => {
        _API.get({ url: fileUrl })
            .then((response) => response.blob())
            .then((blob) => {
                // 블롭(Blob) 객체 생성하고 URL을 생성
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");

                // 생성한 URL과 다운로드할 파일명 설정
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);

                // 링크를 문서(body)에 추가
                document.body.appendChild(link);

                // 링크 클릭 => 파일 다운로드
                link.click();

                // 다운로드 후 링크와 URL을 정리
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
    };

    useEffect(() => {
        idx && onLoadData();
        deviceInfo && getFileList();
    }, [lastUpdateTime]);

    return (
        <Fragment>
            <div className="tab-panel panel-setting-data-cloning" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    {_Device.getSupported("settingDataCloningExport", data) && (
                        <div className="list-box aligned-list-box">
                            <div className="field field-flex">
                                <span className="field-label">{t("device.label.exporttoserver")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn" onClick={onExport}>
                                            {t("editor.button.export")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="field field-flex field-power-on-delay">
                                <span className="field-label" id="ImportSettingDatafromServerLabel">
                                    {t("device.label.importdata")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="ImportSettingDatafromServer"
                                            items={fileList}
                                            value={fileInfo?.value || ""}
                                            ariaLabelId={"ImportSettingDatafromServerLabel"}
                                            selectLabel={fileInfo?.label ? fileInfo?.label : fileList?.length > 0 ? "ㅤ" : t("device.label.nofiles")}
                                            onChange={(item) => {
                                                setFileInfo(item);
                                            }}
                                            deleteBtn={true}
                                            onDelete={(item) => onDelete(item)}
                                            title={t("subtext.title.select.option")}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />

                                        <button type="button" className="btn" onClick={onImport} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("button.import")}
                                        </button>
                                        <button type="button" className="btn" onClick={onDownload} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("common.label.file.download")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};
