import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import cx from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Line, Bar } from "react-chartjs-2";
import styled from "styled-components";
import "chartjs-adapter-moment";
import "chartjs-plugin-annotation";

import useCss from "@hook/useCss";
import _U from "@util/utilities";
import Modal from "@component/UI/Modal";
import { SelectBox, InputDatepicker, InputCalendar } from "@component/UI/Form";
import _API from "@util/api";
import _Device from "@util/devices";
import { AppContext } from "@component/AppProvider";

export const UsageControl = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { auth, workspace, lastUpdateTime } = useContext(AppContext);
    const [paging, setPaging] = useState({
        total_count: 0,
        perPage: 20,
        total_page: 1,
        has_next: false,
        next_page: 1,
    });
    const [dataList, setDataList] = useState([]);
    const [period, setPeriod] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const onLoadData = (page, addFlag) => {
        const startUtc = _U.getOffsetTimeToUtc(workspace, moment().add(period, "day").format("YYYY-MM-DD") + " 00:00:00.000", null);
        const endUtc = _U.getOffsetTimeToUtc(workspace, moment().add(period, "day").format("YYYY-MM-DD") + " 23:59:59.999", null);

        const params = {
            // day: period,
            features: ["usage_control"],
            // page: page || 1,
            // perPage: 10,
        };
        let addUrl = `?page=${page || 1}&perPage=20`;
        if ((page || 1) > 1) {
            addUrl += "&lastIdKey=id&lastIdValue=" + dataList[dataList.length - 1].id;
        }
        addUrl += `&startTime=${startUtc}&endTime=${endUtc}`;
        _API.post({
            path: `/device/devices/${idx}/usage${addUrl}`,
            data: params,
        })
            .then((res) => {
                if (addFlag === "add" && (page || 1) > 1) {
                    setDataList([...dataList, ...res?.data?.result?.objects]);
                } else {
                    setDataList(res?.data?.result?.objects);
                }
                setPaging(res?.data?.result?.paging);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const onExcelDownload = () => {
        const startUtc = _U.getOffsetTimeToUtc(workspace, moment().add(period, "day").format("YYYY-MM-DD") + " 00:00:00.000", null);
        const endUtc = _U.getOffsetTimeToUtc(workspace, moment().add(period, "day").format("YYYY-MM-DD") + " 23:59:59.999", null);
        let addUrl = `?startTime=${startUtc}&endTime=${endUtc}`;

        _API.post({ path: `/device/devices/${idx}/usage${addUrl}&format=excel`, data: { format: "excel", features: ["usage_control"] } })
            .then((res) => {
                const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                const excel = res?.data;
                const downloadLink = document.createElement("a");
                const fileName = res?.headers["content-disposition"].split("filename=")[1];
                downloadLink.href = mediaType + excel;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            })
            .catch((e) => {
                console.log("api error /device/devices?format=excel", e);
            });
    };

    const onNextPage = () => {
        onLoadData(paging?.next_page, "add");
    };

    const goBack = () => {
        props.onBack && props.onBack();
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    useEffect(() => {
        onLoadData(1);
    }, [lastUpdateTime]);

    useEffect(() => {
        resizeTable(paging?.has_next, setWindowWidth);
    }, [paging]);

    return (
        <Fragment>
            <div className="view-history-con">
                <div className="view-history-top">
                    <a href="#!" role="button" className="btn-back" onClick={goBack}>
                        <span className="ir">{t("subtext.go.previous.screen")}</span>
                    </a>
                    <h3>{t("common.label.controlhistory")}</h3>
                </div>

                <div className="data-list-container">
                    <div className="data-list-top usage-list-top">
                        <div className="left-side">
                            <div className="field">
                                <label className="field-label" id="PeriodLabel">
                                    {t("report.label.period")}
                                </label>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="Period"
                                            items={_Device.getPeriodList7(t)}
                                            value={period}
                                            ariaLabelId={"PeriodLabel"}
                                            selectLabel={_Device.getPeriodList7(t).filter((f) => f.value === period)[0]?.label || "ㅤ"}
                                            onChange={(item) => setPeriod(item?.value)}
                                            title={t("subtext.title.select.option")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn" onClick={onSearch}>
                                {t("button.show")}
                            </button>
                        </div>

                        <div className="right-side">
                            <div className="total-count">
                                {t("common.total")} : <span className="count">{paging?.total_count || 0}</span>
                            </div>
                            <button type="button" id="" className="btn btn-download-excel" onClick={onExcelDownload}>
                                <span className="ir">{t("common.button.download.excel.ir")}</span>
                            </button>
                        </div>
                    </div>
                    <div className="table">
                        <div className="table-wrapper">
                            <div className="table-box">
                                <InfiniteScroll
                                    style={{ overflow: "hidden" }}
                                    dataLength={dataList?.length}
                                    next={onNextPage}
                                    hasMore={paging && paging.has_next && windowWidth > 991}
                                    loader={""}
                                    scrollableTarget="scrollableTbody"
                                >
                                    <table>
                                        <caption>{t("common.label.controlhistory")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.id")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("table.head.date")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.type")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("report.label.action")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("result.main.title")}</div>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody id="scrollableTbody">
                                            {dataList?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : ""}</td>
                                                    <td>{item?.action_type}</td>
                                                    <td>{item?.action_command}</td>
                                                    <td>{item?.state}</td>
                                                </tr>
                                            ))}
                                            {(!dataList || dataList?.length === 0) && (
                                                <tr>
                                                    <td colSpan={5} className="align-center">
                                                        {t("common.label.noitems")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">{t("report.label.action")}</div>
                                </div>
                            </div>
                            <InfiniteScroll style={{ overflow: "hidden" }} dataLength={dataList?.length} next={onNextPage} hasMore={paging && paging.has_next && windowWidth <= 991} loader={""}>
                                <ul className="table-body">
                                    {dataList?.map((item, index) => (
                                        <li className="table-row" key={`convert-${index}`}>
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell issue-level" aria-label={t("report.label.action")}>
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {item?.action_command}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li>
                                                        <span className="field-label">{t("common.id")}</span>
                                                        <span className="field-content">{item?.email}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("table.head.date")}</span>
                                                        <span className="field-content">{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("common.type")}</span>
                                                        <span className="field-content">{item?.action_type}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("report.label.action")}</span>
                                                        <span className="field-content">{item?.action_command}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("result.main.title")}</span>
                                                        <span className="field-content">{item?.state}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                    {(!dataList || dataList?.length === 0) && (
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">{t("devicemanagement.search.noresult")}</div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export const UsagePower = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { auth, workspace, lastUpdateTime } = useContext(AppContext);
    const [paging, setPaging] = useState({
        total_count: 0,
        perPage: 20,
        total_page: 1,
        has_next: false,
        next_page: 1,
    });
    const [dataList, setDataList] = useState([]);
    const [period, setPeriod] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const onLoadData = (page, addFlag) => {
        const startUtc = _U.getOffsetTimeToUtc(workspace, (startDate || moment().format("YYYY-MM-DD")) + " 00:00:00.000", null);
        const endUtc = _U.getOffsetTimeToUtc(workspace, (endDate || moment().format("YYYY-MM-DD")) + " 23:59:59.999", null);

        // console.log(">>", startUtc, endUtc);
        const params = {
            // day: period,
            features: ["usage_power"],
            // page: page || 1,
            // perPage: 10,
        };
        let addUrl = `?page=${page || 1}&perPage=20`;
        if ((page || 1) > 1) {
            addUrl += "&lastIdKey=id&lastIdValue=" + dataList[dataList.length - 1].id;
        }
        addUrl += `&startTime=${startUtc}&endTime=${endUtc}`;

        _API.post({
            path: `/device/devices/${idx}/usage${addUrl}`,
            data: params,
        })
            .then((res) => {
                if (addFlag === "add" && (page || 1) > 1) {
                    setDataList([...dataList, ...res?.data?.result?.objects]);
                } else {
                    setDataList(res?.data?.result?.objects);
                }
                setPaging(res?.data?.result?.paging);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const onExcelDownload = () => {
        const startUtc = _U.getOffsetTimeToUtc(workspace, (startDate || moment().format("YYYY-MM-DD")) + " 00:00:00.000", null);
        const endUtc = _U.getOffsetTimeToUtc(workspace, (endDate || moment().format("YYYY-MM-DD")) + " 23:59:59.999", null);
        let addUrl = `?page=1&perPage=${paging?.total_count || 10}`;
        addUrl += `&startTime=${startUtc}&endTime=${endUtc}`;
        _API.post({ path: `/device/devices/${idx}/usage${addUrl}&format=excel`, data: { format: "excel", features: ["usage_power"] } })
            .then((res) => {
                const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                const excel = res?.data;
                const downloadLink = document.createElement("a");
                const fileName = res?.headers["content-disposition"].split("filename=")[1];
                downloadLink.href = mediaType + excel;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            })
            .catch((e) => {
                console.log("api error /device/devices?format=excel", e);
            });
    };

    const onNextPage = () => {
        console.log("onNextPage");
        onLoadData(paging?.next_page, "add");
    };

    const goBack = () => {
        props.onBack && props.onBack();
    };

    const onChangeStartDate = (params) => {
        console.log("params.startDate", params);
        if (endDate && moment(params.startDate) > moment(endDate)) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.label.StartDateDialog"),
                button: t("common.label.confirm"),
            }).then(() => {
                setStartDate("");
            });
        } else {
            setStartDate(params.startDate);
        }
    };

    const onChangeEndDate = (params) => {
        if (startDate && moment(startDate) > moment(params.endDate)) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.label.StartDateDialog"),
                button: t("common.label.confirm"),
            }).then(() => {
                setEndDate(params.endDate);
            });
        } else {
            setEndDate(params.endDate);
        }
    };

    useEffect(() => {
        console.log("window.innerWidth", window.innerWidth);
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    useEffect(() => {
        onLoadData(1);
    }, [lastUpdateTime]);

    useEffect(() => {
        resizeTable(paging?.has_next, setWindowWidth);
    }, [paging]);

    return (
        <Fragment>
            <div className="view-history-con">
                <div className="view-history-top">
                    <a href="#!" role="button" className="btn-back" onClick={goBack}>
                        <span className="ir">{t("subtext.go.previous.screen")}</span>
                    </a>
                    <h3>{t("common.label.powerhistory")}</h3>
                </div>

                <div className="data-list-container">
                    <div className="data-list-top usage-list-top">
                        <div className="left-side">
                            <div className="field">
                                {/* <label className="field-label" id="PowerPeriodLabel">
                                    {t("report.label.period")}
                                </label>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="PowerPeriod"
                                            items={_Device.getPeriodList7(t)}
                                            value={period}
                                            ariaLabelId={"PowerPeriodLabel"}
                                            selectLabel={_Device.getPeriodList7(t).filter((f) => f.value === period)[0]?.label || "ㅤ"}
                                            onChange={(item) => setPeriod(item?.value)}
                                            title={t("subtext.title.select.option")}
                                        />
                                    </div>
                                </div> */}
                                <div className="custom field-datepicker">
                                    <div className="field-form flex">
                                        <InputCalendar
                                            name="startDate"
                                            id="power-start-date"
                                            placeholder="YYYY-MM-DD"
                                            autoComplete="false"
                                            ariaLabel={t("common.label.EnterStartDate")}
                                            ariaDescribedBy="datepickerLabel"
                                            calendarTitle="Start date pick"
                                            value={startDate || null}
                                            onChange={onChangeStartDate}
                                            ir={t("dv.powerhidstory.from")}
                                        />
                                        <div className="separator">~</div>
                                        <InputCalendar
                                            name="endDate"
                                            id="power-end-date"
                                            placeholder="YYYY-MM-DD"
                                            autoComplete="false"
                                            ariaLabel={t("common.label.EnterEndDate")}
                                            ariaDescribedBy="datepickerLabel"
                                            calendarTitle="End date pick"
                                            value={endDate || null}
                                            onChange={onChangeEndDate}
                                            ir={t("dv.powerhistory.to")}
                                        />
                                    </div>
                                    <div id="datepickerLabel" className="hide">
                                        {t("subtext.desc.show.calendar")}.
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn" onClick={onSearch}>
                                {t("button.show")}
                            </button>
                        </div>

                        <div className="right-side">
                            <div className="total-count">
                                {t("common.total")} : <span className="count">{paging?.total_count || 0}</span>
                            </div>
                            <button type="button" id="" className="btn btn-download-excel" onClick={onExcelDownload}>
                                <span className="ir">{t("common.button.download.excel.ir")}</span>
                            </button>
                        </div>
                    </div>
                    <div className="table">
                        <div className="table-wrapper">
                            <div className="table-box">
                                <InfiniteScroll
                                    style={{ overflow: "hidden" }}
                                    dataLength={dataList?.length}
                                    next={onNextPage}
                                    hasMore={paging && paging.has_next && windowWidth > 991}
                                    loader={""}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <table>
                                        <caption>{t("common.label.powerhistory")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="text-block">{t("table.head.date")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("report.label.action")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("dv.powerhistory.method")}</div>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody id="scrollableDiv">
                                            {dataList?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.date ? _U.getLocalTimeToUtc0Time(auth, item?.date) : ""}</td>
                                                    {/* 최종민 선임님 요청으로 무조건 utc0기준 출력
                                                    저장 시 utc0로 저장되시만 실제 utc0가 아니라 세트의 시간값을 YYYY-MM-DD HH:mm:ss로 저장한게 바로 return 되므로 
                                                    무조건  utc0출력 */}
                                                    <td>{item?.action}</td>
                                                    <td>{item?.method}</td>
                                                </tr>
                                            ))}
                                            {(!dataList || dataList?.length === 0) && (
                                                <tr>
                                                    <td colSpan={3} className="align-center">
                                                        {t("common.label.noitems")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">{t("report.label.action")}</div>
                                </div>
                            </div>
                            <InfiniteScroll style={{ overflow: "hidden" }} dataLength={dataList?.length} next={onNextPage} hasMore={paging && paging.has_next && windowWidth <= 991} loader={""}>
                                <ul className="table-body">
                                    {dataList?.map((item, index) => (
                                        <li className="table-row" key={`convert-${index}`}>
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell issue-level" aria-label={t("report.label.action")}>
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {item?.action}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li>
                                                        <span className="field-label">{t("table.head.date")}</span>
                                                        <span className="field-content">{item?.date ? _U.getOffsetTime(auth, workspace, item?.date) : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("report.label.action")}</span>
                                                        <span className="field-content">{item?.action}</span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("dv.powerhistory.method")}</span>
                                                        <span className="field-content">{item?.method}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                    {(!dataList || dataList?.length === 0) && (
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">{t("devicemanagement.search.noresult")}</div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export const UsageCpu = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { lastUpdateTime, auth, workspace } = useContext(AppContext);
    const [period, setPeriod] = useState(0);
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();

    const onLoadData = (page, addFlag) => {
        _API.post({
            path: `/device/devices/${idx}/usage`,
            data: {
                day: period,
                // day: -2,
                features: ["cpu_usage"],
            },
        })
            .then((res) => {
                convertChartData(res?.data?.result?.object);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const convertChartData = (chartList) => {
        const labels = [];
        const list = [];
        for (let item of chartList) {
            labels.push(moment(item.reported_at * 1000).toDate());
            list.push({ x: moment(item.reported_at * 1000).toDate(), y: item.cpu_usage });
        }

        setChartData({
            // labels,
            datasets: [
                {
                    label: t("dashboard.label.cpuusage"),
                    data: list,
                    fill: false,
                    borderColor: "#5689CD",
                    tension: 0.1,
                },
            ],
        });
        setChartOptions({
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            return _U.getOffsetTime(auth, workspace, moment(context[0]?.parsed?.x)?.format("YYYY-MM-DD HH:mm:ss")) || "";
                        },
                        label: (context) => {
                            return t("dashboard.label.cpuusage") + ": " + context.parsed.y + " %";
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    border: { display: false },
                    ticks: {
                        display: true,
                        stepSize: 100,
                        callback: (value, index, ticks) => {
                            return (index < 10 ? "0" + index : index) + ":00";
                        },
                    },
                    type: "time",
                    time: {
                        unit: "hour",
                        tooltipFormat: "H",
                    },
                    grid: { display: false },
                    min: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").valueOf()),
                    max: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").add(1, "day").valueOf()),
                },
                y: {
                    ticks: {
                        stepSize: 5,
                    },
                    min: 0,
                },
            },
            // spanGaps: 1000 * 60 * 60,
        });
    };

    useEffect(() => {
        setPeriod(props.period);
    }, [props.period]);

    useEffect(() => {
        onLoadData(1);
    }, [period, lastUpdateTime]);
    return (
        <Fragment>
            <div className="chart-box">
                <div className="chart-tit">{t("player.label.cpu")}</div>
                <ChartWrapper>{chartData && chartOptions && <Line data={chartData} options={chartOptions} />}</ChartWrapper>
            </div>
        </Fragment>
    );
};

export const UsageMemory = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { lastUpdateTime, auth, workspace } = useContext(AppContext);
    const [period, setPeriod] = useState(0);
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();

    const onLoadData = (page, addFlag) => {
        _API.post({
            path: `/device/devices/${idx}/usage`,
            data: {
                day: period,
                features: ["memory_usage"],
            },
        })
            .then((res) => {
                convertChartData(res?.data?.result?.object);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const convertChartData = (chartList) => {
        // const labels = [...Array(24)].map((m, i) => {
        //     return (i < 9 ? "0" + i : i) + ":00";
        // });
        // const list = [...Array(24)].map((m, i) => 0);
        // let tmpHour = "";
        // let maxVal = 0;
        // for (let i in chartList) {
        //     const item = chartList[i];
        //     const hour = moment(item.reported_at * 1000).hours();

        //     if (hour !== tmpHour) {
        //         if (i > 0) {
        //             list[hour] = maxVal;
        //         }
        //         maxVal = item.memory_usage;
        //         tmpHour = hour;
        //     } else {
        //         maxVal = maxVal > item.memory_usage ? maxVal : item.memory_usage;
        //     }
        //     if (i == chartList?.length - 1) {
        //         list[hour] = maxVal;
        //     }
        // }
        const labels = [];
        const list = [];
        for (let item of chartList) {
            labels.push(moment(item.reported_at * 1000).toDate());
            list.push({ x: moment(item.reported_at * 1000).toDate(), y: item.memory_usage });
        }

        setChartData({
            labels,
            datasets: [
                {
                    label: t("dashboard.label.memoryusage"),
                    data: list,
                    fill: false,
                    borderColor: "#5689CD",
                    tension: 0.1,
                },
            ],
        });

        setChartOptions({
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            return _U.getOffsetTime(auth, workspace, moment(context[0]?.parsed?.x)?.format("YYYY-MM-DD HH:mm:ss")) || "";
                        },
                        label: (context) => {
                            return t("dashboard.label.memoryusage") + ": " + context.parsed.y + " %";
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    border: { display: false },
                    ticks: {
                        display: true,
                        stepSize: 100,
                        callback: (value, index, ticks) => {
                            return (index < 10 ? "0" + index : index) + ":00";
                        },
                    },
                    type: "time",
                    time: {
                        unit: "hour",
                        tooltipFormat: "H",
                    },
                    grid: { display: false },
                    min: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").valueOf()),
                    max: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").add(1, "day").valueOf()),
                },
                y: {
                    ticks: {
                        stepSize: 5,
                    },
                    min: 0,
                },
            },
        });
    };

    useEffect(() => {
        setPeriod(props.period);
    }, [props.period]);

    useEffect(() => {
        onLoadData(1);
    }, [period, lastUpdateTime]);
    return (
        <Fragment>
            <div className="chart-box">
                <div className="chart-tit">{t("player.label.memory")}</div>
                <ChartWrapper>{chartData && chartOptions && <Line data={chartData} options={chartOptions} />}</ChartWrapper>
            </div>
        </Fragment>
    );
};

export const UsageTemperature = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { lastUpdateTime, auth, workspace } = useContext(AppContext);
    const [period, setPeriod] = useState(0);
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();

    const onLoadData = (page, addFlag) => {
        _API.post({
            path: `/device/devices/${idx}/usage`,
            data: {
                day: period,
                features: ["temperature"],
            },
        })
            .then((res) => {
                convertChartData(res?.data?.result?.object, res?.data?.result?.temperature_threshold);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const convertChartData = (chartList, threshold) => {
        // const labels = [...Array(24)].map((m, i) => {
        //     return (i < 9 ? "0" + i : i) + ":00";
        // });
        // const list = [...Array(24)].map((m, i) => 0);
        // let tmpHour = "";
        // let maxVal = 0;
        // for (let i in chartList) {
        //     const item = chartList[i];
        //     const hour = moment(item.reported_at * 1000).hours();

        //     if (hour !== tmpHour) {
        //         if (i > 0) {
        //             list[hour] = maxVal;
        //         }
        //         maxVal = item.temperature;
        //         tmpHour = hour;
        //     } else {
        //         maxVal = maxVal > item.temperature ? maxVal : item.temperature;
        //     }
        //     if (i == chartList?.length - 1) {
        //         list[hour] = maxVal;
        //     }
        // }
        const labels = [];
        const list = [];
        for (let item of chartList) {
            labels.push(moment(item.reported_at * 1000).toDate());
            list.push({ x: moment(item.reported_at * 1000).toDate(), y: item.temperature });
        }

        setChartData({
            labels,
            datasets: [
                {
                    label: t("common.label.temperatureUsage"),
                    data: list,
                    fill: false,
                    borderColor: "#5689CD",
                    tension: 0.1,
                },
            ],
        });

        setChartOptions({
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            return _U.getOffsetTime(auth, workspace, moment(context[0]?.parsed?.x)?.format("YYYY-MM-DD HH:mm:ss")) || "";
                        },
                        label: (context) => {
                            return t("common.label.temperatureUsage") + ": " + context.parsed.y + " ˚C";
                        },
                    },
                },
                legend: {
                    display: false,
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: "line",
                            yMin: threshold,
                            yMax: threshold,
                            borderColor: "#FF5733",
                            borderWidth: 2,
                        },
                    },
                },
            },
            scales: {
                x: {
                    border: { display: false },
                    ticks: {
                        display: true,
                        stepSize: 100,
                        callback: (value, index, ticks) => {
                            return (index < 10 ? "0" + index : index) + ":00";
                        },
                    },
                    type: "time",
                    time: {
                        unit: "hour",
                        tooltipFormat: "H",
                    },
                    grid: { display: false },
                    min: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").valueOf()),
                    max: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").add(1, "day").valueOf()),
                },
                y: {
                    ticks: {
                        stepSize: 5,
                    },
                    min: 0,
                },
            },
        });
    };

    useEffect(() => {
        setPeriod(props.period);
    }, [props.period]);

    useEffect(() => {
        onLoadData(1);
    }, [period, lastUpdateTime]);
    return (
        <Fragment>
            <div className="chart-box">
                <div className="chart-tit">{t("player.label.temperature")}</div>
                <ChartWrapper>{chartData && chartOptions && <Line data={chartData} options={chartOptions} />}</ChartWrapper>
            </div>
        </Fragment>
    );
};

export const UsageStorage = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { lastUpdateTime, auth, workspace } = useContext(AppContext);
    const [period, setPeriod] = useState(0);
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();

    const onLoadData = (page, addFlag) => {
        _API.post({
            path: `/device/devices/${idx}/usage`,
            data: {
                day: period,
                features: ["available_storage"],
            },
        })
            .then((res) => {
                convertChartData(res?.data?.result?.object, res?.data?.result?.available_storage_threshold);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const convertChartData = (chartList, threshold) => {
        // const labels = [...Array(24)].map((m, i) => {
        //     return (i < 9 ? "0" + i : i) + ":00";
        // });
        // const list = [...Array(24)].map((m, i) => 0);
        // let tmpHour = "";
        // let maxVal = 0;
        // for (let i in chartList) {
        //     const item = chartList[i];
        //     const hour = moment(item.reported_at * 1000).hours();

        //     if (hour !== tmpHour) {
        //         if (i > 0) {
        //             list[hour] = maxVal;
        //         }
        //         maxVal = item.available_storage;
        //         tmpHour = hour;
        //     } else {
        //         maxVal = maxVal > item.available_storage ? maxVal : item.available_storage;
        //     }
        //     if (i == chartList?.length - 1) {
        //         list[hour] = maxVal;
        //     }
        // }
        const labels = [];
        const list = [];
        for (let item of chartList) {
            labels.push(moment(item.reported_at * 1000).toDate());
            list.push({ x: moment(item.reported_at * 1000).toDate(), y: item.available_storage });
        }

        setChartData({
            labels,
            datasets: [
                {
                    label: t("common.label.storageUsage"),
                    data: list,
                    fill: false,
                    borderColor: "#5689CD",
                    tension: 0.1,
                },
            ],
        });

        setChartOptions({
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            return _U.getOffsetTime(auth, workspace, moment(context[0]?.parsed?.x)?.format("YYYY-MM-DD HH:mm:ss")) || "";
                        },
                        label: (context) => {
                            return t("common.label.storageUsage") + ": " + _U.number3Comma(context.parsed.y) + " MB";
                        },
                    },
                },
                legend: {
                    display: false,
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: "line",
                            yMin: threshold,
                            yMax: threshold,
                            borderColor: "#FF5733",
                            borderWidth: 2,
                        },
                    },
                },
            },
            scales: {
                x: {
                    border: { display: false },
                    ticks: {
                        display: true,
                        stepSize: 100,
                        callback: (value, index, ticks) => {
                            return (index < 10 ? "0" + index : index) + ":00";
                        },
                    },
                    type: "time",
                    time: {
                        unit: "hour",
                        tooltipFormat: "H",
                    },
                    grid: { display: false },
                    min: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").valueOf()),
                    max: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").add(1, "day").valueOf()),
                },
                y: {
                    ticks: {
                        stepSize: 1000,
                    },
                    min: 0,
                },
            },
        });
    };

    useEffect(() => {
        setPeriod(props.period);
    }, [props.period]);

    useEffect(() => {
        onLoadData(1);
    }, [period, lastUpdateTime]);
    return (
        <Fragment>
            <div className="chart-box">
                <div className="chart-tit">{t("common.label.storage")}</div>
                <ChartWrapper>{chartData && chartOptions && <Line data={chartData} options={chartOptions} />}</ChartWrapper>
            </div>
        </Fragment>
    );
};

export const UsageApp = (props) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="tab-panel panel-ez-temperature" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        <div>
                            <div className="details-tit">{t("common.label.usagehistory")}</div>

                            <div className="data-list-container">
                                <div className="data-list-top usage-list-top">
                                    <div className="left-side">
                                        <div className="field">
                                            <label className="field-label" id="appPeriodLabel">
                                                {t("report.label.period")}
                                            </label>
                                            <div className="field-form">
                                                <div className="field-box">
                                                    <SelectBox
                                                        id="appPeriod"
                                                        items={[...Array(15)]?.map((item, index) => ({ label: index + 1, value: index + 1 }))}
                                                        value={"Value"}
                                                        ariaLabelId={"appPeriodLabel"}
                                                        selectLabel={"Value"}
                                                        onChange={(item) => {}}
                                                        title={t("subtext.title.select.option")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="btn">
                                            View
                                        </button>
                                    </div>

                                    <div className="right-side">
                                        <div className="error-level">Threshold Error Level</div>
                                    </div>
                                </div>
                                <div className="chart-box">
                                    <img className="img" src="/assets/images/usage_chart_img02.png" alt="chart img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export const UsageInput = (props) => {
    const { t } = useTranslation();
    const { idx, deviceInfo } = props;
    const { lastUpdateTime, auth, workspace } = useContext(AppContext);
    const [period, setPeriod] = useState(0);
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();

    const onLoadData = (page, addFlag) => {
        _API.post({
            path: `/device/devices/${idx}/usage`,
            data: {
                day: period,
                features: ["input"],
            },
        })
            .then((res) => {
                convertChartData(res?.data?.result?.object);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const convertChartData = (chartList) => {
        const labels = [];
        const checkList = [];
        let inputCode = "";
        let startVal = 0;
        for (let i in chartList) {
            const item = chartList[i];
            if (item && item?.reported_at) {
                const hour = parseInt(_U.getOffsetTime(auth, workspace, moment(item?.reported_at * 1000)?.format("YYYY-MM-DD HH:mm:ss"), "HH") || 0);
                const min = parseInt(_U.getOffsetTime(auth, workspace, moment(item?.reported_at * 1000)?.format("YYYY-MM-DD HH:mm:ss"), "mm") || 0) / 60;
                // const hour = moment(item.reported_at * 1000).hours();
                // const min = moment(item.reported_at * 1000).minutes() / 60;
                let label = "";
                const itemInput = item?.input?.toUpperCase();
                // if (deviceInfo?.device_type === "IDB" && deviceInfo?.os_type === "ANDROID" && deviceInfo?.device_sub_type === "CVTE") {
                if (deviceInfo?.os_type === "ANDROID") {
                    label = t("wrapper.input." + itemInput + ".odm_cvte" || "");
                } else {
                    label = t("wrapper.input." + itemInput);
                }

                if (itemInput !== inputCode) {
                    if (i > 0) {
                        if (checkList.filter((f) => f?.input === itemInput)?.length > 0) {
                            for (let check of checkList) {
                                if (check?.input && check?.input === itemInput) {
                                    const lastVal = check?.originValues[check?.values?.length - 1][1];
                                    check?.values?.push([startVal - lastVal, hour + min - lastVal]);
                                    check?.originValues?.push([startVal, hour + min]);
                                    break;
                                }
                            }
                        } else {
                            checkList.push({ label, input: itemInput, values: [[startVal, hour + min]], originValues: [[startVal, hour + min]] });
                        }
                    }
                    startVal = hour + min;
                    inputCode = itemInput;
                }
                if (i == chartList?.length - 1) {
                    if (checkList.filter((f) => f?.input === itemInput)?.length > 0) {
                        for (let check of checkList) {
                            if (check?.input && check?.input === itemInput) {
                                const lastVal = check?.originValues[check?.values?.length - 1][1];
                                check?.values?.push([startVal - lastVal, hour + min - lastVal]);
                                check?.originValues?.push([startVal, hour + min]);
                                break;
                            }
                        }
                    } else {
                        checkList.push({ label, input: itemInput, values: [[startVal, hour + min]], originValues: [[startVal, hour + min]] });
                    }
                }
            }
        }
        const datasetList = [];
        if (checkList?.length > 0) {
            const maxDatasetSize = checkList?.length > 0 ? Math.max(...checkList?.map((m) => m?.values?.length)) : 0;
            labels.push(...checkList.map((m) => m?.label));
            const dataList = [];
            const originDataList = [];
            for (let i = 0; i < maxDatasetSize; i++) {
                dataList.push([]);
                originDataList.push([]);
            }

            for (let i = 0; i < checkList?.length; i++) {
                const values = checkList[i]?.values;
                const originValues = checkList[i]?.originValues;
                for (let j = 0; j < maxDatasetSize; j++) {
                    if (j < values?.length) {
                        dataList[j]?.push(values[j]);
                        originDataList[j]?.push(originValues[j]);
                    } else {
                        dataList[j]?.push([0, 0]);
                        originDataList[j]?.push([0, 0]);
                    }
                }
            }
            for (let i = 0; i < maxDatasetSize; i++) {
                const dataset = {
                    label: t("common.label.usagehistory"),
                    data: dataList[i],
                    fill: false,
                    borderColor: "#5689CD",
                    tension: 0.1,
                    backgroundColor: ["#5689CD"],
                    originValues: originDataList[i],
                };
                if (i > 0) {
                    dataset["customTag"] = "term";
                }
                datasetList.push(dataset);
            }
        } else {
            datasetList.push({
                label: t("common.label.usagehistory"),
                data: [],
                fill: false,
                borderColor: "#5689CD",
                tension: 0.1,
                backgroundColor: ["#5689CD"],
            });
        }

        setChartData({
            labels,
            datasets: datasetList,
        });

        setChartOptions({
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            return context[0]?.label || "";
                        },
                        label: (context) => {
                            let startHour = Math.floor(context?.raw[0]);
                            let startMin = Math.round((context?.raw[0] - startHour) * 60);
                            let endHour = Math.floor(context?.raw[1]);
                            let endMin = Math.round((context?.raw[1] - endHour) * 60);

                            if (context?.dataset?.customTag === "term") {
                                startHour = Math.floor(context?.dataset?.originValues[context?.dataIndex][0]);
                                startMin = Math.round((context?.dataset?.originValues[context?.dataIndex][0] - startHour) * 60);
                                endHour = Math.floor(context?.dataset?.originValues[context?.dataIndex][1]);
                                endMin = Math.round((context?.dataset?.originValues[context?.dataIndex][1] - endHour) * 60);
                            }
                            startHour = startHour < 10 ? "0" + startHour : startHour;
                            startMin = startMin < 10 ? "0" + startMin : startMin;
                            endHour = endHour < 10 ? "0" + endHour : endHour;
                            endMin = endMin < 10 ? "0" + endMin : endMin;
                            return " " + startHour + ":" + startMin + " ~ " + endHour + ":" + endMin;
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    min: 0,
                    max: 24,
                    ticks: {
                        stepSize: 1,
                        callback: function (value, index, values) {
                            return (value < 10 ? "0" + value : value) + ":00";
                        },
                    },
                    stacked: true,
                },
            },
        });
    };

    useEffect(() => {
        setPeriod(props.period);
    }, [props.period]);

    useEffect(() => {
        onLoadData(1);
    }, [period, lastUpdateTime]);
    return (
        <Fragment>
            <div className="chart-box">
                <div className="chart-tit">{t("player.label.input")}</div>
                <ChartWrapper>{chartData && chartOptions && <Bar data={chartData} options={chartOptions} />}</ChartWrapper>
            </div>
        </Fragment>
    );
};

export const UsageFan = (props) => {
    const { t } = useTranslation();
    const { idx } = props;
    const { lastUpdateTime, auth, workspace } = useContext(AppContext);
    const [period, setPeriod] = useState(0);
    const [fan, setFan] = useState({ label: t("common.code.usage.fan.CL1"), value: "CL1" });
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();
    const fanClosedLoop = [
        { label: t("common.code.usage.fan.CL1"), value: "CL1" },
        { label: t("common.code.usage.fan.CL2"), value: "CL2" },
        { label: t("common.code.usage.fan.OL1"), value: "OL1" },
        { label: t("common.code.usage.fan.OL2"), value: "OL2" },
    ];

    const onLoadData = (page, addFlag) => {
        _API.post({
            path: `/device/devices/${idx}/usage`,
            data: {
                day: period,
                features: ["usage_fan"],
                fan: fan?.value,
            },
        })
            .then((res) => {
                convertChartData(res?.data?.result);
            })
            .catch((e) => {
                console.log(`api error /device/devices/${idx}/usage`, e?.response);
            });
    };

    const onSearch = () => {
        onLoadData(1);
    };

    const convertChartData = (chartList) => {
        // const labels = [...Array(24)].map((m, i) => {
        //     return (i < 9 ? "0" + i : i) + ":00";
        // });
        // const list = [...Array(24)].map((m, i) => 0);
        // let tmpHour = "";
        // let maxVal = 0;
        // for (let i in chartList) {
        //     const item = chartList[i];
        //     const hour = moment(item.reported_at * 1000).hours();

        //     if (hour !== tmpHour) {
        //         if (i > 0) {
        //             list[hour] = maxVal;
        //         }
        //         maxVal = item["fan_" + fan.value + "_rpm"];
        //         tmpHour = hour;
        //     } else {
        //         maxVal = maxVal > item["fan_" + fan.value + "_rpm"] ? maxVal : item["fan_" + fan.value + "_rpm"];
        //     }
        //     if (i == chartList?.length - 1) {
        //         list[hour] = maxVal;
        //     }
        // }
        const labels = [];
        const list = [];
        for (let item of chartList) {
            labels.push(moment(item.reported_at * 1000).toDate());
            list.push({ x: moment(item.reported_at * 1000).toDate(), y: item["fan_" + fan.value + "_rpm"] });
        }

        setChartData({
            labels,
            datasets: [
                {
                    label: t("common.label.fanhistory"),
                    data: list,
                    fill: false,
                    borderColor: "#5689CD",
                    tension: 0.1,
                },
            ],
        });

        setChartOptions({
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            return _U.getOffsetTime(auth, workspace, moment(context[0]?.parsed?.x)?.format("YYYY-MM-DD HH:mm:ss")) || "";
                        },
                        label: (context) => {
                            return t("common.label.fanhistory") + ": " + _U.number3Comma(context.parsed.y) + " RPM";
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    border: { display: false },
                    ticks: {
                        display: true,
                        stepSize: 100,
                        callback: (value, index, ticks) => {
                            return (index < 10 ? "0" + index : index) + ":00";
                        },
                    },
                    type: "time",
                    time: {
                        unit: "hour",
                        tooltipFormat: "H",
                    },
                    grid: { display: false },
                    min: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").valueOf()),
                    max: _U.getOffsetTimeUnix(auth, workspace, moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(period, "day").add(1, "day").valueOf()),
                },
                y: {
                    ticks: {
                        stepSize: 100,
                    },
                    min: 0,
                },
            },
        });
    };

    const goBack = () => {
        props.onBack && props.onBack();
    };

    useEffect(() => {
        onLoadData(1);
    }, []);
    return (
        <Fragment>
            <div className="view-history-con">
                <div className="view-history-top">
                    <a href="#!" role="button" className="btn-back" onClick={goBack}>
                        <span className="ir">{t("subtext.go.previous.screen")}</span>
                    </a>
                    <h3>{t("common.label.fanhistory")}</h3>
                </div>

                <div className="data-list-container">
                    <div className="data-list-top usage-list-top">
                        <div className="left-side">
                            <div className="field">
                                <label className="field-label" id="fanClosedLoopLabel">
                                    {t("common.label.fan.closedloop")}
                                </label>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="fanClosedLoop"
                                            items={fanClosedLoop}
                                            value={fan?.value}
                                            ariaLabelId={"fanClosedLoopLabel"}
                                            selectLabel={fan?.label || "ㅤ"}
                                            onChange={(item) => setFan(item)}
                                            title={t("subtext.title.select.option")}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="field-label" id="fanPeriodLabel">
                                    {t("report.label.period")}
                                </label>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="fanPeriod"
                                            items={_Device.getPeriodList7(t)}
                                            value={period}
                                            ariaLabelId={"fanPeriodLabel"}
                                            selectLabel={_Device.getPeriodList7(t).filter((f) => f.value === period)[0]?.label || "ㅤ"}
                                            onChange={(item) => setPeriod(item?.value)}
                                            title={t("subtext.title.select.option")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn" onClick={onSearch}>
                                {t("button.show")}
                            </button>
                        </div>

                        {/* <div className="right-side">
                                        <div className="error-level">{t("device.label.thresholderror")}</div>
                                    </div> */}
                    </div>
                    <div className="chart-box">
                        <ChartWrapper>{chartData && chartOptions && <Line data={chartData} options={chartOptions} />}</ChartWrapper>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const ChartWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    canvas {
        width: 100% !important;
        max-height: 26.2rem;
    }

    @media (max-width: 720px) {
        canvas {
            height: 100% !important;
            max-height: unset;
        }
    }
`;

export const UsageHistory = (props) => {
    const { t } = useTranslation();
    const { idx, checkTab, deviceInfo } = props;
    const [period, setPeriod] = useState(0);
    const [periodParam, setPeriodParam] = useState(0);

    const onSearch = () => {
        setPeriodParam(period);
    };

    const goBack = () => {
        props.onBack && props.onBack();
    };

    return (
        <Fragment>
            <div className="view-history-con">
                <div className="view-history-top">
                    <a href="#!" role="button" className="btn-back" onClick={goBack}>
                        <span className="ir">{t("subtext.go.previous.screen")}</span>
                    </a>
                    <h3>{t("common.label.usagehistory")}</h3>
                </div>

                <div className="data-list-container">
                    <div className="data-list-top usage-list-top">
                        <div className="left-side">
                            <div className="field">
                                <label className="field-label" id="usagePeriodLabel">
                                    {t("report.label.period")}
                                </label>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="usagePeriod"
                                            items={_Device.getPeriodList7(t)}
                                            value={period}
                                            ariaLabelId={"usagePeriodLabel"}
                                            selectLabel={_Device.getPeriodList7(t).filter((f) => f.value === period)[0]?.label || "ㅤ"}
                                            onChange={(item) => setPeriod(item?.value)}
                                            title={t("subtext.title.select.option")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn" onClick={onSearch}>
                                {t("button.show")}
                            </button>
                        </div>

                        <div className="right-side">
                            <div className="error-level">{t("device.label.thresholderror")}</div>
                            <div className="error-level normal">{t("device.label.Hourlyusage")}</div>
                        </div>
                    </div>

                    {checkTab?.filter((f) => f["_usageCPU"]?.supported)?.length > 0 && <UsageCpu idx={idx} period={periodParam} />}
                    {checkTab?.filter((f) => f["_usageMemory"]?.supported)?.length > 0 && <UsageMemory idx={idx} period={periodParam} />}
                    {checkTab?.filter((f) => f["_usageTemperature"]?.supported)?.length > 0 && <UsageTemperature idx={idx} period={periodParam} />}
                    {checkTab?.filter((f) => f["_usageStorage"]?.supported)?.length > 0 && <UsageStorage idx={idx} period={periodParam} />}
                    {checkTab?.filter((f) => f["_usageInput"]?.supported)?.length > 0 && <UsageInput idx={idx} period={periodParam} deviceInfo={deviceInfo} />}
                </div>
            </div>
        </Fragment>
    );
};
