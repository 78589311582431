import { AppContext } from "@component/AppProvider";
import Modal from "@component/UI/Modal";
import api from "@util/api";
import utils from "@util/utilities";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ViewResultPopup = ({ type = "", action = "", message_id, onClose }) => {
    const { workspace, auth, saveInnerLoading } = useContext(AppContext);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    const getResultName = (item) => {
        if (item) {
            const { state } = item;
            const resultLowerCase = state?.toLowerCase();
            switch (resultLowerCase) {
                case "true":
                    return t("common.label.success");
                case "false":
                    return t("popup.fail.detail");
                default:
                    return resultLowerCase ? resultLowerCase.charAt(0).toUpperCase() + resultLowerCase.slice(1) : "";
            }
        }
    };

    const fetchList = () => {
        const path = "/issue/history/actions/detail";
        saveInnerLoading(true);
        api.get({
            path,
            data: { message_id },
        })
            .then((res) => {
                if (res?.data?.result?.data) setList(res?.data?.result?.data);
            })
            .catch((e) => {
                console.log(`api error /history/actions/detail`, e);
            })
            .finally(() => saveInnerLoading(false));
    };

    const toggleShow = (index) => {
        setList((prevState) => {
            const newList = [...prevState];
            if (!newList[index].hasOwnProperty("isShow")) {
                newList[index] = {
                    ...newList[index],
                    isShow: true,
                };
            } else {
                newList[index] = {
                    ...newList[index],
                    isShow: !newList[index].isShow, // 현재 값의 반대로 변경
                };
            }
            return newList;
        });
    };

    const onClickDetail = (item) => {
        if (item) {
            const { device_id } = item;
            navigate(`/devices/detail/${device_id}`);
        }
    };

    useEffect(() => {
        fetchList(true);
    }, []);

    return (
        <Fragment>
            <Modal id="popup-view-result" className="popup-view-result large" title={t("result.main.title")} ok onClose={onClose} noFooter>
                <div className="data-list-container">
                    <div className="data-list-top">
                        <div className="left-side">
                            <span>
                                {t("common.type")} : {type || ""}
                            </span>
                            <span className="margin-0-10-style">|</span>
                            <span>
                                {t("report.label.action")} : {action || ""}
                            </span>
                        </div>
                        <div className="right-side">
                            <div className="total-count">
                                {t("common.total")} <span className="count">{list?.length || 0}</span>
                            </div>
                        </div>
                    </div>

                    <div className="data-list-bottom">
                        <div className="table">
                            <div className="table-wrapper SelDevice-table">
                                <div className="table-box">
                                    <table id="viewResult-table">
                                        <caption>{t("result.main.title")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="text-block">{t("table.head.date")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("table.head.instalcolgroup")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("table.head.installcolname")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.label.serialnumber")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("result.main.title")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("report.label.details")}</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="viewResult-table-body">
                                            {list?.map(
                                                (v, i) =>
                                                    v && (
                                                        <tr key={i}>
                                                            <td>{v?.created_at ? utils.getOffsetTime(auth, workspace, v?.created_at) : ""}</td>
                                                            <td>{v?.hierarchy_group || ""}</td>
                                                            <td>{v?.alias || ""}</td>
                                                            <td>{v?.serial_number || ""}</td>
                                                            <td>{getResultName(v)}</td>
                                                            <td className="center">{v?.state_detail || "-"}</td>
                                                        </tr>
                                                    )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="convert-table-to-list">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="thead">{t("table.head.date")}</div>
                                    </div>
                                </div>
                                <ul className="table-body">
                                    {list?.map(
                                        (v, i) =>
                                            v && (
                                                <li className="table-row" key={i}>
                                                    <div className="summary-data">
                                                        <div className="row">
                                                            <div className="table-cell alias">
                                                                <button type="button" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                    {v?.created_at ? utils.getOffsetTime(auth, workspace, v?.created_at) : ""}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="all-data-box">
                                                        <ul className="all-data">
                                                            <li>
                                                                <span className="field-label">{t("table.head.instalcolgroup")}</span>
                                                                <span className="field-content">{v?.hierarchy_group || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("table.head.installcolname")}</span>
                                                                <span className="field-content">{v?.alias || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("common.label.serialnumber")}</span>
                                                                <span className="field-content">{v?.serial_number || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("result.main.title")}</span>
                                                                <span className="field-content">{getResultName(v)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("report.label.details")}</span>
                                                                <div className="field-content">{v?.state_detail || "-"}</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default ViewResultPopup;
