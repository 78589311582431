import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cx from "classnames";
import qs from "query-string";

import useCss from "@hook/useCss";
import { SelectBox } from "@component/UI/Form";

import _API from "@util/api";
import { AppContext } from "@component/AppProvider";
import _U from "@util/utilities";
import Dialog from "@component/UI/Dialog";

export default () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth, product, workspace, rolePermission } = useContext(AppContext);
    const [admin, setAdmin] = useState(false);
    const [oriWork, setOriWork] = useState(workspace);

    const checkBusinessAdministrator = () => {
        if (auth && workspace) {
            if (
                auth?.portal_user_roles?.filter(
                    (f) => "PlatformAdministrator" === f.common_role_name || (["BusinessAdministrator"].includes(f.common_role_name) && f.business_id === workspace?.business_id)
                )?.length > 0
            ) {
                setAdmin(true);
            }
        }
    };

    useEffect(() => {
        checkBusinessAdministrator();
    }, [auth]);

    useEffect(async () => {
        console.log("work", product, workspace, oriWork, rolePermission);
        if (JSON.stringify(workspace) != JSON.stringify(oriWork)) {
            location.href = "/";
        }
    }, [workspace]);

    return (
        <Fragment>
            {!product ? (
                <div className="common-policy">
                    <div className="common-policy-box">
                        <div className="img-box">
                            <img src="../../assets/images/img_nolicense.svg" alt={t("subtext.nolicense")} />
                        </div>
                        <div className="txt-box">
                            <strong>{t("common.message.nolicense.worspace")}</strong>
                            {!admin && <div>{t("common.message.nolicense.workspace.check")}</div>}

                            {admin && (
                                <button type="button" className="btn btn-more" onClick={() => navigate("/administration/license")}>
                                    {t("common.desc.goLicense")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="common-policy">
                    <div className="common-policy-box">
                        <div className="img-box">
                            <img src="../../assets/images/img_norole.svg" alt={t("subtext.norole")} />
                        </div>
                        <div className="txt-box">
                            {!rolePermission && <strong>{t("common.message.norole.menu")}</strong>}
                            {rolePermission && <strong>{t("common.message.nopermission.menu")}</strong>}
                            {/* {admin && <div>{t("common.message.nopermission.menu")}</div>} */}
                            <div>{t("common.message.nolicense.workspace.check")}</div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};
