import Modal from "@component/UI/Modal";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { command_list, cmd_list, list } from "./constants/constant";
import { InputCalendar, InputDatepicker, NumberInput, SelectBox, VolumeInput } from "@component/UI/Form";
import SelectFile from "@component/AlertMessage/TemplatePopup/SelectFile";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";
import _API from "@util/api";
import { useTranslation } from "react-i18next";
import utils from "@util/utilities";

const RegisterCommandPopup = ({ onClose, onRegister, totalCount = 0 }) => {
    const { targetModel, saveTargetModel } = useContext(AppContext);
    const { t } = useTranslation();
    const executionDateRef = useRef(null);

    const [title, setTitle] = useState(`Command_${command_list?.type[0]}_${totalCount + 1}`);
    const [commonField, setCommonField] = useState({
        type: command_list?.type[0],
        execution: cmd_list?.execution[0],
        executionTime: {
            type: cmd_list?.executionType[0]?.value,
            hours: "09",
            minutes: "00",
        },
        checkedDays: [],
    });
    const [executionDate, setExecutionDate] = useState("");
    const [screenField, setScreenField] = useState({
        type: command_list?.screenType[0].name,
    });
    const [inputField, setInputField] = useState({
        label: command_list?.inputType[0].label,
        value: command_list?.inputType[0].value,
    });
    const [touchLockField, setTouchLockField] = useState({
        type: command_list?.lockType[0]?.name,
    });
    const [screenLockField, setScreenLockField] = useState({
        type: command_list?.screenLockType[0]?.name,
        password: "",
        setPassword: false,
    });
    const [brightnessField, setBrightnessField] = useState({ brightness: 0 });
    const [volume, setVolume] = useState(0);
    const [sendFileField, setSendFileField] = useState({
        file: null,
        fileName: "",
    });
    const [fileType, setFileType] = useState("video");
    const [isMultiple, setIsMultiple] = useState(false);
    const [showFileSelect, setShowFileSelect] = useState(false);

    const showFileSelector = (fileType = "file", isShow = true) => {
        setFileType(fileType);
        setShowFileSelect(isShow);
        setIsMultiple(false);
    };

    const changeExecutionTimeState = (key, value) => {
        setCommonField((prevCommonField) => {
            return {
                ...prevCommonField,
                executionTime: {
                    ...prevCommonField.executionTime,
                    [key]: value,
                },
            };
        });
    };
    const handleCheckedDays = (value) => {
        setCommonField((prevCommonField) => {
            const isChecked = prevCommonField.checkedDays.includes(value);

            if (isChecked) {
                const updatedCheckedDays = prevCommonField.checkedDays.filter((day) => day !== value);
                return {
                    ...prevCommonField,
                    checkedDays: updatedCheckedDays,
                };
            } else {
                const updatedCheckedDays = [...prevCommonField.checkedDays, value];
                return {
                    ...prevCommonField,
                    checkedDays: updatedCheckedDays,
                };
            }
        });
    };

    const onChangeFile = (fileData) => {
        showFileSelector("file", false);
        if (!fileData?.length) return;
        const fileKeyParts = fileData[0].Key.split("/");
        setSendFileField({
            ...sendFileField,
            file: fileData[0].Key,
            fileName: fileKeyParts[fileKeyParts.length - 1],
        });
    };

    const getExecutionJsx = () => {
        const time = (
            <div className="field field-type-dropdown field-repeat required">
                <label className="field-label">{t("common.time")}</label>
                <div className="field-form">
                    <div className="field-box">
                        <SelectBox
                            items={list?.executionType}
                            value={commonField.executionTime?.type}
                            selectLabel={commonField.executionTime?.type || ""}
                            onChange={(item) => {
                                const { value } = item;
                                changeExecutionTimeState("type", value);
                            }}
                        />
                        <SelectBox
                            items={Array.from({ length: 12 }, (_, index) => {
                                const minNumber = (index + 1).toString().padStart(2, "0");
                                return { label: minNumber, value: minNumber };
                            })}
                            value={commonField.executionTime?.hours}
                            selectLabel={commonField.executionTime?.hours || ""}
                            onChange={(item) => {
                                const { value } = item;
                                changeExecutionTimeState("hours", value);
                            }}
                        />
                        <span className="colon pc-only"> : </span>
                        <SelectBox
                            items={Array.from({ length: 60 }, (_, index) => {
                                const minuteNumber = index.toString().padStart(2, "0");
                                return { label: minuteNumber, value: minuteNumber };
                            })}
                            value={commonField.executionTime?.minutes}
                            selectLabel={commonField.executionTime?.minutes || ""}
                            onChange={(item) => {
                                const { value } = item;
                                changeExecutionTimeState("minutes", value);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
        switch (commonField.execution) {
            case "Specific":
                return (
                    <>
                        <div className="field field-type-message required">
                            <div className="field-header">
                                <label htmlFor="execution-date" className="field-title">
                                    {t("table.head.actiondate")}
                                </label>
                            </div>
                            <div className="field-form">
                                <InputCalendar
                                    name="executionDate"
                                    id="execution-date"
                                    className="execution-date"
                                    placeholder="YYYY-MM-DD"
                                    autoComplete="false"
                                    ariaLabel={t("table.head.actiondate")}
                                    value={executionDate || null}
                                    onChange={({ executionDate }) => {
                                        setExecutionDate(executionDate);
                                    }}
                                />
                            </div>
                        </div>
                        {time}
                    </>
                );
            case "Repeat":
                return (
                    <>
                        <div className="field field-type-checkbox required">
                            <label className="field-label">{t("device.label.dayofweek")}</label>
                            <div className="field-form">
                                <div className="field-box">
                                    {cmd_list?.executionDayOfWeek?.map((x, i) => (
                                        <div className="checkbox" key={i}>
                                            <input
                                                type="checkbox"
                                                name="checkbox-type"
                                                id={`checkbox-${i + 1}`}
                                                checked={commonField.checkedDays.includes(x)}
                                                onClick={() => handleCheckedDays(x)}
                                                readOnly
                                            />
                                            <label htmlFor={`checkbox-${i + 1}`}>{x}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {time}
                    </>
                );
        }
    };

    const getFromByType = () => {
        switch (commonField.type) {
            case "Screen On/Off":
                return (
                    <div className="field field-type-template">
                        <div className="field-header">
                            <label htmlFor="ip-template-type-2" className="field-title">
                                {t("common.type")}
                            </label>
                        </div>
                        <div className="field-form">
                            {command_list?.screenType?.map((x, i) => (
                                <div
                                    className="radio"
                                    key={i}
                                    onClick={() =>
                                        setScreenField({
                                            ...screenField,
                                            type: x.name,
                                        })
                                    }
                                >
                                    <input type="radio" name={x.name} checked={screenField.type === x.name} readOnly />
                                    <label htmlFor={x.name}>{x.txt}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case "Input":
                return (
                    <div className="field field-type-message required">
                        <div className="field-header">
                            <label htmlFor="ip-message-type" className="field-title">
                                {t("common.label.InputType")}
                            </label>
                        </div>
                        <div className="field-form">
                            <div className="input-box">
                                <SelectBox
                                    items={command_list?.inputType}
                                    value={inputField.value}
                                    selectLabel={inputField.label || ""}
                                    onChange={(item) => {
                                        const { value, label } = item;
                                        setInputField({ ...inputField, value, label });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );

            case "Touch Lock":
            case "IR Lock":
            case "USB Lock":
                const key = commonField.type.replace(" Lock", "").toLowerCase();
                return (
                    <div className="field field-type-template">
                        <div className="field-header">
                            <label htmlFor="ip-template-type-3" className="field-title">
                                {t("common.type")}
                            </label>
                        </div>
                        <div className="field-form">
                            {command_list?.[`${key}LockType`]?.map((x, i) => (
                                <div
                                    className="radio"
                                    key={i}
                                    onClick={() =>
                                        setTouchLockField({
                                            ...touchLockField,
                                            type: x.name,
                                        })
                                    }
                                >
                                    <input type="radio" name={x.name} checked={touchLockField.type === x.name} readOnly />
                                    <label htmlFor={x.name}>{x.txt}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                );

            case "Screen Lock":
                return (
                    <>
                        <div className="field field-type-template">
                            <div className="field-header">
                                <label htmlFor="ip-template-type-4" className="field-title">
                                    {t("common.type")}
                                </label>
                            </div>
                            <div className="field-form">
                                {command_list?.screenLockType?.map((v, i) => (
                                    <div className="radio" key={i} onClick={() => setScreenLockField({ ...screenLockField, type: v.name })}>
                                        <input type="radio" name={v.name} id={v.name} checked={v.name === screenLockField.type} readOnly />
                                        <label htmlFor={v.name}>{v.txt}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {!screenLockField.setPassword && screenLockField.type === "screen lock" && (
                            <div className="field field-type-message required">
                                <div className="field-header">
                                    <label htmlFor="ip-message-type" className="field-title">
                                        {t("common.passwd")}
                                    </label>
                                </div>
                                <div className="field-form">
                                    <div className="input-box">
                                        <input
                                            type="password"
                                            id="ip-message-type"
                                            placeholder="Password"
                                            autoComplete="false"
                                            aria-required="true"
                                            aria-invalid="true"
                                            aria-describedby="description-id-password"
                                            value={screenLockField.password}
                                            maxLength={4}
                                            onChange={(e) => {
                                                const onlyNumbers = e.target.value.replace(/\D/g, "");
                                                if (onlyNumbers !== e.target.value) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setScreenLockField({
                                                    ...screenLockField,
                                                    password: onlyNumbers,
                                                });
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className={`btn-input-remove ${!screenLockField.password && "hide"}`}
                                            onClick={() => setScreenLockField({ ...screenLockField, password: "" })}
                                        >
                                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                        </button>
                                    </div>
                                </div>
                                <span id="description-id-password" className="field-description">
                                    {t("subtext.desc.password.device")}
                                </span>
                            </div>
                        )}

                        {screenLockField.type === "screen lock" && (
                            <div className="field field-type-template">
                                <div className="field-header">
                                    <label htmlFor="ip-template-type-1" className="field-title">
                                        <span className="ir">{t("schedule.code.usepreviouslysetpassword")}</span>
                                    </label>
                                </div>
                                <div className="field-form">
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="checkbox-set-password"
                                            id="checkbox-set-password"
                                            checked={screenLockField.setPassword}
                                            onChange={(e) =>
                                                setScreenLockField({
                                                    ...screenLockField,
                                                    setPassword: e.target.checked,
                                                })
                                            }
                                        />
                                        <label htmlFor="checkbox-set-password">{t("schedule.code.usepreviouslysetpassword")}</label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                );
            case "Brightness":
                return (
                    <NumberInput
                        isRequired
                        title={t("player.label.brightness")}
                        desc={t("common.set_between_min", { 0: "0", 1: "100" })}
                        id="brightness-field"
                        descId="description-id-brightness"
                        min={0}
                        max={100}
                        value={brightnessField.brightness}
                        onChange={(value) =>
                            setBrightnessField({
                                ...brightnessField,
                                brightness: value,
                            })
                        }
                    />
                );
            case "Volume":
                return <VolumeInput isRequired id="volume-field" descId="description-id-volume" value={volume} onChange={(value) => setVolume(value)} />;

            case "Send File":
            case "Install Package":
                return (
                    <div className="field field-type-file required">
                        <div className="field-header">
                            <label htmlFor="command-send-file" className="field-title">
                                {t("filebox.label.file")}
                            </label>
                        </div>
                        <div className="field-form">
                            <div className="field-box">
                                <input type="text" id="command-send-file" readOnly placeholder="File" value={sendFileField.fileName} />
                                <button type="button" className="btn btn-tertiary" onClick={() => showFileSelector(commonField.type === "Install Package" ? "app" : "file", true)}>
                                    {t("device.label.select")}
                                </button>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    const getCountCommonFieldNotEnter = () => {
        const { execution, checkedDays } = commonField;
        const group_id = targetModel?.groupList?.map((v) => v.group_id) || [];
        let count = 0;
        if (!title) count++;
        if (!targetModel?.model_name || !group_id?.length) count++;

        const exLower = execution.toLowerCase();
        switch (exLower) {
            case "specific":
                if (!executionDate) count++;
                break;
            case "repeat":
                if (!checkedDays.length) count++;
                break;
        }
        return count;
    };

    const clickRegister = () => {
        const { type, execution, executionTime, checkedDays } = commonField;
        const group_id = targetModel?.groupList?.map((v) => v.group_id) || [];

        // 2개 이상 입력안한거 유효성 검사
        let count = getCountCommonFieldNotEnter();
        switch (type) {
            case "Screen Lock":
                if (!screenLockField?.setPassword && screenLockField?.type === "screen lock") count++;
                break;
            case "Brightness":
                if (!brightnessField?.brightness?.toString()?.length) count++;
                break;
            case "Volume":
                if (!volume?.toString()?.length) count++;
                break;

            case "Send File":
            case "Install Package":
                if (!sendFileField?.file) count++;
                break;
        }
        if (count >= 2) {
            dialogRequiredField();
            return;
        }

        let data = {};
        if (title) data.title = title;
        if (targetModel?.model_name) data.model_name = targetModel?.model_name;
        if (group_id?.length) data.group_id = group_id;
        if (!title) {
            pleaseEnterDialog(t("form.label.title"));
            return;
        }
        if (!targetModel?.model_name || !group_id?.length) {
            pleaseEnterDialog(t("common.target"));
            return;
        }
        if (type) {
            data.command_value = {};
            data.command_type = type;
            let command_value = {};
            switch (type) {
                case "Screen On/Off":
                    if (screenField.type) command_value.value = screenField.type;
                    break;
                case "Input":
                    if (inputField.value) command_value.value = inputField.value;
                    break;

                case "Touch Lock":
                case "IR Lock":
                case "USB Lock":
                    if (touchLockField.type) {
                        command_value.value = touchLockField.type;
                    }
                    break;

                case "Screen Lock":
                    if (screenLockField?.type) {
                        command_value.value = screenLockField?.type;
                    }
                    if (screenLockField?.password) {
                        command_value.pw = screenLockField?.password;
                    } else if (!screenLockField?.setPassword && screenLockField?.type === "screen lock") {
                        pleaseEnterDialog(t("common.passwd"));
                        return;
                    }
                    command_value.use_previous_pw = screenLockField?.setPassword ? "on" : "off";
                    if (screenLockField?.type === "screen lock" && command_value?.use_previous_pw === "off" && command_value?.pw?.length !== 4) {
                        Dialog({
                            title: t("popup.title.inform"),
                            text: t("Password.must_be.4digit"),
                            button: t("common.label.confirm"),
                        });
                        return;
                    }
                    break;
                case "Brightness":
                    if (!brightnessField.brightness.toString().length) {
                        pleaseEnterDialog(t("player.label.brightness"));
                        return;
                    }
                    const intBrightness = parseInt(brightnessField.brightness);
                    if (intBrightness <= 0) command_value.value = "0";
                    else command_value.value = utils.toHex(intBrightness);
                    break;
                case "Volume":
                    if (!volume.toString().length) {
                        pleaseEnterDialog(t("schedule.commend.set_volume"));
                        return;
                    }
                    const intVolume = parseInt(volume);
                    if (intVolume <= 0) command_value.value = "0";
                    else command_value.value = utils.toHex(intVolume);
                    break;

                case "Send File":
                case "Install Package":
                    command_value.value = type?.toLowerCase();
                    if (sendFileField.file) {
                        command_value.file = [sendFileField.file];
                    } else {
                        pleaseEnterDialog(t("filebox.label.file"));
                        return;
                    }
                    break;
            }
            data.command_value.desc = command_value;
            if (execution) {
                const exLower = execution.toLowerCase();
                let executionData = {};
                executionData.execution_type = exLower;

                switch (exLower) {
                    case "specific":
                        if (executionDate) executionData.date = executionDate;
                        else {
                            pleaseEnterDialog(t("table.head.actiondate"));
                            return;
                        }
                        break;
                    case "repeat":
                        if (checkedDays?.length) {
                            executionData.day_of_week = checkedDays;
                        } else {
                            pleaseEnterDialog(t("device.label.dayofweek"));
                            return;
                        }
                        break;
                }
                const { hours, minutes } = executionTime;
                const parseIntHours = executionTime.type === "AM" ? parseInt(hours) : parseInt(hours) + 12;
                const formattedHours = String(parseIntHours).padStart(2, "0");
                const formattedMinutes = String(minutes).padStart(2, "0");
                executionData.time = `${formattedHours}:${formattedMinutes}`;
                data.execution = executionData;
            }
        }

        _API.post({
            path: `/content/schedule/command`,
            data,
        })
            .then((res) => {
                onRegister();
            })
            .catch((e) => {
                console.log(`api error /content/schedule/command`, e);
                let msg = "";
                if (e?.response?.data?.status?.code === "00-006" && e?.response?.data?.error === "duplicated title") {
                    msg = t("subtext.error.itemDupTitle");
                } else if (e?.response?.data?.error?.error) {
                    msg = e.response.data.error.error;
                } else if (e?.response?.data?.error) {
                    msg = e?.response?.data?.error;
                }
                Dialog({
                    title: t("popup.title.inform"),
                    text: msg,
                    button: t("common.label.confirm"),
                });
            });
    };

    const onClickCancel = () => {
        Dialog({
            title: t("common.button.leave"),
            text: t("subtext.message.leavepage"),
            button: [t("common.button.stay"), t("common.button.leave")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            onClose();
        });
    };

    const dialogRequiredField = () => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("popup.inform.multiItem"),
            button: t("common.label.confirm"),
        });
    };

    const pleaseEnterDialog = (name) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("popup.inform.sigleItem", { n: name }),
            button: t("common.label.confirm"),
        });
    };

    useEffect(() => {
        saveTargetModel({
            open: false,
        });
        console.log("yes");
    }, []);

    useEffect(() => {
        const key = commonField.type.replace(" Lock", "").toLowerCase();
        if (key.includes("touch") || key.includes("ir") || key.includes("usb")) {
            const type = command_list?.[`${key}LockType`][0].name;
            setTouchLockField({ type });
        }
        setTitle(`Command_${commonField.type}_${totalCount + 1}`);
    }, [commonField.type]);

    return (
        <Fragment>
            <Modal
                noFocusTrap
                id="popup-add-template"
                className="popup-add-template"
                title={t("main.btnRegister.title")}
                buttonOkTitle={t("main.btnRegister.title")}
                onClose={onClickCancel}
                onClick={clickRegister}
            >
                <div className="form-guide algin-left">
                    <span>*</span> {t("common.label.isrequired")}
                </div>
                <div className="field field-type-message required">
                    <div className="field-header">
                        <label htmlFor="ip-message-type-commandtype" className="field-title">
                            {t("common.label.CommandType")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="input-box">
                            <SelectBox
                                id="ip-message-type-commandtype"
                                items={command_list?.type?.map((v) => {
                                    return { value: v, label: v };
                                })}
                                value={commonField.type}
                                selectLabel={commonField.type || ""}
                                onChange={(item) => {
                                    const { value } = item;
                                    setCommonField({ ...commonField, type: value });
                                }}
                                aria-describedby="description-id-commandtype"
                            />
                        </div>
                    </div>
                    <span id="description-id-commandtype" className="field-description">
                        {t("common.only_files_uploaded")}
                    </span>
                </div>

                <div className="field field-type-message required">
                    <div className="field-header">
                        <label htmlFor="ip-message-type-title" className="field-title">
                            {t("commom.popup.title")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="input-box">
                            <input
                                type="text"
                                id="ip-message-type-title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                autoComplete="false"
                                aria-required="true"
                                aria-invalid="true"
                                maxLength={100}
                            />
                            <button type="button" className={`btn-input-remove ${title ? "" : "hide"}`} onClick={() => setTitle("")}>
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                        <div className="word-count">({title.length}/100)</div>
                    </div>
                </div>

                <div className="field field-type-message required">
                    <div className="field-header">
                        <label htmlFor="ip-message-type-cmd" className="field-title">
                            {t("common.label.Execution")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="input-box">
                            <SelectBox
                                id="ip-message-type-cmd"
                                items={cmd_list?.execution?.map((v) => {
                                    return { value: v, label: v };
                                })}
                                value={commonField.execution}
                                selectLabel={commonField.execution || ""}
                                onChange={(item) => {
                                    const { value } = item;
                                    setCommonField({ ...commonField, execution: value });
                                }}
                            />
                        </div>
                    </div>
                </div>
                {getExecutionJsx()}
                {getFromByType()}

                <div className="field field-type-message required">
                    <div className="field-header">
                        <label className="field-title">{t("common.label.target.modelNgroup")}</label>
                    </div>
                    <div className="field-form">
                        <div className="message-target-wrap">
                            {!targetModel?.model_name && !targetModel?.groupList?.length && <div className="empty-txt whitespace-pre-line">{t("common.desc.target.select.no")}</div>}
                            {(targetModel?.model_name || targetModel?.groupList?.length) && (
                                <dl className="message-target-info">
                                    <dt>{t("common.label.target.selected.model")}</dt>
                                    <dd>{targetModel?.model_name}</dd>
                                    <dt>{t("devicemanagement.tree.group")}</dt>
                                    <dd className="message-target">
                                        {targetModel?.groupList?.map((item, index) => {
                                            return (
                                                <div className="message-target-item" key={index}>
                                                    {item?.treeName?.join(" > ")}
                                                </div>
                                            );
                                        })}
                                    </dd>
                                </dl>
                            )}
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn btn-tertiary btn-right-arrow btn-set-target"
                        onClick={() =>
                            saveTargetModel({
                                ...targetModel,
                                open: true,
                                model: true,
                                group: true,
                                headLabel: "modelNgroup",
                            })
                        }
                    >
                        {t("common.button.set.target")}
                    </button>
                </div>
            </Modal>
            {showFileSelect && <SelectFile onClose={() => showFileSelector("file", false)} fileType={fileType} onClick={onChangeFile} isMultiple={isMultiple} />}
        </Fragment>
    );
};

export default RegisterCommandPopup;
