import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";

export const SideMenu = (props) => {
    const { t } = useTranslation();
    const tabRef = useRef(null);

    const [historyList, setHistoryList] = useState([
        { name: t("history.issue"), href: "/history/issue", id: "issue_history" },
        { name: t("common.label.history.action"), href: "/history/action", id: "action_history" },
        { name: t("common.label.history.access"), href: "/history/access", id: "access_history" },
        { name: t("common.label.history.license"), href: "/history/license", id: "license_history" },
    ]);
    const { sideMenu, onClose } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const navigatePage = (e, v) => {
        e.preventDefault();
        navigate(v.href);
    };

    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowDown" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    return (
        <div className={cx({ "site-group-list-container": true, active: sideMenu })}>
            <div className="header header-site">
                <button type="button" className="btn btn-back" onClick={onClose}>
                    <span className="ir">{t("common.label.CollapseMenu")}</span>
                </button>
                <div className="title">{t("player.label.physical")}</div>
            </div>

            <div className="child-menu-container">
                <div className="category-menu">
                    <ul aria-orientation="vertical" ref={tabRef}>
                        {historyList?.map((v, i) => (
                            <li key={i} id={v.id} className={cx({ active: location.pathname.includes(v.href) })} role="none presentation">
                                <a
                                    href={v.href}
                                    onClick={(e) => navigatePage(e, v)}
                                    aria-current={location.pathname.includes(v.href) ? "true" : "false"}
                                    // onKeyDown={onTabAreaKeyDown}
                                >
                                    {v.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export const Filter = (props) => {
    const { menu } = props;

    const [level, setLevel] = useState([]);
    const [os, setOs] = useState([]);
    const [issue, setIssue] = useState([]);
    const [type, setType] = useState([]);
    const [browser, setBrowser] = useState([]);

    const [filterList, setFilterList] = useState({
        os: ["webos", "windows", "android", "display"],
        issueCode: [
            "GEN-01-RES",
            "GEN-01-SIG",
            "GEN-01-TEM",
            "GEN-01-NSP",
            "GEN-01-NIP",
            "GEN-01-CPU",
            "GEN-01-MEM",
            "GEN-01-DSK",
            "GEN-01-DIF",
            "LED-01-TEM",
            "LED-02-TEM",
            "LED-04-TEM",
            "LED-01-NET",
            "LED-01-SIG",
            "LED-01-CON",
            "LED-02-LDD",
            "LED-04-PSU",
            "GEN-01-FAN",
            "GEN-01-HUM",
            "GEN-01-DOO",
            "GEN-01-BLU",
            "GEN-01-PXL",
            "GEN-01-FLT",
            "GEN-01-IMP",
            "GEN-01-VOL",
            "GEN-01-FAP",
            "GEN-01-RES",
        ],
    });

    const onIssueChange = (e) => {
        const tmp = JSON.parse(JSON.stringify(issue));
        if (e.target.checked) {
            tmp.push(e.target.value);
        } else {
            tmp.splice(tmp.indexOf(e.target.value), 1);
        }
        setIssue(tmp);
    };

    const onOsChange = (e) => {
        const tmp = os.concat();
        if (e.target.checked) {
            tmp.push(e.target.value);
        } else {
            tmp.splice(tmp.indexOf(e.target.value), 1);
        }
        setOs(tmp);
    };

    const onTypeChange = (e) => {
        const tmp = JSON.parse(JSON.stringify(type));
        if (e.target.checked) {
            tmp.push(e.target.value);
        } else {
            tmp.splice(tmp.indexOf(e.target.value), 1);
        }
        setType(tmp);
    };

    const onBrowserChange = (e) => {
        const tmp = JSON.parse(JSON.stringify(browser));
        if (e.target.checked) {
            tmp.push(e.target.value);
        } else {
            tmp.splice(tmp.indexOf(e.target.value), 1);
        }
        setBrowser(tmp);
    };
    const onReset = () => {
        setLevel([]);
        setOs([]);
        setIssue([]);
        setType([]);
        setBrowser([]);
    };

    const onApply = () => {
        props.onSetFilter &&
            props.onSetFilter({
                level,
                os_type: os,
                issue_code: issue,
                type,
                browser,
            });
    };

    useEffect(() => {
        if (props.filter) {
            const { filter } = props;
            setLevel(props.filter?.level);
            if (filter.hasOwnProperty("os_type")) setOs(props.filter?.os_type);
            setType(props.filter?.type);
            if (filter.hasOwnProperty("issue_code")) setIssue(props.filter?.issue_code);
            setBrowser(props.filter?.browser);
        }
    }, [props.filter]);

    return (
        <div
            id="popup-filter-option"
            className={cx({
                "filter-options": true,
                expand: props.show,
                show: props.show,
            })}
            role="dialog"
        >
            <div className="filter-options-top">
                <h2 className="title-pc">Filter</h2>
                <button type="button" className="btn-close" onClick={props.onClose}>
                    <span className="ir">close</span>
                </button>
            </div>

            <div className="filter-options-middle">
                <div className="option-box">
                    {menu === "ISSUE" && (
                        <Fragment>
                            <div className="field field-type-checkbox field-device-type">
                                <h3 className="field-label">OS</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.os?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input type="checkbox" name="checkbox-device_type" id={`checkbox-device_type-${i}`} value={v} checked={os?.includes(v)} onChange={onOsChange} />
                                                <label htmlFor={`checkbox-device_type-${i}`}>{v}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="field field-type-checkbox field-issue-statue">
                                <h3 className="field-label">Issue Type</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.issueCode?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input type="checkbox" name="checkbox-issue-status" id={`checkbox-issue-status-${i}`} value={v} checked={issue?.includes(v)} onChange={onIssueChange} />
                                                <label htmlFor={`checkbox-issue-status-1${i}`}>{v}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    {menu === "ACTION" && (
                        <div className="field field-type-checkbox field-issue-statue">
                            <h3 className="field-label">Type</h3>
                            <div className="field-form">
                                <div className="field-box not-flex">
                                    <div className="checkbox">
                                        <input type="checkbox" name="checkbox-issue-status" id="checkbox-issue-status-1" value="TYPE1" checked={type?.includes("TYPE1")} onChange={onTypeChange} />
                                        <label htmlFor="checkbox-issue-status-1">Type name</label>
                                    </div>
                                    <div className="checkbox">
                                        <input type="checkbox" name="checkbox-issue-status" id="checkbox-issue-status-2" value="TYPE2" checked={type?.includes("TYPE2")} onChange={onTypeChange} />
                                        <label htmlFor="checkbox-issue-status-2">Type name</label>
                                    </div>
                                    <div className="checkbox">
                                        <input type="checkbox" name="checkbox-issue-status" id="checkbox-issue-status-3" value="TYPE3" checked={type?.includes("TYPE3")} onChange={onTypeChange} />
                                        <label htmlFor="checkbox-issue-status-3">Type name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {menu === "ACCESS" && (
                        <Fragment>
                            <div className="field field-type-checkbox field-device-type">
                                <h3 className="field-label">OS</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        <div className="checkbox">
                                            <input type="checkbox" name="checkbox-device_type" id="checkbox-device_type-1" value="WEBOS" checked={os?.includes("WEBOS")} onChange={onOsChange} />
                                            <label htmlFor="checkbox-device_type-1">webOS</label>
                                        </div>
                                        <div className="checkbox">
                                            <input type="checkbox" name="checkbox-device_type" id="checkbox-device_type-2" value="WINDOWS" checked={os?.includes("WINDOWS")} onChange={onOsChange} />
                                            <label htmlFor="checkbox-device_type-2">Windows</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field field-type-checkbox field-issue-statue">
                                <h3 className="field-label">Browser</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                name="checkbox-issue-status"
                                                id="checkbox-issue-status-1"
                                                value="BROWSER1"
                                                checked={browser?.includes("BROWSER1")}
                                                onChange={onBrowserChange}
                                            />
                                            <label htmlFor="checkbox-issue-status-1">Browser name</label>
                                        </div>
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                name="checkbox-issue-status"
                                                id="checkbox-issue-status-2"
                                                value="BROWSER2"
                                                checked={browser?.includes("BROWSER2")}
                                                onChange={onBrowserChange}
                                            />
                                            <label htmlFor="checkbox-issue-status-2">Browser name</label>
                                        </div>
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                name="checkbox-issue-status"
                                                id="checkbox-issue-status-3"
                                                value="BROWSER3"
                                                checked={browser?.includes("BROWSER3")}
                                                onChange={onBrowserChange}
                                            />
                                            <label htmlFor="checkbox-issue-status-3">Browser name</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}

                    {menu === "LICENSE" && (
                        <div className="field field-type-checkbox field-issue-statue">
                            <h3 className="field-label">Type</h3>
                            <div className="field-form">
                                <div className="field-box not-flex">
                                    <div className="checkbox">
                                        <input type="checkbox" name="checkbox-issue-status" id="checkbox-issue-status-1" value="TYPE1" checked={type?.includes("TYPE1")} onChange={onTypeChange} />
                                        <label htmlFor="checkbox-issue-status-1">Type name</label>
                                    </div>
                                    <div className="checkbox">
                                        <input type="checkbox" name="checkbox-issue-status" id="checkbox-issue-status-2" value="TYPE2" checked={type?.includes("TYPE2")} onChange={onTypeChange} />
                                        <label htmlFor="checkbox-issue-status-2">Type name</label>
                                    </div>
                                    <div className="checkbox">
                                        <input type="checkbox" name="checkbox-issue-status" id="checkbox-issue-status-3" value="TYPE3" checked={type?.includes("TYPE3")} onChange={onTypeChange} />
                                        <label htmlFor="checkbox-issue-status-3">Type name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="button-box apply">
                    <button type="button" className="btn btn-dxp btn-reflash" onClick={onReset}>
                        Reset
                    </button>
                    <button type="button" className="btn btn-dxp btn-apply" onClick={onApply}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};
