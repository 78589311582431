import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";

import { useNavigate } from "react-router-dom";
import Modal from "@component/UI/Modal";
import _API from "@util/api";
import _CK from "@util/cookie";
import utils from "@util/utilities";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";
import { SelectBox } from "@component/UI/Form";
import _U from "@util/utilities";

export const SiteToolbar = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { auth, businessList, workspace, saveWorkspace } = useContext(AppContext);

    const [list, setList] = useState([
        // {
        //     idx: 1,
        //     type: "B",
        //     title: "Site Name 1",
        //     disabled: false,
        //     child: [
        //         { idx: 11, type: "W", title: "Work space 1", disabled: false },
        //         { idx: 12, type: "W", title: "Work space 2", disabled: true },
        //     ],
        // },
        // { idx: 2, type: "B", title: "Site Name 2", disabled: false },
        // { idx: 3, type: "B", title: "Site Name 3", disabled: false },
    ]);
    const areaRef = useRef(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const onSetWorkspace = (item) => {
        saveWorkspace && saveWorkspace(item);
        props.onClose();
    };

    useEffect(() => {
        setList(businessList);
        if (
            auth?.portal_user_roles?.filter((f) => f?.common_role_name === "PlatformAdministrator")?.length > 0 ||
            auth?.portal_user_roles?.filter((f) => ["BusinessAdministrator"].includes(f?.common_role_name) && f?.business_id === workspace?.business_id)?.length > 0
        ) {
            setIsAdmin(true);
        }
    }, [businessList]);

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                props.onClose();
            }
        }
        document.addEventListener("mousedown", handleOutside);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
        };
    }, [areaRef]);

    return (
        <Fragment>
            <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
                <div
                    className={cx({
                        menu: true,
                        pannel: true,
                        "site-menu": true,
                        admin: isAdmin,
                        expand: props?.show,
                    })}
                    ref={areaRef}
                >
                    <h2 className="menu-title">{t("dv.site.list")}</h2>
                    <ul role="menu" id="site-menu" className="site-menuul">
                        {list?.map((item, index) => (
                            <li className={cx({ disabled: true })} key={index}>
                                <a href="#!" className="btn btn-Business" tabIndex={-1}>
                                    <em role="img" aria-label={t("business")}>
                                        {"B"}
                                    </em>
                                    {item?.business_name}
                                </a>
                                {item?.properties?.length > 0 && (
                                    <ul className="site-menu-depth2">
                                        {item?.properties?.map((sItem, sIndex) => (
                                            <li key={sIndex} className={cx({ active: workspace?.property_id === sItem?.property_id })}>
                                                <a
                                                    href="#!"
                                                    className="btn btn-Workspace"
                                                    onClick={() => onSetWorkspace({ ...sItem, business_id: item?.business_id })}
                                                    aria-current={workspace?.property_id === sItem?.property_id}
                                                >
                                                    <em role="img" aria-label={t("common.label.workspace")}>
                                                        {"W"}
                                                    </em>
                                                    {sItem?.property_name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                    <button type="button" className="btn btn-close" onClick={props?.onClose}>
                        {t("button.close")}
                    </button>
                </div>
            </FocusTrap>
        </Fragment>
    );
};

export const SessionToolbar = (props) => {
    const { t } = useTranslation();
    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    const { auth, saveAuth } = useContext(AppContext);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    clearInterval(countdown);
                } else {
                    setSeconds(59);
                    setMinutes(parseInt(minutes) - 1);
                }
            }
        }, 1000);

        if (minutes == 5 && seconds == 0) {
            onWarningMessage("HALF");
        }
        if (minutes <= 0 && seconds <= 0) {
            onWarningMessage("LOGOUT");
        }
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    const onWarningMessage = (type) => {
        if (type === "HALF") {
            Dialog({
                title: t("popup.title.inform"),
                text: t("alertmsg.popup.session.expire.5"),
                button: [t("button.cancel"), t("button.extend")],
            }).then((ok) => {
                if (ok) {
                    onSessionExtend(true);
                }
            });
        }
        if (type === "LOGOUT") {
            Dialog({ title: t("popup.title.inform"), text: t("alertmsg.popup.session.expire"), button: t("common.ok") }).then((id) => {
                onSessionExtend(false);
                _API.get({ path: "/user/auth/logout", data: {} })
                    .then((res) => {
                        _API.onAccessLog("logout");
                        if (res?.data?.result) {
                            saveAuth(null);
                            switch (process.env.STAGE) {
                                case "development":
                                    location.href = process.env.BUSINESS_CLOUD_URL;
                                    break;
                                case "staging":
                                    location.href = process.env.BUSINESS_CLOUD_URL;
                                    break;
                                case "production":
                                    location.href = process.env.BUSINESS_CLOUD_URL;
                                    break;
                                default:
                                    location.href = "/";
                                    break;
                            }
                        }
                    })
                    .catch((e) => {
                        console.log("api error /user/auth/logout", e);
                        saveAuth(null);
                        _API.onAccessLog("logout");
                        switch (process.env.STAGE) {
                            case "development":
                                location.href = process.env.BUSINESS_CLOUD_URL;
                                break;
                            case "staging":
                                location.href = process.env.BUSINESS_CLOUD_URL;
                                break;
                            case "production":
                                location.href = process.env.BUSINESS_CLOUD_URL;
                                break;
                            default:
                                location.href = "/";
                                break;
                        }
                    });
            });
        }
    };

    const onClickExpand = () => {
        onSessionExtend(true);
    };

    const onSessionExtend = (refresh) => {
        _API.get({
            path: `/user/auth/check`,
            data: { refresh, userId: auth?.user_id },
        })
            .then((res) => {
                const { status, result } = res?.data || {};

                if (status?.code === "00-000" && refresh) {
                    const expire = utils.getExpireTime(result?.expired_at);
                    setMinutes(expire?.min);
                    setSeconds(expire?.sec);
                    _CK.set("DXPID-EXPIRE", result?.expired_at);

                    if (location.hostname.indexOf("lgbusinesscloud.com") > -1) {
                        _CK.set("DXPID", result?.access_token, { encode: (v) => v, domain: ".lgbusinesscloud.com", sameSite: "None", secure: true });
                    } else {
                        _CK.set("DXPID", result?.access_token, { encode: (v) => v });
                    }
                }
            })
            .catch((e) => {
                console.log("api error /user/auth/check", e);
            });
    };

    useEffect(() => {
        if (_CK.get("DXPID-EXPIRE")) {
            const expire = utils.getExpireTime(_CK.get("DXPID-EXPIRE"));

            setMinutes(expire?.min);
            setSeconds(expire?.sec);
        }
    }, [_CK.get("DXPID-EXPIRE")]);

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            const { name, data: dataInfo } = data?.data || {};
            if (dataInfo?.result?.token) {
                const { dxp_token, expired_at } = dataInfo?.result?.token || {};
                if (data?.code === "SOCKET-RECEIVED" && name === "extend" && dxp_token && expired_at) {
                    const expire = utils.getExpireTime(expired_at);
                    setMinutes(expire?.min);
                    setSeconds(expire?.sec);
                    _CK.set("DXPID-EXPIRE", expired_at);

                    if (location.hostname.indexOf("lgbusinesscloud.com") > -1) {
                        _CK.set("DXPID", dxp_token, { encode: (v) => v, domain: ".lgbusinesscloud.com", sameSite: "None", secure: true });
                    } else {
                        _CK.set("DXPID", dxp_token, { encode: (v) => v });
                    }
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <Fragment>
            <span className="enable-time">
                {minutes > 0 || seconds > 0 ? (
                    <>
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </>
                ) : (
                    <>00:00</>
                )}
            </span>
            <button type="button" className="btn btn-secondary btn-small btn-expand" title={t("subtext.session.extend.description")} onClick={onClickExpand}>
                {t("button.extend")}
            </button>
        </Fragment>
    );
};

export const AccountToolbar = (props) => {
    const { t } = useTranslation();
    const { auth, saveAuth, businessList, workspace } = useContext(AppContext);
    const [roleModal, setRoleModal] = useState(false);
    const [data, setData] = useState({});
    const [permissionList, setPermissionList] = useState([]);
    const areaRef = useRef(null);
    const roleModalRef = useRef(false);

    const loadData = () => {
        // TODO property-id 동적으로 가져와야함
        _API.get({ path: "/user/roles/property/myrole", data: { "property-id": workspace?.property_id } })
            .then((res) => {
                if (res?.data?.result) {
                    const role = res?.data?.result;
                    setData(role);
                    if (role?.role_permission) {
                        const c = Object.entries(role?.role_permission).map(([key, value]) => ({ label: t("common.code.role.permission." + value), value, key }));
                        setPermissionList(c);
                    }
                }
            })
            .catch((e) => {
                console.log("api error /user/users/me", e);
                setData(null);
                setPermissionList([]);
            });
    };

    useEffect(() => {
        workspace?.property_id && loadData();
    }, [workspace?.property_id]);

    const onLogout = () => {
        Dialog({
            title: t("login.label.signout"),
            text: t("popup.signout.confirm"),
            button: [t("button.cancel"), t("login.label.signout")],
        }).then((id) => {
            if (id) {
                _API.get({ path: "/user/auth/logout", data: {} })
                    .then((res) => {
                        _API.onAccessLog("logout");
                        if (res?.data?.result) {
                            saveAuth(null);
                            switch (process.env.STAGE) {
                                case "development":
                                    location.href = process.env.BUSINESS_CLOUD_URL;
                                    break;
                                case "staging":
                                    location.href = process.env.BUSINESS_CLOUD_URL;
                                    break;
                                case "production":
                                    location.href = process.env.BUSINESS_CLOUD_URL;
                                    break;
                                default:
                                    location.href = "/";
                                    break;
                            }
                        }
                    })
                    .catch((e) => {
                        console.log("api error /user/auth/logout", e);
                        saveAuth(null);
                        _API.onAccessLog("logout");
                        switch (process.env.STAGE) {
                            case "development":
                                location.href = process.env.BUSINESS_CLOUD_URL;
                                break;
                            case "staging":
                                location.href = process.env.BUSINESS_CLOUD_URL;
                                break;
                            case "production":
                                location.href = process.env.BUSINESS_CLOUD_URL;
                                break;
                            default:
                                location.href = "/";
                                break;
                        }
                    });
            }
        });
    };

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                !roleModalRef.current && props.onClose && props.onClose();
            }
        }
        document.addEventListener("mousedown", handleOutside);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
        };
    }, [areaRef]);

    return (
        <Fragment>
            <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
                <div
                    className={cx({
                        menu: true,
                        pannel: true,
                        "myAccount-menu": true,
                        expand: props.show,
                    })}
                    ref={areaRef}
                >
                    <h2 className="menu-title">{t("common.title.my.account")}</h2>
                    <div className="menu-content">
                        <dl className="myAccount-info">
                            <dt>{t("admin.label.accountid")}</dt>
                            <dd>{auth?.lge_user_id}</dd>
                            <dt>
                                [{workspace?.property_name || "-"}] {t("popup.information.assignedRole")}
                            </dt>
                            <dd className="permission-wrap">
                                <label id="permissionLabel" className="dropdown-label">
                                    {t("common.label.role.name")}
                                </label>
                                <SelectBox id={`permission`} items={permissionList} value={""} selectLabel={data?.role_name || ""} onChange={() => {}} ariaLabelId="permissionLabel" noAtagAction />
                            </dd>
                        </dl>
                        <a
                            href="#!"
                            className="link-target btn-assigned"
                            id="btn-assigned"
                            onClick={() => {
                                setRoleModal(true);
                                roleModalRef.current = true;
                            }}
                        >
                            {t("subtext.link.my.account.all.workspace")}
                        </a>
                    </div>
                    <div className="menu-btn-wrap">
                        <button type="button" className="btn btn-Signout" onClick={() => onLogout()}>
                            {t("login.label.signout")}
                        </button>
                    </div>
                    <button type="button" className="btn btn-close" onClick={props.onClose}>
                        {t("button.close")}
                    </button>
                </div>
            </FocusTrap>
            {roleModal && (
                <RoleModal
                    onClose={() => {
                        setRoleModal(null);
                        roleModalRef.current = false;
                    }}
                />
            )}
        </Fragment>
    );
};

const RoleModal = (props) => {
    const { t } = useTranslation();
    const { workspace, businessList } = useContext(AppContext);
    const [data, setData] = useState(null);
    const [selectRole, setSelectRole] = useState(null);

    const loadData = () => {
        _API.get({ path: "/user/roles/property/myrole", data: { "property-id": selectRole?.property_id } })
            .then((res) => {
                if (res?.data?.result) {
                    const role = res?.data?.result;
                    setData(role);
                }
            })
            .catch((e) => {
                console.log("api error /user/users/me", e);
                setData(null);
            });
    };

    const getWorkSpaceList = () => {
        const list = [];
        businessList?.map((item, index) => {
            list.push({ label: item?.business_name, value: item?.business_id, style: { opacity: "0.4", pointerEvents: "none" } });
            item?.properties?.map((sitem, sindex) => {
                list.push({ label: "ㅤㅤㅤ" + sitem?.property_name, value: sitem?.property_id, data: sitem, onClick: setSelectRole });
            });
        });
        return list;
    };

    useEffect(() => {
        selectRole?.property_id && loadData();
    }, [selectRole]);

    useEffect(() => {
        setSelectRole(workspace);
    }, [workspace]);

    return (
        <Modal title={t("subtext.link.my.account.all.workspace")} id="popup-assigned" className="confirm popup-view-assigned" onClose={props.onClose} noFooter ok>
            <div className="field field-type-drpdown">
                <label htmlFor="workspace-name" className="field-label">
                    {t("common.title.myworkspace")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <SelectBox
                            id={"workspace-name"}
                            items={getWorkSpaceList()}
                            value={selectRole?.property_id || ""}
                            ariaLabelId={""}
                            selectLabel={selectRole?.property_name || ""}
                            onChange={(item) => {
                                item?.onClick && item?.onClick(item?.data);
                            }}
                            title={t("subtext.title.select.option")}
                        />
                    </div>
                </div>
            </div>

            <div className="field field-type-text">
                <label htmlFor="headRoleName" className="field-label">
                    {t("popup.information.assignedRole")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <span className="text" id="headRoleName">
                            {data?.role_name || "-"}
                        </span>
                    </div>
                </div>
            </div>

            <div className="field field-type-table">
                <label htmlFor="headPermission" className="field-label">
                    {t("common.label.permission")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="table">
                            <div className="table-wrapper Permission-table no-scroll">
                                <div className="table-box">
                                    <table className="has-not-header" id="headPermission">
                                        <caption>Permission</caption>
                                        <tbody>
                                            {data &&
                                                Object.entries(data?.role_permission).map(([key, value]) => (
                                                    <tr key={key}>
                                                        <th scope="row" className="width-20-rem">
                                                            {key}
                                                        </th>
                                                        <td>{t("common.code.role.permission." + value)}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const CloudToolbar = (props) => {
    const { t } = useTranslation();
    const [info, setInfo] = useState({ country: "US", language: "en-US" });
    const areaRef = useRef(null);

    const goFindOut = () => {
        window.open(process.env.BUSINESS_CLOUD_URL);
    };

    useEffect(() => {
        if (navigator.language === "ko-KR") {
            setInfo({ country: "KR", language: "ko-KR" });
        }
    }, []);

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                props.onClose && props.onClose();
            }
        }
        document.addEventListener("mousedown", handleOutside);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
        };
    }, [areaRef]);
    return (
        <Fragment>
            <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
                <div
                    className={cx({
                        menu: true,
                        pannel: true,
                        "cloudService-menu": true,
                        expand: props.show,
                    })}
                    ref={areaRef}
                >
                    <h2 className="menu-title">{t("common.title.cloudservice")}</h2>
                    <div className="menu-content">
                        <div className="cloud-service-wrap">
                            <div className="cloud-service-row">
                                <h3 className="cloud-service-category">{t("common.title.portal")}</h3>
                                <div className="cloud-service-item business_cloud">
                                    LG Business Cloud
                                    <a href={process.env.BUSINESS_CLOUD_URL} target="_blank" className="btn btn-move-detail">
                                        {t("subtext.link.movedetail")}
                                        <span className="ir">{t("subtext.desc.new.window")}</span>
                                    </a>
                                </div>
                            </div>
                            <div className="cloud-service-row">
                                <h3 className="cloud-service-category">{t("common.title.licensedservice")}</h3>
                                <div className="cloud-service-item supersign_cloud">
                                    SuperSign Cloud
                                    <a
                                        href={process.env.SUPERSIGN_CLOUD_URL.replace("{country}", info.country).replace("{language}", info.language)}
                                        target="_blank"
                                        className="btn btn-move-detail"
                                        title={t("subtext.desc.Openwindow")}
                                    >
                                        {t("subtext.link.movedetail")}
                                        <span className="ir">{t("subtext.desc.new.window")}</span>
                                    </a>
                                </div>
                                <div className="cloud-service-item supersign_ad">
                                    SuperSign AD
                                    <a
                                        href={process.env.SUPERSIGN_AD_URL.replace("{country}", info.country).replace("{language}", info.language)}
                                        target="_blank"
                                        className="btn btn-move-detail"
                                        title={t("subtext.desc.Openwindow")}
                                    >
                                        {t("subtext.link.movedetail")}
                                        <span className="ir">{t("subtext.desc.new.window")}</span>
                                    </a>
                                </div>
                                <div className="cloud-service-item pro_centric_cloud">
                                    Pro:Centric Cloud
                                    <a
                                        href={process.env.PRO_CENTRIC_CLOUD_URL.replace("{country}", info.country).replace("{language}", info.language)}
                                        target="_blank"
                                        className="btn btn-move-detail"
                                        title={t("subtext.desc.Openwindow")}
                                    >
                                        {t("subtext.link.movedetail")}
                                        <span className="ir">{t("subtext.desc.new.window")}</span>
                                    </a>
                                </div>
                                <div className="cloud-service-item pro_centric_stay">
                                    Pro:Centric Stay
                                    <a
                                        href={process.env.PRO_CENTRIC_STAY_URL.replace("{country}", info.country).replace("{language}", info.language)}
                                        target="_blank"
                                        className="btn btn-move-detail"
                                        title={t("subtext.desc.Openwindow")}
                                    >
                                        {t("subtext.link.movedetail")}
                                        <span className="ir">{t("subtext.desc.new.window")}</span>
                                    </a>
                                </div>
                                <div className="cloud-service-item connected_care">
                                    ConnectedCare
                                    <a
                                        href={process.env.CONNECTED_CARE_URL.replace("{country}", info.country).replace("{language}", info.language)}
                                        target="_blank"
                                        className="btn btn-move-detail"
                                        title={t("subtext.desc.Openwindow")}
                                    >
                                        {t("subtext.link.movedetail")}
                                        <span className="ir">{t("subtext.desc.new.window")}</span>
                                    </a>
                                </div>
                                {/* <div className="cloud-service-item connected_care_api">
                                ConnectedCare API
                                <a href={process.env.CONNECTED_CARE_API_URL.replace("{country}", info.country).replace("{language}", info.language)} target="_blank" className="btn btn-move-detail">
                                    {t("subtext.link.movedetail")}
                                </a>
                            </div> */}
                                {/* <div className="cloud-service-item license">
                                Web Player `24 NEW
                                <a href={process.env.WEB_PLAYER_24NEW_URL} target="_blank" className="btn btn-move-detail">
                                    {t("subtext.link.movedetail")}
                                </a>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="menu-btn-wrap">
                        <a href="#!" className="btn btn-Findout" onClick={() => goFindOut()} title={t("subtext.desc.Openwindow")}>
                            {t("button.findoutmoreservices")}
                            <span className="ir">{t("subtext.desc.new.window")}</span>
                        </a>
                    </div>
                    <button type="button" className="btn btn-close" onClick={props.onClose}>
                        {t("button.close")}
                    </button>
                </div>
            </FocusTrap>
        </Fragment>
    );
};

export const SideNavigationBar = (props) => {
    const { t } = useTranslation();
    const areaRef = useRef(null);
    const onCheck = (e) => {
        if (e?.target?.checked) {
            _CK.set("sidenavigationbar", "no");
        } else {
            _CK.set("sidenavigationbar", "yes");
        }
    };

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                props.onClose && props.onClose();
            }
        }
        document.addEventListener("mousedown", handleOutside);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
        };
    }, [areaRef]);

    return (
        <Fragment>
            <div className="side-nav-popup" ref={areaRef}>
                <h2 className="nav-title">{t("popup.title.side.navigation.bar")}</h2>
                <div className="nav-content">
                    <div className="img-box">
                        <img src="../../assets/images/side_nav_popup.gif" alt={t("popup.title.side.navigation.bar")} />
                    </div>
                    <div className="txt-box">{t("subtext.content.side.navigation.bar")}</div>
                    <div className="field field-type-checkbox">
                        <div className="field-form">
                            <div className="field-box">
                                <div className="checkbox">
                                    <input type="checkbox" name="checkbox-type" id="hide-check-sidenavi" onChange={(e) => onCheck(e)} />
                                    <label for="hide-check-sidenavi">{t("common.label.dont.seeagain")}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nav-btn-wrap">
                    <button type="button" className="btn" onClick={props.onClose}>
                        {t("common.ok")}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};
