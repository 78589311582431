import React, { Fragment, useEffect, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import RadioSelectMonitoring from "@component/History/RadioSelectMonitoring";

import _U from "@util/utilities";
import _API from "@util/api";
import _CK from "@util/cookie";

import { SideMenu } from "@component/History";
import { AppContext } from "@component/AppProvider";
import Loading from "@component/UI/Loading";
import LastUpdate from "@component/UI/LastUpdate";
import { useTranslation } from "react-i18next";
import useCss from "@hook/useCss";
import Period from "@component/History/Period";
import utils from "@util/utilities";

export default () => {
    const {
        historyPeriod: { keyword, filter },
        workspace,
        auth,
        periodStartDate,
        periodEndDate,
    } = useContext(AppContext);

    const { t } = useTranslation();

    const [sideMenu, setSideMenu] = useState(false);

    const [isMonitoring, setIsMonitoring] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({
        total_count: 0,
        total_pages: 0,
        page: 0,
        items: [],
    });

    const [diffData, setDiffData] = useState({
        total_count: 0,
        total_pages: 0,
        page: 0,
        items: [],
    });
    const [diffTopData, setDiffTopData] = useState({ groups_selectable: [], models_selectable: [], registration_date: "" });

    const fetchList = (isInit = true) => {
        const perPage = 10;
        if (isInit) {
            setLoading(true);
            _API.get({
                path: "/issue/history/issues",
                data: {
                    page: 1,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword ? keyword : undefined,
                },
            })
                .then((res) => {
                    if (res && res.data) {
                        let {
                            result: {
                                summary: { total },
                            },
                            result,
                        } = res?.data;
                        const total_pages = Math.ceil(total / perPage);
                        setData({
                            ...data,
                            total_count: total,
                            total_pages,
                            page: 1,
                            items: result?.data,
                        });
                    }
                })
                .finally(() => setLoading(false));
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            setLoading(true);
            _API.get({
                path: "/issue/history/issues",
                data: {
                    page,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword ? keyword : undefined,
                },
            })
                .then((res) => {
                    if (res && res.data) {
                        let {
                            result: {
                                summary: { total },
                            },
                            result,
                        } = res?.data;
                        const total_pages = Math.ceil(total / perPage);
                        console.log(total_pages);
                        setData({
                            ...data,
                            total_count: total,
                            total_pages,
                            page,
                            items: [...data.items, ...result?.data],
                        });
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const fetchDiffList = (isInit = true) => {
        const perPage = 10;
        if (isInit) {
            setLoading(true);
            _API.get({
                path: "/issue/history/issues/difference",
                data: {
                    page: 1,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword,
                },
            })
                .then((res) => {
                    if (res?.data?.result) {
                        const { result } = res?.data;
                        const { summary } = result;
                        const { groups_selectable, models_selectable, registration_date, total } = summary;
                        const total_pages = Math.ceil(total / perPage);
                        setDiffData({
                            ...data,
                            total_count: total,
                            total_pages,
                            page: 1,
                            items: result?.data,
                        });
                        setDiffTopData({ ...diffTopData, groups_selectable, models_selectable, registration_date });
                    }
                })
                .finally(() => setLoading(false));
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            setLoading(true);
            _API.get({
                path: "/issue/history/issues/difference",
                data: {
                    page,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword,
                },
            })
                .then((res) => {
                    if (res?.data?.result) {
                        const { result } = res?.data;
                        const { summary } = result;
                        const { groups_selectable, models_selectable, registration_date, total } = summary;
                        const total_pages = Math.ceil(total / perPage);
                        setDiffData({
                            ...data,
                            total_count: total,
                            total_pages,
                            page,
                            items: [...data.item, ...result?.data],
                        });
                        setDiffTopData({ ...diffTopData, groups_selectable, models_selectable, registration_date });
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const onExcelDownload = () => {
        let path = "/issue/history/issues";
        if (!isMonitoring) {
            path += "/issue/history/issues/difference";
        }
        _API.get({
            path,
            data: {
                format: "excel",
                period_start: periodStartDate,
                period_end: periodEndDate,
            },
        }).then((res) => {
            const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            const excel = res?.data;
            const downloadLink = document.createElement("a");
            const fileName = res?.headers["content-disposition"].split("filename=")[1];
            downloadLink.href = mediaType + excel;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
        });
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    useEffect(() => {
        fetchList(true);
    }, [workspace]);

    return (
        <Fragment>
            {loading && <Loading />}

            <div className="content-top">
                <div className="top-left">
                    <h1>{t("device.label.history")}</h1>
                </div>

                <LastUpdate onRefresh={() => fetchList(true)} />
            </div>
            <div className="content-middle wide-padding">
                <SideMenu sideMenu={sideMenu} onClose={() => setSideMenu(false)} />

                <div className="content-middle-box">
                    <Period
                        title={t("history.issue")}
                        onSearch={() => {
                            if (isMonitoring) fetchList(true);
                            else fetchDiffList(true);
                        }}
                        total={data ? data?.total_count : 0}
                        filterMenu={"ISSUE"}
                        onExcelDownload={onExcelDownload}
                        onOpenSideMenu={() => setSideMenu(true)}
                    />

                    <div className="tablist-content" role="tabpanel">
                        <RadioSelectMonitoring
                            isMonitoring={isMonitoring}
                            setIsMonitoring={(bool) => {
                                setIsMonitoring(bool);
                                if (bool) {
                                    fetchList(true);
                                } else {
                                    fetchDiffList(true);
                                }
                            }}
                        />
                        <div className="tab-panel panel-difference-settings-data" role="tabpanel">
                            <div className="inner-content">
                                <div className="white-area">
                                    <div className="data-list-container">
                                        {!isMonitoring && (
                                            <div className="color-info-box">
                                                <ul>
                                                    <li>
                                                        {t("devicemanagement.tree.group")}{" "}
                                                        <span>
                                                            {diffTopData?.groups_selectable[0]?.group_hierarchy} &gt; {diffTopData?.groups_selectable[0]?.group_name}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        {t("player.label.modelName")} <span>{diffTopData?.models_selectable[0]}</span>
                                                    </li>
                                                    <li>
                                                        {t("common.table.header.maindevicename")} <span>{diffTopData?.models_selectable[0]}</span>
                                                    </li>
                                                    <li>
                                                        {t("admin.label.registerationdate")}{" "}
                                                        <span>{diffTopData?.registration_date ? utils.getOffsetTime(auth, workspace, diffTopData.registration_date) : ""}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                        <div className="table">
                                            <div className="table-wrapper horizontal-scroll select-mode">
                                                <div className="table-box">
                                                    {isMonitoring ? (
                                                        <InfiniteScroll
                                                            style={{ overflow: "hidden" }}
                                                            dataLength={data ? data?.items?.length : 0}
                                                            next={() => fetchList(false)}
                                                            hasMore={windowWidth > 991}
                                                            loader={""}
                                                            scrollableTarget="scrollableTbody"
                                                        >
                                                            <table>
                                                                <caption>{t("history.issue_list")}</caption>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("device.label.devicetype")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("table.head.os")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">
                                                                                {t("player.label.modelName")}
                                                                                <br />
                                                                                {t("history.table.device_name")}
                                                                            </div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("common.label.serialnumber")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("report.issuetype")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("dv.issue.threshold")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("dv.issue.occurredTime")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("common.label.resolvedtime")}</div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody id="scrollableTbody" className="height-50-rem">
                                                                    {data?.items?.map(
                                                                        (item, index) =>
                                                                            item && (
                                                                                <tr key={index}>
                                                                                    <td>{_U.hightlight(item.device_type, keyword)}</td>
                                                                                    <td>{_U.hightlight(item.os_type, keyword)}</td>
                                                                                    <td>
                                                                                        {_U.hightlight(item.model_name, keyword)}
                                                                                        <br />({_U.hightlight(item.device_name, keyword)})
                                                                                    </td>
                                                                                    <td>{_U.hightlight(item.serial_number, keyword)}</td>
                                                                                    <td>{_U.hightlight(item.issue_type, keyword)}</td>
                                                                                    <td>{_U.hightlight(item.threshold, keyword)}</td>
                                                                                    <td>{item.occured_time ? _U.getOffsetTime(auth, workspace, item.occured_time) : ""}</td>
                                                                                    <td>{item.resolved_time ? _U.getOffsetTime(auth, workspace, item.resolved_time) : ""}</td>
                                                                                </tr>
                                                                            )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </InfiniteScroll>
                                                    ) : (
                                                        <InfiniteScroll
                                                            style={{ overflow: "hidden" }}
                                                            dataLength={diffData?.items?.length}
                                                            next={() => fetchList(false)}
                                                            hasMore={windowWidth > 991}
                                                            loader={""}
                                                            scrollableTarget="scrollableTbody"
                                                        >
                                                            <table>
                                                                <caption>{t("history.issue_list")}</caption>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("devicemanagement.title.deviceName")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("common.label.serialnumber")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("history.dsd")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("history.main_device_data")}</div>
                                                                        </th>
                                                                        <th scope="col" width="10%">
                                                                            <div className="text-block">{t("dv.issue.occurredTime")}</div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody id="scrollableTbody" className="height-50-rem">
                                                                    {diffData?.items?.map(
                                                                        (item, index) =>
                                                                            item && (
                                                                                <tr key={index}>
                                                                                    <td>{_U.hightlight(item.device_name, keyword)}</td>
                                                                                    <td>{_U.hightlight(item.serial_number, keyword)}</td>
                                                                                    <td>{_U.hightlight(item.model_name, keyword)}</td>
                                                                                    <td>{_U.hightlight(item.device_name, keyword)}</td>
                                                                                    <td>{item.occured_time ? _U.getOffsetTime(auth, workspace, item.occured_time) : ""}</td>
                                                                                </tr>
                                                                            )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </InfiniteScroll>
                                                    )}
                                                </div>
                                            </div>

                                            {isMonitoring ? (
                                                <div className="convert-table-to-list">
                                                    <div className="table-header">
                                                        <div className="row">
                                                            <div className="thead">{t("device.label.devicetype")}</div>
                                                        </div>
                                                    </div>
                                                    <InfiniteScroll
                                                        style={{ overflow: "hidden" }}
                                                        dataLength={data?.items?.length}
                                                        next={() => fetchList(false)}
                                                        hasMore={windowWidth <= 991}
                                                        loader={""}
                                                    >
                                                        <ul className="table-body">
                                                            {data?.items?.map((item, index) => (
                                                                <li className="table-row" key={`reflow-${index}`}>
                                                                    <div className="summary-data">
                                                                        <div className="row">
                                                                            <div className="table-cell status">
                                                                                <button
                                                                                    type="button"
                                                                                    role="listbox"
                                                                                    className="btn btn-expand"
                                                                                    aria-expanded="false"
                                                                                    onClick={(e) => reflowViewExpand(e)}
                                                                                >
                                                                                    {_U.hightlight(item.device_type, keyword)}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="all-data-box">
                                                                        <ul className="all-data">
                                                                            <li>
                                                                                <span className="field-label">OS</span>
                                                                                <span className="field-content">{_U.hightlight(item.os_type, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">
                                                                                    {t("player.label.modelName")}
                                                                                    <br />({t("devicemanagement.title.deviceName")})
                                                                                </span>
                                                                                <span className="field-content">
                                                                                    {_U.hightlight(item.model_name, keyword)}
                                                                                    <br />({_U.hightlight(item.device_name, keyword)})
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("common.label.serialnumber")}</span>
                                                                                <span className="field-content">{_U.hightlight(item.serial_number, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("report.issuetype")}</span>
                                                                                <span className="field-content">{_U.hightlight(item.issue_type, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("dv.issue.threshold")}</span>
                                                                                <span className="field-content">{_U.hightlight(item.threshold, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("dv.issue.occurredTime")}</span>
                                                                                <span className="field-content">{item.occured_time ? _U.getOffsetTime(auth, workspace, item.occured_time) : ""}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("common.label.resolvedtime")}</span>
                                                                                <span className="field-content">{item.resolved_time ? _U.getOffsetTime(auth, workspace, item.resolved_time) : ""}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </InfiniteScroll>
                                                </div>
                                            ) : (
                                                <div className="convert-table-to-list">
                                                    <div className="table-header">
                                                        <div className="row">
                                                            <div className="thead">{t("devicemanagement.title.deviceName")}</div>
                                                        </div>
                                                    </div>
                                                    <InfiniteScroll
                                                        style={{ overflow: "hidden" }}
                                                        dataLength={diffData?.items?.length}
                                                        next={() => fetchList(false)}
                                                        hasMore={windowWidth <= 991}
                                                        loader={""}
                                                    >
                                                        <ul className="table-body">
                                                            {diffData?.items?.map((item, index) => (
                                                                <li className="table-row" key={`reflow-${index}`}>
                                                                    <div className="summary-data">
                                                                        <div className="row">
                                                                            <div className="table-cell status">
                                                                                <button
                                                                                    type="button"
                                                                                    role="listbox"
                                                                                    className="btn btn-expand"
                                                                                    aria-expanded="false"
                                                                                    onClick={(e) => reflowViewExpand(e)}
                                                                                >
                                                                                    {_U.hightlight(item.device_name, keyword)}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="all-data-box">
                                                                        <ul className="all-data">
                                                                            <li>
                                                                                <span className="field-label">{t("table.head.os")}</span>
                                                                                <span className="field-content">{_U.hightlight(item.os_type, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("common.label.serialnumber")}</span>
                                                                                <span className="field-content">{_U.hightlight(item.serial_number, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("history.dsd")}</span>
                                                                                <span className="field-content">{_U.hightlight(item.model_name, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("history.main_device_data")}</span>
                                                                                <span className="field-content">{_U.hightlight(item.device_name, keyword)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="field-label">{t("dv.issue.occurredTime")}</span>
                                                                                <span className="field-content">{item.occured_time ? _U.getOffsetTime(auth, workspace, item.occured_time) : ""}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </InfiniteScroll>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
