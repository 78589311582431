import React, { Fragment, useState, useEffect, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import _CK from "@util/cookie";
import _U from "@util/utilities";
import _API from "@util/api";
import { AppContext } from "@component/AppProvider";

import { SiteToolbar, SessionToolbar, AccountToolbar, CloudToolbar, SideNavigationBar } from "@layout/Main/ToolBar";
import { TooltipBottom } from "@component/Tooltip";
import NotifyToolbar from "@component/Toolbar/NotifyToolbar";

export default (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { businessList, workspace, saveWorkspace, product, rolePermission, sideBarExpand, saveSideBarExpand, hoverChild, saveHoverChild } = useContext(AppContext);

    const [sideNavigationBar, setSideNavigationBar] = useState(false);

    const onMouseOverLeftSide = () => {
        if (hoverChild) {
            saveHoverChild(false);
        }
    };

    const onHamburgerClick = (e) => {
        if (e && e.key && !["Spacebar", 32, " ", "Enter"].includes(e.key)) {
            e.preventDefault();
            return;
        }
        saveSideBarExpand(!sideBarExpand);
    };

    const onHamburgerEnter = (e) => {
        if (!sideBarExpand) {
            saveHoverChild(true);
        }
    };

    useEffect(() => {
        if (_CK.get("sidenavigationbar") !== "no") {
            setSideNavigationBar(true);
        }
    }, [workspace]);

    return (
        <Fragment>
            <div className="header-container">
                <header>
                    {/* <!-- Left side --> */}
                    <div className="left-side" onMouseEnter={() => onMouseOverLeftSide()}>
                        <button type="button" className="btn btn-hamburger mobile-only" onClick={(e) => onHamburgerClick(e)} aria-expanded={sideBarExpand} onMouseEnter={(e) => onHamburgerEnter(e)}>
                            <span className="ir">{t("subtext.menu.open")}</span>
                        </button>
                        <a href="/" className="logo">
                            <img src="/assets/images/header/header_lgcc_logo.svg" alt={t("dv.site.logo")} />
                        </a>
                    </div>
                    {/* <!-- Left side --> */}

                    {/* <!-- Right side --> */}
                    <div className="right-side">
                        <HeadNavi />
                    </div>
                    {/* <!-- Right side --> */}
                </header>
            </div>
            {sideNavigationBar && <SideNavigationBar onClose={() => setSideNavigationBar(false)} />}
        </Fragment>
    );
};

export const HeadNavi = (props) => {
    const { t } = useTranslation();
    const { notiWidth } = props;
    const navigate = useNavigate();
    const { businessList, workspace, saveWorkspace, product, rolePermission, newNotice, saveNewNotice, saveFileboxToolbar, saveShowAlertMessage } = useContext(AppContext);

    const [siteToolbar, setSiteToolbar] = useState(false);
    const [notifyToolbar, setNotifyToolbar] = useState(false);
    const [accountToolbar, setAccountToolbar] = useState(false);
    const [cloudToolbar, setCloudToolbar] = useState(false);
    const [hoverButtonId, setHoverButtonId] = useState("");

    const goPage = (url) => {
        navigate(url);
    };

    const goUrl = (url) => {
        window.open(url);
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            const { name } = data?.data ?? {};
            if (data?.code === "SOCKET-RECEIVED" && name === "new_noti") {
                saveNewNotice(true);
            }
        });
    };

    useEffect(() => {
        _CK.set("business-id", workspace?.business_id, { encode: (v) => v });
        _CK.set("property-id", workspace?.property_id, { encode: (v) => v });
    }, [workspace]);

    useEffect(() => {
        function handleKeyup(e) {
            if (e?.key === "Escape") {
                // document.activeElement.blur();
                $(".tooltip-container")
                    .find("button")
                    .each(function () {
                        $(this).removeClass("hover");
                    });
            }
        }
        document.addEventListener("keyup", handleKeyup);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            document.removeEventListener("keyup", handleKeyup);
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    const getDmsArea = () => {
        return (
            <Fragment>
                <div className="features-pannel-wrap tooltip-container">
                    <button
                        type="button"
                        id="btn-head-broadcast"
                        className={cx({ "btn-icon": true, "btn-streaming": true, "tooltip-label": true, hover: hoverButtonId === "btn-head-broadcast" })}
                        onClick={() => goPage("/schedule/broadcast")}
                        onMouseEnter={() => setHoverButtonId("btn-head-broadcast")}
                        onMouseLeave={() => setHoverButtonId("")}
                        onFocus={() => setHoverButtonId("btn-head-broadcast")}
                        onBlur={() => setHoverButtonId("")}
                    >
                        <TooltipBottom txt={t("popup.title.broadcast")} />
                        <span className="ir">{t("subtext.broadcast.streaming")}</span>
                    </button>
                </div>
                <div className="features-pannel-wrap tooltip-container">
                    <button
                        type="button"
                        id="btn-head-message"
                        className={cx({ "btn-icon": true, "btn-message": true, "tooltip-label": true, hover: hoverButtonId === "btn-head-message" })}
                        onClick={() => saveShowAlertMessage(true)}
                        onMouseEnter={() => setHoverButtonId("btn-head-message")}
                        onMouseLeave={() => setHoverButtonId("")}
                        onFocus={() => setHoverButtonId("btn-head-message")}
                        onBlur={() => setHoverButtonId("")}
                    >
                        <TooltipBottom txt={t("popup.title.alertmessage")} />
                        <span className="ir">{t("popup.title.alertmessage")}</span>
                    </button>
                </div>
                <div className="features-pannel-wrap tooltip-container">
                    <button
                        type="button"
                        id="btn-head-group"
                        className={cx({ "btn-icon": true, "btn-group": true, "tooltip-label": true, hover: hoverButtonId === "btn-head-group" })}
                        onClick={() => saveFileboxToolbar(true)}
                        onMouseEnter={() => setHoverButtonId("btn-head-group")}
                        onMouseLeave={() => setHoverButtonId("")}
                        onFocus={() => setHoverButtonId("btn-head-group")}
                        onBlur={() => setHoverButtonId("")}
                    >
                        <TooltipBottom txt={t("admin.label.filebox")} />
                        <span className="ir">{t("admin.label.filebox")}</span>
                    </button>
                </div>
            </Fragment>
        );
    };

    const getOtherArea = () => {
        return (
            <Fragment>
                <div className="features-pannel-wrap tooltip-container">
                    <button
                        type="button"
                        id="btn-head-alarm"
                        className={cx({ "btn-icon": true, "btn-alarm": true, "tooltip-label": true, hover: hoverButtonId === "btn-head-alarm" })}
                        onClick={() => {
                            setNotifyToolbar(true);
                            setNewNoti(false);
                            setTimeout(() => {
                                document.activeElement.blur();
                            }, 100);
                        }}
                        onMouseEnter={() => setHoverButtonId("btn-head-alarm")}
                        onMouseLeave={() => setHoverButtonId("")}
                        onFocus={() => setHoverButtonId("btn-head-alarm")}
                        onBlur={() => setHoverButtonId("")}
                    >
                        {/* <!-- alarm-count : 읽지 않은 알림 개수를 출력합니다. --> */}
                        <TooltipBottom txt={t("common.notification")} />
                        {newNotice && <span className="alarm-bedge">{t("dashboard.new.title")}</span>}
                        <span className="ir">{t("common.notification")}</span>
                    </button>
                    {notifyToolbar && (
                        <NotifyToolbar
                            show={notifyToolbar}
                            onClose={() => {
                                setNotifyToolbar(false);
                                setTimeout(() => {
                                    document.activeElement.blur();
                                }, 100);
                            }}
                        />
                    )}
                </div>
                <div className="features-pannel-wrap tooltip-container">
                    <button
                        type="button"
                        id="btn-head-profile"
                        className={cx({ "btn-icon": true, "btn-profile": true, "tooltip-label": true, hover: hoverButtonId === "btn-head-profile" })}
                        aria-label={t("common.title.my.account")}
                        onClick={() => setAccountToolbar(true)}
                        onMouseEnter={() => setHoverButtonId("btn-head-profile")}
                        onMouseLeave={() => setHoverButtonId("")}
                        onFocus={() => setHoverButtonId("btn-head-profile")}
                        onBlur={() => setHoverButtonId("")}
                    >
                        <TooltipBottom txt={t("common.title.my.account")} />
                        <span className="ir">{t("common.title.my.account")}</span>
                    </button>
                    {accountToolbar && (
                        <AccountToolbar
                            show={accountToolbar}
                            onClose={() => {
                                setAccountToolbar(false);
                                setTimeout(() => {
                                    document.activeElement.blur();
                                }, 100);
                            }}
                        />
                    )}
                </div>
                <div className="features-pannel-wrap tooltip-container">
                    <button
                        type="button"
                        id="btn-head-apps"
                        className={cx({ "btn-icon": true, "btn-apps": true, "tooltip-label": true, hover: hoverButtonId === "btn-head-apps" })}
                        aria-label={t("player.label.apps")}
                        onClick={() => setCloudToolbar(true)}
                        onMouseEnter={() => setHoverButtonId("btn-head-apps")}
                        onMouseLeave={() => setHoverButtonId("")}
                        onFocus={() => setHoverButtonId("btn-head-apps")}
                        onBlur={() => setHoverButtonId("")}
                    >
                        <TooltipBottom txt={t("common.title.cloudservice")} />
                        <span className="ir">{t("player.label.apps")}</span>
                    </button>
                    {cloudToolbar && (
                        <CloudToolbar
                            show={cloudToolbar}
                            onClose={() => {
                                setCloudToolbar(false);
                                setTimeout(() => {
                                    document.activeElement.blur();
                                }, 100);
                            }}
                        />
                    )}
                </div>
            </Fragment>
        );
    };

    const getSupportArea = () => {
        return (
            <button type="button" className="btn btn-secondary btn-small btn-support" title="" onClick={() => goUrl(process.env.SUPPORT_URL)}>
                {t("device.label.support")}
                <span className="ir">{t("subtext.desc.new.window")}</span>
            </button>
        );
    };

    return (
        <Fragment>
            <ul className="features">
                {/* <!-- Sitemap --> */}
                <li>
                    <button
                        type="button"
                        className={cx({
                            btn: true,
                            "btn-small": true,
                            // "btn-Business": true,
                            "btn-Workspace": true,
                        })}
                        title=""
                        onClick={() => setSiteToolbar(!siteToolbar)}
                    >
                        <em role="img">{workspace?.property_id ? "W" : "B"}</em>
                        {workspace?.property_name || workspace?.business_name}
                    </button>
                    {siteToolbar && <SiteToolbar show={siteToolbar} onClose={() => setSiteToolbar(false)} />}
                </li>

                {/* <!-- Expend Session time --> */}
                <li>
                    <SessionToolbar />
                </li>

                {notiWidth ? (
                    <Fragment>
                        <li className="width-100-percent">
                            {product?.service_type === "DMS" && rolePermission?.schedule > 0 && getDmsArea()}
                            {getOtherArea()}
                        </li>
                        {getSupportArea()}
                    </Fragment>
                ) : (
                    <Fragment>
                        {product?.service_type === "DMS" && rolePermission?.schedule > 0 && <li>{getDmsArea()}</li>}
                        <li>
                            {getOtherArea()}
                            {getSupportArea()}
                        </li>
                    </Fragment>
                )}
            </ul>
        </Fragment>
    );
};
