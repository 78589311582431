import React from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
Chart.register(annotationPlugin);

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  canvas {
    width: 100% !important;
    max-height: 26.2rem;
  }

  @media (max-width: 720px) {
    canvas {
      height: 100% !important;
      max-height: unset;
    }
  }
`;

const bgColor = "#DADDE2";
const hoverColor = "#A5A6E6";

const BarChart = ({ labels, energyList, average }) => {
  const data = {
    labels,
    datasets: [
      {
        label: "My First Dataset",
        data: energyList,
        backgroundColor: bgColor,
        borderColor: bgColor,
        borderWidth: 1,
        barThickness: 28,
        hoverBackgroundColor: hoverColor, // 호버 시 변경될 색상을 설정합니다.
        hoverBorderColor: hoverColor,
      },
    ],
  };

  const options = {
    indexAxis: "x",
    scales: {
      x: {
        ticks: {
          display: true,
        },
        beginAtZero: true,
        grid: { display: false },
      },
      y: {
        ticks: {
          display: false,
          stepSize: 100,
        },
        beginAtZero: true,
        grid: { display: true, drawBorder: false },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: () => "",
          label: (context) => {
            return context.parsed.y + "kWh";
          },
        },
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          line1: {
            display: true,
            type: "line",
            yMin: average,
            yMax: average,
            borderColor: "#354056",
            borderWidth: 1.5,
            label: {
              backgroundColor: "#354056",
              content: `Avg ${average}kWh`,
              position: "end",
              display: true,
            },
          },
        },
      },
    },
  };

  return (
    <ChartWrapper>
      <Bar data={data} options={options} />
    </ChartWrapper>
  );
};

export default BarChart;
