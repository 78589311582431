import BarChart from "@component/DashBoard/BarChart";
import { EnergyDoughnutChart } from "@component/DashBoard/DoughnutChart";
import LineChart from "@component/DashBoard/LineChart";
import RectangleChart from "@component/DashBoard/RectangleChart";
import DashboardWrapper from "@component/DashBoard/Wrapper";
import React, { useContext, useEffect, useState } from "react";
import _API from "@util/api";
import { AppContext } from "@component/AppProvider";

const month = {
    "-6": "Jun",
    "-5": "Jul",
    "-4": "Aug",
    "-3": "Sep",
    "-2": "Oct",
    "-1": "Nov",
    0: "Dec",
    // 위쪽은 currentMonth 가 연초일 경우에 대비해서 만듬
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

export default () => {
    const { workspace } = useContext(AppContext);
    const [energyUsageTodayHour, setEnergyUsageTodayHour] = useState({
        unit: "kWh",
        electric_energy: [],
    });
    const [energyUsageMonthly, setEnergyUsageMonthly] = useState({
        labels: [],
        energyList: [],
        average: 0,
    });
    const [energyTopList, setEnergyTopList] = useState([]);
    const [energySaveList, setEnergySaveList] = useState([]);
    const [modelList, setModelList] = useState([]);

    const fetchData = async () => {
        try {
            const energyUsageTodayHourPromise = _API.get({ path: "/dashboard/energy/usage/today" }).then((res) => {
                if (res?.data?.result) {
                    const { result } = res?.data;
                    setEnergyUsageTodayHour({ ...energyUsageTodayHour, ...result });
                }
            });

            const energyUsageMonthlyPromise = _API.get({ path: "/dashboard/energy/usage/month" }).then((res) => {
                if (res?.data?.result) {
                    let { current_month, current_month_electric_energy, previously_electric_energy } = res?.data?.result ?? {};

                    const labels = [];
                    const energyList = [...previously_electric_energy, current_month_electric_energy];
                    const sum = energyList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    const average = Math.floor(sum / energyList.length);
                    const startMonth = current_month - 5;
                    const endMonth = current_month;
                    for (let i = startMonth; i <= endMonth; i++) {
                        labels.push(month[i]);
                    }

                    setEnergyUsageMonthly({
                        labels,
                        energyList,
                        average,
                    });
                }
            });

            const energyTopPromise = _API.get({ path: "/dashboard/energy/top" }).then((res) => {
                if (res?.data?.result) {
                    const { result } = res?.data;
                    if (result?.hasOwnProperty("top")) {
                        const topList = [];
                        result?.top?.forEach((v) => topList.push({ name: v.device_name, energyUse: v.energy }));
                        setEnergyTopList(topList);
                    }
                }
            });

            const energySavePromise = _API.get({ path: "/dashboard/energy/saving" }).then((res) => {
                if (res?.data?.result) {
                    const { result } = res?.data;
                    if (result?.hasOwnProperty("table")) {
                        const { saving_mode_off_count, saving_mode_on_count } = result?.table ?? {};
                        const saveList = [];
                        saveList.push({
                            name: "Saving Mode On",
                            count: saving_mode_on_count,
                        });
                        saveList.push({
                            name: "Saving Mode Off",
                            count: saving_mode_off_count,
                        });
                        setEnergySaveList(saveList);
                    }
                }
            });

            const modelPromise = _API.get({ path: "/device/devices" }).then((res) => {
                if (res?.data?.result) {
                    const { result } = res?.data;
                    console.log({ result });
                }
            });

            await Promise.all([energyUsageTodayHourPromise, energyUsageMonthlyPromise, energyTopPromise, energySavePromise, modelPromise]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [workspace]);

    return (
        <DashboardWrapper>
            <section className="dashboard-energy">
                <div className="energy-1">
                    <div>
                        <div className="title">Today's Energy Usage</div>
                        <div className="graph-area">
                            <LineChart chartData={energyUsageTodayHour} />
                        </div>
                    </div>
                    <div>
                        <div className="title">Monthly Energy Usage</div>
                        <div className="graph-area">
                            <BarChart average={energyUsageMonthly.average} labels={energyUsageMonthly.labels} energyList={energyUsageMonthly.energyList} />
                        </div>
                    </div>
                </div>
                <div className="energy-2">
                    <div>
                        <div className="title">Today's Energy Usage</div>
                        <div className="graph-area">
                            <div className="dropdown dropdown-type-a margin-bottom-1-6rem">
                                <a href={void 0} role="listbox" aria-haspopup="listbox" className="btn-dropdown" aria-selected="false" title="Please select option">
                                    Model
                                </a>
                                <div className="dropdown-menu">
                                    <ul className="lists" role="listbox" aria-expanded="true">
                                        <li className="list" role="none presentation">
                                            <a href={void 0} role="Model" aria-selected="false">
                                                Model
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <RectangleChart dataList={energyTopList} />
                        </div>
                    </div>
                    <div>
                        <div className="title">Saving Mode</div>
                        <div className="graph-area">
                            <div className="dropdown dropdown-type-a">
                                <a href={void 0} role="listbox" aria-haspopup="listbox" className="btn-dropdown" aria-selected="false" title="Please select option">
                                    Model
                                </a>
                                <div className="dropdown-menu">
                                    <ul className="lists" role="listbox" aria-expanded="true">
                                        <li className="list" role="none presentation">
                                            <a href={void 0} role="Model" aria-selected="false">
                                                Model
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <EnergyDoughnutChart dataList={energySaveList} />
                        </div>
                    </div>
                </div>
            </section>
        </DashboardWrapper>
    );
};
