import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import cx from "classnames";
import moment from "moment";
import useCss from "@hook/useCss";
import { useTranslation } from "react-i18next";

import _U from "@util/utilities";

import Modal from "@component/UI/Modal";
import { SelectBox, SelectBoxBasic } from "@component/UI/Form";
import _API from "@util/api";
import Dialog from "@component/UI/Dialog";
import { AppContext } from "@component/AppProvider";
import _Device from "@util/devices";

export const ControlPower = (props) => {
    const { t } = useTranslation();
    const { idx, uniControlDevice } = props;
    const { saveInnerLoading, rolePermission, auth, workspace, lastUpdateTime } = useContext(AppContext);
    const [addScheduleModal, setAddScheduleModal] = useState(null);
    const [selectItem, setSelectItem] = useState(null);

    const [ir, setIr] = useState(false);
    const [signal, setSignal] = useState(false);
    const [more, setMore] = useState(false);
    const [data, setData] = useState([]);
    const [dataAlarmInfo, setDataAlramInfo] = useState({});
    const [scheduleList, setScheduleList] = useState([]);
    const [sortAsc, setSortAsc] = useState(null);
    const [scheduleApply, setScheduleApply] = useState(false);
    const [loadMore, setLoadMore] = useState("more");
    const [maxScheduleCnt, setMaxScheduleCnt] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const onReboot = async () => {
        const params = [{ reboot: { data: { value: true } } }];
        if (uniControlDevice) {
            const res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            const res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
    };
    const onPowerOff = async () => {
        const params = [{ powerOff: { data: { value: true } } }];
        if (uniControlDevice) {
            const res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            const res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
    };
    const onScreenOff = async () => {
        const params = [{ screenOff: { data: { value: true } } }];
        if (uniControlDevice) {
            const res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            const res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
    };

    const noIRPowerOff = async () => {
        if (ir) {
            // && !dataAlarmInfo?.noIRPowerOff
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.message.noirpoweroff"),
                button: [t("button.cancel"), t("common.ok")],
            }).then(async (id) => {
                if (id) {
                    const params = [{ noIRPowerOff: { data: { value: !ir } } }];
                    let res = null;
                    if (uniControlDevice) {
                        res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
                    } else {
                        res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                    }
                    if (res && res.status === "true") {
                        setIr(!ir);
                        setDataAlramInfo({ ...dataAlarmInfo, noIRPowerOff: true });
                    }
                }
            });
        } else {
            const params = [{ noIRPowerOff: { data: { value: !ir } } }];
            let res = null;
            if (uniControlDevice) {
                res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
            } else {
                res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
            }
            if (res && res.status === "true") {
                setIr(!ir);
            }
        }
    };

    const noSignalPowerOff = async () => {
        if (signal) {
            // && !dataAlarmInfo?.noSignalPowerOff
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.message.noirpoweroff"),
                button: [t("button.cancel"), t("common.ok")],
            }).then(async (id) => {
                if (id) {
                    const params = [{ noSignalPowerOff: { data: { value: !signal } } }];
                    let res = null;
                    if (uniControlDevice) {
                        res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
                    } else {
                        res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                    }
                    if (res && res.status === "true") {
                        setSignal(!signal);
                        setDataAlramInfo({ ...dataAlarmInfo, noSignalPowerOff: true });
                    }
                }
            });
        } else {
            const params = [{ noSignalPowerOff: { data: { value: !signal } } }];
            let res = null;
            if (uniControlDevice) {
                res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
            } else {
                res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
            }
            if (res && res.status === "true") {
                setSignal(!signal);
            }
        }
    };

    const onScheduleDeleteAll = () => {
        Dialog({
            title: t("player.button.deleteAll"),
            text: t("common.message.deleteall.schedule"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                setScheduleList([]);
                setScheduleApply(true);
            }
        });
    };

    const onClickRemove = (i) => {
        Dialog({
            title: t("common.label.delete.schedule"),
            text: t("common.message.delete.schedule"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                const newList = [];
                scheduleList?.forEach((f, index) => {
                    if (index != i) {
                        newList.push(f);
                    }
                });
                setScheduleList(newList);
                setScheduleApply(true);
            }
        });
    };

    const onClickAddSchedule = (item) => {
        setAddScheduleModal(true);
        setSelectItem(item);
    };
    const schedule = [
        {
            powerSchedule: {
                supported: true,
                spec: {
                    inputType: {
                        type: "hex-enum",
                        values: [
                            {
                                valdata: ["29", "90", "91", "92"],
                            },
                        ],
                    },
                    repeat: {
                        type: "hex-enum",
                        values: [
                            {
                                valdata: ["A6", "02", "03", "04", "05", "06", "07", "08", "09", "0A", "0B", "0C", "0D"],
                            },
                        ],
                    },
                },
                data: {
                    onTimer1: {
                        hour: 8,
                        minute: 0,
                        repeat: "02",
                        days: ["mon"],
                    },
                    offTimer1: {
                        hour: 8,
                        minute: 0,
                        repeat: "08",
                        days: null,
                    },
                    onTimer2: null,
                    offTimer2: null,
                    onTimer3: null,
                    offTimer3: null,
                    onTimer4: null,
                    offTimer4: null,
                    onTimer5: null,
                    offTimer5: null,
                    onTimer6: null,
                    offTimer6: null,
                    onTimer7: null,
                    offTimer7: null,
                    onTimer8: null,
                    offTimer8: null,
                    onTimer9: null,
                    offTimer9: null,
                    onTimer10: null,
                    offTimer10: null,
                    onTimer11: null,
                    offTimer11: null,
                    onTimer12: null,
                    offTimer12: null,
                    onTimer13: null,
                    offTimer13: null,
                    onTimer14: null,
                    offTimer14: null,
                    onTimer15: null,
                    offTimer15: null,
                    onTimer16: null,
                    offTimer16: null,
                    onTimer17: null,
                    offTimer17: null,
                    onTimer18: null,
                    offTimer18: null,
                    onTimer19: null,
                    offTimer19: null,
                    onTimer20: null,
                    offTimer20: null,
                    onTimer21: null,
                    offTimer21: null,
                },
            },
            ledDefectDetection: {
                data: {
                    value: [true, false, false, false, false, false, false],
                },
            },
        },
    ];
    const onLoadData = () => {
        saveInnerLoading(true);
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                // features: ["reboot", "powerOff", "screenOff", "noIRPowerOff", "noSignalPowerOff", "dpmSelect", "pmMode", "powerOnDelay", "powerOnStatus"],
                features: [
                    // "reboot",
                    // "screenOff",
                    // "noIRPowerOff",
                    // "noSignalPowerOff",
                    // "dpmSelect",
                    // "pmMode",
                    // "powerOnDelay",
                    // "powerOnStatus",
                    // "powerSchedule32",
                    // "powerScheduleBoe",
                    // "powerSchedule",
                    // "powerOnOffHistory",
                    // "__powerOnOffHistory",
                    // "ledDefectDetection",

                    "powerOn",
                    "reboot",
                    "screenOff",
                    "noIRPowerOff",
                    "noSignalPowerOff",
                    "dpmSelect",
                    "pmMode",
                    "powerOnDelay",
                    "powerOnStatus",
                    "powerOnOffHistory",
                    "__powerOnOffHistory",
                    "powerSchedule32",
                    "powerScheduleBoe",
                    "powerSchedule",
                    "ledDefectDetection",
                ],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                setIr(result?.filter((f) => f["noIRPowerOff"])[0]?.["noIRPowerOff"]?.data?.value || false);
                setSignal(result?.filter((f) => f["noSignalPowerOff"])[0]?.["noSignalPowerOff"]?.data?.value || false);

                if (_Device.getSupported("powerSchedule", result)) {
                    genPowerSchedule("powerSchedule", result);
                }
                resizeTable();
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
                saveInnerLoading(false);
            });
    };

    const genPowerSchedule = (key, result) => {
        const newData = result?.filter((f) => f[key])[0]?.[key]?.data || {};
        const timers = Object.keys(newData);
        const newList = [];
        const max = timers.length > 0 ? Math.max(...timers?.map((m) => m.replace(/[^0-9]/g, "") || 0)) : 0;

        setMaxScheduleCnt(max);
        let idxIndex = 0;
        for (let i = 0; i < timers?.length; i++) {
            const item = newData[timers[i]];
            if (item && timers[i]?.indexOf("onTimerInput") === -1) {
                const newItem = { ...convertScheduleData(timers[i], item), idx: "idx-" + idxIndex++ };
                newList.push(newItem);
            } else if (item && timers[i]?.indexOf("onTimerInput") > -1) {
                const timerIndex = timers[i]?.replace(/[^0-9]/g, "") || 1;
                if (newList?.length >= timerIndex) {
                    newList[timerIndex - 1]["input"] = item;
                }
            }
        }
        if (result?.filter((f) => f["ledDefectDetection"])[0]?.["ledDefectDetection"]?.data?.value?.length > 0) {
            for (let i = 0; i < newList.length; i++) {
                const newItem = newList[i];
                if (result?.filter((f) => f["ledDefectDetection"])[0]?.["ledDefectDetection"]?.data?.value?.length > i) {
                    newItem["ledDefectDetection"] = result?.filter((f) => f["ledDefectDetection"])[0]?.["ledDefectDetection"]?.data?.value[i];
                } else {
                    newItem["ledDefectDetection"] = false;
                }
            }
        }
        console.log("genPowerSchedule", newList);
        setScheduleList(newList);
    };

    const convertScheduleData = (label, item) => {
        const newItem = {};
        const hour = parseInt(item.hour);
        newItem["scheduleApm"] = hour >= 12 ? "pm" : "am";
        let nHour = hour;
        if (hour > 12) {
            nHour = hour - 12;
        }
        if (nHour < 10) {
            nHour = "0" + nHour;
        }
        newItem["hour"] = nHour;
        let min = parseInt(item.minute);
        newItem["minute"] = min < 10 ? "0" + min : min + "";
        newItem["days"] = item.days;
        newItem["scheduleType"] = label.indexOf("onTimer") > -1 ? "on" : "off";
        newItem["oriScheduleType"] = label.indexOf("onTimer") > -1 ? "on" : "off";
        newItem["label"] = label;
        newItem["repeat"] = item.repeat;
        return newItem;
    };

    const updateScheduleList = (item) => {
        const newList = [];
        if (item?.idx && item?.oriScheduleType !== item?.scheduleType) {
            //삭제 후 입력
            for (let n of scheduleList) {
                if (n?.idx !== item?.idx) {
                    newList.push(n);
                }
            }
            newList.push(item);
        } else if (item?.idx) {
            //수정
            for (let n of scheduleList) {
                if (n?.idx === item?.idx) {
                    newList.push(item);
                } else {
                    newList.push(n);
                }
            }
        } else {
            // 등록
            for (let n of scheduleList) {
                newList.push(n);
            }
            newList.push({ ...item, idx: `idx-${newList.length}`, oriScheduleType: item?.scheduleType });
        }
        setScheduleList(newList);
        resizeTable();
    };

    const onScheduleApply = async () => {
        setScheduleApply(false);
        const params = [];
        const scheduleData = {};
        const ledDefectDetectionList = [];
        let onTimerCnt = 0;
        let offTimerCnt = 0;

        let key = "powerSchedule";
        if (_Device.getSupported("powerSchedule", data)) {
            key = "powerSchedule";
        } else if (_Device.getSupported("powerScheduleBoe", data)) {
            key = "powerScheduleBoe";
        }

        if (scheduleList && scheduleList.length > 0) {
            for (let i = 0; i < scheduleList?.length; i++) {
                const item = scheduleList[i];
                const newItem = revertScheduleData(item);
                if (item.scheduleType === "on") {
                    onTimerCnt++;
                    scheduleData["onTimer" + onTimerCnt] = newItem;
                    // console.log(">>", onTimerCnt, scheduleData["onTimer" + onTimerCnt]);
                } else {
                    offTimerCnt++;
                    scheduleData["offTimer" + offTimerCnt] = newItem;
                    // console.log(">>", offTimerCnt, scheduleData["offTimer" + offTimerCnt]);
                }
                if (data?.filter((f) => f["powerSchedule"])[0]?.["powerSchedule"]?.spec?.inputType?.values[0]?.valdata?.length > 0) {
                    scheduleData["onTimerInput" + onTimerCnt] = item.input || null;
                } else if (data?.filter((f) => f["powerScheduleBoe"])[0]?.["powerScheduleBoe"]?.spec?.inputType?.values[0]?.valdata?.length > 0) {
                    scheduleData["onTimerInput" + onTimerCnt] = item.input || null;
                }
                if (data?.filter((f) => f["ledDefectDetection"])[0]?.["ledDefectDetection"]?.data?.value?.length > 0) {
                    ledDefectDetectionList.push(item.ledDefectDetection || false);
                }
            }
        }
        for (let i = onTimerCnt + 1; i <= maxScheduleCnt; i++) {
            scheduleData["onTimer" + i] = null;
            if (data?.filter((f) => f["powerSchedule"])[0]?.["powerSchedule"]?.spec?.inputType?.values[0]?.valdata?.length > 0) {
                scheduleData["onTimerInput" + i] = null;
            } else if (data?.filter((f) => f["powerScheduleBoe"])[0]?.["powerScheduleBoe"]?.spec?.inputType?.values[0]?.valdata?.length > 0) {
                scheduleData["onTimerInput" + i] = null;
            }
        }
        for (let i = offTimerCnt + 1; i <= maxScheduleCnt; i++) {
            scheduleData["offTimer" + i] = null;
        }
        if (data?.filter((f) => f["ledDefectDetection"])[0]?.["ledDefectDetection"]?.data?.value?.length > 0) {
            params.push({
                ledDefectDetection: { data: { value: ledDefectDetectionList } },
            });
        }

        params.push({
            [key]: { data: scheduleData },
        });

        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
        } else {
            setScheduleApply(false);
        }
    };

    const revertScheduleData = (item) => {
        const newItem = {};
        if (item?.scheduleApm === "pm") {
            let hour = parseInt(item?.hour);
            if (hour !== 12) {
                hour = hour + 12;
            }
            newItem["hour"] = hour + "";
        } else {
            newItem["hour"] = parseInt(item?.hour) + "";
        }
        newItem["minute"] = parseInt(item?.minute) + "";
        newItem["days"] = item?.days;
        newItem["repeat"] = item?.days && item?.days?.length > 0 ? "0D" : item?.repeat;
        // console.log("revertData", newItem);
        return newItem;
    };

    const changeData = (key, value) => {
        if (["dpmSelect", "pmMode"].indexOf(key) > -1 && value != "00" && !dataAlarmInfo[key]) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.message.energyconsumptiondisplay"),
                button: [t("button.cancel"), t("common.ok")],
            }).then((id) => {
                if (id) {
                    setDeviceControl(key, value, setDataAlramInfo, { ...dataAlarmInfo, [key]: true });
                }
            });
        } else if (["dpmSelect"].indexOf(key) > -1 && value == "00" && !dataAlarmInfo[key + "00"]) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.message.noirpoweroff"),
                button: [t("button.cancel"), t("common.ok")],
            }).then((id) => {
                if (id) {
                    setDeviceControl(key, value, setDataAlramInfo, { ...dataAlarmInfo, [key + "00"]: true });
                }
            });
        } else {
            setDeviceControl(key, value);
        }
    };

    const setDeviceControl = async (key, value, successFunc, funcData) => {
        const params = [{ [key]: { data: { value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
            const newData = JSON.parse(JSON.stringify(data));
            newData?.forEach((f) => {
                if (f[key]) {
                    f[key]["data"]["value"] = value;
                }
            });
            setData(newData);
            successFunc && successFunc(funcData);
        }
    };

    const onScheduleSort = () => {
        const asc = sortAsc === null ? true : !sortAsc;
        setSortAsc(asc);
        if (scheduleList && scheduleList.length > 0) {
            let newList = [];
            if (asc) {
                newList = scheduleList?.sort((a, b) => (a?.scheduleType > b?.scheduleType ? 1 : -1));
            } else {
                newList = scheduleList?.sort((a, b) => (a?.scheduleType > b?.scheduleType ? -1 : 1));
            }
            setScheduleList(newList);
        }
    };

    useEffect(() => {
        onLoadData();
        resizeTable();
    }, [idx, lastUpdateTime]);

    return (
        <Fragment>
            <div className="tab-panel panel-power" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        {_Device.getSupported("powerOn", data) && (
                            <div className="field field-flex field-reboot">
                                <span className="field-label">{t("device.label.poweron")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-reboot" onClick={() => changeData("powerOn", true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("device.label.poweron")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("powerOff", data) && (
                            <div className="field field-flex field-power-off">
                                <span className="field-label">{t("device.label.poweroff")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-power-off" onClick={onPowerOff} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("device.label.poweroff")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("reboot", data) && (
                            <div className="field field-flex field-reboot">
                                <span className="field-label">{t("player.label.reboot")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-reboot" onClick={onReboot} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("player.label.reboot")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="field field-flex field-screen-off">
                            <span className="field-label">{t("common.screenon")}</span>
                            <div className="field-form">
                                <div className="field-box">
                                    <button type="button" className="btn btn-screen-off" onClick={() => changeData("screenOnOff", true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                        {t("common.screenon")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {_Device.getSupported("screenOff", data) && (
                            <div className="field field-flex field-screen-off">
                                <span className="field-label">{t("player.label.energySaving.screenOff")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-screen-off" onClick={onScreenOff} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("player.label.energySaving.screenOff")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("noIRPowerOff", data) && (
                            <div className="field field-flex field-no-ir-power-off">
                                <span className="field-label">{t("device.label.noirpoweroff")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <div className="switch">
                                            <label htmlFor="default-switch-1" className="switch-controller">
                                                {t("device.label.noirpoweroff")}
                                            </label>
                                            <input
                                                type="checkbox"
                                                id="default-switch-1"
                                                name="default-switch-1"
                                                role="switch"
                                                aria-checked="true"
                                                checked={ir}
                                                onChange={() => noIRPowerOff()}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                            <span className="slider"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("noSignalPowerOff", data) && (
                            <div className="field field-flex field-no-signal-power-off">
                                <span className="field-label">{t("device.label.nosignalpoweroff")}</span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <div className="switch">
                                            <label htmlFor="default-switch-2" className="switch-controller">
                                                {t("device.label.nosignalpoweroff")}
                                            </label>
                                            <input
                                                type="checkbox"
                                                id="default-switch-2"
                                                name="default-switch-2"
                                                role="switch"
                                                aria-checked="true"
                                                checked={signal}
                                                onChange={() => noSignalPowerOff()}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                            <span className="slider"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("dpmSelect", data) && (
                            <div className="field field-flex field-dpm">
                                <span className="field-label" id="dpmSelectLabel">
                                    {t("player.label.dpm")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="dpmSelect"
                                            items={_Device.getDropDownItems(t, null, "dpmSelect", data)}
                                            value={_Device.getHexRangeValue(t, "dpmSelect", data)}
                                            ariaLabelId={"dpmSelectLabel"}
                                            selectLabel={_Device.getHexRangeValue(t, "dpmSelect", data)}
                                            onChange={(item) => changeData("dpmSelect", item?.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("pmMode", data) && (
                            <div className="field field-flex field-pm-mode">
                                <span className="field-label" id="pmModeLabel">
                                    {t("device.label.pmmode")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="pmMode"
                                            items={_Device.getDropDownItems(t, null, "pmMode", data)}
                                            value={_Device.getHexRangeValue(t, "pmMode", data)}
                                            ariaLabelId={"pmModeLabel"}
                                            selectLabel={_Device.getHexRangeValue(t, "pmMode", data)}
                                            onChange={(item) => changeData("pmMode", item?.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {_Device.getSupported("powerOnDelay", data) && (
                            <div className="field field-flex field-power-on-delay">
                                <span className="field-label" id="powerOnDelayLabel">
                                    {t("player.label.powerOnDelay")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="powerOnDelay"
                                            items={_Device.getDropDownItems(t, null, "powerOnDelay", data)}
                                            value={_Device.getHexRangeValue(t, "powerOnDelay", data)}
                                            ariaLabelId={"powerOnDelayLabel"}
                                            selectLabel={_Device.getHexRangeValue(t, "powerOnDelay", data)}
                                            onChange={(item) => changeData("powerOnDelay", item?.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("powerOnStatus", data) && (
                            <div className="field field-flex field-power-on-status">
                                <span className="field-label" id="powerOnStatusLabel">
                                    {t("device.label.poweronstatus")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="powerOnStatus"
                                            items={_Device.getDropDownItems(t, null, "powerOnStatus", data)}
                                            value={_Device.getHexRangeValue(t, "powerOnStatus", data)}
                                            ariaLabelId={"powerOnStatusLabel"}
                                            selectLabel={_Device.getHexRangeValue(t, "powerOnStatus", data)}
                                            onChange={(item) => changeData("powerOnStatus", item?.value)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {(_Device.getSupported("powerSchedule", data) || _Device.getSupported("powerScheduleBoe", data)) && (
                            <div className="field field-flex field-power-on-off-schedule">
                                <span className="field-label">{t("dv.schedule.title")}</span>
                                <div className="field-form field-form-type">
                                    <div className="field-box">
                                        <button type="button" className="btn btn-add" onClick={() => onClickAddSchedule({ scheduleList, data })} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            <img src="/assets/images/btn_icon_add.svg" alt={t("form.label.addschedule")} /> {t("form.label.addschedule")}
                                        </button>
                                        <button type="button" className="btn" onClick={() => onScheduleApply()} disabled={!scheduleApply || _U.getAuthWriteDisable(rolePermission)}>
                                            {t("player.button.apply")}
                                        </button>
                                    </div>

                                    <div className="click-view-box">
                                        <div className="data-list-container">
                                            <div className="data-list-top">
                                                <div className="right-side">
                                                    <div className="total-count">
                                                        {t("player.label.total")} <span className="count">{scheduleList?.length || 0}</span>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={onScheduleDeleteAll}
                                                        disabled={!scheduleList || scheduleList.length === 0 || _U.getAuthWriteDisable(rolePermission)}
                                                    >
                                                        {t("player.button.deleteAll")}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="table">
                                                <div className="table-wrapper schedule-setting no-scroll">
                                                    <div className="table-box">
                                                        <table>
                                                            <caption>{t("schedule.label.list")}</caption>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="sorting" aria-sort={sortAsc === null ? "none" : sortAsc ? "ascending" : "descending"}>
                                                                        <button type="button" className={cx({ "btn-sort": true, asc: sortAsc, desc: !sortAsc })} onClick={onScheduleSort}>
                                                                            {t("table.head.scheduletype")}
                                                                        </button>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("common.time")}</div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("common.label.dayofweek")}</div>
                                                                    </th>
                                                                    {data?.filter((f) => f["powerSchedule"])[0]?.["powerSchedule"]?.spec?.inputType?.values[0]?.valdata?.length > 0 && (
                                                                        <th scope="col">
                                                                            <div className="text-block">{t("player.label.input")}</div>
                                                                        </th>
                                                                    )}
                                                                    <th scope="col" width="79">
                                                                        <div className="text-block">{t("common.edit")}</div>
                                                                    </th>
                                                                    <th scope="col" width="79">
                                                                        <div className="text-block">{t("button.delete")}</div>
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {scheduleList?.map((item, index) => {
                                                                    if (loadMore === "more" && index > 2) {
                                                                        return "";
                                                                    }
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td id={`schedule-td1-${index}`}>
                                                                                {item?.scheduleType === "on" ? t("common.code.poweronoffschedule.on") : t("common.code.poweronoffschedule.off")}
                                                                            </td>
                                                                            <td id={`schedule-td2-${index}`}>
                                                                                {item?.hour}:{item?.minute} {item?.scheduleApm?.toUpperCase()}
                                                                            </td>
                                                                            <td id={`schedule-td3-${index}`}>
                                                                                {item?.repeat != "0D"
                                                                                    ? t("wrapper.onOffScheduleType3.repeat." + item?.repeat)
                                                                                    : item?.days?.length > 0
                                                                                    ? item?.days?.length === 7
                                                                                        ? t("wrapper.onOffScheduleType3.repeat.02")
                                                                                        : item?.days?.map((m) => t("wrapper.onOffScheduleType1." + m))?.join(",")
                                                                                    : ""}
                                                                            </td>
                                                                            {data?.filter((f) => f["powerSchedule"])[0]?.["powerSchedule"]?.spec?.inputType?.values[0]?.valdata?.length > 0 && (
                                                                                <td id={`schedule-td4-${index}`}>
                                                                                    {item?.input ? t("wrapper.onOffScheduleType2supportInput.input." + item?.input) : ""}
                                                                                </td>
                                                                            )}
                                                                            <td width="79">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-edit btn-small"
                                                                                    onClick={() => onClickAddSchedule({ item, scheduleList, data })}
                                                                                    aria-describedby={`schedule-td1-${index} schedule-td2-${index} schedule-td3-${index}`}
                                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                                >
                                                                                    {t("common.edit")}
                                                                                </button>
                                                                            </td>
                                                                            <td width="79">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-delete"
                                                                                    onClick={() => onClickRemove(index)}
                                                                                    aria-describedby={`schedule-td1-${index} schedule-td2-${index} schedule-td3-${index}`}
                                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                                >
                                                                                    {t("button.delete")}
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                {(!scheduleList || scheduleList?.length === 0) && (
                                                                    <tr>
                                                                        <td
                                                                            colSpan={
                                                                                data?.filter((f) => f["powerSchedule"])[0]?.["powerSchedule"]?.spec?.inputType?.values[0]?.valdata?.length > 0 ? 6 : 5
                                                                            }
                                                                            className="align-center"
                                                                        >
                                                                            {t("common.label.noitems")}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="convert-table-to-list">
                                                    <div className="table-header">
                                                        <div className="row">
                                                            <div className="thead">{t("table.head.scheduletype")}</div>
                                                        </div>
                                                    </div>

                                                    <ul className="table-body">
                                                        {scheduleList?.map((item, index) => (
                                                            <li className="table-row" key={`convert-${index}`}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell issue-level" aria-label={t("table.head.scheduletype")}>
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                                                {item?.scheduleType === "on" ? t("common.code.poweronoffschedule.on") : t("common.code.poweronoffschedule.off")}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li id={`schedule-li1-${index}`}>
                                                                            <span className="field-label">{t("table.head.scheduletype")}</span>
                                                                            <span className="field-content">
                                                                                {item?.scheduleType === "on" ? t("common.code.poweronoffschedule.on") : t("common.code.poweronoffschedule.off")}
                                                                            </span>
                                                                        </li>
                                                                        <li id={`schedule-li2-${index}`}>
                                                                            <span className="field-label">{t("common.time")}</span>
                                                                            <span className="field-content">
                                                                                {" "}
                                                                                {item?.hour}:{item?.minute} {item?.scheduleApm?.toUpperCase()}
                                                                            </span>
                                                                        </li>
                                                                        <li id={`schedule-li3-${index}`}>
                                                                            <span className="field-label">{t("common.label.dayofweek")}</span>
                                                                            <span className="field-content">
                                                                                {item?.repeat != "0D"
                                                                                    ? t("wrapper.onOffScheduleType3.repeat." + item?.repeat)
                                                                                    : item?.days?.length > 0
                                                                                    ? item?.days?.length === 7
                                                                                        ? t("wrapper.onOffScheduleType3.repeat.02")
                                                                                        : item?.days?.map((m) => t("wrapper.onOffScheduleType1." + m))?.join(",")
                                                                                    : ""}
                                                                            </span>
                                                                        </li>
                                                                        {data?.filter((f) => f["powerSchedule"])[0]?.["powerSchedule"]?.spec?.inputType?.values[0]?.valdata?.length > 0 && (
                                                                            <li id={`schedule-li4-${index}`}>
                                                                                <span className="field-label">{t("player.label.input")}</span>
                                                                                <span className="field-content">
                                                                                    {item?.input ? t("wrapper.onOffScheduleType2supportInput.input." + item?.input) : ""}
                                                                                </span>
                                                                            </li>
                                                                        )}
                                                                        <li>
                                                                            <span className="field-label">{t("common.edit")}</span>
                                                                            <span className="field-content">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-edit btn-small"
                                                                                    onClick={() => onClickAddSchedule({})}
                                                                                    aria-describedby={`schedule-li1-${index} schedule-li2-${index} schedule-li3-${index}`}
                                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                                >
                                                                                    {t("common.edit")}
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("button.delete")}</span>
                                                                            <span className="field-content">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-delete"
                                                                                    onClick={() => onClickRemove(index)}
                                                                                    aria-describedby={`schedule-li1-${index} schedule-li2-${index} schedule-li3-${index}`}
                                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                                >
                                                                                    {t("button.delete")}
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        ))}
                                                        {(!scheduleList || scheduleList?.length === 0) && (
                                                            <li className="table-row">
                                                                <div className="summary-data">
                                                                    <div className="row">{t("common.label.noitems")}</div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                            {scheduleList && scheduleList.length > 3 && loadMore === "more" ? (
                                                <div className="btn-center-box">
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setLoadMore("fold");
                                                            resizeTable(null, setWindowWidth);
                                                        }}
                                                    >
                                                        {t("common.button.loadmore")}
                                                    </button>
                                                </div>
                                            ) : scheduleList && scheduleList.length > 3 && loadMore === "fold" ? (
                                                <div className="btn-center-box">
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setLoadMore("more");
                                                            resizeTable(null, setWindowWidth);
                                                        }}
                                                    >
                                                        {t("common.button.fold")}
                                                    </button>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {_Device.getSupported("powerOnOffHistory", data) && (
                            <div className="field field-flex field-power-on-status">
                                <span className="field-label" id="powerOnOffHistory">
                                    {t("device.label.poweronoffhistory")}
                                </span>
                                {/* <span className="field-content">
                                    {data?.filter((f) => f["powerOnOffHistory"])[0]?.["powerOnOffHistory"]?.data?.value
                                        ? JSON.stringify(data?.filter((f) => f["powerOnOffHistory"])[0]?.["powerOnOffHistory"]?.data?.value)
                                        : "-"}
                                </span> */}
                                <div className="field-form field-form-type">
                                    <div className="click-view-box">
                                        <div className="data-list-container">
                                            <div className="data-list-top">
                                                <div className="right-side">
                                                    <div className="total-count">
                                                        {t("player.label.total")}{" "}
                                                        <span className="count">{data?.filter((f) => f["powerOnOffHistory"])[0]?.["powerOnOffHistory"]?.data?.value?.length || 0}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table">
                                                <div className="table-wrapper schedule-setting no-scroll">
                                                    <div className="table-box">
                                                        <table>
                                                            <caption>{t("schedule.label.list")}</caption>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="text-block">
                                                                        {t("table.head.date")}
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("report.label.action")}</div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("dv.powerhistory.method")}</div>
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {data
                                                                    ?.filter((f) => f["powerOnOffHistory"])[0]
                                                                    ?.["powerOnOffHistory"]?.data?.value?.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{item?.date ? _U.getOffsetTime(auth, workspace, item?.date) : ""}</td>
                                                                            <td>{item?.action}</td>
                                                                            <td>{item?.method}</td>
                                                                        </tr>
                                                                    ))}
                                                                {(!data?.filter((f) => f["powerOnOffHistory"])[0]?.["powerOnOffHistory"]?.data?.value ||
                                                                    data?.filter((f) => f["powerOnOffHistory"])[0]?.["powerOnOffHistory"]?.data?.value?.length === 0) && (
                                                                    <tr>
                                                                        <td colSpan={3} className="align-center">
                                                                            {t("common.label.noitems")}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="convert-table-to-list">
                                                    <div className="table-header">
                                                        <div className="row">
                                                            <div className="thead">{t("table.head.date")}</div>
                                                        </div>
                                                    </div>

                                                    <ul className="table-body">
                                                        {data
                                                            ?.filter((f) => f["powerOnOffHistory"])[0]
                                                            ?.["powerOnOffHistory"]?.data?.value?.map((item, index) => (
                                                                <li className="table-row" key={`convert-${index}`}>
                                                                    <div className="summary-data">
                                                                        <div className="row">
                                                                            <div className="table-cell issue-level" aria-label={t("table.head.scheduletype")}>
                                                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                                                    {item?.date ? _U.getOffsetTime(auth, workspace, item?.date) : ""}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="all-data-box">
                                                                        <ul className="all-data">
                                                                            <li id={`schedule-li1-${index}`}>
                                                                                <span className="field-label">{t("report.label.action")}</span>
                                                                                <span className="field-content">{item?.action}</span>
                                                                            </li>
                                                                            <li id={`schedule-li2-${index}`}>
                                                                                <span className="field-label">{t("dv.powerhistory.method")}</span>
                                                                                <span className="field-content">{item?.method}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        {(!data?.filter((f) => f["powerOnOffHistory"])[0]?.["powerOnOffHistory"]?.data?.value ||
                                                            data?.filter((f) => f["powerOnOffHistory"])[0]?.["powerOnOffHistory"]?.data?.value?.length === 0) && (
                                                            <li className="table-row">
                                                                <div className="summary-data">
                                                                    <div className="row">{t("common.label.noitems")}</div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {addScheduleModal && (
                <AddScheduleModal
                    item={selectItem}
                    maxScheduleCnt={maxScheduleCnt}
                    onClose={() => {
                        setSelectItem(null);
                        setAddScheduleModal(false);
                    }}
                    onDone={(item) => {
                        setSelectItem(null);
                        setAddScheduleModal(false);
                        updateScheduleList(item);
                        setScheduleApply(true);
                    }}
                />
            )}
        </Fragment>
    );
};

const AddScheduleModal = (props) => {
    const { t } = useTranslation();
    const { item, maxScheduleCnt } = props;
    const [data, setData] = useState({});
    const [scheduleList, setScheduleList] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [ledDefectDetection, setLedDefectDetection] = useState(false);

    const apmList = [
        { label: t("schedule.code.am"), value: "am" },
        { label: t("schedule.code.pm"), value: "pm" },
    ];
    const hours = [...Array(12)].map((m, i) => ({ label: i < 9 ? "0" + (i + 1) : i + 1 + "", value: i < 9 ? "0" + (i + 1) : i + 1 + "" }));
    const minutes = [...Array(60)].map((m, i) => ({ label: i < 10 ? "0" + i : i + "", value: i < 10 ? "0" + i : i + "" }));

    const onClickDone = () => {
        if (!data?.scheduleType) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("table.head.scheduletype") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (!data?.hour || !data?.minute || !data?.scheduleApm) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("common.time") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (!data?.days || data?.days?.length === 0) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("common.label.Repeaton") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else {
            // LGCCAMOEBA-1167 요청으로 중복시간 체크 차단
            // const dupCnt = onCheckDupTime();
            // if (dupCnt > 0) {
            //     Dialog({
            //         title: t("popup.title.inform"),
            //         text: t("common.message.schedule.duptime"),
            //         button: t("common.ok"),
            //     }).then((id) => {
            //         if (id) {
            //         }
            //     });
            // } else {
            if (onMaxScheduleCheck(data)) {
                props.onDone && props.onDone({ ...data, repeat: "0D" });
            } else {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.message.max.schedule", { cnt: maxScheduleCnt, type: data.scheduleType }),
                    button: t("common.ok"),
                }).then((id) => {
                    if (id) {
                    }
                });
            }
            // }
        }
    };

    const onCheckDupTime = () => {
        let dupCnt = 0;
        scheduleList?.forEach((f) => {
            if (f) {
                if (
                    f?.idx !== data?.idx &&
                    f?.hour === data?.hour &&
                    f?.minute === data?.minute &&
                    f?.scheduleApm === data?.scheduleApm &&
                    f?.days?.filter((f) => data?.days?.includes(f))?.length > 0
                ) {
                    // && f.scheduleType === data?.scheduleType && f.label !== data?.label
                    dupCnt++;
                }
            }
        });
        return dupCnt;
    };

    const onChangeData = (key, value) => {
        const newData = JSON.parse(JSON.stringify(data));
        newData[key] = value;
        setData(newData);
    };

    const onChangeDays = (key, e) => {
        const newData = JSON.parse(JSON.stringify(data));
        if (!newData?.days) {
            newData["days"] = [];
        }
        if (e.target.checked) {
            newData?.days.push(key);
        } else {
            newData?.days.splice(newData?.days.indexOf(key), 1);
        }
        setData(newData);
    };

    const onMaxScheduleCheck = (item) => {
        const scheduleCnt = scheduleList?.filter((f) => f.scheduleType === item.scheduleType)?.length || 0;
        if (item.idx && item.oriScheduleType !== item.scheduleType) {
            //수정 - 스케줄 타입 변경 시 이전타입 삭제 후 신규 타입 등록
            if (scheduleCnt + 1 > maxScheduleCnt) {
                return false;
            }
        } else if (item.idx) {
            //수정
        } else {
            // 등록
            if (scheduleCnt + 1 > maxScheduleCnt) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        if (item?.item) {
            setData(item.item);
        }
        if (item?.scheduleList) {
            setScheduleList(item.scheduleList);
        }
        if (item?.data) {
            let code = "";
            if (item?.data?.filter((f) => f["powerSchedule"])[0]?.["powerSchedule"]?.spec?.inputType?.values[0]?.valdata?.length > 0) {
                code = "powerSchedule";
            } else if (item?.data?.filter((f) => f["powerScheduleBoe"])[0]?.["powerScheduleBoe"]?.spec?.inputType?.values[0]?.valdata?.length > 0) {
                code = "powerScheduleBoe";
            }
            if (code) {
                const list = item?.data?.filter((f) => f[code])[0]?.[code]?.spec?.inputType?.values[0]?.valdata || [];
                const newList = [];
                for (let n of list) {
                    newList.push({ label: t("wrapper.onOffScheduleType2supportInput.input." + n), value: n });
                }
                setInputList(newList);
            }
            if (item?.data?.filter((f) => f["ledDefectDetection"])[0]?.["ledDefectDetection"]?.data?.value?.length > 0) {
                setLedDefectDetection(true);
            }
        }
    }, [item]);

    return (
        <Modal
            title={item?.item ? t("common.label.EditOnOffSchedule") : t("form.label.addschedule")}
            id="popup-select-file"
            className="popup-add-schedule popup-type no-scroll"
            onClose={props.onClose}
            onClick={onClickDone}
        >
            <div className="field">
                <label className="field-label">{t("table.head.scheduletype")}</label>
                <div className="field-form">
                    <div className="field-box">
                        <ul role="radiogroup">
                            <li className="radio">
                                <input type="radio" name="rad-tem-type01" id="rad-tem-type01-1" checked={data?.scheduleType === "on"} onChange={() => onChangeData("scheduleType", "on")} />
                                <label htmlFor="rad-tem-type01-1">{t("common.code.poweronoffschedule.on")}</label>
                            </li>
                            <li className="radio">
                                <input type="radio" name="rad-tem-type01" id="rad-tem-type01-2" checked={data?.scheduleType === "off"} onChange={() => onChangeData("scheduleType", "off")} />
                                <label htmlFor="rad-tem-type01-2">{t("common.code.poweronoffschedule.off")}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="field field-type-dropdown field-repeat">
                <label className="field-label" id="scheduleTime">
                    {t("common.time")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <SelectBox
                            id="scheduleApm"
                            items={apmList}
                            value={data?.scheduleApm || ""}
                            ariaLabelId={"scheduleTime"}
                            selectLabel={apmList.filter((f) => f.value === data?.scheduleApm)[0]?.label || "ㅤ"}
                            onChange={(item) => onChangeData("scheduleApm", item.value)}
                        />

                        <SelectBox
                            id="scheduleHour"
                            items={hours}
                            value={data?.hour || ""}
                            ariaLabelId={"scheduleTime"}
                            selectLabel={data?.hour || "ㅤ"}
                            onChange={(item) => onChangeData("hour", item.value)}
                        />
                        <span className="colon"> : </span>
                        <SelectBox
                            id="scheduleMinute"
                            items={minutes}
                            value={data?.minute || ""}
                            ariaLabelId={"scheduleTime"}
                            selectLabel={data?.minute || "ㅤ"}
                            onChange={(item) => onChangeData("minute", item.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="field field-type-checkbox field-unique-class">
                <label className="field-label">{t("common.label.Repeaton")}</label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-week" id="checkbox-week-mon" checked={data && data?.days?.indexOf("mon") > -1} onChange={(e) => onChangeDays("mon", e)} />
                            <label htmlFor="checkbox-week-mon">{t("wrapper.onOffScheduleType1.mon")}</label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-week" id="checkbox-week-tue" checked={data && data?.days?.indexOf("tue") > -1} onChange={(e) => onChangeDays("tue", e)} />
                            <label htmlFor="checkbox-week-tue">{t("wrapper.onOffScheduleType1.tue")}</label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-week" id="checkbox-week-wed" checked={data && data?.days?.indexOf("wed") > -1} onChange={(e) => onChangeDays("wed", e)} />
                            <label htmlFor="checkbox-week-wed">{t("wrapper.onOffScheduleType1.wed")}</label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-week" id="checkbox-week-thu" checked={data && data?.days?.indexOf("thu") > -1} onChange={(e) => onChangeDays("thu", e)} />
                            <label htmlFor="checkbox-week-thu">{t("wrapper.onOffScheduleType1.thu")}</label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-week" id="checkbox-week-fri" checked={data && data?.days?.indexOf("fri") > -1} onChange={(e) => onChangeDays("fri", e)} />
                            <label htmlFor="checkbox-week-fri">{t("wrapper.onOffScheduleType1.fri")}</label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-week" id="checkbox-week-sat" checked={data && data?.days?.indexOf("sat") > -1} onChange={(e) => onChangeDays("sat", e)} />
                            <label htmlFor="checkbox-week-sat">{t("wrapper.onOffScheduleType1.sat")}</label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-week" id="checkbox-week-sun" checked={data && data?.days?.indexOf("sun") > -1} onChange={(e) => onChangeDays("sun", e)} />
                            <label htmlFor="checkbox-week-sun">{t("wrapper.onOffScheduleType1.sun")}</label>
                        </div>
                    </div>
                </div>
            </div>
            {inputList?.length > 0 && data?.scheduleType === "on" && (
                <div className="field field-type-dropdown field-repeat">
                    <label className="field-label" id="scheduleInputLabel">
                        {t("player.label.input")}
                    </label>
                    <div className="field-form">
                        <div className="field-box">
                            <SelectBox
                                id="scheduleInput"
                                items={inputList}
                                value={data?.input || ""}
                                ariaLabelId={"scheduleInputLabel"}
                                selectLabel={inputList.filter((f) => f.value === data?.input)[0]?.label || "ㅤ"}
                                onChange={(item) => onChangeData("input", item.value)}
                            />
                        </div>
                    </div>
                </div>
            )}
            {ledDefectDetection && (
                <div className="field field-type-checkbox field-unique-class">
                    <div className="field-form">
                        <div className="field-box">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    name="ledDefectDetection"
                                    id="ledDefectDetection"
                                    checked={data?.ledDefectDetection}
                                    onChange={(e) => onChangeData("ledDefectDetection", e.target.checked)}
                                />
                                <label htmlFor="ledDefectDetection">{t("led.msg.detect.power.on")}</label>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const ControlSupport = (props) => {
    const { idx, uniControlDevice, deviceInfo } = props;
    const { t } = useTranslation();
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);
    const [lockModal, setLockModal] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);
    const [daylightModal, setDaylightModal] = useState(false);
    const [rs232cModal, setRs232cModal] = useState(false);

    const [remote, setRemote] = useState(false);
    const [osd, setOsd] = useState(false);
    const [ir, setIr] = useState(false);
    const [touch, setTouch] = useState(false);
    const [daylight, setDaylight] = useState(false);
    const [data, setData] = useState([]);
    const [usbLock, setUsbLock] = useState(false);
    const [allowInstallUnkownApp, setAllowInstallUnkownApp] = useState(false);

    const onRemoteKeyLock = async () => {
        const value = !remote;
        setRemote(value);
        const params = [{ remoteLock: { data: { value: value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
        } else {
            setRemote(value);
        }
    };
    const onOsdLock = async () => {
        const value = !osd;
        setOsd(value);
        const params = [{ osdLock: { data: { value: value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
        } else {
            setOsd(!value);
        }
    };
    const onIrLock = async () => {
        const value = !ir;
        setIr(value);
        const params = [{ irLock: { data: { value: value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
        } else {
            setIr(!value);
        }
    };
    const onTouchLock = async () => {
        const value = !touch;
        setTouch(value);
        const params = [{ touchLock: { data: { value: value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
        } else {
            setTouch(!value);
        }
    };

    const onUsbLock = async () => {
        const value = !usbLock;
        setUsbLock(value);
        const params = [{ USBLock: { data: { value: value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
        } else {
            setUsbLock(!value);
        }
    };

    const onAllowInstallUnkownApp = async () => {
        const value = !allowInstallUnkownApp;
        setAllowInstallUnkownApp(value);
        const params = [{ allowInstallUnkownApp: { data: { value: value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
        } else {
            setAllowInstallUnkownApp(!value);
        }
    };

    const onDaylightSavingTime = async () => {
        resizeTable();
        if (daylight) {
            const params = [{ daylightSavingTimeOff: { data: { daylightSet: false } } }];
            let res = null;
            if (uniControlDevice) {
                res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
            } else {
                res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
            }
            if (res && res.status === "true") {
                setDaylight(!daylight);
            } else {
                return;
            }
        }
        setDaylight(!daylight);
    };

    const onScreenShare = async () => {
        Dialog({
            title: t("common.disable"),
            text: t("device.msg.screensharedisable"),
            button: [t("button.cancel"), t("common.disable")],
        }).then(async (id) => {
            if (id) {
                const params = [{ screenshare: { data: { value: "off" } } }];
                let res = null;
                if (uniControlDevice) {
                    res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
                } else {
                    res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
                }
            }
        });
    };

    const onLoadData = () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: [
                    // "remoteLock",
                    // "osdLock",
                    // "irLock",
                    // "screenLock",
                    // "touchLock",
                    // "date",
                    // "time",
                    // "timezone",
                    // "timeZone",
                    // "daylightSavingTime",
                    // "daylightSavingTimeOff",
                    // "language",
                    // "screenshare",
                    // "__rs232cCommand",

                    // "USBLock",
                    // "ismMode",
                    // "allowInstallUnkownApp",

                    "remoteLock",
                    "osdLock",
                    "irLock",
                    "screenLock",
                    "touchLock",
                    "USBLock",
                    "date",
                    "time",
                    "timeZone",
                    "daylightSavingTime",
                    "daylightSavingTimeOff",
                    "language",
                    "screenshare",
                    "allowInstallUnkownApp",
                    "__rs232cCommand",
                ],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                setRemote(result?.filter((f) => f["remoteLock"])[0]?.["remoteLock"]?.data?.value || false);
                setOsd(result?.filter((f) => f["osdLock"])[0]?.["osdLock"]?.data?.value || false);
                setIr(result?.filter((f) => f["irLock"])[0]?.["irLock"]?.data?.value || false);
                setTouch(result?.filter((f) => f["touchLock"])[0]?.["touchLock"]?.data?.value || false);
                setDaylight(result?.filter((f) => f["daylightSavingTime"])[0]?.["daylightSavingTime"]?.data?.daylightSet ? true : false);
                setAllowInstallUnkownApp(result?.filter((f) => f["allowInstallUnkownApp"])[0]?.["allowInstallUnkownApp"]?.data?.value || false);
                resizeTable();
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    const changeData = async (key, value) => {
        const params = [{ [key]: { data: { value } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
            const newData = JSON.parse(JSON.stringify(data));
            newData?.forEach((f) => {
                if (f[key]) {
                    f[key]["data"]["value"] = value;
                }
            });
            setData(newData);
        }
    };

    const onScreenLock = async (passwd, lock) => {
        const params = [{ screenLock: { data: { onOff: lock === "True", password: passwd } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
            setLockModal(false);
            setUnlockModal(false);
        }
    };

    const saveDaylight = async (item) => {
        const params = [{ daylightSavingTime: { data: { ...item, daylightSet: true } } }];
        let res = null;
        if (uniControlDevice) {
            res = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (res && res.status === "true") {
            const newData = JSON.parse(JSON.stringify(data));
            newData?.forEach((f) => {
                if (f["daylightSavingTime"]) {
                    f["daylightSavingTime"]["data"] = item;
                }
            });
            setData(newData);
        }
    };

    useEffect(() => {
        onLoadData();
        resizeTable();
    }, [idx, lastUpdateTime]);

    // useEffect(() => {
    //     if (daylight) {
    //         setDaylightModal(true);
    //     }
    // }, [daylight]);

    const monthList = [
        { label: t("device.label.january"), value: 1 },
        { label: t("device.label.february"), value: 2 },
        { label: t("device.label.march"), value: 3 },
        { label: t("device.label.april"), value: 4 },
        { label: t("device.label.may"), value: 5 },
        { label: t("device.label.june"), value: 6 },
        { label: t("device.label.july"), value: 7 },
        { label: t("device.label.august"), value: 8 },
        { label: t("device.label.september"), value: 9 },
        { label: t("device.label.october"), value: 10 },
        { label: t("device.label.november"), value: 11 },
        { label: t("device.label.december"), value: 12 },
    ];

    const weekList = [
        { value: "01", label: t("device.label.first") },
        { value: "02", label: t("device.label.second") },
        { value: "03", label: t("device.label.third") },
        { value: "04", label: t("device.label.fourth") },
        { value: "05", label: t("device.label.last") },
    ];

    const dayOfTheWeekList = [
        { value: "00", label: t("admin.label.sunday") },
        { value: "01", label: t("admin.label.monday") },
        { value: "02", label: t("admin.label.tuesday") },
        { value: "03", label: t("admin.label.wednesday") },
        { value: "04", label: t("admin.label.thursday") },
        { value: "05", label: t("admin.label.friday") },
        { value: "06", label: t("admin.label.saturday") },
    ];

    return (
        <Fragment>
            <div className="tab-panel panel-support" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        {(_Device.getSupported("remoteLock", data) ||
                            _Device.getSupported("osdLock", data) ||
                            _Device.getSupported("irLock", data) ||
                            _Device.getSupported("screenLock", data) ||
                            _Device.getSupported("touchLock", data) ||
                            _Device.getSupported("USBLock", data)) && (
                            <div>
                                <div className="details-tit">{t("dv.support.lock")}</div>

                                {_Device.getSupported("remoteLock", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("dv.support.remoteKeylock")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                {/* <!-- 아래 switch 구조만 사용 --> */}
                                                <div className="switch">
                                                    <label htmlFor="default-switch-3" className="switch-controller">
                                                        {t("dv.support.remoteKeylock")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="default-switch-3"
                                                        name="default-switch-3"
                                                        role="switch"
                                                        aria-checked={remote}
                                                        value="Y"
                                                        checked={remote}
                                                        onChange={() => onRemoteKeyLock()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {_Device.getSupported("osdLock", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("device.label.osdlock")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                {/* <!-- 아래 switch 구조만 사용 --> */}
                                                <div className="switch">
                                                    <label htmlFor="default-switch-4" className="switch-controller">
                                                        {t("device.label.osdlock")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="default-switch-4"
                                                        name="default-switch-4"
                                                        role="switch"
                                                        aria-checked={osd}
                                                        value="Y"
                                                        checked={osd}
                                                        onChange={() => onOsdLock()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("irLock", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("schedule.commend.ir_lock_unlock")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                {/* <!-- 아래 switch 구조만 사용 --> */}
                                                <div className="switch">
                                                    <label htmlFor="default-switch-5" className="switch-controller">
                                                        {t("schedule.commend.ir_lock_unlock")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="default-switch-5"
                                                        name="default-switch-5"
                                                        role="switch"
                                                        aria-checked={ir}
                                                        value="Y"
                                                        checked={ir}
                                                        onChange={() => onIrLock()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("screenLock", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("schedule.commend.screen_lock_unlock")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <button type="button" className="btn" onClick={() => setLockModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                    {t("schedule.commend.screen_lock_unlock")}
                                                </button>
                                                <button type="button" className="btn" onClick={() => onScreenLock("", "False")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                    {t("form.label.screenunlock")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("touchLock", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("schedule.commend.touch_lock_unlock")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                {/* <!-- 아래 switch 구조만 사용 --> */}
                                                <div className="switch">
                                                    <label htmlFor="default-switch-6" className="switch-controller">
                                                        {t("schedule.commend.touch_lock_unlock")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="default-switch-6"
                                                        name="default-switch-6"
                                                        role="switch"
                                                        aria-checked={touch}
                                                        value="Y"
                                                        checked={touch}
                                                        onChange={() => onTouchLock()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {_Device.getSupported("USBLock", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("schedule.commend.usb_lock_unlock")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <div className="switch">
                                                    <label htmlFor="usbLock" className="switch-controller">
                                                        {t("schedule.commend.usb_lock_unlock")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="usbLock"
                                                        name="default-switch-6"
                                                        role="switch"
                                                        aria-checked={usbLock}
                                                        value="Y"
                                                        checked={usbLock}
                                                        onChange={() => onUsbLock()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {(_Device.getSupported("date", data) || _Device.getSupported("time", data) || _Device.getSupported("timeZone", data) || _Device.getSupported("daylightSavingTime", data)) && (
                            <div>
                                <div className="details-tit">{t("dv.time.settings")}</div>
                                {_Device.getSupported("date", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("table.head.date")}</span>
                                        <span className="field-content">
                                            {/* {data?.filter((f) => f["date"])[0]?.["date"]?.data?.year}-{data?.filter((f) => f["date"])[0]?.["date"]?.data?.month}-
                                        {data?.filter((f) => f["date"])[0]?.["date"]?.data?.day} */}
                                            {data?.filter((f) => f["date"])[0]?.["date"]?.data?.value}
                                        </span>
                                    </div>
                                )}
                                {_Device.getSupported("time", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("common.time")}</span>
                                        <span className="field-content">
                                            {/* {data?.filter((f) => f["time"])[0]?.["time"]?.data?.hour}:{data?.filter((f) => f["time"])[0]?.["time"]?.data?.minute}:
                                        {data?.filter((f) => f["time"])[0]?.["time"]?.data?.second} */}
                                            {data?.filter((f) => f["time"])[0]?.["time"]?.data?.value}
                                        </span>
                                    </div>
                                )}
                                {_Device.getSupported("timeZone", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("settings.label.timezone")}</span>
                                        <span className="field-content">
                                            {data?.filter((f) => f["timeZone"])[0]?.["timeZone"]?.data?.timezone
                                                ? `(${data?.filter((f) => f["timeZone"])[0]?.["timeZone"]?.data?.timezone}) ${data?.filter((f) => f["timeZone"])[0]?.["timeZone"]?.data?.timezone_name}`
                                                : "-"}
                                        </span>
                                    </div>
                                )}
                                {_Device.getSupported("daylightSavingTime", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("device.label.summertime")}</span>
                                        <div className="field-form field-form-type">
                                            <div className="field-box">
                                                <div className="switch">
                                                    <label htmlFor="default-switch-7" className="switch-controller">
                                                        {t("device.label.summertime")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="default-switch-7"
                                                        name="default-switch-7"
                                                        role="switch"
                                                        aria-checked="true"
                                                        value="Y"
                                                        checked={daylight}
                                                        onChange={() => onDaylightSavingTime()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                            {daylight && (
                                                <div className="data-list-container">
                                                    <div className="data-list-top">
                                                        <div className="right-side">
                                                            <button type="button" className="btn" onClick={() => setDaylightModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                                {t("common.edit")}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="table show-always no-scroll">
                                                        <div className="table-wrapper no-scroll">
                                                            <div className="table-box">
                                                                <table>
                                                                    <caption>
                                                                        {t("logreport.common.starttime")}, {t("logreport.common.endtime")}
                                                                    </caption>
                                                                    <thead>
                                                                        <tr>
                                                                            <td scope="col" width="16.4%">
                                                                                <div className="text-block"></div>
                                                                            </td>
                                                                            <th scope="col">
                                                                                <div className="text-block">{t("logreport.common.starttime")}</div>
                                                                            </th>
                                                                            <th scope="col">
                                                                                <div className="text-block">{t("logreport.common.endtime")}</div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row" width="16.4%">
                                                                                {t("schedule.calendar.month")}
                                                                            </th>
                                                                            <td>
                                                                                {monthList?.filter(
                                                                                    (m) => m.value == data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.start?.month
                                                                                )[0]?.label || "-"}
                                                                            </td>
                                                                            <td>
                                                                                {monthList?.filter(
                                                                                    (m) => m.value == data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.end?.month
                                                                                )[0]?.label || "-"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row" width="16.4%">
                                                                                {t("schedule.calendar.week")}
                                                                            </th>
                                                                            <td>
                                                                                {weekList?.filter(
                                                                                    (m) => m.value == data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.start?.week
                                                                                )[0]?.label || "-"}
                                                                            </td>
                                                                            <td>
                                                                                {weekList?.filter(
                                                                                    (m) => m.value == data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.end?.week
                                                                                )[0]?.label || "-"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row" width="16.4%">
                                                                                {t("device.label.dayofweek")}
                                                                            </th>
                                                                            <td>
                                                                                {dayOfTheWeekList?.filter(
                                                                                    (m) => m.value == data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.start?.dayOfTheWeek
                                                                                )[0]?.label || "-"}
                                                                            </td>
                                                                            <td>
                                                                                {dayOfTheWeekList?.filter(
                                                                                    (m) => m.value == data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.end?.dayOfTheWeek
                                                                                )[0]?.label || "-"}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row" width="16.4%">
                                                                                {t("distribution.label.hour")}
                                                                            </th>
                                                                            <td>{data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.start?.hour}</td>
                                                                            <td>{data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data?.end?.hour}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {(_Device.getSupported("allowInstallUnkownApp", data) ||
                            _Device.getSupported("language", data) ||
                            _Device.getSupported("screenshare", data) ||
                            _Device.getSupported("__rs232cCommand", data)) && (
                            <div>
                                <div className="details-tit">{t("common.label.Others")}</div>

                                {_Device.getSupported("allowInstallUnkownApp", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("common.label.AllowInstallUnkownApp")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <div className="switch">
                                                    <label htmlFor="allowInstallUnkownApp" className="switch-controller">
                                                        {t("common.label.AllowInstallUnkownApp")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="allowInstallUnkownApp"
                                                        name="default-switch-6"
                                                        role="switch"
                                                        aria-checked={allowInstallUnkownApp}
                                                        value="Y"
                                                        checked={allowInstallUnkownApp}
                                                        onChange={() => onAllowInstallUnkownApp()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {_Device.getSupported("language", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="languageLabel">
                                            {t("user.label.language")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="scheduleHour"
                                                    items={_Device.getDropDownItems(t, null, "language", data, null, deviceInfo)}
                                                    value={_Device.getHexRangeValue(t, "language", data, null, null, null, deviceInfo)}
                                                    ariaLabelId={"languageLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "language", data, null, null, null, deviceInfo)}
                                                    onChange={(item) => changeData("language", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("screenshare", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("device.label.screenshare")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <button type="button" className="btn" onClick={onScreenShare} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                    {t("common.disable")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("__rs232cCommand", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("dv.support.rs232.menu")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <button type="button" className="btn" onClick={() => setRs232cModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                    {t("dv.support.response")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {lockModal && <LockModal title={t("schedule.commend.screen_lock_unlock")} onOff={"True"} onScreenLock={onScreenLock} onClose={() => setLockModal(false)} />}
            {/* {unlockModal && <LockModal title={t("form.label.screenunlock")} onOff={"False"} onScreenLock={onScreenLock} onClose={() => setUnlockModal(false)} />} */}
            {daylightModal && (
                <DaylightModal
                    monthList={monthList}
                    weekList={weekList}
                    dayOfTheWeekList={dayOfTheWeekList}
                    data={data?.filter((f) => f?.daylightSavingTime)[0]?.daylightSavingTime?.data}
                    onClose={() => {
                        setDaylightModal(false);
                        // setDaylight(false);
                    }}
                    onClick={(data) => {
                        setDaylightModal(false);
                        saveDaylight(data);
                    }}
                />
            )}
            {rs232cModal && <Rs232cModal idx={idx} uniControlDevice={uniControlDevice} onClose={() => setRs232cModal(false)} />}
        </Fragment>
    );
};

const LockModal = (props) => {
    const { t } = useTranslation();

    const onClickDone = () => {
        props.onScreenLock && props.onScreenLock(passwd, props.onOff);
    };

    const [usePrev, setUsePrev] = useState(false);
    const [passwd, setPasswd] = useState("");

    const onUsePrev = () => {
        setUsePrev(!usePrev);
        !usePrev && setPasswd("");
    };

    const changePasswd = (value) => {
        let val = _U.getOnlyNumber(value);
        if (val?.length > 4) {
            val = val.substring(0, 4);
        }
        setPasswd(val);
    };

    return (
        <Modal
            title={props.title}
            id="popup-select-file"
            className="popup-screen-lock pop-middle no-scroll"
            onClose={props.onClose}
            buttonOkDisabled={usePrev !== true && passwd === ""}
            onClick={onClickDone}
        >
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <p>
                {t("subtext.deivce.detail.lock1")}
                <br />
                {t("subtext.deivce.detail.lock2")}
            </p>
            <div className="field popup-create-role required">
                <div className="field-header">
                    <label htmlFor="ip-message-type" className="field-title">
                        {t("common.passwd")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="input-box">
                        <input
                            type="text"
                            id="ip-message-type"
                            name=""
                            placeholder="Password"
                            autoComplete="false"
                            aria-required="true"
                            value={passwd}
                            disabled={usePrev}
                            onChange={(e) => changePasswd(e.target.value)}
                        />
                        <button type="button" className={cx({ "btn-input-remove": true, hide: passwd ? false : true })} onClick={() => setPasswd("")}>
                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="field field-type-checkbox">
                <div className="field-form">
                    <div className="field-box">
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-power-option" id="checkbox-password" checked={usePrev} onChange={() => onUsePrev()} />
                            <label htmlFor="checkbox-password">{t("schedule.code.usepreviouslysetpassword")}</label>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const DaylightModal = (props) => {
    const { t } = useTranslation();

    const { monthList, weekList, dayOfTheWeekList, data: dataProps } = props;
    const [data, setData] = useState({});
    const onClickDone = () => {
        props.onClick(data);
    };

    const onChange = (stEnd, key, value) => {
        const ch = { ...data[stEnd], [key]: value };
        setData({ ...data, [stEnd]: ch });
    };

    useEffect(() => {
        const tmp = JSON.parse(JSON.stringify(dataProps));
        if (!tmp?.start) {
            tmp["start"] = {};
            tmp["start"]["month"] = "1";
            tmp["start"]["week"] = "01";
            tmp["start"]["dayOfTheWeek"] = "00";
            tmp["start"]["hour"] = 0;
        }
        if (!tmp?.end) {
            tmp["end"] = {};
            tmp["end"]["month"] = "1";
            tmp["end"]["week"] = "01";
            tmp["end"]["dayOfTheWeek"] = "00";
            tmp["end"]["hour"] = 0;
        }
        setData(tmp);
    }, [dataProps]);

    return (
        <Modal title={t("dv.edit.dayligt")} id="popup-select-file" className="popup-screen-lock pop-middle no-scroll" onClose={props.onClose} onClick={onClickDone}>
            <div className="data-list-container">
                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper no-scroll">
                            <div className="table-box">
                                <table>
                                    <caption>{t("subtext.device.daylightsaving.table")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" width="auto">
                                                <div className="text-block"></div>
                                            </th>
                                            <th scope="col" width="auto">
                                                <div className="text-block">{t("logreport.common.starttime")}</div>
                                            </th>
                                            <th scope="col" width="auto">
                                                <div className="text-block">{t("logreport.common.endtime")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th className="align-left">{t("schedule.calendar.month")}</th>
                                            <td>
                                                <SelectBoxBasic
                                                    id={"startMonth"}
                                                    items={monthList}
                                                    value={data?.start?.month}
                                                    onChange={(e) => onChange("start", "month", e.target.value)}
                                                    ariaLabel={`${t("device.label.start")} ${t("schedule.calendar.month")}`}
                                                    empty={t("table.head.choicerow")}
                                                />
                                            </td>
                                            <td>
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"endMonth"}
                                                        items={monthList}
                                                        value={data?.end?.month}
                                                        onChange={(e) => onChange("end", "month", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("schedule.calendar.month")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="align-left">{t("schedule.calendar.week")}</th>
                                            <td>
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"startWeek"}
                                                        items={weekList}
                                                        value={data?.start?.week}
                                                        onChange={(e) => onChange("start", "week", e.target.value)}
                                                        ariaLabel={`${t("device.label.start")} ${t("schedule.calendar.week")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"endWeek"}
                                                        items={weekList}
                                                        value={data?.end?.week}
                                                        onChange={(e) => onChange("end", "week", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("schedule.calendar.week")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="align-left">{t("device.label.dayofweek")}</th>
                                            <td>
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"startDayOfWeek"}
                                                        items={dayOfTheWeekList}
                                                        value={data?.start?.dayOfTheWeek}
                                                        onChange={(e) => onChange("start", "dayOfTheWeek", e.target.value)}
                                                        ariaLabel={`${t("device.label.start")} ${t("device.label.dayofweek")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"endDayOfWeek"}
                                                        items={dayOfTheWeekList}
                                                        value={data?.end?.dayOfTheWeek}
                                                        onChange={(e) => onChange("end", "dayOfTheWeek", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("device.label.dayofweek")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="align-left">{t("distribution.label.hour")}</th>
                                            <td>
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"startHour"}
                                                        items={[...Array(24)]?.map((item, index) => ({ label: index, value: index }))}
                                                        value={data?.start?.hour}
                                                        onChange={(e) => onChange("start", "hour", e.target.value)}
                                                        ariaLabel={`${t("device.label.start")} ${t("distribution.label.hour")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"endHour"}
                                                        items={[...Array(24)]?.map((item, index) => ({ label: index, value: index }))}
                                                        value={data?.end?.hour}
                                                        onChange={(e) => onChange("end", "hour", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("distribution.label.hour")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <ul className="table-body">
                            <li className="table-row">
                                <div className="summary-data">
                                    <div className="row">
                                        <div className="table-cell month">
                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                {t("schedule.calendar.month")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-data-box">
                                    <ul className="all-data">
                                        <li>
                                            <span className="field-label">{t("common.label.startdate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-startMonth"}
                                                        items={monthList}
                                                        value={data?.start?.month}
                                                        onChange={(e) => onChange("start", "month", e.target.value)}
                                                        ariaLabel={`${t("device.label.start")} ${t("schedule.calendar.month")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>

                                        <li>
                                            <span className="field-label">{t("common.label.enddate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-endMonth"}
                                                        items={monthList}
                                                        value={data?.end?.month}
                                                        onChange={(e) => onChange("end", "month", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("schedule.calendar.month")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="table-row">
                                <div className="summary-data">
                                    <div className="row">
                                        <div className="table-cell month">
                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                {t("schedule.calendar.week")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-data-box">
                                    <ul className="all-data">
                                        <li>
                                            <span className="field-label">{t("common.label.startdate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-startWeek"}
                                                        items={weekList}
                                                        value={data?.start?.week}
                                                        onChange={(e) => onChange("start", "week", e.target.value)}
                                                        ariaLabel={`${t("device.label.start")} ${t("schedule.calendar.week")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>

                                        <li>
                                            <span className="field-label">{t("common.label.enddate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-endWeek"}
                                                        items={weekList}
                                                        value={data?.end?.week}
                                                        onChange={(e) => onChange("end", "week", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("schedule.calendar.week")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="table-row">
                                <div className="summary-data">
                                    <div className="row">
                                        <div className="table-cell day-of-the-week">
                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                {t("device.label.dayofweek")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-data-box">
                                    <ul className="all-data">
                                        <li>
                                            <span className="field-label">{t("common.label.startdate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-startDayOfWeek"}
                                                        items={dayOfTheWeekList}
                                                        value={data?.start?.dayOfTheWeek}
                                                        onChange={(e) => onChange("start", "dayOfTheWeek", e.target.value)}
                                                        ariaLabel={`${t("device.label.start")} ${t("device.label.dayofweek")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>

                                        <li>
                                            <span className="field-label">{t("common.label.enddate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-endDayOfWeek"}
                                                        items={dayOfTheWeekList}
                                                        value={data?.end?.dayOfTheWeek}
                                                        onChange={(e) => onChange("end", "dayOfTheWeek", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("device.label.dayofweek")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="table-row">
                                <div className="summary-data">
                                    <div className="row">
                                        <div className="table-cell hour">
                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                {t("distribution.label.hour")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-data-box">
                                    <ul className="all-data">
                                        <li>
                                            <span className="field-label">{t("common.label.startdate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-startHour"}
                                                        items={[...Array(24)]?.map((item, index) => ({ label: index, value: index }))}
                                                        value={data?.start?.hour}
                                                        onChange={(e) => onChange("start", "hour", e.target.value)}
                                                        ariaLabel={`${t("device.label.start")} ${t("distribution.label.hour")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>

                                        <li>
                                            <span className="field-label">{t("common.label.enddate")}</span>
                                            <span className="field-content">
                                                <div className="select-box">
                                                    <SelectBoxBasic
                                                        id={"convert-endHour"}
                                                        items={[...Array(24)]?.map((item, index) => ({ label: index, value: index }))}
                                                        value={data?.end?.hour}
                                                        onChange={(e) => onChange("end", "hour", e.target.value)}
                                                        ariaLabel={`${t("page.button.end")} ${t("distribution.label.hour")}`}
                                                        empty={t("table.head.choicerow")}
                                                    />
                                                </div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const Rs232cModal = (props) => {
    const { idx, uniControlDevice } = props;
    const { t } = useTranslation();
    const { saveInnerLoading } = useContext(AppContext);
    const [command, setCommand] = useState("");
    const [resData, setResData] = useState("");
    const onClickDone = () => {
        props.onClose();
    };

    const onSend = () => {
        if (command) {
            onRs232Send();
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: "Please enter a command.",
                button: [t("common.ok")],
            }).then((id) => {});
        }
    };

    const onRs232Send = async () => {
        let res = null;
        res = await _API.onDeviceControl2(null, null, saveInnerLoading, t, {
            path: `/device/devices/rs232`,
            data: {
                device_id: idx,
                command: command,
            },
        });
        if (res && res.status === "true") {
            setResData(t("common.ok"));
        }
    };

    return (
        <Modal title={t("dv.support.response")} id="popup-select-file" className="popup-check-response popup-type no-scroll" onClose={props.onClose} close noFooter>
            <div className="list-box">
                <div className="field field-type-textarea">
                    <div className="flex-between">
                        <label htmlFor="command-textarea" className="field-label">
                            {t("dv.support.rs232")}
                        </label>
                        <button type="button" className="btn btn-send" onClick={onSend}>
                            {t("common.label.send")}
                        </button>
                    </div>
                    <div className="field-form">
                        <div className="field-box">
                            <textarea
                                name="command-textarea"
                                id="command-textarea"
                                placeholder={t("dv.support.rs232")}
                                aria-describedby="description-name"
                                value={command}
                                onChange={(e) => setCommand(e.target.value)}
                            ></textarea>
                        </div>
                        {/* <!-- <span id="description-name" className="field-description">RS232C Control Command</span> --> */}
                    </div>
                </div>

                <div className="field field-type-textarea">
                    <div className="flex-between">
                        <label htmlFor="command-textarea2" className="field-label">
                            {t("device.label.response")}
                        </label>
                        {/* <!-- <button type="button" className="btn btn-small btn-send">Send</button> --> */}
                    </div>
                    <div className="field-form">
                        <div className="field-box">
                            <textarea
                                name="command-textarea2"
                                id="command-textarea2"
                                placeholder={t("dv.support.rs232.res")}
                                // aria-describedby="description-name"
                                value={resData}
                            ></textarea>
                        </div>
                        {/* <!-- <span id="description-name" className="field-description">Waiting for the device to respond.</span> --> */}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const ControlUpdate = (props) => {
    const { t } = useTranslation();
    const { idx, deviceInfo, uniControlDevice, lastUpdateTime } = props;

    const { innerLoading, saveInnerLoading, rolePermission, auth, workspace } = useContext(AppContext);
    const [updateIng, setUpdateIng] = useState(false);
    const [ledUpdateTypeList, setLedUpdateTypeList] = useState([]);
    const [updateType, setUpdateType] = useState(null);
    const [updateTypeServer, setUpdateTypeServer] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [selectFile, setSelectFile] = useState({});
    const [cabinetId, setCabinetId] = useState(null);
    const fRef = useRef(null);
    const [file, setFile] = useState(null);
    const [historyList, setHistoryList] = useState([]);

    const onLoadFileList = () => {
        _API.get({
            path: `/device/devices/files/firmware/${deviceInfo?.model_name}`,
            data: {},
        })
            .then((res) => {
                const result = res?.data?.result?.Contents;
                if (result?.length > 0) {
                    setFileList(result.map((m) => ({ ...m, label: decodeURIComponent(m.Key.substring(m.Key.lastIndexOf("/") + 1)), value: m.Key })));
                } else {
                    setFileList([]);
                }
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
                setFileList([]);
            });
    };

    const onLoadData = () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: ["__ledUpdateType1", "__ledUpdateType2", "__ledUpdateType3"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                if (_Device.getSupported("__ledUpdateType1", result)) {
                    const newList = [];
                    newList.push({ label: t("common.code.device.update.type.fw"), value: "fw", ext: ".epk, .zip", urlPath: "firmware" });
                    newList.push({ label: t("common.code.device.update.type.micom"), value: "micom", ext: ".lff", urlPath: "micom" });
                    newList.push({ label: t("common.code.device.update.type.fpga"), value: "fpga", ext: ".lff", urlPath: "fpga" });
                    newList.push({ label: t("common.code.device.update.type.ledfpga"), value: "ledfpga", ext: ".lff", urlPath: "ledfpga" });
                    setLedUpdateTypeList(newList);
                } else if (_Device.getSupported("__ledUpdateType2", result)) {
                    const newList = [];
                    newList.push({ label: t("common.code.device.update.type.fw"), value: "fw", ext: ".epk, .zip", urlPath: "firmware" });
                    newList.push({ label: t("common.code.device.update.type.fpga"), value: "fpga", ext: ".lff", urlPath: "fpga" });
                    newList.push({ label: t("common.code.device.update.type.rcv"), value: "rcv", ext: ".hex", urlPath: "rcv" });
                    newList.push({ label: t("common.code.device.update.type.cali"), value: "cali", ext: ".cali", urlPath: "calibration" });
                    newList.push({ label: t("common.code.device.update.type.settings"), value: "settings", ext: ".cfg", urlPath: "settings" });
                    setLedUpdateTypeList(newList);
                } else if (_Device.getSupported("__ledUpdateType3", result)) {
                    const newList = [];
                    newList.push({ label: t("common.code.device.update.type.fw"), value: "fw", ext: ".epk, .zip", urlPath: "firmware" });
                    newList.push({ label: t("common.code.device.update.type.micom"), value: "micom", ext: ".lff", urlPath: "micom" });
                    setLedUpdateTypeList(newList);
                }
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    const fileSelected = (e) => {
        if (e?.target?.files?.length > 0) {
            const checkFile = e?.target?.files[0];
            const acceptFileFormat = updateType ? updateType?.ext?.split(",")?.map((m) => m?.replace(/[ .]/g, "")?.toUpperCase()) : ["EPK", "ZIP", "epk"];
            const fileName = checkFile?.name;
            const ext = fileName?.split(".")?.pop()?.toUpperCase();
            if (!acceptFileFormat.includes(ext)) {
                //
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("content.msg.fileformat"),
                    button: t("common.ok"),
                }).then((id) => {
                    if (id) {
                    }
                });
            } else {
                const check = _U.checkFileName(e?.target?.files?.[0]?.name);
                if (check === -2) {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.over100.filename"),
                        button: t("common.ok"),
                    }).then((id) => {});
                } else if (check === -1) {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.message.NoSpecialCharacters"),
                        button: t("common.ok"),
                    }).then((id) => {});
                } else {
                    setFile(e?.target?.files?.[0]);
                }
            }
        }
    };

    const upload = () => {
        if (file) {
            let info = { object_key: null, upload_url: null };
            const fileName = file.name;
            saveInnerLoading(true);
            _API.post({ path: `/device/devices/files/${updateType ? updateType.urlPath : "firmware"}/${deviceInfo?.model_name}/${fileName}`, data: {} })
                .then((res) => {
                    info.object_key = res?.data?.result?.object_key;
                    info.upload_url = res?.data?.result?.upload_url;

                    _API.uploadS3File({ url: info.upload_url, file })
                        .then((rs) => {
                            onLoadFileList();
                            saveInnerLoading(false);
                            Dialog({
                                title: t("popup.title.inform"),
                                text: t("dv.update.serverSucess"),
                                button: t("common.ok"),
                            }).then((id) => {});
                        })
                        .catch((e) => {
                            console.log("file upload fail", e?.response);
                            saveInnerLoading(false);
                        });
                })
                .catch((e) => {
                    console.log("api error /device/devices/aufirmware", e);
                    saveInnerLoading(false);
                });
        }
    };

    const onUpdate = async () => {
        if (selectFile) {
            saveInnerLoading(true);
            const valueParam = {
                type: updateTypeServer?.value || "fw",
            };
            const res = await _API.post({ path: `/device/devices/files/${updateType ? updateType.urlPath : "firmware"}/${deviceInfo?.model_name}`, data: { objectKey: selectFile?.value } });
            const source = `${res?.data?.result?.url}`;
            if (source) {
                valueParam["download_url"] = source;
            }
            if (cabinetId) {
                valueParam["cabinet_id"] = cabinetId;
            }
            if (deviceInfo?.device_type && deviceInfo?.device_type.toLowerCase() === "led") {
                valueParam["model_name"] = deviceInfo.model_name.split("-")[0] || "";
            }
            const params = [{ firmwareUpdate: { data: { value: JSON.stringify(valueParam) } } }];
            let controlRes = null;
            if (uniControlDevice) {
                controlRes = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t, null, true);
            } else {
                controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t, null, true);
            }
            if (controlRes && controlRes.status === "true") {
                onFileUploadSuccess();
            }
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("validation.select.file"),
                button: t("common.ok"),
            }).then((id) => {});
        }
    };

    const onFileUploadSuccess = (e) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("dv.failover.upload"),
            button: t("common.ok"),
        }).then((id) => {});
    };

    const onLoadHistory = () => {
        _API.get({ path: `/device/devices/update/history`, data: { device_id: idx } })
            .then((res) => {
                console.log(res);
                setHistoryList(res?.data?.result);
                resizeTable();
            })
            .catch((e) => {
                console.log("api error /devdevice/devices/update/history", e);
            });
    };

    const delteFile = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("dv.failover.delete"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                saveInnerLoading(true);
                const params = { key: item?.value };
                _API.delete({ path: `/device/devices/files`, data: params })
                    .then((res) => {
                        onLoadFileList();
                        saveInnerLoading(false);
                    })
                    .catch((e) => {
                        console.log("api error /device/devices/control", e?.response);
                        saveInnerLoading(false);
                    });
            }
        });
    };

    useEffect(() => {
        onLoadData();
        onLoadFileList();
        onLoadHistory();
    }, [lastUpdateTime]);

    useEffect(() => {
        if (file) {
            upload();
        }
    }, [file]);

    return (
        <Fragment>
            <div className="tab-panel panel-update" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        {/* <div className="field">
                            <label className="field-label">{t("dv.update.type")}</label>
                            <div className="field-form">
                                <div className="field-box">
                                    <div className="radio">
                                        <input type="radio" name="rad-tem-type01" id="rad-tem-type01-1" />
                                        <label htmlFor="rad-tem-type01-1">{t("dv.update.server")}</label>
                                    </div>
                                    <div className="radio">
                                        <input type="radio" name="rad-tem-type01" id="rad-tem-type01-2" />
                                        <label htmlFor="rad-tem-type01-2">{t("dv.update.local")}</label>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div>
                            <div className="details-tit">{t("dv.update.cloud")}</div>
                            <div className="field field-flex">
                                <span className="field-label" id="fwFileSelectionLabel">
                                    {t("dv.update.file")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        {ledUpdateTypeList?.length > 0 && (
                                            <SelectBox
                                                id="fwFileSelection"
                                                ariaLabelId={"fwFileSelectionLabel"}
                                                items={ledUpdateTypeList}
                                                value={updateType?.value || ""}
                                                selectLabel={updateType?.label || "ㅤ"}
                                                onChange={(item) => setUpdateType(item)}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                        )}
                                        <input type="file" id="ip-file-upload" accept={updateType ? updateType.ext : ".epk, .zip"} ref={fRef} onChange={fileSelected} className="display-none-style" />
                                        <button type="button" className="btn" onClick={() => fRef.current.click()} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                            {t("device.label.uploadtocloud")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="details-tit">{t("device.button.update")}</div>
                            <div className="field field-flex">
                                <span className="field-label" id="fwFileSelectionFromServerLabel">
                                    {t("dv.update.file.fromserver")}
                                </span>
                                <div className="field-form field-form-type">
                                    <div className="field-box">
                                        {ledUpdateTypeList?.length > 0 && (
                                            <SelectBox
                                                id="fwFileSelectionFromServer"
                                                ariaLabelId={"fwFileSelectionFromServerLabel"}
                                                items={ledUpdateTypeList}
                                                value={updateTypeServer?.value || ""}
                                                selectLabel={updateTypeServer?.label || "ㅤ"}
                                                onChange={(item) => setUpdateTypeServer(item)}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                        )}
                                        <SelectBox
                                            id="selectFwFile"
                                            items={fileList}
                                            value={selectFile?.value || ""}
                                            ariaLabelId={"fwFileSelectionFromServerLabel"}
                                            selectLabel={selectFile?.label ? selectFile?.label : fileList?.length > 0 ? t("dv.select.firmwarelist") : t("device.label.nofwfiles")}
                                            onChange={(item) => setSelectFile(item)}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                            noItemText={t("device.label.nofwfiles")}
                                            deleteBtn={true}
                                            onDelete={(item) => delteFile(item)}
                                        />

                                        {updateTypeServer?.value === "cali" && (
                                            <input
                                                type="text"
                                                className="input-txt"
                                                placeholder={t("subtext.AssignedCabinetID")}
                                                value={cabinetId || ""}
                                                onChange={(e) => setCabinetId(e.target.value)}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                        )}
                                        <button type="button" className="btn" onClick={onUpdate} disabled={!selectFile?.value || _U.getAuthWriteDisable(rolePermission)}>
                                            {t("device.button.update")}
                                        </button>

                                        <p className="txt-ing" role="status">
                                            {innerLoading && t("dv.update.preparing")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="details-tit">{t("dv.update.history")}</div>
                            <div className="data-list-container">
                                <div className="table">
                                    <div className="table-wrapper no-scroll">
                                        <div className="table-box">
                                            <table>
                                                <caption>{t("dv.update.history")}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <div className="text-block">{t("dv.update.type")}</div>
                                                        </th>
                                                        {ledUpdateTypeList?.length > 0 && (
                                                            <th scope="col">
                                                                <div className="text-block">{t("common.label.FWFileType")}</div>
                                                            </th>
                                                        )}
                                                        <th scope="col">
                                                            <div className="text-block">{t("dv.update.select")}</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div className="text-block">{t("dv.control.requested")}</div>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {historyList?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item?.update_type || "-"}</td>
                                                            {ledUpdateTypeList?.length > 0 && <td>{item?.file_type || "-"}</td>}
                                                            <td>{item?.file_name || "-"}</td>
                                                            <td>{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : "-"}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="convert-table-to-list">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="thead">{t("dv.update.type")}</div>
                                            </div>
                                        </div>

                                        <ul className="table-body">
                                            {historyList?.map((item, index) => (
                                                <li className="table-row" key={`convert-${index}`}>
                                                    <div className="summary-data">
                                                        <div className="row">
                                                            <div className="table-cell issue-level" aria-label="issue level">
                                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                                    {item?.update_type || "-"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="all-data-box">
                                                        <ul className="all-data">
                                                            <li>
                                                                <span className="field-label">{t("dv.update.type")}</span>
                                                                <span className="field-content">{item?.update_type || "-"}</span>
                                                            </li>
                                                            {ledUpdateTypeList?.length > 0 && (
                                                                <li>
                                                                    <span className="field-label">{t("common.label.FWFileType")}</span>
                                                                    <span className="field-content">{item?.file_type || "-"}</span>
                                                                </li>
                                                            )}
                                                            <li>
                                                                <span className="field-label">{t("dv.update.select")}</span>
                                                                <span className="field-content">{item?.file_name || "-"}</span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("dv.control.requested")}</span>
                                                                <span className="field-content">{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : "-"}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export const ControlDisplay = (props) => {
    const { t } = useTranslation();
    const { idx, deviceInfo, uniControlDevice } = props;
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);
    const [editModal, setEditModal] = useState(null);
    const [fileModal, setFileModal] = useState("");
    const [pictureModal, setPictureModal] = useState(false);
    const [data, setData] = useState([]);
    const [inputAliasList, setInputAliasList] = useState([]);
    const [colorValues, setColorValues] = useState({});

    const onLoadData = () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: [
                    "input",
                    "switchInputAlias",
                    "aspectRatio",
                    "pictureMode",
                    "brightnessControl",
                    "colorTemp",
                    "colorTempEx",
                    "redGain",
                    "greenGain",
                    "blueGain",
                    "redOffset",
                    "greenOffset",
                    "blueOffset",
                    "__pictureDetailSetings",
                    "screenRotation",
                    "externalRotation",
                    "changeLogo",
                    "changeWallpaper",
                ],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                if (result?.filter((f) => f["switchInputAlias"])[0]?.["switchInputAlias"]?.data?.input_alias) {
                    setInputAliasList(result?.filter((f) => f["switchInputAlias"])[0]?.["switchInputAlias"]?.data?.input_alias);
                }
                setColorValues({
                    redGain: result?.filter((f) => f["redGain"])[0]?.["redGain"]?.data?.value || 0,
                    greenGain: result?.filter((f) => f["greenGain"])[0]?.["greenGain"]?.data?.value || 0,
                    blueGain: result?.filter((f) => f["blueGain"])[0]?.["blueGain"]?.data?.value || 0,
                    redOffset: result?.filter((f) => f["redOffset"])[0]?.["redOffset"]?.data?.value || 0,
                    greenOffset: result?.filter((f) => f["greenOffset"])[0]?.["greenOffset"]?.data?.value || 0,
                    blueOffset: result?.filter((f) => f["blueOffset"])[0]?.["blueOffset"]?.data?.value || 0,
                });
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    const changeData = (key, value) => {
        setDeviceControl(key, value);
    };

    const setDeviceControl = async (key, value) => {
        const params = [{ [key]: { data: { value: value } } }];
        let controlRes = null;
        if (uniControlDevice) {
            controlRes = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (controlRes && controlRes.status === "true") {
            const newData = JSON.parse(JSON.stringify(data));
            newData?.forEach((f) => {
                if (f[key]) {
                    f[key]["data"]["value"] = value;
                }
            });
            setData(newData);
        }
    };

    const changeInputAlias = async (key, alias) => {
        const value = [];
        const newList = JSON.parse(JSON.stringify(inputAliasList));
        newList?.forEach((f) => {
            Object.entries(f).forEach(([skey, svalue]) => {
                if (key === skey) {
                    value.push({ [skey]: alias });
                    f[skey] = alias;
                } else {
                    value.push({ [skey]: svalue });
                }
            });
        });

        setEditModal(null);
        const params = [{ switchInputAlias: { data: { value: value } } }];
        let controlRes = null;
        if (uniControlDevice) {
            controlRes = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (controlRes && controlRes.status === "true") {
            setInputAliasList(newList);
        }
    };

    const getBackground = (key, max) => {
        if (!max) {
            max = 254;
        }
        let value = colorValues[key] || 0;
        value = Math.floor((value / max) * 100);

        return {
            background: "linear-gradient(to right, #a50034 0%, #a50034 " + (value || 0) + "%, #f1f1f1 " + (value || 0) + "%, #f1f1f1 100%)",
        };
    };

    const onColorChange = (e, key) => {
        const max = parseInt(e.target.max || 254);
        const value = e.target.value ? parseInt(e.target.value) : 0;
        setColorValues({ ...colorValues, [key]: value >= max ? max : value });
    };

    const onPlus = (key, max) => {
        if (!max) {
            max = 254;
        }
        setColorValues({ ...colorValues, [key]: parseInt(colorValues[key]) >= max ? max : parseInt(colorValues[key]) + 1 });
    };

    const onMinus = (key) => {
        setColorValues({ ...colorValues, [key]: parseInt(colorValues[key]) > 0 ? parseInt(colorValues[key]) - 1 : 0 });
    };

    const onColorApply = async (key) => {
        const params = [{ [key]: { data: { value: colorValues[key] || 0 } } }];
        let controlRes = null;
        if (uniControlDevice) {
            controlRes = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (!controlRes || controlRes.status !== "true") {
            setColorValues({
                redGain: data?.filter((f) => f["redGain"])[0]?.["redGain"]?.data?.value || 0,
                greenGain: data?.filter((f) => f["greenGain"])[0]?.["greenGain"]?.data?.value || 0,
                blueGain: data?.filter((f) => f["blueGain"])[0]?.["blueGain"]?.data?.value || 0,
                redOffset: data?.filter((f) => f["redOffset"])[0]?.["redOffset"]?.data?.value || 0,
                greenOffset: data?.filter((f) => f["greenOffset"])[0]?.["greenOffset"]?.data?.value || 0,
                blueOffset: data?.filter((f) => f["blueOffset"])[0]?.["blueOffset"]?.data?.value || 0,
            });
        }
    };

    const getColorTemperature = () => {
        if (deviceInfo?.os_type === "ANDROID") {
            const val = parseInt(data?.filter((f) => f["colorTemp"])[0]?.["colorTemp"]?.data?.value || 0);
            if (val) {
                const val16 = val?.toString(16)?.toUpperCase();
                return t("wrapper.colorTempEx." + val16);
            }
            return "-";
        } else {
            const spec = data?.filter((f) => f["colorTemp"])[0]?.["colorTemp"]?.spec?.values[0]?.valdata;
            const val = parseInt(data?.filter((f) => f["colorTemp"])[0]?.["colorTemp"]?.data?.value || 0);
            if (spec?.length === 2 && [112, 210].indexOf(parseInt(spec[0], 16)) > -1) {
                return 3200 + (val - 112) * 100 + "K";
            } else if (spec?.length === 2 && [0, 100].indexOf(parseInt(spec[0], 16)) > -1) {
                if (val < 50) {
                    return t("player.label.colorTemp.warm") + val;
                } else if (val > 50) {
                    return t("player.label.colorTemp.cold") + val;
                } else {
                    return 0;
                }
            }
        }
    };

    useEffect(() => {
        onLoadData();
        resizeTable();
    }, [idx, lastUpdateTime]);

    return (
        <Fragment>
            <div className="tab-panel panel-display" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        <div>
                            {_Device.getSupported("input", data) && (
                                <div className="field field-flex">
                                    <span className="field-label" id="inputLabel">
                                        {t("player.label.input")}
                                    </span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <SelectBox
                                                id="input"
                                                items={_Device.getDropDownItems(t, null, "input", data, null, deviceInfo)}
                                                value={_Device.getHexRangeValue(t, "input", data, null, null, null, deviceInfo)}
                                                ariaLabelId={"inputLabel"}
                                                selectLabel={_Device.getHexRangeValue(t, "input", data, null, null, null, deviceInfo)}
                                                onChange={(item) => changeData("input", item?.value)}
                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {_Device.getSupported("switchInputAlias", data) && (
                                <div className="field field-flex">
                                    <span className="field-label">{t("common.label.InputAdvanced")}</span>
                                    <div className="field-form field-form-type">
                                        <div className="table">
                                            <div className="table-wrapper schedule-setting no-scroll">
                                                <div className="table-box">
                                                    <table>
                                                        <caption>{t("subtext.table.caption.InputAdvancedlist")}</caption>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("player.label.input")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("table.header.input.alias")}</div>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {inputAliasList.map((item, index) =>
                                                                Object.entries(item).map(([key, value]) => (
                                                                    <tr key={key}>
                                                                        {/* <td>{t("wrapper.input." + key.toUpperCase() + ".odm_cvte")}</td> */}
                                                                        <td id={`switchInputAlias-td1-${key}`}>{key}</td>
                                                                        <td className="flex-box">
                                                                            <div className="left-side" id={`switchInputAlias-div2-${key}`}>
                                                                                {value}
                                                                            </div>
                                                                            <div className="right-side">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-edit btn-small"
                                                                                    onClick={() => setEditModal({ key, value })}
                                                                                    aria-describedby={`switchInputAlias-td1-${key} switchInputAlias-div2-${key}`}
                                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                                >
                                                                                    {t("common.edit")}
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="convert-table-to-list">
                                                <div className="table-header">
                                                    <div className="row">
                                                        <div className="thead">{t("player.label.input")}</div>
                                                    </div>
                                                </div>

                                                <ul className="table-body">
                                                    {inputAliasList.map((item, index) =>
                                                        Object.entries(item).map(([key, value]) => (
                                                            <li className="table-row" key={key}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell issue-level" aria-label="issue level">
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                                {key}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li id={`convert-switchInputAlias-td1-${key}`}>
                                                                            <span className="field-label">{t("player.label.input")}</span>
                                                                            <span className="field-content">{key}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label" id={`convert-switchInputAlias-div2-${key}`}>
                                                                                {t("table.header.input.alias")}
                                                                            </span>
                                                                            <span className="field-content">
                                                                                {value}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-edit btn-small"
                                                                                    onClick={() => setEditModal({ key, value })}
                                                                                    aria-describedby={`convert-switchInputAlias-td1-${key} convert-switchInputAlias-div2-${key}`}
                                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                                >
                                                                                    {t("common.edit")}
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        ))
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* {_Device.getSupported("energySavingMode", data) && (
                                <div className="field field-flex">
                                    <span className="field-label" id="energySavingModeLabel">
                                        Energy Saving Mode
                                    </span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <SelectBox
                                                id="energySavingMode"
                                                items={_Device.getDropDownItems(t, null, "energySavingMode", data)}
                                                value={_Device.getHexRangeValue(t, "energySavingMode", data)}
                                                ariaLabelId={"energySavingModeLabel"}
                                                selectLabel={_Device.getHexRangeValue(t, "energySavingMode", data)}
                                                onChange={(item) => changeData("energySavingMode", item?.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )} */}
                            {_Device.getSupported("changeWallpaper", data) && (
                                <div className="field field-flex">
                                    <span className="field-label">{t("common.label.changewallpaper")}</span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <button type="button" className="btn" onClick={() => setFileModal("WALL_PAPER")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                {t("button.Change")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {_Device.getSupported("changeLogo", data) && (
                                <div className="field field-flex">
                                    <span className="field-label">{t("setting.label.logo")}</span>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <button type="button" className="btn" onClick={() => setFileModal("LOGO")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                {t("button.Change")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {(_Device.getSupported("aspectRatio", data) ||
                            _Device.getSupported("pictureMode", data) ||
                            _Device.getSupported("brightnessControl", data) ||
                            _Device.getSupported("colorTemp", data) ||
                            _Device.getSupported("redGain", data) ||
                            _Device.getSupported("greenGain", data) ||
                            _Device.getSupported("blueGain", data) ||
                            _Device.getSupported("redOffset", data) ||
                            _Device.getSupported("greenOffset", data) ||
                            _Device.getSupported("blueOffset", data) ||
                            _Device.getSupported("__pictureDetailSetings", data)) && (
                            <div>
                                <div className="details-tit">{t("player.label.picture")}</div>
                                {_Device.getSupported("aspectRatio", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="aspectRatioLabel">
                                            {t("player.label.screenRatio")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="aspectRatio"
                                                    items={_Device.getDropDownItems(t, null, "aspectRatio", data, null, deviceInfo)}
                                                    value={_Device.getHexRangeValue(t, "aspectRatio", data, null, null, null, deviceInfo)}
                                                    ariaLabelId={"aspectRatioLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "aspectRatio", data, null, null, null, deviceInfo)}
                                                    onChange={(item) => changeData("aspectRatio", item?.value)}
                                                    disabled={
                                                        _U.getAuthWriteDisable(rolePermission) ||
                                                        (deviceInfo?.os_type === "ANDROID" && data?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9")
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("pictureMode", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="pictureModeLabel">
                                            {t("player.label.pictureMode")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="pictureMode"
                                                    items={_Device.getDropDownItems(t, null, "pictureMode", data, null, deviceInfo)}
                                                    value={_Device.getHexRangeValue(t, "pictureMode", data, null, null, null, deviceInfo)}
                                                    ariaLabelId={"pictureModeLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "pictureMode", data, null, null, null, deviceInfo)}
                                                    onChange={(item) => changeData("pictureMode", item?.value)}
                                                    disabled={
                                                        _U.getAuthWriteDisable(rolePermission) ||
                                                        (deviceInfo?.os_type === "ANDROID" && data?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9")
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("brightnessControl", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="brightnessControlLabel">
                                            {t("device.label.brightnesscontrol")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="brightnessControl"
                                                    items={_Device.getDropDownItems(t, null, "brightnessControl", data)}
                                                    value={_Device.getHexRangeValue(t, "brightnessControl", data)}
                                                    ariaLabelId={"brightnessControlLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "brightnessControl", data)}
                                                    onChange={(item) => changeData("brightnessControl", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("colorTemp", data) &&
                                    (_Device.getSupported("redGain", data) ||
                                        _Device.getSupported("greenGain", data) ||
                                        _Device.getSupported("blueGain", data) ||
                                        _Device.getSupported("redOffset", data) ||
                                        _Device.getSupported("greenOffset", data) ||
                                        _Device.getSupported("blueOffset", data)) && (
                                        <div className="field field-flex">
                                            <span className="field-label">{t("player.label.colorTemp")}</span>
                                            <span className="field-content">{getColorTemperature()}</span>
                                        </div>
                                    )}
                                {_Device.getSupported("redGain", data) && (
                                    <div className="field field-flex value-field-box">
                                        <span className="field-label">{t("device.label.redgain")} (0~254)</span>
                                        <div className="field-content">
                                            <div className="field field-slide-bar field-set-value">
                                                <div className="field-form">
                                                    <div className="slide-bar-container">
                                                        <button type="button" className="btn btn-minus" onClick={() => onMinus("redGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.redgain")}
                                                        </button>
                                                        <input
                                                            type="range"
                                                            name="field-slide-red-gain"
                                                            aria-labelledby="field-slide-red-gain"
                                                            min="0"
                                                            max="254"
                                                            minLength="0"
                                                            maxLength="254"
                                                            value={colorValues?.redGain || 0}
                                                            onChange={(e) => onColorChange(e, "redGain")}
                                                            style={getBackground("redGain")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-plus" onClick={() => onPlus("redGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.redgain")}
                                                        </button>
                                                    </div>
                                                    <div className="field-value">
                                                        <input
                                                            type="text"
                                                            name="field-input-slide-red-gain"
                                                            id="field-input-slide-red-gain"
                                                            value={colorValues?.redGain || 0}
                                                            min="0"
                                                            max="254"
                                                            minLength="0"
                                                            maxLength="254"
                                                            onChange={(e) => onColorChange(e, "redGain")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("redGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("player.button.apply")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("greenGain", data) && (
                                    <div className="field field-flex value-field-box">
                                        <span className="field-label">{t("device.label.greengain")} (0~254)</span>
                                        <div className="field-content">
                                            <div className="field field-slide-bar field-set-value">
                                                <div className="field-form">
                                                    <div className="slide-bar-container">
                                                        <button type="button" className="btn btn-minus" onClick={() => onMinus("greenGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.greengain")}
                                                        </button>
                                                        <input
                                                            type="range"
                                                            name="field-slide-green-gain"
                                                            aria-labelledby="field-slide-green-gain"
                                                            min="0"
                                                            max="254"
                                                            minLength="0"
                                                            maxLength="254"
                                                            value={colorValues?.greenGain || 0}
                                                            onChange={(e) => onColorChange(e, "greenGain")}
                                                            style={getBackground("greenGain")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-plus" onClick={() => onPlus("greenGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.greengain")}
                                                        </button>
                                                    </div>
                                                    <div className="field-value">
                                                        <input
                                                            type="text"
                                                            name="field-input-slide-green-gain"
                                                            id="field-input-slide-green-gain"
                                                            min="0"
                                                            max="254"
                                                            minLength="0"
                                                            maxLength="254"
                                                            value={colorValues?.greenGain || 0}
                                                            onChange={(e) => onColorChange(e, "greenGain")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("greenGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("player.button.apply")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("blueGain", data) && (
                                    <div className="field field-flex value-field-box">
                                        <span className="field-label">{t("device.label.bluegain")} (0~254)</span>
                                        <div className="field-content">
                                            <div className="field field-slide-bar field-set-value">
                                                <div className="field-form">
                                                    <div className="slide-bar-container">
                                                        <button type="button" className="btn btn-minus" onClick={() => onMinus("blueGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.bluegain")}
                                                        </button>
                                                        <input
                                                            type="range"
                                                            name="field-slide-blue-gain"
                                                            aria-labelledby="field-slide-blue-gain"
                                                            min="0"
                                                            max="254"
                                                            minLength="0"
                                                            maxLength="254"
                                                            value={colorValues?.blueGain || 0}
                                                            onChange={(e) => onColorChange(e, "blueGain")}
                                                            style={getBackground("blueGain")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-plus" onClick={() => onPlus("blueGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.bluegain")}
                                                        </button>
                                                    </div>
                                                    <div className="field-value">
                                                        <input
                                                            type="text"
                                                            name="field-input-slide-blue-gain"
                                                            id="field-input-slide-blue-gain"
                                                            min="0"
                                                            max="254"
                                                            minLength="0"
                                                            maxLength="254"
                                                            value={colorValues?.blueGain || 0}
                                                            onChange={(e) => onColorChange(e, "blueGain")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("blueGain")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("player.button.apply")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("redOffset", data) && (
                                    <div className="field field-flex value-field-box">
                                        <span className="field-label">{t("device.label.redoffset")} (0~127)</span>
                                        <div className="field-content">
                                            <div className="field field-slide-bar field-set-value">
                                                <div className="field-form">
                                                    <div className="slide-bar-container">
                                                        <button type="button" className="btn btn-minus" onClick={() => onMinus("redOffset")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.redoffset")}
                                                        </button>
                                                        <input
                                                            type="range"
                                                            name="field-slide-red-offset"
                                                            aria-labelledby="field-slide-red-offset"
                                                            min="0"
                                                            max="127"
                                                            minLength="0"
                                                            maxLength="127"
                                                            value={colorValues?.redOffset || 0}
                                                            onChange={(e) => onColorChange(e, "redOffset")}
                                                            style={getBackground("redOffset", 127)}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-plus" onClick={() => onPlus("redOffset", 127)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.redoffset")}
                                                        </button>
                                                    </div>
                                                    <div className="field-value">
                                                        <input
                                                            type="text"
                                                            name="field-input-slide-red-offset"
                                                            id="field-input-slide-red-offset"
                                                            min="0"
                                                            max="127"
                                                            minLength="0"
                                                            maxLength="127"
                                                            value={colorValues?.redOffset || 0}
                                                            onChange={(e) => onColorChange(e, "redOffset")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("redOffset")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("player.button.apply")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("greenOffset", data) && (
                                    <div className="field field-flex value-field-box">
                                        <span className="field-label">{t("device.label.greenoffset")} (0~127)</span>
                                        <div className="field-content">
                                            <div className="field field-slide-bar field-set-value">
                                                <div className="field-form">
                                                    <div className="slide-bar-container">
                                                        <button type="button" className="btn btn-minus" onClick={() => onMinus("greenOffset")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.greenoffset")}
                                                        </button>
                                                        <input
                                                            type="range"
                                                            name="field-slide-green-offset"
                                                            aria-labelledby="field-slide-green-offset"
                                                            min="0"
                                                            max="127"
                                                            minLength="0"
                                                            maxLength="127"
                                                            value={colorValues?.greenOffset || 0}
                                                            onChange={(e) => onColorChange(e, "greenOffset")}
                                                            style={getBackground("greenOffset", 127)}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-plus" onClick={() => onPlus("greenOffset", 127)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.greenoffset")}
                                                        </button>
                                                    </div>
                                                    <div className="field-value">
                                                        <input
                                                            type="text"
                                                            name="field-input-slide-green-offset"
                                                            id="field-input-slide-green-offset"
                                                            min="0"
                                                            max="127"
                                                            minLength="0"
                                                            maxLength="127"
                                                            value={colorValues?.greenOffset || 0}
                                                            onChange={(e) => onColorChange(e, "greenOffset")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-tertiary"
                                                            onClick={() => onColorApply("greenOffset")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        >
                                                            {t("player.button.apply")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("blueOffset", data) && (
                                    <div className="field field-flex value-field-box">
                                        <span className="field-label">{t("device.label.blueoffset")} (0~127)</span>
                                        <div className="field-content">
                                            <div className="field field-slide-bar field-set-value">
                                                <div className="field-form">
                                                    <div className="slide-bar-container">
                                                        <button type="button" className="btn btn-minus" onClick={() => onMinus("blueOffset")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.blueoffset")}
                                                        </button>
                                                        <input
                                                            type="range"
                                                            name="field-slide-blue-offset"
                                                            aria-labelledby="field-slide-blue-offset"
                                                            min="0"
                                                            max="127"
                                                            minLength="0"
                                                            maxLength="127"
                                                            value={colorValues?.blueOffset || 0}
                                                            onChange={(e) => onColorChange(e, "blueOffset")}
                                                            style={getBackground("blueOffset", 127)}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-plus" onClick={() => onPlus("blueOffset", 127)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("device.label.blueoffset")}
                                                        </button>
                                                    </div>
                                                    <div className="field-value">
                                                        <input
                                                            type="text"
                                                            name="field-input-slide-blue-offset"
                                                            id="field-input-slide-blue-offset"
                                                            min="0"
                                                            max="127"
                                                            minLength="0"
                                                            maxLength="127"
                                                            value={colorValues?.blueOffset || 0}
                                                            onChange={(e) => onColorChange(e, "blueOffset")}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        />
                                                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("blueOffset")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("player.button.apply")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {_Device.getSupported("__pictureDetailSetings", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label"></span>
                                        <div className="field-content field-double-line">
                                            <div className="btn-box">
                                                <button type="button" className="btn" onClick={() => setPictureModal(true)}>
                                                    {t("dv.picture.detailSetting")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {(_Device.getSupported("screenRotation", data) || _Device.getSupported("externalRotation", data) || _Device.getSupported("colorTempEx", data)) && (
                            <div>
                                <div className="details-tit">{t("dv.picture.rotation")}</div>
                                {_Device.getSupported("screenRotation", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="screenRotationLabel">
                                            {t("device.label.screenrotation")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="screenRotation"
                                                    items={_Device.getDropDownItems(t, null, "screenRotation", data)}
                                                    value={_Device.getHexRangeValue(t, "screenRotation", data)}
                                                    ariaLabelId={"screenRotationLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "screenRotation", data)}
                                                    onChange={(item) => changeData("screenRotation", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {_Device.getSupported("externalRotation", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="externalRotationLabel">
                                            {t("device.label.externalrotation")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="externalRotation"
                                                    items={_Device.getDropDownItems(t, null, "externalRotation", data)}
                                                    value={_Device.getHexRangeValue(t, "externalRotation", data)}
                                                    ariaLabelId={"externalRotationLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "externalRotation", data)}
                                                    onChange={(item) => changeData("externalRotation", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("colorTempEx", data) && (
                                    <div className="field field-flex field-input">
                                        <span className="field-label" id="colorTempExLabel">
                                            {t("comomon.label.ColorTempEx")}
                                        </span>
                                        {/* <span className="field-content">
                                            {data?.filter((f) => f["colorTempEx"])[0]?.["colorTempEx"]?.data?.value
                                                ? _Device.getHexRangeValue(t, "colorTempEx", data, null, null, null, deviceInfo)
                                                : "-"}
                                        </span> */}
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="colorTempEx"
                                                    items={_Device.getDropDownItems(t, null, "colorTempEx", data)}
                                                    value={_Device.getHexRangeValue(t, "colorTempEx", data, null, null, null, deviceInfo)}
                                                    ariaLabelId={"colorTempExLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "colorTempEx", data, null, null, null, deviceInfo)}
                                                    onChange={(item) => changeData("colorTempEx", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {editModal && <EditModal data={editModal} changeInputAlias={changeInputAlias} onClose={() => setEditModal(null)} />}
            {fileModal && <FileModal type={fileModal} deviceInfo={deviceInfo} uniControlDevice={uniControlDevice} onClose={() => setFileModal("")} />}
            {pictureModal && <PictureModal idx={idx} data={data} deviceInfo={deviceInfo} uniControlDevice={uniControlDevice} onClose={() => setPictureModal(false)} />}
        </Fragment>
    );
};

const EditModal = (props) => {
    const { t } = useTranslation();
    const [inputAlias, setInputAlias] = useState(props.data?.value || "");
    const onClickDone = () => {
        props.changeInputAlias && props.changeInputAlias(props.data?.key, inputAlias);
    };
    return (
        <Modal
            title={t("common.edit")}
            id="popup-select-file"
            className="popup-edit-device-name popup-type no-scroll"
            buttonOkDisabled={inputAlias === ""}
            onClose={props.onClose}
            onClick={onClickDone}
        >
            <div className="field field-type-input field-unique-class">
                <label htmlFor="name" className="field-label pop-label">
                    {t("table.header.input.alias")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="input-box pop-input">
                            <input type="text" id="name" name="name" autocomplete="false" value={inputAlias} onChange={(e) => setInputAlias(e.target.value)} />
                            <button type="button" className={cx({ "btn-input-remove": true, hide: inputAlias ? false : true })} onClick={() => setInputAlias("")}>
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const FileModal = (props) => {
    const { t } = useTranslation();
    const { deviceInfo, uniControlDevice } = props;
    const { workspace, saveInnerLoading, auth } = useContext(AppContext);

    const [fileList, setFileList] = useState([]);
    const [fileInfo, setFileInfo] = useState(null);

    const onClickDone = () => {
        if (fileInfo) {
            _API.get({ path: `/content/filebox/files/download-token`, data: { expires_in_minutes: 120, file_path: fileInfo.Key } })
                .then(async (res) => {
                    console.log("api /content/filebox/files/download-token", res);
                    const source = `${res?.data?.result?.url}`;

                    const key = props.type === "WALL_PAPER" ? "changeWallpaper" : "changeLogo";
                    const params = [{ [key]: { data: { file_path: source } } }];
                    let controlRes = null;
                    if (uniControlDevice) {
                        controlRes = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
                    } else {
                        controlRes = await _API.onDeviceControl2([deviceInfo.device_id], params, saveInnerLoading, t);
                    }
                })
                .catch((e) => {
                    console.log("api error /device/devices/lgcc_cloning", e);
                });
            props.onClose();
        }
    };

    const getFileList = () => {
        _API.get({
            path: "/content/filebox/files",
            data: { property_id: workspace?.property_id, type: "image" },
        }).then((res) => {
            let { result } = res?.data;

            if (result?.Contents && result?.Contents.length) {
                const { Contents } = result;
                console.log("Contents", Contents);
                setFileList(Contents);
            }
        });
    };

    const onChangeRadio = (item) => {
        setFileInfo(item);
    };

    useEffect(() => {
        getFileList();
        resizeTable();
    }, []);

    return (
        <Modal
            title={props.type === "WALL_PAPER" ? t("common.label.changewallpaper") : t("setting.label.logo")}
            id="popup-select-file"
            className="popup popup-change-wall-paper middle"
            onClose={props.onClose}
            onClick={onClickDone}
        >
            <div className="txt-group">
                <p className="description">{t("subtext.selectimagechange")}</p>
            </div>

            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        <div className="total-count">
                            {t("player.label.total")} <span className="count">{fileList?.length || 0}</span>
                        </div>
                    </div>
                </div>

                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper device-table no-scroll">
                            <div className="table-box">
                                <table>
                                    <caption>{t("table.summary.filebox")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="check" width="60"></th>
                                            <th scope="col">
                                                <div className="text-block">{t("table.head.filename")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("table.head.filesize")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("table.head.date")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileList?.map((item, index) => (
                                            <tr key={index}>
                                                <td className="center" width="60">
                                                    <div className="field field-type-checkbox field-select-device">
                                                        <div className="field-form">
                                                            <div className="radio">
                                                                <input type="radio" name="radio-type" id={`file-radio-${index}`} onChange={(e) => onChangeRadio(item)} />
                                                                <label htmlFor={`file-radio-${index}`}>
                                                                    <span className="ir">{t("select.row.desc")}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{item?.Key?.substring(item?.Key.lastIndexOf("/") + 1)}</td>
                                                <td>{item?.Size ? _U.byteSizeParse(item?.Size) : "0"}</td>
                                                <td>{item?.LastModified ? _U.getOffsetTime(auth, workspace, item?.LastModified) : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <div className="table-header">
                            <div className="row">
                                <div className="thead"></div>
                                <div className="thead">{t("table.head.filename")}</div>
                            </div>
                        </div>

                        <ul className="table-body">
                            {fileList?.map((item, index) => (
                                <li className="table-row" key={`convert-${index}`}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell number center">
                                                <div className="field field-type-checkbox field-select-device">
                                                    <div className="field-form">
                                                        <div className="radio">
                                                            <input type="radio" name="convert-radio-type" id={`convert-file-radio-${index}`} onChange={(e) => onChangeRadio(item)} />
                                                            <label htmlFor={`convert-file-radio-${index}`}>
                                                                <span className="ir">{t("select.row.desc")}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-cell issue-level">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                    {item?.Key?.substring(item?.Key.lastIndexOf("/") + 1)}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">{t("table.head.filesize")}</span>
                                                <span className="field-content">{item?.Size ? _U.byteSizeParse(item?.Size) : "0"}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("table.head.date")}</span>
                                                <span className="field-content">{item?.LastModified ? _U.getOffsetTime(auth, workspace, item?.LastModified) : ""}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const PictureModal = (props) => {
    useCss("/assets/css/popup_picture_detail_settings.css");
    const { saveInnerLoading, rolePermission } = useContext(AppContext);
    const { t } = useTranslation();

    const { idx, data: propsData, uniControlDevice, deviceInfo } = props;
    const [data, setData] = useState({
        backlight: 0,
        contrast: 0,
        brightness: 0,
        sharpness: 0,
        color: 0,
        tint: 0,
    });
    const [tintText, setTintText] = useState("");

    const onClickDone = () => {
        props.onClose();
    };

    const getBackground = (key) => {
        let value = data[key];

        if (key === "sharpness") {
            value = value * 2;
        }

        return {
            background: "linear-gradient(to right, #a50034 0%, #a50034 " + (value || 0) + "%, #f1f1f1 " + (value || 0) + "%, #f1f1f1 100%)",
        };
    };

    const onDataChange = (e, key) => {
        const max = e.target.maxLength;
        let val = _U.getOnlyNumber(e.target.value) || 0;
        val = parseInt(val);
        if (key === "tint") {
            setTintText(getTintValue(val));
        }
        setData({ ...data, [key]: val >= max ? max : val });
    };

    const onPlus = (key, max) => {
        if (!max) {
            max = 100;
        }

        setData({ ...data, [key]: parseInt(data[key]) >= max ? max : parseInt(data[key]) + 1 });
        if (key === "tint") {
            setTintText(getTintValue(parseInt(data[key]) >= max ? max : parseInt(data[key]) + 1));
        }
    };

    const onMinus = (key) => {
        setData({ ...data, [key]: parseInt(data[key]) > 0 ? parseInt(data[key]) - 1 : 0 });
        if (key === "tint") {
            setTintText(getTintValue(parseInt(data[key]) > 0 ? parseInt(data[key]) - 1 : 0));
        }
    };

    const getTintValue = (val) => {
        const tint = parseInt(val);
        if (tint < 50) {
            return "R" + (50 - tint);
        } else if (tint > 50) {
            return "G" + (tint - 50);
        } else if (tint == 50) {
            return 0;
        } else {
            return tint;
        }
    };

    const onDataChangeTint = (e) => {
        const val = e?.target?.value?.toUpperCase();
        let nVal = parseInt(_U.getOnlyNumber(val) || 0);
        console.log("val", val, "nVal", nVal);
        if ((val != 0 && !val?.startsWith("R") && !val?.startsWith("G")) || val == "") {
            nVal = 50;
            setTintText("");
        } else if (nVal < 0 || nVal > 50 || val == 0) {
            nVal = 50;
            setTintText("0");
        } else if (val?.length > 1 && nVal == 0) {
            nVal = 50;
            setTintText(val?.substring(0, 1));
        } else if (val?.startsWith("R")) {
            nVal = 50 - nVal;
            setTintText(val);
        } else {
            // val?.startsWith("G")
            nVal = 50 + nVal;
            setTintText(val);
        }
        setData({ ...data, tint: nVal });
    };

    const onColorApply = async (key) => {
        const value = data[key];
        const params = [{ [key]: { data: { value: value || 0 } } }];
        let controlRes = null;
        if (uniControlDevice) {
            controlRes = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
    };

    const onLoadData = () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: ["backlight", "contrast", "brightness", "sharpness", "color", "tint"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData({
                    backlight: result?.filter((f) => f["backlight"])[0]?.["backlight"]?.data?.value || 0,
                    contrast: result?.filter((f) => f["contrast"])[0]?.["contrast"]?.data?.value || 0,
                    brightness: result?.filter((f) => f["brightness"])[0]?.["brightness"]?.data?.value || 0,
                    sharpness: result?.filter((f) => f["sharpness"])[0]?.["sharpness"]?.data?.value || 0,
                    color: result?.filter((f) => f["color"])[0]?.["color"]?.data?.value || 0,
                    tint: result?.filter((f) => f["tint"])[0]?.["tint"]?.data?.value || 0,
                });
                console.log("tint text", getTintValue(result?.filter((f) => f["tint"])[0]?.["tint"]?.data?.value || 0));
                setTintText(getTintValue(result?.filter((f) => f["tint"])[0]?.["tint"]?.data?.value || 0));
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    useEffect(() => {
        onLoadData();
    }, [props.data]);

    return (
        <Modal title="Picture Detail Settings" id="popup-default" className="popup-picture-detail-setting" onClose={props.onClose} onClick={onClickDone} noFooter ok>
            <div className="field field-slide-bar field-set-value">
                <div className="field-header">
                    <label id="field-backlight" htmlFor="field-input-backlight" className="field-title">
                        {t("player.label.backlight")} (0~100)
                    </label>
                </div>
                <div className="field-form">
                    <div className="slide-bar-container">
                        <button type="button" className="btn btn-minus" onClick={() => onMinus("backlight")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.backlight")} {t("common.label.valuedecrease")}
                        </button>
                        <input
                            type="range"
                            name="field-slide-backlight"
                            value={data?.backlight || 0}
                            min="0"
                            max="100"
                            minLength="0"
                            maxLength="100"
                            aria-labelledby="field-backlight"
                            onChange={(e) => onDataChange(e, "backlight")}
                            style={getBackground("backlight")}
                            disabled={_U.getAuthWriteDisable(rolePermission)}
                        />
                        <button type="button" className="btn btn-plus" onClick={() => onPlus("backlight")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.backlight")} {t("common.label.valueincrease")}
                        </button>
                    </div>
                    <div className="field-value">
                        <input
                            type="text"
                            name="field-input-backlight"
                            id="field-input-backlight"
                            minLength="0"
                            maxLength="100"
                            value={data?.backlight}
                            onChange={(e) => onDataChange(e, "backlight")}
                            disabled={_U.getAuthWriteDisable(rolePermission)}
                        />
                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("backlight")} disabled={_U.getAuthWriteDisable(rolePermission)}>
                            {t("player.button.apply")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="field field-slide-bar field-set-value">
                <div className="field-header">
                    <label id="field-contrast" htmlFor="field-input-contrast" className="field-title">
                        {t("player.label.contrast")} (0~100)
                    </label>
                </div>
                <div className="field-form">
                    <div className="slide-bar-container">
                        <button
                            type="button"
                            className="btn btn-minus"
                            onClick={() => onMinus("contrast")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        >
                            {t("player.label.contrast")} {t("common.label.valuedecrease")}
                        </button>
                        <input
                            type="range"
                            name="field-slide-contrast"
                            value={data?.contrast || 0}
                            min="0"
                            max="100"
                            minLength="0"
                            maxLength="100"
                            aria-labelledby="field-contrast"
                            onChange={(e) => onDataChange(e, "contrast")}
                            style={getBackground("contrast")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        />
                        <button
                            type="button"
                            className="btn btn-plus"
                            onClick={() => onPlus("contrast")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        >
                            {t("player.label.contrast")} {t("common.label.valueincrease")}
                        </button>
                    </div>
                    <div className="field-value">
                        <input
                            type="text"
                            name="field-input-contrast"
                            id="field-input-contrast"
                            value={data?.contrast}
                            minLength="0"
                            maxLength="100"
                            onChange={(e) => onDataChange(e, "contrast")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        />
                        <button
                            type="button"
                            className="btn btn-tertiary"
                            onClick={() => onColorApply("contrast")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        >
                            {t("player.button.apply")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="field field-slide-bar field-set-value">
                <div className="field-header">
                    <label id="field-brightness" htmlFor="field-input-brightness" className="field-title">
                        {t("common.label.brightness.100")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="slide-bar-container">
                        <button
                            type="button"
                            className="btn btn-minus"
                            onClick={() => onMinus("brightness")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        >
                            {t("common.button.brightness.decrease")}
                        </button>
                        <input
                            type="range"
                            name="field-slide-brightness"
                            value={data?.brightness || 0}
                            min="0"
                            max="100"
                            minLength="0"
                            maxLength="100"
                            aria-labelledby="field-brightness"
                            onChange={(e) => onDataChange(e, "brightness")}
                            style={getBackground("brightness")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        />
                        <button
                            type="button"
                            className="btn btn-plus"
                            onClick={() => onPlus("brightness")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        >
                            {t("common.button.brightness.increase")}
                        </button>
                    </div>
                    <div className="field-value">
                        <input
                            type="text"
                            name="field-input-brightness"
                            id="field-input-brightness"
                            value={data?.brightness}
                            minLength="0"
                            maxLength="100"
                            onChange={(e) => onDataChange(e, "brightness")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        />
                        <button
                            type="button"
                            className="btn btn-tertiary"
                            onClick={() => onColorApply("brightness")}
                            disabled={
                                _U.getAuthWriteDisable(rolePermission) ||
                                (deviceInfo?.os_type === "ANDROID" &&
                                    (propsData?.filter((f) => f["input"])?.[0]?.["input"]?.data?.value?.toUpperCase() === "F9" ||
                                        propsData?.filter((f) => f["pictureMode"])?.[0]?.["pictureMode"]?.data?.value?.toUpperCase() !== "F2"))
                            }
                        >
                            {t("player.button.apply")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="field field-slide-bar field-set-value">
                <div className="field-header">
                    <label id="field-sharpness" htmlFor="field-input-sharpness" className="field-title">
                        {t("player.label.sharpness")} (0~50)
                    </label>
                </div>
                <div className="field-form">
                    <div className="slide-bar-container">
                        <button type="button" className="btn btn-minus" onClick={() => onMinus("sharpness")} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.sharpness")} {t("common.label.valuedecrease")}
                        </button>
                        <input
                            type="range"
                            name="field-slide-sharpness"
                            value={data?.sharpness}
                            min="0"
                            max="50"
                            minLength="0"
                            maxLength="50"
                            aria-labelledby="field-sharpness"
                            onChange={(e) => onDataChange(e, "sharpness")}
                            style={getBackground("sharpness")}
                            disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}
                        />
                        <button type="button" className="btn btn-plus" onClick={() => onPlus("sharpness", 50)} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.sharpness")} {t("common.label.valueincrease")}
                        </button>
                    </div>
                    <div className="field-value">
                        <input
                            type="text"
                            name="field-input-sharpness"
                            id="field-input-sharpness"
                            value={data?.sharpness}
                            minLength="0"
                            maxLength="50"
                            onChange={(e) => onDataChange(e, "sharpness")}
                            disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}
                        />
                        <button
                            type="button"
                            className="btn btn-tertiary"
                            onClick={() => onColorApply("sharpness")}
                            disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}
                        >
                            {t("player.button.apply")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="field field-slide-bar field-set-value">
                <div className="field-header">
                    <label id="field-color" htmlFor="field-input-color" className="field-title">
                        {t("player.label.color")} (0~100)
                    </label>
                </div>
                <div className="field-form">
                    <div className="slide-bar-container">
                        <button type="button" className="btn btn-minus" onClick={() => onMinus("color")} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.color")} {t("common.label.valuedecrease")}
                        </button>
                        <input
                            type="range"
                            name="field-slide-color"
                            value={data?.color}
                            min="0"
                            max="100"
                            minLength="0"
                            maxLength="100"
                            aria-labelledby="field-color"
                            onChange={(e) => onDataChange(e, "color")}
                            style={getBackground("color")}
                            disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}
                        />
                        <button type="button" className="btn btn-plus" onClick={() => onPlus("color")} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.color")} {t("common.label.valueincrease")}
                        </button>
                    </div>
                    <div className="field-value">
                        <input
                            type="text"
                            name="field-input-color"
                            id="field-input-color"
                            value={data?.color}
                            minLength="0"
                            maxLength="100"
                            onChange={(e) => onDataChange(e, "color")}
                            disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}
                        />
                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("color")} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.button.apply")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="field field-slide-bar field-set-value">
                <div className="field-header">
                    <label id="field-tint" htmlFor="field-input-tint" className="field-title">
                        {t("player.label.tint")} (R50~G50)
                    </label>
                </div>
                <div className="field-form">
                    <div className="slide-bar-container">
                        <button type="button" className="btn btn-minus" onClick={() => onMinus("tint")} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.tint")} {t("common.label.valuedecrease")}
                        </button>
                        <input
                            type="range"
                            name="field-slide-tint"
                            value={data?.tint}
                            minLength="0"
                            maxLength="100"
                            min="0"
                            max="100"
                            aria-labelledby="field-tint"
                            onChange={(e) => onDataChange(e, "tint")}
                            style={getBackground("tint")}
                            disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}
                        />
                        <button type="button" className="btn btn-plus" onClick={() => onPlus("tint")} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.label.tint")} {t("common.label.valueincrease")}
                        </button>
                    </div>
                    <div className="field-value">
                        <input
                            type="text"
                            name="field-input-tint"
                            id="field-input-tint"
                            value={!tintText && tintText != 0 ? "" : tintText}
                            minLength="0"
                            maxLength="3"
                            // readOnly={true}
                            onChange={(e) => onDataChangeTint(e)}
                            disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}
                        />
                        <button type="button" className="btn btn-tertiary" onClick={() => onColorApply("tint")} disabled={deviceInfo?.os_type === "ANDROID" || _U.getAuthWriteDisable(rolePermission)}>
                            {t("player.button.apply")}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const ControlAudio = (props) => {
    const { idx, deviceInfo, uniControlDevice } = props;
    const { t } = useTranslation();
    const { saveInnerLoading, rolePermission, lastUpdateTime } = useContext(AppContext);

    const [mute, setMute] = useState(false);
    const [data, setData] = useState([]);

    const onLoadData = async () => {
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: ["soundMode", "soundBalance", "speaker", "soundOut", "audioOut", "digitalAudioInput", "volumeMute", "volume"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                console.log("mute", result?.filter((f) => f["volumeMute"])[0]?.["volumeMute"]?.data?.value);
                setMute(result?.filter((f) => f["volumeMute"])[0]?.["volumeMute"]?.data?.value);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
            });
    };

    const changeData = (key, value) => {
        if (key == "soundMode" && value != "F0" && deviceInfo?.os_type === "ANDROID") {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.msg.notcustom"),
                button: [t("common.ok")],
            }).then((id) => {
                setDeviceControl(key, value);
            });
        } else {
            setDeviceControl(key, value);
        }
    };

    const setDeviceControl = async (key, value) => {
        const params = [{ [key]: { data: { value } } }];
        let controlRes = null;
        if (uniControlDevice) {
            controlRes = await _API.onDeviceControl2([...uniControlDevice?.device_list], params, saveInnerLoading, t);
        } else {
            controlRes = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
        }
        if (controlRes && controlRes.status === "true") {
            const newData = JSON.parse(JSON.stringify(data));
            newData?.forEach((f) => {
                if (f[key]) {
                    f[key]["data"]["value"] = value;
                }
            });

            setData(newData);
            if (key === "volumeMute") {
                setMute(!mute);
            }
            onLoadData();
        }
    };

    const onVolumeMute = () => {
        setDeviceControl("volumeMute", !mute);
    };

    const getSoundBalance = (val) => {
        if (val || val == 0) {
            if (parseInt(val) < 50) {
                return "L" + (50 - val);
            } else if (val > 50) {
                return "R" + (val - 50);
            } else {
                return 0;
            }
        }
        return val;
    };

    useEffect(() => {
        onLoadData();
        resizeTable();
    }, [idx, lastUpdateTime]);

    return (
        <Fragment>
            <div className="tab-panel panel-audio" role="tabpanel">
                <div className="inner-box inner-box-ml">
                    <div className="list-box aligned-list-box">
                        {(_Device.getSupported("soundMode", data) || _Device.getSupported("soundBalance", data)) && (
                            <div>
                                <div className="details-tit">{t("dv.sound.mode")}</div>

                                {_Device.getSupported("soundMode", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="soundModeLabel">
                                            {t("player.label.soundMode")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="soundMode"
                                                    items={_Device.getDropDownItems(t, null, "soundMode", data, null, deviceInfo)}
                                                    value={_Device.getHexRangeValue(t, "soundMode", data, null, null, null, deviceInfo)}
                                                    ariaLabelId={"soundModeLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "soundMode", data, null, null, null, deviceInfo)}
                                                    onChange={(item) => changeData("soundMode", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("soundBalance", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="soundBalanceLabel">
                                            {t("player.label.balance")} (L50~R50)
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="soundBalance"
                                                    items={_Device.getDropDownItems(t, null, "soundBalance", data, getSoundBalance)}
                                                    value={_Device.getHexRangeValue(t, "soundBalance", data, null, null, getSoundBalance)}
                                                    ariaLabelId={"soundBalanceLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "soundBalance", data, null, null, getSoundBalance)}
                                                    onChange={(item) => changeData("soundBalance", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {(_Device.getSupported("speaker", data) ||
                            _Device.getSupported("soundOut", data) ||
                            _Device.getSupported("audioOut", data) ||
                            _Device.getSupported("digitalAudioInput", data)) && (
                            <div>
                                <div className="details-tit">{t("dv.audio.source")}</div>

                                {_Device.getSupported("speaker", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="speakerLabel">
                                            {t("player.label.speaker")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="speaker"
                                                    items={_Device.getDropDownItems(t, null, "speaker", data)}
                                                    value={_Device.getHexRangeValue(t, "speaker", data)}
                                                    ariaLabelId={"speakerLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "speaker", data)}
                                                    onChange={(item) => changeData("speaker", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("soundOut", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="soundOutLabel">
                                            {t("device.label.soundout")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="soundOut"
                                                    items={_Device.getDropDownItems(t, null, "soundOut", data)}
                                                    value={_Device.getHexRangeValue(t, "soundOut", data)}
                                                    ariaLabelId={"soundOutLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "soundOut", data)}
                                                    onChange={(item) => changeData("soundOut", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("audioOut", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="audioOutLabel">
                                            {t("player.label.audioout")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="audioOut"
                                                    items={_Device.getDropDownItems(t, null, "audioOut", data)}
                                                    value={_Device.getHexRangeValue(t, "audioOut", data)}
                                                    ariaLabelId={"audioOutLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "audioOut", data)}
                                                    onChange={(item) => changeData("audioOut", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("digitalAudioInput", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="digitalAudioInputLabel">
                                            {t("device.label.digitalaudioinput")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="digitalAudioInput"
                                                    items={_Device.getDropDownItems(t, null, "digitalAudioInput", data)}
                                                    value={_Device.getHexRangeValue(t, "digitalAudioInput", data)}
                                                    ariaLabelId={"digitalAudioInputLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "digitalAudioInput", data)}
                                                    onChange={(item) => changeData("digitalAudioInput", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {(_Device.getSupported("volumeMute", data) || _Device.getSupported("volume", data)) && (
                            <div>
                                <div className="details-tit">{t("dv.volume.settings")}</div>

                                {_Device.getSupported("volumeMute", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label">{t("player.label.volumeMute")}</span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <div className="switch">
                                                    <label htmlFor="default-switch-8" className="switch-controller">
                                                        {t("player.label.volumeMute")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="default-switch-8"
                                                        name="default-switch-8"
                                                        role="switch"
                                                        aria-checked="true"
                                                        checked={mute}
                                                        onChange={() => onVolumeMute()}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {_Device.getSupported("volume", data) && (
                                    <div className="field field-flex">
                                        <span className="field-label" id="volumeLabel">
                                            {t("common.label.volume.100")}
                                        </span>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <SelectBox
                                                    id="volume"
                                                    items={_Device.getDropDownItems(t, null, "volume", data)}
                                                    value={_Device.getHexRangeValue(t, "volume", data)}
                                                    ariaLabelId={"volumeLabel"}
                                                    selectLabel={_Device.getHexRangeValue(t, "volume", data)}
                                                    onChange={(item) => changeData("volume", item?.value)}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    noItemText={_Device.getHexRangeValue(t, "volume", data)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
