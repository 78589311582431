import React, { useState, useEffect, useContext, Fragment } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";

import { AppContext } from "@component/AppProvider";
import TargetModelnGroup from "@component/Device/ModelnGroup";
import _API from "@util/api";
import useCss from "@hook/useCss";
import Dialog from "@component/UI/Dialog";

const SetTargetModal = (props) => {
    useCss("/assets/css/popup_set_target.css");

    const { targetModel: targetModelProps, saveTargetModel, workspace } = useContext(AppContext);
    const { t } = useTranslation();

    const [keyword, setKeyword] = useState("");
    const [models, setModels] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [targetModel, setTargetModel] = useState({});
    const [groupList, setGroupList] = useState([]);

    const onDone = () => {
        console.log("click done");
        if (targetModel?.headLabel === "modelNgroup" && !targetModel?.model_name && (!targetModel?.groupList || targetModel?.groupList?.length === 0)) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.multiItem"),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (targetModel?.headLabel === "modelNgroup" && !targetModel?.model_name) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("player.label.model") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (targetModel?.headLabel === "modelNgroup" && (!targetModel?.groupList || targetModel?.groupList?.length === 0)) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("devicemanagement.tree.group") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (targetModel?.headLabel !== "modelNgroup" && (!targetModel?.groupList || targetModel?.groupList?.length === 0)) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("devicemanagement.tree.group") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else {
            saveTargetModel({ ...targetModel, open: false });
        }
    };
    const onCancel = () => {
        saveTargetModel({ open: false });
    };

    const onLoadModels = () => {
        _API.get({ path: "/business/groups/models", data: { property_id: workspace?.property_id } })
            .then((res) => {
                // console.log("api /business/groups/models", res);
                setModels(res?.data?.result?.models || []);
                setModelList(res?.data?.result?.models || []);
            })
            .catch((e) => {
                console.log("api error /business/groups/models", e);
            });
    };

    const onSearchModel = () => {
        if (keyword) {
            const newModelList = models?.filter((f) => f.toLowerCase().indexOf(keyword) > -1);
            setModelList(newModelList);
        } else {
            setModelList(models);
        }
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onSearchModel();
        }
    };

    const selectModel = (modelName) => {
        setTargetModel({ ...targetModel, model_name: modelName });
    };

    const getGroupList = (checkList) => {
        _API.get({ path: "/business/groups", data: {} })
            .then((res) => {
                // console.log("api /business/groups", res);
                // setDataList(res?.data?.result);
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    const newGroupList = res?.data?.result?.map((m) => convertGroupFormat(m, checkList));
                    setGroupList(newGroupList);
                } else {
                    setGroupList([]);
                }
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
                setGroupList([]);
            });
    };

    const convertGroupFormat = (data, checkList) => {
        if (data && data?.group_id) {
            const m = {
                ...data,
                enabled: true,
                expanded: false,
                id: data.group_id,
                label: data.group_name,
                checked: checkList?.map((m) => m.group_id)?.indexOf(data.group_id) > -1,
                child: data?.lower_groups?.map((m) => convertGroupFormat(m, checkList) || []),
            };
            if (m.child?.filter((f) => f.checked)?.length > 0) {
                m.expanded = true;
            }
            return m;
        }
        return [];
    };

    const convertGroupFormat2 = (data, id, checked, parentChecked) => {
        if (data && data?.group_id) {
            const checkedMe = data.group_id === id ? checked : targetModel?.groupRadio ? false : parentChecked === true ? true : parentChecked === false ? false : data?.checked;
            if (!targetModel?.groupRadio) {
                parentChecked = parentChecked === true || parentChecked === false ? parentChecked : data.group_id === id ? checked : null;
            }
            const newData = {
                ...data,
                checked: checkedMe,
                child: data?.child?.map((m) => convertGroupFormat2(m, id, checked, parentChecked) || []),
            };
            if (!targetModel?.groupRadio) {
                if (newData?.child?.length > 0 && newData?.child?.length === newData?.child?.filter((f) => f.checked)?.length) {
                    newData.checked = true;
                } else if (newData?.child?.length > 0 && newData?.child?.length !== newData?.child?.filter((f) => f.checked)?.length) {
                    newData.checked = false;
                }
            }
            return { ...newData };
        }
        return [];
    };

    const selectGroupCheck = (data, checkList, treeName) => {
        treeName = treeName ? [...treeName, data?.group_name] : [data?.group_name];
        if (data?.checked) {
            checkList.push({ ...data, treeName: treeName });
        }
        if (!data?.checked) {
            data?.child?.forEach((f) => {
                selectGroupCheck(f, checkList, treeName);
            });
        }
    };

    const onCheckGroup = (id, checked, items) => {
        const newGroupList = items?.map((m) => convertGroupFormat2(m, id, checked));
        setGroupList(newGroupList);
        const selectGroupList = [];
        newGroupList?.forEach((f) => {
            selectGroupCheck(f, selectGroupList);
        });
        setTargetModel({ ...targetModel, groupList: selectGroupList });
    };

    useEffect(() => {
        onSearchModel();
    }, [keyword]);

    useEffect(() => {
        if (targetModelProps?.model) {
            onLoadModels();
        }
        if (targetModelProps?.group) {
            getGroupList(targetModelProps?.groupList);
        }
        setTargetModel(targetModelProps);
    }, [targetModelProps]);

    useEffect(() => {
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    return (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
            <div id="popup-set-target" className="popup-wrapper show">
                <div className="dim">&nbsp;</div>
                <div className="popup popup-set-target overflow-y-auto" role="dialog" aria-labelledby="popup-label" aria-modal="true">
                    <div className="popup-container max-height-unset">
                        <div className="popup-header">
                            <h1 id="popup-label">{t("common.button.set.target")}</h1>
                        </div>

                        <div className="popup-content">
                            <div className="set-guide-txt">
                                {targetModel?.headLabel === "modelNgroup" ? t("common.desc.target.modelNgroup") : t("common.desc.target.group")}
                                {targetModel?.uniController && <span>{t("subtext.desc.unicontroller")}</span>}
                            </div>
                            <div className="target-select-wrap">
                                {targetModel?.model && (
                                    <div className="row search-row">
                                        <h2 className="list-title">{t("common.label.device.model")}</h2>
                                        <div className="search-container">
                                            <div className="field field-type-search">
                                                <input
                                                    type="text"
                                                    id="search"
                                                    className="search search_c"
                                                    name="search"
                                                    placeholder={t("subtext.guid.searchmodelname")}
                                                    autoComplete="false"
                                                    aria-label={t("subtext.guid.searchmodelname")}
                                                    value={keyword}
                                                    onChange={(e) => setKeyword(e.target.value)}
                                                    onKeyDown={onKeyDown}
                                                />
                                                <button type="button" className={cx({ "btn-input-remove": true, hide: keyword ? false : true })} onClick={() => setKeyword("")}>
                                                    <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                                </button>
                                                <button type="button" className="btn-search" onClick={onSearchModel}>
                                                    <span className="ir">{t("button.search")}</span>
                                                </button>
                                            </div>
                                            <ul className="device-model-wrap" role="radiogroup">
                                                {modelList?.map((item, index) => (
                                                    <li className="radio" key={index}>
                                                        <input
                                                            type="radio"
                                                            name="device-model01"
                                                            id={`device-model01-${index}`}
                                                            checked={item === targetModel?.model_name}
                                                            onChange={(e) => selectModel(item)}
                                                        />
                                                        <label htmlFor={`device-model01-${index}`}>{item}</label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {targetModel?.group && (
                                    <div className="row group-row">
                                        <h2 className="list-title">Group List</h2>
                                        <div className="site-group-list-container">
                                            <div className="child-menu-container">
                                                <Tree onClick={onCheckGroup} items={groupList} t={t} targetModel={targetModel} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <TargetModelnGroup targetInfo={{ ...targetModel, setTarget: false }} />
                        </div>
                    </div>

                    <div className="popup-footer">
                        <div className="button-box double-button">
                            <button type="button" id="btn-st-cancel" className="btn btn-huge btn-tertiary" onClick={() => onCancel()}>
                                {t("button.cancel")}
                            </button>
                            <button type="button" id="btn-st-done" className="btn btn-huge btn-secondary " onClick={() => onDone()}>
                                {t("distribution.label.completed")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default SetTargetModal;

const Tree = (props) => {
    const { items: itemsInProps, checkGroupList, current: currentInProps, onClick, t, targetModel } = props;

    const [items, setItems] = useState(itemsInProps || []);
    const [current, setCurrent] = useState(currentInProps || null);

    const handleItemClick = (id) => {
        const expand = (o) => {
            if (o.id === id) {
                if (o.child?.length > 0) {
                    o.expanded = !o.expanded;
                } else {
                    setCurrent(id);
                }
            }
            o.child?.map(expand);
        };
        const nl = [...items];
        nl.map(expand);
        setItems(nl);
    };

    const handleItemChange = (id, e) => {
        onClick && onClick(id, e.target.checked, items);
        // const expand = (o) => {
        //     if (o.id === id) {
        //         o.checked = !o.checked;
        //         onClick && onClick(id, !o.checked);
        //     }
        //     o.child?.map(expand);
        // };
        // const nl = [...items];
        // nl.map(expand);
        // setItems(nl);
    };

    useEffect(() => {
        setItems(itemsInProps);
    }, [itemsInProps]);

    useEffect(() => {
        setCurrent(currentInProps);
    }, [currentInProps]);

    return (
        <div className="devices-menu-site">
            <ul role="menu">
                {items.map((item, i) => (
                    <Branches key={i} {...item} checkGroupList={checkGroupList} onClick={handleItemClick} onChange={handleItemChange} current={current} t={t} targetModel={targetModel} />
                ))}
            </ul>
        </div>
    );
};

const Branches = ({ id, label, enabled, expanded, child, onClick, onChange, current, checked, t, checkGroupList, targetModel }) => {
    return (
        <li id={id} className={cx({ "child-menu": true, "has-child": child?.length > 0, "open-menu": expanded, disabled: !enabled, active: false })} role="none presentation">
            <div className={cx({ "menu-box": true, active: current === id })}>
                <a href="#" role="menuitemradio" onClick={onClick?.bind(this, id)}>
                    {t("subtext.menu.open")}
                </a>
                <div className="checkbox">
                    <input type={targetModel?.groupRadio ? "radio" : "checkbox"} name="check-group" id={"check-group-" + id} value={""} onChange={onChange?.bind(this, id)} checked={checked} />
                    <label htmlFor={"check-group-" + id}>{label}</label>
                </div>
            </div>
            {expanded && child?.length > 0 && (
                <ul role="menu">
                    {child.map((item, i) => (
                        <Branches key={i} {...item} onClick={onClick} onChange={onChange} current={current} t={t} targetModel={targetModel} />
                    ))}
                </ul>
            )}
        </li>
    );
};
