import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Header from "./Header";
import SideBar from "./SideBar";
import Footer from "./Footer";
import { AppContext } from "@component/AppProvider";
import Loading from "@component/UI/Loading";
import SetTargetModal from "@component/Device/SetTargetModal";
import AlertMessage from "@component/AlertMessage";
import FileboxToolbar from "@component/Toolbar/Filebox";

export default (props) => {
    const { children } = props;
    const { t } = useTranslation();

    const location = useLocation();
    const { innerLoading, targetModel, sideBarExpand, fileboxToolbar, saveFileboxToolbar, showAlertMessage, saveShowAlertMessage } = useContext(AppContext);
    const [addClass, setAddClass] = useState("");

    useEffect(() => {
        if (location?.pathname?.includes("report")) {
            setAddClass("report"); //Report page class name
        } else if (location?.pathname?.includes("administration")) {
            setAddClass("administration"); //admin page class name
        }

        return () => {
            setAddClass("");
        };
    }, [location]);

    useEffect(() => {
        function handleKeyup(e) {
            if (e?.key === "Escape") {
                document.activeElement.blur();
            }
        }
        document.addEventListener("keyup", handleKeyup);
        return () => {
            document.removeEventListener("keyup", handleKeyup);
        };
    }, []);

    return (
        <Fragment>
            {innerLoading && <Loading />}
            <a href="#go-content" className="bypass">
                {t("subtext.go.main.content")}
            </a>
            <div id="ROOT_CONTAINER" className="wrapper">
                <Header showAlertMessage={(state) => setShowAlertMessage(state)} />
                <div className={cx({ "content-container": true, expand: sideBarExpand })}>
                    <SideBar />
                    <main id="go-content" className={`content-box show-lnb ${addClass}`}>
                        {children}
                    </main>
                    <Footer />
                </div>
            </div>
            {targetModel?.open && <SetTargetModal />}
            {fileboxToolbar && (
                <FileboxToolbar
                    onClose={() => {
                        saveFileboxToolbar(false);
                        setTimeout(() => {
                            document.activeElement.blur();
                        }, 100);
                    }}
                />
            )}
            {showAlertMessage && (
                <AlertMessage
                    onClose={() => {
                        saveShowAlertMessage(false);
                        setTimeout(() => {
                            document.activeElement.blur();
                        }, 100);
                    }}
                />
            )}
        </Fragment>
    );
};
