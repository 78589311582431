import React, { useEffect, useState, useContext, useRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { TooltipBottom } from "@component/Tooltip";
import { AppContext } from "@component/AppProvider";

import _U from "@util/utilities";

export const Features = (props) => {
    const { t } = useTranslation();
    const { title, items: itemsProps, onClick } = props;
    const { rolePermission, product } = useContext(AppContext);
    const [expand, setExpand] = useState(false);
    const [items, setItems] = useState([]);

    const onExpand = () => {
        setExpand(!expand);
        props.treeUlOn && props.treeUlOn(!expand);
    };

    const onAddHoverClass = (id) => {
        const list = JSON.parse(JSON.stringify(items));
        for (let i = 0; i < list?.length; i++) {
            const subList = list[i];
            for (let item of subList) {
                if (item) {
                    if (item?.id === id) {
                        item["hoverClass"] = "hover";
                    } else {
                        item["hoverClass"] = "";
                    }
                }
            }
        }
        setItems(list);
    };

    const onRemoveHoverClass = (id) => {
        if (items) {
            const list = JSON.parse(JSON.stringify(items));
            for (let i = 0; i < list?.length; i++) {
                const subList = list[i];
                for (let item of subList) {
                    if (item && item?.id === id) {
                        item["hoverClass"] = "";
                    }
                }
            }
            setItems(list);
        }
    };

    useEffect(() => {
        setItems(itemsProps);
        function handleKeyup(e) {
            if (e?.key === "Escape") {
                // document.activeElement.blur();
                $(".dropdown-icon-list")
                    .find("button")
                    .each(function () {
                        $(this).removeClass("hover");
                    });
            }
        }
        document.addEventListener("keyup", handleKeyup);
        return () => {
            document.removeEventListener("keyup", handleKeyup);
        };
    }, [itemsProps]);

    return (
        <div className="pecialized-group">
            <div className="dropdown-icon">
                <a href="#" role="button" type="button" aria-expanded={expand} className={cx({ "btn-icon_group": true, active: expand })} onClick={() => onExpand()}>
                    {title}
                </a>
                <div className="dropdown-icon-list">
                    <ul className="lists" role="menu">
                        {items?.map(
                            (row, i) =>
                                row && (
                                    <li key={i} className="list tooltip-container">
                                        {row?.map((item, j) => {
                                            if (
                                                (item?.id === "SPF-UNICONTROLLER" && _U.getAuthWriteDisable(rolePermission)) ||
                                                (item?.id === "SPF-MONITOR-SETTING" && product?.service_type !== "Standard")
                                            ) {
                                                return "";
                                            }
                                            return (
                                                <button
                                                    type="button"
                                                    key={j}
                                                    id={item?.id}
                                                    className={`${item?.icon} tooltip-label ${
                                                        (item?.id === "SPF-UNICONTROLLER" && _U.getAuthWriteDisable(rolePermission)) ||
                                                        (item?.id === "SPF-MONITOR-SETTING" && product?.service_type !== "Standard")
                                                            ? "disabled-type"
                                                            : ""
                                                    } ${item?.hoverClass ? item.hoverClass : ""}`}
                                                    aria-label={item?.label}
                                                    onClick={(e) => {
                                                        onClick(item?.id);
                                                        document.activeElement.blur();
                                                    }}
                                                    disabled={
                                                        (item?.id === "SPF-UNICONTROLLER" && _U.getAuthWriteDisable(rolePermission)) ||
                                                        (item?.id === "SPF-MONITOR-SETTING" && product?.service_type !== "Standard")
                                                    }
                                                    onMouseEnter={() => onAddHoverClass(item?.id)}
                                                    onMouseLeave={() => onRemoveHoverClass(item?.id)}
                                                    onFocus={() => onAddHoverClass(item?.id)}
                                                    onBlur={() => onRemoveHoverClass(item?.id)}
                                                >
                                                    {item?.label && <TooltipBottom txt={item?.label} />}
                                                    {/* <span className="ir tooltip">{item?.label || ""}</span> */}
                                                </button>
                                            );
                                        })}
                                    </li>
                                )
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export const Tree = (props) => {
    const { t } = useTranslation();
    const { title, subTitle, onSettingClick, items: itemsInProps, current: currentInProps, onClick, onCreateGroup, treeUlOn } = props;
    const { rolePermission } = useContext(AppContext);

    const [items, setItems] = useState(itemsInProps || []);
    const [current, setCurrent] = useState(currentInProps || null);

    const handleItemClick = (id) => {
        const expand = (o) => {
            if (o.id === id) {
                if (o.child?.length > 0) {
                    o.expanded = !o.expanded;
                    onClick && onClick(id);
                } else {
                    setCurrent(id);
                    onClick && onClick(id);
                }
            }
            o.child?.map(expand);
        };
        const nl = [...items];
        nl.map(expand);
        setItems(nl);
    };

    useEffect(() => {
        setItems(itemsInProps);
    }, [itemsInProps]);

    useEffect(() => {
        setCurrent(currentInProps);
    }, [currentInProps]);

    return (
        <div className="devices-menu-site">
            {/* <div className="title">{title}</div> */}
            <div className="inner-title">
                {subTitle}
                {items && items.length > 1 && (
                    <button type="button" className="btn-icon btn-setting" aria-label={`${subTitle}`} onClick={onSettingClick} disabled={_U.getAuthWriteDisable(rolePermission)}>
                        <span className="ir">{`${subTitle}`}</span>
                    </button>
                )}
            </div>
            <ul className={cx({ on: treeUlOn })}>
                {items.map((item, i) => (
                    <Branches key={i} {...item} onClick={handleItemClick} current={current} />
                ))}
                {(!items || items.length === 0 || items.length === 1) && (
                    <li id="newGroup" className="child-menu create-group margin-top5px">
                        <button type="button" onClick={onCreateGroup} disabled={_U.getAuthWriteDisable(rolePermission)}>
                            <span>{t("common.button.create.group")}</span>
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
};

const Branches = ({ id, label, enabled, expanded, child, onClick, current, device_count, level }) => {
    const dynamicProps = {};
    if (expanded) {
        dynamicProps["aria-expanded"] = "true";
    } else if (child?.length > 0) {
        dynamicProps["aria-expanded"] = "false";
    }
    if (current === id) {
        dynamicProps["aria-current"] = "true";
    } else if (child?.length === 0) {
        dynamicProps["aria-current"] = "false";
    }

    return (
        <li id={id} className={cx({ "child-menu": true, "has-child": child?.length > 0, "open-menu": expanded, disabled: !enabled, active: false })}>
            {!enabled ? (
                <div>
                    <span>{label}</span>
                </div>
            ) : (
                <button type="button" className={cx({ active: current === id })} onClick={onClick?.bind(this, id)} {...dynamicProps}>
                    <span>
                        {label} {level < 3 && <span className="child-menu-num">({device_count})</span>}
                    </span>
                </button>
            )}

            {expanded && child?.length > 0 && (
                <ul role="menu">
                    {child.map((item, i) => (
                        <Branches key={i} {...item} onClick={onClick} current={current} />
                    ))}
                </ul>
            )}
        </li>
    );
};
