import React, { Fragment, useRef, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import moment from "moment";

import _U from "@util/utilities";
import _API from "@util/api";

import useCss from "@hook/useCss";
import { SideMenu, AssignModal, WithdrawModal } from "@component/Admin";
import { SelectBox, InputDatepicker } from "@component/UI/Form";
import { Paging, ToPage, GetParams } from "@component/UI/Paging";
import LastUpdate from "@component/UI/LastUpdate";
import Dialog from "@component/UI/Dialog";
import { AppContext } from "@component/AppProvider";

export default () => {
    const { t } = useTranslation();
    const { auth, workspace, rolePermission, lastUpdateTime } = useContext(AppContext);
    useCss("/assets/css/administration.css");

    const navigate = useNavigate();
    const location = useLocation();

    const [assignModal, setAssignModal] = useState(false);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const [serviceType, setServiceType] = useState("");

    const [sideMenu, setSideMenu] = useState(false);
    const [data, setData] = useState(null);
    const [noServiceType, setNoServiceType] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    const loadServiceType = () => {
        _API.get({ path: "/license/codes/product" })
            .then((res) => {
                const { result } = res.data;
                setServiceType(result.service_type);
                setNoServiceType(true);
            })
            .catch((e) => {
                console.log("api error /license/codes/product", e);
                setServiceType(null);
                setNoServiceType(false);
            });
    };

    const loadData = (data) => {
        _API.get({ path: "/license/workspace", serviceType })
            .then((res) => {
                const { result } = res.data;
                setData(result);
                if (typeof resizeTable !== "undefined") {
                    resizeTable();
                }
            })
            .catch((e) => {
                console.log("api error /license/workspace", e);
                setData({});
            });
    };

    useEffect(() => {
        document.title = t("common.label.WorkspaceLicense") + " | " + t("common.title.lgconnectedcare");
        loadServiceType();
        if (
            auth?.portal_user_roles?.filter(
                (f) => "PlatformAdministrator" === f.common_role_name || (["BusinessAdministrator"].includes(f.common_role_name) && f.business_id === workspace?.business_id)
            )?.length > 0
        ) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
            navigate("/nolicense");
        }
    }, [workspace]);

    useEffect(() => {
        serviceType && loadData();
    }, [serviceType, lastUpdateTime]);

    // useEffect(() => {
    //     workspace && loadData();
    // }, [workspace]);

    const onRefresh = () => {
        setAssignModal(false);
        setWithdrawModal(false);
        loadData();
    };

    const onAssignClick = () => {
        if (!serviceType || !data?.business_site_license?.total_remaining_days || data?.business_site_license?.total_remaining_days <= 0) {
            Dialog({
                title: t("popup.title.inform"),
                html: `${t("common.message.nolicense.assigned")}<br/>${t("common.message.addlicense")}`,
                button: t("common.ok"),
            });
        } else {
            setAssignModal(true);
        }
    };

    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>{t("common.label.administration")}</h1>
                </div>

                <LastUpdate />
            </div>

            <div className="content-middle wide-padding">
                <SideMenu sideMenu={sideMenu} onClose={() => setSideMenu(false)} />

                <div className="content-middle-box">
                    <div className="content-middle-top">
                        <button type="button" className="btn btn-open-child" onClick={() => setSideMenu(true)}>
                            <span className="ir">{t("history.open_sub")}</span>
                        </button>
                        <h2 className="title-content">{t("common.label.WorkspaceLicense")}</h2>
                    </div>

                    <div className="administration-profile">
                        <div className="list-box">
                            <div className="field field-type-input field-flex">
                                <label className="field-label" htmlFor="serviceType">
                                    {t("common.label.ServiceType")}
                                </label>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            id="serviceType"
                                            items={[
                                                { label: t("common.code.serviceType.Standard"), value: "Standard" },
                                                { label: t("common.code.serviceType.DMS"), value: "DMS" },
                                            ]}
                                            value={serviceType}
                                            ariaLabelId={""}
                                            selectLabel={serviceType || "ㅤ"}
                                            onChange={(item) => setServiceType(item?.value)}
                                            disabled={noServiceType}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="details-tit">{t("common.label.BusinessSiteLicense")}</div>
                                <div className="field field-type-input field-flex">
                                    <label htmlFor="total-remaining-days" className="field-label">
                                        {t("common.total")} {t("common.label.RemainingDays")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    id="total-remaining-days"
                                                    name="total-remaining-days"
                                                    value={
                                                        data?.subscribe?.is_subscribe === true
                                                            ? t("common.subscription")
                                                            : `${_U.number3Comma(data?.business_site_license?.total_remaining_days || 0)} Days`
                                                    }
                                                    autoComplete="false"
                                                    readOnly="readOnly"
                                                />
                                            </div>
                                        </div>
                                        <div className="field-box">
                                            <button type="button" className="btn btn-reboot" onClick={() => window.open(process.env.BUSINESS_CLOUD_URL)}>
                                                {t("common.label.GoManageBusinessLicense")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="details-tit">{t("common.label.LicenseDaysWorkspace")}</div>
                                <div className="field field-type-input field-flex">
                                    <label htmlFor="total-remaining-days2" className="field-label">
                                        {t("common.total")} {t("common.label.RemainingDays")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    id="total-remaining-days2"
                                                    name="total-remaining-days2"
                                                    value={
                                                        data?.subscribe?.is_subscribe === true
                                                            ? t("common.subscription")
                                                            : `${_U.number3Comma(data?.license_days_for_workspace?.total_remaining_day || 0)} Days`
                                                    }
                                                    autoComplete="false"
                                                    readOnly="readOnly"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field field-type-input field-flex">
                                    <label htmlFor="daily-deduction" className="field-label">
                                        {t("common.label.DailyDeduction")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    id="daily-deduction"
                                                    name="daily-deduction"
                                                    value={data?.subscribe?.is_subscribe === true ? "-" : `${_U.number3Comma(data?.license_days_for_workspace?.daily_deduction || 0)} Days`}
                                                    autoComplete="false"
                                                    readOnly="readOnly"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field field-type-input field-flex">
                                    <label htmlFor="upcoming-expiration" className="field-label">
                                        {t("common.label.UpcomingExpiration")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    id="upcoming-expiration"
                                                    name="upcoming-expiration"
                                                    value={
                                                        data?.subscribe?.is_subscribe === true
                                                            ? "-"
                                                            : `${
                                                                  data?.license_days_for_workspace?.expire_date ? _U.getOffsetTime(auth, workspace, data?.license_days_for_workspace?.expire_date) : "-"
                                                              } ${_U.getDday(data?.license_days_for_workspace?.expire_date)}`
                                                    }
                                                    autoComplete="false"
                                                    readOnly="readOnly"
                                                />
                                            </div>
                                        </div>

                                        <div className="field-box">
                                            <button type="button" className="btn btn-reboot" onClick={onAssignClick}>
                                                {t("common.label.AssigntoWorkspace")}
                                            </button>
                                            <button type="button" className="btn btn-reboot" onClick={() => setWithdrawModal(true)}>
                                                {t("common.label.WithdrawfromWorkspace")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="details-tit">
                                    {t("common.label.LicenseAssignment")} / {t("common.label.WithdrawalHistory")}
                                </div>
                                <div className="search-and-filter-box">
                                    <div className="filter-box">
                                        <div className="left-side">
                                            <button type="button" className="btn" onClick={() => navigate("/history/license")}>
                                                {t("common.label.ViewAllHistory")}
                                            </button>
                                        </div>

                                        <div className="right-side">
                                            <div className="selected-count">
                                                {t("common.total")} : <span className="count">{data?.history_for_workspace?.propertyDayHistoryList?.length || 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <div className="white-area">
                                        <div className="data-list-container">
                                            <div className="table">
                                                <div className="table-wrapper horizontal-scroll select-mode no-scroll">
                                                    <div className="table-box">
                                                        <table>
                                                            <caption>
                                                                {t("common.label.LicenseAssignment")} / {t("common.label.WithdrawalHistory")}
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("editor.label.date")}</div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("report.label.action")}</div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("common.label.Days")}</div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">{t("common.label.Remark")}</div>
                                                                    </th>
                                                                    <th scope="col">
                                                                        <div className="text-block">
                                                                            {t("common.total")} {t("common.label.RemainingDays")}
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {data?.history_for_workspace?.propertyDayHistoryList?.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item?.date ? _U.getOffsetTime(auth, workspace, item?.date) : ""}</td>
                                                                        <td>{item?.action}</td>
                                                                        <td className={cx({ point: item?.days < 0, "point-blue": item?.days > 0 })}>
                                                                            {item?.days > 0 && "+"}
                                                                            {_U.number3Comma(item?.days)}
                                                                        </td>
                                                                        <td>{item?.remark}</td>
                                                                        <td>{_U.number3Comma(item?.remaining_days)}</td>
                                                                    </tr>
                                                                ))}
                                                                {(!data?.history_for_workspace?.propertyDayHistoryList || data?.history_for_workspace?.propertyDayHistoryList?.length === 0) && (
                                                                    <tr>
                                                                        <td colSpan={5} className="align-center">
                                                                            {t("common.message.nodata.available")}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="convert-table-to-list">
                                                    <div className="table-header">
                                                        <div className="row">
                                                            <div className="thead">{t("editor.label.date")}</div>
                                                        </div>
                                                    </div>
                                                    <ul className="table-body">
                                                        {data?.history_for_workspace?.propertyDayHistoryList?.map((item, index) => (
                                                            <li className="table-row" key={`reflow-${index}`}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell status">
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                                {item?.date ? _U.getOffsetTime(auth, workspace, item?.date) : ""}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li>
                                                                            <span className="field-label">{t("report.label.action")}</span>
                                                                            <span className="field-content">
                                                                                <span className="link-target">{item?.action}</span>
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("common.label.Days")}</span>
                                                                            <span
                                                                                className={cx({
                                                                                    "field-content": true,
                                                                                    point: item?.days < 0,
                                                                                    "point-blue": item?.days > 0,
                                                                                })}
                                                                            >
                                                                                {item?.days > 0 && "+"}
                                                                                {_U.number3Comma(item?.days)}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("common.label.Remark")}</span>
                                                                            <span className="field-content">{item?.remark}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">
                                                                                {t("common.total")} {t("common.label.RemainingDays")}
                                                                            </span>
                                                                            <span className="field-content">{_U.number3Comma(item?.remaining_days)}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        ))}
                                                        {(!data?.history_for_workspace?.propertyDayHistoryList || data?.history_for_workspace?.propertyDayHistoryList?.length === 0) && (
                                                            <li className="table-row">
                                                                <div className="summary-data">
                                                                    <div className="row">{t("common.message.nodata.available")}</div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {assignModal && (
                <AssignModal
                    onClose={onRefresh}
                    days={data?.business_site_license?.total_remaining_days || 0}
                    serviceType={data?.business_site_license?.service_type}
                    productCode={data?.business_site_license?.product_code}
                    headerServiceType={serviceType}
                />
            )}
            {withdrawModal && (
                <WithdrawModal onClose={onRefresh} serviceType={data?.business_site_license?.service_type} productCode={data?.business_site_license?.product_code} headerServiceType={serviceType} />
            )}
        </Fragment>
    );
};
