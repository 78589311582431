import React, { Fragment, useEffect, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import _U from "@util/utilities";
import _API from "@util/api";

import { SideMenu } from "@component/History";
import { AppContext } from "@component/AppProvider";
import Loading from "@component/UI/Loading";
import LastUpdate from "@component/UI/LastUpdate";
import { useTranslation } from "react-i18next";
import Period from "@component/History/Period";
import ViewResultPopup from "@component/History/Action/ViewResultPopup";
import Dialog from "@component/UI/Dialog";

export default () => {
    const {
        historyPeriod: { keyword, filter },
        workspace,
        auth,
        periodStartDate,
        periodEndDate,
    } = useContext(AppContext);
    const { t } = useTranslation();

    const [sideMenu, setSideMenu] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({
        total_count: 0,
        total_pages: 0,
        page: 0,
        items: [],
    });

    const [viewResultField, setViewResultField] = useState({ type: "", action: "", message_id: "" });
    const [showPopup, setShowPopup] = useState(false);

    const onClickResult = (item) => {
        const { type, action, message_id } = item;
        setViewResultField({ type, action, message_id });
        setShowPopup(true);
    };

    const onClickDetail = (item) => {
        setLoading(true);
        const path = "/issue/history/actions/detail";
        _API.get({
            path,
            data: { message_id: item?.message_id },
        })
            .then((res) => {
                if (res?.data?.result?.data) {
                    const data = res?.data?.result?.data[0];
                    Dialog({
                        title: t("report.label.details"),
                        text: data?.state?.detail || "-",
                        button: t("common.ok"),
                    });
                }
            })
            .catch((e) => {
                console.log(`api error /history/actions/detail`, e);
            })
            .finally(() => setLoading(false));
    };

    const getResultName = (item) => {
        if (item) {
            const { result, number_of_devices } = item;
            const resultLowerCase = result?.toLowerCase();
            const numJsx = (
                <button type="button" className="btn" onClick={() => onClickResult(item)}>
                    {t("common.ViewResult")}
                </button>
            );
            const isNumberOfDevices = number_of_devices && number_of_devices > 1;
            switch (resultLowerCase) {
                case "true":
                    if (isNumberOfDevices) return numJsx;
                    return t("common.label.success");
                case "false":
                    if (isNumberOfDevices) return numJsx;
                    return (
                        <a href="#!" className="link-target" onClick={() => onClickDetail(item)}>
                            {t("popup.fail.detail")}
                        </a>
                    );
                default:
                    if (isNumberOfDevices) return numJsx;
                    return resultLowerCase ? (
                        <a href="#!" className="link-target" onClick={() => onClickDetail(item)}>
                            {resultLowerCase.charAt(0).toUpperCase() + resultLowerCase.slice(1)}
                        </a>
                    ) : (
                        ""
                    );
            }
        }
    };

    const onExcelDownload = () => {
        const path = "/issue/history/actions";
        _API.get({
            path,
            data: {
                format: "excel",
                period_start: periodStartDate,
                period_end: periodEndDate,
            },
        }).then((res) => {
            const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            const excel = res?.data;
            const downloadLink = document.createElement("a");
            const fileName = res?.headers["content-disposition"].split("filename=")[1];
            downloadLink.href = mediaType + excel;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
        });
    };

    const fetchList = (isInit = true) => {
        const path = "/issue/history/actions";
        const perPage = 10;
        if (isInit) {
            setLoading(true);
            _API.get({
                path,
                data: {
                    page: 1,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword ? keyword : undefined,
                },
            })
                .then((res) => {
                    let total, resultData;
                    if (res && res.data) {
                        const {
                            result: {
                                summary: { total: summaryTotal },
                            },
                            result: resultObject,
                        } = res.data;

                        total = summaryTotal;
                        resultData = resultObject;
                    }

                    const total_pages = total ? Math.ceil(total / perPage) : 1;

                    setData({
                        ...data,
                        total_count: total || 0,
                        total_pages,
                        page: 1,
                        items: resultData?.data || [],
                    });
                })
                .finally(() => setLoading(false));
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            setLoading(true);
            _API.get({
                path,
                data: {
                    page,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword ? keyword : undefined,
                },
            })
                .then((res) => {
                    let total, resultData;
                    if (res && res.data) {
                        const {
                            result: {
                                summary: { total: summaryTotal },
                            },
                            result: resultObject,
                        } = res.data;

                        total = summaryTotal;
                        resultData = resultObject;
                    }

                    const total_pages = total ? Math.ceil(total / perPage) : 1;

                    setData((prevData) => ({
                        ...prevData,
                        total_count: total || 0,
                        total_pages,
                        page,
                        items: [...prevData.items, ...(resultData?.data || [])],
                    }));
                })
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        fetchList(true);
    }, [workspace]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    return (
        <Fragment>
            {loading && <Loading />}

            <div className="content-top">
                <div className="top-left">
                    <h1>History</h1>
                </div>

                <LastUpdate onRefresh={() => fetchList(true)} />
            </div>
            <div className="content-middle wide-padding">
                <SideMenu sideMenu={sideMenu} onClose={() => setSideMenu(false)} />

                <div className="content-middle-box">
                    <Period
                        title={t("common.label.history.action")}
                        onSearch={() => {
                            fetchList(true);
                        }}
                        total={data?.total_count}
                        filterMenu={"ACTION"}
                        onExcelDownload={onExcelDownload}
                        onOpenSideMenu={() => setSideMenu(true)}
                        searchPlaceholder={t("placeholder.SearchForSerialNumber")}
                    />

                    <div className="inner-content">
                        <div className="white-area">
                            <div className="data-list-container">
                                <div className="table">
                                    <div className="table-wrapper horizontal-scroll select-mode">
                                        <div className="table-box">
                                            <InfiniteScroll
                                                style={{ overflow: "hidden" }}
                                                dataLength={data?.items?.length}
                                                next={() => fetchList(false)}
                                                hasMore={windowWidth > 991}
                                                loader={""}
                                                scrollableTarget="scrollableTbody"
                                            >
                                                <table>
                                                    <caption>{t("common.label.history.action.list")}</caption>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("noticeBoard.label.date")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("devicemanagement.tree.group")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("devicemanagement.title.deviceName")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("common.label.serialnumber")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("common.type")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("report.label.action")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("common.label.NumberOfDevices")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("result.main.title")}</div>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody id="scrollableTbody" className="height-50-rem">
                                                        {data?.items?.map(
                                                            (item, index) =>
                                                                item && (
                                                                    <tr key={index}>
                                                                        <td>{item?.date ? _U.getOffsetTime(auth, workspace, item.date) : ""}</td>
                                                                        <td>{_U.hightlight(item.group_hierarchy, keyword)}</td>
                                                                        <td>{_U.hightlight(item.name, keyword)}</td>
                                                                        <td>{_U.hightlight(item.serial_number, keyword)}</td>
                                                                        <td>{_U.hightlight(item.type, keyword)}</td>
                                                                        <td className="whitespace-pre-line">{_U.hightlight(item.action, keyword)}</td>
                                                                        <td>{_U.hightlight(item.number_of_devices, keyword)}</td>
                                                                        <td>{getResultName(item)}</td>
                                                                    </tr>
                                                                )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </InfiniteScroll>
                                        </div>
                                    </div>

                                    <div className="convert-table-to-list">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="thead">{t("table.head.date")}</div>
                                            </div>
                                        </div>
                                        <InfiniteScroll style={{ overflow: "hidden" }} dataLength={data?.items?.length} next={() => fetchList(false)} hasMore={windowWidth <= 991} loader={""}>
                                            <ul className="table-body">
                                                {data?.items?.map(
                                                    (item, index) =>
                                                        item && (
                                                            <li className="table-row" key={`reflow-${index}`}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell status">
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                                {item?.date ? _U.getOffsetTime(auth, workspace, item.date) : ""}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li>
                                                                            <span className="field-label">{t("table.head.instalcolgroup")}</span>
                                                                            <span className="field-content">{_U.hightlight(item.group?.group_name, keyword)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("table.head.name")}</span>
                                                                            <span className="field-content">{_U.hightlight(item.name, keyword)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("common.label.serialnumber")}</span>
                                                                            <span className="field-content">{_U.hightlight(item.serial_number, keyword)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("common.type")}</span>
                                                                            <span className="field-content">{_U.hightlight(item.type, keyword)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("report.label.action")}</span>
                                                                            <span className="field-content whitespace-pre-line">{_U.hightlight(item.action?.action, keyword)}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && <ViewResultPopup type={viewResultField.type} action={viewResultField.action} message_id={viewResultField.message_id} onClose={() => setShowPopup(false)} />}
        </Fragment>
    );
};
