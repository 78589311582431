import React, { useState, useEffect, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "@component/AppProvider";

const TargetModelnGroup = (props) => {
    const { targetInfo: targetInfoProps } = props;
    const { targetModel, saveTargetModel } = useContext(AppContext);
    const { t } = useTranslation();
    const [targetInfo, setTargetInfo] = useState(targetInfoProps);

    const onTarget = () => {
        saveTargetModel({ ...targetInfo, open: true });
    };

    useEffect(() => {
        console.log("targetInfoProps", targetInfoProps);
        setTargetInfo(targetInfoProps);
    }, [targetInfoProps]);

    useEffect(() => {
        if (targetModel?.model_name || targetModel?.groupList?.length > 0) {
            setTargetInfo({ ...targetInfo, model_name: targetModel?.model_name, groupList: targetModel?.groupList });
            props.onChange && props.onChange({ model_name: targetModel?.model_name, groupList: targetModel?.groupList });
        }
    }, [targetModel]);

    return (
        <div className="field field-type-message required">
            {targetInfo?.headLabel && (
                <div className="field-header">
                    <h2 className="field-title">{targetInfo?.headLabel === "modelNgroup" ? t("common.label.target.modelNgroup") : t("common.label.target.group")}</h2>
                </div>
            )}
            <div className="field-form">
                <div className="message-target-wrap">
                    {!targetInfo?.model_name && (!targetInfo?.groupList || targetInfo?.groupList?.length === 0) && (
                        <div className="empty-txt whitespace-pre-line">
                            {/* There is no target set. <br />
                            Please set a target. */}
                            {t("common.desc.target.select.no")}
                        </div>
                    )}
                    {(targetInfo?.model_name || targetInfo?.groupList?.length > 0) && (
                        <dl className="message-target-info">
                            {targetInfo?.headLabel === "modelNgroup" && (
                                <Fragment>
                                    <dt>{t("common.label.target.selected.model")}</dt>
                                    <dd>{targetInfo?.model_name || "-"}</dd>
                                </Fragment>
                            )}
                            <dt>{t("devicemanagement.tree.group")}</dt>
                            <dd className="message-target">
                                {targetInfo?.groupList?.map((item, index) => {
                                    return (
                                        <div className="message-target-item" key={index}>
                                            {item?.treeName ? item?.treeName?.join(" > ") : item?.hierarchy_group ? item?.hierarchy_group : ""}
                                        </div>
                                    );
                                })}
                            </dd>
                        </dl>
                    )}
                </div>
            </div>
            {targetInfo?.setTarget && (
                <button type="button" className="btn btn-tertiary btn-right-arrow btn-set-target" onClick={() => onTarget()}>
                    {t("common.button.set.target")}
                </button>
            )}
        </div>
    );
};

export default TargetModelnGroup;
