import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _CK from "@util/cookie";

export default () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [temsPrivacy, setTemsPrivacy] = useState("#!");
    const [temsUse, setTemsUse] = useState("#!");

    // const goPage = (url) => {
    //     url && navigate(url);
    // };

    useEffect(() => {
        if (navigator.language === "ko-KR") {
            setTemsPrivacy(
                (process.env.TERMS_URL + "/lgacc/front/v1/footer/terms/retrieveTerms").replace("{local_country}", "kr") +
                    "?menFootId=3&svcId=SVC955&local_country=KR&local_lang=ko&title=" +
                    encodeURIComponent("개인정보 처리방침")
            );
            setTemsUse(
                (process.env.TERMS_URL + "/lgacc/front/v1/footer/terms/retrieveTerms").replace("{local_country}", "kr") +
                    "?menFootId=2&svcId=SVC955&local_country=KR&local_lang=ko&title=" +
                    encodeURIComponent("서비스 이용 약관")
            );
        } else {
            setTemsPrivacy(
                (process.env.TERMS_URL + "/lgacc/front/v1/footer/terms/retrieveTerms").replace("{local_country}", "us") +
                    "?menFootId=3&svcId=SVC955&local_country=US&local_lang=en&title=" +
                    encodeURIComponent("Privacy Policy")
            );
            setTemsUse(
                (process.env.TERMS_URL + "/lgacc/front/v1/footer/terms/retrieveTerms").replace("{local_country}", "us") +
                    "?menFootId=2&svcId=SVC955&local_country=US&local_lang=en&title=" +
                    encodeURIComponent("Terms of Use")
            );
        }
    }, []);

    return (
        <footer className="content-footer">
            <div className="footer_wrap">
                <div className="footer_copy">Copyright ⓒ LG Electronics. All Rights Reserved.</div>
                <ul className="footer_menu">
                    {/* <li>
                        <a href={process.env.BUSINESS_CLOUD_URL} target="_blank" className="footer_menu_item">
                            LG Business Cloud Portal
                            <span className="ir">{t("subtext.desc.new.window")}</span>
                        </a>
                    </li> */}
                    {_CK.get("region") === "aic" && (
                        <li>
                            <a href={process.env.PRICING_URL} target="_blank" className="footer_menu_item">
                                Pricing
                                <span className="ir">{t("subtext.desc.new.window")}</span>
                            </a>
                        </li>
                    )}
                    {/* <li>
                        <a href={process.env.SUPPORT_URL} target="_blank" className="footer_menu_item">
                            Support
                            <span className="ir">{t("subtext.desc.new.window")}</span>
                        </a>
                    </li> */}
                    <li>
                        <a href="/sitemap" className="footer_menu_item">
                            Sitemap
                            <span className="ir">{t("common.label.sitemap")}</span>
                        </a>
                    </li>
                    <li>
                        <a href={temsPrivacy} target="_blank" className="footer_menu_item open">
                            {t("create.privacy.terms")}
                            <span className="ir">{t("subtext.desc.new.window")}</span>
                        </a>
                    </li>
                    <li>
                        <a href={temsUse} target="_blank" className="footer_menu_item open">
                            {t("login.label.termsuse")}
                            <span className="ir">{t("subtext.desc.new.window")}</span>
                        </a>
                    </li>
                    <li>
                        <a href={window.location.origin + "/content/ossnotice"} target="_blank" className="footer_menu_item open">
                            {t("common.label.openSourceNotice")}
                            <span className="ir">{t("subtext.desc.new.window")}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
};
