import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import _API from "@util/api";
import SendMessage from "./SendMessage";
import TemplatePopup from "./TemplatePopup";
import utils from "@util/utilities";
import { SelectBox } from "@component/UI/Form";
import Dialog from "@component/UI/Dialog";
import FocusTrap from "focus-trap-react";
import { AppContext } from "@component/AppProvider";

const SERVICE_TYPE = "schedule";

const AlertMessage = ({ onClose }) => {
    const { t } = useTranslation();
    const {
        rolePermission,
        auth,
        workspace,
        auth: {
            user_info: { datetime_format },
        },
    } = useContext(AppContext);
    const tabRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [loadingImg, setLoadingImg] = useState(false);
    const [mode, setMode] = useState("Message");
    const [modeList, setModeList] = useState(["Message", "Template"]);
    const [messageList, setMessageList] = useState([]);
    const [templateList, setTemplateList] = useState([]);

    const [showSendMsg, setShowSendMsg] = useState(false);
    const [templatePopup, setTemplatePopup] = useState({
        isShow: false,
        mode: "add",
        templateId: null,
    });

    const onTabAreaKeyDown = (e) => {
        if (e.key === "Tab") {
            if (e.shiftKey) {
                e.preventDefault();
                document.getElementById("btn-alert-message-close").focus();
                return;
            }
            const tabList = tabRef.current.querySelectorAll("a");
            const activeTab = document.activeElement;
            const index = Array.from(tabList).indexOf(activeTab);
            const nextTab = tabList[index + 1];
            if (nextTab) {
                nextTab.focus();
            }
        } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    if (e.key === "ArrowLeft") {
                        idx = i - 1;
                    } else if (e.key === "ArrowRight") {
                        idx = i + 1;
                    }
                    return true;
                }
            });
            if (idx >= tabList.length) {
                idx = 0;
            } else if (idx < 0) {
                idx = tabList.length - 1;
            }
            tabList[idx].focus();
        }
    };

    const toggleMessageShow = (index) => {
        setMessageList((prevList) => prevList.map((item, i) => (i === index ? { ...item, isShow: !item.isShow } : item)));
    };

    const deleteMessageItem = (item) => {
        if (!item.hasOwnProperty("id")) return;
        if (!item.hasOwnProperty("fk_group_id")) return;
        Dialog({
            title: t("player.button.stop") + " & " + t("button.delete"),
            text: t("alertmsg.message.delete_ask"),
            button: [t("button.cancel"), t("player.button.stop")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            const { id, fk_group_id } = item;
            console.log({ id, fk_group_id });
            _API.delete({ path: `/content/alert/message/${id}?group_id=${fk_group_id}` }).then(() => {
                fetchMsgList();
            });
        });
    };

    const toggleTemplateShow = (index) => {
        setTemplateList((prevList) => prevList.map((item, i) => (i === index ? { ...item, isShow: !item.isShow } : item)));
    };

    const deleteTemplateItem = (item) => {
        if (!item.hasOwnProperty("id")) return;
        Dialog({
            title: t("button.delete"),
            text: t("common.label.AskDeleteTemplate"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            const { id } = item;
            _API.delete({ path: `/content/alert/template/${id}` }).then(() => {
                fetchTemplateList();
            });
        });
    };

    const toggleTemplatePopup = (isShow, mode, templateId = null) => {
        setTemplatePopup({ ...templatePopup, isShow, mode, templateId });
    };

    const fetchMsgList = async () => {
        setLoading(true);
        _API.get({ path: "/content/alert/message" })
            .then((res) => {
                let { result } = res?.data;
                result?.forEach((v) => {
                    if (!v.hasOwnProperty("isShow")) v.isShow = false;
                });
                setMessageList(result);
            })
            .finally(() => setLoading(false));
    };
    const fetchTemplateList = async () => {
        setLoading(true);
        _API.get({ path: "/content/alert/template" })
            .then((res) => {
                let { result } = res?.data;
                result?.forEach((v) => {
                    if (!v.hasOwnProperty("isShow")) v.isShow = false;
                    if (!v.hasOwnProperty("img_url")) v.img_url = "";
                });
                setTemplateList(result);
                setLoadingImg(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchInitData = async () => {
        await Promise.all([fetchMsgList(), fetchTemplateList()]).finally(() => setLoading(false));
    };

    const loadImgList = async () => {
        const templateListConcat = templateList.concat();
        await templateListConcat.forEach(async (v) => {
            if (v?.image_file) {
                const img_url = await utils.getFileDownloadUrl(v.image_file);
                v.img_url = img_url;
            }
        });
        setTemplateList(templateListConcat);
    };

    useEffect(() => {
        if (loadingImg) {
            setLoadingImg(false);
            loadImgList();
        }
    }, [loadingImg]);

    useEffect(() => {
        fetchInitData();
    }, []);

    useEffect(() => {
        // 모달 팝업 시 background scroll방지
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    return (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
            <div className={`popup-wrapper show`}>
                <div className="popup popup-alert-message" role="dialog" aria-labelledby="popup-label" aria-modal="true">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h1 id="popup-label">{t("popup.title.alertmessage")}</h1>
                        </div>

                        <div className="popup-content">
                            <div className="tablist-content is-tab" role="tabpanel">
                                <div className="tab-group type-default pc-only" role="tablist" ref={tabRef}>
                                    {modeList?.map(
                                        (x, i) =>
                                            x && (
                                                <div key={i} className={`tab ${x === mode && "active"}`} onKeyDown={onTabAreaKeyDown}>
                                                    <a id={`alert-message${i}`} href="#!" role="tab" aria-selected={x === mode} onClick={() => setMode(x)}>
                                                        {x}
                                                    </a>
                                                </div>
                                            )
                                    )}
                                </div>
                                <SelectBox
                                    className="mobile-only"
                                    items={
                                        modeList
                                            ? modeList?.map((x, i) => {
                                                  return { label: x, value: x };
                                              })
                                            : []
                                    }
                                    value={mode}
                                    selectLabel={mode}
                                    onChange={(item) => {
                                        setMode(item.value);
                                    }}
                                />
                                {mode === "Message" && (
                                    <div>
                                        <div className="tab-panel panel-alert-message" role="tabpanel">
                                            <div className="alert-guide-txt">{t("alert.msg.noti")}</div>
                                            <div className="data-state">
                                                <div className="col">
                                                    <button
                                                        type="button"
                                                        className="btn btn-tertiary"
                                                        onClick={() => setShowSendMsg(true)}
                                                        disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                    >
                                                        {t("alertmsg.send.message")}
                                                    </button>
                                                </div>
                                                <div className="col">
                                                    <div className="total-count">
                                                        {t("player.label.total")} <strong>{messageList?.length || 0}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field field-type-table">
                                                <div className="field-form">
                                                    <div className="field-box">
                                                        <div className="table">
                                                            <div className="table-wrapper Message-table">
                                                                <div className="table-box">
                                                                    <table>
                                                                        <caption>{t("message.label.message")}</caption>
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">{t("dg.message.type")}</th>
                                                                                <th scope="col" className="width-12-percent">
                                                                                    {t("table.head.group")}
                                                                                </th>
                                                                                <th scope="col">{t("alertmsg.broadcast.date")}</th>
                                                                                <th scope="col" className="width-12-percent">
                                                                                    {t("player.button.stop")} &amp; {t("button.delete")}
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {messageList?.map(
                                                                                (v, i) =>
                                                                                    v && (
                                                                                        <tr key={i}>
                                                                                            <td id={`al-td1-${i}`}>{v?.title || "-"}</td>
                                                                                            <td id={`al-td2-${i}`} className="width-12-percent">
                                                                                                {v?.group_name || "-"}
                                                                                            </td>
                                                                                            <td id={`al-td3-${i}`}>{v?.created_at ? utils.getOffsetTime(auth, workspace, v?.created_at) : ""}</td>
                                                                                            <td className="center width-12-percent">
                                                                                                <button
                                                                                                    aria-describedby={`al-td1-${i} al-td2-${i} al-td3-${i}`}
                                                                                                    type="button"
                                                                                                    className="btn btn-data-delete"
                                                                                                    onClick={() => deleteMessageItem(v)}
                                                                                                    disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                                                >
                                                                                                    {t("alertmsg.stop.delete")}
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                            {/* 모바일 */}
                                                            <div className="convert-table-to-list">
                                                                <div className="table-header">
                                                                    <div className="row">
                                                                        <div className="thead">Message Type</div>
                                                                    </div>
                                                                </div>
                                                                <ul className="table-body">
                                                                    {messageList?.map(
                                                                        (v, i) =>
                                                                            v && (
                                                                                <li className={`table-row ${v.isShow ? "expand" : ""}`} key={i}>
                                                                                    <div className="summary-data" onClick={() => toggleMessageShow(i)}>
                                                                                        <div className="row">
                                                                                            <div className="table-cell alias">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    role="listbox"
                                                                                                    className="btn btn-expand"
                                                                                                    aria-expanded={v?.isShow ? "true" : "false"}
                                                                                                >
                                                                                                    {v?.title || ""}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="all-data-box">
                                                                                        <ul className="all-data">
                                                                                            <li id={`al-li1-${i}`}>
                                                                                                <span className="field-label">{t("table.head.group")}</span>
                                                                                                <div className="field-content">{v?.group_name || "-"}</div>
                                                                                            </li>
                                                                                            <li id={`al-li2-${i}`}>
                                                                                                <span className="field-label">{t("alertmsg.broadcast.date")}</span>
                                                                                                <span className="field-content">
                                                                                                    {v?.created_at ? utils.getOffsetTime(auth, workspace, v?.created_at) : ""}
                                                                                                </span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span className="field-label">
                                                                                                    {t("player.button.stop")} & {t("button.delete")}
                                                                                                </span>
                                                                                                <span className="field-content">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-data-delete"
                                                                                                        onClick={() => deleteMessageItem(v)}
                                                                                                        aria-describedby={`al-li1-${i} al-li2-${i}`}
                                                                                                        disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                                                    >
                                                                                                        {t("alertmsg.stop.delete")}
                                                                                                    </button>
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {mode === "Template" && (
                                    <div>
                                        <div className="tab-panel panel-alert-template" role="tabpanel">
                                            <div className="data-state">
                                                <div className="col">
                                                    <button
                                                        type="button"
                                                        className="btn btn-tertiary"
                                                        onClick={() => toggleTemplatePopup(true, "add")}
                                                        disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                    >
                                                        {t("alertmsg.template.add")}
                                                    </button>
                                                </div>
                                                <div className="col">
                                                    <div className="total-count">
                                                        {t("player.label.total")} <strong>{templateList?.length || 0}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field field-type-table">
                                                <div className="field-form">
                                                    <div className="field-box">
                                                        <div className="table">
                                                            <div className="table-wrapper Template-table">
                                                                <div className="table-box">
                                                                    <table>
                                                                        <caption>{t("alertmsg.template")}</caption>
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">{t("commom.popup.title")}</th>
                                                                                <th scope="col">{t("schedule.commend.set_volume")}</th>
                                                                                <th scope="col">{t("alertmsg.template.sound_name")}</th>
                                                                                <th scope="col">{t("alertmsg.template.image_name")}</th>
                                                                                <th scope="col" className="width-12-percent">
                                                                                    {t("common.edit")}
                                                                                </th>
                                                                                <th scope="col" className="width-12-percent">
                                                                                    {t("button.delete")}
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {templateList?.map(
                                                                                (x, i) =>
                                                                                    x && (
                                                                                        <tr key={i}>
                                                                                            <td id={`tp1-${i}`}>{x?.title || "-"}</td>
                                                                                            <td id={`tp2-${i}`}>{x?.sound_volume || 0}</td>
                                                                                            <td id={`tp3-${i}`}>{utils.getFilename(x?.sound_file) || "-"}</td>
                                                                                            <td id={`tp4-${i}`}>{utils.getFilename(x?.image_file) || "-"}</td>
                                                                                            <td className="center width-12-percent">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-data-edit"
                                                                                                    onClick={() => toggleTemplatePopup(true, "edit", x.id)}
                                                                                                    aria-describedby={`tp1-${i} tp2-${i} tp3-${i} tp4-${i}`}
                                                                                                    disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                                                >
                                                                                                    {t("common.edit")}
                                                                                                </button>
                                                                                            </td>
                                                                                            <td className="center width-12-percent">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-data-delete"
                                                                                                    onClick={() => deleteTemplateItem(x)}
                                                                                                    aria-describedby={`tp1-${i} tp2-${i} tp3-${i} tp4-${i}`}
                                                                                                    disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                                                >
                                                                                                    {t("button.delete")}
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                            {/* 모바일 */}
                                                            <div className="convert-table-to-list">
                                                                <div className="table-header">
                                                                    <div className="row">
                                                                        <div className="thead">{t("form.label.title")}</div>
                                                                    </div>
                                                                </div>
                                                                <ul className="table-body">
                                                                    {templateList?.map(
                                                                        (v, i) =>
                                                                            v && (
                                                                                <li className={`table-row ${v?.isShow ? "expand" : ""}`} key={i}>
                                                                                    <div className="summary-data" onClick={() => toggleTemplateShow(i)}>
                                                                                        <div className="row">
                                                                                            <div className="table-cell alias">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    role="listbox"
                                                                                                    className="btn btn-expand"
                                                                                                    aria-expanded={v?.isShow ? "true" : "false"}
                                                                                                >
                                                                                                    {v?.title || ""}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="all-data-box">
                                                                                        <ul className="all-data">
                                                                                            <li id={`tp-li1-${i}`}>
                                                                                                <span className="field-label">{t("player.label.volume")}</span>
                                                                                                <div className="field-content">{v?.sound_volume || 0}</div>
                                                                                            </li>
                                                                                            <li id={`tp-li2-${i}`}>
                                                                                                <span className="field-label">{t("alertmsg.template.sound_name")}</span>
                                                                                                <span className="field-content">{utils.getFilename(v?.sound_file) || "-"}</span>
                                                                                            </li>
                                                                                            <li id={`tp-li3-${i}`}>
                                                                                                <span className="field-label">{t("alertmsg.template.image_name")}</span>
                                                                                                <span className="field-content">{utils.getFilename(v?.image_file) || "-"}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span className="field-label">{t("common.edit")}</span>
                                                                                                <span className="field-content">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-data-edit"
                                                                                                        onClick={() => toggleTemplatePopup(true, "edit", v?.id)}
                                                                                                        aria-describedby={`tp-li1-${i} tp-li2-${i} tp-li3-${i}`}
                                                                                                        disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                                                    >
                                                                                                        {t("common.edit")}
                                                                                                    </button>
                                                                                                </span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span className="field-label">{t("button.delete")}</span>
                                                                                                <span className="field-content">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-data-delete"
                                                                                                        onClick={() => deleteTemplateItem(v)}
                                                                                                        aria-describedby={`tp-li1-${i} tp-li2-${i} tp-li3-${i}`}
                                                                                                        disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                                                    >
                                                                                                        {t("button.delete")}
                                                                                                    </button>
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="popup-footer">
                            <div className="button-box">
                                <button type="button" id="btn-alert-message-close" className="btn btn-huge btn-tertiary" onClick={onClose}>
                                    {t("button.close")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {showSendMsg && (
                    <SendMessage
                        changeShowSendMsg={(state) => setShowSendMsg(state)}
                        onMessageRegister={() => {
                            setShowSendMsg(false);
                            fetchMsgList();
                        }}
                        list={templateList}
                    />
                )}
                {templatePopup.isShow && (
                    <TemplatePopup
                        mode={templatePopup.mode}
                        onClose={() => toggleTemplatePopup(false)}
                        onClick={() => {
                            toggleTemplatePopup(false);
                            fetchTemplateList();
                        }}
                        templateId={templatePopup.templateId}
                        totalCount={templateList?.length || 0}
                    />
                )}
            </div>
        </FocusTrap>
    );
};

export default AlertMessage;
