import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";

import _U from "@util/utilities";
import _CK from "@util/cookie";
import _API from "@util/api";
import { AppContext } from "@component/AppProvider";

export default (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { auth, rolePermission, workspace, saveLastUpdateTime } = useContext(AppContext);
    const [install, setInstall] = useState(false);
    const [updateTime, setUpdateTime] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const businessInit = () => {
        // _API.get({ path: "/business/init", data: {} })
        //     .then((res) => {
        //         const time = _U.getOffsetTime(auth, workspace, moment().valueOf());
        //         // moment().format("YYYY. MM. DD HH:mm:ss");
        //         _CK.set("headLastUpdateTime", time);
        //         setUpdateTime(time);
        //     })
        //     .catch((e) => {
        //         console.log("api error /business/init", e);
        //     });
        const time = _U.getOffsetTime(auth, workspace, moment().valueOf());
        setUpdateTime(time);
        saveLastUpdateTime(moment().valueOf());
        props.onRefresh && props.onRefresh();
    };

    useEffect(() => {
        // setUpdateTime(_CK.get("headLastUpdateTime") || _U.getOffsetTime(auth, workspace, moment().valueOf()));
        setUpdateTime(_U.getOffsetTime(auth, workspace, moment().valueOf()));
        if (
            auth?.portal_user_roles?.filter(
                (f) => "PlatformAdministrator" === f.common_role_name || (["BusinessAdministrator", "PropertyAdministrator"].includes(f.common_role_name) && f.business_id === workspace?.business_id)
            )?.length > 0
        ) {
            setIsAdmin(true);
        }
    }, [location, auth, workspace]);

    return (
        <Fragment>
            <div className="last-update">
                <div id="updateTimeLabel">
                    <span className="field-label">{t("device.label.lastupdatetime")}: </span>
                    <span className="field-content">{updateTime}</span>
                </div>
                <button className="btn-update" aria-label="update data" aria-describedby="updateTimeLabel" onClick={businessInit}></button>
                {/* <p id="updated-time" className="hide">
                    {t("subtext.label.lastupdatetime", { updateTime })}
                </p> */}

                {props?.installationCode && isAdmin && (
                    <Fragment>
                        <div className="tooltip-container">
                            <button className="btn btn-installation-code" onClick={() => setInstall(true)}>
                                {t("common.label.installationcode")}
                                <div className="tooltip-content">{t("subtext.tooltip.installationcode")}</div>
                            </button>
                        </div>

                        {install && <InstallationCode onClose={(e) => setInstall(false)} />}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

const InstallationCode = (props) => {
    const { t } = useTranslation();
    const { rolePermission, saveInnerLoading } = useContext(AppContext);
    const [data, setData] = useState({});
    const [expiredAt, setExpiredAt] = useState(null);
    const areaRef = useRef(null);

    const getData = (refresh) => {
        saveInnerLoading(true);
        _API.get({ path: "/devices/codes", data: { refresh } })
            .then((res) => {
                setData(res?.data?.result);
                if (res?.data?.result?.expired_at) {
                    setExpiredAt(res?.data?.result?.expired_at - moment().unix());
                }
                // console.log(new Date(res?.data?.result?.expired_at));
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log("api error /devices/codes", e);
                saveInnerLoading(false);
            });
    };

    const Refrech = () => {
        getData(true);
    };

    const Discard = () => {
        saveInnerLoading(true);
        _API.delete({ path: `/devices/codes/${data?.code}`, data: {} })
            .then((res) => {
                props.onClose && props.onClose();
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log("api error /devices/codes", e);
                props.onClose && props.onClose();
                saveInnerLoading(false);
            });
    };

    useEffect(() => {
        getData(false);
    }, []);

    const getRemainTime = () => {
        if (expiredAt) {
            const hour = Math.floor(expiredAt / 3600);
            const min = Math.floor((expiredAt - hour * 3600) / 60);
            const sec = expiredAt - hour * 3600 - min * 60;
            return (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
        }
        return "";
    };
    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(expiredAt) >= 0) {
                setExpiredAt(parseInt(expiredAt) - 1);
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [expiredAt]);

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                props.onClose && props.onClose();
            }
        }
        document.addEventListener("mousedown", handleOutside);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
        };
    }, [areaRef]);

    return (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
            <div className="installation-code" ref={areaRef} role="dialog" aria-modal="true" aria-label={t("common.label.installationcode")}>
                <div className="code-time">{getRemainTime()}</div>
                <div className="code-num">
                    <span>{data?.code}</span>
                    <button type="button" className="btn btn-refresh" onClick={Refrech} disabled={_U.getAuthWriteDisable(rolePermission)}>
                        <span className="ir">{t("player.button.refresh")}</span>
                    </button>
                </div>
                <div className="btn-box">
                    <button type="button" className="btn" onClick={Discard} disabled={_U.getAuthWriteDisable(rolePermission)}>
                        {t("common.button.discard")}
                    </button>
                </div>
                <button type="button" className="btn btn-close" onClick={props.onClose}>
                    {t("button.close")}
                </button>
            </div>
        </FocusTrap>
    );
};
