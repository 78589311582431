import React, { useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import _U from "@util/utilities";
import Layout from "@layout/Main";
import _CK from "@util/cookie";
import _API from "@util/api";
import { AppContext } from "@component/AppProvider";
import Loading from "@component/UI/Loading";
import SitemapPage from "./pages/sitemap";
import { GuideTablePage, GuideDepthPage, GuidePopupPage } from "./pages/guide";
import { DeviceListPage, DeviceDetailPage } from "./pages/device";
import { HistoryIssuePage, HistoryActionPage, HistoryAccessPage, HistoryLicensePage } from "./pages/history";
import { ReportListPage } from "./pages/report";
import { AdminProfilePage, AdminDevicePage, AdminRolePage, AdminAgentPage } from "./pages/admin";
import { DashboardDevices, DashboardEnergy } from "./pages/dashboard";
import { ScheduleAppManagement, ScheduleBroadcast, ScheduleCommand } from "./pages/schedule";
import QRRegist from "./pages/mobile/QRRegist";
import NoLicense from "./pages/license/nolicense";
import WebSocket from "@component/Socket";
import Dialog from "@component/UI/Dialog";
import OSSNotice from "./pages/content/OSSNotice";
// import Test from "./pages/test/test";

export default () => {
    const { auth, saveAuth, saveBusinessList, workspace, saveWorkspace, saveProduct, saveRolePermission } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const exceptRoleList = ["redirect", "nolicense", "qr_regist", "administration/license", "/content/ossnotice"];
    const [oriWorkSpace, setOriWorkSpace] = useState(null);
    const { t } = useTranslation();
    const [noAccess, setNoAccess] = useState(false);

    const getUserMe = async () => {
        try {
            const res = await _API.get({ path: "/user/users/me", data: {} });
            if (res?.data?.result) {
                saveAuth(res?.data?.result);
                return res?.data?.result;
            }
        } catch (e) {
            console.log("api error /user/users/me", e);
            if (["00-003", "00-002"].includes(e?.response?.data?.status?.code)) {
                return "00-003";
            }
        }
        return null;
    };

    const onLoadBusinessList = async () => {
        let myWorkspace = null;
        try {
            const res = await _API.get({ path: "/business/sites", data: {} });
            if (res?.data?.result) {
                saveBusinessList(res?.data?.result);
                if (window.location.pathname === "/redirect") {
                    const params = new URLSearchParams(window.location.search);
                    if (params.size > 0) {
                        const businessId = params.get("business_id");
                        const propertyId = params.get("property_id");
                        const path = params.get("path");
                        if (businessId && !propertyId) {
                            //business 오고 property 없을때
                            const businessInfo = res?.data?.result.filter((f) => f.business_id === businessId)[0];
                            const propertyInfo = businessInfo?.properties[0];
                            if (propertyInfo) {
                                myWorkspace = { ...propertyInfo, business_id: businessId };
                                setOriWorkSpace(myWorkspace);
                                saveWorkspace(myWorkspace);
                                _CK.set("business-id", businessId, { encode: (v) => v });
                                _CK.set("property-id", propertyInfo.property_id, { encode: (v) => v });
                            } else {
                                // 해당 business에 property 없을때 프로퍼티 생성 필요 alert
                                alert("워크스페이스가 없습니다.");
                                myWorkspace = settingWorkSpace(res?.data?.result); // TODO 임시로 설정
                            }
                        } else if (businessId && propertyId) {
                            // 둘다 넘어 올때
                            const businessInfo = res?.data?.result.filter((f) => f.business_id === businessId)[0];
                            const propertyInfo = businessInfo.properties?.filter((f) => f.property_id === propertyId)[0];
                            if (propertyInfo) {
                                myWorkspace = { ...propertyInfo, business_id: businessId };
                                setOriWorkSpace(myWorkspace);
                                saveWorkspace(myWorkspace);
                                _CK.set("business-id", businessId, { encode: (v) => v });
                                _CK.set("property-id", propertyInfo.property_id, { encode: (v) => v });
                            } else {
                                // param으로 넘어온 business와 property 없을때
                                alert("잘못된 비지니스 및 워크스페이스 호출");
                                myWorkspace = settingWorkSpace(res?.data?.result); // TODO 임시로 설정
                            }
                        } else {
                            //business정보 안넘어 올때 기본 설정
                            myWorkspace = settingWorkSpace(res?.data?.result);
                        }
                        if (path) {
                            location.href = decodeURIComponent(path);
                        }
                    } else {
                        location.href = "/";
                        return;
                    }
                } else {
                    myWorkspace = settingWorkSpace(res?.data?.result);
                }
            } else {
                // error 비지니스가 아예 없는 경우
            }
        } catch (e) {
            console.log("api error /business/sites", e);
        }
        return myWorkspace;
    };

    const settingWorkSpace = (data) => {
        if (_CK.get("property-id") && _CK.get("property-id") != "undefined") {
            let newWorkSpace = null;
            data?.forEach((f) => {
                f?.properties?.forEach((p) => {
                    if (p?.property_id == _CK.get("property-id")) {
                        newWorkSpace = { ...p, business_id: f.business_id };
                    }
                });
            });
            setOriWorkSpace(newWorkSpace);
            saveWorkspace(newWorkSpace);
            return newWorkSpace;
        } else if (data[0] && data[0]?.properties && data[0]?.properties[0]) {
            setOriWorkSpace({ ...data[0]?.properties[0], business_id: data[0]?.business_id });
            saveWorkspace({ ...data[0]?.properties[0], business_id: data[0]?.business_id });
            _CK.set("business-id", data[0]?.business_id, { encode: (v) => v });
            _CK.set("property-id", data[0]?.properties[0]?.property_id, { encode: (v) => v });
            return { ...data[0]?.properties[0], business_id: data[0]?.business_id };
        }
    };

    const getExpireDate = async (myAuth) => {
        try {
            const res = await _API.get({
                path: `/user/auth/check`,
                data: { refresh: false, userId: myAuth?.user_id },
            });
            const { status, result } = res.data;
            //
            if (status.code === "00-000") {
                _CK.set("DXPID-EXPIRE", result.expired_at, { encode: (v) => v });
            }
        } catch (e) {
            console.log("api error /user/auth/check", e);
        }
    };

    const businessInit = async (myAuth, myWorkspace) => {
        try {
            const res = await _API.get({ path: "/business/init", data: {} });
            const { status, result } = res.data;
            const time = _U.getOffsetTime(myAuth, myWorkspace, moment().valueOf());
            _CK.set("headLastUpdateTime", time);
        } catch (e) {
            console.log("api error /business/init", e);
        }
    };

    const getProduct = async () => {
        // property 별 라이센스
        try {
            const res = await _API.get({ path: "/license/codes/product", data: {} });
            if (res?.data?.result) {
                saveProduct(res?.data?.result);
                return res?.data?.result;
            }
            return null;
        } catch (e) {
            console.log("api error /business/sites", e);
            if (e?.response?.data?.status?.code === "03-001") {
                saveProduct(null);
                // location.href = "/nolicense";
            }
            return null;
        }
    };

    const getMyRole = async (myWorkspace, myAuth, myProduct) => {
        try {
            const res = await _API.get({ path: "/user/roles/property/myrole", data: { "property-id": myWorkspace?.property_id } });
            const rolePermission = res?.data?.result?.role_permission;
            if (rolePermission) {
                saveRolePermission(rolePermission);
                if (!exceptRoleList.includes(window.location.pathname.replace("/", ""))) {
                    if (
                        myAuth &&
                        myAuth?.portal_user_roles?.filter(
                            (f) =>
                                "PlatformAdministrator" === f.common_role_name ||
                                (["BusinessAdministrator", "PropertyAdministrator"].includes(f.common_role_name) && f.business_id === myWorkspace?.business_id)
                        )?.length > 0
                    ) {
                    } else {
                        if (window.location.pathname.split("/")?.length > 1) {
                            let checkRole = window.location.pathname.split("/")[1];
                            if (!checkRole) {
                                checkRole = "dashboard";
                            }
                            if (!exceptRoleList.includes(checkRole) && (!rolePermission[checkRole] || rolePermission[checkRole] < 1)) {
                                // saveRolePermission(null);
                                location.href = "/nolicense";
                            }
                        }
                    }
                }
            } else {
                saveRolePermission(null);
                location.href = "/nolicense";
            }
        } catch (e) {
            console.log("api error /user/users/me", e);
            if (e?.response?.data?.status?.code === "00-006") {
                saveRolePermission(null);
                if (window.location.pathname.split("/")?.length > 1) {
                    let checkRole = window.location.pathname.split("/")[1];
                    if (!checkRole) {
                        checkRole = "dashboard";
                    }
                    if (!exceptRoleList.includes(checkRole)) {
                        // saveRolePermission(null);
                        location.href = "/nolicense";
                    }
                }
            }
        }
        if (!myProduct && !exceptRoleList.includes(window.location.pathname.replace("/", ""))) {
            location.href = "/nolicense";
        }
    };

    const init = async () => {
        setLoading(true);
        if (!["/content/ossnotice"].includes(window.location.pathname)) {
            if (process.env.STAGE === "local" && (!_CK.get("DXPID") || _CK.get("DXPID") === "undefined")) {
                _CK.set(
                    "DXPID",
                    "LGJS1K6hfSSVM1WxxiC11i7wPOWj6k5JPXo8s5ZCIFVkvoPqNPMGkyUoIYPf3nX-fdmnohdNzgAIkv4azBJkPSeTgvQGgnUcxBxWqDNL3YWVzfM0Zdtj-KCaS393BLWFbjgKaR_L2OeTfrfayDxoShPXCHxeDfQvOrCaqw6__FM44RvH6IyolDW5c4uaeyng7CD0-VBBFYe029SM48PLWLYOFhuoJFeBce__6fpi1orGA1psxajdyj-WrngWZEiBnFK2uLQg8xSRyFNEikVwzw==",
                    { encode: (v) => v }
                );
            }

            const myWorkspace = await onLoadBusinessList();
            const myAuth = await getUserMe();
            if (["00-003", "00-002"].includes(myAuth)) {
                setNoAccess(true);
                Dialog({ title: t("popup.title.inform"), text: t("alertmsg.popup.session.expire"), button: t("common.ok") }).then((id) => {
                    if (process.env.STAGE !== "local") {
                        if (window.opener) window.close();
                        else location.href = process.env.BUSINESS_CLOUD_URL;
                    }
                });
            } else {
                const myProduct = await getProduct();
                await getMyRole(myWorkspace, myAuth, myProduct);
                await getExpireDate(myAuth);
                await businessInit(myAuth, myWorkspace);
                _API.onAccessLog("login");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params && params?.size > 0) {
            const region = params.get("region");
            const country = params.get("country");
            const language = params.get("language");
            region && _CK.set("region", region);
            country && _CK.set("country", country);
            language && _CK.set("language", language);
        }
    }, [window.location.pathname]);

    useEffect(async () => {
        await init();
    }, []);

    useEffect(async () => {
        // if (workspace && oriWorkSpace && JSON.stringify(workspace) != JSON.stringify(oriWorkSpace) && !exceptRoleList.includes(window.location.pathname.replace("/", ""))) {
        //     await getProduct();
        //     await getMyRole(workspace);
        // }
        if (workspace && oriWorkSpace && JSON.stringify(workspace) != JSON.stringify(oriWorkSpace)) {
            location.href = "/";
        }
    }, [workspace]);

    if (noAccess) {
        //초기 접속시 권한이 아예 없을때
        return "";
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <Router>
            {!["/qr_regist", "/content/ossnotice"].includes(window.location.pathname) ? (
                <Layout>
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard/devices" replace />} exact />
                        <Route path="/sitemap" element={<SitemapPage />} />
                        <Route path="/guide/table" element={<GuideTablePage />} />
                        <Route path="/guide/depth" element={<GuideDepthPage />} />
                        <Route path="/guide/popup" element={<GuidePopupPage />} />
                        <Route path="/devices/list" element={<DeviceListPage />} />
                        <Route path="/devices/detail/:idx" element={<DeviceDetailPage />} />

                        <Route path="/history/issue" element={<HistoryIssuePage />} />
                        <Route path="/history/action" element={<HistoryActionPage />} />
                        <Route path="/history/access" element={<HistoryAccessPage />} />
                        <Route path="/history/license" element={<HistoryLicensePage />} />

                        <Route path={`/report/list`} element={<ReportListPage />} />
                        <Route path={`/administration/profile`} element={<AdminProfilePage />} />
                        <Route path={`/administration/license`} element={<AdminDevicePage />} />
                        <Route path={`/administration/role`} element={<AdminRolePage />} />
                        <Route path={`/administration/agent`} element={<AdminAgentPage />} />

                        <Route path="/dashboard/devices" element={<DashboardDevices />} />
                        <Route path="/dashboard/energy" element={<DashboardEnergy />} />

                        <Route path="/schedule/broadcast" element={<ScheduleBroadcast />} />
                        <Route path="/schedule/command" element={<ScheduleCommand />} />
                        <Route path="/schedule/app-management" element={<ScheduleAppManagement />} />
                        <Route path="/nolicense" element={<NoLicense />} />
                        <Route path="*" element={<Navigate to="/dashboard/devices" replace />} />
                        {/* <Route path="/llulker" element={<Test />} /> */}
                    </Routes>
                </Layout>
            ) : (
                <Routes>
                    <Route path="/qr_regist" element={<QRRegist />} />
                    <Route path="/content/ossnotice" element={<OSSNotice />} />
                </Routes>
            )}
            {auth && <WebSocket auth={auth} workspace={workspace} />}
        </Router>
    );
};
