import useIsMobile from "@hook/useIsMobile";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LastUpdate from "@component/UI/LastUpdate";
import { useTranslation } from "react-i18next";

const ScheduleWrapper = ({ children, onRefresh }) => {
    const { t } = useTranslation();
    const tabRef = useRef(null);

    const [menuList, setMenuList] = useState([
        { title: t("subtext.table.caption.BroadcastList"), name: t("popup.title.broadcast"), path: "/schedule/broadcast" },
        { title: t("common.reserved_command"), name: t("device.label.command"), path: "/schedule/command" },
        {
            title: t("admin.label.appmanagement"),
            name: t("admin.label.appmanagement"),
            path: "/schedule/app-management",
        },
    ]);
    const [isOpenLeft, setIsOpenLeft] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useIsMobile();

    const getSideGroupListStyle = () => {
        if (!isMobile) {
            return "left-3-2-rem";
        } else if (isOpenLeft) {
            return "left-0-style";
        } else {
            return "left--100-percent";
        }
    };

    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowDown" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    return (
        <div className="schedule">
            <div className="content-top">
                <div className="top-left">
                    <h1>{t("common.schedule")}</h1>
                </div>
                <LastUpdate onRefresh={onRefresh} />
            </div>
            <div className="content-middle wide-padding">
                <div className={`site-group-list-container ${getSideGroupListStyle()}`}>
                    <div className="header header-site">
                        <button type="button" className="btn btn-back" onClick={() => setIsOpenLeft(false)}>
                            <span className="ir">{t("subtext.menu.collapse")}</span>
                        </button>
                        <div className="title">{t("player.label.physical")}</div>
                    </div>

                    <div className="child-menu-container">
                        <div className="category-menu">
                            <ul role="menulist" aria-orientation="vertical" ref={tabRef}>
                                {menuList &&
                                    menuList?.map(
                                        (x, i) =>
                                            x && (
                                                <li className={location.pathname === x.path ? "active" : ""} key={i} role="none presentation">
                                                    <a
                                                        href={x.path}
                                                        aria-current={location.path === x.path ? "true" : "false"}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate(x.path);
                                                        }}
                                                        // onKeyDown={onTabAreaKeyDown}
                                                        // tabIndex={location.pathname === x.path ? 0 : -1}
                                                    >
                                                        {x.name}
                                                    </a>
                                                </li>
                                            )
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content-middle-box">
                    <div className="content-middle-top">
                        <button type="button" className="btn btn-open-child" onClick={() => setIsOpenLeft(true)}>
                            <span className="ir">{t("history.open_sub")}</span>
                        </button>
                        <h2 className="title-content">{menuList ? menuList?.find((x) => x.path === location.pathname).title : ""}</h2>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ScheduleWrapper;
