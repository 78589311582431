import React, { Fragment, useContext, useEffect, useState } from "react";
import cx from "classnames";
import { AppContext } from "@component/AppProvider";
import { useTranslation } from "react-i18next";
import Dialog from "@component/UI/Dialog";

const Filter = ({ menu, onSetFilter, onClose, show }) => {
    const { t } = useTranslation();
    const { historyPeriod, setHistoryPeriod } = useContext(AppContext);

    const [apply, setApply] = useState(false);
    const [selectList, setSelectList] = useState({
        os_type: [],
        issue_code: [],
        device_type: [],
        type: [],
        os: [],
        browser: [],
    });
    const [filterList, setFilterList] = useState({
        os: [
            { label: t("dv.device.webOS"), value: "webos" },
            { label: t("dv.device.windows"), value: "windows" },
            { label: t("dv.device.android"), value: "android" },
        ],
        deviceType: [
            { label: t("common.code.device.type.signage"), value: "signage" },
            { label: t("common.code.device.type.led"), value: "led" },
            { label: t("common.code.device.type.mp"), value: "mp" },
            { label: t("common.code.device.type.idb"), value: "idb" },
            { label: t("common.code.device.type.signagetv"), value: "signagetv" },
            { label: t("common.code.device.type.display"), value: "display" },
        ],
        issueCode: [
            { label: "Temperature", value: "TEM" },
            { label: "FAN", value: "FAN" },
            { label: "Signal", value: "SGN" },
            { label: "No Signal Power Off (15Min)", value: "NSP" },
            { label: "No IR Power Off (4hour)", value: "NIP" },
            { label: "Ethernet", value: "ETH" },
            { label: "Power", value: "PWR" },
            { label: "Power", value: "PWR" },
            { label: "Cell/Line Defect Detection", value: "CLD" },
            { label: "Humidity", value: "HUM" },
            { label: "Door", value: "DRO" },
            { label: "Panel (BLU)", value: "BLU" },
            { label: "Pixel", value: "PXL" },
            { label: "Filter alarm", value: "FLT" },
            { label: "Impact", value: "IMP" },
            { label: "Volume", value: "VOL" },
            { label: "Signal Check", value: "AUD" },
        ],
        actionType: [
            { label: "Command", value: "COMMAND" },
            { label: "RS232", value: "RS232" },
        ],
        actionOs: [
            { label: "Linux", value: "Linux" },
            { label: "MacOS", value: "MacOS" },
            { label: "UNIX", value: "UNIX" },
            { label: "Windows", value: "Windows" },
        ],
        browser: [
            { label: "Internet Explorer 6", value: "Internet Explorer 6" },
            { label: "Internet Explorer 7", value: "Internet Explorer 7" },
            { label: "Internet Explorer 8", value: "Internet Explorer 8" },
            { label: "Internet Explorer 9", value: "Internet Explorer 9" },
            { label: "Internet Explorer 10", value: "Internet Explorer 10" },
            { label: "Internet Explorer 11", value: "Internet Explorer 11" },
            { label: "Firefox", value: "Firefox" },
            { label: "Opera", value: "Opera" },
            { label: "Microsoft Edge", value: "Microsoft Edge" },
            { label: "Chrome", value: "Chrome" },
            { label: "Safari", value: "Safari" },
        ],
        licenseType: [
            { label: "Assigned", value: "assigned" },
            { label: "Withdrawn", value: "withdrawn" },
            { label: "Deducted", value: "deducted" },
        ],
    });

    const onChangeCheck = (key, value) => {
        const tmp = JSON.parse(JSON.stringify(selectList[key]));
        if (tmp.includes(value)) {
            tmp.splice(tmp.indexOf(value), 1);
        } else {
            tmp.push(value);
        }
        setSelectList((prevState) => ({ ...prevState, [key]: tmp }));
    };

    const onReset = () => {
        Dialog({
            title: t("main.settingsPage.reset"),
            text: t("model.popup.resetDetail"),
            button: [t("button.cancel"), t("main.settingsPage.reset")],
        }).then((ok) => {
            if (ok) {
                const updatedList = Object.fromEntries(Object.entries(selectList).map(([key, _]) => [key, []]));
                setSelectList(updatedList);
                onClose();
            }
        });
    };

    const onApply = async () => {
        const isEmpty = Object.values(selectList).every((value) => value.length === 0);
        if (isEmpty) {
            await setHistoryPeriod({
                ...historyPeriod,
                filter: null,
            });
        } else {
            const filteredList = Object.fromEntries(Object.entries(selectList).filter(([_, value]) => value.length > 0));
            await setHistoryPeriod({
                ...historyPeriod,
                filter: JSON.stringify(filteredList),
            });
        }
        setApply(true);
        setTimeout(() => {
            setApply(false);
        }, 100);
    };

    const clickClose = () => {
        const isEmpty = Object.values(selectList).every((value) => value.length === 0);
        if (isEmpty) {
            onClose();
        } else {
            Dialog({
                title: t("button.cancel"),
                text: t("model.popup.cancletDetail"),
                button: [t("model.popup.exit"), t("model.popup.apply")],
            }).then((isApply) => {
                if (isApply) {
                    onApply();
                } else {
                    onClose();
                    console.log("asdf");
                }
            });
        }
    };

    useEffect(() => {
        if (apply) onSetFilter();
    }, [apply]);

    return (
        <div
            id="popup-filter-option"
            className={cx({
                "filter-options": true,
                expand: show,
                show: show,
            })}
            role="dialog"
        >
            <div className="filter-options-top">
                <h2 className="title-pc">{t("user.filter")}</h2>
                <button type="button" className="btn-close" onClick={clickClose}>
                    <span className="ir">{t("common.close")}</span>
                </button>
            </div>

            <div className="filter-options-middle">
                <div className="option-box">
                    {menu === "ISSUE" && (
                        <Fragment>
                            <div className="field field-type-checkbox field-device-type">
                                <h3 className="field-label">{t("device.label.devicetype")}</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.deviceType?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-issue-device-type-${i}`}
                                                    value={v.value}
                                                    checked={selectList?.device_type.includes(v.value)}
                                                    onChange={(e) => onChangeCheck("device_type", v.value)}
                                                />
                                                <label htmlFor={`checkbox-issue-device-type-${i}`}>{v.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="field field-type-checkbox field-device-type">
                                <h3 className="field-label">{t("table.head.os")}</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.os?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-issue-device-${i}`}
                                                    value={v.value}
                                                    checked={selectList?.os_type.includes(v.value)}
                                                    onChange={(e) => onChangeCheck("os_type", v.value)}
                                                />
                                                <label htmlFor={`checkbox-issue-device-${i}`}>{v.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="field field-type-checkbox field-issue-statue">
                                <h3 className="field-label">{t("report.issuetype")}</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.issueCode?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-issue-status-${i}`}
                                                    value={v.value}
                                                    checked={selectList.issue_code.includes(v.value)}
                                                    onChange={(e) => onChangeCheck("issue_code", v.value)}
                                                />
                                                <label htmlFor={`checkbox-issue-status-${i}`}>{v.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    {menu === "ACTION" && (
                        <Fragment>
                            <div className="field field-type-checkbox field-device-type">
                                <h3 className="field-label">{t("common.type")}</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.actionType?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-action-type-${i}`}
                                                    value={v}
                                                    checked={selectList?.type.includes(v.value)}
                                                    onChange={(e) => onChangeCheck("type", v.value)}
                                                />
                                                <label htmlFor={`checkbox-action-type-${i}`}>{v.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    {menu === "ACCESS" && (
                        <Fragment>
                            <div className="field field-type-checkbox field-device-type">
                                <h3 className="field-label">{t("table.head.os")}</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.actionOs?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-access-os-${i}`}
                                                    value={v.value}
                                                    checked={selectList?.os.includes(v.value)}
                                                    onChange={(e) => onChangeCheck("os", v.value)}
                                                />
                                                <label htmlFor={`checkbox-access-os-${i}`}>{v.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="field field-type-checkbox field-issue-statue">
                                <h3 className="field-label">{t("admin.label.browser")}</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.browser?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-access-browser-${i}`}
                                                    value={v.value}
                                                    checked={selectList.browser.includes(v.value)}
                                                    onChange={(e) => onChangeCheck("browser", v.value)}
                                                />
                                                <label htmlFor={`checkbox-access-browser-${i}`}>{v.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    {menu === "LICENSE" && (
                        <Fragment>
                            <div className="field field-type-checkbox field-device-type">
                                <h3 className="field-label">{t("common.type")}</h3>
                                <div className="field-form">
                                    <div className="field-box not-flex">
                                        {filterList?.licenseType?.map((v, i) => (
                                            <div className="checkbox" key={i}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-license-${i}`}
                                                    value={v.value}
                                                    checked={selectList?.type.includes(v.value)}
                                                    onChange={(e) => onChangeCheck("type", v.value)}
                                                />
                                                <label htmlFor={`checkbox-license-${i}`}>{v.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>

                <div className="button-box apply">
                    <button type="button" className="btn btn-dxp btn-reflash" onClick={onReset}>
                        {t("main.settingsPage.reset")}
                    </button>
                    <button type="button" className="btn btn-dxp btn-apply" onClick={onApply}>
                        {t("player.button.apply")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Filter;
