import React, { Fragment, useEffect, useState, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

import { AppContext } from "@component/AppProvider";
import useCss from "@hook/useCss";
import Modal from "@component/UI/Modal";
import { SelectBox } from "@component/UI/Form";
import Dialog from "@component/UI/Dialog";
import _API from "@util/api";
import TargetModelnGroup from "@component/Device/ModelnGroup";
import _U from "@util/utilities";

export default (props) => {
    useCss("/assets/css/popup_device_list.css");

    const { t } = useTranslation();
    const { saveTargetModel, rolePermission, auth, workspace } = useContext(AppContext);

    const [registerModal, setRegisterModal] = useState(false);
    const [groupModal, setGroupModal] = useState(null);
    const [resultModal, setResultModal] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [paging, setPaging] = useState(null);

    const [expand, setExpand] = useState(false);

    const onDelete = (item) => {
        Dialog({ title: t("button.delete"), text: t("device.msg.deletetheitem"), button: [t("button.cancel"), t("button.delete")] }).then((id) => {
            if (id) {
                _API.delete({ path: `/device/devices/energy-saving/${item.id}`, data: {} })
                    .then((res) => {
                        onLoadData(1);
                    })
                    .catch((e) => {
                        console.log("api error /device/devices/energy-saving", e?.response);
                    });
            }
        });
    };

    const onLoadData = (page, append) => {
        _API.get({
            path: "/device/devices/energy-saving",
            data: { page, per_page: 10, last_id_key: "id", last_id_value: page == 1 ? null : dataList?.length > 0 ? dataList[dataList.length - 1].id : null },
        })
            .then((res) => {
                console.log("api /device/devices/energy-saving", res);
                // setDataList(res?.data?.result);
                append && setDataList([...dataList, ...res?.data?.result?.data]);
                !append && setDataList(res?.data?.result?.data);
                setPaging(res?.data?.result?.paging);
            })
            .catch((e) => {
                console.log("api error /device/devices/energy-saving", e?.response);
            });
    };

    const nextPage = () => {
        onLoadData(paging?.next_page || 1, true);
    };

    const onModelStatusChange = (item, e) => {
        const newList = dataList ? JSON.parse(JSON.stringify(dataList)) : [];
        newList?.forEach((f) => {
            if (f?.id === item?.id) {
                f["saving_mode"] = e.value;
            }
        });
        setDataList(newList);
        const params = {
            // groups: item.group,
            groups: item.group?.map((m) => m.group_id),
            model_name: item.model,
            saving_mode: e.value,
        };

        _API.put({
            path: `/device/devices/energy-saving/${item.id}`,
            data: params,
        })
            .then((res) => {
                onLoadData(1);
            })
            .catch((e) => {
                console.log("api error /device/devices/energy-saving", e?.response);
            });
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    useEffect(() => {
        onLoadData(1);
    }, []);

    return (
        <Fragment>
            <Modal
                title={t("subtext.label.device.energy.saving.mode")}
                id="popup-alert-message"
                className="popup-alert-message popup-energy-saving-mode popup-assign-license"
                onClose={props.onClose}
                noFooter
                close
            >
                <div className="tablist-content is-tab" role="tabpanel">
                    <div className="tab-panel fixed-panel" role="tabpanel">
                        <div className="data-state">
                            <div className="col">
                                <button type="button" className="btn btn-tertiary" onClick={() => setRegisterModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                    {t("main.btnRegister.title")}
                                </button>
                            </div>
                            <div className="col">
                                <div className="total-count">
                                    {t("common.total")} : <strong>{paging?.total_count || 0}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="field field-type-table">
                            <div className="field-form">
                                <div className="field-box">
                                    <div className="table">
                                        <div className="table-wrapper AutoCloning-table">
                                            <div className="table-box">
                                                <InfiniteScroll
                                                    style={{ overflow: "hidden" }}
                                                    dataLength={dataList?.length}
                                                    next={nextPage}
                                                    hasMore={paging && paging.has_next && windowWidth > 991}
                                                    loader={""}
                                                    scrollableTarget="scrollableTbodyFwUpdate"
                                                >
                                                    <table className="height-100vh-55rem">
                                                        <caption>{t("subtext.label.device.energy.saving.mode")}</caption>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="width-12-percent">
                                                                    <div className="text-block">{t("table.head.group")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("player.label.modelName")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("common.label.modestatus")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("admin.label.registerationdate")}</div>
                                                                </th>
                                                                <th scope="col" className="width-8-percent">
                                                                    <div className="text-block">{t("button.delete")}</div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="scrollableTbodyFwUpdate" className="height-100vh-58-6-rem">
                                                            {dataList?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className="width-12-percent" id={`saving-td1-${index}`}>
                                                                        {/* <a href="#!" className="link-target" onClick={() => setGroupModal({ open: true, data: item?.group })}>
                                                                            {item?.group?.length || 0}
                                                                        </a> */}
                                                                        {item?.group?.length || 0}
                                                                    </td>
                                                                    <td id={`saving-td2-${index}`}>{item?.model}</td>
                                                                    <td id={`saving-td3-${index}`}>
                                                                        {statusList(t)?.filter((f) => f.value === item?.saving_mode)[0]?.label || "-"}
                                                                        {/* <SelectBox
                                                                            id={"saving_mode" + index}
                                                                            items={statusList(t)}
                                                                            value={item?.saving_mode || ""}
                                                                            ariaLabelId={""}
                                                                            selectLabel={statusList(t)?.filter((f) => f.value === item?.saving_mode)[0]?.label || "ㅤ"}
                                                                            onChange={(s) => onModelStatusChange(item, s)}
                                                                            title={t("subtext.title.select.option")}
                                                                            ariadescribedby={`saving-td2-${index}`}
                                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                        /> */}
                                                                    </td>
                                                                    <td id={`saving-td4-${index}`}>{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : ""}</td>
                                                                    <td className="center width-8-percent">
                                                                        <button
                                                                            type="button"
                                                                            id=""
                                                                            className="btn btn-data-delete"
                                                                            onClick={() => onDelete(item)}
                                                                            aria-describedby={`saving-td1-${index} saving-td2-${index} saving-td3-${index} saving-td4-${index}`}
                                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                        >
                                                                            {t("button.delete")}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            {(!dataList || dataList.length === 0) && (
                                                                <tr>
                                                                    <td colSpan={5} className="align-center">
                                                                        {t("devicemanagement.search.noresult")}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </div>

                                        <div className="convert-table-to-list">
                                            <div className="table-header">
                                                <div className="row">
                                                    <div className="thead">{t("player.label.modelName")}</div>
                                                </div>
                                            </div>
                                            <InfiniteScroll
                                                style={{ overflow: "hidden" }}
                                                dataLength={dataList?.length}
                                                next={nextPage}
                                                hasMore={paging && paging.has_next && windowWidth <= 991}
                                                loader={""}
                                            >
                                                <ul className="table-body">
                                                    {dataList?.map((item, index) => (
                                                        <li className="table-row" key={`convert-${index}`}>
                                                            <div className="summary-data">
                                                                <div className="row">
                                                                    <div className="table-cell alias">
                                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                                            {t("common.label.expand")}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="all-data-box">
                                                                <ul className="all-data">
                                                                    <li id={`convert-saving-td1-${index}`}>
                                                                        <span className="field-label">{t("table.head.group")}</span>
                                                                        <span className="field-content">
                                                                            {/* <a href="#!" className="link-target" onClick={() => setGroupModal({ open: true, data: item?.group })}>
                                                                                {item?.group?.length || 0}
                                                                            </a> */}
                                                                            {item?.group?.length || 0}
                                                                        </span>
                                                                    </li>
                                                                    <li id={`convert-saving-td2-${index}`}>
                                                                        <span className="field-label">{t("player.label.modelName")}</span>
                                                                        <span className="field-content">{item?.model}</span>
                                                                    </li>
                                                                    <li id={`convert-saving-td3-${index}`}>
                                                                        <span className="field-label">{t("common.label.modestatus")}</span>
                                                                        <span className="field-content">
                                                                            {statusList(t)?.filter((f) => f.value === item?.saving_mode)[0]?.label || "-"}
                                                                            {/* <SelectBox
                                                                                id={"convert-saving_mode" + index}
                                                                                items={statusList(t)}
                                                                                value={item.saving_mode || ""}
                                                                                ariaLabelId={""}
                                                                                selectLabel={statusList(t)?.filter((f) => f.value === item.saving_mode)[0]?.label || "ㅤ"}
                                                                                onChange={(s) => onModelStatusChange(item, s)}
                                                                                title={t("subtext.title.select.option")}
                                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                            /> */}
                                                                        </span>
                                                                    </li>
                                                                    <li id={`convert-saving-td4-${index}`}>
                                                                        <span className="field-label">{t("admin.label.registerationdate")}</span>
                                                                        <span className="field-content">{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : ""}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("button.delete")}</span>
                                                                        <span className="field-content">
                                                                            <button
                                                                                type="button"
                                                                                id=""
                                                                                className="btn btn-data-delete"
                                                                                onClick={() => onDelete(item)}
                                                                                aria-describedby={`convert-saving-td1-${index} convert-saving-td2-${index} convert-saving-td3-${index} convert-saving-td4-${index}`}
                                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                            >
                                                                                {t("button.delete")}
                                                                            </button>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {registerModal && (
                <RegisterModal
                    onClose={() => {
                        onLoadData(1);
                        setRegisterModal(false);
                        saveTargetModel({ open: false });
                    }}
                />
            )}
            {resultModal?.open && <ResultModal data={resultModal?.data} onClose={() => setResultModal({ open: false })} />}
            {groupModal?.open && <GroupModal data={groupModal?.data || []} onClose={() => setGroupModal({ open: false })} />}
        </Fragment>
    );
};

const RegisterModal = (props) => {
    const { t } = useTranslation();
    const [targetInfo, setTargetInfo] = useState({ model_name: "", groupList: [] });

    const [saving_mode, setSaving_mode] = useState(null);

    const onClose = () => {
        Dialog({ title: t("button.cancel"), text: t("subtext.message.leavepage"), button: [t("common.button.stay"), t("common.button.leave")] }).then((id) => {
            if (id) {
                props.onClose();
            }
        });
    };

    const onClickDone = () => {
        if (saving_mode && targetInfo?.model_name && targetInfo?.groupList?.length > 0) {
            const params = {
                groups: targetInfo.groupList.map((m) => m.group_id),
                model_name: targetInfo.model_name,
                saving_mode: saving_mode?.value,
            };
            _API.post({ path: `/device/devices/energy-saving`, data: { ...params } })
                .then((res) => {
                    console.log("api /device/devices/energy-saving", res);
                    props.onClose();
                })
                .catch((e) => {
                    console.log("api error /device/devices/energy-saving", e?.response);
                });
        } else {
            Dialog({ title: t("popup.title.inform"), text: t("form.is.required"), button: t("common.ok") }).then((id) => {
                if (id) {
                }
            });
        }
    };

    return (
        <Modal title={t("main.btnRegister.title")} id="popup-register" className="popup-register" onClose={onClose} buttonOkTitle={t("main.btnRegister.title")} onClick={onClickDone}>
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <TargetModelnGroup targetInfo={{ ...targetInfo, setTarget: true, headLabel: "modelNgroup", model: true, group: true }} onChange={setTargetInfo} />

            <div className="field field-type-dropdown required">
                <div className="field-header">
                    <label className="field-title">{t("common.label.modestatus")}</label>
                </div>
                <div className="field-form">
                    <div className="field-box">
                        <SelectBox
                            id={"regist-saving_mode"}
                            items={statusList(t)}
                            value={saving_mode?.value || ""}
                            ariaLabelId={""}
                            selectLabel={statusList(t)?.filter((f) => f.value === saving_mode?.value)[0]?.label || "ㅤ"}
                            onChange={(s) => setSaving_mode(s)}
                            title={t("subtext.title.select.option")}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const ResultModal = (props) => {
    const { t } = useTranslation();
    const { data } = props;
    const { auth, workspace } = useContext(AppContext);
    const [dataList, setDataList] = useState([]);

    const onLoadData = (id) => {
        _API.get({ path: `/device/devices/energy-saving/${id}/result`, data: {} })
            .then((res) => {
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    setDataList(res?.data?.result);
                }
            })
            .catch((e) => {
                console.log("api error /device/devices/energy-saving", e?.response);
            });
    };

    useEffect(() => {
        data?.id && onLoadData(data?.id);
    }, [data]);

    return (
        <Modal title={t("result.main.title")} id="popup-result-list" className="popup-result-list" onClose={props.onClose} noFooter close>
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        <div className="total-count">
                            {t("common.label.success")} <span className="count">{dataList?.filter((f) => f.result)?.length || 0}</span>
                        </div>
                        <div className="total-count">
                            {t("common.total")} : <span className="count">{dataList?.length || 0}</span>
                        </div>
                    </div>
                </div>
                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper Result-table no-scroll">
                            <div className="table-box">
                                <table id="Result-table">
                                    <caption>{t("result.main.title")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.serialnumber")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("devicemanagement.title.deviceName")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("player.label.modelName")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("editor.label.date")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.resultvalue")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item?.serial_number}</td>
                                                <td>{item?.device_name}</td>
                                                <td>{item?.model}</td>
                                                <td>{item?.time ? _U.getOffsetTime(auth, workspace, item?.time) : ""}</td>
                                                <td>{item?.result === true ? "true" : item?.result}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">{t("common.label.serialnumber")}</div>
                                </div>
                            </div>
                            <ul className="table-body">
                                {dataList?.map((item, index) => (
                                    <li className={cx({ "table-row": true })} key={`convert-${index}`}>
                                        <div className="summary-data">
                                            <div className="row">
                                                <div className="table-cell alias">
                                                    <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                        {item?.serial_number}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="all-data-box">
                                            <ul className="all-data">
                                                <li>
                                                    <span className="field-label">{t("common.label.serialnumber")}</span>
                                                    <span className="field-content">{item?.serial_number}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("devicemanagement.title.deviceName")}</span>
                                                    <span className="field-content">{item?.device_name}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("player.label.modelName")}</span>
                                                    <span className="field-content">{item?.model}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("editor.label.date")}</span>
                                                    <span className="field-content">{item?.time ? _U.getOffsetTime(auth, workspace, item?.time) : ""}</span>
                                                </li>
                                                <li>
                                                    <span className="field-label">{t("common.label.resultvalue")}</span>
                                                    <span className="field-content">{item?.result === true ? "true" : item?.result}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const GroupModal = (props) => {
    const { data: groupList } = props;
    const { t } = useTranslation();
    console.log("groupList", groupList);

    return (
        <Modal title={t("table.head.group")} id="popup-assigned-user" className="popup-assigned-user" onClick={props.onClick} onClose={props.onClose} noFooter close>
            <div className="inner-header-box">
                <div className="left-side"></div>
                <div className="right-side">
                    <div className="table-state">
                        {t("common.total")} : <strong>{groupList?.length || 0}</strong>
                    </div>
                </div>
            </div>
            <div className="email-recipients-box">
                <div className="field field-type-email">
                    <div className="field-form">
                        <ul className="email-list">
                            {groupList?.map((item, index) => (
                                <li key={index}>
                                    <span>{item?.group_name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const statusList = (t) => {
    return [
        { label: t("common.code.energysavingmode.auto"), value: "auto" },
        { label: t("common.code.energysavingmode.off"), value: "off" },
        { label: t("common.code.energysavingmode.minimun"), value: "minimum" },
        { label: t("common.code.energysavingmode.medium"), value: "medium" },
        { label: t("common.code.energysavingmode.maximum"), value: "maximum" },
    ];
};
