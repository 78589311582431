import React from "react";
import DashboardHeader from "./Header";
import Tab from "./Tab";
import useCss from "@hook/useCss";

const DashboardWrapper = ({ children, onRefresh }) => {
    useCss("/assets/css/dashboard.css");
    return (
        <div className="dashboard">
            <DashboardHeader onRefresh={onRefresh} />
            <div className="content-middle">
                <Tab />
                {children}
            </div>
        </div>
    );
};

export default DashboardWrapper;
