import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { AppContext } from "@component/AppProvider";

import { HeadNavi } from "./Header";

export default (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setContentContainerExpand } = props;
    const { auth, rolePermission, workspace, product, sideBarExpand, saveSideBarExpand, hoverChild, saveHoverChild } = useContext(AppContext);
    const [isAdmin, setIsAdmin] = useState(false);

    const goPage = (url) => {
        url && navigate(url);
    };

    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const tabList = tabRef.current.querySelectorAll("button");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowDown" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    const onHamburgerClick = (e) => {
        if (e && e.key && !["Spacebar", 32, " ", "Enter"].includes(e.key)) {
            e.preventDefault();
            return;
        }
        saveSideBarExpand(!sideBarExpand);
    };

    const onHamburgerEnter = (e) => {
        if (!sideBarExpand) {
            saveHoverChild(true);
        }
    };

    const onSideBottomLeave = (e) => {
        if (hoverChild) {
            saveHoverChild(false);
        }
    };

    const onSideBottomBlur = (e) => {
        // if (sideBarExpand) {
        //     saveSideBarExpand(false);
        // }
    };

    useEffect(() => {
        if (
            auth?.portal_user_roles?.filter(
                (f) => "PlatformAdministrator" === f.common_role_name || (["BusinessAdministrator", "PropertyAdministrator"].includes(f.common_role_name) && f.business_id === workspace?.business_id)
            )?.length > 0
        ) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [auth, rolePermission, workspace]);

    useEffect(() => {
        function handleKeyup(e) {
            if (e?.key === "Escape") {
                saveSideBarExpand(false);
                saveHoverChild(false);
            }
        }
        document.addEventListener("keyup", handleKeyup);
        return () => {
            document.removeEventListener("keyup", handleKeyup);
        };
    }, []);

    return (
        <nav className={cx({ "side-bar": true, expand: sideBarExpand, "hover-child": hoverChild })}>
            {/* <!-- Side Top --> */}
            <div className="side-top">
                {/* <!--
              side-bar Collapsed 일 때 햄버거 버튼 title : 메인 메뉴를 펼칩니다.
              side-bar Expand 일 때 햄버거 버튼 title : 메인 메뉴를 접습니다.
            --> */}
                <button type="button" className="btn-hamburger" onClick={(e) => onHamburgerClick(e)} aria-expanded={sideBarExpand} onMouseEnter={(e) => onHamburgerEnter(e)}>
                    <div className="ir">{t("sidebar.menu.extension")}</div>
                </button>
                <div>
                    <img src="/assets/images/menu_lgcc_logo.svg" alt={t("dv.site.logo")} />
                </div>
            </div>
            {/* <!-- Side Top --> */}

            {/* <!-- Side Bottom --> */}
            {(sideBarExpand || hoverChild) && (
                <div className="side-bottom" onMouseLeave={(e) => onSideBottomLeave()} onBlur={() => onSideBottomBlur()}>
                    <div className="side-bottom-menu">
                        {/* <!-- Main menu Depth 1 --> */}
                        <ul id="main-menu" className="main-menu" role="menu" ref={tabRef}>
                            {/* <!-- Dashboard --> */}
                            {(isAdmin || rolePermission?.dashboard > 0) && (
                                <li className="parent-menu menu-1" role="none presentation">
                                    <button type="button" className="btn-main-menu" role="menuitem" tabIndex="0" onClick={() => goPage("/")} onKeyDown={onTabAreaKeyDown}>
                                        {t("home.label.DashBoard")}
                                    </button>
                                </li>
                            )}
                            {(isAdmin || rolePermission?.devices > 0) && (
                                <li className="parent-menu menu-2" role="none presentation">
                                    <button
                                        type="button"
                                        className="btn-main-menu"
                                        role="menuitem"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        onClick={() => goPage("/devices/list")}
                                        onKeyDown={onTabAreaKeyDown}
                                    >
                                        {t("device.label.devices")}
                                    </button>
                                </li>
                            )}
                            {(isAdmin || rolePermission?.history > 0) && (
                                <li className="parent-menu menu-3" role="none presentation">
                                    <button type="button" className="btn-main-menu" role="menuitem" onClick={() => goPage("/history/issue")} onKeyDown={onTabAreaKeyDown}>
                                        {t("device.label.history")}
                                    </button>
                                </li>
                            )}
                            {(isAdmin || rolePermission?.report > 0) && (
                                <li className="parent-menu menu-4" role="none presentation">
                                    <button
                                        type="button"
                                        className="btn-main-menu"
                                        role="menuitem"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        onClick={() => goPage("/report/list")}
                                        onKeyDown={onTabAreaKeyDown}
                                    >
                                        {t("common.label.report")}
                                    </button>
                                </li>
                            )}
                            {(isAdmin || rolePermission?.administration > 0) && (
                                <li className="parent-menu menu-5" role="none presentation">
                                    <button type="button" className="btn-main-menu" role="menuitem" onClick={() => goPage("/administration/profile")} onKeyDown={onTabAreaKeyDown}>
                                        {t("common.label.administration")}
                                    </button>
                                </li>
                            )}
                            {(isAdmin || rolePermission?.schedule > 0) && product?.service_type === "DMS" && (
                                <li className="parent-menu menu-6" role="none presentation">
                                    <button type="button" className="btn-main-menu" role="menuitem" onClick={() => goPage("/schedule/broadcast")} onKeyDown={onTabAreaKeyDown}>
                                        {t("common.schedule")}
                                    </button>
                                </li>
                            )}
                        </ul>
                        {/* <!-- Main menu Depth 1 --> */}
                    </div>
                    <div className="side-footer">
                        <HeadNavi notiWidth={{ width: "100%" }} />
                    </div>
                </div>
            )}
            {/* <!-- Side Bottom --> */}
        </nav>
    );
};
