import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";

import useCss from "@hook/useCss";
import { SelectBox, SelectBoxBasic } from "@component/UI/Form";
import _API from "@util/api";
import _U from "@util/utilities";
import Dialog from "@component/UI/Dialog";

const AddEmail = (props) => {
    useCss("/assets/css/popup_pannels.css");
    useCss("/assets/css/popup_device_list.css");

    const { t } = useTranslation();
    const [dataList, setDataList] = useState([]);
    const { data: dataProps } = props;
    const [data, setData] = useState({});

    const onAdd = () => {
        if (_U.checkEmailAddress(data?.email)) {
            if (data?.recipient_id) {
                //수정
                _API.put({ path: `/issue/monitoring/recipient/${data?.recipient_id}`, data: revertData() })
                    .then((res) => {
                        props.onClose && props.onClose();
                    })
                    .catch((e) => {
                        console.log(`api error /monitoring/recipient/${data?.recipient_id}`, e);
                    });
            } else {
                //등록
                _API.post({ path: `/issue/monitoring/recipient`, data: revertData() })
                    .then((res) => {
                        props.onClose && props.onClose();
                    })
                    .catch((e) => {
                        console.log(`api error /monitoring/recipient`, e);
                    });
            }
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("err.message.invalid.email"),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        }
    };

    const onCancel = () => {
        if (data && JSON.stringify(data) !== JSON.stringify(revertData())) {
            Dialog({
                title: t("button.cancel"),
                text: t("proxy.popup.exitdetail"),
                button: [t("popup.cancel.exit"), t("popup.cancel.saveExit")],
            }).then((id) => {
                if (id) {
                    onAdd();
                } else {
                    props.onClose && props.onClose();
                }
            });
        } else {
            props.onClose && props.onClose();
        }
    };

    const dummayDataList = [
        { code: "mon", day: t("admin.label.monday"), startApm: "am", startHour: "09", startMin: "00", endApm: "pm", endHour: "06", endMin: "00", activation: false },
        { code: "tue", day: t("admin.label.tuesday"), startApm: "am", startHour: "09", startMin: "00", endApm: "pm", endHour: "06", endMin: "00", activation: false },
        { code: "wed", day: t("admin.label.wednesday"), startApm: "am", startHour: "09", startMin: "00", endApm: "pm", endHour: "06", endMin: "00", activation: false },
        { code: "thu", day: t("admin.label.thursday"), startApm: "am", startHour: "09", startMin: "00", endApm: "pm", endHour: "06", endMin: "00", activation: false },
        { code: "fri", day: t("admin.label.friday"), startApm: "am", startHour: "09", startMin: "00", endApm: "pm", endHour: "06", endMin: "00", activation: false },
        { code: "sat", day: t("admin.label.saturday"), startApm: "am", startHour: "09", startMin: "00", endApm: "pm", endHour: "06", endMin: "00", activation: false },
        { code: "sun", day: t("admin.label.sunday"), startApm: "am", startHour: "09", startMin: "00", endApm: "pm", endHour: "06", endMin: "00", activation: false },
    ];
    const apmList = [
        { label: t("schedule.code.am"), value: "am" },
        { label: t("schedule.code.pm"), value: "pm" },
    ];
    const hours = [...Array(12)].map((m, i) => ({ label: i < 9 ? "0" + (i + 1) : i + 1 + "", value: i < 9 ? "0" + (i + 1) : i + 1 + "" }));
    const minutes = [...Array(60)].map((m, i) => ({ label: i < 10 ? "0" + i : i + "", value: i < 10 ? "0" + i : i + "" }));

    const onChange = (code, e, item) => {
        const newDataList = JSON.parse(JSON.stringify(dataList));
        newDataList.forEach((f) => {
            if (f.code === item.code) {
                if (code === "activation") {
                    f[code] = e.target.checked;
                } else {
                    f[code] = e.target.value;
                }
            }
        });
        setDataList(newDataList);
    };

    const onClickValue = (code, item) => {
        const newDataList = JSON.parse(JSON.stringify(dataList));
        newDataList.forEach((f) => {
            if (f.code === item.code) {
                f[code] = item.value;
            }
        });
        setDataList(newDataList);
    };

    const revertData = () => {
        const newData = { email: data?.email, time: {} };
        if (data?.recipient_id) {
            newData.recipient_id = data?.recipient_id;
        }
        dataList.forEach((f) => {
            newData.time[f.code] = {};
            newData.time[f.code].start = getDateFormat(f.startApm, f.startHour, f.startMin);
            newData.time[f.code].end = getDateFormat(f.endApm, f.endHour, f.endMin);
            newData.time[f.code].activation = f.activation ? true : false;
        });
        return newData;
    };

    const getDateFormat = (apm, hour, min) => {
        if (apm === "am" && parseInt(hour) === 12) {
            return "00:" + min + "00";
        } else if (apm === "pm" && parseInt(hour) < 12) {
            return parseInt(hour) + 12 + ":" + min + ":00";
        } else {
            return hour + ":" + min + ":00";
        }
    };
    const convertFormat = (info) => {
        const items = info;
        const list = JSON.parse(JSON.stringify(dummayDataList));
        if (items) {
            list.forEach((f) => {
                const item = items?.time[f.code];
                if (item) {
                    let time = item?.start?.split(":");
                    f.startApm = parseInt(time[0]) < 12 ? "am" : "pm";
                    f.startHour = parseInt(time[0]) > 12 ? parseInt(time[0]) - 12 : parseInt(time[0]);
                    if (f.startHour < 10) {
                        f.startHour = "0" + f.startHour;
                    }
                    f.startHour = f.startHour + "";
                    f.startMin = parseInt(time[1]);
                    if (f.startMin < 10) {
                        f.startMin = "0" + f.startMin;
                    }
                    f.startMin = f.startMin + "";
                    time = item?.end?.split(":");
                    f.endApm = parseInt(time[0]) < 12 ? "am" : "pm";
                    f.endHour = parseInt(time[0]) > 12 ? parseInt(time[0]) - 12 : parseInt(time[0]);
                    if (f.endHour < 10) {
                        f.endHour = "0" + f.endHour;
                    }
                    f.endHour = f.endHour + "";
                    f.endMin = parseInt(time[1]);
                    if (f.endMin < 10) {
                        f.endMin = "0" + f.endMin;
                    }
                    f.endMin = f.endMin + "";
                    f.day = f.day;
                    f.activation = item?.activation;
                }
            });
        }
        setDataList(list);
    };

    useEffect(() => {
        setData(dataProps);
        convertFormat(dataProps);
        resizeTable();
    }, [dataProps]);

    useEffect(() => {
        // 모달 팝업 시 background scroll방지
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    return (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
            <div id={props.id} className="popup-wrapper show">
                <div className="dim">&nbsp;</div>
                <div className={`popup ${props.className}`} role="dialog" aria-labelledby="popup-label" aria-modal="true">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h1 id="popup-label">{data ? t("common.title.edit.email") : t("common.title.add.email")}</h1>
                            {props.topClose && (
                                <button type="button" className="btn-close" onClick={onCancel}>
                                    <span className="ir">{t("button.close")}</span>
                                </button>
                            )}
                        </div>
                        {props.popupContentHeader || ""}

                        <div className="popup-content">
                            <div className="form-guide algin-left">
                                <span>*</span> {t("common.label.isrequired")}
                            </div>
                            <div className="field field-type-input required">
                                <div className="field-header">
                                    <label htmlFor="ip-email-receive" className="field-title">
                                        {t("dg.popup.addTrouble")}
                                    </label>
                                </div>
                                <div className="field-form">
                                    <div className="input-box">
                                        <input
                                            type="text"
                                            id="ip-email-receive"
                                            name=""
                                            placeholder={t("dg.popup.addTrouble")}
                                            autoComplete="false"
                                            aria-required="true"
                                            value={data?.email}
                                            onChange={(e) => setData({ ...data, email: e.target.value })}
                                        />
                                        <button type="button" className={cx({ "btn-input-remove": true, hide: data?.email ? false : true })} onClick={() => setData({ ...data, email: "" })}>
                                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="field field-type-table required">
                                <div className="field-header">
                                    <label className="field-title">{t("common.table.header.time.available.receive.mail")}</label>
                                </div>
                                <div className="field-form">
                                    <div className="field-box">
                                        <div className="table">
                                            <div className="table-wrapper table-receive no-scroll">
                                                <div className="table-box">
                                                    <table>
                                                        <caption>{t("common.table.header.time.available.receive.mail")}</caption>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="width-20-percent">
                                                                    <div className="text-block">{t("common.day")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("logreport.common.starttime")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("logreport.common.endtime")}</div>
                                                                </th>
                                                                <th scope="col" className="width-14-percent">
                                                                    <div className="text-block">{t("schedule.active.state.active")}</div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dataList?.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td id={item?.day} className="width-20-percent">
                                                                            {item?.day}
                                                                        </td>
                                                                        <td id={item?.day + "-startTime"}>
                                                                            <div className="field">
                                                                                <div className="field-form">
                                                                                    <div className="field-box" role="group" aria-labelledby={item?.day} aria-required="true">
                                                                                        <SelectBoxBasic
                                                                                            id={`${item?.day}-startApm`}
                                                                                            items={apmList}
                                                                                            value={item?.startApm}
                                                                                            onChange={(e) => onChange("startApm", e, item)}
                                                                                            ariaLabel={t("subtext.select.label.apm")}
                                                                                        />
                                                                                        <SelectBoxBasic
                                                                                            id={`${item?.day}-startHour`}
                                                                                            items={hours}
                                                                                            value={item?.startHour}
                                                                                            onChange={(e) => onChange("startHour", e, item)}
                                                                                            ariaLabel={t("subtext.select.label.hours")}
                                                                                        />
                                                                                        <SelectBoxBasic
                                                                                            id={`${item?.day}-startMin`}
                                                                                            items={minutes}
                                                                                            value={item?.startMin}
                                                                                            onChange={(e) => onChange("startMin", e, item)}
                                                                                            ariaLabel={t("subtext.select.label.minutes")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td id={item?.day + "-endTime"}>
                                                                            <div className="field">
                                                                                <div className="field-form">
                                                                                    <div className="field-box" role="group" aria-labelledby={item?.day} aria-required="true">
                                                                                        <SelectBoxBasic
                                                                                            id={`${item?.day}-endApm`}
                                                                                            items={apmList}
                                                                                            value={item?.endApm}
                                                                                            onChange={(e) => onChange("endApm", e, item)}
                                                                                            ariaLabel={t("subtext.select.label.apm")}
                                                                                        />
                                                                                        <SelectBoxBasic
                                                                                            id={`${item?.day}-endHour`}
                                                                                            items={hours}
                                                                                            value={item?.endHour}
                                                                                            onChange={(e) => onChange("endHour", e, item)}
                                                                                            ariaLabel={t("subtext.select.label.hours")}
                                                                                        />
                                                                                        <SelectBoxBasic
                                                                                            id={`${item?.day}-endMin`}
                                                                                            items={minutes}
                                                                                            value={item?.endMin}
                                                                                            onChange={(e) => onChange("endMin", e, item)}
                                                                                            ariaLabel={t("subtext.select.label.minutes")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-center width-14-percent">
                                                                            <div className="switch">
                                                                                <label htmlFor={`${item?.day}-activation-switch`} className="switch-controller">
                                                                                    {t("schedule.active.state.active")}
                                                                                </label>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`${item?.day}-activation-switch`}
                                                                                    name={`${item?.day}-activation-switch`}
                                                                                    role="switch"
                                                                                    checked={item?.activation || false}
                                                                                    onChange={(e) => onChange("activation", e, item)}
                                                                                    aria-labelledby={`${item?.day} ${item?.day}-startTime ${item?.day}-endTime`}
                                                                                    aria-required="true"
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="convert-table-to-list">
                                                <div className="table-header">
                                                    <div className="row">
                                                        <div className="thead">{t("schedule.active.state.active")}</div>
                                                        <div className="thead">{t("common.day")}</div>
                                                    </div>
                                                </div>
                                                <ul className="table-body">
                                                    {dataList?.map((item, index) => {
                                                        return (
                                                            <li className="table-row" key={"convert-table-" + index}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell device-type center">
                                                                            <div className="switch">
                                                                                <label htmlFor={`check-activation-reflow-${index}`} className="switch-controller">
                                                                                    {t("schedule.active.state.active")}
                                                                                </label>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`check-activation-reflow-${index}`}
                                                                                    name={`check-activation-reflow-${index}`}
                                                                                    role="switch"
                                                                                    aria-checked="true"
                                                                                    checked={item?.activation || false}
                                                                                    onChange={(e) => onChange("activation", e, item)}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="table-cell model-name">
                                                                            <button type="button" role="listbox" className="btn btn-expand" onClick={reflowViewExpand}>
                                                                                {item?.day}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li>
                                                                            <span className="field-label">{t("logreport.common.starttime")}</span>
                                                                            <div className="set-time start-time">
                                                                                <div className="field field-type-dropdown field-start-hour-reflow-1">
                                                                                    <div className="field-form">
                                                                                        <div className="field-box">
                                                                                            <SelectBox
                                                                                                id={`convert-${item?.day}-startApm`}
                                                                                                items={apmList}
                                                                                                value={item?.startApm}
                                                                                                selectLabel={apmList?.filter((f) => f.value == item?.startApm)[0]?.label}
                                                                                                onChange={(item) => onClickValue("startApm", item)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="separator">:</div>

                                                                                <div className="field field-type-dropdown field-start-minute-reflow-1">
                                                                                    <div className="field-form">
                                                                                        <div className="field-box">
                                                                                            <SelectBox
                                                                                                id={`convert-${item?.day}-startHour`}
                                                                                                items={hours}
                                                                                                value={item?.startHour}
                                                                                                selectLabel={hours?.filter((f) => f.value == item?.startHour)[0]?.label}
                                                                                                onChange={(item) => onClickValue("startHour", item)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="separator">:</div>

                                                                                <div className="field field-type-dropdown field-start-second-reflow-1">
                                                                                    <div className="field-form">
                                                                                        <div className="field-box">
                                                                                            <SelectBox
                                                                                                id={`convert-${item?.day}-startMin`}
                                                                                                items={minutes}
                                                                                                value={item?.startMin}
                                                                                                selectLabel={minutes?.filter((f) => f.value == item?.startMin)[0]?.label}
                                                                                                onChange={(item) => onClickValue("startMin", item)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("logreport.common.endtime")}</span>
                                                                            <div className="set-time end-time">
                                                                                <div className="field field-type-dropdown field-end-hour-reflow-1">
                                                                                    <div className="field-form">
                                                                                        <div className="field-box">
                                                                                            <SelectBox
                                                                                                id={`convert-${item?.day}-endApm`}
                                                                                                items={apmList}
                                                                                                value={item?.endApm}
                                                                                                selectLabel={apmList?.filter((f) => f.value == item?.endApm)[0]?.label}
                                                                                                onChange={(item) => onClickValue("endApm", item)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="separator">:</div>

                                                                                <div className="field field-type-dropdown field-end-minute-reflow-1">
                                                                                    <div className="field-form">
                                                                                        <div className="field-box">
                                                                                            <SelectBox
                                                                                                id={`convert-${item?.day}-endHour`}
                                                                                                items={hours}
                                                                                                value={item?.endHour}
                                                                                                selectLabel={hours?.filter((f) => f.value == item?.endHour)[0]?.label}
                                                                                                onChange={(item) => onClickValue("endHour", item)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="separator">:</div>

                                                                                <div className="field field-type-dropdown field-end-second-reflow-1">
                                                                                    <div className="field-form">
                                                                                        <div className="field-box">
                                                                                            <SelectBox
                                                                                                id={`convert-${item?.day}-endMin`}
                                                                                                items={minutes}
                                                                                                value={item?.endMin}
                                                                                                selectLabel={minutes?.filter((f) => f.value == item?.endMin)[0]?.label}
                                                                                                onChange={(item) => onClickValue("endMin", item)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-footer">
                            <div className="button-box double-button">
                                <button type="button" id="btn-sm-cancel" className="btn btn-huge btn-tertiary" onClick={onCancel}>
                                    {t("button.cancel")}
                                </button>
                                <button type="button" id="btn-adde-add" className="btn btn-huge btn-secondary" onClick={onAdd}>
                                    {data?.recipient_id ? t("distribution.label.completed") : t("common.add")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default AddEmail;
