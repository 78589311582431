import React, { Fragment, useRef, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import moment from "moment";

import useCss from "@hook/useCss";
import { AppContext } from "@component/AppProvider";
import _API from "@util/api";

import { SelectBox, InputDatepicker } from "@component/UI/Form";
import { Paging, ToPage, GetParams } from "@component/UI/Paging";

import { SideMenu } from "@component/Admin";
import LastUpdate from "@component/UI/LastUpdate";

export default (props) => {
    useCss("/assets/css/administration.css");
    const { auth, workspace, lastUpdateTime } = useContext(AppContext);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const [sideMenu, setSideMenu] = useState(false);
    const [data, setData] = useState({});

    const onRefresh = () => {};

    const loadData = () => {
        _API.get({ path: "/user/roles/property/myrole", data: { "property-id": workspace?.property_id } })
            .then((res) => {
                console.log(res?.data?.result);
                if (res?.data?.result) {
                    const role = res?.data?.result;
                    setData(role);
                } else {
                    setData({});
                }
            })
            .catch((e) => {
                console.log("api error /user/users/me", e);
                setData({});
            });
    };

    useEffect(() => {
        document.title = t("common.profile") + " | " + t("common.title.lgconnectedcare");
        workspace && loadData();
    }, [workspace, lastUpdateTime]);

    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>{t("common.label.administration")}</h1>
                </div>

                <LastUpdate />
            </div>

            <div className="content-middle wide-padding">
                <SideMenu sideMenu={sideMenu} onClose={() => setSideMenu(false)} />

                <div className="content-middle-box">
                    <div className="content-middle-top">
                        <button type="button" className="btn btn-open-child" onClick={() => setSideMenu(true)}>
                            <span className="ir">{t("history.open_sub")}</span>
                        </button>
                        <h2 className="title-content">{t("common.profile")}</h2>
                    </div>

                    <div className="administration-profile">
                        <div className="list-box admin-list-box">
                            <div className="field field-type-input field-flex">
                                <label id="accountIdLabel" className="field-label">
                                    {t("admin.label.accountid")}
                                </label>
                                <div className="field-form">
                                    <div className="field-content" aria-labelledby="accountIdLabel">
                                        {auth?.lge_user_id || ""}
                                    </div>
                                </div>
                            </div>

                            <div className="field field-type-input field-flex">
                                <label htmlFor="assigned-role" className="field-label">
                                    {t("popup.information.assignedRole")}
                                </label>
                                <div className="field-form">
                                    <div className="field-content">{data?.role_name || "-"}</div>
                                    <div className="field-box">
                                        <div className="field-content">
                                            <div className="title-box">{t("common.label.permission")}</div>
                                            {data?.role_permission &&
                                                Object.entries(data?.role_permission).map(([key, value]) => (
                                                    <dl key={key}>
                                                        <dt>{key}</dt>
                                                        <dd>{t("common.code.role.permission." + value)}</dd>
                                                    </dl>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
