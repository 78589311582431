import React, { Fragment, useEffect, useState, useRef, forwardRef, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import useScript from "@hook/useScript";
import "jquery-ui/ui/widgets/datepicker";
import qs from "query-string";
import _U from "@util/utilities";
import Calendar from "react-calendar";
import moment from "moment";
import { AppContext } from "@component/AppProvider";
import styled from "styled-components";
import FocusTrap from "focus-trap-react";

export const SelectBox = (props) => {
    const { t } = useTranslation();
    const { id, items, value, onChange, selectLabel, title, description, ariaLabelId, className, deleteBtn, onDelete, isRequired = false } = props;
    const { selectLabelClass, selectLabelEm, noItemText, disabled, ariadescribedby, noAtagAction, dropdownBottom } = props;
    const [expand, setExpand] = useState(false);
    const [selectId, setSelectId] = useState("");
    const areaRef = useRef(null);

    const onChangeItem = (sId, item) => {
        console.log("sid, item", sId, item);
        if (selectLabel != item?.label) {
            setSelectId(sId);
            onChange && onChange(item);
        }
        setExpand(false);
    };

    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowDown" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    useEffect(() => {
        if (selectLabel || selectLabel === 0) {
            for (let i = 0; i < items?.length; i++) {
                const item = items[i];
                if (item.label == selectLabel) {
                    setSelectId(`${id || ""}-select-id-${i}`);
                    break;
                }
            }
        }
    }, []);

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                setExpand(false);
            }
        }
        function handleKeyup(e) {
            if (e?.key === "Escape") {
                // document.activeElement.blur();
                setExpand(false);
            } else if (areaRef.current && !areaRef.current.contains(document.activeElement)) {
                setExpand(false);
            }
        }
        document.addEventListener("mousedown", handleOutside);
        document.addEventListener("keyup", handleKeyup);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
            document.removeEventListener("keyup", handleKeyup);
        };
    }, [areaRef]);

    return (
        <div className={`dropdown dropdown-type-a dropdown-overflow ${className} ${disabled ? "disabled" : ""} ${dropdownBottom ? "dropdown-bottom" : ""}`} ref={areaRef}>
            <a
                href="#!"
                role="combobox"
                aria-haspopup="listbox"
                className={`btn-dropdown ${selectLabelClass} ${disabled ? "disabled" : ""}`}
                aria-expanded={expand}
                aria-required={isRequired ? "true" : "false"}
                title={title || selectLabel}
                onClick={() => {
                    if (!disabled) {
                        setExpand(!expand);
                    }
                }}
                onKeyDown={(e) => {
                    if (!disabled && e.key === "ArrowDown") {
                        setExpand(!expand);
                        setTimeout(() => {
                            onTabAreaKeyDown(e);
                        }, 100);
                    }
                }}
                aria-labelledby={`${ariaLabelId || (id || "") + "-label-select"} ${selectId || ""}`}
                aria-activedescendant={selectId}
                aria-describedby={ariadescribedby || ""}
            >
                <div>
                    {selectLabelEm && <em>{selectLabelEm}</em>}
                    {selectLabel}
                </div>
            </a>
            <div className={cx({ "dropdown-menu": true, "list-delete": deleteBtn })}>
                <ul className="lists" role="listbox" aria-labelledby={ariaLabelId || (id || "") + "-label-select"} ref={tabRef}>
                    {items?.map((item, index) => (
                        <li className={`list ${item?.liClassName}`} role="none presentation" key={index} style={item?.style}>
                            {noAtagAction ? (
                                <Fragment>
                                    {item?.key && <span>{item?.key}</span>}
                                    {item?.em && <em>{item?.em}</em>}
                                    {item?.label}
                                </Fragment>
                            ) : (
                                <a
                                    href="#!"
                                    id={`${id || ""}-select-id-${index}`}
                                    role="option"
                                    aria-selected={value === item?.value ? "true" : "false"}
                                    onClick={() => onChangeItem(`${id || ""}-select-id-${index}`, item)}
                                    className={item?.aClassName || ""}
                                    onKeyDown={onTabAreaKeyDown}
                                    tabIndex={-1}
                                >
                                    {item?.key && <span>{item?.key}</span>}
                                    {item?.em && <em>{item?.em}</em>}
                                    {item?.label}
                                </a>
                            )}
                            {deleteBtn && (
                                <button type="button" className="btn-input-remove" onClick={() => onDelete && onDelete(item)}>
                                    <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                </button>
                            )}
                        </li>
                    ))}
                    {(!items || items?.length === 0) && (
                        <li className="list" role="none presentation">
                            <a href="#!" role="option" aria-selected="false">
                                {noItemText || noItemText == 0 ? noItemText : ""}
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export const SelectBoxBasic = (props) => {
    const { items, value, onChange, id, empty, ariaLabel } = props;

    return (
        <div className="select-box">
            <select id={id} onChange={onChange} value={value} aria-label={ariaLabel}>
                {empty && <option value="">{empty}</option>}
                {items?.map((item, i) => (
                    <option value={item?.value} key={i}>
                        {item?.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

let _inputDatePickerTimer = null;

const months = [
    { label: "Jan", value: 1 },
    { label: "Feb", value: 2 },
    { label: "Mar", value: 3 },
    { label: "Apr", value: 4 },
    { label: "May", value: 5 },
    { label: "Jun", value: 6 },
    { label: "Jul", value: 7 },
    { label: "Aug", value: 8 },
    { label: "Sep", value: 9 },
    { label: "Oct", value: 10 },
    { label: "Nov", value: 11 },
    { label: "Dec", value: 12 },
];

const StyledCalenderContainer = styled.div`
    .react-calendar__navigation {
        display: none;
    }
`;
export const InputCalendar = ({
    params: paramsInProps,
    className = "from-date",
    id,
    name,
    placeholder,
    autoComplete,
    ariaLabel,
    value,
    onChange,
    minDate,
    calendarTitle = "Calendar View",
    ariaDescribedBy,
    ir,
    isRequired = false,
}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
    const [activeDate, setActiveDate] = useState(new Date());
    const {
        auth: {
            user_info: { datetime_format },
        },
    } = useContext(AppContext);

    useEffect(() => {
        if (datetime_format) setDateFormat(datetime_format.split(" ")[0]);
    }, [datetime_format]);
    const calendarRef = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!calendarRef?.current?.contains(event?.target) && !event?.target?.classList?.contains("calendar_pop") && !event?.target?.closest(".calendar_pop")) {
                setShowCalendar(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showCalendar && value) {
            const vSplit = value.split("-");
            const year = parseInt(vSplit[0]);
            const month = parseInt(vSplit[1]);
            setCurrentYear(year);
            setCurrentMonth(month);
        }
    }, [showCalendar]);

    useEffect(() => {
        setActiveDate(new Date(`${currentYear}-${currentMonth}-1`));
    }, [currentYear, currentMonth]);

    const handleDateChange = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        onChange && onChange({ ...paramsInProps, [name]: formattedDate });
        setShowCalendar(false);
    };

    const onClickPrev = () => {
        if (currentMonth <= 1) {
            setCurrentYear(currentYear - 1);
            setCurrentMonth(12);
            return;
        }
        setCurrentMonth(currentMonth - 1);
    };

    const onClickNext = () => {
        if (currentMonth >= 12) {
            setCurrentMonth(1);
            setCurrentYear(currentYear + 1);
            return;
        }
        setCurrentMonth(currentMonth + 1);
    };

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    return (
        <div className={`datepicker-container ${className}`}>
            <label htmlFor={id}>
                <span className="ir">{ir}</span>
            </label>
            <div className="input-box">
                <input
                    type="text"
                    id={id}
                    name={name}
                    className="datepicker"
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    aria-label={ariaLabel}
                    value={value ? moment(value).format(dateFormat) : ""}
                    onClick={toggleCalendar}
                    readOnly
                    aria-required={isRequired ? "true" : "false"}
                />
                <button type="button" className="ui-datepicker-trigger" aria-describedby={ariaDescribedBy} onClick={toggleCalendar}>
                    <img src="/assets/images/btn_icon_calendar.svg" alt={calendarTitle} title={calendarTitle} />
                </button>
            </div>
            {showCalendar && (
                <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
                    <div className="calendar_pop display-block-style">
                        <div className="cal_header clearfix">
                            <div className="field field-type-dropdown field-flex">
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            items={months}
                                            value={currentMonth}
                                            selectLabel={currentMonth || ""}
                                            onChange={(item) => {
                                                const { value } = item;
                                                const isPlus = value > currentMonth;
                                                let loopTimes = 0;
                                                if (isPlus) {
                                                    loopTimes = value - currentMonth;
                                                    for (let i = 0; i < loopTimes; i++) {
                                                        const nextButton = document.querySelector(".react-calendar__navigation__next-button");
                                                        if (nextButton) nextButton.click();
                                                    }
                                                } else {
                                                    loopTimes = currentMonth - value;
                                                    for (let i = 0; i < loopTimes; i++) {
                                                        const prevButton = document.querySelector(".react-calendar__navigation__prev-button");
                                                        if (prevButton) prevButton.click();
                                                    }
                                                }
                                                setCurrentMonth(value);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            items={Array.from({ length: 21 }, (_, index) => {
                                                const year = currentYear - 10 + index;
                                                return { label: year, value: year };
                                            })}
                                            value={currentYear}
                                            selectLabel={currentYear || ""}
                                            onChange={(item) => {
                                                const { value } = item;
                                                setCurrentYear(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="cal_btnbox">
                                <a href="#!" role="button" className="btn-prev" onClick={onClickPrev}>
                                    <span className="ir">{t("page.button.prev")}</span>
                                </a>
                                <a href="#!" role="button" className="btn-next" onClick={onClickNext}>
                                    <span className="ir">{t("page.button.next")}</span>
                                </a>
                            </div>
                        </div>
                        <StyledCalenderContainer className="cal_content" ref={calendarRef}>
                            <Calendar view={["year", "month", "decade", "century"]} value={value} onChange={handleDateChange} minDate={minDate} className="ev-calendar" activeStartDate={activeDate} />
                        </StyledCalenderContainer>
                    </div>
                </FocusTrap>
            )}
        </div>
    );
};

export const InputDatepicker = forwardRef(({ params: paramsInProps, id, name, placeholder, autoComplete, ariaLabel, value, onChange, calendarTitle = "Calendar View" }, ref) => {
    useScript("/assets/lib/date-picker.js");

    useEffect(() => {
        if (ref.current) {
            $(ref.current)
                .datepicker({
                    autoSize: true,
                    showOn: "button",
                    changeMonth: true,
                    changeYear: true,
                    dateFormat: "yy-mm-dd",
                    buttonImage: "/assets/images/btn_icon_calendar.svg",
                    buttonImageOnly: false,
                    buttonText: calendarTitle,
                    dayNamesShort: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    showButtonPanel: true,
                    closeText: "Close",
                    onClose: () => {
                        $("#dp-container").removeAttr("aria-hidden");
                        $("#skipnav").removeAttr("aria-hidden");
                    },
                    onSelect: (d) => {
                        console.log("change");
                        $(ref.current).change();
                    },
                    onChange: (d) => {
                        console.log("change");
                    },
                    onChangeMonthYear: () => {
                        setTimeout(() => {
                            $(".ui-datepicker-month")[0].focus();
                        }, 10);
                    },
                })
                .on("change", () => {
                    if (_inputDatePickerTimer) {
                        clearTimeout(_inputDatePickerTimer);
                        _inputDatePickerTimer = null;
                    }
                    _inputDatePickerTimer = setTimeout(() => {
                        onChange && onChange({ ...paramsInProps, [name]: $(ref.current).val() });
                        _inputDatePickerTimer = null;
                    }, 100);
                });
        }
        return () => {
            if (ref.current) {
                $(ref.current).datepicker("destroy");
            }
        };
    }, [ref, paramsInProps]);

    useEffect(() => {
        if (!value && ref && ref?.current) {
            $(ref.current).val("");
        }
    }, [ref, value]);

    return <input type="text" id={id} name={name} className="datepicker" placeholder={placeholder} autoComplete={autoComplete} aria-label={ariaLabel} value={value || ""} ref={ref} readOnly />;
});

const isNotNumber = (value) => {
    const regExp = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
    return regExp.test(value);
};

export const NumberInput = ({ isRequired = false, autoComplete = false, min = 0, max = 100, id, value, onChange, descId, desc, title }) => {
    const { t } = useTranslation();
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    return (
        <div className={`field field-type-message ${isRequired ? "required" : ""}`}>
            <div className="field-header">
                <label htmlFor={id} className="field-title">
                    {title}
                </label>
            </div>
            <div className="field-form">
                <div className="input-box">
                    <input
                        type="text"
                        id={id}
                        value={value}
                        min={min}
                        max={max}
                        onKeyDown={(e) => {
                            const keyCode = e.keyCode || e.which;
                            if (keyCode >= 37 && keyCode <= 40) {
                                return;
                            }
                            if (e.key.length === 1 && e.key.charCodeAt(0) > 127) {
                                e.preventDefault();
                            }
                            if (exceptThisSymbols.includes(e.key)) e.preventDefault();
                        }}
                        onPaste={(e) => {
                            e.preventDefault();
                            const clipboardData = e.clipboardData || window.clipboardData;
                            const pastedText = clipboardData.getData("text");
                            const filteredText = pastedText.replace(/[^\d]/g, "");
                            const parsedValue = filteredText === "" ? "" : parseInt(filteredText, 10);
                            const clampedValue = filteredText === "" ? "" : Math.min(Math.max(parsedValue, min), max);
                            onChange(clampedValue);
                        }}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/[^0-9]/g, "");
                            console.log(inputValue);
                            const parsedValue = inputValue === "" ? "" : parseInt(inputValue, 10); // 숫자로 변환
                            const clampedValue = inputValue === "" ? "" : Math.min(Math.max(parsedValue, min), max); // 값 클램프
                            onChange(clampedValue);
                        }}
                        autoComplete={autoComplete ? "true" : "false"}
                        aria-required={isRequired ? "true" : "false"}
                        aria-invalid="true"
                        aria-describedby={descId}
                    />

                    <button type="button" className="btn-input-remove hide">
                        <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                    </button>
                </div>
            </div>
            {desc && (
                <span id={descId} className="field-description">
                    {desc}
                </span>
            )}
        </div>
    );
};

export const VolumeInput = ({ isRequired = false, autoComplete = false, min = 0, max = 100, id, value, onChange, descId, title, placeholder = "0" }) => {
    const { t } = useTranslation();
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    return (
        <div className={`field field-type-message ${isRequired ? "required" : ""}`}>
            <div className="field-header">
                <label htmlFor={id} className="field-title">
                    {title ? title : t("player.label.volume")}
                </label>
            </div>
            <div className="field-form">
                <div className="input-box">
                    <input
                        placeholder={placeholder}
                        type="text"
                        id={id}
                        value={value}
                        min={min}
                        max={max}
                        onKeyDown={(e) => {
                            const keyCode = e.keyCode || e.which;
                            if (keyCode >= 37 && keyCode <= 40) {
                                return;
                            }
                            if (e.key.length === 1 && e.key.charCodeAt(0) > 127) {
                                e.preventDefault();
                            }
                            if (exceptThisSymbols.includes(e.key)) e.preventDefault();
                        }}
                        onPaste={(e) => {
                            e.preventDefault();
                            const clipboardData = e.clipboardData || window.clipboardData;
                            const pastedText = clipboardData.getData("text");
                            const filteredText = pastedText.replace(/[^\d]/g, "");
                            const parsedValue = filteredText === "" ? "" : parseInt(filteredText, 10);
                            const clampedValue = filteredText === "" ? "" : Math.min(Math.max(parsedValue, min), max);
                            onChange(clampedValue);
                        }}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/[^0-9]/g, "");
                            const parsedValue = inputValue === "" ? "" : parseInt(inputValue, 10); // 숫자로 변환
                            const clampedValue = inputValue === "" ? "" : Math.min(Math.max(parsedValue, min), max); // 값 클램프
                            onChange(clampedValue);
                        }}
                        autoComplete={autoComplete ? "true" : "false"}
                        aria-required={isRequired ? "true" : "false"}
                        aria-invalid="true"
                        aria-describedby={descId}
                    />

                    <button type="button" className="btn-input-remove hide">
                        <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                    </button>
                </div>
            </div>
            <span id={descId} className="field-description">
                {t("alertmsg.popup.field_guide")}
            </span>
        </div>
    );
};

export const TextInput = ({ isRequired = false, title, id, value = "", onChange, maxLength = 100, placeholder = "", hideRemoveButton = false, hideWordCount = false, children }) => {
    const { t } = useTranslation();
    return (
        <div className={`field field-type-message ${isRequired ? "required" : ""}`}>
            {title && (
                <div className="field-header">
                    <label htmlFor={id} className="field-title">
                        {title}
                    </label>
                </div>
            )}
            <div className="field-form">
                <div className="input-box">
                    <input
                        type="text"
                        id={id}
                        value={value}
                        onChange={(e) => {
                            const value = e?.target?.value;
                            const truncatedValue = value?.slice(0, maxLength);
                            onChange(truncatedValue || value);
                        }}
                        autoComplete="false"
                        aria-required={isRequired ? "true" : "false"}
                        aria-invalid="true"
                        placeholder={placeholder}
                    />
                    {!hideRemoveButton && (
                        <button type="button" className={`btn-input-remove ${value ? "" : "hide"}`} onClick={() => onChange("")}>
                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                        </button>
                    )}
                </div>
                {!hideWordCount && (
                    <div className="word-count">
                        ({value.length}/{maxLength})
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};

export const TextArea = ({ isRequired = false, title, id, value = "", onChange, maxLength = 100, placeholder = "", hideWordCount = false }) => {
    const { t } = useTranslation();
    return (
        <div className={`field field-type-textarea ${isRequired ? "required" : ""}`}>
            {title && (
                <div className="field-header">
                    <label htmlFor={id} className="field-title">
                        {title}
                    </label>
                </div>
            )}
            <div className="field-form">
                <div className="field-box margin-0-style">
                    <textarea
                        name={id}
                        id={id}
                        aria-required={isRequired ? "true" : "false"}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => {
                            const value = e?.target?.value;
                            const truncatedValue = value?.slice(0, maxLength);
                            onChange(truncatedValue || value);
                        }}
                    ></textarea>
                </div>
                {!hideWordCount && (
                    <div className="word-count">
                        ({value.length}/{maxLength})
                    </div>
                )}
            </div>
        </div>
    );
};
