import React, { useEffect, useState } from "react";
import Modal from "@component/UI/Modal";
import Dialog from "@component/UI/Dialog";
import { useTranslation } from "react-i18next";

export const EmailModal = (props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [emailList, setEmailList] = useState([]);

    const onReset = () => {
        Dialog({
            title: t("main.settingsPage.reset"),
            text: t("common.label.ResetEmailRecipients"),
            button: [t("button.cancel"), t("main.settingsPage.reset")],
        }).then((ok) => {
            if (!ok) return;
            setEmailList([]);
        });
    };

    const onAdd = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailList.includes(email)) {
            alert("This email is already in the list.");
            return;
        }
        if (!emailPattern.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
        setEmailList([...emailList, email]);
        setEmail(""); // 입력 필드 초기화
    };

    const onRemove = (index) => {
        Dialog({
            title: t("button.delete"),
            text: t("common.label.DeleteSelectedRecipient"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((ok) => {
            if (!ok) return;
            const updatedList = [...emailList];
            updatedList.splice(index, 1);
            setEmailList(updatedList);
        });
    };

    useEffect(() => {
        setEmailList(props?.emailList);
    }, []);

    return (
        <Modal title={t("common.label.EMailRecipients")} id="popup-email-recipients" className="popup-email-recipients" onClose={props.onClose} onClick={() => props.onSave(emailList)}>
            <div className="inner-header-box">
                <div className="left-side">
                    <div className="table-state">
                        {t("player.label.total")} <strong>{emailList?.length || 0}</strong>
                    </div>
                </div>
                <div className="right-side">
                    <button type="button" className="btn btn-reset" onClick={onReset}>
                        {t("main.settingsPage.reset")}
                    </button>
                </div>
            </div>
            <div className="email-recipients-box">
                <div className="field field-type-email">
                    <div className="field-form">
                        <div className="field-box">
                            <input
                                type="text"
                                id="ip-sound-file"
                                placeholder="Enter the e-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onAdd();
                                    }
                                }}
                            />
                            <button type="button" className="btn btn-tertiary" onClick={onAdd}>
                                {t("common.add")}
                            </button>
                        </div>
                    </div>

                    <div className="field-form">
                        <ul className="email-list">
                            {emailList?.map((v, i) => (
                                <li key={i}>
                                    <span>{v}</span>
                                    <button type="button" className="btn-email-delete" onClick={() => onRemove(i)}>
                                        <span className="ir">{t("common.label.IrDeleteEmail")}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const CycleModal = (props) => {
    const { t } = useTranslation();
    const { cycle: cycleProps, onSetCycle } = props;

    const [cycle, setCycle] = useState(cycleProps);

    useEffect(() => {
        setCycle(cycleProps);
    }, [cycleProps]);

    const onClickDone = () => {
        onSetCycle(cycle);
        props.onClose && props.onClose();
    };

    return (
        <Modal title="Receive Cycle" id="popup-email-recipients" className="popup-email-recipients" onClose={props.onClose} onClick={onClickDone}>
            <div className="field field-type-radio">
                <div className="field-form">
                    <div className="radio">
                        <input type="radio" name="cycle" id="rad-tem-type01-1" value="2WEEK" checked={cycle === 0} onClick={() => setCycle(0)} readOnly />
                        <label htmlFor="rad-tem-type01-1">{t("common.label.Every2Weeks")}</label>
                    </div>
                    <div className="radio">
                        <input type="radio" name="cycle" id="rad-tem-type01-2" value="EVERYMONTH" checked={cycle === 1} onClick={() => setCycle(1)} readOnly />
                        <label htmlFor="rad-tem-type01-2">{t("common.label.EveryMonth")}</label>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const SettingModal = (props) => {
    const { t } = useTranslation();
    const { cycle: cycleProps, onSetSetting, onEmailSave } = props;

    const [email, setEmail] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [cycle, setCycle] = useState(cycleProps);

    const onReset = () => {
        Dialog({
            title: t("main.settingsPage.reset"),
            text: t("common.label.ResetEmailRecipients"),
            button: [t("button.cancel"), t("main.settingsPage.reset")],
        }).then((ok) => {
            if (!ok) return;
            setEmailList([]);
        });
    };

    const onAdd = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailList.includes(email)) {
            alert("This email is already in the list.");
            return;
        }
        if (!emailPattern.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
        setEmailList([...emailList, email]);
        setEmail(""); // 입력 필드 초기화
    };

    const onRemove = (index) => {
        Dialog({
            title: t("button.delete"),
            text: t("common.label.DeleteSelectedRecipient"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((ok) => {
            if (!ok) return;
            const updatedList = [...emailList];
            updatedList.splice(index, 1);
            setEmailList(updatedList);
        });
    };

    useEffect(() => {
        setEmailList(props?.emailList);
    }, []);

    useEffect(() => {
        setCycle(cycleProps);
    }, [cycleProps]);

    const onClickDone = () => {
        onSetSetting(cycle);
        onEmailSave(emailList);
        props.onClose && props.onClose();
    };

    return (
        <Modal title={t("common.label.ReportReceiveSettings")} id="popup-email-recipients" className="popup-email-recipients" onClose={props.onClose} onClick={onClickDone}>
            <div className="inner-header-box">
                <div className="left-side">
                    <div className="table-state">
                        {t("common.label.EMailRecipients")} <strong>{emailList?.length || 0}</strong>
                    </div>
                </div>
                <div className="right-side">
                    <button type="button" className="btn btn-reset" onClick={onReset}>
                        {t("main.settingsPage.reset")}
                    </button>
                </div>
            </div>
            <div className="email-recipients-box">
                <div className="field field-type-email">
                    <div className="field-form">
                        <div className="field-box">
                            <input
                                type="text"
                                id="ip-sound-file"
                                placeholder="Enter the e-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onAdd();
                                    }
                                }}
                            />
                            <button type="button" className="btn btn-tertiary" onClick={onAdd}>
                                {t("common.add")}
                            </button>
                        </div>
                    </div>

                    <div className="field-form">
                        <ul className="email-list">
                            {emailList?.map((v, i) => (
                                <li key={i}>
                                    <span>{v}</span>
                                    <button type="button" className="btn-email-delete" onClick={() => onRemove(i)}>
                                        <span className="ir">{t("common.label.IrDeleteEmail")}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="field field-type-radio">
                <div className="field-header">
                    <label htmlFor="ip-template-type" className="field-title">
                        {t("common.label.ReceiveCycle")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="radio">
                        <input type="radio" name="rad-tem-type01" id="rad-tem-type01-1" value="BI-WEEKLY" checked={cycle === 0} onClick={() => setCycle(0)} readOnly />
                        <label htmlFor="rad-tem-type01-1">{t("common.label.Every2Weeks")}</label>
                    </div>
                    <div className="radio">
                        <input type="radio" name="rad-tem-type01" id="rad-tem-type01-2" value="MONTHLY" checked={cycle === 1} onClick={() => setCycle(1)} readOnly />
                        <label htmlFor="rad-tem-type01-2">{t("common.label.EveryMonth")}</label>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const RecipientsModal = (props) => {
    const { t } = useTranslation();
    const { selectItem } = props;

    const onClickDone = () => {
        onSetCycle(cycle);
        props.onClose && props.onClose();
    };

    return (
        <Modal
            title={`${t("common.label.EMailRecipients")} (${selectItem?.length || 0})`}
            id="popup-email-recipients"
            className="popup-email-recipients"
            onClose={props.onClose}
            onClick={onClickDone}
            ok
            noFooter
        >
            <div className="field field-type-radio">
                <div className="field-form">
                    {selectItem?.map((v, i) => (
                        <div className="radio" key={i}>
                            <label>{v}</label>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};
