import { AppContext } from "@component/AppProvider";
import RegisterCommandPopup from "@component/Schedule/Popup/RegisterCommandPopup";
import ScheduleWrapper from "@component/Schedule/Wrapper";
import React, { Fragment, useContext, useEffect, useState } from "react";
import _API from "@util/api";
import Loading from "@component/UI/Loading";
import utils from "@util/utilities";
import { useTranslation } from "react-i18next";
import Dialog from "@component/UI/Dialog";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowWidth from "@hook/useWindowWidth";
import { BroadcastResult } from "@component/Device/Detail.Broadcast";
import { TBody } from "@component/Schedule/TBody";

export default () => {
    const { t } = useTranslation();
    const { workspace, rolePermission, auth } = useContext(AppContext);
    const [result, setResult] = useState(null);
    const windowWidth = useWindowWidth();

    const [loading, setLoading] = useState(true);

    const [isShow, setIsShow] = useState(false);
    const [data, setData] = useState({ total_count: 0, total_pages: 0, page: 0, items: [] });

    const toggleExecutionShow = (index) => {
        setData((prevData) => {
            const newItems = [...prevData.items];
            if (newItems[index]?.hasOwnProperty("isShow")) {
                newItems[index] = {
                    ...newItems[index],
                    isShow: !newItems[index].isShow,
                };
            } else {
                newItems[index] = {
                    ...newItems[index],
                    isShow: true,
                };
            }
            return { ...prevData, items: newItems };
        });
    };

    const onClickResult = (item) => {
        if (item) setResult({ id: item?.id });
    };

    const deleteItem = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("device.msg.deletetheitem"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            const { id } = item;
            _API.delete({ path: `/content/schedule/command/${id}` })
                .then((res) => {
                    if (res?.data?.result) {
                        fetchList(true);
                    }
                })
                .catch((e) => {
                    console.log(`api error /content/schedule/command/${id}`, e);
                });
        });
    };

    const fetchList = (isInit = true) => {
        const path = "/content/schedule/command";
        const per_page = 11;
        const is_group = true;
        if (isInit) {
            const page = 1;
            setLoading(true);
            _API.get({
                path,
                data: {
                    page,
                    per_page,
                    is_group,
                },
            })
                .then((res) => {
                    if (res?.data?.result) {
                        let {
                            result: {
                                data: items,
                                paging: { total_count },
                            },
                        } = res?.data;
                        const total_pages = Math.ceil(total_count / per_page);
                        setData({
                            ...data,
                            total_count,
                            total_pages,
                            page: 1,
                            items,
                        });
                    }
                })
                .finally(() => setLoading(false));
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            setLoading(true);
            _API.get({
                path,
                data: {
                    page,
                    per_page,
                    is_group,
                    last_id_key: data?.items?.length > 0 ? "id" : null,
                    last_id_value: data?.items?.length > 0 ? data.items[data.items.length - 1]?.id : null,
                },
            })
                .then((res) => {
                    if (res?.data?.result) {
                        let {
                            result: {
                                data: items,
                                paging: { total_count },
                            },
                        } = res?.data;
                        const total_pages = Math.ceil(total_count / per_page);
                        setData({
                            ...data,
                            total_count,
                            total_pages,
                            page,
                            items: [...data.items, ...items],
                        });
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        document.title = t("common.schedule") + " | " + t("common.title.lgconnectedcare");
        fetchList(true);
    }, [workspace]);

    return (
        <Fragment>
            {loading && <Loading />}
            <ScheduleWrapper onRefresh={() => fetchList(true)}>
                <div className="search-and-filter-box">
                    <div className="filter-box">
                        <div className="left-side">
                            <button type="button" className="btn btn-register" onClick={() => setIsShow(true)} disabled={utils.getAuthWriteDisable(rolePermission)}>
                                {t("main.btnRegister.title")}
                            </button>
                        </div>

                        <div className="right-side">
                            <div className="selected-count">
                                {t("common.total")} : <span className="count">{data?.total_count || 0}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="inner-content">
                    <div className="white-area">
                        <div className="data-list-container">
                            <div className="table">
                                <div className="table-wrapper horizontal-scroll select-mode">
                                    <div className="table-box">
                                        <InfiniteScroll
                                            style={{ overflow: "hidden" }}
                                            dataLength={data ? data?.items?.length : 0}
                                            next={() => fetchList(false)}
                                            hasMore={windowWidth > 991}
                                            loader={""}
                                            scrollableTarget="cmd-list-tbody"
                                        >
                                            <table>
                                                <caption>{t("common.reserved_command")}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <div className="text-block">{t("common.label.CommandType")}</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div className="text-block">{t("commom.popup.title")}</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div className="text-block">{t("common.label.Execution")}</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div className="text-block">{t("player.label.model")}</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div className="text-block">{t("devicemanagement.tree.group")}</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div className="text-block">{t("admin.label.registerationdate")}</div>
                                                        </th>
                                                        <th scope="col" width="75">
                                                            <div className="text-block">{t("table.head.result")}</div>
                                                        </th>
                                                        <th scope="col" width="75">
                                                            <div className="text-block">{t("button.delete")}</div>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <TBody id="cmd-list-tbody">
                                                    {data?.items?.map(
                                                        (x, i) =>
                                                            x && (
                                                                <tr key={i}>
                                                                    <td id={`command-td1-${i}`}>{x?.command_type || ""}</td>
                                                                    <td id={`command-td2-${i}`}>{x?.title || ""}</td>
                                                                    <td id={`command-td3-${i}`}>{x?.execution?.execution_type || ""}</td>
                                                                    <td id={`command-td5-${i}`}>{x?.model_name || ""}</td>
                                                                    <td id={`command-td6-${i}`}>{x?.group_name || ""}</td>
                                                                    <td id={`command-td7-${i}`}>{x?.created_at ? utils.getOffsetTime(auth, workspace, x.created_at) : ""}</td>
                                                                    <td className="align-center" width="75">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-result"
                                                                            onClick={() => onClickResult(x)}
                                                                            aria-describedby={`command-td1-${i} command-td2-${i} command-td3-${i} command-td4-${i} command-td5-${i} command-td6-${i} command-td7-${i}`}
                                                                        >
                                                                            {t("result.main.title")}
                                                                        </button>
                                                                    </td>
                                                                    <td className="align-center" width="75">
                                                                        <button
                                                                            id={`td-delete-${i}`}
                                                                            type="button"
                                                                            className="btn btn-delete"
                                                                            onClick={() => deleteItem(x)}
                                                                            aria-describedby={`command-td1-${i} command-td2-${i} command-td3-${i} command-td4-${i} command-td5-${i} command-td6-${i} command-td7-${i}`}
                                                                            disabled={utils.getAuthWriteDisable(rolePermission)}
                                                                        >
                                                                            {t("button.delete")}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    )}
                                                </TBody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>

                                <div className="convert-table-to-list">
                                    <div className="table-header">
                                        <div className="row">
                                            <div className="thead">{t("common.label.CommandType")}</div>
                                        </div>
                                    </div>
                                    <InfiniteScroll style={{ overflow: "hidden" }} dataLength={data ? data?.items?.length : 0} next={() => fetchList(false)} hasMore={windowWidth <= 991} loader={""}>
                                        <ul className="table-body">
                                            {data?.items?.map(
                                                (x, i) =>
                                                    x && (
                                                        <li className="table-row" key={i}>
                                                            <div className="summary-data">
                                                                <div className="row">
                                                                    <div className="table-cell status">
                                                                        <button type="button" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                            {x?.command_type || ""}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="all-data-box">
                                                                <ul className="all-data">
                                                                    <li id={`command-convert1-${i}`}>
                                                                        <span className="field-label">{t("commom.popup.title")}</span>
                                                                        <span className="field-content">{x.title}</span>
                                                                    </li>
                                                                    <li id={`command-convert2-${i}`}>
                                                                        <span className="field-label">{t("common.label.Execution")}</span>
                                                                        <span className="field-content">{x?.execution?.execution_type || ""}</span>
                                                                    </li>
                                                                    <li id={`command-convert4-${i}`}>
                                                                        <span className="field-label">{t("player.label.model")}</span>
                                                                        <span className="field-content">{x?.model_name || ""}</span>
                                                                    </li>
                                                                    <li id={`command-convert5-${i}`}>
                                                                        <span className="field-label">{t("devicemanagement.tree.group")}</span>
                                                                        <span className="field-content">{x?.group_name || ""}</span>
                                                                    </li>
                                                                    <li id={`command-convert6-${i}`}>
                                                                        <span className="field-label">{t("admin.label.registerationdate")}</span>
                                                                        <span className="field-content">{x?.created_at ? utils.getOffsetTime(auth, workspace, x.created_at) : ""}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("result.main.title")}</span>
                                                                        <span className="field-content">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-result"
                                                                                onClick={() => onClickResult(x)}
                                                                                aria-describedby={`command-convert1-${i} command-convert2-${i} command-convert3-${i} command-convert4-${i} command-convert5-${i} command-convert6-${i}`}
                                                                            >
                                                                                {t("result.main.title")}
                                                                            </button>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("button.delete")}</span>
                                                                        <span className="field-content">
                                                                            <button
                                                                                aria-describedby={`command-convert1-${i} command-convert2-${i} command-convert3-${i} command-convert4-${i} command-convert5-${i} command-convert6-${i}`}
                                                                                type="button"
                                                                                className="btn btn-delete"
                                                                                onClick={() => deleteItem(x)}
                                                                                disabled={utils.getAuthWriteDisable(rolePermission)}
                                                                            >
                                                                                {t("button.delete")}
                                                                            </button>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isShow && (
                    <RegisterCommandPopup
                        onClose={() => setIsShow(false)}
                        onRegister={() => {
                            setIsShow(false);
                            fetchList(true);
                        }}
                        totalCount={data?.total_count || 0}
                    />
                )}
                {result && <BroadcastResult is_broadcast={false} broadcastId={result?.id} onClose={() => setResult(null)} typeTitle={t("common.label.CommandType")} hideModel />}
            </ScheduleWrapper>
        </Fragment>
    );
};
