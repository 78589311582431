import Modal from "@component/UI/Modal";
import useWindowWidth from "@hook/useWindowWidth";
import api from "@util/api";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import cx from "classnames";

const DeviceStatus = ({ status = "connected", statusName = "", onClose, isIssue = false, isOnlyError = false }) => {
    const { t } = useTranslation();
    const [data, setData] = useState({
        total_count: 0,
        total_pages: 0,
        page: 0,
        items: [],
    });
    const windowWidth = useWindowWidth();
    const navigate = useNavigate();

    const fetchList = (isInit = true) => {
        const path = "/device/devices";
        const per_page = 10;
        let filter = {};
        if (isOnlyError) {
            filter = {
                issue_status: {
                    status: ["error"],
                    errors: ["TEM", "DSK", "FAN", "CON", "RES", "SGN", "NSP", "NIP", "ETH", "PWR", "CLD", "HUM", "DRO", "BLU", "PXL", "FLT", "IMP", "VOL", "AUD", "CVO", "CUL", "DIF", "CPU", "MEM"],
                },
            };
        } else if (isIssue) {
            filter = {
                issue_status: {
                    status: ["normal", "error"],
                    errors: [status],
                },
            };
        } else {
            filter = { connection_status: [status] };
        }
        if (isInit) {
            const page = 1;
            api.get({
                path,
                data: { filter, per_page, page },
            })
                .then((res) => {
                    if (res?.data?.result?.paging?.total_count) {
                        const { total_count: total } = res?.data?.result?.paging;
                        const { data } = res?.data?.result;
                        data?.forEach((v) => (v.isShow = false));
                        const total_pages = total ? Math.ceil(total / per_page) : 1;
                        setData((prevData) => ({
                            ...prevData,
                            total_count: total || 0,
                            total_pages,
                            page,
                            items: data || [],
                        }));
                    }
                })
                .catch((e) => {
                    console.log(`api error /device/devices`, e);
                });
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            api.get({
                path,
                data: {
                    filter,
                    per_page,
                    page,
                    last_id_key: data?.items?.length > 0 ? "device_id" : null,
                    last_id_value: data?.items?.length > 0 ? data.items[data.items.length - 1]?.device_id : null,
                },
            })
                .then((res) => {
                    if (res?.data?.result?.paging?.total_count) {
                        const { total_count: total } = res?.data?.result?.paging;
                        const { data } = res?.data?.result;
                        data?.forEach((v) => (v.isShow = false));
                        const total_pages = total ? Math.ceil(total / per_page) : 1;
                        setData((prevData) => ({
                            ...prevData,
                            total_count: total || 0,
                            total_pages,
                            page,
                            items: [...prevData.items, ...(data || [])],
                        }));
                    }
                })
                .catch((e) => {
                    console.log(`api error /device/devices`, e);
                });
        }
    };

    const onClickDetail = (item) => {
        if (item) {
            const { device_id } = item;
            navigate(`/devices/detail/${device_id}`);
        }
    };

    const toggleShow = (index) => {
        setData((prevData) => {
            const newData = { ...prevData };
            newData.items = [...prevData.items];
            newData.items[index] = {
                ...newData.items[index],
                isShow: !newData.items[index].isShow,
            };
            return newData;
        });
    };

    useEffect(() => {
        fetchList(true);
    }, []);

    return (
        <Fragment>
            <Modal id="popup-status-name" className="popup-status-name large" title={statusName} close onClose={onClose} noFooter>
                <div className="data-list-container">
                    <div className="data-list-top">
                        <div className="left-side"></div>
                        <div className="right-side">
                            <div className="total-count">
                                {t("common.total")} <span className="count">{data?.total_count || 0}</span>
                            </div>
                        </div>
                    </div>

                    <div className="data-list-bottom">
                        <div className="table">
                            <div className="table-wrapper SelDevice-table">
                                <div className="table-box">
                                    <InfiniteScroll
                                        style={{ overflow: "hidden" }}
                                        dataLength={data?.items?.length}
                                        next={() => fetchList(false)}
                                        hasMore={windowWidth > 991}
                                        loader={""}
                                        scrollableTarget="SelDevice-table-body"
                                    >
                                        <table id="SelDevice-table">
                                            <caption>{t("common.label.devicestatus")}</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <div className="text-block">{t("device.label.devicetype")}</div>
                                                    </th>
                                                    <th scope="col">
                                                        <div className="text-block">{t("table.head.os")}</div>
                                                    </th>
                                                    <th scope="col">
                                                        <div className="text-block">{t("filter.title.connection.status")}</div>
                                                    </th>
                                                    <th scope="col">
                                                        <div className="text-block">{t("player.label.modelName")}</div>
                                                    </th>
                                                    <th scope="col">
                                                        <div className="text-block">{t("common.label.serialnumber")}</div>
                                                    </th>
                                                    <th scope="col">
                                                        <div className="text-block">{t("filter.title.issueStatus")}</div>
                                                    </th>
                                                    <th scope="col" width="80">
                                                        <div className="text-block">{t("report.label.details")}</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="SelDevice-table-body">
                                                {data?.items?.map(
                                                    (v, i) =>
                                                        v && (
                                                            <tr key={i}>
                                                                <td>{v?.device_type || ""}</td>
                                                                <td>{v?.os_type || ""}</td>
                                                                <td>{v?.connection_status || ""}</td>
                                                                <td>{v?.model_name || ""}</td>
                                                                <td>{v?.serial_number || ""}</td>
                                                                <td>{v?.issue_count}</td>
                                                                <td width="80" className="center">
                                                                    <a href="#!" role="button" className="btn btn-detail" onClick={() => onClickDetail(v)}>
                                                                        {t("common.label.detail.information")}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                )}
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                            </div>

                            <div className="convert-table-to-list">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="thead">{t("device.label.devicetype")}</div>
                                    </div>
                                </div>
                                <InfiniteScroll
                                    style={{ overflow: "hidden" }}
                                    dataLength={data?.items?.length}
                                    next={() => fetchList(false)}
                                    hasMore={windowWidth <= 991}
                                    loader={""}
                                    scrollableTarget="popup-status-name-container"
                                >
                                    <ul className="table-body">
                                        {data?.items?.map(
                                            (v, i) =>
                                                v && (
                                                    <li className="table-row" key={i}>
                                                        <div className="summary-data">
                                                            <div className="row">
                                                                <div className="table-cell alias">
                                                                    <button type="button" className="btn btn-expand" onClick={(e) => reflowViewExpand(e)}>
                                                                        {v?.device_type || ""}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="all-data-box">
                                                            <ul className="all-data">
                                                                <li>
                                                                    <span className="field-label">{t("table.head.os")}</span>
                                                                    <span className="field-content">{v?.os_type || ""}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="field-label">{t("filter.title.connection.status")}</span>
                                                                    <span className="field-content">{v?.connection_status || ""}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="field-label">{t("player.label.modelName")}</span>
                                                                    <span className="field-content">{v?.model_name || ""}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="field-label">{t("common.label.serialnumber")}</span>
                                                                    <span className="field-content">{v?.serial_number || ""}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="field-label">{t("filter.title.issueStatus")}</span>
                                                                    <span className="field-content">{v?.issue_count}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="field-label">{t("report.label.details")}</span>
                                                                    <div className="field-content">
                                                                        <a href="#!" role="button" className="btn btn-detail" onClick={() => onClickDetail(v)}>
                                                                            {t("common.label.detail.information")}
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                )
                                        )}
                                    </ul>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default DeviceStatus;
