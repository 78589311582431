import React from "react";
import { useTranslation } from "react-i18next";

const RadioSelectMonitoring = ({ isMonitoring, setIsMonitoring }) => {
    const { t } = useTranslation();
    return (
        <div className="field field-type-radio">
            <div className="field-form">
                <div className="field-box" role="tablist">
                    <div className="radio" onClick={() => !isMonitoring && setIsMonitoring(true)}>
                        <input type="radio" name="radio-tab" id="monitoring" checked={isMonitoring === true} readOnly role="tab" aria-selected={isMonitoring === true} />
                        <label htmlFor="monitoring">{t("history.monitoring")}</label>
                    </div>
                    <div className="radio" onClick={() => isMonitoring && setIsMonitoring(false)}>
                        <input type="radio" name="radio-tab" id="difference-settings-data" checked={isMonitoring === false} readOnly role="tab" aria-selected={isMonitoring === false} />
                        <label htmlFor="difference-settings-data">{t("history.dsd")}</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RadioSelectMonitoring;
