import React, { Fragment, useRef, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import moment from "moment";

import useCss from "@hook/useCss";
import { AppContext } from "@component/AppProvider";
import _API from "@util/api";

import { SelectBox, InputDatepicker } from "@component/UI/Form";
import { Paging, ToPage, GetParams } from "@component/UI/Paging";

import { SideMenu } from "@component/Admin";
import Loading from "@component/UI/Loading";
import LastUpdate from "@component/UI/LastUpdate";
import _U from "@util/utilities";

export default (props) => {
    useCss("/assets/css/administration.css");
    const { auth, workspace, rolePermission, lastUpdateTime } = useContext(AppContext);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const [sideMenu, setSideMenu] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saveBtn, setSaveBtn] = useState(false);
    const [androidVersionList, setAndroidVersionList] = useState([]);
    const [webosVersionList, setWebosVersionList] = useState([]);

    const thumbnailQuality = [
        { label: "Invisible", value: "disabled" },
        { label: "low-quality", value: "low" },
        { label: "high-quality", value: "high" },
    ];

    const loadData = () => {
        setLoading(true);
        _API.get({ path: `/business/properties/${workspace?.property_id}` })
            .then((res) => {
                if (res && res?.data?.result) {
                    const role = res?.data?.result;
                    setData(role);
                } else {
                    setData({});
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log("api error /business/properties/{{property_id}}", e);
                setLoading(false);
                setData({});
            });
    };

    const loadAgentSettingData = () => {
        setLoading(true);
        _API.get({ path: `/business/properties/${workspace?.property_id}/agent-setting` })
            .then((res) => {
                if (res && res?.data?.result) {
                    const aList = [];
                    const wList = [];
                    for (let m of res?.data?.result?.ANDROID) {
                        if (m) {
                            aList.push({ label: m, value: m });
                        }
                    }
                    for (let m of res?.data?.result?.WEBOS) {
                        if (m) {
                            wList.push({ label: m, value: m });
                        }
                    }
                    setAndroidVersionList(aList);
                    setWebosVersionList(wList);
                } else {
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(`api error /business/properties/${workspace?.property_id}/agent-setting`, e);
                setLoading(false);
            });
    };

    useEffect(() => {
        document.title = t("common.agent") + " | " + t("common.title.lgconnectedcare");
        workspace && loadData();
        if (workspace && auth?.portal_user_roles?.filter((f) => "PlatformAdministrator" === f.common_role_name)?.length > 0) {
            loadAgentSettingData();
        }
    }, [workspace, auth, lastUpdateTime]);

    const onThumbnailChange = (e) => {
        setData({ ...data, thumbnail: e.value });
        onThumbnailSave(e.value);
    };

    const getSelectLabel = (key) => {
        switch (key) {
            case "thumbnail":
                return thumbnailQuality.find((item) => item.value === data?.thumbnail)?.label;
        }
    };

    const onPollingTimeChange = (e) => {
        let time = parseInt(e.target.value);

        if (time > 360) {
            time = 360;
        }
        setSaveBtn(true);
        setData({ ...data, agent_polling_time: time });
    };

    const onPollingTimeSave = () => {
        _API.put({
            path: `/business/properties/${workspace?.property_id}/agent-setting/polling`,
            data: { value: data?.agent_polling_time },
        })
            .then((res) => {
                console.log(res);
                loadData();
                setSaveBtn(false);
            })
            .catch((e) => {
                console.log("api error /business/properties/{{property_id}}/agent-setting/polling", e);
            });
    };

    const onThumbnailSave = (val) => {
        _API.put({
            path: `/business/properties/${workspace?.property_id}/agent-setting/thumbnail`,
            data: { value: val },
        })
            .then((res) => {
                console.log(res);
                loadData();
            })
            .catch((e) => {
                console.log("api error /business/properties/{{property_id}}/agent-setting/thumbnail", e);
            });
    };

    const onChangeVersion = (type, value) => {
        if (type && value) {
            setLoading(true);
            _API.put({ path: `/business/properties/${workspace?.property_id}/agent-setting/${type}`, data: { value } })
                .then((res) => {
                    loadData();
                })
                .catch((e) => {
                    console.log(`api error /business/properties/${workspace?.property_id}/agent-setting/${type}`, e);
                    setLoading(false);
                });
        }
    };

    return (
        <Fragment>
            {loading && <Loading />}
            <div className="content-top">
                <div className="top-left">
                    <h1>{t("common.label.administration")}</h1>
                </div>

                <LastUpdate />
            </div>

            <div className="content-middle wide-padding">
                <SideMenu sideMenu={sideMenu} onClose={() => setSideMenu(false)} />

                <div className="content-middle-box">
                    <div className="content-middle-top">
                        <button type="button" className="btn btn-open-child" onClick={() => setSideMenu(true)}>
                            <span className="ir">{t("history.open_sub")}</span>
                        </button>
                        <h2 className="title-content">{t("common.agent")}</h2>
                    </div>

                    <div className="administration-profile">
                        <div className="list-box">
                            <div className="field field-flex">
                                <span className="field-label" id="pollingLabel">
                                    {t("admin.label.agent.polling")}
                                </span>
                                <div className="field-form">
                                    <div className="field-box">
                                        <input
                                            type="number"
                                            value={data?.agent_polling_time || ""}
                                            placeholder="Seconds"
                                            onChange={onPollingTimeChange}
                                            onBlur={(e) => {
                                                if (e.target.value < 120) {
                                                    setData({ ...data, agent_polling_time: 120 });
                                                }
                                            }}
                                            aria-labelledby="pollingLabel"
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                        <button type="button" className="btn btn-reboot" onClick={onPollingTimeSave} disabled={!saveBtn || _U.getAuthWriteDisable(rolePermission)}>
                                            {t("button.save")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="field field-flex field-dpm">
                                <label className="field-label" id="qualityLabel">
                                    {t("admin.label.agent.quality")}
                                </label>
                                <div className="field-form">
                                    <div className="field-box">
                                        <SelectBox
                                            items={thumbnailQuality}
                                            selectLabel={getSelectLabel("thumbnail")}
                                            value={data?.thumbnail}
                                            onChange={onThumbnailChange}
                                            ariaLabelId={"qualityLabel"}
                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="details-tit">{t("admin.label.agent.version")}</div>

                                {/* <div className="field field-type-input field-flex">
                                    <label htmlFor="windows-agent-version" className="field-label">
                                        {t("admin.label.agent.version.window")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    id="windows-agent-version"
                                                    name="windows-agent-version"
                                                    value={data?.windows_agent_version || "Auto"}
                                                    autoComplete="false"
                                                    readOnly="readOnly"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="field field-type-input field-flex">
                                    <label htmlFor="android-agent-version" className="field-label">
                                        {t("admin.label.agent.version.android")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="input-box">
                                                {auth?.portal_user_roles?.filter((f) => "PlatformAdministrator" === f.common_role_name)?.length > 0 ? (
                                                    <SelectBox
                                                        items={androidVersionList}
                                                        selectLabel={data?.android_agent_version || "Auto"}
                                                        value={data?.android_agent_version || "Auto"}
                                                        onChange={(e) => onChangeVersion("ANDROID", e.value)}
                                                        id={"android-agent-version"}
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        id="android-agent-version"
                                                        name="android-agent-version"
                                                        value={data?.android_agent_version || "Auto"}
                                                        autoComplete="false"
                                                        readOnly="readOnly"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field field-type-input field-flex">
                                    <label htmlFor="webos-agent-version" className="field-label">
                                        {t("admin.label.agent.version.webos")}
                                    </label>
                                    <div className="field-form">
                                        <div className="field-box">
                                            <div className="input-box">
                                                {auth?.portal_user_roles?.filter((f) => "PlatformAdministrator" === f.common_role_name)?.length > 0 ? (
                                                    <SelectBox
                                                        items={webosVersionList}
                                                        selectLabel={data?.webos_agent_version || "Auto"}
                                                        value={data?.webos_agent_version || "Auto"}
                                                        onChange={(e) => onChangeVersion("WEBOS", e.value)}
                                                        id={"webos-agent-version"}
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        id="webos-agent-version"
                                                        name="webos-agent-version"
                                                        value={data?.webos_agent_version || "Auto"}
                                                        autoComplete="false"
                                                        readOnly="readOnly"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
