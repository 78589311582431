import React, { useState, useEffect, useContext, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { AppContext } from "@component/AppProvider";
import useCss from "@hook/useCss";
import Dialog from "@component/UI/Dialog";
import _API from "@util/api";
import { SelectBox } from "@component/UI/Form";

const GroupSettings = (props) => {
    useCss("/assets/css/site_group_list_container.css");
    useCss("/assets/css/popup_pannels.css");
    useCss("/assets/css/popup_set_target.css");

    const { t } = useTranslation();
    const { saveInnerLoading, workspace } = useContext(AppContext);
    const [groupList, setGroupList] = useState([]);
    const [sortInfo, setSortInfo] = useState({ code: "created_at", sort: "asc" });
    const createBtnRef = useRef(null);

    const getGroupList = () => {
        createBtnRef.current?.blur();
        saveInnerLoading && saveInnerLoading(true);
        _API.get({ path: "/business/groups", data: {} })
            .then((res) => {
                console.log("api /business/groups", res);
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    const newGroupList = res?.data?.result?.map((m, i) => convertGroupFormat(m, i));
                    setGroupList([]);
                    setTimeout(() => {
                        setGroupList(newGroupList);
                    }, 100);
                }
                saveInnerLoading && saveInnerLoading(false);
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
                saveInnerLoading && saveInnerLoading(false);
            });
    };

    const convertGroupFormat = (data, i) => {
        if (data && data?.group_id) {
            return {
                ...data,
                enabled: true,
                expanded: false,
                id: data.group_id,
                label: data.group_name,
                child: data?.lower_groups?.map((m) => convertGroupFormat(m) || []),
                myIndex: i,
            };
        }
        return data;
    };

    const addGroup = () => {
        if (groupList?.filter((f) => !f.id)?.length === 0) {
            const pId = groupList?.filter((f) => f.group_name === "Unassigned")[0]?.group_id;
            setGroupList([...groupList, { edit: true, enabled: true, expanded: false, id: null, pId, label: "", child: [], myIndex: groupList?.length || 0 }]);
        }
    };

    const onSaveGroup = (pId, id, groupName) => {
        const params = {};
        params.group_name = groupName;
        saveInnerLoading && saveInnerLoading(true);
        if (id) {
            _API.put({ path: `/business/groups/${id}`, data: params })
                .then((res) => {
                    if (res?.data?.result) {
                        console.log(`/business/groups/${id}`, res?.data?.result);
                    }
                    getGroupList();
                })
                .catch((e) => {
                    console.log("api error /business/groups", e);
                    saveInnerLoading && saveInnerLoading(false);
                });
        } else {
            params.target_group_uuid = pId;
            _API.post({ path: `/business/groups`, data: params })
                .then((res) => {
                    if (res?.data?.result) {
                        console.log(`/business/groups`, res?.data?.result);
                    }
                    getGroupList();
                })
                .catch((e) => {
                    console.log("api error /business/groups", e);
                    saveInnerLoading && saveInnerLoading(false);
                });
        }
    };
    const onDelete = (id) => {
        _API.delete({ path: `/business/groups/${id}`, data: {} })
            .then((res) => {
                if (res?.data?.result) {
                    console.log(`/business/groups/${id}`, res?.data?.result);
                    getGroupList();
                }
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
            });
    };

    const onSort = (code, sort) => {
        setSortInfo({ code, sort });
        if (code && sort) {
            let order = 0;
            if (code === "created_at" && sort === "asc") order = 0;
            else if (code === "created_at" && sort === "desc") order = 1;
            else if (code === "group_name" && sort === "asc") order = 2;
            else if (code === "group_name" && sort === "desc") order = 3;
            saveInnerLoading(true);
            _API.put({ path: `/business/properties/${workspace?.property_id}`, data: { group_order: order } })
                .then((res) => {
                    if (res?.data?.result) {
                        getGroupList();
                    }
                    // getGroupList();
                    saveInnerLoading(false);
                })
                .catch((e) => {
                    console.log("api error /business/groups", e);
                    saveInnerLoading && saveInnerLoading(false);
                });
            // let newGroupList = groupList?.map((m) => sortGroup(m, code, sort));
            // if (sort === "desc") {
            //     newGroupList = newGroupList.sort((a, b) => (a[code] > b[code] ? -1 : a[code] < b[code] ? 1 : 0));
            // } else {
            //     newGroupList = newGroupList.sort((a, b) => (a[code] < b[code] ? -1 : a[code] > b[code] ? 1 : 0));
            // }
            // console.log("sort", newGroupList);
            // setGroupList(newGroupList);
        }
    };

    const sortGroup = (data, code, sort) => {
        const c = { ...data, enabled: true, expanded: false, child: data?.child?.map((m) => sortGroup(m, code, sort) || []) };
        if (c.child?.length > 0) {
            if (sort === "desc") {
                c.child = c.child.sort((a, b) => (a[code] > b[code] ? -1 : a[code] < b[code] ? 1 : 0));
            } else {
                c.child = c.child.sort((a, b) => (a[code] < b[code] ? -1 : a[code] > b[code] ? 1 : 0));
            }
        }
        return c;
    };

    useEffect(() => {
        getGroupList();
    }, []);

    const sortByList = [
        { label: t("device.group.sort.code.created_at.asc"), column: "created_at", sort: "asc", value: 1 },
        { label: t("device.group.sort.code.created_at.desc"), column: "created_at", sort: "desc", value: 2 },
        { label: t("device.group.sort.code.group_name.asc"), column: "group_name", sort: "asc", value: 3 },
        { label: t("device.group.sort.code.group_name.desc"), column: "group_name", sort: "desc", value: 4 },
    ];

    return (
        <Fragment>
            <div className="group-list-wrap">
                <div className="row group-row">
                    <div className="inner-header-box">
                        <label htmlFor="group-list">{t("table.head.grouplist")}</label>
                        <button type="button" id="group-list" className="btn btn-create-group" onClick={addGroup} ref={createBtnRef}>
                            {t("common.button.create.group")}
                        </button>
                    </div>

                    <div className="group-list-container">
                        <div className="child-menu-container">
                            <div className="devices-menu-site">
                                <ul role="menu">
                                    <Tree items={groupList} onSaveGroup={onSaveGroup} onDelete={onDelete} t={t} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field field-type-sort-by">
                <div className="field-header">
                    <label className="field-title" id="groupSortByLabel">
                        {t("common.label.sortby")}
                    </label>
                </div>

                <div className="field-form">
                    <SelectBox
                        id="groupSortBy"
                        items={sortByList}
                        value={sortByList.filter((f) => f.column === sortInfo?.code && f.sort === sortInfo?.sort)[0]?.value || ""}
                        ariaLabelId={"groupSortByLabel"}
                        selectLabel={t("device.group.sort.code." + sortInfo?.code + "." + sortInfo?.sort)}
                        onChange={(item) => onSort(item.column, item.sort)}
                        title={t("subtext.title.select.option")}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default GroupSettings;

const Tree = (props) => {
    const { items: itemsInProps, checkGroupList, current: currentInProps, onClick, t } = props;
    const { onSaveGroup, onDelete } = props;

    const [items, setItems] = useState([]);
    const [current, setCurrent] = useState(currentInProps || null);
    const [totalOpenCnt, setTotalOpenCnt] = useState(itemsInProps?.length || 0);

    const handleItemClick = (id) => {
        let myIndex = 0;
        let totalCnt = 0;
        const expand = (o, pExpand) => {
            if (o.id === id) {
                if (o.child?.length > 0) {
                    o.expanded = !o.expanded;
                } else {
                    setCurrent(id);
                }
            }
            o.myIndex = pExpand ? myIndex++ : myIndex;
            totalCnt = pExpand ? totalCnt++ : totalCnt;
            o.child?.map((m, index) => expand(m, o.expanded));
        };
        const nl = [...items];
        nl.map((m, i) => expand(m, true));
        setItems(nl);
        setTotalOpenCnt(totalCnt);
    };

    const onAddSubGroup = (id) => {
        const newGroupList = items?.map((m) => addGroupFormat(m, id));
        setItems(newGroupList);
    };

    const addGroupFormat = (data, id) => {
        if (data) {
            if (data?.group_id === id) {
                if (!data.child) data.child = [];
                data.child.push({ edit: true, enabled: true, expanded: false, id: null, pId: id, label: "", child: [] });
            }
            const c = { ...data, child: data?.child?.map((m) => addGroupFormat(m, id) || []) };
            if (c?.child?.filter((f) => f.edit)?.length > 0) {
                c.expanded = true;
            }

            return c;
        }
    };

    const onEdit = (id) => {
        const newGroupList = items?.map((m) => editGroupName(m, id));
        setItems(newGroupList);
    };

    const editGroupName = (data, id) => {
        if (data) {
            const c = { ...data, edit: data?.group_id === id, child: data?.child?.map((m) => editGroupName(m, id) || []) };
            if (c?.child?.filter((f) => f.edit)?.length > 0) {
                c.expanded = true;
            }

            return c;
        }
    };

    useEffect(() => {
        setItems(itemsInProps);
        setTotalOpenCnt(itemsInProps?.length || 0);
    }, [itemsInProps]);

    useEffect(() => {
        setCurrent(currentInProps);
    }, [currentInProps]);

    return (
        <div className="devices-menu-site">
            <ul role="menu">
                {items?.map((item, i) => (
                    <Branches
                        key={i}
                        {...item}
                        checkGroupList={checkGroupList}
                        onClick={handleItemClick}
                        current={current}
                        t={t}
                        onSaveGroup={onSaveGroup}
                        onDelete={onDelete}
                        onAddSubGroup={onAddSubGroup}
                        onEdit={onEdit}
                        depthGroupList={items}
                        totalOpenCnt={totalOpenCnt}
                    />
                ))}
            </ul>
        </div>
    );
};

const Branches = (props) => {
    const { id, pId, label, enabled, expanded, child, onClick, current, t } = props;
    const { level, onSaveGroup, onDelete, onAddSubGroup, edit: editProps, onEdit, depthGroupList, myIndex, totalOpenCnt } = props;

    const [groupName, setGroupName] = useState(label);
    const [edit, setEdit] = useState(editProps || false);
    const inputRef = useRef(null);

    const onKeyDown = (e, id, pId) => {
        if (e.key === "Enter" && groupName?.trimStart()) {
            let error = 0;
            if (groupName?.toLowerCase() === "unassigned") {
                error = -1;
            }
            for (let index = 0; index < depthGroupList?.length; index++) {
                const checkItem = depthGroupList[index];
                if (checkItem?.id !== id && checkItem?.label === groupName) {
                    error = -2;
                    break;
                }
            }
            if (error < 0) {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("subtext.error.DuplicateGroupName"),
                    button: t("common.ok"),
                }).then((ok) => {
                    if (ok) {
                    }
                });
            } else {
                onSaveGroup && onSaveGroup(pId, id, groupName);
                setEdit(false);
            }
            e.preventDefault();
            return false;
        }
    };

    const onEditName = (groupId) => {
        onEdit(groupId);
    };

    const onDeleteCheck = (id) => {
        Dialog({
            title: t("admin.label.deletegroup"),
            text: t("device.group.message.delete.group.confirm"),
            button: [t("button.cancel"), t("admin.label.deletegroup")],
        }).then((ok) => {
            if (ok) {
                onDelete && onDelete(id);
            }
        });
    };

    const items = [
        { label: t("device.group.setting.action.edit.name"), id, onClick: onEditName, value: 1 },
        { label: t("admin.label.deletegroup"), id, onClick: onDeleteCheck, value: 2 },
    ];
    if (level < 4) {
        items.push({ label: t("device.group.setting.action.add.subgroup"), id, onClick: onAddSubGroup, value: 3 });
    }

    const onChangeGroupName = (val) => {
        if (val?.startsWith(" ")) {
            val = val?.trimStart();
        }
        if (val?.length > 30) {
            val = val?.substring(0, 30);
        }
        setGroupName(val);
    };

    useEffect(() => {
        setEdit(editProps);
    }, [editProps]);

    useEffect(() => {
        if (edit && inputRef?.current) {
            inputRef.current.focus();
        }
    }, [edit]);

    return (
        <li id={id} className={cx({ "child-menu": true, "has-child": child?.length > 0, "open-menu": expanded, disabled: !enabled, active: false })} role="none presentation">
            <div className={cx({ "menu-box": true, active: current === id })}>
                <div className="group-tit" id={(id || "") + "-groupNameLabel"}>
                    <a
                        href="#"
                        role="menuitemradio"
                        onClick={() => {
                            onClick(id);
                        }}
                    >
                        {t("subtext.menu.open")}
                    </a>
                    {!edit ? (
                        " " + (groupName || label)?.trimStart()
                    ) : (
                        <input
                            type="text"
                            value={groupName?.trimStart() || ""}
                            onChange={(e) => onChangeGroupName(e?.target?.value)}
                            onKeyDown={(e) => onKeyDown(e, id, pId)}
                            placeholder={t("subtext.Enteragroupname")}
                            maxLength={30}
                            ref={inputRef}
                        />
                    )}
                </div>
                {!edit && label !== "Unassigned" && (
                    <div className="field field-type-dropdown field-flex">
                        <div className="field-form">
                            <div className="field-box">
                                <SelectBox
                                    id={(id || "") + "-groupName-dropdown"}
                                    items={items}
                                    value={""}
                                    ariaLabelId={(id || "") + "-groupNameLabel"}
                                    selectLabel={""}
                                    onChange={(item) => item?.onClick(item?.id)}
                                    title={t("subtext.title.select.option")}
                                    dropdownBottom={parseInt(totalOpenCnt || 0) - parseInt(myIndex || 0) < 3 && totalOpenCnt > 2}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {expanded && child?.length > 0 && (
                <ul role="menu">
                    {child.map((item, i) => (
                        <Branches
                            key={i}
                            {...item}
                            onClick={onClick}
                            current={current}
                            t={t}
                            onSaveGroup={onSaveGroup}
                            onDelete={onDelete}
                            onAddSubGroup={onAddSubGroup}
                            depthGroupList={child}
                            totalOpenCnt={totalOpenCnt}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};
