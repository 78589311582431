import React, { useState } from "react";

export const TooltipBottom = ({ txt }) => {
    const [isShow, setIsShow] = useState(false);
    return (
        <div className="tooltip bottom type_a">
            <div className="tooltip-body">{txt}</div>
        </div>
    );
};

export const TooltipTop = ({ txt, widthClass }) => {
    const [isShow, setIsShow] = useState(false);
    return (
        <span className="tooltip-top-style">
            <div className={`tooltip top type_a ${widthClass || "width-13-rem"}`}>
                <div className="tooltip-body">{txt}</div>
            </div>
        </span>
    );
};
