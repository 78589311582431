import React, { useContext, useEffect, useState } from "react";
import useCss from "@hook/useCss";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";
import _API from "@util/api";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";
import utils from "@util/utilities";
import styled from "styled-components";

const StyledThumbDesc = styled.div`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow-y: hidden;
    max-width: 160px;
    max-height: 68px;
`;

const SendMessage = ({ list, changeShowSendMsg, onMessageRegister }) => {
    const { t } = useTranslation();

    const { targetModel, saveTargetModel } = useContext(AppContext);
    const [modelName, setModelName] = useState(null);
    const [templateId, setTemplateId] = useState(null);
    const [sortedList, setSortedList] = useState([]);

    useCss("/assets/css/popup_pannels.css");
    useCss("/assets/css/popup_set_target.css");

    const onClickCancel = () => {
        Dialog({
            title: t("common.button.leave"),
            text: t("subtext.message.leavepage"),
            button: [t("common.button.stay"), t("common.button.leave")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            changeShowSendMsg(false);
        });
    };

    const pleaseEnterDialog = (name) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("popup.inform.sigleItem", { n: name }),
            button: t("common.label.confirm"),
        });
    };

    const onClickDone = () => {
        const group_id = targetModel?.groupList?.map((v) => v.group_id);
        const template_id = templateId ? `${templateId}` : "";
        let count = 0;
        if (!template_id) count++;
        if (!group_id?.length) count++;
        if (count >= 2) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.multiItem"),
                button: t("common.label.confirm"),
            });
            return;
        }

        if (!template_id) {
            pleaseEnterDialog(t("dg.message.type"));
            return;
        }
        if (!group_id?.length) {
            pleaseEnterDialog(t("table.head.group"));
            return;
        }

        _API.post({
            path: `/content/alert/message`,
            data: { group_id, template_id },
        })
            .then((res) => {
                changeShowSendMsg(false);
                onMessageRegister();
            })
            .catch((e) => {
                if (e) {
                    console.log(`api error /content/alert/message`, e);
                    if (e?.response?.status === 400) {
                        let msg = "";
                        if (e?.response?.data?.status?.code === "00-006" && e?.response?.data?.error === "duplicated title") {
                            msg = t("subtext.error.itemDupTitle");
                        } else if (e?.response?.data?.error?.error) msg = e.response.data.error.error;
                        else msg = e.response.data.error;
                        Dialog({
                            title: "LGE",
                            text: msg,
                            button: t("common.ok"),
                        });
                    }
                }
            });
    };

    useEffect(() => {
        saveTargetModel({
            open: false,
            model: false,
            group: true,
            headLabel: "modelNgroup",
        });
    }, []);

    useEffect(() => {
        const sorted = [...list].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        setSortedList(sorted);
        if (sorted?.length) {
            const item = sorted[0];
            if (item.hasOwnProperty("id")) setTemplateId(item.id);
        }
    }, [list]);

    useEffect(() => {
        if (targetModel?.hasOwnProperty("model_name")) setModelName(targetModel?.model_name);
    }, [targetModel]);

    return (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
            <div id="popup-send-message" className="popup-wrapper show">
                <div className="dim">&nbsp;</div>
                <div className="popup popup-send-message" role="dialog" aria-labelledby="popup-label" aria-modal="true">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h1 id="popup-label">{t("alertmsg.send.message")}</h1>
                        </div>

                        <div className="popup-content">
                            <div className="form-guide algin-left">
                                <span>*</span> {t("common.label.isrequired")}
                            </div>
                            <div className="field field-type-message required">
                                <div className="field-header">
                                    <h2 className="field-title">{t("dg.message.type")}</h2>
                                </div>
                                <div className="field-form">
                                    {sortedList?.slice(0, 5)?.map(
                                        (v, i) =>
                                            v && (
                                                <div className="field-message-item messageType01" onClick={() => setTemplateId(v.id)} key={i}>
                                                    <input type="radio" id={`messageType01-${i}`} name="messageType01" checked={v?.id === templateId} readOnly />
                                                    <label htmlFor={`messageType01-${i}`}>
                                                        <div
                                                            className="thumb"
                                                            style={{
                                                                background: `url("${v?.img_url}") no-repeat center/cover`,
                                                            }}
                                                        >
                                                            {!v?.image_file && <StyledThumbDesc>{v?.message || ""}</StyledThumbDesc>}
                                                        </div>
                                                        <p className="item-name">{v.title || "-"}</p>
                                                    </label>
                                                </div>
                                            )
                                    )}
                                </div>
                            </div>
                            <div className="field field-type-message required">
                                <div className="field-header">
                                    <h2 className="field-title">{t("common.label.target.group")}</h2>
                                </div>
                                <div className="field-form">
                                    <div className="message-target-wrap">
                                        {!targetModel?.groupList?.length && <div className="empty-txt whitespace-pre-line">{t("common.desc.target.select.no")}</div>}
                                        {targetModel?.groupList?.length ? (
                                            <dl className="message-target-info">
                                                <dt>Group</dt>
                                                <dd className="message-target">
                                                    {targetModel?.groupList?.map((item, index) => {
                                                        return (
                                                            <div className="message-target-item" key={index}>
                                                                {item?.treeName?.join(" > ")}
                                                            </div>
                                                        );
                                                    })}
                                                </dd>
                                            </dl>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-tertiary btn-right-arrow btn-set-target"
                                    onClick={() =>
                                        saveTargetModel({
                                            ...targetModel,
                                            open: true,
                                            model: false,
                                            group: true,
                                            headLabel: "group",
                                        })
                                    }
                                >
                                    Set Target
                                </button>
                            </div>
                        </div>

                        <div className="popup-footer">
                            <div className="button-box double-button">
                                <button type="button" id="btn-sm-cancel" className="btn btn-huge btn-tertiary" onClick={onClickCancel}>
                                    {t("button.cancel")}
                                </button>
                                <button type="button" id="btn-sm-done" className="btn btn-huge btn-secondary " onClick={onClickDone}>
                                    {t("distribution.label.completed")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default SendMessage;
