import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import cx from "classnames";
import FocusTrap from "focus-trap-react";

import { Features, Tree } from "@component/UI/SideBar";
import Modal from "@component/UI/Modal";
import MonitoringSetting from "./popup/monitoringSettings";

import useCss from "@hook/useCss";

import { ControllerModal, AssignModal, UnAssignModal } from "@component/Device/List";
import { GroupModal } from "@component/Device/Detail";

import EnergySaving from "@component/Device/List.EnergySaving";
import UniController from "@component/Device/List.UniController";
import AutoCloning from "@component/Device/List.AutoCloning";
import AutoUpdate from "@component/Device/List.AutoUpdate";
import _API from "@util/api";
import Loading from "@component/UI/Loading";
import { AppContext } from "@component/AppProvider";
import LastUpdate from "@component/UI/LastUpdate";
import GroupSettings from "@component/Device/Group.Setting";
import Dialog from "@component/UI/Dialog";
import _U from "@util/utilities";
import _Device from "@util/devices";

export default () => {
    useCss("/assets/css/popup_device_list.css");
    const { t } = useTranslation();
    const location = useLocation();
    const { auth, workspace, targetModel, saveTargetModel, lastUpdateTime } = useContext(AppContext);
    const [groupList, setGroupList] = useState([]);
    const [selectGroupInfo, setSelectGroupInfo] = useState({});
    const [monitoring, setMonitoring] = useState(false);
    const [savingMode, setSavingMode] = useState(false);
    const [uniController, setUniController] = useState(false);
    const [autoCloning, setAutoCloning] = useState(false);
    const [autoUpdate, setAutoUpdate] = useState(false);
    const [groupId, setGroupId] = useState(null);
    const [locState, setLocState] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [groupSetting, setGroupSetting] = useState(false);
    const [myGroup, setMyGroup] = useState({});
    const [uniControlDevice, setUniControlDevice] = useState(null);
    const [sideMenu, setSideMenu] = useState(false);
    const [groupAddModal, setGroupAddModal] = useState({ open: false });
    const [treeUlOn, setTreeUlOn] = useState(false);

    const featureItems = [
        [
            { id: "SPF-MONITOR-SETTING", label: t("subtext.label.device.monitoring.settings"), icon: "btn-icon btn-monitoring" },
            { id: "SPF-AUTO-UPDATE", label: t("subtext.label.device.auto.update"), icon: "btn-icon btn-update" },
            { id: "SPF-AUTO-CLONING-DATA", label: t("subtext.label.device.auto.cloning.data"), icon: "btn-icon btn-cloning" },
            { id: "SPF-UNICONTROLLER", label: t("subtext.label.device.unicontroller"), icon: "btn-icon btn-uniController" },
            { id: "SPF-ENERGY-SAVING-MODE", label: t("subtext.label.device.energy.saving.mode"), icon: "btn-icon btn-energy" },
            // { id: "SPF-REBOOT-SCHEDULE", label: t("subtext.label.device.reboot.schedule"), icon: "btn-icon btn-schedule" },
        ],
    ];

    const onClickFeatures = (id) => {
        switch (id) {
            case "SPF-MONITOR-SETTING":
                setMonitoring(true);
                break;
            case "SPF-ENERGY-SAVING-MODE":
                setSavingMode(true);
                break;
            case "SPF-UNICONTROLLER":
                // setUniController(true);
                saveTargetModel({ open: true, model: true, group: true, headLabel: "modelNgroup", uniController: true });
                break;
            case "SPF-AUTO-CLONING-DATA":
                setAutoCloning(true);
                break;
            case "SPF-AUTO-UPDATE":
                setAutoUpdate(true);
                break;
            case "SPF-REBOOT-SCHEDULE":
            default:
                alert("준비중입니다.");
                break;
        }
    };

    const getGroupList = () => {
        _API.get({ path: "/business/groups", data: {} })
            .then((res) => {
                console.log("api /business/groups", res);
                // setDataList(res?.data?.result);
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    const newGroupList = res?.data?.result?.map((m) => convertGroupFormat(m));
                    setGroupList(newGroupList);
                }
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
            });
    };

    const convertGroupFormat = (data) => {
        if (data && data?.group_id) {
            return { ...data, enabled: true, expanded: false, id: data.group_id, label: data.group_name, child: data?.lower_groups?.map((m) => convertGroupFormat(m) || []) };
        }
        return [];
    };

    const onGroupSet = () => {
        setGroupSetting(true);
    };

    const onClickGroup = (id) => {
        setSideMenu(false);
        const ccc = (i, l) => {
            if (l) {
                if (l.id == i) {
                    setMyGroup(l);
                }
                if (l.id == i || l?.child?.filter((f) => ccc(i, f))?.length > 0) {
                    return true;
                }
            }
            return false;
        };
        const selectTopGroupItem = groupList?.filter((f) => ccc(id, f))[0];
        setSelectGroupInfo(selectTopGroupItem);
        if (locState) {
            setLocState({ ...locState, groupId: id });
        } else {
            setLocState({ groupId: id });
        }
        setFirstLoad(false);
    };

    const onUniControl = (modelName, groupList) => {
        // const dummyResData = {
        //     status: {
        //         code: "200",
        //         message: "Success",
        //     },
        //     result: {
        //         main_device: "device_id_15",
        //         device_list: ["device_id_15", "device_id_16", "device_id_17", "device_id_18", "device_id_3", "device_id_4", "device_id_5", "device_id_6", "device_id_7", "device_id_8", "device_id_9"],
        //         groups: ["아시아 > 일본", "아시아 > 한국"],
        //     },
        // };
        if (modelName && groupList?.length > 0) {
            const params = {};
            params.model = modelName;
            params.groups = groupList.map((m) => m.group_id);
            _API.post({ path: "/device/devices/uni-control", data: params })
                .then((res) => {
                    console.log("api /devices/uni-control", res);
                    if (!res?.data?.result?.device_list || res?.data?.result?.device_list?.length === 0) {
                        // 모든 그룹에 타겟 모델이 없는경우
                        Dialog({
                            title: t("popup.title.inform"),
                            text: t("unicontroller.msg.notargetmodel.all"),
                            button: t("common.ok"),
                        }).then((id) => {
                            if (id) {
                            }
                        });
                    }
                    // else if (result === 2) {
                    //     // 일부그룹에 타겟모델이 없는 경우 : 삭제될듯 하다고 함
                    //     Dialog({
                    //         title: t("popup.title.inform"),
                    //         body: uniControllerMessage(t("unicontroller.msg.notargetmodel.one"), t("common.label.excludedgroupslist"), dummyResData?.result?.groups),
                    //         button: t("common.ok"),
                    //     }).then((id) => {
                    //         if (id) {
                    //         }
                    //     });
                    // }
                    // else if (result === 3) {
                    //     // 일부그룹 전체가 라이선스 미할당 디바이스로 구성되어 있는 경우 : 라이선스 관련 항목은 삭제
                    //     Dialog({
                    //         title: t("popup.title.inform"),
                    //         body: uniControllerMessage(t("unicontroller.msg.nolicense.one"), t("common.label.excludedgroupslist"), dummyResData?.result?.groups),
                    //         button: t("common.ok"),
                    //     }).then((id) => {
                    //         if (id) {
                    //         }
                    //     });
                    // } else if (result === 4) {
                    //     //모든 그룹이 라이선스 미할당 디바이스로 구성되어 있는경우
                    //     Dialog({
                    //         title: t("popup.title.inform"),
                    //         body: uniControllerMessage(t("unicontroller.msg.nolicense.all")),
                    //         button: t("common.ok"),
                    //     }).then((id) => {
                    //         if (id) {
                    //         }
                    //     });
                    // }
                    else if (res?.data?.result?.main_device) {
                        setUniControlDevice({ ...res?.data?.result, model_name: modelName });
                    }
                    saveTargetModel({ open: false });
                })
                .catch((e) => {
                    console.log("api error /devices/uni-control", e);
                    saveTargetModel({ open: false });
                });
        }
    };

    const onCreateGroup = () => {
        if (groupList?.length > 0) {
            const pId = groupList?.filter((f) => f?.group_name?.toLowerCase() === "unassigned")[0]?.group_id;
            setGroupAddModal({ open: true, pId: pId });
        }
    };

    useEffect(() => {
        document.title = t("device.label.devices") + " | " + t("common.title.lgconnectedcare");
        location.state && setLocState(location.state);
    }, [location]);

    useEffect(() => {
        if (groupList?.length > 0) {
            if (!locState?.group_id) {
                onClickGroup(groupList?.filter((f) => f?.group_name?.toLowerCase() === "unassigned")[0]?.group_id);
            } else {
                onClickGroup(locState?.group_id);
            }
        }
    }, [groupList]);

    useEffect(() => {
        if (targetModel?.groupList?.length > 0 && targetModel?.model_name && targetModel?.uniController) {
            onUniControl(targetModel?.model_name, targetModel?.groupList);
        }
    }, [targetModel]);

    useEffect(() => {
        getGroupList();
    }, [workspace, lastUpdateTime]);

    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>{t("device.label.devices")}</h1>
                </div>

                <LastUpdate installationCode />
            </div>
            <div className="content-middle wide-padding">
                <div className={cx({ "site-group-list-container": true, active: sideMenu })}>
                    <div className="header header-site">
                        <button type="button" className="btn btn-back" onClick={() => setSideMenu(false)}>
                            <span class="ir">{t("subtext.menu.collapse")}</span>
                        </button>
                        <div className="title">{t("device.label.devices")}</div>
                    </div>
                    <div className="child-menu-container">
                        <Features title={t("common.label.specialized.features")} items={featureItems} onClick={(id) => onClickFeatures(id)} treeUlOn={(e) => setTreeUlOn(e)} />

                        <Tree
                            title={t("common.labe.alldevices")}
                            subTitle={t("am.list.group")}
                            onSettingClick={() => onGroupSet()}
                            onClick={(id) => onClickGroup(id)}
                            items={groupList}
                            current={locState?.groupId}
                            onCreateGroup={onCreateGroup}
                            treeUlOn={treeUlOn}
                        />
                    </div>
                </div>

                <div className="content-middle-box">
                    {locState?.groupId && (
                        <DeviceList
                            selectGroupInfo={selectGroupInfo}
                            myGroup={myGroup}
                            locState={locState}
                            onChangeGroup={getGroupList}
                            onClickGroup={onClickGroup}
                            uniControlDevice={uniControlDevice}
                            onSideMenu={() => setSideMenu(true)}
                        />
                    )}
                </div>
            </div>
            {monitoring && (
                <MonitoringSetting
                    id="popup-monitor-setting"
                    title={t("subtext.label.device.monitoring.settings")}
                    className="popup popup-alert-message popup-monitor-setting"
                    onClose={() => setMonitoring(false)}
                    close
                    noFooter
                />
            )}
            {savingMode && <EnergySaving onClose={() => setSavingMode(false)} />}
            {autoCloning && <AutoCloning onClose={() => setAutoCloning(false)} />}
            {autoUpdate && (
                <AutoUpdate
                    onClose={() => {
                        setAutoUpdate(false);
                    }}
                />
            )}
            {groupSetting && (
                <GroupSettingModal
                    onClose={() => {
                        getGroupList();
                        setGroupSetting(false);
                    }}
                />
            )}
            {groupAddModal?.open && (
                <CreateGroupModal
                    pId={groupAddModal?.pId}
                    onClose={() => {
                        getGroupList();
                        setGroupAddModal({ open: false });
                    }}
                />
            )}
        </Fragment>
    );
};

const DeviceList = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const scrollRef = useRef(null);

    const [controllerModal, setControllerModal] = useState({ open: false });
    const [assignModal, setAssignModal] = useState(false);
    const [unAssignModal, setUnAssignModal] = useState(false);
    const [groupModal, setGroupModal] = useState(false);

    const [checkAll, setCheckAll] = useState(false);
    const [viewType, setViewType] = useState("grid");
    const [dataList, setDataList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [filterList, setFilterList] = useState(dummyFilterList(t) || []);
    const [dataListTotal, setDataListToatal] = useState(0);
    const [paging, setPaging] = useState(null);
    const [loading, setLoading] = useState(true);
    const [groupId, setGroupId] = useState(null);
    const [tooltip, setTooltip] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const onClickLicense = () => {
        setAssignModal(true);
    };

    const onClickController = () => {
        if (dataList?.filter((f) => f.checked === true)?.length > 0) {
            setControllerModal({ open: true, deviceList: dataList?.filter((f) => f.checked === true) });
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.message.select.no.item"),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        }
    };

    const onClickInfo = () => {
        setTooltip(true);
    };

    const onCheckAll = () => {
        const newList = dataList?.map((m) => ({ ...m, checked: !checkAll }));
        setDataList(newList);
        setCheckAll(!checkAll);
    };

    const onCheck = (item) => {
        const newList = dataList?.map((m) => (item && m.device_id === item?.device_id ? { ...m, checked: !item?.checked } : m));
        setDataList(newList);
        if (newList.filter((f) => f.checked === true)?.length === newList.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    };

    const selectModeClose = () => {
        const newList = dataList?.map((m) => ({ ...m, checked: false }));
        setDataList(newList);
        setCheckAll(false);
    };

    const onChangeKeyword = (e) => {
        const value = e.target.value;
        // win + . 이모지 입력 방지 정규식
        const reg =
            /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        if (reg.test(value)) return;
        setKeyword(value);
    };

    const onSearchKeywordDel = () => {
        setKeyword("");
        onDataLoad({
            search: "",
            page: 1,
            filter: getFilterResult(),
            group_id: groupId || null,
        });
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onDataLoad({
                search: keyword,
                page: 1,
                filter: getFilterResult(),
                group_id: groupId || null,
            });
        }
    };

    // const onFilterCheck = (item) => {
    //     const newFilterList = [...filterList];
    //     newFilterList?.forEach((f, index) => {
    //         f?.items?.forEach((s) => {
    //             let checked = s?.checked === true ? false : true;
    //             s?.items?.forEach((t) => {
    //                 if (t.pCode === item.pCode && t.code === item.code) {
    //                     let tChecked = t?.checked === true ? false : true;
    //                     // checkedCnt += tChecked * 1;
    //                     t.checked = tChecked;
    //                 } else if (s.pCode === item.pCode && s.code === item.code) {
    //                     t.checked = checked;
    //                 }
    //             });

    //             if (s.pCode === item.pCode && s.code === item.code) {
    //                 s.checked = checked;
    //             } else if (s?.items?.length > 0 && s?.items?.filter((si) => si.checked === true)?.length === s?.items?.length) {
    //                 s.checked = true;
    //             }
    //         });
    //     });
    //     setFilterList(newFilterList);
    // };

    const setLocationFilter = (filter) => {
        const newFilterList = [...filterList];
        newFilterList?.forEach((f, index) => {
            const checkFilter = filter ? filter[f.code] : [];
            if (f) {
                f?.items?.forEach((s) => {
                    if (s) {
                        if (s?.items?.length > 0) {
                            const dFilter = checkFilter ? checkFilter[s.code] : [];
                            s?.items?.forEach((t) => {
                                if (t) {
                                    t.checked = false;
                                    if (dFilter?.indexOf(t.code) > -1) {
                                        t.checked = true;
                                    }
                                }
                            });
                            s.checked = false;
                            if (s?.items?.length === s?.items?.filter((sf) => sf.checked)?.length) {
                                s.checked = true;
                            }
                        } else {
                            if (f.code === "issue_status" && s.code === "normal") {
                                s.checked = checkFilter?.status?.indexOf("normal") > -1;
                            } else {
                                s.checked = checkFilter?.indexOf(s.code) > -1;
                            }
                        }
                    }
                });
            }
        });
        setFilterList(newFilterList);
        return newFilterList;
    };

    const getFilterResult = (paramFilter) => {
        const resultFilter = {};
        const newFilterList = paramFilter ? [...paramFilter] : [...filterList];
        newFilterList?.forEach((f, index) => {
            if (f) {
                let addFilter = f?.items?.filter((f) => f?.items?.length > 0)?.length > 0 ? {} : [];
                let valueCnt = 0;
                if (f.code === "issue_status") {
                    addFilter["status"] = [];
                }
                f?.items?.forEach((s) => {
                    if (s && s?.items?.length > 0) {
                        addFilter[s.code] = [];
                        s?.items?.forEach((t) => {
                            if (t?.checked) {
                                addFilter[s?.code].push(t?.code);
                                valueCnt++;
                            }
                        });
                        if (f?.code === "issue_status" && addFilter[s?.code].length > 0) {
                            addFilter["status"].push(s.code);
                            valueCnt++;
                        }
                    } else {
                        if (f?.code === "issue_status") {
                            if (s?.checked) {
                                addFilter["status"].push(s?.code);
                                valueCnt++;
                            }
                        } else {
                            if (s?.checked) {
                                addFilter.push(s?.code);
                                valueCnt++;
                            }
                        }
                    }
                });
                if (valueCnt > 0) {
                    resultFilter[f?.code] = addFilter;
                }
            }
        });
        if (Object.keys(resultFilter)?.length === 0) {
            return null;
        }
        return resultFilter;
    };

    const filterReset = () => {
        const newFilterList = [...filterList];
        newFilterList?.forEach((f, index) => {
            f?.items?.forEach((s) => {
                if (s) {
                    s.checked = false;
                    s?.items?.forEach((t) => {
                        if (t) {
                            t.checked = false;
                        }
                    });
                }
            });
        });
        setFilterList(newFilterList);
        onDataLoad({
            search: keyword,
            page: 1,
            filter: null,
            group_id: groupId || null,
        });
        setFilterOpen(false);
    };

    const filterApply = (paramFilterList) => {
        setFilterOpen(false);
        setFilterList(paramFilterList);
        onDataLoad({
            search: keyword,
            page: 1,
            filter: getFilterResult(paramFilterList),
            group_id: groupId || null,
        });
    };

    const goDetail = (idx, tabId) => {
        idx && navigate(`/devices/detail/${idx}`, { state: { keyword, filter: filterList, group_id: groupId, tabId: tabId || null, viewType } });
    };

    const onExcelDownload = () => {
        const params = {
            search: keyword,
            filter: getFilterResult(),
            group_id: groupId || null,
            format: "excel",
        };

        _API.get({ path: "/device/devices", data: params })
            .then((res) => {
                const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                const excel = res?.data;
                const downloadLink = document.createElement("a");
                const fileName = res?.headers["content-disposition"].split("filename=")[1];
                downloadLink.href = mediaType + excel;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            })
            .catch((e) => {
                console.log("api error /device/devices?format=excel", e);
            });
    };

    const onDataLoad = (params, append) => {
        setLoading(true);
        _API.get({ path: "/device/devices", data: { ...params, per_page: 10, format: "json" } })
            .then((res) => {
                append && setDataList([...dataList, ...res?.data?.result?.data]?.map((m) => ({ ...m, checked: false })));
                !append && setDataList(res?.data?.result?.data?.map((m) => ({ ...m, checked: false })));
                setCheckAll(false);
                setDataListToatal(res?.data?.result?.total || 0);
                setPaging(res?.data?.result?.paging);
                setSearchText(params.search || "");
                setLoading(false);
            })
            .catch((e) => {
                console.log("api error /device/devices", e);
                setLoading(false);
            });
    };

    const nextPage = () => {
        onDataLoad(
            {
                search: keyword,
                page: paging?.next_page || 1,
                filter: getFilterResult(),
                group_id: groupId || null,
                last_id_key: dataList && dataList.length > 0 ? "device_id" : null,
                last_id_value: dataList && dataList.length > 0 ? dataList[dataList.length - 1]?.device_id : null,
            },
            true
        );
    };

    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowRight" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    useEffect(() => {
        if (props.locState) {
            const params = { search: keyword, page: 1, filter: getFilterResult(), group_id: groupId };
            if (props.locState?.connection_status) {
                params.filter = setLocationFilter(props.locState);
            }
            if (props.locState?.filter) {
                params.filter = setFilterList(props.locState?.filter);
            }
            if (props.locState?.keyword) {
                setKeyword(props.locState?.keyword);
                params.search = props.locState?.keyword;
            }
            if (props.locState?.groupId) {
                setGroupId(props.locState?.groupId);
                params.group_id = props.locState?.groupId;
            }
            if (props.locState?.viewType) {
                setViewType(props.locState?.viewType);
            }
            onDataLoad(params);
        }
    }, [props.locState]);

    const goDetailUni = (uniControlDevice) => {
        navigate(`/devices/detail/${uniControlDevice.main_device}`, { state: { keyword, filter: filterList, group_id: groupId, tabId: "_control", viewType, uniControlDevice } });
    };

    useEffect(() => {
        props.uniControlDevice && goDetailUni(props.uniControlDevice);
    }, [props?.uniControlDevice]);

    return (
        <Fragment>
            {loading && <Loading />}
            <div className="content-middle-top">
                <button type="button" className="btn btn-open-child" onClick={props.onSideMenu}>
                    <span className="ir">{t("history.open_sub")}</span>
                </button>
                <h2 className="title-content">{props ? props?.myGroup?.label : ""}</h2>
                <div className="title-info">
                    {/* {t("subtext.device.subgroups.devices.cnt")} : */}
                    <span className="font-blue">({props.myGroup?.device_count || 0})</span>
                </div>
            </div>

            <div className="search-and-filter-box">
                <h3 className="title-inner">{t("log.label.devicelist")}</h3>

                {(!dataList || dataList?.filter((f) => f.checked === true)?.length === 0) && (
                    <div className="filter-box">
                        <div className="left-side">
                            {/* <button type="button" className="btn btn-license" onClick={() => onClickLicense()}>
                            {t("license.popup.label.license")}
                        </button> */}
                            <button type="button" className="btn btn-quick-controller" onClick={() => onClickController()}>
                                {t("common.button.quick.controller")}
                            </button>
                        </div>

                        <div className="right-side">
                            <div className="tooltip-container">
                                <span className="tooltip-label">
                                    <button type="button" id="" className="btn btn-info" onClick={() => onClickInfo()}>
                                        <span className="ir">{t("common.button.search.tooltip.ir")}</span>
                                    </button>

                                    <div className="tooltip bottom info">
                                        <div className="tooltip-body">{t("devicemanagement.search.placeholder")}</div>
                                    </div>
                                </span>
                            </div>

                            <div className="search-box2">
                                <div className="search-inner-box">
                                    <input
                                        type="text"
                                        id="search"
                                        className="search search_b"
                                        name="search"
                                        placeholder="Search"
                                        autoComplete="true"
                                        aria-label="Search"
                                        value={keyword}
                                        onChange={onChangeKeyword}
                                        onKeyDown={onKeyDown}
                                    />
                                    {loading && (
                                        <div className="search-loading">
                                            <span className="ir">{t("subtext.loading")}</span>
                                        </div>
                                    )}
                                    <button
                                        type="button"
                                        className="btn-search"
                                        onClick={() =>
                                            onDataLoad({
                                                search: keyword,
                                                page: 1,
                                                filter: getFilterResult(),
                                                group_id: groupId || null,
                                            })
                                        }
                                    >
                                        <span className="ir">{t("button.search")}</span>
                                    </button>
                                    <button type="button" className={cx({ "btn-input-remove": true, hide: keyword ? false : true })} onClick={() => onSearchKeywordDel()}>
                                        <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                    </button>
                                </div>
                                {/* <div className="search-inner-box">
                                    <input
                                        type="text"
                                        id="search"
                                        className="search search_b"
                                        name="search"
                                        placeholder="Search"
                                        autoComplete="true"
                                        aria-label="Search"
                                        value={keyword}
                                        onChange={onChangeKeyword}
                                        onKeyDown={onKeyDown}
                                    />
                                    <button type="button" className={cx({ "btn-input-remove": true, hide: keyword ? false : true })} onClick={() => onSearchKeywordDel()}>
                                        <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                    </button>
                                </div> */}
                            </div>

                            <button
                                type="button"
                                id="btn-filter-option"
                                className={`btn btn-filter ${
                                    filterList?.filter((f) => f?.items?.filter((s) => s?.checked === true || s?.items?.filter((t) => t?.checked === true)?.length > 0)?.length > 0)?.length > 0
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => setFilterOpen(true)}
                                title={t("player.button.apply")}
                            >
                                {t("user.filter")}
                                {filterList?.filter((f) => f?.items?.filter((s) => s?.checked === true || s?.items?.filter((t) => t?.checked === true)?.length > 0)?.length > 0)?.length > 0 && (
                                    <span className="ir">{t("dashboard.new.title")}</span>
                                )}
                            </button>

                            {filterOpen && <Filter data={filterList} filterApply={filterApply} filterReset={filterReset} onClose={() => setFilterOpen(false)} />}

                            <div className="tab-group" role="radiogroup" ref={tabRef}>
                                <div className={`tab last grid ${viewType === "grid" ? "active" : ""}`}>
                                    <a
                                        id="data-grid-type"
                                        href="#!"
                                        role="radio"
                                        aria-checked={viewType === "grid"}
                                        onClick={() => setViewType("grid")}
                                        tabIndex={viewType === "grid" ? 0 : -1}
                                        onKeyDown={onTabAreaKeyDown}
                                    >
                                        <span className="ir">{t("subtext.grid")}</span>
                                    </a>
                                </div>

                                <div className={`tab first list ${viewType === "table" ? "active" : ""}`}>
                                    <a
                                        id="data-table-type"
                                        href="#!"
                                        role="radio"
                                        aria-checked={viewType === "table"}
                                        onClick={() => setViewType("table")}
                                        tabIndex={viewType === "table" ? 0 : -1}
                                        onKeyDown={onTabAreaKeyDown}
                                    >
                                        <span className="ir">{t("subtext.list")}</span>
                                    </a>
                                </div>
                            </div>

                            <button type="button" id="" className="btn btn-download-excel" onClick={onExcelDownload}>
                                <span className="ir">{t("common.button.download.excel.ir")}</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side">
                        {viewType === "grid" && (
                            <div className="field-type-checkbox">
                                <div className="field-form">
                                    <div className="checkbox">
                                        <input type="checkbox" name="checkbox-select-all" id="checkbox-select-all" checked={checkAll} onChange={(e) => onCheckAll(e)} />
                                        <label htmlFor="checkbox-select-all">{t("common.selectAll")}</label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="right-side">
                        <div className="total-count">
                            {t("common.total")} : <span className="count">{dataListTotal || 0}</span>
                        </div>
                    </div>
                </div>

                {viewType === "grid" ? (
                    <GridList
                        data={dataList}
                        paging={paging}
                        searchText={searchText}
                        nextPage={nextPage}
                        onCheck={onCheck}
                        goDetail={goDetail}
                        myGroup={props.myGroup}
                        onClickGroup={props.onClickGroup}
                    />
                ) : (
                    <TableList
                        data={dataList}
                        paging={paging}
                        searchText={searchText}
                        nextPage={nextPage}
                        onCheck={onCheck}
                        goDetail={goDetail}
                        myGroup={props.myGroup}
                        onClickGroup={props.onClickGroup}
                        checkAll={checkAll}
                        onCheckAll={onCheckAll}
                    />
                )}
                <div className={`select-mode-options ${dataList?.filter((f) => f.checked === true)?.length > 0 ? "expand" : ""}`}>
                    <div className="left-side">
                        <h2 aria-live="polite">
                            {dataList?.filter((f) => f.checked === true)?.length} {t("dv.table.selected")}
                        </h2>
                        <div className="button-box">
                            <button type="button" className="btn btn-small btn-dxp btn-quick-controller" onClick={() => onClickController()}>
                                {t("common.button.quick.controller")}
                            </button>
                            <button type="button" className="btn btn-small btn-dxp btn-change-group" onClick={() => setGroupModal(true)}>
                                {t("common.button.change.group")}
                            </button>
                        </div>
                    </div>

                    <div className="right-side">
                        <button type="button" className="btn-icon btn-close" onClick={() => selectModeClose()}>
                            <span className="ir">{t("button.close")}</span>
                        </button>
                    </div>
                </div>
            </div>
            {controllerModal.open && <ControllerModal deviceList={controllerModal.deviceList} onClose={() => setControllerModal({ open: false })} />}
            {assignModal && <AssignModal onClose={() => setAssignModal(false)} />}
            {unAssignModal && <UnAssignModal onClose={() => setUnAssignModal(false)} />}
            {groupModal && (
                <GroupModal
                    currentGroupId={groupId}
                    checkList={dataList?.filter((f) => f.checked)}
                    onClose={() => setGroupModal(false)}
                    onDone={() => {
                        onDataLoad({
                            keyword,
                            page: 1,
                            filter: getFilterResult(),
                            group_id: groupId || null,
                        });
                        props.onChangeGroup && props.onChangeGroup();
                    }}
                />
            )}
        </Fragment>
    );
};

const dummyFilterList = (t) => {
    return [
        {
            label: t("device.label.devicetype"),
            code: "device_type",
            items: [
                { label: t("common.code.device.type.signage"), code: "signage", pCode: "device_type", checked: false },
                { label: t("common.code.device.type.led"), code: "led", pCode: "device_type", checked: false },
                { label: t("common.code.device.type.mp"), code: "mp", pCode: "device_type", checked: false },
                { label: t("common.code.device.type.idb"), code: "idb", pCode: "device_type", checked: false },
                { label: t("common.code.device.type.signagetv"), code: "signagetv", pCode: "device_type", checked: false },
                { label: t("common.code.device.type.display"), code: "display", pCode: "device_type", checked: false },
            ],
        },
        {
            label: t("table.head.os"),
            code: "os_type",
            items: [
                { label: t("dv.device.webOS"), code: "webos", pCode: "os_type", checked: false },
                { label: t("dv.device.windows"), code: "windows", pCode: "os_type", checked: false },
                { label: t("dv.device.android"), code: "android", pCode: "os_type", checked: false },
                { label: t("player.label.display"), code: "display", pCode: "os_type", checked: false },
            ],
        },
        {
            label: t("filter.title.connection.status"),
            code: "connection_status",
            items: [
                { label: t("common.label.connected"), code: "connected", pCode: "connection_status", checked: false },
                { label: t("common.label.ConnectedNoSignal"), code: "connected_no_signal", pCode: "connection_status", checked: false },
                { label: t("common.label.DisconnectedScreenOff"), code: "disconnected_screen_off", pCode: "connection_status", checked: false },
                { label: t("common.label.disconnected"), code: "disconnected", pCode: "connection_status", checked: false },
            ],
        },
        {
            label: t("filter.title.issueStatus"),
            code: "issue_status",
            items: [
                {
                    label: t("common.error"),
                    code: "errors",
                    pCode: "issue_status",
                    checked: false,
                    items: [
                        // { label: t("dashboard.label.displaytemperature"), code: "GEN-01-TEM" },
                        // { label: t("filter.title.fan.status"), code: "GEN-01-FAN" },
                        // { label: t("filter.title.continuous.usage.time"), code: "GEN-01-CON" },
                        // { label: t("distribution.label.storageFree"), code: "GEN-01-DSK" },
                        // { label: t("filter.title.response.media.player"), code: "GEN-01-RES" },
                        // { label: t("filter.title.no.vidoe.signal.to.display"), code: "GEN-01-SIG" },
                        // { label: t("failure.prediction.title"), code: "GEN-01-FAP" },
                        // { label: t("filter.title.diagnosis"), code: "GEN-01-DIA" },
                        // { label: t("dv.panel.blu"), code: "GEN-01-BLU" },
                        // { label: t("filter.title.pixel"), code: "GEN-01-PXL" },
                        // { label: t("sensor.label.filteralarm"), code: "GEN-01-FLT" },
                        // { label: t("sensor.label.impactdetection"), code: "GEN-01-IMP" },
                        // { label: t("device.label.nosignalpoweroff"), code: "GEN-01-NSP" },
                        // { label: t("device.label.noirpoweroff"), code: "GEN-01-NIP" },
                        // { label: t("sensor.label.humidity"), code: "GEN-01-HUM" },
                        // { label: t("filter.title.door.open"), code: "GEN-01-DOO" },
                        // { label: t("player.label.volume"), code: "GEN-01-VOL" },
                        // { label: t("filter.title.no.audio.signal.to.display"), code: "GEN-01-AUD" },

                        { label: t("player.label.temperature"), code: "TEM", pCode: "issue_status_errors", checked: false },
                        { label: t("distribution.label.storageFree"), code: "DSK", pCode: "issue_status_errors", checked: false },
                        { label: t("player.label.fan"), code: "FAN", pCode: "issue_status_errors", checked: false },
                        { label: t("common.label.continuoususagetime"), code: "CON", pCode: "issue_status_errors", checked: false },
                        { label: t("device.label.response"), code: "RES", pCode: "issue_status_errors", checked: false },
                        { label: t("player.label.signal"), code: "SGN", pCode: "issue_status_errors", checked: false },
                        { label: t("common.label.nosignalpoweroff"), code: "NSP", pCode: "issue_status_errors", checked: false },
                        { label: t("common.label.NoIRPowerOff"), code: "NIP", pCode: "issue_status_errors", checked: false },
                        { label: t("common.label.Ethernet"), code: "ETH", pCode: "issue_status_errors", checked: false },
                        { label: t("player.label.power"), code: "PWR", pCode: "issue_status_errors", checked: false },
                        { label: t("common.msg.detection"), code: "CLD", pCode: "issue_status_errors", checked: false },
                        { label: t("sensor.label.humidity"), code: "HUM", pCode: "issue_status_errors", checked: false },
                        { label: t("device.label.door"), code: "DRO", pCode: "issue_status_errors", checked: false },
                        { label: t("dv.panel.blu"), code: "BLU", pCode: "issue_status_errors", checked: false },
                        { label: t("filter.title.pixel"), code: "PXL", pCode: "issue_status_errors", checked: false },
                        { label: t("sensor.label.filteralarm"), code: "FLT", pCode: "issue_status_errors", checked: false },
                        { label: t("common.label.Impact"), code: "IMP", pCode: "issue_status_errors", checked: false },
                        { label: t("player.label.volume"), code: "VOL", pCode: "issue_status_errors", checked: false },
                        { label: t("common.label.SignalCheck"), code: "AUD", pCode: "issue_status_errors", checked: false },
                        { label: t("common.label.VolumeRagne"), code: "CVO", pCode: "issue_status_errors", checked: false },
                        { label: t("device.label.usblock"), code: "CUL", pCode: "issue_status_errors", checked: false },
                        { label: t("issue.type.misconfiguration"), code: "DIF", pCode: "issue_status_errors", checked: false },
                        { label: t("dashboard.label.cpuusage"), code: "CPU", pCode: "issue_status_errors", checked: false },
                        { label: t("dashboard.label.memoryusage"), code: "MEM", pCode: "issue_status_errors", checked: false },
                    ],
                },
                { label: t("device.label.normal"), code: "normal", pCode: "issue_status", checked: false },
            ],
        },
        // {
        //     label: t("filter.title.license.status"),
        //     code: "license_status",
        //     items: [
        //         { label: t("filter.title.license.in.used"), code: "in_used" },
        //         { label: t("filter.title.license.expired.soon"), code: "expired_soon" },
        //         { label: t("filter.title.license.unassigned"), code: "unassigned" },
        //         { label: t("filter.title.license.expired"), code: "expired" },
        //     ],
        // },
    ];
};

const GridList = (props) => {
    const { t } = useTranslation();

    const [dataList, setDataList] = useState([]);
    const [openPopupInfo, setOpenPopupInfo] = useState({});

    const onCheck = (item) => {
        props?.onCheck && props?.onCheck(item);
    };

    const onImagePopup = (item) => {
        setOpenPopupInfo({
            id: "data-img-popup-" + item?.idx,
            imgUrl: item?.thumbnail_url?.length > 0 ? item?.thumbnail_url[0] : item?.thumbnail_url?.large ? item?.thumbnail_url?.large : "../../assets/images/sample/thumb_sample_01.png",
            imgAlt: item?.imgAlt,
        });
    };

    const goDetail = (item) => {
        props?.goDetail && props?.goDetail(item?.device_id);
    };

    const errDetail = (item) => {
        props?.goDetail && props?.goDetail(item?.device_id, "_openIssue");
    };

    useEffect(() => {
        setDataList(props?.data);
    }, [props?.data]);

    return (
        <Fragment>
            <div className="grid tab-panel panel-data-grid-type" role="tabpanel">
                <div className="grid-elements list" id="scrollableDiv">
                    <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={dataList ? dataList?.length : 0}
                        next={props?.nextPage}
                        hasMore={props?.paging && props?.paging?.has_next}
                        loader={""}
                        scrollableTarget="scrollableDiv"
                    >
                        {props?.myGroup?.lower_groups?.length > 0 &&
                            props?.myGroup?.lower_groups?.map((m, index) => (
                                <div className="element element-depth2" key={index}>
                                    <div className="element-top image-box">
                                        <div className="dim">
                                            <a href="#!" aria-label={t("dv.group.select")} aria-expanded="false" aria-haspopup="true" onClick={() => props?.onClickGroup(m?.group_id)} role="button">
                                                <img src="../../assets/images/sample/thumb_sample.png" alt={t("common.folder")} />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="element-middle">
                                        <div className="title-box">
                                            <dl className="model_name">
                                                <dt>
                                                    {m?.group_name} <span>({m?.device_count})</span>
                                                </dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {dataList?.map((item, index) => {
                            return (
                                <div className="element" key={`element-${index}`}>
                                    <div className="element-select">
                                        <div className="left-side">
                                            <div className="field-type-checkbox">
                                                <div className="field-form">
                                                    <div className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name={`checkbox-select-device-${item?.device_id}`}
                                                            id={`checkbox-select-device-${item?.device_id}`}
                                                            aria-labelledby={`device-model-name-${item?.device_id}`}
                                                            onChange={() => onCheck(item)}
                                                            checked={item?.checked || false}
                                                        />
                                                        <label htmlFor={`checkbox-select-device-${item?.device_id}`}>
                                                            <span className="ir">{t("common.choice")}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="right-side">
                                            &nbsp;
                                            <div className={`device-state ${item?.license_status === "expire" ? "expired" : item?.license_status === "remain" ? "dday" : ""}`}>
                                                {item?.license_status === "expire"
                                                    ? t("common.lebel.license.expired")
                                                    : item?.license_status === "remain"
                                                    ? t("license.popup.label.license") + " D-" + (item?.licenseRemainDay || 0)
                                                    : t("common.lebel.license.unassigned")}
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="element-top image-box">
                                        <div className="dim">
                                            {/* <a
                                                href="#!"
                                                aria-label={item?.imgAlt || "device image"}
                                                // aria-expanded={"data-img-popup-" + item?.device_id === openPopupInfo?.idx}
                                                aria-haspopup="true"
                                                aria-controls={"data-img-popup-" + item?.device_id}
                                                role="button"
                                            >
                                            </a> */}
                                            {["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 && (
                                                <span className="icon">
                                                    <span className="ir">{t("common.label.disconnected")}</span>
                                                </span>
                                            )}
                                            <img
                                                src={
                                                    item?.thumbnail_url?.length > 0
                                                        ? item?.thumbnail_url[0]
                                                        : item?.thumbnail_url?.small
                                                        ? item?.thumbnail_url?.small
                                                        : "../../assets/images/sample/thumb_sample_01.png"
                                                }
                                                alt={item?.model_name + " | " + item?.device_name}
                                                onError={(e) => (e.target.src = "../../assets/images/sample/thumb_sample_01.png")}
                                            />
                                            {item?.thumbnail_url?.large && (
                                                <button type="button" className="icon2" onClick={() => onImagePopup(item)}>
                                                    <span className="ir">{t("common.label.expand")}</span>
                                                </button>
                                            )}

                                            {/* <a href="#!" className="device-error"><span>{item?.errCnt}</span></a> */}
                                            {item?.issue_count > 0 && (
                                                <a
                                                    href="#!"
                                                    className={cx({ "device-error": true, "disabled-type": ["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 })}
                                                    onClick={() => errDetail(item)}
                                                >
                                                    <span className="ir">{t("common.error")}</span>
                                                    <span>{item?.issue_count || 0}</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>

                                    <div className="element-middle">
                                        <div className="title-box">
                                            <dl className="model_name" id={`device-model-name-${item?.device_id}`}>
                                                <dt>{_U.searchTextBold(props?.searchText, item?.model_name)}</dt>
                                                <dd>{_U.searchTextBold(props?.searchText, item?.device_name)}</dd>
                                            </dl>
                                        </div>
                                        <div className="device-model">
                                            <dl>
                                                <dt>{t("device.label.devicetype")}</dt>
                                                <dd>{item?.device_type ? t("common.code.device.type." + item?.device_type.toLowerCase()) : ""}</dd>
                                            </dl>
                                            <dl>
                                                <dt>{t("table.head.os")}</dt>
                                                <dd>{_Device.getOsTypeName(t, item?.os_type)}</dd>
                                            </dl>
                                            <dl>
                                                <dt>{t("common.label.serialnumber")}</dt>
                                                <dd>{_U.searchTextBold(props?.searchText, item?.serial_number)}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <div className="element-bottom">
                                        <a
                                            href="#!"
                                            role="button"
                                            className={cx({ btn: true, "btn-detail": true, "disabled-type": ["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 })}
                                            onClick={() => goDetail(item)}
                                            disabled={["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1}
                                            tabIndex={["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 ? -1 : 0}
                                            aria-disabled={["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 ? true : false}
                                        >
                                            {t("common.details")}
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                        {(!dataList || dataList.length === 0) && (!props?.myGroup?.lower_groups || props?.myGroup?.lower_groups?.length === 0) && (
                            <div className="element">{t("devicemanagement.search.noresult")}</div>
                        )}
                    </InfiniteScroll>
                </div>
                {openPopupInfo && openPopupInfo?.id && <ThumbnailModal info={openPopupInfo} onClose={() => setOpenPopupInfo({})} />}
            </div>
        </Fragment>
    );
};

const TableList = (props) => {
    const { t } = useTranslation();
    const [dataList, setDataList] = useState([]);
    const [openPopupId, setOpenPopupId] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const onCheck = (item) => {
        props?.onCheck && props?.onCheck(item);
    };

    const onImagePopup = (item) => {
        console.log("이미지 확장", item);
    };

    const goDetail = (item) => {
        props?.goDetail && props?.goDetail(item?.device_id);
    };

    const errDetail = (item) => {
        props?.goDetail && props?.goDetail(item?.device_id, "_openIssue");
    };

    useEffect(() => {
        setDataList(props?.data);
        resizeTable(props?.paging?.has_next, setWindowWidth);
    }, [props?.data]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    return (
        <Fragment>
            <div className=" table tab-panel panel-data-table-type" role="tabpanel">
                <div className="table-wrapper device-list horizontal-scroll select-mode" id="dataListTableWrapper">
                    <div className="table-box">
                        <InfiniteScroll
                            style={{ overflowX: "auto", overflowY: "hidden" }}
                            dataLength={dataList ? dataList?.length : 0}
                            next={props?.nextPage}
                            hasMore={props?.paging && props?.paging?.has_next && windowWidth > 991}
                            loader={""}
                            scrollableTarget="scrollableTbody"
                        >
                            <table>
                                <caption>
                                    {t("log.label.devicelist")} {t("subtext.select.all.cell")}
                                </caption>
                                <thead>
                                    <tr>
                                        <td className="align-center">
                                            <div className="field field-type-checkbox">
                                                <div className="field-form">
                                                    <div className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="checkbox-device "
                                                            id="checkbox-device"
                                                            aria-label="Select Device"
                                                            checked={props?.checkAll}
                                                            onChange={(e) => props?.onCheckAll(e)}
                                                        />
                                                        <label htmlFor="checkbox-device">
                                                            <span className="ir">{t("common.selectAll")}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <th scope="col">
                                            <div className="text-block">{t("device.label.devicetype")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("table.head.os")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("deivcemanagement.title.connection")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("player.label.modelName")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("table.head.devicename")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("common.label.serialnumber")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("filter.title.issueStatus")}</div>
                                        </th>
                                        {/* <th scope="col">
                                            <div className="text-block">{t("filter.title.license.status")}</div>
                                        </th> */}
                                        <th scope="col">
                                            <div className="text-block">{t("common.details")}</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="scrollableTbody">
                                    {props.myGroup?.lower_groups?.length > 0 &&
                                        props.myGroup?.lower_groups?.map((m, index) => (
                                            <tr key={index}>
                                                <td colSpan="9" className="td-depth2" onClick={() => props.onClickGroup(m.group_id)}>
                                                    {m?.group_name} <span>({m?.device_count})</span>
                                                </td>
                                            </tr>
                                        ))}
                                    {dataList?.map((item, index) => {
                                        return (
                                            <tr key={`element-${index}`}>
                                                <td className="align-center">
                                                    <div className="field field-type-checkbox">
                                                        <div className="field-form">
                                                            <div className="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    name={`checkbox-select-deviceT-${item?.device_id}`}
                                                                    id={`checkbox-select-deviceT-${item?.device_id}`}
                                                                    // aria-label={`Select Device ${item?.device_id}`}
                                                                    aria-labelledby={`deviceList-td2-${index} deviceList-td3-${index} deviceList-td4-${index} deviceList-td5-${index} deviceList-td6-${index} deviceList-td7-${index} deviceList-td8-${index} deviceList-td9-${index}`}
                                                                    onChange={() => onCheck(item)}
                                                                    checked={item?.checked || false}
                                                                />
                                                                <label htmlFor={`checkbox-select-deviceT-${item?.device_id}`}>
                                                                    <span className="ir">{t("select.row.desc")}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id={`deviceList-td2-${index}`}>{item?.device_type ? t("common.code.device.type." + item?.device_type.toLowerCase()) : ""}</td>
                                                <td id={`deviceList-td3-${index}`}>{_Device.getOsTypeName(t, item?.os_type)}</td>
                                                <td id={`deviceList-td4-${index}`}>
                                                    {dummyFilterList(t)
                                                        ?.filter((f) => f.code === "connection_status")[0]
                                                        ?.items?.filter((f) => f.code === item?.connection_status)[0]?.label || "-"}
                                                </td>
                                                <td id={`deviceList-td5-${index}`}>{_U.searchTextBold(props.searchText, item?.model_name)}</td>
                                                <td id={`deviceList-td6-${index}`}>{_U.searchTextBold(props.searchText, item?.device_name)}</td>
                                                <td id={`deviceList-td7-${index}`}>{_U.searchTextBold(props.searchText, item?.serial_number)}</td>
                                                <td id={`deviceList-td8-${index}`}>
                                                    {/* {item?.issue?.issue_status || "-"} */}
                                                    {item?.issue_count > 0 ? (
                                                        <span
                                                            className={cx({ "link-target": true, "disabled-type": ["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 })}
                                                            onClick={() => errDetail(item)}
                                                            aria-labelledby={`deviceList-td2-${index} deviceList-td3-${index} deviceList-td4-${index} deviceList-td5-${index} deviceList-td6-${index} deviceList-td7-${index} deviceList-td8-${index} deviceList-td9-${index}`}
                                                        >
                                                            {item?.issue_count || ""}
                                                        </span>
                                                    ) : (
                                                        <span>{item?.issue_count || ""}</span>
                                                    )}
                                                </td>
                                                {/* <td>{item?.license_status || "-"}</td> */}
                                                <td className="align-center" id={`deviceList-td9-${index}`}>
                                                    <a
                                                        href="#!"
                                                        role="button"
                                                        className={cx({
                                                            btn: true,
                                                            "btn-detail": true,
                                                            "disabled-type": ["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1,
                                                        })}
                                                        onClick={() => goDetail(item)}
                                                        aria-labelledby={`deviceList-td2-${index} deviceList-td3-${index} deviceList-td4-${index} deviceList-td5-${index} deviceList-td6-${index} deviceList-td7-${index} deviceList-td8-${index} deviceList-td9-${index}`}
                                                        disabled={["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1}
                                                        tabIndex={["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 ? -1 : 0}
                                                        aria-disabled={["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 ? true : false}
                                                    >
                                                        {t("subtext.go.detail")}
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {(!dataList || dataList.length === 0) && (!props.myGroup?.lower_groups || props.myGroup?.lower_groups?.length === 0) && (
                                        <tr>
                                            <td colSpan={9} className="align-center">
                                                {t("devicemanagement.search.noresult")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>

            <div className="convert-table-to-list" role="table" aria-label="Device List">
                <div className="table-header" role="rowgroup">
                    <div className="row" role="columnheader">
                        <div className="thead" role="columnheader" aria-sort="none">
                            {t("device.label.devicetype")}
                        </div>
                        <div className="thead" role="columnheader" aria-sort="none">
                            {t("common.label.serialnumber")}
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    style={{ overflow: "hidden" }}
                    dataLength={dataList?.length}
                    next={props?.nextPage}
                    hasMore={props?.paging && props?.paging?.has_next && windowWidth <= 991}
                    loader={""}
                >
                    <ul className="table-body">
                        {dataList?.map((item, index) => {
                            return (
                                <li className="table-row" key={`reflow-${index}`}>
                                    <div className="summary-data" role="rowgroup">
                                        <div className="row" role="row">
                                            <div className="table-cell device-type center" role="cell" aria-label="Device type">
                                                {/* <img src="../../assets/images/device_type/device_type_icon_display_n.svg" alt={item?.deviceType} /> */}
                                                {item?.device_type ? t("common.code.device.type." + item?.device_type.toLowerCase()) : ""}
                                            </div>
                                            <div className="table-cell model-name" role="cell" aria-label="Model name">
                                                <button type="button" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                    {item?.model_name}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">{t("table.head.os")}</span>
                                                <span className="field-content">{item?.os_type}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("deivcemanagement.title.connection")}</span>
                                                <span className="field-content">
                                                    {dummyFilterList(t)
                                                        ?.filter((f) => f.code === "connection_status")[0]
                                                        ?.items?.filter((f) => f.code === item?.connection_status)[0]?.label || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("player.label.modelName")}</span>
                                                <span className="field-content">{item?.model_name}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("table.head.devicename")}</span>
                                                <span className="field-content">{item?.device_name}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("common.label.serialnumber")}</span>
                                                <span className="field-content">{item?.serial_number}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("filter.title.issueStatus")}</span>
                                                <span className="field-content">
                                                    {item?.issue_count > 0 ? (
                                                        <span
                                                            className={cx({ "link-target": true, "disabled-type": ["disconnected", "disconnected_screen_off"].indexOf(item?.connection_status) > -1 })}
                                                            onClick={() => errDetail(item)}
                                                            aria-labelledby={`deviceList-td2-${index} deviceList-td3-${index} deviceList-td4-${index} deviceList-td5-${index} deviceList-td6-${index} deviceList-td7-${index} deviceList-td8-${index} deviceList-td9-${index}`}
                                                        >
                                                            {item?.issue_count || ""}
                                                        </span>
                                                    ) : (
                                                        <span>{item?.issue_count || ""}</span>
                                                    )}
                                                </span>
                                            </li>
                                            {/* <li>
                                                <span className="field-label">{t("filter.title.license.status")}</span>
                                                <span className="field-content">{item?.license_status || "-"}</span>
                                            </li> */}
                                            <li className="middle">
                                                <span className="field-label">{t("common.details")}</span>
                                                <span className="field-content">
                                                    <a href="#!" role="button" className="btn btn-detail" onClick={() => goDetail(item)}>
                                                        {t("subtext.go.detail")}
                                                    </a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            );
                        })}
                        {(!dataList || dataList.length === 0) && (!props.myGroup?.lower_groups || props.myGroup?.lower_groups?.length === 0) && (
                            <li className="table-row">
                                <div className="summary-data">
                                    <div className="row">{t("devicemanagement.search.noresult")}</div>
                                </div>
                            </li>
                        )}
                    </ul>
                </InfiniteScroll>
            </div>
        </Fragment>
    );
};

const Filter = (props) => {
    const { t } = useTranslation();
    const { filterApply: filterApplyProps } = props;
    const [dataList, setDataList] = useState([]);
    const [btnApply, setBtnApply] = useState(true);
    const [btnReset, setBtnReset] = useState(true);

    const filterRef = useRef(null);
    const applyRef = useRef(btnApply);
    const dataListRef = useRef(dataList);
    const filterApplyRef = useRef(null);
    const outfocusOnOffRef = useRef(true);

    const onCheck = (e) => {
        setBtnApply(false);
        applyRef.current = false;
        // props.onFilterCheck && props.onFilterCheck(e);
        onFilterCheckOne(e);
    };

    const openCheckBox = (e, item) => {
        // console.log("item", item);
        // e?.target?.classList?.toggle("expand");
        const newFilterList = [...dataList];
        newFilterList?.forEach((f, index) => {
            if (f) {
                f?.items?.forEach((s) => {
                    if (s && s.pCode === item?.pCode && s.code === item?.code && !item?.expand) {
                        s["expand"] = true;
                    } else {
                        s["expand"] = false;
                    }
                });
            }
        });
        setDataList(newFilterList);
        dataListRef.current = newFilterList;
    };

    const filterApply = () => {
        setBtnApply(true);
        applyRef.current = true;
        filterApplyProps && filterApplyProps(dataList);
    };

    const filterReset = () => {
        outfocusOnOffRef.current = false;
        Dialog({
            title: t("main.settingsPage.reset"),
            text: t("model.popup.resetDetail"),
            button: [t("button.cancel"), t("main.settingsPage.reset")],
        }).then((id) => {
            if (id) {
                setBtnReset(true);
                props?.filterReset && props?.filterReset();
            } else {
                outfocusOnOffRef.current = true;
            }
        });
    };

    const onFilterCheck = (filterList) => {
        const newFilterList = [...filterList];
        let checkCnt = 0;
        newFilterList?.forEach((f, index) => {
            f?.items?.forEach((s) => {
                s?.checked && checkCnt++;
                s?.items?.forEach((t) => {
                    t?.checked && checkCnt++;
                });
            });
        });
        checkCnt > 0 && setBtnReset(false);
    };

    const onFilterCheckOne = (item) => {
        const newFilterList = [...dataList];
        newFilterList?.forEach((f, index) => {
            if (f) {
                f?.items?.forEach((s) => {
                    if (s) {
                        let checked = s?.checked === true ? false : true;
                        s?.items?.forEach((t) => {
                            if (t) {
                                if (t.pCode === item.pCode && t.code === item.code) {
                                    let tChecked = t?.checked === true ? false : true;
                                    // checkedCnt += tChecked * 1;
                                    t.checked = tChecked;
                                } else if (s.pCode === item.pCode && s.code === item.code) {
                                    t.checked = checked;
                                }
                            }
                        });

                        if (s?.pCode === item?.pCode && s?.code === item?.code) {
                            s.checked = checked;
                        } else if (s?.items?.length > 0 && s?.items?.filter((si) => si?.checked === true)?.length === s?.items?.length) {
                            s.checked = true;
                        }
                    }
                });
            }
        });
        setDataList(newFilterList);
        dataListRef.current = newFilterList;
    };

    const onClose = () => {
        if (!applyRef.current) {
            Dialog({
                title: t("button.cancel"),
                text: t("model.popup.cancletDetail"),
                button: [t("model.popup.exit"), t("model.popup.apply")],
            }).then((id) => {
                if (id) {
                    filterApplyRef.current && filterApplyRef.current(dataListRef.current);
                } else {
                    props.onClose && props.onClose();
                }
            });
        } else {
            props.onClose && props.onClose();
        }
    };

    useEffect(() => {
        dataListRef.current = JSON.parse(JSON.stringify(props.data));
        setDataList(JSON.parse(JSON.stringify(props.data)));
        filterApplyRef.current = filterApplyProps;
    }, [props?.data, filterApplyProps]);

    useEffect(() => {
        onFilterCheck(dataList);
    }, [dataList]);

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (filterRef.current && !filterRef.current.contains(e.target)) {
                if (outfocusOnOffRef.current) {
                    if (!applyRef.current) {
                        Dialog({
                            title: t("button.cancel"),
                            text: t("model.popup.cancletDetail"),
                            button: [t("model.popup.exit"), t("model.popup.apply")],
                        }).then((id) => {
                            if (id) {
                                filterApplyRef.current && filterApplyRef.current(dataListRef.current);
                            } else {
                                props.onClose && props.onClose();
                            }
                        });
                    } else {
                        props.onClose && props.onClose();
                    }
                }
            }
        }
        document.addEventListener("mousedown", handleOutside);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
        };
    }, [filterRef]);

    return (
        <Fragment>
            <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
                <div id="popup-filter-option" className="filter-options show expand" role="dialog" ref={filterRef}>
                    <div className="filter-options-top">
                        <h2 className="title-pc">{t("user.filter")}</h2>
                        <button type="button" className="btn-close" onClick={onClose}>
                            <span className="ir">{t("button.close")}</span>
                        </button>
                    </div>

                    <div className="filter-options-middle">
                        <div className="option-box">
                            {dataList?.map((item, index) => {
                                return (
                                    <div className="field field-type-checkbox field-issue-statue" key={index}>
                                        <h3 className="field-label">{item?.label}</h3>
                                        <div className="field-form">
                                            <div className="field-box not-flex">
                                                {item?.items?.map((subItem, sIndex) => {
                                                    return (
                                                        <div className="checkbox" key={sIndex}>
                                                            <input type="checkbox" id={`filter-checkbox-${index}-${sIndex}`} checked={subItem?.checked || false} onChange={(e) => onCheck(subItem)} />
                                                            <label htmlFor={`filter-checkbox-${index}-${sIndex}`}>{subItem?.label}</label>

                                                            {subItem?.items?.length > 0 && (
                                                                <Fragment>
                                                                    <button
                                                                        type="button"
                                                                        id=""
                                                                        className={`btn-icon btn-open-checkbox ${subItem?.expand === true ? "expand" : ""}`}
                                                                        aria-expanded={subItem?.expand}
                                                                        onClick={(e) => openCheckBox(e, subItem)}
                                                                    >
                                                                        <span className="ir">{t("history.open_sub")}</span>
                                                                    </button>
                                                                    <div className="field-box not-flex">
                                                                        {subItem?.items?.map((tItem, tIndex) => {
                                                                            return (
                                                                                <div className="checkbox" key={tIndex}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`filter-checkbox-${index}-${sIndex}-${tIndex}`}
                                                                                        checked={tItem?.checked || false}
                                                                                        onChange={(e) => onCheck(tItem)}
                                                                                    />
                                                                                    <label htmlFor={`filter-checkbox-${index}-${sIndex}-${tIndex}`}>{tItem?.label}</label>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </Fragment>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="button-box apply">
                            <button type="button" className="btn btn-dxp btn-apply" onClick={filterApply} disabled={btnApply}>
                                {t("player.button.apply")}
                            </button>
                            <button type="button" className="btn btn-dxp btn-reflash" onClick={filterReset} disabled={btnReset}>
                                {t("main.settingsPage.reset")}
                            </button>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </Fragment>
    );
};

const ThumbnailModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal title={t("common.preview")} id={props?.info?.id} className="popup-image-view large" onClose={props?.onClose} noFooter close>
            <div className="popup-content">
                <div className="image-container">
                    <img
                        className="img"
                        src={props ? props?.info?.imgUrl : "../../assets/images/sample/thumb_sample_01.png"}
                        alt={t("subtext.device.monitor.image")}
                        onError={(e) => (e.target.src = "../../assets/images/sample/thumb_sample_01.png")}
                    />
                </div>
                {props?.info?.imgAlt && (
                    <div className="desc-container">
                        <span className="semantic">*</span>
                        <span>{props?.info?.imgAlt}</span>
                    </div>
                )}
            </div>
        </Modal>
    );
};

const GroupSettingModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal title={t("am.list.group")} id={"popup-set-target"} className="popup-set-target" onClose={props.onClose} noFooter close>
            <GroupSettings />
        </Modal>
    );
};

const uniControllerMessage = (message, subTitle, groupList) => {
    return (
        <Fragment>
            <div className="description whitespace-pre-line">{message}</div>
            {groupList && groupList.length > 0 && (
                <div className="field field-type-message">
                    <div className="field-header">
                        <h2 className="field-title">{subTitle}</h2>
                    </div>
                    <div className="field-form">
                        <div className="message-target-wrap">
                            <dl className="message-target-info">
                                <dd className="message-target">
                                    {groupList?.map((item, index) => {
                                        return (
                                            <div className="message-target-item" key={index}>
                                                {item}
                                            </div>
                                        );
                                    })}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

const CreateGroupModal = (props) => {
    const { t } = useTranslation();
    const { pId } = props;
    const { saveInnerLoading } = useContext(AppContext);
    const [groupName, setGroupName] = useState("");

    const onChangeGroupName = (val) => {
        if (val?.startsWith(" ")) {
            val = val?.trimStart();
        }
        if (val?.length > 30) {
            val = val?.substring(0, 30);
        }
        setGroupName(val);
    };

    const onSaveGroup = () => {
        if (groupName) {
            if (groupName?.toLowerCase() === "unassigned") {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("subtext.error.DuplicateGroupName"),
                    button: t("common.ok"),
                }).then((ok) => {
                    if (ok) {
                    }
                });
            } else {
                saveInnerLoading && saveInnerLoading(true);
                const params = {};
                params.group_name = groupName;
                params.target_group_uuid = pId;
                _API.post({ path: `/business/groups`, data: params })
                    .then((res) => {
                        if (res?.data?.result) {
                            console.log(`/business/groups`, res?.data?.result);
                        }
                        saveInnerLoading && saveInnerLoading(false);
                        props.onClose && props.onClose();
                    })
                    .catch((e) => {
                        console.log("api error /business/groups", e);
                        saveInnerLoading && saveInnerLoading(false);
                    });
            }
        }
    };

    useEffect(() => {}, []);
    return (
        <Modal title={t("common.button.create.group")} id="popup-select-file" className="popup-edit-device-name popup-type no-scroll" onClose={props.onClose} onClick={() => onSaveGroup()}>
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <div className="field field-type-input field-unique-class">
                <label htmlFor="name" className="field-label pop-label">
                    {t("devicemanagement.group.name")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="input-box pop-input">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={groupName}
                                placeholder={t("devicemanagement.group.name")}
                                autocomplete="false"
                                onChange={(e) => onChangeGroupName(e?.target?.value)}
                                aria-invalid="true"
                                maxLength={30}
                            />
                            <button type="button" className={cx({ "btn-input-remove": true, hide: groupName ? false : true })} onClick={() => setGroupName("")}>
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
