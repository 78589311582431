import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cx from "classnames";
import qs from "query-string";
import FocusTrap from "focus-trap-react";

import useCss from "@hook/useCss";
import { SelectBox } from "@component/UI/Form";

import _API from "@util/api";
import { AppContext } from "@component/AppProvider";
import _U from "@util/utilities";
import Dialog from "@component/UI/Dialog";

export default () => {
    useCss("/assets/css/mobile_QRcode.css");

    const { t } = useTranslation();
    const location = useLocation();
    const { auth, workspace, businessList } = useContext(AppContext);
    const [selectRole, setSelectRole] = useState(null);
    const [searchParam, setSearchParam] = useState({});
    const [alertModal, setAlertModal] = useState(null);
    const [regist, setRegist] = useState(false);
    const [workspaceList, setWorkspaceList] = useState([]);

    const getWorkSpaceList = () => {
        const list = [];
        businessList?.map((item, index) => {
            if (item) {
                list.push({ label: item.business_name, value: item.business_id, em: "B", aClassName: "btn-Business", liClassName: "pointerevent-none-opacity0-4" });
                item?.properties?.map((sitem, sindex) => {
                    list.push({ label: sitem.property_name, value: sitem.property_id, data: sitem, key: "ㅤㅤ", em: "W", aClassName: "btn-Workspace", business_id: item.business_id });
                });
            }
        });
        return list;

        // _API.get({ path: "/device/devices/qr/workspace", data: {} })
        //     .then((res) => {
        //         console.log("api /device/devices/qr/workspace", res);
        //         if (res?.data?.result && res?.data?.result?.length > 0) {
        //             const newList = res?.data?.result?.map((item) => ({ label: item.property_name, value: item.property_id, em: "W", aClassName: "btn-Workspace" }));
        //             setWorkspaceList(newList);
        //         }
        //     })
        //     .catch((e) => {
        //         console.log("api error /business/groups", e);
        //     });
    };

    const onChangeData = (key, value) => {
        const newData = JSON.parse(JSON.stringify(searchParam));
        newData[key] = value;
        setSearchParam(newData);
    };

    const onCancel = () => {
        setAlertModal({ cancel: true });
    };
    const onRegist = () => {
        if (!selectRole?.value) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.required.msg", { 0: t("common.label.workspace") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (!searchParam.serial_number) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.required.msg", { 0: t("common.label.serialnumber") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (!searchParam.model_name) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.required.msg", { 0: t("player.label.modelName") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else if (!searchParam.device_name) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.required.msg", { 0: t("devicemanagement.title.deviceName") }),
                button: t("common.ok"),
            }).then((id) => {
                if (id) {
                }
            });
        } else {
            _API.post({ path: "/device/devices/qr/regist", data: { ...searchParam, business_id: selectRole.business_id, property_id: selectRole.property_id } })
                .then((res) => {
                    console.log("api /device/devices/qr/regist", res);
                    setRegist(true);
                    setAlertModal({ complete: true });
                })
                .catch((e) => {
                    console.log("api error /business/groups", e);
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("popup.case6.title"),
                        button: t("common.ok"),
                    }).then((id) => {
                        setRegist(true);
                        setAlertModal({ complete: true });
                    });
                });
        }
    };
    const onSignout = () => {
        Dialog({
            title: t("login.label.signout"),
            text: t("popup.signout.confirm"),
            button: [t("button.cancel"), t("login.label.signout")],
        }).then((id) => {
            if (id) {
                _API.get({ path: "/user/auth/logout", data: {} })
                    .then((res) => {
                        _API.onAccessLog("logout");
                        if (res?.data?.result) {
                            saveAuth(null);
                            if (window.opener) window.close();
                            else location.href = process.env.BUSINESS_CLOUD_URL;
                        }
                    })
                    .catch((e) => {
                        console.log("api error /user/auth/logout", e);
                    });
            }
        });
    };
    const onCancelConfirm = () => {
        window.close();
        setAlertModal(null);
    };
    const onRegistConfirm = () => {
        setAlertModal(null);
    };

    useEffect(() => {
        setSelectRole({ ...workspace, label: workspace.property_name, value: workspace.property_id, em: "W", aClassName: "btn-Workspace" });
    }, [workspace]);

    useEffect(() => {
        setSearchParam(qs.parse(location.search));
    }, [location]);

    return (
        <Fragment>
            <div className="mobile-info-body">
                <div className="wrapper">
                    <div className="mobile-info-wrap">
                        <div className="mobile-logo">
                            <img src="../../assets/images/business_cloud_logo.svg" alt="LG Business Cloud" />
                        </div>
                        <dl className="myAccount-info">
                            <dt>{t("admin.label.accountid")}</dt>
                            <dd>{auth?.lge_user_id}</dd>
                        </dl>

                        {!regist && (
                            <Fragment>
                                <SelectBox
                                    id={"workspace-name"}
                                    items={getWorkSpaceList()}
                                    value={selectRole?.value || ""}
                                    ariaLabelId={""}
                                    selectLabel={selectRole?.label || ""}
                                    onChange={(item) => setSelectRole(item)}
                                    selectLabelClass={selectRole?.em === "W" ? "btn-Workspace" : "btn-Business"}
                                    selectLabelEm={selectRole?.em}
                                    title={t("subtext.title.select.option")}
                                />
                                <div>
                                    <div className="icon-box">
                                        <img src="../../assets/images/ic_devicetype_fill.svg" alt={t("subtext.main.icon")} />
                                    </div>

                                    <div className="field">
                                        <label htmlFor="serial-number" className="field-title">
                                            {t("common.label.serialnumber")}
                                        </label>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <input
                                                    type="text"
                                                    id="serial-number"
                                                    readOnly
                                                    placeholder="xxxx-xxxx-xxxx-xxxx"
                                                    value={searchParam.serial_number || ""}
                                                    onChange={(e) => onChangeData("serial_number", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label htmlFor="model-name" className="field-title">
                                            {t("player.label.modelName")}
                                        </label>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <input
                                                    type="text"
                                                    id="model-name"
                                                    readOnly
                                                    placeholder="TR3DK"
                                                    value={searchParam.model_name || ""}
                                                    onChange={(e) => onChangeData("model_name", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label htmlFor="device-name" className="field-title">
                                            {t("table.head.devicename")}
                                        </label>
                                        <div className="field-form">
                                            <div className="field-box">
                                                <input
                                                    type="text"
                                                    id="device-name"
                                                    readOnly
                                                    placeholder="TR3DK"
                                                    value={searchParam.device_name || ""}
                                                    onChange={(e) => onChangeData("device_name", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        <div className="button-box">
                            <button type="button" className={cx({ btn: true, "btn-huge": true, "btn-tertiary": true, hide: regist })} onClick={onCancel}>
                                {t("button.cancel")}
                            </button>
                            <button type="button" className={cx({ btn: true, "btn-huge": true, "btn-secondary": true, hide: regist })} onClick={onRegist}>
                                {t("main.btnRegister.title")}
                            </button>

                            <button type="button" id="btn-ip-signout" className={cx({ btn: true, "btn-huge": true, "btn-tertiary": true, hide: !regist })} onClick={onSignout}>
                                {t("login.label.signout")}
                            </button>
                        </div>
                    </div>

                    {alertModal && (
                        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
                            <div className="popup-wrapper mobile-info-pop show">
                                <div className="dim">&nbsp;</div>

                                {alertModal?.cancel && (
                                    <div className="popup">
                                        <h1 className="popup-label">{t("common.label.RegistrationCancelled")}</h1>
                                        <div className="button-box">
                                            <button type="button" className="btn btn-huge btn-secondary" onClick={onCancelConfirm}>
                                                {t("common.ok")}
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {alertModal?.complete && (
                                    <div className="popup">
                                        <h1 className="popup-label">{t("common.label.RegistrationCompleted")}</h1>
                                        <div className="popup-content">{t("common.message.checkdevice.lgcc")}</div>
                                        <div className="button-box">
                                            <button type="button" className="btn btn-huge btn-secondary" onClick={onRegistConfirm}>
                                                {t("common.ok")}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </FocusTrap>
                    )}
                </div>
            </div>
        </Fragment>
    );
};
