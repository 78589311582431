import React from "react";
import { useTranslation } from "react-i18next";

export default (props) => {
    const { t } = useTranslation();

    return (
        <div className="loading">
            <div className="loading-icon"></div>
            <span>{t("common.label.Loading")}...</span>
        </div>
    );
};
