import { AppContext } from "@component/AppProvider";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";

import Filter from "./Filter";
import { useTranslation } from "react-i18next";
import { InputCalendar } from "@component/UI/Form";
import moment from "moment";
import useCss from "@hook/useCss";
import Dialog from "@component/UI/Dialog";

const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 1);

const Period = ({ title, onSearch, total, filterMenu, onExcelDownload, onOpenSideMenu, searchPlaceholder, noSearch = false }) => {
    useCss("/assets/css/history.css");

    const { t } = useTranslation();
    const {
        historyPeriod,
        historyPeriod: { dateType, keyword, filter },
        setHistoryPeriod,
        resetHistoryPeriod,
        periodStartDate,
        periodEndDate,
        setPeriodStartDate,
        setPeriodEndDate,
        auth: {
            user_info: { datetime_format },
        },
    } = useContext(AppContext);
    const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");

    useEffect(() => {
        const intervalId = setInterval(() => {
            jQuery(".ui-state-default").each(function () {
                let ariaLabel = $(this)?.attr("aria-label");
                let cleanedLabel = ariaLabel?.split("<")[0]?.trim();
                if (cleanedLabel) {
                    let hasOptions = ariaLabel?.includes("<option");
                    if (hasOptions) cleanedLabel += " days";
                    $(this).attr("aria-label", cleanedLabel);
                }
            });
            const $prevMonth = jQuery("span.ui-icon.ui-icon-circle-triangle-w");
            const $nextMonth = jQuery("span.ui-icon.ui-icon-circle-triangle-e");
            if ($prevMonth.text() !== "Previous Month") $prevMonth.text("Previous Month");
            if ($nextMonth.text() !== "Next Month") $nextMonth.text("Next Month");
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (datetime_format) setDateFormat(datetime_format.split(" ")[0]);
    }, [datetime_format]);

    const [showFilter, setShowFilter] = useState(false);

    const setSearchDate = (type) => {
        let startDate = null;
        let endDate = null;

        switch (type) {
            case "1DAY":
                startDate = moment().add(-1, "days").format("YYYY-MM-DD");
                endDate = moment().format("YYYY-MM-DD");
                break;
            case "7DAY":
                startDate = moment().add(-7, "days").format("YYYY-MM-DD");
                endDate = moment().format("YYYY-MM-DD");
                break;
            case "1MONTH":
                startDate = moment().add(-1, "month").format("YYYY-MM-DD");
                endDate = moment().format("YYYY-MM-DD");
                break;
            case "3MONTH":
                startDate = moment().add(-3, "month").format("YYYY-MM-DD");
                endDate = moment().format("YYYY-MM-DD");
                break;
            case "CUSTOM":
                startDate = periodStartDate;
                endDate = periodEndDate;
                break;
        }
        setHistoryPeriod({ ...historyPeriod, dateType: type });
        setPeriodStartDate(startDate);
        setPeriodEndDate(endDate);
    };

    const onChangeStartDate = (params) => {
        if (periodEndDate && moment(params.startDate) > moment(periodEndDate)) {
            Dialog({
                title: "LGE",
                text: t("common.label.StartDateDialog"),
                button: t("common.label.confirm"),
            }).then(() => {});
            return;
        } else {
            setHistoryPeriod({ ...historyPeriod, dateType: "CUSTOM" });
            setPeriodStartDate(params.startDate);
        }
    };

    const onChangeEndDate = (params) => {
        if (periodStartDate && moment(periodStartDate) > moment(params.endDate)) {
            Dialog({
                title: "LGE",
                text: t("common.label.StartDateDialog"),
                button: t("common.label.confirm"),
            }).then(() => {});
            return;
        } else {
            setHistoryPeriod({ ...historyPeriod, dateType: "CUSTOM" });
            setPeriodEndDate(params.endDate);
        }
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    };

    const onSetFilter = () => {
        setShowFilter(false);
        onSearch();
    };

    useEffect(() => {
        resetHistoryPeriod();
    }, []);

    return (
        <Fragment>
            <div className="content-middle-top">
                <button type="button" className="btn btn-open-child" onClick={onOpenSideMenu}>
                    <span className="ir">{t("history.open_sub")}</span>
                </button>
                <h2 className="title-content">{title}</h2>
            </div>

            <div className="period-box">
                <div className="field field-type-radio">
                    <label className="field-label">{t("report.label.period")}</label>
                    <div className="field-form">
                        <div className="field-box">
                            <div className="radio">
                                <input type="radio" name="searchDate" id="radio-1" value="1DAY" checked={dateType === "1DAY"} onClick={() => setSearchDate("1DAY")} />
                                <label htmlFor="radio-1">{t("history.last.1day")}</label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="searchDate" id="radio-2" value="7DAY" checked={dateType === "7DAY"} onClick={() => setSearchDate("7DAY")} />
                                <label htmlFor="radio-2">{t("history.last.7day")}</label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="searchDate" id="radio-3" value="1MONTH" checked={dateType === "1MONTH"} onClick={() => setSearchDate("1MONTH")} />
                                <label htmlFor="radio-3">{t("history.last.1month")}</label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="searchDate" id="radio-4" value="3MONTH" checked={dateType === "3MONTH"} onClick={() => setSearchDate("3MONTH")} />
                                <label htmlFor="radio-4">{t("history.last.3month")}</label>
                            </div>
                            <div className="radio">
                                <input type="radio" name="searchDate" id="radio-5" value="CUSTOM" checked={dateType === "CUSTOM"} onClick={() => setSearchDate("CUSTOM")} />
                                <label htmlFor="radio-5">{t("player.label.soundMode.custom")}</label>
                                <div className="custom field-datepicker">
                                    <div className="field-form flex">
                                        <InputCalendar
                                            name="startDate"
                                            className="period-start-date"
                                            id="period-start-date"
                                            placeholder="YYYY-MM-DD"
                                            autoComplete="false"
                                            ariaLabel={t("common.label.EnterStartDate")}
                                            ariaDescribedBy="datepickerLabel"
                                            calendarTitle="Start date pick"
                                            value={periodStartDate}
                                            onChange={onChangeStartDate}
                                            minDate={minDate}
                                            ir={t("dv.powerhidstory.from")}
                                        />
                                        <div className="separator">~</div>
                                        <InputCalendar
                                            name="endDate"
                                            className="period-end-date"
                                            id="period-end-date"
                                            placeholder="YYYY-MM-DD"
                                            autoComplete="false"
                                            ariaLabel={t("common.label.EnterEndDate")}
                                            ariaDescribedBy="datepickerLabel"
                                            calendarTitle="End date pick"
                                            value={periodEndDate}
                                            onChange={onChangeEndDate}
                                            minDate={minDate}
                                            ir={t("dv.powerhistory.to")}
                                        />
                                    </div>
                                    <div id="datepickerLabel" className="hide">
                                        {t("common.label.selectDate")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn btn-view" onClick={onSearch}>
                            {t("button.show")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="search-and-filter-box">
                <div className="filter-box">
                    <div className="left-side">
                        <dl className="history_list">
                            <dt>{title + " " + t("subtext.list")}</dt>

                            <dd>
                                {periodStartDate && moment(periodStartDate).format(dateFormat)}
                                {(periodStartDate || periodEndDate) && " ~ "}
                                {periodEndDate && moment(periodEndDate).format(dateFormat)}
                            </dd>
                        </dl>
                    </div>

                    <div className="right-side">
                        <div className="selected-count">
                            {t("common.total")} : <span className="count">{total || 0}</span>
                        </div>

                        {!noSearch && (
                            <div className="search-box2">
                                <div className="search-inner-box">
                                    <input
                                        type="text"
                                        id="search"
                                        className="search search_b"
                                        name="search"
                                        placeholder={searchPlaceholder ? searchPlaceholder : t("button.search")}
                                        autoComplete="true"
                                        aria-label={searchPlaceholder ? searchPlaceholder : t("button.search")}
                                        aria-describedby="description-id"
                                        onKeyDown={onKeyDown}
                                        value={keyword}
                                        onChange={(e) =>
                                            setHistoryPeriod({
                                                ...historyPeriod,
                                                keyword: e.target.value,
                                            })
                                        }
                                    />
                                    <button type="button" class="btn-search">
                                        <span class="ir">{t("button.search")}</span>
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn-input-remove ${keyword ? "" : "hide"}`}
                                        onClick={() =>
                                            setHistoryPeriod({
                                                ...historyPeriod,
                                                keyword: "",
                                            })
                                        }
                                    >
                                        <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="btn-filter-box">
                            <button
                                type="button"
                                id="btn-filter-option"
                                className={cx({ btn: true, "btn-filter": true, active: filter ? true : false })}
                                onClick={() => setShowFilter(true)}
                                title={t("player.button.apply")}
                            >
                                {t("user.filter")}
                                {filter && <span className="ir">{t("dashboard.new.title")}</span>}
                            </button>

                            <Filter show={showFilter} onSetFilter={onSetFilter} onClose={() => setShowFilter(false)} menu={filterMenu} />

                            <button type="button" id="" className="btn btn-download-excel" onClick={onExcelDownload}>
                                <span className="ir">{t("common.button.download.excel.ir")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Period;
