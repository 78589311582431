import moment from "moment";
import React, { createContext, useState } from "react";

const AppContext = createContext();
const AppProvider = ({ auth: authInProps, children }) => {
    const [auth, setAuth] = useState(authInProps);
    const [workspace, setWorkspace] = useState(null);
    const [businessList, setBusinessList] = useState();
    const [innerLoading, setInnerLoading] = useState(false);
    const [targetModel, setTargetModel] = useState({ open: false });
    const [product, setProduct] = useState(null);
    const [rolePermission, setRolePermission] = useState(null);
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [sideBarExpand, setSideBarExpand] = useState(false);
    const [hoverChild, setHoverChild] = useState(false);
    const [newNotice, setNewNotice] = useState(false);
    const [fileboxToolbar, setFileboxToolbar] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(false);

    // History 페이지 관련 공통 상태 처리
    const [historyPeriod, setHistoryPeriod] = useState({
        keyword: "",
        filter: null,
        dateType: "1DAY",
    });
    const [periodStartDate, setPeriodStartDate] = useState(moment().add(-1, "days").format("YYYY-MM-DD"));
    const [periodEndDate, setPeriodEndDate] = useState(moment().format("YYYY-MM-DD"));

    const saveAuth = (auth) => {
        setAuth(auth);
    };

    const saveWorkspace = (data) => {
        setWorkspace(data);
    };

    const saveBusinessList = (data) => {
        setBusinessList(data);
    };

    const saveInnerLoading = (data) => {
        setInnerLoading(data);
    };

    const saveTargetModel = (data) => {
        setTargetModel(data);
    };

    const resetHistoryPeriod = () => {
        setHistoryPeriod({
            dateType: "1DAY",
            keyword: "",
            filter: null,
        });
        setPeriodStartDate(moment().add(-1, "days").format("YYYY-MM-DD"));
        setPeriodEndDate(moment().format("YYYY-MM-DD"));
    };

    const saveProduct = (data) => {
        setProduct(data);
    };

    const saveRolePermission = (data) => {
        setRolePermission(data);
    };

    const saveLastUpdateTime = (data) => {
        setLastUpdateTime(data);
    };

    const saveSideBarExpand = (data) => {
        setSideBarExpand(data);
    };

    const saveHoverChild = (data) => {
        setHoverChild(data);
    };

    const saveNewNotice = (data) => {
        setNewNotice(data);
    };

    const saveFileboxToolbar = (data) => {
        setFileboxToolbar(data);
    };

    const saveShowAlertMessage = (data) => {
        setShowAlertMessage(data);
    };

    return (
        <AppContext.Provider
            value={{
                auth,
                saveAuth,
                workspace,
                saveWorkspace,
                businessList,
                saveBusinessList,
                innerLoading,
                saveInnerLoading,
                targetModel,
                saveTargetModel,
                historyPeriod,
                setHistoryPeriod,
                resetHistoryPeriod,
                product,
                saveProduct,
                rolePermission,
                saveRolePermission,
                periodStartDate,
                setPeriodStartDate,
                periodEndDate,
                setPeriodEndDate,
                lastUpdateTime,
                saveLastUpdateTime,
                sideBarExpand,
                saveSideBarExpand,
                hoverChild,
                saveHoverChild,
                newNotice,
                saveNewNotice,
                fileboxToolbar,
                saveFileboxToolbar,
                showAlertMessage,
                saveShowAlertMessage,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };
