import React from "react";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDv4 } from "uuid";
import { useTranslation } from "react-i18next";

import Paging from "./Paging";

/**
 * Summary Type
const summary = {
    enable:true,                            // Boolean              - Summary show or hide value
    button:(),                              // DOM Object           - Button dom object
    enableDownload:true,                    // Boolean              - Download To Excel Button show/hide 
    onExcelDownload:()=>{},                 // Function             - Excel Download Function
}           
/**/
/**         
 * Header Type          
const header = {            
    type:'horizontal'                       // String               - Table display case type
    enableCheckbox:true,                    // Boolean              - Use Checkbox
    checkedAll:false                        // Boolean              - All Checked
    onCheckChange:(id)=>{}                  // Function             - Checkbox Changed
    onClick:(id, item, sort)                 // Function             - Sort Click
    list:[                                  // Object               - thead > tr > th options
        {           
            id                              // String               - ID
            title                           // String               - Title
            enableSort                      // Boolean              - Enable Sort
            sort                            // String               - ascending // descending
            sortItem                        // String               - current sort item
            sortItems                       // Array (String)       - Sort Option
            calSpan                         // Integer              - default 1
            rowSpan                         // Integer              - default 1
            isMobile                        // Boolean              - Display Row in Mobile
        }
    ]
}
/**/
/**         
 * Body Type          
const list = [
    {
        id:                                 // String               - ID
        screenLabel:                        // String               - Screen Reader Text
        list:[
            {
                text                        // String
                children                    // DOM Object
                html                        // String               - HTML
                isMobile                    // Boolean              - Display Row in Mobile
            }
        ]
    }
]
/**/
/**         
 * Paging Type          
const paging = {
    start: 0, 
    totalSize: 0,
    pageSize: 1,
    startPageNo: 1,
    endPageNo: 1,
    prevBlockNo: 1,
    nextBlockNo: 1,
    finalPageNo: 1,
    prevPageNo: 1,
    nextPageNo: 1,
    virtualRecordNo: 1,
    pageNo: 1
}
/**/

const Table = (props) => {
    const { summary, header, caption, thead, tbody, list } = props;
    const { paging, onPageChange, numPerPage, numPerPages, onNumPerPageChange } = props;
    const id = UUIDv4();

    const { t } = useTranslation();

    const mobileHeader = [];
    header?.list.map((o, i) => {
        if (o.isMobile) {
            mobileHeader.push({ ...o, dataIndex: i });
        }
    });

    return (
        <div className="data-list-container">
            {summary?.enable && (
                <div className="data-list-top">
                    <div className="left-side">
                        {summary?.button}
                        {summary?.enableDownload && (
                            <button type="button" className="btn btn-download-excel" onClick={summary?.onExcelDownload}>
                                {t("tableExcelDownload")}
                            </button>
                        )}
                    </div>

                    {!isNull(summary?.current) && !isNull(summary?.total) && (
                        <div className="right-side">
                            <div className="selected-count">
                                Total : <span className="count">{summary.current}</span> items
                            </div>

                            <div className="separator">/</div>
                            <div className="total-count">
                                <span className="count">{summary.total}</span> items
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div
                className={cx({
                    "table-wrapper": true,
                    "double-thead": !header?.type,
                    "double-thead-example": !header?.type,
                    "no-scroll": !header?.type,
                    "horizontal-scroll": header?.type === "horizontal",
                })}
            >
                <div className="table-box">
                    <StyledTable>
                        <caption>{caption}</caption>
                        {thead || (
                            <thead>
                                <tr>
                                    {header?.enableCheckbox && (
                                        <th scope="col" className="sorting" aria-sort="descending">
                                            <div className="field field-type-checkbox">
                                                <div className="field-form">
                                                    <div className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name={`${id}-cb-all`}
                                                            aria-label={t("tableSelectAll")}
                                                            id={`${id}-cb-all`}
                                                            checked={header?.checkedAll}
                                                            onChange={header?.onCheckChange.bind(this, "CHECKBOX-ALL")}
                                                        />
                                                        <label htmlFor={`${id}-cb-all`}></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {header?.list.map((o, i) => (
                                        <Th key={i} {...o} onClick={header?.onClick} />
                                    ))}
                                </tr>
                            </thead>
                        )}
                        {tbody || (
                            <tbody>
                                {list?.map((o, i) => (
                                    <Td key={i} tableId={id} no={i} {...o} enableCheckbox={header?.enableCheckbox} onCheckChange={header?.onCheckChange} />
                                ))}
                            </tbody>
                        )}
                        {!tbody && (!list || list.length === 0) && (
                            <tbody>
                                <tr>
                                    <td colSpan={header?.enableCheckbox ? header?.list.length + 1 : header?.list.length}>
                                        <div className="nodata-text">{t("tableNoItem")}</div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </StyledTable>
                </div>
            </div>

            <div className="convert-table-to-list">
                <div className="table-header">
                    <div className="row">
                        {mobileHeader.map((item, i) => (
                            <div key={i} className="thead">
                                {item.title}
                            </div>
                        ))}
                    </div>
                </div>
                <ul className="table-body">
                    {list?.map((o, i) => (
                        <li key={i} className="table-row">
                            <div className="summary-data">
                                <div className="row">
                                    {mobileHeader.map((h, j) => {
                                        const content = o.list[h.dataIndex];
                                        if (j === 0) {
                                            return (
                                                <div key={j} className="table-cell device-type center" aria-label={h.title}>
                                                    {content.html ? <div dangerouslySetInnerHTML={{ __html: content.html }} /> : content.children || content.text}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={j} className="table-cell model-name" aria-label={content.text}>
                                                    <button type="button" className="btn btn-expand">
                                                        {content.text}
                                                    </button>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="all-data-box">
                                <ul className="all-data">
                                    {header?.list.map((item, j) => {
                                        const content = o.list[j];
                                        return (
                                            <li key={j}>
                                                <span className="field-label">{item.title}</span>
                                                <div className="field-content">{content.html ? <div dangerouslySetInnerHTML={{ __html: content.html }} /> : content.children || content.text}</div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {numPerPages && numPerPages.length > 0 && (
                <div className="select-box show-items">
                    <label htmlFor="select-items">Show items</label>
                    <select id="select-items" value={numPerPage} onChange={onNumPerPageChange}>
                        {numPerPages.map((p, i) => (
                            <option key={i} value={p}>
                                {p}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {paging && <Paging paging={paging} pageCallback={onPageChange} />}
        </div>
    );
};

const Th = (props) => {
    const { id, title, enableSort, sort, sortItem, sortItems, onClick, colSpan, rowSpan } = props;

    return (
        <th scope="col" className={cx({ sorting: enableSort })} aria-sort={sort} colSpan={colSpan || 1} rowSpan={rowSpan || 1}>
            {enableSort &&
                (sortItems?.length > 0 ? (
                    <div className="field field-type-dropdown field-account" aria-label={title}>
                        <div className="field-form">
                            {/* 현재 선택된 옵션은 aria-selected 속성 값을 true로 설정해주세요. */}
                            <div className="dropdown dropdown-type-a">
                                <a href="#!" role="listbox" aria-haspopup="listbox" className="btn-dropdown" aria-selected="false" title="Please Select option">
                                    {title}
                                </a>
                                <div className="dropdown-menu">
                                    <ul className="lists" role="listbox" aria-expanded="true">
                                        {sortItems?.map((o, i) => (
                                            <li key={i} className="list" role="none presentation">
                                                <a href="#!" role="option" aria-selected={sortItem === o} onClick={onClick?.bind(this, id, o, sort === "descending" ? "ascending" : "descending")}>
                                                    {o}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <button type="button" className="btn-sort" onClick={onClick?.bind(this, id, "", sort === "descending" ? "ascending" : "descending")}>
                        {title}
                    </button>
                ))}
            {!enableSort && title}
        </th>
    );
};

const Td = (props) => {
    const { tableId, no, enableCheckbox, onCheckChange } = props;
    const { id, screenLabel, list } = props;
    return (
        <tr>
            {enableCheckbox && (
                <td id={id} className="align-center">
                    <div className="field field-type-checkbox">
                        <div className="field-form">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    name="checkbox-select-device-18"
                                    id={`${tableId}-cb-${id || no}`}
                                    value={id}
                                    onChange={onCheckChange?.bind(this, id)}
                                    aria-labelledby={screenLabel}
                                />
                                <label htmlFor={`${tableId}-cb-${id || no}`}></label>
                            </div>
                        </div>
                    </div>
                </td>
            )}
            {list?.map((item, i) => (
                <td key={i} className={item.className}>
                    {item.html ? <div dangerouslySetInnerHTML={{ __html: item.html }} /> : item.children || item.text}
                </td>
            ))}
        </tr>
    );
};

const isNull = (val) => {
    if (val === undefined || val === null) {
        return true;
    }
    return false;
};

export default Table;

const StyledTable = styled.table`
    &:not(.has-not-header) thead {
        width: auto;
    }
`;
