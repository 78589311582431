import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChartArea = styled.div`
    margin-top: 1rem;
    display: flex;
    padding: 0 5% 0 10%;
    @media (max-width: 1301px) {
        padding-right: 1%;
    }
    @media (max-width: 1254px) {
        display: flex;
        padding: 0;
        align-items: center;
        flex-direction: column;
        margin-top: 1.6rem;
    }
`;

const ChartWrapper = styled.div`
    position: relative;
    width: 180px;
    height: 180px;
    .doughnut-area {
        height: 100%;
        position: relative;
        z-index: 99;
    }
`;
const ChartTitle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    > :first-child {
        font-size: 1.8rem;
        font-weight: 400;
        color: #555;
        line-height: 140%;
    }
    > :last-child {
        font-size: 3.2rem;
        font-weight: 700;
        color: #111;
        line-height: 130%;
    }
`;
const LegendWrapper = styled.div`
    margin-left: 2.8rem;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1254px) {
        margin-top: 4rem;
        width: 95%;
    }
    > div {
        display: flex;
        justify-content: space-between;
        color: #333;
        cursor: pointer;
        &:not(:last-child) {
            margin-bottom: 1.2rem;
        }
        > :first-child {
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 140%;
            > :first-child {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 5px;
                margin-bottom: 3px;
            }
        }
        > :last-child {
            font-size: 2rem;
            font-weight: 700;
            line-height: 130%;
            > :last-child {
                margin-left: 0.8rem;
            }
        }
    }
    .upcoming {
        display: block;
    }

    @media (max-width: 720px) {
        margin: 4rem 0 0 0;
        width: 100%;
    }
`;

export const DeviceDoughnutChart = ({ data, colorMode = "connection", upcomingExpired, dday, onClickDetail, isSubscribe = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [total, setTotal] = useState(0);
    const [fontSize, setFontSize] = useState("3.2rem");
    const [sortData, setSortData] = useState([]);
    const [dataCount, setDataCount] = useState([]);
    const [dataName, setDataName] = useState([]);
    const [colorList, setColorList] = useState([]);

    useEffect(() => {
        const sortedData = data.concat();
        const color = {
            connection: ["#8384DA", "#A5A6E6", "#CECEF1", "#E4E4F7"],
            license: ["#70CFD2", "#9EDFE1", "#CAEDEF", "#E2F5F6"],
        };
        const updatedSortData = sortedData.map((item, index) => ({
            ...item,
            isFilter: item.hasOwnProperty("isFilter") ? item.isFilter : false,
            color: color[colorMode][index],
        }));
        setSortData(updatedSortData);
        const total = updatedSortData.reduce((acc, item) => acc + item.count, 0);
        setTotal(total);
    }, [data]);

    useEffect(() => {
        const filteredData = sortData.filter((item) => !item.isFilter);
        const filteredCount = filteredData.map((item) => item.count);
        const filteredName = filteredData.map((item) => item.name);
        const filteredColor = filteredData.map((item) => item.color);
        setDataCount(filteredCount);
        setDataName(filteredName);
        setColorList(filteredColor);
    }, [sortData]);

    useEffect(() => {
        if (total) {
            const leng = total.toString().length;
            if (leng >= 15) {
                setFontSize("1.2rem");
                return;
            }
            if (leng >= 14) {
                setFontSize("1.3rem");
                return;
            }
            if (leng >= 13) {
                setFontSize("1.4rem");
                return;
            }
            if (leng >= 12) {
                setFontSize("1.5rem");
                return;
            }
            if (leng >= 11) {
                setFontSize("1.6rem");
                return;
            }
            if (leng >= 10) {
                setFontSize("1.8rem");
                return;
            }
            if (leng >= 9) {
                setFontSize("2rem");
                return;
            }
            if (leng >= 8) {
                setFontSize("2.2rem");
                return;
            }
            if (leng >= 7) {
                setFontSize("2.6rem");
                return;
            }
            if (leng >= 6) {
                setFontSize("3rem");
                return;
            }
        }
    }, [total]);

    const handleIsFilter = (index) => {
        !isSubscribe && setSortData((prevSortData) => prevSortData.map((item, i) => (i === index ? { ...item, isFilter: !item.isFilter } : item)));
    };

    const chartData = {
        labels: dataName,
        datasets: [
            {
                data: dataCount, // 여기에 실제 데이터를 넣으셔야 합니다.
                backgroundColor: colorList,
                hoverBackgroundColor: colorList,
                borderWidth: 1,
                width: 30,
            },
        ],
    };

    const clickToMovePage = (e, item) => {
        e.stopPropagation();
        switch (item.name) {
            case "Connected":
                onClickDetail({ status: "connected", statusName: t("common.label.connected") });
                break;
            case "Connected (No Signal)":
                onClickDetail({ status: "connected_no_signal", statusName: t("common.label.ConnectedNoSignal") });
                break;
            case "Disconnected (Screen Off)":
                onClickDetail({ status: "disconnected_screen_off", statusName: t("common.label.DisconnectedScreenOff") });
                break;
            case "Disconnected":
                onClickDetail({ status: "disconnected", statusName: t("common.label.disconnected") });
                break;
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "65%", // 도넛 중앙에 공백을 만들기 위해 사용되는 속성
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <ChartArea>
            <ChartWrapper>
                <div className="doughnut-area">
                    <Doughnut data={chartData} options={options} />
                </div>
                <ChartTitle>
                    <div>{t("player.label.total")}</div>
                    <div style={{ fontSize }}>{isSubscribe ? "-" : total}</div>
                </ChartTitle>
            </ChartWrapper>
            <LegendWrapper>
                {sortData?.map((x, i) => (
                    <div key={i} onClick={() => handleIsFilter(i)}>
                        <span>
                            <span style={{ backgroundColor: `${x.color}` }}></span>
                            <span style={{ textDecoration: x.isFilter && !isSubscribe && "line-through" }}>{x.name}</span>
                        </span>
                        {!isSubscribe && colorMode === "connection" ? (
                            <button onClick={(e) => clickToMovePage(e, x)} tabIndex="0">
                                <span>{x.count}</span>
                                <img src="../../assets/images/ic_arrow_right2.svg" alt={t("common.open_detail_popup")} />
                            </button>
                        ) : (
                            <span>{!isSubscribe && <span>{x.count}</span>}</span>
                        )}
                    </div>
                ))}
                {colorMode === "license" && dday <= 30 && (
                    <dl className="license-dl position-relative-style">
                        <dt>Upcoming Expiration</dt>
                        <dd>
                            {isSubscribe ? (
                                "-"
                            ) : (
                                <>
                                    {upcomingExpired} <span>D-{dday}</span>
                                </>
                            )}
                        </dd>
                    </dl>
                )}
            </LegendWrapper>
        </ChartArea>
    );
};

/* 여기서부턴 Energy 도넛 차트 */

// Styled Component
const StyledDoughnutArea = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 26.5rem !important;
    padding: 0 5%;
    .chart-wrapper {
        position: relative;
        width: 200px;
        height: 200px;
        .doughnut-area {
            height: 100%;
            position: relative;
            z-index: 99;
        }
    }
    .legend-wrapper {
        margin-left: 6.8rem;
        table {
            height: 15.2rem;
            thead {
                height: 4.4rem;
                tr {
                    height: 100%;
                }
                th {
                    border: none;
                    padding: 1.2rem;
                    text-align: left;
                    &:nth-child(1) {
                        width: 13.27%;
                    }
                    &:nth-child(2) {
                        width: 38.91%;
                    }
                    &:nth-child(3) {
                        width: 23.91%;
                    }
                    &:nth-child(4) {
                        width: 23.91%;
                    }
                }
            }
            tbody {
                height: fit-content !important;
                tr td {
                    border: none;
                    &:nth-child(1) {
                        width: 13.27%;
                    }
                    &:nth-child(2) {
                        width: 38.91%;
                    }
                    &:nth-child(3) {
                        width: 23.91%;
                    }
                    &:nth-child(4) {
                        width: 23.91%;
                    }
                }
            }
        }
    }
    .color-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .color-bg {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 4px;
    }
    .mo-table {
        display: none;
    }

    @media (max-width: 720px) {
        height: unset !important;
        margin-top: 2rem;
        padding: 0;
        flex-direction: column;
        .legend-wrapper {
            margin: 0;
            margin-top: 4rem;
            width: 100%;
            table {
                display: none;
            }
            .mo-table {
                display: block;
                .title {
                    font-weight: 400 !important;
                    font-size: 1.4rem !important;
                    margin-bottom: 0 !important;
                }
                .pin-title {
                    height: 100%;
                    .color-wrapper {
                        width: 6rem;
                        border-right: 1px solid #dadde2;
                    }
                }
            }
        }
    }
`;

export const EnergyDoughnutChart = ({ dataList }) => {
    // 상수 정의
    const COLOR_LIST = ["#9EDFE1", "#697387"];
    // 상태값 정의
    const [sortedDataList, setSortedDataList] = useState([]);
    const [dataCount, setDataCount] = useState([]);
    const [dataName, setDataName] = useState([]);
    // total 값 반환
    const sumTotal = dataList.reduce((acc, curr) => acc + curr.count, 0);

    useEffect(() => {
        let sortedList = [...dataList].sort((a, b) => b.count - a.count);
        sortedList.forEach((item) => {
            const percent = ((item.count / sumTotal) * 100).toFixed(1);
            item.percent = parseFloat(percent);
            item.isShow = false;
        });
        const dataCountList = [];
        const dataNameList = [];
        sortedList.forEach((x) => {
            dataCountList.push(x.count);
            dataNameList.push(x.name);
        });
        setSortedDataList(sortedList);
        setDataCount(dataCountList);
        setDataName(dataNameList);
    }, [dataList]);

    const toggleExecutionShow = (index) => {
        setSortedDataList((prevState) => {
            const newList = [...prevState];
            newList[index] = {
                ...newList[index],
                isShow: !newList[index].isShow, // 현재 값의 반대로 변경
            };
            return newList;
        });
    };

    const chartData = {
        labels: dataName,
        datasets: [
            {
                data: dataCount, // 여기에 실제 데이터를 넣으셔야 합니다.
                backgroundColor: COLOR_LIST,
                hoverBackgroundColor: COLOR_LIST,
                borderWidth: 1,
                width: 30,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "65%", // 도넛 중앙에 공백을 만들기 위해 사용되는 속성
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <StyledDoughnutArea>
            <div className="chart-wrapper">
                <Doughnut data={chartData} options={options} />
                <ChartTitle>
                    <div>Total</div>
                    <div>{sumTotal}</div>
                </ChartTitle>
            </div>
            <div className="legend-wrapper">
                <table>
                    <caption>Total</caption>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Total</th>
                            <th scope="col"></th>
                            <th scope="col">kWh</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedDataList?.map((x, i) => (
                            <tr key={i}>
                                <td>
                                    <div className="color-wrapper">
                                        <span className="color-bg" style={{ backgroundColor: COLOR_LIST[i] }}></span>
                                    </div>
                                </td>
                                <td>{x.name}</td>
                                <td>{x.percent}%</td>
                                <td>{x.count} kWh</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="mo-table">
                    <div className="title">Total</div>
                    <div className="detail">
                        {sortedDataList?.map((x, i) => (
                            <div className="item" key={i}>
                                <div className="detail-title-area" onClick={() => toggleExecutionShow(i)}>
                                    <span className="pin-title">
                                        <div className="color-wrapper">
                                            <span className="color-bg" style={{ backgroundColor: COLOR_LIST[i] }}></span>
                                        </div>
                                        <span className="title">{x.name}</span>
                                    </span>
                                    <span className={`arrow ${x.isShow && "is-opened"}`}></span>
                                </div>
                                <div className="contents">
                                    {x.isShow && (
                                        <>
                                            <div>
                                                <span></span>
                                                <span>{x.percent}%</span>
                                            </div>
                                            <div>
                                                <span>kWh</span>
                                                <span>{x.count} kWh</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </StyledDoughnutArea>
    );
};
