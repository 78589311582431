import { DeviceDoughnutChart } from "@component/DashBoard/DoughnutChart";
import DashboardWrapper from "@component/DashBoard/Wrapper";
import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import _API from "@util/api";
import { useTranslation } from "react-i18next";
import { AppContext } from "@component/AppProvider";
import MonitoringSetting from "../device/popup/monitoringSettings";
import InfiniteScroll from "react-infinite-scroll-component";
import useLicenseNotice from "@hook/useLicenseNotice";
import utils from "@util/utilities";
import DeviceStatus from "@component/DashBoard/Popup/DeviceStatus";
import cx from "classnames";

// Styled Componenet
const StyledChartArea = styled.div`
    @media (max-width: 720px) {
        height: unset;
    }
`;

export default () => {
    const { t } = useTranslation();
    const {
        auth,
        workspace,
        rolePermission,
        product,
        saveInnerLoading,
        auth: {
            user_info: { datetime_format },
        },
    } = useContext(AppContext);
    const noti = useLicenseNotice();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [alwaysOnMonitoringList, setAlwaysOnMonitoringList] = useState({
        isErrorFilter: false,
        nDevices: 0,
        originData: [],
        filterData: [],
    });
    const [selectionMonitoringList, setSelectionMonitoringList] = useState({
        isErrorFilter: false,
        nDevices: 0,
        items: [],
    });
    const [isErrorAll, setIsErrorAll] = useState(false);
    const [appInUse, setAppInUse] = useState([]);
    const [monitoringSchedule, setMonitoringSchedule] = useState({
        total_count: 0,
        total_pages: 0,
        page: 0,
        items: [],
    });
    const [connectionData, setConnectionData] = useState([]);
    const [licenseData, setLicenseData] = useState([]);
    const [connectionPopup, setConnectionPopup] = useState({ status: "connected", statusName: "", isShow: false, isIssue: false });

    const [upcomingExpired, setUpcomingExpired] = useState("");
    const [dday, setDday] = useState("");

    const [monitoring, setMonitoring] = useState(false);
    const [isSubscribe, setIsSubscribe] = useState(false);
    const [viewErrorStatus, setViewErrorStatus] = useState(false);

    const fetchSelectionMonitoring = () =>
        _API.get({ path: "/dashboard/monitoring/selection" }).then((res) => {
            if (res?.data?.result) {
                const { result: items } = res?.data;
                const updatedItems = items.map((item) => ({
                    ...item,
                    isShow: item.hasOwnProperty("isShow") ? item.isShow : false,
                }));
                updatedItems.sort((a, b) => b.pin_status - a.pin_status);
                let nDevices = 0;
                items.forEach((item) => {
                    if (item.hasOwnProperty("n_devices") && item.n_devices > 0) {
                        nDevices++;
                    }
                });
                setSelectionMonitoringList({
                    ...selectionMonitoringList,
                    items: updatedItems,
                    nDevices,
                });
            }
        });

    const fetchData = async () => {
        saveInnerLoading(true);
        try {
            const connectionPromise = _API.get({ path: "/dashboard/device/connection" }).then((res) => {
                if (res?.data?.result) {
                    const { connected, disconnected, connected_no_signal, disconnected_screen_off } = res?.data?.result;
                    setConnectionData([
                        { count: Number(connected), name: "Connected" },
                        {
                            count: Number(connected_no_signal),
                            name: "Connected (No Signal)",
                        },
                        {
                            count: Number(disconnected_screen_off),
                            name: "Disconnected (Screen Off)",
                        },
                        { count: Number(disconnected), name: "Disconnected" },
                    ]);
                }
            });

            const licensePromise = _API.get({ path: "/dashboard/device/license", serviceType: product?.service_type, productCode: product?.product_code }).then((res) => {
                if (res?.data?.result) {
                    const { remaining_days, using_days, expire_date, d_day, is_subscribe } = res?.data?.result;
                    if (is_subscribe) {
                        setLicenseData([{ count: 1, name: "Subscription" }]);
                    } else {
                        setLicenseData([
                            { count: Number(using_days), name: "Using Days" },
                            { count: Number(remaining_days), name: "Remaining Days" },
                        ]);
                        setUpcomingExpired(expire_date);
                        setDday(d_day);
                    }
                    setIsSubscribe(is_subscribe);
                }
            });

            const alwaysOnMonitoringPromise = _API.get({ path: "/dashboard/monitoring/default" }).then((res) => {
                if (res && res.data && res.data.result) {
                    const { result: items } = res?.data;
                    let nDevices = 0;
                    items.forEach((item) => {
                        if (item.hasOwnProperty("n_devices") && item.n_devices > 0) {
                            nDevices++;
                        }
                    });
                    setAlwaysOnMonitoringList({
                        ...alwaysOnMonitoringList,
                        originData: items,
                        filterData: items,
                        nDevices,
                    });
                }
            });

            const appInUsePromise = _API.get({ path: "/dashboard/monitoring/app" }).then((res) => {
                if (res && res.data && res.data.result) {
                    const { result: items } = res?.data;
                    const sortedItems = items.sort((a, b) => a.rank - b.rank);
                    setAppInUse(sortedItems);
                }
            });

            await Promise.all([connectionPromise, licensePromise, alwaysOnMonitoringPromise, fetchSelectionMonitoring(), appInUsePromise, fetchScheduleList()]).finally(() => saveInnerLoading(false));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        document.title = t("home.label.DashBoard") + " | " + t("common.title.lgconnectedcare");
        fetchData();
    }, [workspace]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    const fetchScheduleList = (isInit = true) => {
        if (isInit) {
            console.log("request1");
            _API.get({
                path: "/dashboard/monitoring/schedule",
                data: { page: 1, per_page: 10 },
            }).then((res) => {
                if (res?.data?.result) {
                    let { paging, rows } = res?.data?.result;
                    if (paging?.current) {
                        const { current, total_count, total_pages } = paging;
                        if (rows?.length) {
                            rows.forEach((item) => {
                                if (!item.hasOwnProperty("isShow")) item.isShow = false;
                            });
                        }
                        setMonitoringSchedule({
                            ...monitoringSchedule,
                            total_count,
                            total_pages,
                            page: current,
                            items: rows,
                        });
                    } else {
                        setMonitoringSchedule({
                            total_count: 0,
                            total_pages: 0,
                            page: 0,
                            items: [],
                        });
                    }
                }
            });
        } else {
            let { total_pages, page } = monitoringSchedule;
            if (page >= total_pages) return;
            page += 1;
            _API.get({
                path: "/dashboard/monitoring/schedule",
                data: { page, per_page: 10 },
            }).then((res) => {
                if (res?.data?.result) {
                    let { paging, rows } = res?.data?.result;
                    if (paging?.current) {
                        const { current, total_count, total_pages } = paging;
                        if (rows?.length) {
                            rows.forEach((item) => {
                                if (!item.hasOwnProperty("isShow")) {
                                    item.isShow = false;
                                }
                            });
                        }
                        setMonitoringSchedule({
                            ...monitoringSchedule,
                            total_count,
                            total_pages,
                            page: current,
                            items: [...monitoringSchedule.items, ...rows],
                        });
                    } else {
                        setMonitoringSchedule({
                            total_count: 0,
                            total_pages: 0,
                            page: 0,
                            items: [],
                        });
                    }
                }
            });
        }
    };

    const togglePinned = (e, index) => {
        e.stopPropagation();
        const { user_id } = auth;
        const findOne = selectionMonitoringList.items[index];
        let { pin_status, code } = findOne;
        if (pin_status === -1) return;
        pin_status = pin_status === 0 ? 1 : 0;
        // setSelectionMonitoringList((prevState) => {
        //     const newList = {
        //         ...prevState,
        //         items: prevState.items.map((item, i) => (i === index ? { ...item, pin_status } : item)),
        //     };
        //     return newList;
        // });
        _API.post({
            path: "/dashboard/monitoring/selection/pin",
            data: { user_id, code, pin_status },
        })
            .then((res) => {
                fetchSelectionMonitoring();
            })
            .catch((e) => {
                console.log(`api error /dashboard/monitoring/selection/pin `, e);
            });
    };

    const toggleShow = (index) => {
        setSelectionMonitoringList((prevState) => {
            const newItems = [...prevState.items];
            newItems[index] = {
                ...newItems[index],
                isShow: !newItems[index].isShow,
            };
            return {
                ...prevState,
                items: newItems,
            };
        });
    };

    const toggleExecutionShow = (index) => {
        setMonitoringSchedule((prevState) => {
            const newItems = [...prevState.items];
            if (newItems[index]) {
                newItems[index] = {
                    ...newItems[index],
                    isShow: !newItems[index].isShow,
                };
            }
            return { ...prevState, items: newItems };
        });
    };

    const toggleIsAllError = () => {
        setViewErrorStatus(true);
    };

    const onClickSelectiveRemark = (item, isTitleError = false) => {
        if (item) {
            setConnectionPopup({ status: item?.code, statusName: isTitleError ? t("common.error") : item?.name, isShow: true, isIssue: true });
        }
    };

    const getStatusImg = (status, pinStatus) => {
        if (pinStatus === -1) return <img src="../../assets/images/ic_disconected.svg" alt="" />;
        if (status === "OK") {
            return <img src="../../assets/images/ic_check_green.svg" alt="check" />;
        } else {
            return <img src="../../assets/images/ic_warning_red.svg" alt="check" />;
        }
    };

    const onClickDetail = ({ status, statusName }) => {
        setConnectionPopup({ ...connectionPopup, status, statusName, isShow: true, isIssue: false });
    };
    const onCloseDetail = () => {
        setConnectionPopup({ ...connectionPopup, isShow: false, isIssue: false });
    };

    const onClickAboutLicense = () => {
        switch (process?.env?.STAGE?.toLowerCase()) {
            case "development":
            case "local":
                window.open("http://dev.lgbusinesscloud.com/support/notice/#17086554931nOZuBSk80TDlCS4o8eM0n");
                break;
            case "staging":
                window.open("http://qa.lgbusinesscloud.com/support/notice/#170891082176AoZUf5nokNHa1lwwkJYc");
                break;
        }
    };

    return (
        <Fragment>
            <DashboardWrapper onRefresh={fetchData}>
                <section className="dashboard-devices">
                    <div className="device-status-area">
                        <h2 className="title">{t("common.label.devicestatus")}</h2>
                        <div>
                            <div className="connection">
                                <h3 className="title">{t("common.label.connection")}</h3>
                                <StyledChartArea>
                                    <DeviceDoughnutChart data={connectionData} onClickDetail={onClickDetail} />
                                </StyledChartArea>
                            </div>
                            <div className="license">
                                <h3 className="title">{t("license.popup.label.license")}</h3>
                                <div className="chart-area license-chart">
                                    <DeviceDoughnutChart colorMode="license" data={licenseData} upcomingExpired={upcomingExpired} dday={dday} isSubscribe={isSubscribe} />
                                    <a href="#!" className="link" title={t("common.open_new_window")} onClick={onClickAboutLicense}>
                                        {t("common.label.LearnMore")}
                                        <span className="ir">{t("subtext.desc.new.window")}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="monitoring-status-area">
                        <div className="title-area">
                            <h2 className="title">
                                {t("common.label.MonitoringStatus")}
                                <button className="btn tit-btn" onClick={toggleIsAllError}>
                                    {t("common.label.ViewDeviceError")}
                                </button>
                            </h2>
                        </div>
                        <div>
                            <div className="single-box">
                                <h3 className="title">{t("common.label.AlwaysOnMonitoring")}</h3>
                                <div className="area">
                                    {alwaysOnMonitoringList?.filterData?.map(
                                        (v, i) =>
                                            v && (
                                                <div className="card" key={i}>
                                                    <div className="card-title">
                                                        {v?.name}
                                                        {v.hasOwnProperty("n_devices") && v.n_devices ? (
                                                            <button>
                                                                <span onClick={() => onClickSelectiveRemark(v, true)}>
                                                                    {v?.n_devices || 0} {t("device.label.devices")} <img src="../../assets/images/ic_arrow_right2.svg" alt="" />
                                                                </span>
                                                            </button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div>
                                                            {v.status === "OK" ? (
                                                                <img src="../../assets/images/ic_glass_success.png" alt="OK" />
                                                            ) : (
                                                                <img src="../../assets/images/ic_glass_error.png" alt="Error" />
                                                            )}
                                                        </div>
                                                        <div>{v.status === "OK" ? t("common.ok") : t("common.error")}</div>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </div>
                            </div>
                            <div className={cx({ "display-none-style": product?.service_type === "DMS" })}>
                                <div className="title-area">
                                    <h3 className="title">{t("common.label.SelectiveMonitoring")}</h3>
                                    <button
                                        className="btn tit-btn"
                                        aria-label={t("common.label.OpenMonitoringSettings")}
                                        onClick={() => setMonitoring(true)}
                                        disabled={utils.getAuthWriteDisable(rolePermission)}
                                    >
                                        {t("common.label.MonitoringSettings")}
                                    </button>
                                </div>
                                <div className="area">
                                    <table className="pc-only">
                                        <caption>{t("common.label.SelectiveMonitoring")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">fix</th>
                                                <th scope="col">Monitoring Item</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody tabIndex="0">
                                            {selectionMonitoringList?.items?.map((x, i) => {
                                                const el = (
                                                    <tr className={x.pin_status > 0 ? "fill" : ""} key={i}>
                                                        <td onClick={(e) => togglePinned(e, i)}>
                                                            <div className="vertical-center">
                                                                {x.pin_status === 1 ? (
                                                                    <button aria-pressed="true" aria-label={t("common.pin")}>
                                                                        <img src="../../assets/images/ic_pin_fill.svg" alt={t("pin.row_to_top")} />
                                                                    </button>
                                                                ) : x.pin_status === 0 ? (
                                                                    <button aria-pressed="false" aria-label={t("common.pin")}>
                                                                        <img src="../../assets/images/ic_pin.svg" alt={t("unpin.row_to_top")} />
                                                                    </button>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="vertical-center">{x.name}</div>
                                                        </td>
                                                        <td className="status">
                                                            <div className="vertical-center left">
                                                                {getStatusImg(x.status, x.pin_status)}
                                                                <span>{x.pin_status === -1 ? "Not Monitored" : x.status === "OK" ? t("common.ok") : t("common.error")}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {x.hasOwnProperty("n_devices") && x.n_devices ? (
                                                                <div className="vertical-center">
                                                                    <button aria-label={t("common.open_detail_popup")}>
                                                                        <span onClick={() => onClickSelectiveRemark(x)}>
                                                                            {x?.n_devices || 0} {t("device.label.devices")}{" "}
                                                                        </span>
                                                                        <img
                                                                            src="../../assets/images/ic_arrow_right2.svg"
                                                                            alt={t("common.open_detail_popup")}
                                                                            onClick={() => onClickSelectiveRemark(x)}
                                                                        />
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                                if (!selectionMonitoringList.isErrorFilter) return el;
                                                else if (x.hasOwnProperty("n_devices") && x.n_devices) return el;
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="mo-table mobile-only">
                                        <div className="title">{t("common.label.MonitoringItem")}</div>
                                        <div className="detail">
                                            {selectionMonitoringList?.items?.map((x, i) => {
                                                const el = (
                                                    <div className="item" key={i}>
                                                        <div className="detail-title-area" onClick={() => toggleShow(i)}>
                                                            <span className="pin-title">
                                                                <span onClick={(e) => togglePinned(e, i)} className={`pin-area ${x.pin_status > 0 ? "fill" : ""}`}></span>
                                                                <span className="title">{x.name}</span>
                                                            </span>
                                                            <span className={`arrow ${x.isShow ? "is-opened" : ""}`}></span>
                                                        </div>
                                                        <div className="contents">
                                                            {x.isShow && (
                                                                <>
                                                                    <div>
                                                                        <span>{t("common.status")}</span>
                                                                        <span>
                                                                            {getStatusImg(x.status, x.pin_status)}
                                                                            <span className="margin-left-0-4rem">
                                                                                {x.pin_status === -1 ? "Not Monitored" : x.status === "OK" ? t("subtext.button.ok") : t("common.error")}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <span>{t("common.label.Remark")}</span>
                                                                        <span>
                                                                            {x.hasOwnProperty("n_devices") && x.n_devices ? (
                                                                                <button aria-label={t("common.open_detail_popup")}>
                                                                                    <span onClick={() => onClickSelectiveRemark(x)}>
                                                                                        {x?.n_devices || 0} {t("device.label.devices")}{" "}
                                                                                    </span>
                                                                                    <img
                                                                                        src="../../assets/images/ic_arrow_right2.svg"
                                                                                        alt={t("common.open_detail_popup")}
                                                                                        onClick={() => onClickSelectiveRemark(x)}
                                                                                    />
                                                                                </button>
                                                                            ) : (
                                                                                "-"
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                                if (!selectionMonitoringList.isErrorFilter) return el;
                                                else if (x.hasOwnProperty("n_devices") && x.n_devices) return el;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {product?.service_type === "DMS" && (
                        <div className="last-area">
                            <div>
                                <div className="title-area">
                                    <h2 className="title">
                                        {t("common.label.appinuse")} <span className="sub-title">{t("common.label.Last7DaysData")}</span>
                                    </h2>
                                </div>
                                <div>
                                    <div className="rank">
                                        {appInUse?.slice(0, 3).map(
                                            (v) =>
                                                v && (
                                                    <div className="rank-card" key={v.rank}>
                                                        <span>
                                                            {v.rank === 1 ? (
                                                                <img src="../../assets/images/ic_medal_1st.svg" alt="procloud first" />
                                                            ) : v.rank === 2 ? (
                                                                <img src="../../assets/images/ic_medal_2nd.svg" alt="procloud second" />
                                                            ) : (
                                                                <img src="../../assets/images/ic_medal_3rd.svg" alt="procloud third" />
                                                            )}
                                                        </span>
                                                        <span>
                                                            <div className="rank-number">
                                                                {v.rank === 1
                                                                    ? t("device.label.first")
                                                                    : v.rank === 2
                                                                    ? t("device.label.second")
                                                                    : v.rank === 3
                                                                    ? t("device.label.third")
                                                                    : v.rank + "st"}
                                                            </div>
                                                            <div className="desc">{v.app_name}</div>
                                                        </span>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                    <div className="other-rank">
                                        <div className="row-item">
                                            {appInUse?.slice(3, 7).map((v) => (
                                                <div className="item" key={v.rank}>
                                                    <span>
                                                        <span className="bold">{v.rank}</span>
                                                        <span>th</span>
                                                    </span>
                                                    <span>{v.app_name}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="row-item">
                                            {appInUse?.slice(7, 10).map((v) => (
                                                <div className="item" key={v.rank}>
                                                    <span>
                                                        <span className="bold">{v.rank}</span>
                                                        <span>th</span>
                                                    </span>
                                                    <span>{v.app_name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="title-area">
                                    <h2 className="title">{t("schedule.label.list")}</h2>
                                </div>
                                <div>
                                    <div className="history-status-table">
                                        <div className="total-count margin-top0-style">
                                            <span>
                                                {t("player.label.total")} <strong>{monitoringSchedule?.total_count || 0}</strong>
                                            </span>
                                        </div>
                                        <InfiniteScroll
                                            style={{ overflow: "hidden" }}
                                            className="table-scroll"
                                            dataLength={monitoringSchedule?.items?.length || 0}
                                            next={() => fetchScheduleList(false)}
                                            hasMore={windowWidth > 991}
                                            loader={""}
                                            scrollableTarget="scheduleListTbody"
                                        >
                                            <table className="pc-only height-38-rem" tabIndex="0">
                                                <caption>{t("common.label.ScheduleListDate")}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("table.head.actiondate")}</th>
                                                        <th scope="col">{t("table.head.group")}</th>
                                                        <th scope="col">{t("form.label.activeservice")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="scheduleListTbody" className="height-35-rem" tabIndex="0">
                                                    {monitoringSchedule?.items?.map(
                                                        (x, i) =>
                                                            x && (
                                                                <tr key={i}>
                                                                    <td>{x?.execution_date ? utils.getOffsetTime(auth, workspace, x.execution_date, datetime_format?.split(" ")[0]) : ""}</td>
                                                                    <td>{x.group}</td>
                                                                    <td>{x.title}</td>
                                                                </tr>
                                                            )
                                                    )}
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                        <InfiniteScroll
                                            style={{ overflow: "hidden" }}
                                            dataLength={monitoringSchedule?.items?.length || 0}
                                            next={() => fetchScheduleList(false)}
                                            hasMore={windowWidth < 991}
                                            scrollableTarget="moScheduleTarget"
                                        >
                                            <div className="mo-table mobile-only height-50-rem overflow-y-auto" id="moScheduleTarget">
                                                <div className="title">{t("table.head.actiondate")}</div>
                                                <div className="detail">
                                                    {monitoringSchedule?.items?.map(
                                                        (x, i) =>
                                                            x && (
                                                                <div className="item" key={i}>
                                                                    <div className="detail-title-area" onClick={() => toggleExecutionShow(i)}>
                                                                        <span className="title">
                                                                            {x?.execution_date ? utils.getOffsetTime(auth, workspace, x.execution_date, datetime_format?.split(" ")[0]) : ""}
                                                                        </span>
                                                                        <span className={`arrow ${x.isShow && "is-opened"}`}></span>
                                                                    </div>
                                                                    <div className="contents">
                                                                        {x.isShow && (
                                                                            <>
                                                                                <div>
                                                                                    <span>{t("table.head.group")}</span>
                                                                                    <span>{x.group}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <span>{t("form.label.activeservice")}</span>
                                                                                    <span>{x.title}</span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </DashboardWrapper>
            {monitoring && (
                <MonitoringSetting
                    id="popup-monitor-setting"
                    title={t("subtext.label.device.monitoring.settings")}
                    className="popup popup-alert-message popup-monitor-setting"
                    onClose={() => {
                        setMonitoring(false);
                        fetchSelectionMonitoring();
                    }}
                    tabId="threshold"
                    close
                    noFooter
                />
            )}
            {connectionPopup.isShow && <DeviceStatus status={connectionPopup.status} statusName={connectionPopup.statusName} onClose={onCloseDetail} isIssue={connectionPopup.isIssue} />}
            {viewErrorStatus && <DeviceStatus isOnlyError onClose={() => setViewErrorStatus(false)} statusName="Error" />}
        </Fragment>
    );
};
