import { AppContext } from "@component/AppProvider";
import cx from "classnames";
import React, { useContext, useEffect, useState, useRef } from "react";
import _API from "@util/api";
import Modal from "@component/UI/Modal";
import utils from "@util/utilities";
import _CK from "@util/cookie";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";
import moment from "moment";

const NoticeTitle = styled.h2`
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    max-width: 40rem;
`;

const MessageModal = ({ onClose, title, created_at, content }) => {
    const { t } = useTranslation();
    const { auth, workspace } = useContext(AppContext);
    return (
        <Modal title={t("common.notification")} id="popup-notice-detail" className="confirm popup-notice-detail" onClose={onClose} noFooter ok>
            <div className="notice-state">
                <NoticeTitle className="notice-tit">{title}</NoticeTitle>
                <span className="notice-date">{utils.getOffsetTime(auth, workspace, utils.formatUnixTimeDate(created_at, 1))}</span>
            </div>
            <div className="notice-detail">{content}</div>
        </Modal>
    );
};

const NotiWrapper = styled.div`
    #notice_wrap {
        max-height: 400px;
    }
    h3.notice-tit {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-wrap: break-word;
    }
`;

const ITEMS_PER_PAGE = 5;

const NotifyToolbar = ({ show, onClose }) => {
    const { t } = useTranslation();
    const { auth, workspace } = useContext(AppContext);

    const [data, setData] = useState({
        total: 0,
        items: [],
    });
    const [page, setPage] = useState(1);

    const [list, setList] = useState([]);
    const [selectItem, setSelectItem] = useState(null);
    const [isAll, setIsAll] = useState(true);
    const areaRef = useRef(null);
    const tabRef = useRef(null);

    const onTabAreaKeyDown = (e) => {
        if (e.key === "Tab") {
            if (e.shiftKey) {
                e.preventDefault();
                document.getElementById("btn-noti-close").focus();
                return;
            }
            const tabList = tabRef.current.querySelectorAll("button");
            const index = 0;
            const nextTab = tabList[index + 1];
            if (nextTab) {
                nextTab.focus();
            }
        } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("button");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    if (e.key === "ArrowLeft") {
                        idx = i - 1;
                    } else if (e.key === "ArrowRight") {
                        idx = i + 1;
                    }
                    return true;
                }
            });
            if (idx >= tabList.length) {
                idx = 0;
            } else if (idx < 0) {
                idx = tabList.length - 1;
            }
            tabList[idx].focus();
        }
    };

    const onDetailView = (item) => {
        setIsRead(item);
        setSelectItem(item);
        if (!isAll) {
            const updatedList = list.filter((existingItem) => existingItem !== item);
            setList(updatedList);
            setData({
                ...data,
                total: updatedList.length,
            });
        }
    };

    const onClickFilter = (type) => {
        setPage(1);
        if (type === "UNREAD") {
            const listConcat = data?.items?.concat();
            const result = listConcat.filter((item) => !getIsRead(item));
            setList(result);
            setData({ ...data, total: result.length });
            setIsAll(false);
        } else {
            setList(data.items);
            setData({ ...data, total: data.items.length });
            setIsAll(true);
        }
    };

    const getListPerPage = () => {
        const startIndex = 0;
        const endIndex = startIndex + ITEMS_PER_PAGE * page;
        return list.slice(startIndex, endIndex);
    };

    const hasNext = () => {
        if (getListPerPage()?.length < data.items.length) {
            return true;
        } else {
            return false;
        }
    };

    const onClickMarkAll = () => {
        const listConcat = list.concat();
        listConcat.forEach((item) => setIsRead(item));
        setList(listConcat);

        if (!isAll) onClickFilter("UNREAD");
    };

    const getIsRead = (item) => {
        const { notice_id } = item;
        const notiReadList = localStorage.getItem("noti_read_list") ? JSON.parse(localStorage.getItem("noti_read_list")) : [];
        if (notiReadList.includes(notice_id)) return true;
        else return false;
    };

    const isUnreadExist = () => {
        let bool = false;
        if (list?.length) {
            for (const item of list) {
                if (!getIsRead(item)) {
                    bool = true;
                    break;
                }
            }
        }
        return bool;
    };

    const setIsRead = (item) => {
        const { notice_id } = item;
        const notiReadList = localStorage.getItem("noti_read_list") ? JSON.parse(localStorage.getItem("noti_read_list")) : [];
        if (!notiReadList.includes(notice_id)) notiReadList.push(notice_id);
        let json = JSON.stringify(notiReadList);
        localStorage.setItem("noti_read_list", JSON.stringify(notiReadList));
    };

    const fetchData = async () => {
        try {
            const notiPromise = _API.get({ path: "/system/notices" }).then((res) => {
                const {
                    result: { notices },
                } = res?.data;
                setData({ total: notices?.length, items: notices });
                setPage(1);
                setList(notices);
            });

            await Promise.all([notiPromise]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [workspace]);

    useEffect(() => {
        function handleOutside(e) {
            // current.contains(e.target) : 컴포넌트 특정 영역 외 클릭 감지를 위해 사용
            if (areaRef.current && !areaRef.current.contains(e.target)) {
                onClose && onClose();
            }
        }
        document.addEventListener("mousedown", handleOutside);
        return () => {
            document.removeEventListener("mousedown", handleOutside);
        };
    }, [areaRef]);

    return (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
            <NotiWrapper ref={areaRef}>
                <div
                    className={cx({
                        menu: true,
                        pannel: true,
                        "notice-menu": true,
                        expand: show,
                    })}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="notification-label"
                >
                    <h2 className="menu-title" id="notification-label">
                        {t("common.notification")}
                    </h2>
                    <div className="menu-content">
                        <div className="notice-filter">
                            <div className="col" role="tablist" ref={tabRef}>
                                <button
                                    type="button"
                                    className={cx({
                                        btn: true,
                                        "btn-tertiary": true,
                                        "btn-All": true,
                                        active: isAll,
                                    })}
                                    onClick={() => onClickFilter()}
                                    role="tab"
                                    aria-selected={isAll === true}
                                    onKeyDown={onTabAreaKeyDown}
                                >
                                    {t("common.all")}
                                </button>
                                <button
                                    type="button"
                                    className={cx({
                                        btn: true,
                                        "btn-tertiary": true,
                                        "btn-Unread": true,
                                        active: !isAll,
                                        new: isUnreadExist(),
                                    })}
                                    onClick={() => onClickFilter("UNREAD")}
                                    role="tab"
                                    aria-selected={isAll === false}
                                    onKeyDown={onTabAreaKeyDown}
                                >
                                    {t("common.unread")}
                                </button>
                            </div>
                            <div className="col">
                                <button type="button" className="btn btn-light btn-Allread" onClick={onClickMarkAll}>
                                    {t("common.label.MarkAllRead")}
                                </button>
                            </div>
                        </div>
                        <div className="notice-container">
                            <div className="notice-state">Total : {data?.total || 0}</div>
                            <InfiniteScroll
                                style={{ overflow: "hidden" }}
                                dataLength={getListPerPage()?.length}
                                next={() => {
                                    setPage(page + 1);
                                }}
                                hasMore={hasNext()}
                                loader={""}
                                scrollableTarget="notice_wrap"
                            >
                                <div id="notice_wrap" className="notice-wrap">
                                    {getListPerPage()?.map((item, index) => (
                                        <a
                                            href="#!"
                                            className={cx({
                                                "notice-item": true,
                                                "state-unread": !getIsRead(item),
                                            })}
                                            onClick={() => onDetailView(item)}
                                            key={index}
                                            title={getIsRead(item) ? t("common.checked") : t("common.unread")}
                                        >
                                            <h3 className="notice-tit">{item.title}</h3>
                                            <div className="notice-detail">{item.content}</div>
                                            <span className="notice-date">{utils.getOffsetTime(auth, workspace, utils.formatUnixTimeDate(item.created_at, 1))}</span>
                                        </a>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                    <button type="button" className="btn btn-icon btn-light btn-close" onClick={onClose} id="btn-noti-close">
                        {t("button.close")}
                    </button>
                </div>
                {selectItem && <MessageModal {...selectItem} onClose={() => setSelectItem(null)} />}
            </NotiWrapper>
        </FocusTrap>
    );
};
export default NotifyToolbar;
