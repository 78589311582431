import React from "react";
import cx from "classnames";
import qs from "query-string";

import _U from "@util/utilities";

export default class Paging extends React.Component {
    handlePageClicked(page) {
        this.props.pageCallback && this.props.pageCallback(page < 1 ? 1 : page);
    }

    render() {
        const { paging } = this.props;
        const pagingItems = [];
        const pagingSmallItems = [];
        if (paging) {
            for (let i = paging.startPageNo; i <= paging.endPageNo; i++) {
                pagingItems.push(
                    <a
                        key={i}
                        href="#!"
                        role="button"
                        className={cx({ page: true, [`page-${i}`]: true, current: paging.pageNo === i })}
                        aria-label={`${i} 페이지`}
                        onClick={this.handlePageClicked.bind(this, i)}
                    >
                        <span>{i}</span>
                    </a>
                );
                if (i < paging.startPageNo + 3) {
                    pagingSmallItems.push(
                        <a
                            key={i}
                            href="#!"
                            role="button"
                            className={cx({ page: true, [`page-${i}`]: true, current: paging.pageNo === i })}
                            aria-label={`${i} 페이지`}
                            onClick={this.handlePageClicked.bind(this, i)}
                        >
                            <span>{i}</span>
                        </a>
                    );
                }
            }
        }

        if (!paging) {
            return null;
        }

        return (
            <>
                <div className="pagination long-pagination">
                    <a href="#!" role="button" className="page go-before" onClick={this.handlePageClicked.bind(this, 1)}>
                        <span className="ir">first page</span>
                    </a>
                    <a href="#!" role="button" className="page go-prev" onClick={this.handlePageClicked.bind(this, paging.prevBlockNo)}>
                        <span className="ir">prev page</span>
                    </a>

                    <div className="pager">{pagingItems}</div>

                    <a href="#!" role="button" className="page go-next" onClick={this.handlePageClicked.bind(this, paging.nextBlockNo)}>
                        <span className="ir">next page</span>
                    </a>
                    <a href="#!" role="button" className="page go-after" onClick={this.handlePageClicked.bind(this, paging.finalPageNo)}>
                        <span className="ir">last page</span>
                    </a>
                </div>

                <div className="pagination mini-pagination small">
                    <a href="#!" role="button" className="page go-before" onClick={this.handlePageClicked.bind(this, 1)}>
                        <span className="ir">first page</span>
                    </a>
                    <a href="#!" role="button" className="page go-prev" onClick={this.handlePageClicked.bind(this, paging.prevBlockNo)}>
                        <span className="ir">prev page</span>
                    </a>

                    <div className="pager">{pagingSmallItems}</div>

                    <a href="#!" role="button" className="page go-next" onClick={this.handlePageClicked.bind(this, paging.nextBlockNo)}>
                        <span className="ir">next page</span>
                    </a>
                    <a href="#!" role="button" className="page go-after" onClick={this.handlePageClicked.bind(this, paging.finalPageNo)}>
                        <span className="ir">last page</span>
                    </a>
                </div>
            </>
        );
    }
}

export const ToPage = (location, navigate, params) => {
    const url = `${location.pathname}?q=${_U.encode(params)}`;
    navigate(url);
};

export const GetParams = (location) => {
    const { q } = qs.parse(location?.search);
    if (!q) {
        return null;
    }
    return _U.decode(q);
};
