import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import useCss from "@hook/useCss";

import _U from "@util/utilities";
import _API from "@util/api";

import Modal from "@component/UI/Modal";
import { AppContext } from "@component/AppProvider";

export const BroadcastResult = (props) => {
    useCss("/assets/css/popup_device_list.css");
    const { t } = useTranslation();
    const { broadcastId, deviceId, is_broadcast = true, typeTitle = t("device.label.command"), hideModel = false } = props;
    const { auth, workspace } = useContext(AppContext);
    const [list, setList] = useState([]);

    const loadData = () => {
        //_API.get({ path: `/content/schedule/broadcast/${broadcastId}`, data: { is_group: false } })
        _API.get({ path: `/content/schedule/result/${broadcastId}`, data: { is_broadcast, device_id: deviceId } })
            .then((res) => {
                console.log(res);
                const { result } = res.data;
                setList(result);
                if (typeof resizeTable !== "undefined") {
                    resizeTable();
                }
            })
            .catch((e) => {
                console.log("api error ", e);
            });
    };

    useEffect(() => {
        if (broadcastId) {
            loadData();
        }
    }, [broadcastId]);

    return (
        <Modal title={t("result.main.title")} id="popup-result-list" className="popup-result-list" onClose={props.onClose} noFooter ok>
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        <div className="total-count">
                            {t("player.label.total")} <span className="count">{list?.length > 0 ? _U.number3Comma(list?.length) : 0}</span>
                        </div>
                    </div>
                </div>
                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper Result-table no-scroll">
                            <div className="table-box">
                                <table id="Result-table">
                                    <caption>{t("result.main.title")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">{t("table.head.actiondate")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{typeTitle}</div>
                                            </th>
                                            {!hideModel && (
                                                <th scope="col">
                                                    <div className="text-block">{t("player.label.modelName")}</div>
                                                </th>
                                            )}
                                            <th scope="col">
                                                <div className="text-block">{t("devicemanagement.title.deviceName")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.serialnumber")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("table.head.runstate")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map(
                                            (item, index) =>
                                                item && (
                                                    <tr key={index}>
                                                        <td>{item?.execution_date ? _U.getOffsetTime(auth, workspace, item?.execution_date) : "-"}</td>
                                                        <td>{item?.command || ""}</td>
                                                        {!hideModel && <td>{item?.model_name || ""}</td>}
                                                        <td>{item?.device_name || ""}</td>
                                                        <td>{item?.serial_number || ""}</td>
                                                        <td>{item?.execution_status ? "true" : "false"}</td>
                                                    </tr>
                                                )
                                        )}
                                        {(!list || list.length === 0) && (
                                            <tr>
                                                <td colSpan={6} className="align-center">
                                                    {t("devicemanagement.search.noresult")}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">{t("table.head.actiondate")}</div>
                                </div>
                            </div>
                            <ul className="table-body">
                                {list?.map(
                                    (item, index) =>
                                        item && (
                                            <li className="table-row" key={`reflow-${index}`}>
                                                <div className="summary-data">
                                                    <div className="row">
                                                        <div className="table-cell alias">
                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                {item.execution_date ? _U.getOffsetTime(auth, workspace, item?.execution_date) : "-"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="all-data-box">
                                                    <ul className="all-data">
                                                        <li>
                                                            <span className="field-label">{t("device.label.command")}</span>
                                                            <span className="field-content">{item?.command || ""}</span>
                                                        </li>
                                                        {!hideModel && (
                                                            <li>
                                                                <span className="field-label">{t("player.label.modelName")}</span>
                                                                <span className="field-content">{item?.model_name || ""}</span>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <span className="field-label">{t("devicemanagement.title.deviceName")}</span>
                                                            <span className="field-content">{item?.device_name || ""}</span>
                                                        </li>
                                                        <li>
                                                            <span className="field-label">{t("common.label.serialnumber")}</span>
                                                            <span className="field-content">{item?.serial_number || ""}</span>
                                                        </li>
                                                        <li>
                                                            <span className="field-label">{t("table.head.runstate")}</span>
                                                            <span className="field-content">{item?.execution_status ? "true" : "false"}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                )}
                                {(!list || list.length === 0) && (
                                    <li className="table-row">
                                        <div className="summary-data">
                                            <div className="row">{t("devicemanagement.search.noresult")}</div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

// export const BroadcastRegistModal = (props) => {
//     const { t } = useTranslation();
//     const exeDateRef = useRef(null);

//     const [fileModal, setFileModal] = useState(null);
//     const [data, setData] = useState({
//         broadcastType: { label: "Video", value: "VIDEO" },
//     });

//     const [typeList, setTypeList] = useState([
//         { label: "Video", value: "VIDEO" },
//         { label: "Image", value: "IMAGE" },
//         { label: "Audio", value: "AUDIO" },
//         { label: "Message", value: "MESSAGE" },
//     ]);

//     const onChangeExeDate = (params) => {
//         setData({ ...data, exeDate: params.exeDate });
//     };

//     const onClickDone = () => {
//         props.onClose();
//     };

//     useEffect(() => {
//         if (data?.broadcastType?.value === "IMAGE") {
//             resizeTable();
//         }
//     }, [data?.broadcastType]);

//     const apmList = [
//         { label: t("schedule.code.am"), value: "am" },
//         { label: t("schedule.code.pm"), value: "pm" },
//     ];
//     const hours = [...Array(12)].map((m, i) => ({ label: i < 9 ? "0" + (i + 1) : i + 1 + "", value: i < 9 ? "0" + (i + 1) : i + 1 + "" }));
//     const minutes = [...Array(60)].map((m, i) => ({ label: i < 10 ? "0" + i : i + "", value: i < 10 ? "0" + i : i + "" }));

//     return (
//         <Modal buttonOkTitle="Register" title="Register" id="popup-add-template" className="popup-add-template" onClose={props.onClose} onClick={onClickDone}>
//             <div className="form-guide algin-left">
//                 <span>*</span> is required
//             </div>

//             <div className="field field-type-message required">
//                 <div className="field-header">
//                     <label htmlFor="broadcastType" className="field-title">
//                         Broadcast Type
//                     </label>
//                 </div>
//                 <div className="field-form">
//                     <div className="input-box">
//                         <SelectBox
//                             id="broadcastType"
//                             items={typeList}
//                             value={data?.broadcastType?.value || ""}
//                             selectLabel={data?.broadcastType?.label || ""}
//                             onChange={(item) => {
//                                 console.log("aasdf>", item);
//                                 setData({ ...data, broadcastType: item });
//                             }}
//                         />
//                     </div>
//                 </div>
//                 <span id="description-id" className="field-description">
//                     Only files uploaded to the file box can be used.
//                 </span>
//             </div>

//             <div className="field field-type-message required">
//                 <div className="field-header">
//                     <label htmlFor="ip-message-type" className="field-title">
//                         Title
//                     </label>
//                 </div>
//                 <div className="field-form">
//                     <div className="input-box">
//                         <input
//                             type="text"
//                             id="ip-message-type"
//                             name=""
//                             value=""
//                             autoComplete="false"
//                             aria-required="true"
//                             aria-invalid="true"
//                             aria-describedby="description-id"
//                             aria-errormessage="error-id"
//                             onChange={(e) => {}}
//                         />
//                         <button type="button" className="btn-input-remove hide">
//                             <span className="ir">delete input</span>
//                         </button>
//                     </div>
//                     <div className="word-count">(0/200)</div>
//                 </div>
//             </div>

//             <div className="field field-type-message required">
//                 <div className="field-header">
//                     <label htmlFor="broadcastExecution" className="field-title">
//                         Execution
//                     </label>
//                 </div>
//                 <div className="field-form">
//                     <div className="input-box">
//                         <SelectBox id="broadcastExecution" items={[]} value={""} selectLabel={"Immediately" || ""} onChange={(item) => {}} />
//                     </div>
//                 </div>
//             </div>
//             {data?.broadcastType?.value === "IMAGE" && (
//                 <Fragment>
//                     <div className="field field-type-message required">
//                         <div className="field-header">
//                             <label htmlFor="execution-date" className="field-title">
//                                 Execution Date
//                             </label>
//                         </div>
//                         <div className="field-form">
//                             <div className="datepicker-container execution-date">
//                                 <div className="input-box">
//                                     <InputDatepicker
//                                         name="exeDate"
//                                         id="execute-date"
//                                         placeholder="YYYY-MM-DD"
//                                         autoComplete="false"
//                                         ariaLabel="날자를 입력해주세요."
//                                         value={data?.exeDate || null}
//                                         onChange={onChangeExeDate}
//                                         ref={exeDateRef}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Fragment>
//             )}

//             {(data?.broadcastType?.value === "AUDIO" || data?.broadcastType?.value === "MESSAGE") && (
//                 <div className="field field-type-checkbox required">
//                     <label className="field-label">Day of the Week</label>
//                     <div className="field-form">
//                         <div className="field-box">
//                             <div className="checkbox">
//                                 <input type="checkbox" name="checkbox-type" id="checkbox-1" onChange={(e) => {}} />
//                                 <label htmlFor="checkbox-1">Sun</label>
//                             </div>
//                             <div className="checkbox">
//                                 <input type="checkbox" name="checkbox-type" id="checkbox-2" onChange={(e) => {}} />
//                                 <label htmlFor="checkbox-2">Mon</label>
//                             </div>
//                             <div className="checkbox">
//                                 <input type="checkbox" name="checkbox-type" id="checkbox-3" onChange={(e) => {}} />
//                                 <label htmlFor="checkbox-3">Tue</label>
//                             </div>
//                             <div className="checkbox">
//                                 <input type="checkbox" name="checkbox-type" id="checkbox-4" onChange={(e) => {}} />
//                                 <label htmlFor="checkbox-4">Wed</label>
//                             </div>
//                             <div className="checkbox">
//                                 <input type="checkbox" name="checkbox-type" id="checkbox-5" onChange={(e) => {}} />
//                                 <label htmlFor="checkbox-5">Thu</label>
//                             </div>
//                             <div className="checkbox">
//                                 <input type="checkbox" name="checkbox-type" id="checkbox-6" onChange={(e) => {}} />
//                                 <label htmlFor="checkbox-6">Fri</label>
//                             </div>
//                             <div className="checkbox">
//                                 <input type="checkbox" name="checkbox-type" id="checkbox-7" onChange={(e) => {}} />
//                                 <label htmlFor="checkbox-7">Sat</label>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}

//             {(data?.broadcastType?.value === "IMAGE" || data?.broadcastType?.value === "AUDIO" || data?.broadcastType?.value === "MESSAGE") && (
//                 <div className="field field-type-dropdown field-repeat required">
//                     <label className="field-label" id="targetTime">
//                         Time
//                     </label>
//                     <div className="field-form">
//                         <div className="field-box">
//                             <SelectBox
//                                 id="targetTimeApm"
//                                 items={apmList}
//                                 value={"am"}
//                                 selectLabel={"AM" || ""}
//                                 onChange={(item) => {}}
//                                 ariaLabelId={"targetTime"}
//                                 title={t("subtext.input.title.powerontimeoption")}
//                             />
//                             <SelectBox id="targetTimeHour" items={hours} value={"09"} ariaLabelId={"targetTime"} selectLabel={"09" || ""} onChange={(item) => {}} />
//                             <span className="colon pc-only"> : </span>
//                             <SelectBox id="targetTimeMinute" items={minutes} value={"00"} ariaLabelId={"targetTime"} selectLabel={"00" || ""} onChange={(item) => {}} />
//                         </div>
//                     </div>
//                 </div>
//             )}

//             {data?.broadcastType?.value !== "MESSAGE" && (
//                 <Fragment>
//                     <div className="field field-type-template">
//                         <div className="field-header">
//                             <label htmlFor="ip-template-type" className="field-title">
//                                 Playback
//                             </label>
//                         </div>
//                         <div className="field-form">
//                             <div className="radio">
//                                 <input type="radio" name="rad-tem-type01" id="rad-tem-type01-1" onChange={(e) => {}} />
//                                 <label htmlFor="rad-tem-type01-1">Only Once</label>
//                             </div>
//                             <div className="radio">
//                                 <input type="radio" name="rad-tem-type01" id="rad-tem-type01-2" onChange={(e) => {}} />
//                                 <label htmlFor="rad-tem-type01-2">Repeatedly</label>
//                             </div>
//                         </div>
//                     </div>

//                     {data?.broadcastType?.value === "IMAGE" && (
//                         <Fragment>
//                             <div className="field field-type-message required">
//                                 <div className="field-header">
//                                     <label htmlFor="slideTime" className="field-title">
//                                         Slide Time
//                                     </label>
//                                 </div>
//                                 <div className="field-form">
//                                     <div className="input-box">
//                                         <SelectBox id="slideTime" items={minutes} value={"05"} ariaLabelId={"Time"} selectLabel={"5 Seconds" || ""} onChange={(item) => {}} />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="field field-type-message required">
//                                 <div className="field-header">
//                                     <label htmlFor="ip-message-type" className="field-title">
//                                         Holding Time(minute)
//                                     </label>
//                                 </div>
//                                 <div className="field-form">
//                                     <div className="input-box">
//                                         <input
//                                             type="text"
//                                             id="ip-message-type"
//                                             name=""
//                                             value="1"
//                                             autoComplete="false"
//                                             aria-required="true"
//                                             aria-invalid="true"
//                                             aria-describedby="description-id"
//                                             aria-errormessage="error-id"
//                                             onChange={(e) => {}}
//                                         />
//                                         <button type="button" className="btn-input-remove hide">
//                                             <span className="ir">delete input</span>
//                                         </button>
//                                     </div>
//                                 </div>
//                                 <span id="description-id" className="field-description">
//                                     Set between 1 and 120 minutes. (When the retention time ends, the audio file also ends.)
//                                 </span>
//                             </div>

//                             <div className="field field-type-message required">
//                                 <div className="field-header">
//                                     <label htmlFor="ip-message-type" className="field-title">
//                                         Image
//                                     </label>
//                                 </div>
//                                 <div className="field-form">
//                                     <div className="input-box">
//                                         <button type="button" className="btn btn-tertiary" onClick={() => setFileModal("IMAGE")}>
//                                             Select
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="field field-type-message required">
//                                 <div className="field-header">
//                                     <label htmlFor="ip-message-type" className="field-title">
//                                         Selected File
//                                     </label>
//                                 </div>
//                                 <div className="field-form">
//                                     <div className="table">
//                                         <div className="table-wrapper device-table no-scroll">
//                                             <div className="table-box">
//                                                 <table style={{ height: "255px" }}>
//                                                     <caption>Discovered device list</caption>
//                                                     <thead>
//                                                         <tr>
//                                                             {/* <!-- <th scope="col"><div className="text-block">Menu</div></th>
//                             <th scope="col"><div className="text-block">Permission</div></th> --> */}
//                                                             <th scope="col">
//                                                                 <div className="text-block">No.</div>
//                                                             </th>
//                                                             <th scope="col">
//                                                                 <div className="text-block">Image</div>
//                                                             </th>
//                                                             <th scope="col" style={{ width: "40%" }}>
//                                                                 <div className="text-block">Fill Name</div>
//                                                             </th>
//                                                             <th scope="col" style={{ width: "15%" }}>
//                                                                 <div className="text-block">Delete</div>
//                                                             </th>
//                                                             <th scope="col" style={{ width: "20%" }}>
//                                                                 <div className="text-block">Sort</div>
//                                                             </th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         <tr>
//                                                             <td>123</td>
//                                                             <td>
//                                                                 <img src="/assets/images/sample/details_display.jpg" width="100%" alt="Display" />
//                                                             </td>
//                                                             <td style={{ width: "40%" }}>Data</td>
//                                                             <td className="center" style={{ width: "15%" }}>
//                                                                 <button type="button" id="" className="btn btn-delete" onClick="togglePopup('', '');">
//                                                                     Delete
//                                                                 </button>
//                                                             </td>
//                                                             <td className="center" style={{ width: "20%" }}>
//                                                                 <button type="button" id="" className="btn btn-sort-up" onClick="togglePopup('', '');">
//                                                                     up
//                                                                 </button>
//                                                                 <button type="button" id="" className="btn btn-sort-down" onClick="togglePopup('', '');">
//                                                                     Down
//                                                                 </button>
//                                                             </td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="convert-table-to-list">
//                                         <div className="table-header">
//                                             <div className="row">
//                                                 <div className="thead">Image</div>
//                                                 <div className="thead">Fill Name</div>
//                                             </div>
//                                         </div>

//                                         <ul className="table-body">
//                                             <li className="table-row">
//                                                 <div className="summary-data">
//                                                     <div className="row">
//                                                         <div className="table-cell issue-level">
//                                                             <img src="/assets/images/sample/details_display.jpg" width="100%" alt="Display" />
//                                                         </div>
//                                                         <div className="table-cell issue-level">
//                                                             <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false">
//                                                                 Data
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="all-data-box">
//                                                     <ul className="all-data">
//                                                         <li>
//                                                             <span className="field-label">Delete</span>
//                                                             <span className="field-content">
//                                                                 <button type="button" id="" className="btn btn-delete" onClick="togglePopup('', '');">
//                                                                     Delete
//                                                                 </button>
//                                                             </span>
//                                                         </li>
//                                                         <li>
//                                                             <span className="field-label">Sort</span>
//                                                             <span className="field-content">
//                                                                 <button type="button" id="" className="btn btn-sort-up" onClick="togglePopup('', '');">
//                                                                     up
//                                                                 </button>
//                                                                 <button type="button" id="" className="btn btn-sort-down" onClick="togglePopup('', '');">
//                                                                     Down
//                                                                 </button>
//                                                             </span>
//                                                         </li>
//                                                     </ul>
//                                                 </div>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="title-box">
//                                 <h2 className="section-title">Audio Playback Option</h2>
//                             </div>

//                             <div className="field field-type-template">
//                                 <div className="field-header">
//                                     <label htmlFor="ip-template-type" className="field-title">
//                                         Playback
//                                     </label>
//                                 </div>
//                                 <div className="field-form">
//                                     <div className="radio">
//                                         <input type="radio" name="rad-tem-type02" id="rad-tem-type02-1" onChange={(e) => {}} />
//                                         <label htmlFor="rad-tem-type02-1">Not Used</label>
//                                     </div>
//                                     <div className="radio">
//                                         <input type="radio" name="rad-tem-type02" id="rad-tem-type02-2" onChange={(e) => {}} />
//                                         <label htmlFor="rad-tem-type02-2">Used</label>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="field field-type-file required">
//                                 <div className="field-header">
//                                     <label htmlFor="ip-sound-file" className="field-title">
//                                         Audio File
//                                     </label>
//                                 </div>
//                                 <div className="field-form">
//                                     <div className="field-box">
//                                         <input type="text" id="ip-sound-file" readOnly={true} placeholder="Audio File" onChange={(e) => {}} />
//                                         <button type="button" className="btn btn-tertiary" onClick={() => setFileModal("AUDIO")}>
//                                             Select
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Fragment>
//                     )}

//                     <div className="field field-type-message required">
//                         <div className="field-header">
//                             <label htmlFor="ip-message-type" className="field-title">
//                                 Volume
//                             </label>
//                         </div>
//                         <div className="field-form">
//                             <div className="input-box">
//                                 <input
//                                     type="text"
//                                     id="ip-message-type"
//                                     name=""
//                                     value="0"
//                                     autoComplete="false"
//                                     aria-required="true"
//                                     aria-invalid="true"
//                                     aria-describedby="description-id"
//                                     aria-errormessage="error-id"
//                                     onChange={(e) => {}}
//                                 />
//                                 <button type="button" className="btn-input-remove hide">
//                                     <span className="ir">delete input</span>
//                                 </button>
//                             </div>
//                         </div>
//                         <span id="description-id" className="field-description">
//                             The volume can be set between 0 and 100.
//                         </span>
//                     </div>
//                 </Fragment>
//             )}

//             {data?.broadcastType?.value === "VIDEO" && (
//                 <div className="field field-type-file required">
//                     <div className="field-header">
//                         <label htmlFor="ip-sound-file" className="field-title">
//                             Video File
//                         </label>
//                     </div>
//                     <div className="field-form">
//                         <div className="field-box">
//                             <input type="text" id="ip-sound-file" readOnly={true} placeholder="Video File" onChange={(e) => {}} />
//                             <button type="button" className="btn btn-tertiary" onClick={() => setFileModal("VIDEO")}>
//                                 Select
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//             {data?.broadcastType?.value === "AUDIO" && (
//                 <div className="field field-type-file required">
//                     <div className="field-header">
//                         <label htmlFor="ip-sound-file" className="field-title">
//                             Audio File
//                         </label>
//                     </div>
//                     <div className="field-form">
//                         <div className="field-box">
//                             <input type="text" id="ip-sound-file" readOnly={true} placeholder="Audio File" onChange={(e) => {}} />
//                             <button type="button" className="btn btn-tertiary" onClick={() => setFileModal("AUDIO")}>
//                                 Select
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//             {data?.broadcastType?.value === "MESSAGE" && (
//                 <Fragment>
//                     <div className="title-box">
//                         <h2 className="section-title">User Information</h2>
//                     </div>

//                     <div className="field field-type-template">
//                         <div className="field-header">
//                             <label htmlFor="ip-template-type" className="field-title">
//                                 Type
//                             </label>
//                         </div>
//                         <div className="field-form">
//                             <div className="radio">
//                                 <input type="radio" name="rad-tem-type01" id="rad-tem-type01-1" onChange={(e) => {}} />
//                                 <label htmlFor="rad-tem-type01-1">pop-up</label>
//                             </div>
//                             <div className="radio">
//                                 <input type="radio" name="rad-tem-type01" id="rad-tem-type01-2" onChange={(e) => {}} />
//                                 <label htmlFor="rad-tem-type01-2">Full Screen</label>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="field field-type-message required">
//                         <div className="field-header">
//                             <label htmlFor="ip-message-type" className="field-title">
//                                 Retention time(minute)
//                             </label>
//                         </div>
//                         <div className="field-form">
//                             <div className="input-box">
//                                 <input
//                                     type="text"
//                                     id="ip-message-type"
//                                     name=""
//                                     value="1"
//                                     autoComplete="false"
//                                     aria-required="true"
//                                     aria-invalid="true"
//                                     aria-describedby="description-id"
//                                     aria-errormessage="error-id"
//                                     onChange={(e) => {}}
//                                 />
//                                 <button type="button" className="btn-input-remove hide">
//                                     <span className="ir">delete input</span>
//                                 </button>
//                             </div>
//                         </div>
//                         <span id="description-id" className="field-description">
//                             Set between 1 and 120 minutes.
//                         </span>
//                     </div>

//                     <div className="field field-type-message required">
//                         <div className="field-header">
//                             <label htmlFor="ip-message-type" className="field-title">
//                                 Notification Sound Volume
//                             </label>
//                         </div>
//                         <div className="field-form">
//                             <div className="input-box">
//                                 <input
//                                     type="text"
//                                     id="ip-message-type"
//                                     name=""
//                                     value="0"
//                                     autoComplete="false"
//                                     aria-required="true"
//                                     aria-invalid="true"
//                                     aria-describedby="description-id"
//                                     aria-errormessage="error-id"
//                                     onChange={(e) => {}}
//                                 />
//                                 <button type="button" className="btn-input-remove hide">
//                                     <span className="ir">delete input</span>
//                                 </button>
//                             </div>
//                         </div>
//                         <span id="description-id" className="field-description">
//                             The volume can be set between 0 and 100.
//                         </span>
//                     </div>

//                     <div className="field field-type-textarea required">
//                         <div className="field-header">
//                             <label htmlFor="ip-textarea" className="field-title">
//                                 Message
//                             </label>
//                         </div>
//                         <div className="field-form">
//                             <div className="field-box" style={{ margin: "0" }}>
//                                 <textarea name="ip-textarea" id="ip-textarea" aria-required="true" placeholder="Message"></textarea>
//                             </div>
//                             <div className="word-count">(0/200)</div>
//                         </div>
//                     </div>
//                 </Fragment>
//             )}
//             {fileModal && <FileModal onClose={() => setFileModal(false)} />}
//         </Modal>
//     );
// };

// const FileModal = (props) => {
//     const onClickDone = () => {
//         props.onClose();
//     };

//     useEffect(() => {
//         resizeTable();
//     }, []);

//     return (
//         <Modal buttonOkTitle="Select" title="Select" id="popup-select-file" className="popup-select middle" onClose={props.onClose} onClick={onClickDone}>
//             <div className="data-list-container">
//                 <div className="data-list-top">
//                     <div className="left-side"></div>
//                     <div className="right-side">
//                         <div className="total-count">
//                             Total <span className="count">N</span>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="data-list-bottom">
//                     <div className="table">
//                         <div className="table-wrapper device-table no-scroll">
//                             <div className="table-box">
//                                 <table>
//                                     <caption>list</caption>
//                                     <thead>
//                                         <tr>
//                                             <th scope="col" className="check" width="60"></th>
//                                             <th scope="col">
//                                                 <div className="text-block">Fill Name</div>
//                                             </th>
//                                             <th scope="col">
//                                                 <div className="text-block">Size</div>
//                                             </th>
//                                             <th scope="col">
//                                                 <div className="text-block">Date</div>
//                                             </th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr>
//                                             <td className="center" width="60">
//                                                 <div className="field field-type-checkbox field-select-device">
//                                                     <div className="field-form">
//                                                         <div className="radio">
//                                                             <input type="radio" name="radio-type" id="radio-1" onChange={(e) => {}} />
//                                                             <label for="radio-1">
//                                                                 <span className="ir">행 선택</span>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td>Aaaabbbccc.jpg</td>
//                                             <td>20MB</td>
//                                             <td>2023-10-20 17:53</td>
//                                         </tr>
//                                         <tr>
//                                             <td className="center" width="60">
//                                                 <div className="field field-type-checkbox field-select-device">
//                                                     <div className="field-form">
//                                                         <div className="radio">
//                                                             <input type="radio" name="radio-type" id="radio-2" onChange={(e) => {}} />
//                                                             <label for="radio-2">
//                                                                 <span className="ir">행 선택</span>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td>Aaaabbbccc.jpg</td>
//                                             <td>20MB</td>
//                                             <td>2023-10-20 17:53</td>
//                                         </tr>
//                                         <tr>
//                                             <td className="center" width="60">
//                                                 <div className="field field-type-checkbox field-select-device">
//                                                     <div className="field-form">
//                                                         <div className="radio">
//                                                             <input type="radio" name="radio-type" id="radio-3" onChange={(e) => {}} />
//                                                             <label for="radio-3">
//                                                                 <span className="ir">행 선택</span>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td>Aaaabbbccc.jpg</td>
//                                             <td>20MB</td>
//                                             <td>2023-10-20 17:53</td>
//                                         </tr>
//                                         <tr>
//                                             <td className="center" width="60">
//                                                 <div className="field field-type-checkbox field-select-device">
//                                                     <div className="field-form">
//                                                         <div className="radio">
//                                                             <input type="radio" name="radio-type" id="radio-4" onChange={(e) => {}} />
//                                                             <label for="radio-4">
//                                                                 <span className="ir">행 선택</span>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td>Aaaabbbccc.jpg</td>
//                                             <td>20MB</td>
//                                             <td>2023-10-20 17:53</td>
//                                         </tr>
//                                         <tr>
//                                             <td className="center" width="60">
//                                                 <div className="field field-type-checkbox field-select-device">
//                                                     <div className="field-form">
//                                                         <div className="radio">
//                                                             <input type="radio" name="radio-type" id="radio-5" onChange={(e) => {}} />
//                                                             <label for="radio-5">
//                                                                 <span className="ir">행 선택</span>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td>Aaaabbbccc.jpg</td>
//                                             <td>20MB</td>
//                                             <td>2023-10-20 17:53</td>
//                                         </tr>
//                                         <tr>
//                                             <td className="center" width="60">
//                                                 <div className="field field-type-checkbox field-select-device">
//                                                     <div className="field-form">
//                                                         <div className="radio">
//                                                             <input type="radio" name="radio-type" id="radio-6" onChange={(e) => {}} />
//                                                             <label for="radio-6">
//                                                                 <span className="ir">행 선택</span>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td>Aaaabbbccc.jpg</td>
//                                             <td>20MB</td>
//                                             <td>2023-10-20 17:53</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="convert-table-to-list">
//                         <div className="table-header">
//                             <div className="row">
//                                 <div className="thead"></div>
//                                 <div className="thead">title</div>
//                             </div>
//                         </div>

//                         <ul className="table-body">
//                             <li className="table-row">
//                                 <div className="summary-data">
//                                     <div className="row">
//                                         <div className="table-cell number center">
//                                             <div className="field field-type-checkbox field-select-device">
//                                                 <div className="field-form">
//                                                     <div className="radio">
//                                                         <input type="radio" name="radio-type" id="radio-7" onChange={(e) => {}} />
//                                                         <label for="radio-7">
//                                                             <span className="ir">행 선택</span>
//                                                         </label>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="table-cell issue-level">
//                                             <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false">
//                                                 Data
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="all-data-box">
//                                     <ul className="all-data">
//                                         <li>
//                                             <span className="field-label">Fill Name</span>
//                                             <span className="field-content">Aaaabbbccc.jpg</span>
//                                         </li>
//                                         <li>
//                                             <span className="field-label">Size</span>
//                                             <span className="field-content">20MB</span>
//                                         </li>
//                                         <li>
//                                             <span className="field-label">Date</span>
//                                             <span className="field-content">2023-10-20 17:53</span>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </Modal>
//     );
// };
