// broadcast 및 command 팝업에서 사용하는 상수 목록
export const list = {
    type: ["Video", "Image", "Audio", "Message"],
    typeDropdown: [
        { label: "Video", value: "video" },
        { label: "Image", value: "image" },
        { label: "Audio", value: "audio" },
        { label: "Message", value: "message" },
        { label: "Live Streaming", value: "live streaming" },
    ],
    typeDropdownWebOs: [
        { label: "Video", value: "video" },
        { label: "Image", value: "image" },
        { label: "Message", value: "message" },
        { label: "Live Streaming", value: "live streaming" },
    ],
    execution: ["Immediately", "Specific", "Repeat"],
    executionDropdown: [{ label: "Immediately", value: "immediately" }],
    executionDropdown2: [
        { label: "Immediately", value: "immediately" },
        { label: "Specific", value: "specific" },
        { label: "Repeat", value: "repeat" },
    ],
    executionType: [
        { label: "AM", value: "AM" },
        { label: "PM", value: "PM" },
    ],
    executionDayOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    playback: [
        { txt: "Only Once", name: "only once" },
        { txt: "Repeatedly", name: "repeatedly" },
    ],
    imgPlayback: [
        { txt: "Not Used", name: "not used" },
        { txt: "Used", name: "used" },
    ],
    slideTimeList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    msgType: [
        { txt: "Pop-up", name: "pop-up" },
        { txt: "Full Screen", name: "full screen" },
    ],
};

export const cmd_list = {
    ...list,
    execution: ["Specific", "Repeat"],
};

// command 팝업에서 사용하는 상수 목록
export const command_list = {
    type: ["Screen On/Off", "Input", "Touch Lock", "IR Lock", "USB Lock", "Screen Lock", "Brightness", "Volume", "Send File", "Install Package"],
    screenType: [
        { txt: "Screen On", name: "screen on" },
        { txt: "Screen Off", name: "screen off" },
    ],
    inputType: [
        { value: "00", label: "TV" },
        { value: "F9", label: "Android" },
        { value: "F1", label: "HDMI1" },
        { value: "F2", label: "HDMI2" },
        { value: "F5", label: "HDMI3" },
        { value: "F4", label: "HDMI4" },
        { value: "F0", label: "VGA" },
        { value: "FD", label: "Type C" },
        { value: "FE", label: "Type C 2" },
        { value: "FA", label: "PC" },
        { value: "FB", label: "DP" },
        { value: "20", label: "AV" },
        { value: "40", label: "COMPONENT" },
        { value: "60", label: "RGB" },
        { value: "70", label: "DVI-D (PC / Normal)" },
        { value: "80", label: "DVI-D (DTV / Video)" },
        { value: "90", label: "HDMI1 (DTV / Video)" },
        { value: "A0", label: "HDMI1 (PC / Normal)" },
        { value: "91", label: "HDMI2 (DTV / Video)" },
        { value: "A1", label: "HDMI2 (PC / Normal)" },
        { value: "92", label: "OPS/HDMI3/DVI-D (DTV / Video)" },
        { value: "A2", label: "OPS/HDMI3/DVI-D (PC / Normal)" },
        { value: "93", label: "HDMI4 (DTV / Video)" },
        { value: "A3", label: "HDMI4 (PC / Normal)" },
        { value: "95", label: "OPS/DVI-D (DTV / Video)" },
        { value: "A5", label: "OPS/DVI-D (PC / Normal)" },
        { value: "96", label: "HDMI3/DVI-D (DTV / Video)" },
        { value: "A6", label: "HDMI3/DVI-D (PC / Normal)" },
        { value: "97", label: "HDMI3/HDMI2/DVI-D (DTV / Video)" },
        { value: "A7", label: "HDMI3/HDMI2/DVI-D (PC / Normal)" },
        { value: "98", label: "OPS (DTV / Video)" },
        { value: "A8", label: "OPS (PC / Normal)" },
        { value: "99", label: "HDMI2/OPS (DTV / Video)" },
        { value: "A9", label: "HDMI2/OPS (PC / Normal)" },
        { value: "C0", label: "DISPLAYPORT (DTV / Video)" },
        { value: "D0", label: "DISPLAYPORT (PC / Normal)" },
        { value: "C1", label: "DISPLAYPORT/USB-C (DTV/ Video)" },
        { value: "D1", label: "DISPLAYPORT/USB-C (PC / Normal)" },
        { value: "C2", label: "HDMI3 (DTV / Video)" },
        { value: "D2", label: "HDMI3 (PC / Normal)" },
        { value: "C3", label: "HDBaseT (DTV / Video)" },
        { value: "D3", label: "HDBaseT (PC / Normal)" },
        { value: "C5", label: "USB-C (DTV / Video)" },
        { value: "D5", label: "USB-C (PC / Normal)" },
        { value: "E0", label: "SuperSign webOS Player" },
        { value: "E1", label: "Others" },
        { value: "E2", label: "Multi Screen" },
        { value: "E3", label: "URL 재생(SuperSign Server 기능)" },
        { value: "E4", label: "One:Quick" },
        { value: "E5", label: "One:Quick Flex" },
        { value: "E6", label: "One:Quick Works" },
        { value: "E7", label: "One:Quick Pro" },
        { value: "E8", label: "SI App" },
        { value: "F0", label: "SDI 1" },
        { value: "F1", label: "SDI 2" },
        { value: "F2", label: "SDI 3" },
        { value: "F3", label: "SDI 4" },
        { value: "F4", label: "Dual Link (SDI 1&2)" },
        { value: "F5", label: "Dual Link (SDI 3&4)" },
        { value: "F6", label: "Quad Link : Auto" },
        { value: "F7", label: "Quad Link : 2SI" },
        { value: "F8", label: "Quad Link : Square" },
        { value: "F9", label: "SDI Quad View" },
    ],
    touchLockType: [
        { txt: "Touch Lock", name: "touch lock" },
        { txt: "Touch Unlock", name: "touch unlock" },
    ],
    irLockType: [
        { txt: "IR Lock", name: "ir lock" },
        { txt: "IR Unlock", name: "ir unlock" },
    ],
    usbLockType: [
        { txt: "USB Lock", name: "usb lock" },
        { txt: "USB Unlock", name: "usb unlock" },
    ],
    screenLockType: [
        { txt: "Screen Lock", name: "screen lock" },
        { txt: "Screen Unlock", name: "screen unlock" },
    ],
    lockType: [
        { txt: "Lock", name: "lock" },
        { txt: "Unlock", name: "unlock" },
    ],
};
