import React, { Fragment, useContext, useEffect, useState } from "react";
import useCss from "@hook/useCss";
import _API from "@util/api";
import LastUpdate from "@component/UI/LastUpdate";

import { CycleModal } from "@component/Report";
import styled from "styled-components";
import { AppContext } from "@component/AppProvider";
import Loading from "@component/UI/Loading";
import useWindowWidth from "@hook/useWindowWidth";
import InfiniteScroll from "react-infinite-scroll-component";
import Dialog from "@component/UI/Dialog";
import utils from "@util/utilities";
import { useTranslation } from "react-i18next";

const StyledTable = styled.table`
    height: unset !important;
    tbody {
        height: unset !important;
        max-height: 500px !important;
        position: unset !important;
    }
`;

export default (props) => {
    useCss("/assets/css/report.css");

    const { t } = useTranslation();
    const { auth, workspace, rolePermission } = useContext(AppContext);
    const windowWidth = useWindowWidth();

    const [loading, setLoading] = useState(true);
    const [emailModal, setEmailModal] = useState(false);
    const [cycleModal, setCycleModal] = useState(false);
    const [settingModal, setSettingModal] = useState(false);
    const [recipientsModal, setRecipientsModal] = useState(false);
    const [selectItem, setSelectItem] = useState(null);
    const [receive, setReceive] = useState(false);
    const [cycle, setCycle] = useState(0);
    const [lastId, setLastId] = useState(null);
    const [emailList, setEmailList] = useState([]);
    const [reportDate, setReportDate] = useState("");
    const [data, setData] = useState({
        total_count: 0,
        total_pages: 0,
        page: 0,
        items: [],
    });

    const onReceiveChange = () => {
        const bool = !receive;
        setReceive(bool);
        _API.put({
            path: "/report/settings/onoff",
            data: { enable: bool ? 1 : 0 },
        }).finally(() => getSetting());
        // if (bool) setSettingModal(true);
    };

    const onChangeCycle = (c) => {
        setCycle(c ? "MONTHLY" : "BI-WEEKLY");
        _API.put({
            path: "/report/settings/cycle",
            data: { cycle: c ? "MONTHLY" : "BI-WEEKLY" },
        }).finally(() => {
            getReportDate();
        });
    };

    const onEmailSave = (mailList) => {
        setEmailModal(false);
        emailListEdit(mailList);
    };

    const onChangeSetting = (c) => {
        setCycle(c ? "MONTHLY" : "BI-WEEKLY");
        _API.put({
            path: "/report/settings/cycle",
            data: { cycle: c ? "MONTHLY" : "BI-WEEKLY" },
        }).finally(() => {
            getReportDate();
        });
    };

    const onClickDeleteAll = () => {
        Dialog({
            title: t("player.button.deleteAll"),
            text: t("common.label.DeleteAllHistory"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: `/report/history/delete`,
                    data: { report_ids: [] },
                })
                    .then(() => {
                        fetchList(true);
                    })
                    .catch((error) => {
                        console.error("Failed to delete:", error);
                    });
            }
        });
    };

    const onClickDelete = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("common.label.DeleteTheHistory"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((ok) => {
            if (ok) {
                if (item?.hasOwnProperty("id")) {
                    const { id } = item;
                    _API.post({
                        path: `/report/history/delete`,
                        data: { report_ids: [id] },
                    })
                        .then(() => {
                            fetchList(true);
                        })
                        .catch((error) => {
                            console.error("Failed to delete:", error);
                        });
                }
            }
        });
    };

    const onClickDownload = (item) => {
        setLoading(true);
        _API.get({ path: `/report/history/download/${item.id}` })
            .then((res) => {
                const { download } = res?.data?.result;
                if (download) window.open(download);
            })
            .finally(() => setLoading(false));
    };

    const onRecipientsModal = (item) => {
        setRecipientsModal(true);
        setSelectItem(item);
    };

    const getSetting = async () => {
        _API.get({ path: "/report/settings" }).then((res) => {
            const { result } = res?.data;
            if (result) {
                const { enable, recipients } = result;
                setReceive(enable);
                setEmailList(recipients);
                setCycle(result?.cycle);
            }
        });
    };

    const fetchData = async () => {
        try {
            await Promise.all([getSetting(), fetchList(true), getReportDate()]);
        } catch (error) {
            console.error(error);
        }
    };

    // 이메일 수신자 목록 변경 (E-Mail Recipients 목록 변경)
    const emailListEdit = (mailList) => {
        _API.put({
            path: "/report/settings/emails",
            data: { recipients: mailList },
        });
        setEmailList(mailList);
    };

    const getReportDate = () => {
        _API.get({ path: "/report/history/schedule" }).then((res) => {
            setReportDate(res?.data?.result?.schedule);
        });
    };

    const fetchList = (isInit = true) => {
        const perPage = 10;
        if (isInit) {
            setLoading(true);
            _API.get({
                path: "/report/history",
                data: {
                    page: 0,
                    per_page: perPage,
                },
            })
                .then((res) => {
                    let {
                        result: {
                            data,
                            paging: { total_count: total },
                        },
                    } = res?.data;
                    const total_pages = Math.ceil(total / perPage);
                    setData({
                        ...data,
                        total_count: total,
                        total_pages,
                        page: 0,
                        items: data,
                    });
                })
                .finally(() => setLoading(false));
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            setLoading(true);
            _API.get({
                path: "/report/history",
                data: {
                    page,
                    per_page: perPage,
                },
            })
                .then((res) => {
                    let {
                        result: {
                            data: dataInfo,
                            paging: { total_count: total },
                        },
                    } = res?.data;
                    const total_pages = Math.ceil(total / perPage);
                    setData({
                        ...data,
                        total_count: total,
                        total_pages,
                        page,
                        items: [...data.items, ...dataInfo],
                    });
                })
                .finally(() => setLoading(false));
        }
    };

    const getIsDisabled = () => utils.getAuthWriteDisable(rolePermission);
    const getIsAdmin = () => {
        const list = auth?.portal_user_roles?.filter(
            (f) => "PlatformAdministrator" === f.common_role_name || (["BusinessAdministrator"].includes(f.common_role_name) && f.business_id === workspace?.business_id)
        );
        return list?.length > 0;
    };

    useEffect(() => {
        document.title = t("common.label.report") + " | " + t("common.title.lgconnectedcare");
        fetchData();
    }, [workspace]);

    return (
        <Fragment>
            {loading && <Loading />}

            <div className="content-top">
                <div className="top-left">
                    <h1>{t("common.label.report")}</h1>
                </div>

                <LastUpdate onRefresh={fetchData} />
            </div>

            <div className="content-middle">
                <div className="content-middle-box">
                    <div className="report-settings">
                        <div className="report-settings-top">
                            <button type="button" className="btn-icon btn-report-settings">
                                <span className="ir">{t("common.label.ReportReceiveSettings")}</span>
                            </button>
                            <h2 className="report-title">{t("common.label.ReportReceiveSettings")}</h2>
                        </div>
                        <div className="list-box">
                            <dl>
                                <dt>{t("common.label.ReceiveReport")}</dt>
                                <dd>
                                    <div className="switch">
                                        <label htmlFor="default-switch-1" className="switch-controller">
                                            {t("player.label.on")}
                                        </label>
                                        <input
                                            type="checkbox"
                                            id="default-switch-1"
                                            name="default-switch-1"
                                            role="switch"
                                            aria-checked={receive ? "true" : "false"}
                                            aria-label={t("common.label.ReceiveReport")}
                                            checked={receive}
                                            onChange={onReceiveChange}
                                            disabled={getIsDisabled()}
                                        />
                                        <span className="slider"></span>
                                    </div>
                                </dd>
                            </dl>
                            {receive && getIsAdmin() ? (
                                <Fragment>
                                    {/* <dl>
                                        <dt>{t("common.label.EMailRecipients")}</dt>
                                        <dd>
                                            <button type="button" className="btn btn-recipients" role="" onClick={() => setEmailModal(true)}>
                                                {emailList.length} &gt;
                                            </button>
                                        </dd>
                                    </dl> */}
                                    <dl>
                                        <dt>{t("common.label.ReportReceiveCycle")}</dt>
                                        <dd>
                                            <span>{cycle === "BI-WEEKLY" ? t("common.label.Every2Weeks") : t("common.label.EveryMonth")}</span>
                                            <button type="button" className="btn btn-change" role="" onClick={() => setCycleModal(true)} disabled={getIsDisabled()}>
                                                {t("button.Change")}
                                            </button>
                                        </dd>
                                    </dl>
                                </Fragment>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    <div className="content-middle-top">
                        <h2 className="title-content">{t("common.label.ReportSendingHistory")}</h2>
                    </div>

                    <div className="search-and-filter-box">
                        <div className="filter-box">
                            <div className="left-side">
                                {getIsAdmin() && (
                                    <button type="button" className="btn btn-delete-all" onClick={onClickDeleteAll} disabled={getIsDisabled()}>
                                        {t("player.button.deleteAll")}
                                    </button>
                                )}
                            </div>

                            <div className="right-side">
                                <div className="selected-count">
                                    {t("player.label.total")} : <span className="count">{data ? data?.total_count : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-content">
                        <div className="white-area">
                            <div className="data-list-container">
                                <div className="table">
                                    <div className="table-wrapper horizontal-scroll select-mode">
                                        <div className="table-box">
                                            <InfiniteScroll
                                                style={{ overflow: "hidden" }}
                                                dataLength={data ? data?.items?.length : 0}
                                                next={() => fetchList(false)}
                                                hasMore={windowWidth > 991}
                                                loader={""}
                                                scrollableTarget="reportTbody"
                                            >
                                                <StyledTable>
                                                    <caption>{t("common.label.ReportSendingHistory")}</caption>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="25%">
                                                                <div className="text-block">{t("common.label.SentDate")}</div>
                                                            </th>
                                                            <th scope="col" width="25%">
                                                                <div className="text-block">{t("common.label.ReportReceiveCycle")}</div>
                                                            </th>
                                                            <th scope="col" width="15%">
                                                                <div className="text-block">{t("common.label.ReportDownload")}</div>
                                                            </th>
                                                            {getIsAdmin() && (
                                                                <th scope="col" width="10%">
                                                                    <div className="text-block">{t("button.delete")}</div>
                                                                </th>
                                                            )}
                                                        </tr>
                                                    </thead>

                                                    <tbody id="reportTbody">
                                                        <tr>
                                                            <td className="alert" colSpan="5">
                                                                {receive ? (
                                                                    t("common.label.ReportScheduleSend", { 0: reportDate })
                                                                ) : (
                                                                    <>
                                                                        {t("common.label.ReceiveFunctionOff")}
                                                                        <br />
                                                                        {t("common.label.ReceiveFunctionOff2")}
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        {data?.items?.map(
                                                            (item, index) =>
                                                                item && (
                                                                    <tr key={index}>
                                                                        <td className="width-25-percent" id={`report-td1-${index}`}>
                                                                            {item.sent_at ? utils.getOffsetTime(auth, workspace, item.sent_at) : ""}
                                                                        </td>
                                                                        <td className="width-25-percent" id={`report-td2-${index}`}>
                                                                            {item?.cycle === "BI-WEEKLY" ? t("common.label.Every2Weeks") : t("common.label.EveryMonth")}
                                                                        </td>
                                                                        <td className="align-center width-15-percent">
                                                                            <button
                                                                                aria-describedby={`report-td1-${index} report-td2-${index}`}
                                                                                type="button"
                                                                                className="btn btn-download"
                                                                                onClick={() => onClickDownload(item)}
                                                                            >
                                                                                {t("remote.button.download")}
                                                                            </button>
                                                                        </td>
                                                                        {getIsAdmin() && (
                                                                            <td className="align-center width-10-percent">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-delete"
                                                                                    onClick={() => onClickDelete(item)}
                                                                                    aria-describedby={`report-td1-${index} report-td2-${index}`}
                                                                                    disabled={getIsDisabled()}
                                                                                >
                                                                                    {t("button.delete")}
                                                                                </button>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                )
                                                        )}
                                                    </tbody>
                                                </StyledTable>
                                            </InfiniteScroll>
                                        </div>
                                    </div>

                                    <div className="convert-table-to-list">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="thead">{t("common.label.SentDate")}</div>
                                            </div>
                                        </div>
                                        <InfiniteScroll
                                            style={{ overflow: "hidden" }}
                                            dataLength={data ? data?.items?.length : 0}
                                            next={() => fetchList(false)}
                                            hasMore={windowWidth <= 991}
                                            loader={""}
                                        >
                                            <ul className="table-body">
                                                <li className="alert" colSpan="5">
                                                    {receive ? (
                                                        t("common.label.ReportScheduleSend", { 0: reportDate })
                                                    ) : (
                                                        <>
                                                            {t("common.label.ReceiveFunctionOff")}
                                                            <br />
                                                            {t("common.label.ReceiveFunctionOff2")}
                                                        </>
                                                    )}
                                                </li>
                                                {data?.items?.map(
                                                    (item, index) =>
                                                        item && (
                                                            <li className="table-row" key={`reflow-${index}`}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell status">
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                                {item.sent_at ? utils.getOffsetTime(auth, workspace, item.sent_at) : ""}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        {/* <li id={`ali1-${index}`}>
                                                                            <span className="field-label">{t("common.label.EMailRecipients")}</span>
                                                                            <span className="field-content">
                                                                                <a href="#!" className="link-target" onClick={() => onRecipientsModal(item.recipients.split(","))}>
                                                                                    {item.recipients.split(",").length}
                                                                                </a>
                                                                            </span>
                                                                        </li> */}
                                                                        <li id={`ali2-${index}`}>
                                                                            <span className="field-label">{t("common.label.ReportReceiveCycle")}</span>
                                                                            <span className="field-content">
                                                                                {item?.cycle === "BI-WEEKLY" ? t("common.label.Every2Weeks") : t("common.label.EveryMonth")}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("common.label.ReportDownload")}</span>
                                                                            <span className="field-content">
                                                                                <button
                                                                                    type="button"
                                                                                    aria-describedby={`ali2-${index}`}
                                                                                    className="btn btn-download"
                                                                                    onClick={() => onClickDownload(item)}
                                                                                >
                                                                                    {t("remote.button.download")}
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                        {getIsAdmin() && (
                                                                            <li>
                                                                                <span className="field-label">{t("button.delete")}</span>
                                                                                <span className="field-content">
                                                                                    <button
                                                                                        type="button"
                                                                                        aria-describedby={`ali2-${index}`}
                                                                                        className="btn btn-delete"
                                                                                        onClick={() => onClickDelete(item)}
                                                                                        disabled={getIsDisabled()}
                                                                                    >
                                                                                        {t("button.delete")}
                                                                                    </button>
                                                                                </span>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {emailModal && <EmailModal onSave={onEmailSave} onClose={() => setEmailModal(false)} emailList={emailList} />} */}
            {cycleModal && <CycleModal cycle={cycle === "BI-WEEKLY" ? 0 : 1} onSetCycle={onChangeCycle} onClose={() => setCycleModal(false)} />}
            {/* {settingModal && (
                <SettingModal cycle={cycle === "BI-WEEKLY" ? 0 : 1} onSetSetting={onChangeSetting} onEmailSave={onEmailSave} onClose={() => setSettingModal(false)} emailList={emailList} />
            )} */}
            {/* {recipientsModal && <RecipientsModal selectItem={selectItem} onClose={() => setRecipientsModal(false)} />} */}
        </Fragment>
    );
};
