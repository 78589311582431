import React, { Fragment, useEffect, useState, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import useCss from "@hook/useCss";

import Modal from "@component/UI/Modal";
import { SelectBox } from "@component/UI/Form";
import Dialog from "@component/UI/Dialog";
import _API from "@util/api";
import _Device from "@util/devices";
import { AppContext } from "@component/AppProvider";
import _U from "@util/utilities";

export const ControllerModal = (props) => {
    const { t } = useTranslation();
    const { saveInnerLoading } = useContext(AppContext);

    const [deviceList, setDeviceList] = useState([]);
    const [brightness, setBrightness] = useState(0);
    const [volume, setVolume] = useState(0);
    const [lock, setLock] = useState({
        touchLock: false,
        irLock: false,
        USBLock: false,
        screenLock: false,
    });
    const [data, setData] = useState(null);
    const [deviceInfo, setDeviceInfo] = useState({});
    const [lockModal, setLockModal] = useState({ open: false });

    const onMinus = (key) => {
        let val = 0;
        if (key === "brightness") {
            val = !brightness ? 0 : parseInt(brightness);
            setBrightness(val - 1 > 0 ? val - 1 : 0);
        } else {
            val = !volume ? 0 : parseInt(volume);
            setVolume(val - 1 > 0 ? val - 1 : 0);
        }
    };

    const onPlus = (key, max) => {
        if (!max) {
            max = 100;
        }
        let val = 0;
        if (key === "brightness") {
            val = !brightness ? 0 : parseInt(brightness);
            setBrightness(val + 1 > max ? max : val + 1);
        } else {
            val = !volume ? 0 : parseInt(volume);
            setVolume(val + 1 > max ? max : val + 1);
        }
    };

    const onBrightnessChange = (e) => {
        const max = e.target.maxLength;
        let val = _U.getOnlyNumber(e.target.value);
        val = !val ? 0 : val;
        setBrightness(val >= max ? max : val);
    };

    const onVolumeChange = (e) => {
        const max = e.target.maxLength;
        let val = _U.getOnlyNumber(e.target.value);
        val = !val ? 0 : parseInt(val);
        setVolume(val >= max ? max : val);
    };

    const getBackground = (key) => {
        let value = null;
        if (key === "brightness") {
            value = brightness;
        } else {
            value = volume;
        }

        return {
            background: "linear-gradient(to right, #a50034 0%, #a50034 " + (value || 0) + "%, #f1f1f1 " + (value || 0) + "%, #f1f1f1 100%)",
        };
    };

    const onAction = (code, value) => {
        setDeviceControl(code, value);
    };

    const onScreenLock = async (passwd, lock) => {
        setDeviceControl("screenLock", lock === "True", passwd);
        setLockModal({ open: false });
    };

    const onLoadData = (deviceId) => {
        saveInnerLoading(true);
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: deviceList[0]?.device_id || deviceId,
                features: ["input", "brightness", "volume", "powerOn", "reboot", "screenOnOff", "screenOff", "touchLock", "irLock", "USBLock", "screenLock"],
            },
        })
            .then((res) => {
                const result = res?.data?.result;
                setData(result);
                if (deviceList?.length === 1 || props.deviceList?.length === 1) {
                    setBrightness(result?.filter((f) => f["brightness"])[0]?.["brightness"]?.data?.value || 0);
                    setVolume(result?.filter((f) => f["volume"])[0]?.["volume"]?.data?.value || 0);
                    setLock({
                        touchLock: result?.filter((f) => f["touchLock"])[0]?.["touchLock"]?.data?.value || false,
                        irLock: result?.filter((f) => f["irLock"])[0]?.["irLock"]?.data?.value || false,
                        USBLock: result?.filter((f) => f["USBLock"])[0]?.["USBLock"]?.data?.value || false,
                        screenLock: result?.filter((f) => f["screenLock"])[0]?.["screenLock"]?.data?.value || false,
                    });
                }
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read `, e);
                saveInnerLoading(false);
            });
    };

    const setDeviceControl = (key, value, passwd) => {
        let message = (
            <Fragment>
                <div className="description">{t("common.message.control.after")}</div>
                <div className="inform-txt-box">
                    <div className="description">{t("common.message.control.after2")}</div>
                </div>
            </Fragment>
        );
        if (key === "powerOn") {
            message = (
                <Fragment>
                    <div className="description">{t("common.message.control.after")}</div>
                    <div className="info">{t("common.message.control.poweron.after")}</div>
                    <div className="inform-txt-box">
                        <div className="description">{t("common.message.control.after2")}</div>
                    </div>
                </Fragment>
            );
        }
        Dialog({
            title: t("popup.title.inform"),
            body: message,
            button: t("common.ok"),
            popupClassName: { "pop-middle": true, "popup-inform": true },
        }).then(async (id) => {
            if (id) {
                const params = [];
                if (key === "screenLock") {
                    params.push({ [key]: { data: { onOff: value, password: passwd } } });
                } else {
                    params.push({ [key]: { data: { value: value } } });
                }
                let res = await _API.onDeviceControl2([...deviceList?.map((m) => m.device_id)], params, saveInnerLoading, t);
                if (res && res.status === "true") {
                    const newData = JSON.parse(JSON.stringify(data));
                    newData.forEach((f) => {
                        if (f[key] && ["powerOn", "reboot", "screenOnOff", "screenOff", "touchLock", "irLock", "USBLock", "screenLock"].indexOf(key) === -1) {
                            f[key]["data"]["value"] = value;
                        }
                    });
                    setData(newData);
                    if (deviceList?.length === 1 || props.deviceList?.length === 1) {
                        setBrightness(newData?.filter((f) => f["brightness"])[0]?.["brightness"]?.data?.value || 0);
                        setVolume(newData?.filter((f) => f["volume"])[0]?.["volume"]?.data?.value || 0);
                        setLock({
                            touchLock: newData?.filter((f) => f["touchLock"])[0]?.["touchLock"]?.data?.value || false,
                            irLock: newData?.filter((f) => f["irLock"])[0]?.["irLock"]?.data?.value || false,
                            USBLock: newData?.filter((f) => f["USBLock"])[0]?.["USBLock"]?.data?.value || false,
                            screenLock: newData?.filter((f) => f["screenLock"])[0]?.["screenLock"]?.data?.value || false,
                        });
                    }
                    if (["touchLock", "irLock", "USBLock", "screenLock"].indexOf(key) > -1) {
                        setLock({ ...lock, [key]: value });
                    }
                    if (key === "input") {
                        setSelectBlank(false);
                    }
                } else {
                    if (deviceList?.length === 1 || props.deviceList?.length === 1) {
                        setBrightness(data?.filter((f) => f["brightness"])[0]?.["brightness"]?.data?.value || 0);
                        setVolume(data?.filter((f) => f["volume"])[0]?.["volume"]?.data?.value || 0);
                        setLock({
                            touchLock: data?.filter((f) => f["touchLock"])[0]?.["touchLock"]?.data?.value || false,
                            irLock: data?.filter((f) => f["irLock"])[0]?.["irLock"]?.data?.value || false,
                            USBLock: data?.filter((f) => f["USBLock"])[0]?.["USBLock"]?.data?.value || false,
                            screenLock: data?.filter((f) => f["screenLock"])[0]?.["screenLock"]?.data?.value || false,
                        });
                    }
                }
            }
        });
    };
    const [selctBlank, setSelectBlank] = useState(true);
    useEffect(() => {
        if (props.deviceList && props.deviceList.length > 0) {
            if (props.deviceList.length === 1) {
                setSelectBlank(false);
                setDeviceInfo(props.deviceList[0]);
            }
            onLoadData(props.deviceList[0].device_id);
            setDeviceList(props.deviceList);
        }
    }, [props.deviceList]);

    return (
        <Fragment>
            <Modal title={t("common.button.quick.controller")} id="popup-quick-controller" className="popup-quick-controller" onClose={props.onClose} noFooter close>
                <div className="select-count">
                    {t("dv.table.selected")} <span className="count">{deviceList?.length || 0}</span>
                </div>
                <div className="field field-type-input">
                    <div className="field-header">
                        <label id="inputDeviceLabel" className="field-title">
                            {t("player.label.input")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="field-box">
                            <SelectBox
                                id="inputDevice"
                                items={_Device.getDropDownItems(t, null, "input", data, null, deviceInfo)}
                                value={selctBlank ? "" : _Device.getHexRangeValue(t, "input", data, null, null, null, deviceInfo)}
                                ariaLabelId="inputDeviceLabel"
                                selectLabel={selctBlank ? t("table.head.choicerow") : _Device.getHexRangeValue(t, "input", data, null, null, null, deviceInfo)}
                                onChange={(item) => onAction("input", item?.value)}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("input", data)}
                            />
                        </div>
                    </div>
                </div>

                <div className="field field-slide-bar field-set-value">
                    <div className="field-header">
                        <label id="field-brightness" htmlFor="field-input-brightness" className="field-title">
                            {t("common.label.brightness.100")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="slide-bar-container">
                            <button type="button" className="btn btn-minus" onClick={() => onMinus("brightness")} disabled={deviceList?.length === 1 && !_Device.getSupported("brightness", data)}>
                                {t("common.button.brightness.decrease")}
                            </button>
                            <input
                                type="range"
                                name="field-slide-brightness"
                                value={brightness || 0}
                                min="0"
                                max="100"
                                minLength="0"
                                maxLength="100"
                                aria-labelledby="field-brightness"
                                onChange={(e) => onBrightnessChange(e, "brightness")}
                                style={getBackground("brightness")}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("brightness", data)}
                            />
                            <button type="button" className="btn btn-plus" onClick={() => onPlus("brightness")} disabled={deviceList?.length === 1 && !_Device.getSupported("brightness", data)}>
                                {t("common.button.brightness.increase")}
                            </button>
                        </div>
                        <div className="field-value">
                            <input
                                type="text"
                                name="field-input-brightness"
                                id="field-input-brightness"
                                value={brightness}
                                minLength="0"
                                maxLength="100"
                                onChange={(e) => onBrightnessChange(e, "brightness")}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("brightness", data)}
                            />
                            <button
                                type="button"
                                className="btn btn-tertiary"
                                onClick={() => onAction("brightness", brightness)}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("brightness", data)}
                            >
                                {t("player.button.apply")}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="field field-slide-bar field-set-value">
                    <div className="field-header">
                        <label id="field-volume" htmlFor="field-input-volume" className="field-title">
                            {t("common.label.volume.100")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="slide-bar-container">
                            <button type="button" className="btn btn-minus" onClick={() => onMinus("volume")} disabled={deviceList?.length === 1 && !_Device.getSupported("volume", data)}>
                                {t("common.button.volume.decrease")}
                            </button>
                            <input
                                type="range"
                                name="field-slide-volume"
                                value={volume || 0}
                                min="0"
                                max="100"
                                minLength="0"
                                maxLength="100"
                                aria-labelledby="field-volume"
                                onChange={(e) => onVolumeChange(e, "volume")}
                                style={getBackground("volume")}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("volume", data)}
                            />
                            <button type="button" className="btn btn-plus" onClick={() => onPlus("volume")} disabled={deviceList?.length === 1 && !_Device.getSupported("volume", data)}>
                                {t("common.button.volume.increase")}
                            </button>
                        </div>
                        <div className="field-value">
                            <input
                                type="text"
                                name="field-input-volume"
                                id="field-input-volume"
                                value={volume}
                                minLength="0"
                                maxLength="100"
                                onChange={(e) => onVolumeChange(e, "volume")}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("volume", data)}
                            />
                            <button type="button" className="btn btn-tertiary" onClick={() => onAction("volume", volume)} disabled={deviceList?.length === 1 && !_Device.getSupported("volume", data)}>
                                {t("player.button.apply")}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="field field-type-power">
                    <div className="field-header">
                        <label className="field-title">{t("form.label.power")}</label>
                    </div>
                    <div className="field-form">
                        <div className="field-box">
                            <button
                                type="button"
                                className="btn btn-power btn-power-on"
                                onClick={() => onAction("powerOn", "01")}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("powerOn", data)}
                            >
                                {t("device.label.poweron")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-power btn-reboot"
                                onClick={() => onAction("reboot", "01")}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("reboot", data)}
                            >
                                {t("player.label.reboot")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-power btn-screen-on"
                                onClick={() => onAction("screenOnOff", true)}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("screenOnOff", data)}
                            >
                                {t("common.screenon")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-power btn-screen-off"
                                onClick={() => onAction("screenOff", true)}
                                disabled={deviceList?.length === 1 && !_Device.getSupported("screenOff", data)}
                            >
                                {t("player.label.energySaving.screenOff")}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="field field-type-lock">
                    <div className="field-header">
                        <label className="field-title">{t("dv.support.lock")}</label>
                    </div>
                    <div className="field-form">
                        <div className={cx({ "lock-toggle-box": true, locked: lock?.touchLock })}>
                            <div className="lock-toggle-tit">
                                <strong>{t("common.label.touch")}</strong>
                            </div>
                            <div className="field-box">
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    onClick={() => onAction("touchLock", true)}
                                    disabled={(lock?.touchLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("touchLock", data))}
                                >
                                    {t("dv.support.lock")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    onClick={() => onAction("touchLock", false)}
                                    disabled={(!lock?.touchLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("touchLock", data))}
                                >
                                    {t("dv.support.unlock")}
                                </button>
                            </div>
                        </div>

                        <div className={cx({ "lock-toggle-box": true, locked: lock?.irLock })}>
                            <div className="lock-toggle-tit">
                                <strong>{t("common.label.ir")}</strong>
                            </div>
                            <div className="field-box">
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    onClick={() => onAction("irLock", true)}
                                    disabled={(lock?.irLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("irLock", data))}
                                >
                                    {t("dv.support.lock")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    onClick={() => onAction("irLock", false)}
                                    disabled={(!lock?.irLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("irLock", data))}
                                >
                                    {t("dv.support.unlock")}
                                </button>
                            </div>
                        </div>

                        <div className={cx({ "lock-toggle-box": true, locked: lock?.USBLock })}>
                            <div className="lock-toggle-tit">
                                <strong>{t("common.label.usb")}</strong>
                            </div>
                            <div className="field-box">
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    onClick={() => onAction("USBLock", true)}
                                    disabled={(lock?.USBLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("USBLock", data))}
                                >
                                    {t("dv.support.lock")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    onClick={() => onAction("USBLock", false)}
                                    disabled={(!lock?.USBLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("USBLock", data))}
                                >
                                    {t("dv.support.unlock")}
                                </button>
                            </div>
                        </div>

                        <div className={cx({ "lock-toggle-box": true, locked: lock?.screenLock })}>
                            <div className="lock-toggle-tit">
                                <strong>{t("form.label.screen_on_off")}</strong>
                            </div>
                            <div className="field-box">
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    // onClick={() => onAction("screenLock", true)}
                                    onClick={() => setLockModal({ open: true, onOff: "True" })}
                                    disabled={(lock?.screenLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("screenLock", data))}
                                >
                                    {t("dv.support.lock")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    // onClick={() => onAction("screenLock", false)}
                                    onClick={() => onScreenLock("", "False")}
                                    disabled={(!lock?.screenLock && deviceList?.length === 1) || (deviceList?.length === 1 && !_Device.getSupported("screenLock", data))}
                                >
                                    {t("dv.support.unlock")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {lockModal?.open && <LockModal onOff={lockModal?.onOff || "True"} onScreenLock={onScreenLock} onClose={() => setLockModal({ open: false })} />}
        </Fragment>
    );
};

export const AssignModal = (props) => {
    const { t } = useTranslation();
    const [licenseList, setLicenseList] = useState([
        { value: 1, label: "license 1" },
        { value: 2, label: "license 2" },
    ]);

    const [isExsitLicense, setIsExsitsLicense] = useState(true); // 선택 디바이스중 라이선스가 할당된게 포함되어있을경우 true
    const [license, setLicense] = useState(null);
    const [licenseExpand, setLicenseExpand] = useState(false);
    const [listExpand, setListExpand] = useState(false);

    const onClickDone = () => {
        props.onClose();
    };

    useEffect(() => {
        setTimeout(() => {
            resizeTable();
        }, 100);
    }, []);

    useEffect(() => {
        if (isExsitLicense) {
            Dialog({
                title: "Select Device",
                html: "Among the selected devices, there are already devices to which licenses have been assigned.<br />Do you want to select only devices to which licenses have not been assigned?",
                button: ["Cancel", "Ok"],
            }).then((id) => {
                if (id === 0) {
                    props.onClose();
                }
            });
        }
    }, [isExsitLicense]);

    return (
        <Modal title="Assign License" id="popup-assign-license" className="popup-assign-license" onClose={props.onClose} buttonOkTitle="Assign" onClick={onClickDone}>
            <p className="description">Select device model and group to set target.</p>

            <div className="data-list-container">
                <h2>License</h2>
                <div className="data-list-bottom">
                    <div className="table">
                        {/* <!-- 
                    .no-scroll : 스크롤없는 경우
                  --> */}
                        <div className="table-wrapper License-table no-scroll">
                            <div className="table-box">
                                <table id="License-table">
                                    <caption>License</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">Purchase ID</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Period (Days)</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Available License</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>
                                                <div className="field field-type-dropdown">
                                                    <div className="field-form">
                                                        <div className="field-box">
                                                            <SelectBox
                                                                id={"licence"}
                                                                items={licenseList}
                                                                value={license || ""}
                                                                ariaLabelId={""}
                                                                selectLabel={licenseList?.filter((f) => f.value == license)[0]?.label || ""}
                                                                onChange={(item) => setLicense(item?.value)}
                                                                title={t("subtext.title.select.option")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">Purchase ID</div>
                                </div>
                            </div>
                            <ul className="table-body">
                                <li className={cx({ "table-row": true, expand: licenseExpand })}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell alias">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={() => setLicenseExpand(!licenseExpand)}>
                                                    Data
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box expan">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">Purchase ID</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Period (Days)</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Available License</span>
                                                <span className="field-content">
                                                    <div className="field field-type-dropdown">
                                                        <div className="field-form">
                                                            <div className="field-box">
                                                                <SelectBox
                                                                    id={"convert-licence"}
                                                                    items={licenseList}
                                                                    value={license || ""}
                                                                    ariaLabelId={""}
                                                                    selectLabel={licenseList?.filter((f) => f.value == license)[0]?.label || ""}
                                                                    onChange={(item) => setLicense(item?.value)}
                                                                    title={t("subtext.title.select.option")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        <div className="total-count">
                            Total <span className="count">N</span>
                        </div>
                    </div>
                </div>

                <h2>Selected Device</h2>
                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper SelDevice-table no-scroll">
                            <div className="table-box">
                                <table id="SelDevice-table">
                                    <caption>Selected Device</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">Device Type</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Model Name</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Device Name</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Serial Number</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                        </tr>
                                        <tr>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                        </tr>
                                        <tr>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">Type</div>
                                </div>
                            </div>
                            <ul className="table-body">
                                <li className={cx({ "table-row": true, expand: listExpand })}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell alias">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={() => setListExpand(!listExpand)}>
                                                    date
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">Device Type</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Model Name</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Device Name</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Serial Number</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const UnAssignModal = (props) => {
    const [expand, setExpand] = useState(false);

    const onClicnDone = () => {
        props.onClose();
    };

    return (
        <Modal title="Un-assign License" id="popup-assign-license" className="popup-assign-license" onClose={props.onClose} buttonOkTitle="Un-Assign" onClick={onClicnDone}>
            <p className="description">Do you want to cancel the license assignment for the selected device?</p>

            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        <div className="total-count">
                            Total <span className="count">N</span>
                        </div>
                    </div>
                </div>

                <h2>Target (Model &amp; Group)</h2>
                <div className="data-list-bottom">
                    <div className="table">
                        {/* <!-- 
        .no-scroll : 스크롤없는 경우
      --> */}
                        <div className="table-wrapper Target-table no-scroll">
                            <div className="table-box">
                                <table id="Target-table">
                                    <caption>Target (Model & Group)</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="text-block">Device Type</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Model Name</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Device Name</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">Serial Number</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                        </tr>
                                        <tr>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                        </tr>
                                        <tr>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                            <td>Data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">Type</div>
                                </div>
                            </div>
                            <ul className="table-body">
                                <li className={cx({ "table-row": true, expand: expand })}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell alias">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={() => setExpand(!expand)}>
                                                    date
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">Device Type</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Model Name</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Device Name</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                            <li>
                                                <span className="field-label">Serial Number</span>
                                                <span className="field-content">Data</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const LockModal = (props) => {
    const { t } = useTranslation();

    const onClickDone = () => {
        props.onScreenLock && props.onScreenLock(passwd, props.onOff);
    };

    const [usePrev, setUsePrev] = useState(false);
    const [passwd, setPasswd] = useState("");

    const onUsePrev = () => {
        setUsePrev(!usePrev);
        !usePrev && setPasswd("");
    };

    const changePasswd = (value) => {
        let val = _U.getOnlyNumber(value);
        if (val?.length > 4) {
            val = val.substring(0, 4);
        }
        setPasswd(val);
    };

    return (
        <Modal
            title={props?.onOff === "True" ? t("schedule.commend.screen_lock_unlock") : t("form.label.screenunlock")}
            id="popup-select-file"
            className="popup-screen-lock pop-middle no-scroll"
            onClose={props.onClose}
            buttonOkDisabled={usePrev !== true && passwd === ""}
            onClick={onClickDone}
        >
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <p>
                {t("subtext.deivce.detail.lock1")}
                <br />
                {t("subtext.deivce.detail.lock2")}
            </p>
            <div className="field popup-create-role required">
                <div className="field-header">
                    <label htmlFor="ip-message-type" className="field-title">
                        {t("common.passwd")}
                    </label>
                </div>
                <div className="field-form">
                    <div className="input-box">
                        <input
                            type="text"
                            id="ip-message-type"
                            name=""
                            placeholder="Password"
                            autoComplete="false"
                            aria-required="true"
                            value={passwd}
                            disabled={usePrev}
                            onChange={(e) => changePasswd(e.target.value)}
                        />
                        <button type="button" className={cx({ "btn-input-remove": true, hide: passwd ? false : true })} onClick={() => setPasswd("")}>
                            <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="field field-type-checkbox">
                <div className="field-form">
                    <div className="field-box">
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox-power-option" id="checkbox-password" checked={usePrev} onChange={() => onUsePrev()} />
                            <label htmlFor="checkbox-password">{t("schedule.code.usepreviouslysetpassword")}</label>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
