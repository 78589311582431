import { TooltipBottom } from "@component/Tooltip";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const RectangleArea = styled.div`
    .graph {
        width: 100%;
        height: 4rem;
        margin-bottom: 1.6rem;
        display: flex;
        > .item {
            &:not(:last-child) {
                margin-right: 0.1rem;
            }
        }
    }
    .detail-items {
        > .item {
            width: 100%;
            height: 4rem;
            display: flex;
            align-items: center;
            padding: 0 1.6rem;
            justify-content: space-between;
            color: #333;
            .left {
                display: flex;
                align-items: center;
                height: 100%;
                .bg-color {
                    margin: 0 1.2rem;
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    background-color: #dadde2;
                    border-radius: 4px;
                }
                .idx {
                    margin: 0 1.2rem;
                    width: 2rem;
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 140%;
                }
                .device-name {
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 140%;
                    margin: 0 1.2rem;
                }
            }
            .right {
                font-size: 18px;
                font-weight: 700;
                line-height: 130%;
                margin: 0 1.2rem;
            }
        }
    }

    @media (max-width: 720px) {
        height: unset !important;
        .detail-items {
            > .item {
                height: 8rem;
                flex-direction: column;
                align-items: flex-start;
                &:not(:last-child) {
                    margin-bottom: 1.6rem;
                }
                .right {
                    padding: 0.8rem 0;
                    margin-left: 10rem;
                }
            }
        }
    }
`;

const COLOR_LIST = ["#A5A6E6", "#9EDFE1", "#F2C193", "#E8A49F", "#DADDE2"];

const RectangleChart = ({ dataList }) => {
    const [sortedDataList, setSortedDataList] = useState([]);
    useEffect(() => {
        const sortedList = [...dataList].sort((a, b) => b.energyUse - a.energyUse);
        setSortedDataList(sortedList);
    }, [dataList]);

    const sumEnergyUse = sortedDataList ? sortedDataList.reduce((acc, curr) => acc + curr.energyUse, 0) : 0;
    const renderedItems =
        sortedDataList &&
        sortedDataList.map((x, i) => {
            const energyPercentage = (x.energyUse / sumEnergyUse) * 100;

            return (
                <div
                    className="item"
                    key={i}
                    style={{
                        position: "relative",
                        backgroundColor: COLOR_LIST[i],
                        flexBasis: `${energyPercentage}%`,
                    }}
                >
                    <TooltipBottom txt={`${x.name}: ${x.energyUse} kwh`} />
                </div>
            );
        });

    return (
        <RectangleArea>
            <div className="graph">{renderedItems}</div>
            <div className="detail-items">
                {sortedDataList &&
                    sortedDataList?.map((x, i) => (
                        <div className="item" key={i}>
                            <div className="left">
                                <span className="bg-color" style={{ backgroundColor: COLOR_LIST[i] }}></span>
                                <span className="idx">{i}</span>
                                <span className="device-name">{x.name}</span>
                            </div>
                            <div className="right">{x.energyUse} kwh</div>
                        </div>
                    ))}
            </div>
        </RectangleArea>
    );
};

export default RectangleChart;
