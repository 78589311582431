import React, { Fragment, useEffect } from "react";

import { Features, Tree } from "@component/UI/SideBar";

export default () => {
    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>
                        <button type="button" className="btn btn-open-child">
                            <span className="ir">open sub menu</span>
                        </button>
                        Device
                    </h1>
                </div>

                {/* <div className="last-update">
                    <span className="field-label">Last updates: </span>
                    <span className="field-content">2021. 03. 18 09:44:15</span>
                    <button className="btn-update" aria-label="update data" aria-describedby="updated-time"></button>
                    <p id="updated-time" className="hide">
                        데이터를 마지막으로 업데이트한 시각은 2021년 3월 18일 9시 44분 15초 입니다.
                    </p>
                </div> */}
            </div>
            <div className="content-middle wide-padding">
                <div className="site-group-list-container">
                    <div className="child-menu-container">
                        <Features
                            title="Specialized Features"
                            items={[
                                [
                                    { id: "SPF-MONITOR-SETTING", label: "Monitoring Settings", icon: "btn-icon btn-monitoring" },
                                    { id: "SPF-AUTO-UPDATE", label: "Auto Update", icon: "btn-icon btn-update" },
                                    { id: "SPF-AUTO-CLONING-DATA", label: "Auto Cloning Data", icon: "btn-icon btn-cloning" },
                                ],
                                [
                                    { id: "SPF-UNICONTROLLER", label: "UniController", icon: "btn-icon btn-uniController" },
                                    { id: "SPF-ENERGY-SAVING-MODE", label: "Energy Saving Mode", icon: "btn-icon btn-energy" },
                                    { id: "SPF-REBOOT-SCHEDULE", label: "Reboot Schedule", icon: "btn-icon btn-schedule" },
                                ],
                            ]}
                            onClick={(id) => console.log(id)}
                        />

                        <Tree
                            title="All Device"
                            subTitle="Group"
                            onSettingClick={() => console.log("setting click")}
                            onClick={(id) => console.log(id)}
                            items={[
                                {
                                    id: "menu-1",
                                    label: "1 depth group name",
                                    enabled: true,
                                    expanded: false,
                                    child: [
                                        {
                                            id: "menu-1-1",
                                            label: "2 depth group name",
                                            enabled: true,
                                            expanded: false,
                                            child: [
                                                { id: "menu-1-1-1", label: "3 depth group name depth group name", enabled: true, expanded: false, child: [] },
                                                { id: "menu-1-1-2", label: "3 depth group name depth group name 2", enabled: true, expanded: false, child: [] },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    id: "menu-2",
                                    label: "1 depth group name",
                                    enabled: true,
                                    expanded: false,
                                    child: [
                                        {
                                            id: "menu-2-1",
                                            label: "2 depth group name",
                                            enabled: true,
                                            expanded: false,
                                            child: [{ id: "menu-2-1-1", label: "3 depth group name depth group name", enabled: true, expanded: false, child: [] }],
                                        },
                                    ],
                                },
                                {
                                    id: "menu-3",
                                    label: "1 depth group name",
                                    enabled: true,
                                    expanded: false,
                                    child: [
                                        {
                                            id: "menu-3-1",
                                            label: "2 depth group name",
                                            enabled: true,
                                            expanded: false,
                                            child: [],
                                        },
                                    ],
                                },
                                { id: "menu-4", label: "1 depth group name", enabled: true, expanded: false, child: [] },
                                { id: "menu-5", label: "Unassigned", enabled: false, expanded: false, child: [] },
                            ]}
                        />
                    </div>
                </div>

                <div className="content-middle-box">
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                    test
                    <br />
                </div>
            </div>
        </Fragment>
    );
};
