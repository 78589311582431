import React, { Fragment } from "react";

import Table from "@component/UI/Table";

export default () => {
    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>Dashboard</h1>
                </div>

                {/* <div className="last-update">
                    <span className="field-label">Last Update Time </span>
                    <span className="field-content">2021. 03. 18 09:44:15</span>
                    <button className="btn-update" aria-label="update data" aria-describedby="updated-time"></button>
                    <p id="updated-time" className="hide">
                        데이터를 마지막으로 업데이트한 시각은 2021년 3월 18일 9시 44분 15초 입니다.
                    </p>
                </div> */}
            </div>

            <div className="content-middle">
                <div className="content-middle-box">
                    <Table
                        caption="Device List"
                        summary={{
                            enable: true,
                            button: (
                                <button type="button" className="btn btn-select-mode">
                                    Select Mode
                                </button>
                            ),
                            enableDownload: true,
                            onExcelDownload: () => {
                                console.log("excel");
                            },
                            total: 100,
                            current: 0,
                        }}
                        header={{
                            type: "horizontal",
                            enableCheckbox: true,
                            checkedAll: false,
                            onClick: (id, sort, item) => {
                                console.log(id, sort, item);
                            },
                            onCheckChange: (id) => {
                                console.log(id);
                            },
                            list: [
                                {
                                    id: "NO",
                                    title: "No",
                                    enableSort: true,
                                    sort: "ascending",
                                },
                                {
                                    id: "DEVICE-TYPE",
                                    title: "Device Type",
                                    enableSort: true,
                                    sort: "descending",
                                    sortItem: "Android",
                                    sortItems: ["webOS", "Android", "Windows", "Display"],
                                    isMobile: true,
                                },
                                {
                                    id: "CONNECTION-STATUS",
                                    title: "Connection Status",
                                    enableSort: true,
                                    sort: "descending",
                                    sortItem: "Connected",
                                    sortItems: ["Connected", "Disconnected"],
                                },
                                { id: "MODEL-NAME", title: "Model Name", enableSort: true, sort: "descending" },
                                { id: "DEVICE-NAME", title: "Device Name", enableSort: true, sort: "descending", isMobile: true },
                                { id: "SERIAL-NUMBER", title: "Serial number", enableSort: true, sort: "descending" },
                                { id: "ACCOUNT-NAME", title: "Account Name", enableSort: true, sort: "descending" },
                                { id: "SITE", title: "Site", enableSort: true, sort: "descending" },
                                { id: "LOCATION", title: "Location", enableSort: true, sort: "descending" },
                                { id: "SHOP", title: "Shop", enableSort: true, sort: "descending" },
                                { id: "IP-ADDRESS", title: "IP Address", enableSort: true, sort: "descending" },
                                { id: "GROUP-TAG", title: "Group Tag", enableSort: true, sort: "descending" },
                                { ID: "DETAILS", title: "Details", enableSort: true, sort: "descending" },
                            ],
                        }}
                        list={[
                            {
                                id: "OBJ1",
                                screenLabel: "label text",
                                checked: true,
                                list: [
                                    { text: "018" },
                                    { children: <img src="/assets/images/device_type/device_type_icon_media_player_n.svg" alt="경고" /> },
                                    { text: "DATA" },
                                    { text: "DATA" },
                                    { text: "008DASFAD7999DF" },
                                    { text: "DATA" },
                                    { text: "PT_SHOWROOM" },
                                    { text: "IBIC" },
                                    { text: "LED Signage" },
                                    { text: "unlabeled" },
                                    { text: "111.112.133.12" },
                                    {
                                        children: (
                                            <a href="#!" className="btn tag">
                                                Group name
                                            </a>
                                        ),
                                        className: "tag",
                                    },
                                    {
                                        children: (
                                            <a href="#!" role="button" className="btn btn-detail">
                                                상세페이지로 이동합니다.
                                            </a>
                                        ),
                                        className: "align-center",
                                    },
                                ],
                            },
                            {
                                id: "OBJ2",
                                screenLabel: "label text",
                                checked: true,
                                list: [
                                    { text: "017" },
                                    { children: <img src="/assets/images/device_type/device_type_icon_display_n.svg" alt="정상" /> },
                                    { text: "DATA" },
                                    { text: "DATA" },
                                    { text: "008DASFAD7999DF" },
                                    { text: "DATA" },
                                    { text: "PT_SHOWROOM" },
                                    { text: "IBIC" },
                                    { text: "LED Signage" },
                                    { text: "unlabeled" },
                                    { text: "111.112.133.12" },
                                    {
                                        children: (
                                            <a href="#!" className="btn tag">
                                                Group name
                                            </a>
                                        ),
                                        className: "tag",
                                    },
                                    {
                                        children: (
                                            <a href="#!" role="button" className="btn btn-detail">
                                                상세페이지로 이동합니다.
                                            </a>
                                        ),
                                        className: "align-center",
                                    },
                                ],
                            },
                            {
                                id: "OBJ3",
                                screenLabel: "label text",
                                checked: true,
                                list: [
                                    { text: "017" },
                                    { children: <img src="/assets/images/device_type/device_type_icon_media_player_e.svg" alt="정상" /> },
                                    { text: "DATA" },
                                    { text: "DATA" },
                                    { text: "008DASFAD7999DF" },
                                    { text: "DATA" },
                                    { text: "PT_SHOWROOM" },
                                    { text: "IBIC" },
                                    { text: "LED Signage" },
                                    { text: "unlabeled" },
                                    { text: "111.112.133.12" },
                                    {
                                        children: (
                                            <a href="#!" className="btn tag">
                                                Group name
                                            </a>
                                        ),
                                        className: "tag",
                                    },
                                    {
                                        children: (
                                            <a href="#!" role="button" className="btn btn-detail">
                                                상세페이지로 이동합니다.
                                            </a>
                                        ),
                                        className: "align-center",
                                    },
                                ],
                            },
                        ]}
                        paging={{
                            start: 0,
                            totalSize: 100,
                            pageSize: 1,
                            startPageNo: 1,
                            endPageNo: 10,
                            prevBlockNo: 1,
                            nextBlockNo: 1,
                            finalPageNo: 100,
                            prevPageNo: 1,
                            nextPageNo: 1,
                            virtualRecordNo: 1,
                            pageNo: 1,
                        }}
                        onPageChange={(page) => console.log(`${page}페이지로 이동`)}
                        numPerPage={20}
                        numPerPages={[10, 20, 30]}
                        onNumPerPageChange={(e) => console.log(`${e.target.value}씩 보기`)}
                    />
                </div>
            </div>
        </Fragment>
    );
};
