import React, { useContext, useEffect, useState } from "react";
import _API from "@util/api";
import cookie from "@util/cookie";
import Dialog from "@component/UI/Dialog";
import { useTranslation } from "react-i18next";
import { AppContext } from "@component/AppProvider";

const useLicenseNotice = () => {
    const { t } = useTranslation();
    const { product } = useContext(AppContext);
    const [isShow, setIsShow] = useState(true);

    const fetchData = async () => {
        try {
            const licensePromise = _API.get({ path: "/dashboard/device/license", serviceType: product?.service_type, productCode: product?.product_code }).then((res) => {
                if (res?.data?.result) {
                    const { is_subscribe, d_day, expire_date } = res?.data?.result;
                    if (is_subscribe) return;
                    if (d_day && d_day <= 30) {
                        const info = cookie.get("license_remind");
                        if (info) return;
                        Dialog({
                            title: t("common.license.notice.title", { 0: d_day }),
                            text: t("common.license.notice.text", { 0: expire_date }),
                            button: [t("common.ok"), t("button.label.remind.later")],
                        }).then((isConfirm) => {
                            if (!isConfirm) return;
                            const expires = new Date();
                            expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000);
                            document.cookie = `license_remind=true;expires=${expires.toUTCString()};path=/`;
                        });
                    } else if (!d_day) {
                        const notiInfo = cookie.get("license_issue_notice");
                        if (notiInfo) return;
                        Dialog({
                            title: t("license.issue.notice"),
                            text: (
                                <>
                                    {t("license.issue.notice.desc01")}
                                    <br />
                                    {t("license.issue.notice.desc02")}
                                </>
                            ),
                            button: [t("common.ok"), t("button.label.remind.later")],
                        }).then((isConfirm) => {
                            if (!isConfirm) return;
                            const expires = new Date();
                            expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000);
                            document.cookie = `license_issue_notice=true;expires=${expires.toUTCString()};path=/`;
                        });
                    }
                }
            });

            await Promise.all([licensePromise]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return isShow;
};

export default useLicenseNotice;
