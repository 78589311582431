import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";

import useCss from "@hook/useCss";
import _U from "@util/utilities";
import _API from "@util/api";
import Dialog from "@component/UI/Dialog";

const EditThreshold = (props) => {
    useCss("/assets/css/popup_pannels.css");
    useCss("/assets/css/popup_device_list.css");

    const { t } = useTranslation();
    const [dataList, setDataList] = useState([]);

    const onAdd = () => {
        if (dataList?.length > 0) {
            const params = dataList?.map((m) => ({ ...m, error: !m.error ? "0" : m.error + "", activation: m.activation ? true : false, email_notice: m.email_notice ? true : false }));
            _API.put({ path: `/issue/monitoring/threshold`, data: { threshold: params } })
                .then((res) => {
                    props.onClose && props.onClose();
                })
                .catch((e) => {
                    console.log(`api error /monitoring/threshold`, e);
                });
        }
    };

    const onCancel = () => {
        if (dataList?.length > 0 && JSON.stringify(props.data?.threshold) !== JSON.stringify(dataList)) {
            Dialog({
                title: t("button.cancel"),
                text: t("subtext.message.leavepage"),
                button: [t("common.button.stay"), t("common.button.leave")],
            }).then((id) => {
                if (id) {
                    props.onClose && props.onClose();
                } else {
                    // onAdd();
                }
            });
        } else {
            props.onClose && props.onClose();
        }
    };

    const onChangeData = (code, e, index) => {
        const newList = JSON.parse(JSON.stringify(dataList));
        for (let i = 0; i < newList?.length; i++) {
            const item = newList[i];
            if (i == index) {
                if (["activation", "email_notice"].indexOf(code) > -1) {
                    item[code] = e ? e.target.checked : false;
                    if ("activation" === code && !item[code]) {
                        item["email_notice"] = false;
                    }
                } else {
                    item[code] = e ? e.target.value : "";
                }
            }
        }
        console.log("newList", newList);
        setDataList(newList);
    };

    const convertFormat = (list) => {
        const newList = [];
        for (let item of list) {
            item.activation = item.activation ? true : false;
            item.email_notice = item.email_notice ? true : false;
            newList.push(item);
        }
        return newList;
    };

    useEffect(() => {
        setDataList(convertFormat(props.data?.threshold));
        setTimeout(() => {
            resizeTable();
        }, 100);
    }, [props.data]);

    useEffect(() => {
        // 모달 팝업 시 background scroll방지
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    return (
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
            <div id={props.id} className="popup-wrapper show">
                <div className="dim">&nbsp;</div>
                <div className={`popup ${props.className}`} role="dialog" aria-labelledby="popup-label" aria-modal="true">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h1 id="popup-label">{props.title}</h1>
                        </div>

                        <div className="popup-content">
                            <div className="field field-type-table">
                                <div className="field-form">
                                    <div className="field-box">
                                        <div className="table">
                                            <div className="table-wrapper table-edit-threshold no-scroll">
                                                <div className="table-box">
                                                    <table>
                                                        <caption>{t("dv.edit.threshold")}</caption>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("common.label.category")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("report.issuetype")}</div>
                                                                </th>
                                                                <th scope="col" className="width-12-percent">
                                                                    <div className="text-block">{t("editor.label.code")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("editor.label.item")}</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div className="text-block">{t("common.error")}</div>
                                                                </th>
                                                                <th scope="col" className="width-12-percent">
                                                                    <div className="text-block">{t("schedule.active.state.active")}</div>
                                                                </th>
                                                                <th scope="col" className="width-12-percent">
                                                                    <div className="text-block">{t("dg.email.notice")}</div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dataList?.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item?.category}</td>
                                                                        <td>{item?.issue_type}</td>
                                                                        <td className="width-12-percent">{item?.code}</td>
                                                                        <td>{item?.item}</td>
                                                                        <td>
                                                                            <div className="field field-type-input field-flex">
                                                                                <div className="field-form">
                                                                                    <div className="field-box">
                                                                                        <div className="input-box">
                                                                                            <input
                                                                                                type="number"
                                                                                                id={`ip-editTh-error-${index}`}
                                                                                                placeholder="XXX"
                                                                                                autoComplete="false"
                                                                                                aria-required="true"
                                                                                                aria-invalid="true"
                                                                                                value={item?.error}
                                                                                                onChange={(e) => onChangeData("error", e, index)}
                                                                                            />
                                                                                            <button
                                                                                                type="button"
                                                                                                className={cx({ "btn-input-remove": true, hide: item?.error || item?.error == 0 ? false : true })}
                                                                                                onClick={() => onChangeData("error", "", index)}
                                                                                            >
                                                                                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <span className="unit">{t("common.unit")}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-center width-12-percent">
                                                                            <div className="switch">
                                                                                <label htmlFor={`editTh-act-switch-${index}`} className="switch-controller">
                                                                                    {t("schedule.active.state.active")}
                                                                                </label>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`editTh-act-switch-${index}`}
                                                                                    name={`editTh-act-switch-${index}`}
                                                                                    role="switch"
                                                                                    checked={item?.activation || false}
                                                                                    onChange={(e) => onChangeData("activation", e, index)}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-center width-12-percent">
                                                                            <div className="switch">
                                                                                <label htmlFor={`editTh-noti-switch-${index}`} className="switch-controller">
                                                                                    {t("dg.email.notice")}
                                                                                </label>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`editTh-noti-switch-${index}`}
                                                                                    name={`editTh-noti-switch-${index}`}
                                                                                    role="switch"
                                                                                    checked={item?.email_notice || false}
                                                                                    disabled={!item?.activation}
                                                                                    onChange={(e) => {
                                                                                        item?.activation ? onChangeData("email_notice", e, index) : null;
                                                                                    }}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="convert-table-to-list">
                                                <div className="table-header">
                                                    <div className="row">
                                                        <div className="thead">{t("schedule.active.state.active")}</div>
                                                        <div className="thead">{t("report.issuetype")}</div>
                                                    </div>
                                                </div>
                                                <ul className="table-body">
                                                    {dataList?.map((item, index) => {
                                                        return (
                                                            <li className="table-row" key={index}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell activation center">
                                                                            <div className="switch">
                                                                                <label htmlFor={`check-activation-reflow-${index}`} className="switch-controller">
                                                                                    {t("schedule.active.state.active")}
                                                                                </label>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`check-activation-reflow-${index}`}
                                                                                    name={`check-activation-reflow-${index}`}
                                                                                    role="switch"
                                                                                    aria-checked={item?.activation || false}
                                                                                    checked={item?.activation || false}
                                                                                    onChange={(e) => onChangeData("activation", e, index)}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="table-cell alias">
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                                                {item?.issue_type}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li>
                                                                            <span className="field-label">{t("common.label.category")}</span>
                                                                            <span className="field-content">{item?.category}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("report.issuetype")}</span>
                                                                            <span className="field-content">{item?.issueType}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("editor.label.code")}</span>
                                                                            <span className="field-content">{item?.code}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("editor.label.item")}</span>
                                                                            <span className="field-content">{item?.item}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("common.error")}</span>
                                                                            <span className="field-content">
                                                                                <div className="field field-type-input field-flex">
                                                                                    <div className="field-form">
                                                                                        <div className="field-box">
                                                                                            <div className="input-box">
                                                                                                <input
                                                                                                    type="number"
                                                                                                    id={`ip-editTh-error-reflow-${index}`}
                                                                                                    placeholder="XXX"
                                                                                                    autoComplete="false"
                                                                                                    aria-required="true"
                                                                                                    aria-invalid="true"
                                                                                                    value={item?.error}
                                                                                                    onChange={(e) => onChangeData("error", e, index)}
                                                                                                />
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className={cx({ "btn-input-remove": true, hide: item?.error || item?.error == 0 ? false : true })}
                                                                                                    onClick={() => onChangeData("error", null, index)}
                                                                                                >
                                                                                                    <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                                                                                                </button>
                                                                                            </div>
                                                                                            <span className="unit">{t("common.unit")}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">Activation</span>
                                                                            <span className="field-content">
                                                                                <div className="switch">
                                                                                    <label htmlFor={`editTh-act-switch-reflow-${index}`} className="switch-controller">
                                                                                        {t("schedule.active.state.active")}
                                                                                    </label>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`editTh-act-switch-reflow-${index}`}
                                                                                        name={`editTh-act-switch-reflow-${index}`}
                                                                                        role="switch"
                                                                                        aria-checked={item?.activation || false}
                                                                                        checked={item?.activation || false}
                                                                                        onChange={(e) => onChangeData("activation", e, index)}
                                                                                    />
                                                                                    <span className="slider"></span>
                                                                                </div>
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">E-Mail Notice</span>
                                                                            <span className="field-content">
                                                                                <div className="switch">
                                                                                    <label htmlFor="editTh-noti-switch-1" className="switch-controller">
                                                                                        {t("dg.email.notice")}
                                                                                    </label>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="editTh-noti-switch-1"
                                                                                        name="editTh-noti-switch-1"
                                                                                        role="switch"
                                                                                        checked={item?.email_notice || false}
                                                                                        disabled={!item?.activation}
                                                                                        onChange={(e) => {
                                                                                            item?.activation ? onChangeData("email_notice", e, index) : null;
                                                                                        }}
                                                                                    />
                                                                                    <span className="slider"></span>
                                                                                </div>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-footer">
                            <div className="button-box double-button">
                                <button type="button" id="btn-sm-cancel" className="btn btn-huge btn-tertiary" onClick={onCancel}>
                                    {t("button.cancel")}
                                </button>
                                <button type="button" id="btn-adde-add" className="btn btn-huge btn-secondary" onClick={onAdd}>
                                    {t("distribution.label.completed")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default EditThreshold;
