import Modal from "@component/UI/Modal";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Preview from "../Preview";
import styled from "styled-components";
import SelectFile from "./SelectFile";
import _API from "@util/api";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";
import utils from "@util/utilities";
import { VolumeInput } from "@component/UI/Form";

const StyledPreviewBtn = styled.button`
    font-size: 1.2rem !important;
    height: 2.8rem !important;
    width: 8rem !important;
`;

const MAX_TITLE_LENGTH = 50;

const TemplatePopup = ({ onClose, onClick, mode, templateId, totalCount = 0 }) => {
    const {
        workspace: { property_id },
    } = useContext(AppContext);

    const { t } = useTranslation();

    const [id, setId] = useState(null);
    const [field, setField] = useState({
        title: `Alert_Message_${totalCount + 1}`,
        templateType: "text",
        message: "",
        imageFile: { name: "", file: "" },
        soundFile: { name: "", file: "" },
        volume: 0,
    });
    const [duration, setDuration] = useState(0);

    const [showPreview, setShowPreview] = useState(false);

    const [selectFileMode, setSelectFileMode] = useState("image");
    const [showFileSelect, setShowFileSelect] = useState(false);

    const onChangeFile = (fileData) => {
        setShowFileSelect(false);
        if (!fileData?.length) return;

        const fileKeyParts = fileData[0].Key.split("/");
        const name = fileKeyParts[fileKeyParts.length - 1];
        const file = fileData[0].Key;
        if (selectFileMode === "image") {
            setField({ ...field, imageFile: { name, file } });
        } else {
            setField({ ...field, soundFile: { name, file } });
        }
    };

    const pleaseEnterDialog = (name) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("popup.inform.sigleItem", { n: name }),
            button: t("common.label.confirm"),
        });
    };

    const dialogRequiredField = () => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("popup.inform.multiItem"),
            button: t("common.label.confirm"),
        });
    };

    const clickToApply = () => {
        const { templateType, title, message, imageFile, soundFile, volume } = field;

        // 2개 이상 입력 안한 카운트 세기
        let count = 0;
        if (!title) count++;
        if (!soundFile?.file) count++;
        if (!volume?.toString()?.length) count++;
        switch (templateType) {
            case "text":
                if (!message) count++;
                break;
            case "image":
                if (!imageFile?.file) count++;
                break;
        }
        if (count >= 2) {
            dialogRequiredField();
            return;
        }

        // 유효성 체크
        if (!title) {
            pleaseEnterDialog(t("commom.popup.title"));
            return;
        }
        switch (templateType) {
            case "text":
                if (!message) {
                    pleaseEnterDialog(t("schedule.commend.broadcast_message"));
                    return;
                }
                break;
            case "image":
                if (!imageFile.file) {
                    pleaseEnterDialog(t("alertmsg.template.image"));
                    return;
                }
                break;
        }
        if (!soundFile.file) {
            pleaseEnterDialog(t("alertmsg.popup.sound_file"));
            return;
        }
        if (!volume.toString.length) {
            pleaseEnterDialog(t("player.label.volume"));
            return;
        }

        const type = templateType.charAt(0).toUpperCase() + templateType.slice(1);
        const image_file = imageFile.file;
        const sound_file = soundFile.file;
        const sound_volume = volume;
        if (mode === "add") {
            _API.post({
                path: `/content/alert/template`,
                data: { type, title, message, image_file, sound_file, sound_volume, sound_duration: duration },
            })
                .then((res) => {
                    onClick();
                })
                .catch((e) => {
                    console.log(`api error POST /content/alert/template`, e);
                    let msg = "";
                    if (e?.response?.data?.status?.code === "00-006" && e?.response?.data?.error === "duplicated title") {
                        msg = t("subtext.error.samenameexists");
                    } else if (e?.response?.data?.error?.error) {
                        msg = e.response.data.error.error;
                    } else if (e?.response?.data?.error) {
                        msg = e?.response?.data?.error;
                    }
                    Dialog({
                        title: "LGE",
                        text: msg,
                        button: t("common.label.confirm"),
                    });
                });
        } else {
            _API.put({
                path: `/content/alert/template/${id}`,
                data: {
                    type,
                    title,
                    message,
                    sound_volume,
                    sound_file,
                    image_file,
                    sound_duration: duration,
                },
            })
                .then((res) => {
                    onClick();
                })
                .catch((e) => {
                    console.log(`api error PUT /content/alert/template`, e);
                });
        }
    };

    const onCloseModal = () => {
        Dialog({
            title: t("button.cancel"),
            text: t("subtext.message.leavepage"),
            button: [t("common.button.stay"), t("common.button.leave")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            onClose();
        });
    };

    useEffect(() => {
        if (mode === "edit" && templateId) {
            _API.get({ path: `/content/alert/template/${templateId}` })
                .then((res) => {
                    const { image_file, sound_file, sound_volume, title } = res?.data?.result[0];
                    setId(res?.data?.result[0].id);
                    setField({
                        ...field,
                        title,
                        templateType: "image",
                        imageFile: { name: image_file.split("/")?.pop(), file: image_file },
                        soundFile: { name: sound_file.split("/")?.pop(), file: sound_file },
                        volume: sound_volume,
                    });
                })
                .catch((e) => {
                    console.log(`api error PUT /content/alert/template/${templateId}`, e);
                });
        }
    }, [mode, templateId]);

    useEffect(async () => {
        const fileKey = field?.soundFile?.file;
        if (fileKey) {
            const soundDuration = await utils.getDuration(fileKey);
            setDuration(soundDuration);
        }
    }, [field.soundFile.file]);

    return (
        <Fragment>
            <Modal id="popup-add-template" className="popup-add-template" title={t("alertmsg.template.add")} onClose={onCloseModal} onClick={clickToApply} buttonOkTitle={t("common.add")}>
                <div className="form-guide algin-left">{t("form.is.required")}</div>
                <div className="field field-type-message required">
                    <div className="field-header">
                        <label htmlFor="template-ip-message-type" className="field-title">
                            {t("commom.popup.title")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="input-box">
                            <input
                                type="text"
                                id="template-ip-message-type"
                                placeholder={t("commom.popup.title")}
                                autoComplete="false"
                                aria-required="true"
                                aria-invalid="true"
                                value={field.title}
                                onChange={(e) => setField({ ...field, title: e.target.value })}
                                maxLength={MAX_TITLE_LENGTH}
                            />
                            <button type="button" className={`btn-input-remove ${field.title ? "" : "hide"}`} onClick={() => setField({ ...field, title: "" })}>
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                        </div>
                        <div className="word-count">
                            ({field?.title?.length}/{MAX_TITLE_LENGTH})
                        </div>
                    </div>
                </div>

                <div className="field field-type-template required">
                    <div className="field-header">
                        <label htmlFor="template-ip-template-type" className="field-title">
                            {t("alertmsg.template.type")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="radio" onClick={() => setField({ ...field, templateType: "text" })}>
                            <input type="radio" name="rad-tem-type01" id="rad-tem-type01-1" checked={field.templateType === "text"} onChange={() => setField({ ...field, templateType: "text" })} />
                            <label htmlFor="rad-tem-type01-1">{t("alertmsg.popup.text")}</label>
                        </div>
                        <div className="radio" onClick={() => setField({ ...field, templateType: "image" })}>
                            <input type="radio" name="rad-tem-type01" id="rad-tem-type01-2" checked={field.templateType === "image"} onChange={() => setField({ ...field, templateType: "image" })} />
                            <label htmlFor="rad-tem-type01-2">{t("filebox.label.image")}</label>
                        </div>
                    </div>
                </div>

                {field?.templateType === "text" ? (
                    <div className="field field-type-textarea required">
                        <div className="field-header">
                            <label htmlFor="template-ip-textarea" className="field-title">
                                {t("broadcast.commend.broadcast_message")}
                            </label>
                            {/* <StyledPreviewBtn className="btn btn-unique-class" onClick={() => setShowPreview(true)}>
                                {t("common.preview")}
                            </StyledPreviewBtn> */}
                        </div>
                        <div className="field-form">
                            <div className="field-box margin-0-style">
                                <textarea
                                    name="template-ip-textarea"
                                    id="template-ip-textarea"
                                    aria-required="true"
                                    placeholder={t("message.label.message")}
                                    value={field?.message}
                                    onChange={(e) => setField({ ...field, message: e.target.value })}
                                    maxLength={200}
                                ></textarea>
                            </div>
                            <div className="word-count">({field?.message?.length || 0}/200)</div>
                        </div>
                    </div>
                ) : (
                    <div className="field field-type-file required">
                        <div className="field-header">
                            <label htmlFor="template-ip-image-file" className="field-title">
                                {t("alertmsg.template.image")}
                            </label>
                            {/* <StyledPreviewBtn className="btn btn-unique-class" onClick={() => setShowPreview(true)}>
                                {t("common.preview")}
                            </StyledPreviewBtn> */}
                        </div>
                        <div className="field-form">
                            <div className="field-box">
                                <input type="text" id="template-ip-image-file" readOnly placeholder={t("alertmsg.popup.select_img_file")} value={field?.imageFile?.name || ""} />
                                <button
                                    type="button"
                                    className="btn btn-tertiary"
                                    onClick={() => {
                                        setSelectFileMode("image");
                                        setShowFileSelect(true);
                                    }}
                                >
                                    {t("device.label.select")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="field field-type-file required">
                    <div className="field-header">
                        <label htmlFor="template-ip-sound-file" className="field-title">
                            {t("alertmsg.popup.sound_file")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="field-box">
                            <input type="text" id="template-ip-sound-file" readOnly placeholder={t("alertmsg.popup.selec_sound_file")} value={field?.soundFile?.name || ""} />
                            <button
                                type="button"
                                className="btn btn-tertiary"
                                onClick={() => {
                                    setSelectFileMode("audio");
                                    setShowFileSelect(true);
                                }}
                            >
                                {t("device.label.select")}
                            </button>
                        </div>
                    </div>
                </div>

                <VolumeInput isRequired id="template-ip-volume-type" value={field?.volume} onChange={(value) => setField({ ...field, volume: value })} descId="template-volume-desc" />
            </Modal>
            {/* {showPreview && (
                <Preview onClose={() => setShowPreview(false)}>
                    {field.templateType === "text" ? field.message : <img src={async () => await utils.getFileDownloadUrl(field?.imageFile?.file)} alt="template image" />}
                </Preview>
            )} */}
            {showFileSelect && <SelectFile onClose={() => setShowFileSelect(false)} fileType={selectFileMode} onClick={onChangeFile} />}
        </Fragment>
    );
};

export default TemplatePopup;
