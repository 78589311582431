import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { Features, Tree } from "@component/UI/SideBar";
import useCss from "@hook/useCss";

import { Display, GroupModal, NameModal, FullModal, AddDisplayModal, AddDisplaySelectModal, RemoteModal } from "@component/Device/Detail";
import { Information, Issue, Controll, EZSettings, Usage, Diagnostics, Broadcast, Update, Apps } from "@component/Device/Detail";
import _API from "@util/api";
import _U from "@util/utilities";
import Loading from "@component/UI/Loading";
import { AppContext } from "@component/AppProvider";
import LastUpdate from "@component/UI/LastUpdate";

import Dialog from "@component/UI/Dialog";
import _Device from "@util/devices";
import _ from "lodash";

export default (props) => {
    useCss("/assets/css/device_manage_overview.css");

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { auth, workspace, saveInnerLoading, rolePermission, product, lastUpdateTime } = useContext(AppContext);

    const { idx } = useParams();
    const [tab, setTab] = useState({ label: "Information", value: "_information" });
    const [display, setDisplay] = useState(false);
    const [fullModal, setFullModal] = useState(false);
    const [groupModal, setGroupModal] = useState(false);
    const [nameModal, setNameModal] = useState(false);
    const [addModal, setAddModal] = useState({ open: false, callBack: null });
    const [addSelectModal, setAddSelectModal] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState({});
    const [checkTab, setCheckTab] = useState([]);
    const [workCheck, setWorkCheck] = useState(null);
    const [tabOpen, setTabOpen] = useState(false);
    const [locState, setLocState] = useState(null);
    const [remoteModal, setRemoteModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const onScreenRefresh = () => {
        onLoadData();
    };

    const onLoadData = async () => {
        try {
            const res = await _API.get({ path: `/device/devices/${idx}`, data: {} }).then((res) => {
                setDeviceInfo(res?.data?.result);
                saveInnerLoading && saveInnerLoading(false);
            });
        } catch (e) {
            console.log(`api error /device/devices/${idx}`, e);
            saveInnerLoading && saveInnerLoading(false);
        }
    };

    const onLoadMenuCheck = async () => {
        let features = ["_information", "_openIssue", "_control", "_ezSetting", "_usage", "_diagnostics", "_broadcast", "_apps", "__addDisplay", "__remoteControl", "__remoteAccess"];
        if (location?.state?.uniControlDevice) {
            features = ["_control"];
        }
        try {
            const res = await _API.post({
                path: `/device/devices/read`,
                data: {
                    device_id: `${idx}`,
                    features: features,
                },
            });
            if (res) {
                setCheckTab(res?.data?.result);
            }
        } catch (e) {
            console.log(`api error /device/devices/read ${idx}`, e);
        }
    };

    const goList = () => {
        if (locState) {
            navigate("/devices/list", { state: locState });
        } else {
            navigate("/devices/list");
        }
    };

    const tabList = [
        { label: t("common.info"), value: "_information" },
        { label: t("common.label.open.issue"), value: "_openIssue" },
        { label: t("common.label.control"), value: "_control" },
        { label: t("common.label.ezsettings"), value: "_ezSetting" },
        { label: t("dashboard.label.usage"), value: "_usage" },
        { label: t("device.label.diagnostics"), value: "_diagnostics" },
        { label: t("popup.title.broadcast"), value: "_broadcast" },
        { label: t("player.label.apps"), value: "_apps" },
    ];

    useEffect(async () => {
        document.title = t("common.label.device.details") + " | " + t("common.title.lgconnectedcare");
        saveInnerLoading(true);
        await onLoadMenuCheck();
        await onLoadData();
        if (location && location.state?.tabId) {
            setTab(tabList?.filter((f) => f.value === location.state?.tabId)[0]);
        }
        setLocState(location.state);
        saveInnerLoading(false);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (workspace && workCheck && workCheck.property_id !== workspace.property_id) {
            navigate("/devices/list");
        }
        setWorkCheck(workspace);
    }, [workspace]);

    const onRemoteControl = () => {
        Dialog({
            title: t("device.label.remoteaccess"),
            html: `${t("device.label.startaccess")}<br/><br/><span class="remote-control-dialog">(${t("admin.label.alias")} : ${deviceInfo?.device_name})</span>`,
            button: [t("button.cancel"), t("common.ok")],
        }).then((ok) => {
            if (ok) {
                if (checkTab?.filter((f) => f["__remoteAccess"])[0]?.["__remoteAccess"]?.supported) {
                    _U.downloadRemoteAccessFile(deviceInfo?.os_type, idx, t);
                } else if (checkTab?.filter((f) => f["__remoteControl"])[0]?.["__remoteControl"]?.supported) {
                    setRemoteModal(true);
                }
            }
        });
    };
    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowDown" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    useEffect(async () => {
        saveInnerLoading(true);
        await onLoadMenuCheck();
        await onLoadData();
        saveInnerLoading(false);
    }, [lastUpdateTime]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <a href="#!" className="btn-back" onClick={() => goList()}>
                        <span className="ir">{t("subtext.go.previous.screen")}</span>
                    </a>
                    {locState?.uniControlDevice ? (
                        <Fragment>
                            <h1>{t("common.label.UniController")}</h1>
                            <div className="breadcrumb">
                                <span className="bread first">{locState?.uniControlDevice?.model_name}</span>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h1>{t("player.label.physical")}</h1>
                            <div className="breadcrumb">
                                <span className="bread first">{t("common.label.device.details")}</span>
                            </div>
                        </Fragment>
                    )}
                </div>

                <LastUpdate />
            </div>
            <div className="content-middle">
                <div className="content-middle-box">
                    <div className="detail-content">
                        {locState?.uniControlDevice ? (
                            <div className="top-info-box">
                                <dl className="group-info-box">
                                    <dt>{t("devicemanagement.tree.group")}</dt>
                                    <dd className="group-info">
                                        {locState?.uniControlDevice?.groups?.map((item, index) => (
                                            <div className="group-info-item" key={index}>
                                                {item}
                                            </div>
                                        ))}
                                    </dd>
                                </dl>
                            </div>
                        ) : (
                            <div className="top-info-box">
                                <h2 className="page-title">
                                    {deviceInfo?.model_name} ({deviceInfo?.device_name})
                                    {checkTab?.filter((f) => f["__addDisplay"])[0]?.["__addDisplay"]?.supported && (
                                        <button type="button" className="btn btn-playlist" onClick={() => setDisplay(!display)}>
                                            <span className="ir">{t("subtext.ir.playlist")}</span>
                                        </button>
                                    )}
                                    {display && <Display idx={idx} onClose={() => setDisplay(false)} onSetAddModal={(e) => setAddModal(e)} />}
                                </h2>

                                <div className="info-content">
                                    <div className="img-box">
                                        <img
                                            src={deviceInfo?.thumbnail_url?.small ? deviceInfo?.thumbnail_url?.small : "/assets/images/sample/details_display.jpg"}
                                            alt={t("player.label.physical") + " " + t("common.label.screenshot")}
                                            onError={(e) => (e.target.src = "/assets/images/sample/details_display.jpg")}
                                        />
                                        <div className="control-memu">
                                            {(checkTab?.filter((f) => f["__remoteControl"])[0]?.["__remoteControl"]?.supported ||
                                                checkTab?.filter((f) => f["__remoteAccess"])[0]?.["__remoteAccess"]?.supported) && (
                                                <button type="button" className="btn-icon btn-remote" role="" onClick={onRemoteControl} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                    <span className="ir">{t("subtext.device.detail.remoteControl")}</span>
                                                </button>
                                            )}
                                            <button type="button" className="btn-icon btn-refresh" onClick={onScreenRefresh}>
                                                <span className="ir">{t("common.button.screen.refresh")}</span>
                                            </button>
                                            {deviceInfo?.thumbnail_url?.large && (
                                                <button type="button" className="btn-icon btn-fullscreen" onClick={() => setFullModal(true)}>
                                                    <span className="ir">{t("subtext.device.detail.full.screen")}</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="info-box">
                                        <div className="left-box">
                                            <div className="state-box">
                                                <dl>
                                                    <dt>
                                                        {t("device.label.devicetype")} | {t("table.head.os")}
                                                    </dt>
                                                    <dd>
                                                        <span>
                                                            {deviceInfo?.device_type} | {_Device.getOsTypeName(t, deviceInfo?.os_type)}
                                                        </span>
                                                        {/* <button type="button" className="btn-icon btn-edit" role="">
                                                        <span className="ir">Group 수정 팝업</span>
                                                    </button> */}
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt>{t("devicemanagement.tree.group")}</dt>
                                                    <dd>
                                                        <span>
                                                            {/* {deviceInfo?.group
                                                            ?.sort((a, b) => a.group_level - b.group_level)
                                                            ?.map((f, i) => f.group_name)
                                                            ?.join(" > ")}
                                                        {(!deviceInfo?.group || deviceInfo?.group?.length === 0) && "-"} */}
                                                            {deviceInfo?.hierarchy_group ? deviceInfo?.hierarchy_group : deviceInfo?.group_name}
                                                        </span>
                                                        <button type="button" className="btn btn-edit" role="" onClick={() => setGroupModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("button.edit")}
                                                        </button>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt>{t("table.head.devicename")}</dt>
                                                    <dd>
                                                        <span>{deviceInfo?.device_name || "-"}</span>
                                                        <button type="button" className="btn btn-edit" role="" onClick={() => setNameModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                            {t("button.edit")}
                                                        </button>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt>{t("common.label.serialnumber")}</dt>
                                                    <dd>
                                                        <span>{deviceInfo?.serial_number || "-"}</span>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt>{t("deivcemanagement.title.connection")}</dt>
                                                    <dd>
                                                        <span>{connectionStatusList(t)?.filter((f) => f.code === deviceInfo?.connection_status)[0]?.label || "-"}</span>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt>{t("filter.title.issueStatus")}</dt>
                                                    <dd>
                                                        <span className="img-icon chart">
                                                            <img src="/assets/images/ic_statistics.svg" alt={t("device.label.issue") + " " + t("common.label.icon")} /> {deviceInfo?.issue_count || ""}
                                                        </span>
                                                        <button type="button" className="btn-icon btn-edit" role="" onClick={() => setTab({ label: "Open Issue", value: "_openIssue" })}>
                                                            <span className="ir">{t("common.button.issue.detail")}</span>
                                                        </button>
                                                    </dd>
                                                </dl>
                                                {(checkTab?.filter((f) => f["__remoteControl"])[0]?.["__remoteControl"]?.supported ||
                                                    checkTab?.filter((f) => f["__remoteAccess"])[0]?.["__remoteAccess"]?.supported) && (
                                                    <dl>
                                                        <dt>{t("device.label.remotecontrol")}</dt>
                                                        <dd>
                                                            <button type="button" className="btn btn-view" role="" onClick={onRemoteControl} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                                                {t("button.show")}
                                                            </button>
                                                        </dd>
                                                    </dl>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={cx({ "content-info-box": true, "content-uni-controller": locState?.uniControlDevice ? true : false })}>
                            <div className="left-side">
                                <div className="category-menu">
                                    <div className="current-tab-menu">
                                        <a href="#none" className="current-selected-tab" aria-expanded={tabOpen} onClick={() => setTabOpen(!tabOpen)}>
                                            {tab?.label}
                                        </a>
                                    </div>
                                    <ul className={cx({ tabs: true, expand: tabOpen })} role="tablist" aria-orientation="vertical" ref={tabRef}>
                                        {checkTab?.filter((f) => f["_information"]?.supported)?.length > 0 && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_information" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("common.info"), value: "_information" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a
                                                    href="#none"
                                                    role="tab"
                                                    id="tab_information"
                                                    aria-selected={tab?.value === "_information" ? "true" : "false"}
                                                    tabIndex={tab?.value === "_information" ? 0 : -1}
                                                >
                                                    {t("common.info")}
                                                </a>
                                            </li>
                                        )}
                                        {checkTab?.filter((f) => f["_openIssue"]?.supported)?.length > 0 && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_openIssue" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("common.label.open.issue"), value: "_openIssue" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a
                                                    href="#none"
                                                    role="tab"
                                                    id="tab_openIssue"
                                                    aria-selected={tab?.value === "_openIssue" ? "true" : "false"}
                                                    tabIndex={tab?.value === "_openIssue" ? 0 : -1}
                                                >
                                                    {t("common.label.open.issue")}
                                                </a>
                                            </li>
                                        )}
                                        {checkTab?.filter((f) => f["_control"]?.supported)?.length > 0 && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_control" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("common.label.control"), value: "_control" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a href="#none" role="tab" id="tab_control" aria-selected={tab?.value === "_control" ? "true" : "false"} tabIndex={tab?.value === "_control" ? 0 : -1}>
                                                    {t("common.label.control")}
                                                </a>
                                            </li>
                                        )}
                                        {checkTab?.filter((f) => f["_ezSetting"]?.supported)?.length > 0 && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_ezSetting" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("common.label.ezsettings"), value: "_ezSetting" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a
                                                    href="#none"
                                                    role="tab"
                                                    id="tab_ezSetting"
                                                    aria-selected={tab?.value === "_ezSetting" ? "true" : "false"}
                                                    tabIndex={tab?.value === "_ezSetting" ? 0 : -1}
                                                >
                                                    {t("common.label.ezsettings")}
                                                </a>
                                            </li>
                                        )}
                                        {checkTab?.filter((f) => f["_usage"]?.supported)?.length > 0 && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_usage" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("dashboard.label.usage"), value: "_usage" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a href="#none" role="tab" id="tab_usage" aria-selected={tab?.value === "_usage" ? "true" : "false"} tabIndex={tab?.value === "_usage" ? 0 : -1}>
                                                    {t("dashboard.label.usage")}
                                                </a>
                                            </li>
                                        )}
                                        {checkTab?.filter((f) => f["_diagnostics"]?.supported)?.length > 0 && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_diagnostics" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("device.label.diagnostics"), value: "_diagnostics" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a
                                                    href="#none"
                                                    role="tab"
                                                    id="tab_diagnostics"
                                                    aria-selected={tab?.value === "_diagnostics" ? "true" : "false"}
                                                    tabIndex={tab?.value === "_diagnostics" ? 0 : -1}
                                                >
                                                    {t("device.label.diagnostics")}
                                                </a>
                                            </li>
                                        )}
                                        {checkTab?.filter((f) => f["_broadcast"]?.supported)?.length > 0 && product?.service_type === "DMS" && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_broadcast" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("popup.title.broadcast"), value: "_broadcast" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a
                                                    href="#none"
                                                    role="tab"
                                                    id="tab_broadcast"
                                                    aria-selected={tab?.value === "_broadcast" ? "true" : "false"}
                                                    tabIndex={tab?.value === "_broadcast" ? 0 : -1}
                                                >
                                                    {t("popup.title.broadcast")}
                                                </a>
                                            </li>
                                        )}
                                        {checkTab?.filter((f) => f["_apps"]?.supported)?.length > 0 && product?.service_type === "DMS" && (
                                            <li
                                                className={cx({ tab: true, active: tab?.value === "_apps" })}
                                                role="none presentation"
                                                onClick={() => {
                                                    setTab({ label: t("player.label.apps"), value: "_apps" });
                                                    setTabOpen(!tabOpen);
                                                }}
                                                onKeyDown={onTabAreaKeyDown}
                                            >
                                                <a
                                                    href="#none"
                                                    role="tab"
                                                    id="tab_apps"
                                                    aria-selected={tab?.value === "_apps" ? "true" : "false"}
                                                    tabIndex={tab?.value === "_in_appsformation" ? 0 : -1}
                                                >
                                                    {t("player.label.apps")}
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="right-side">
                                {tab?.value === "_information" && checkTab?.filter((f) => f["_information"]?.supported)?.length > 0 && <Information idx={idx} deviceInfo={deviceInfo} />}
                                {tab?.value === "_openIssue" && checkTab?.filter((f) => f["_openIssue"]?.supported)?.length > 0 && <Issue idx={idx} />}
                                {tab?.value === "_control" && checkTab?.filter((f) => f["_control"]?.supported)?.length > 0 && (
                                    <Controll idx={idx} deviceInfo={deviceInfo} uniControlDevice={locState?.uniControlDevice} />
                                )}
                                {tab?.value === "_ezSetting" && checkTab?.filter((f) => f["_ezSetting"]?.supported)?.length > 0 && <EZSettings idx={idx} deviceInfo={deviceInfo} />}
                                {tab?.value === "_usage" && checkTab?.filter((f) => f["_usage"]?.supported)?.length > 0 && <Usage idx={idx} deviceInfo={deviceInfo} />}
                                {/* {tab?.value === "_diagnostics" && checkTab?.filter((f) => f["_diagnostics"]?.supported)?.length > 0 && <Diagnostics idx={idx} />} */}
                                {tab?.value === "_broadcast" && product?.service_type === "DMS" && checkTab?.filter((f) => f["_broadcast"]?.supported)?.length > 0 && (
                                    <Broadcast idx={idx} deviceInfo={deviceInfo} />
                                )}
                                {tab?.value === "_apps" && product?.service_type === "DMS" && checkTab?.filter((f) => f["_apps"]?.supported)?.length > 0 && <Apps idx={idx} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {groupModal && <GroupModal idx={idx} currentGroupId={deviceInfo?.group_id} checkList={[{ device_id: idx }]} onClose={() => setGroupModal(false)} onDone={onLoadData} />}
            {nameModal && <NameModal data={deviceInfo} onClose={() => setNameModal(false)} onLoadData={onLoadData} />}
            {fullModal && <FullModal deviceInfo={deviceInfo} checkTab={checkTab} onRemoteControl={onRemoteControl} onClose={() => setFullModal(false)} onScreenRefresh={onScreenRefresh} />}
            {addModal?.open && (
                <AddDisplayModal
                    idx={idx}
                    onClose={() => {
                        addModal?.callBack && addModal?.callBack();
                        setAddModal({ open: false });
                    }}
                    onAddSelectModal={() => {
                        setAddModal({ open: false });
                        setAddSelectModal({ open: true, callBack: addModal?.callBack });
                    }}
                />
            )}
            {addSelectModal?.open && (
                <AddDisplaySelectModal
                    deviceInfo={deviceInfo}
                    onClose={() => {
                        addSelectModal?.callBack && addSelectModal?.callBack();
                        setAddSelectModal(false);
                    }}
                />
            )}
            {remoteModal && <RemoteModal deviceInfo={deviceInfo} onClose={() => setRemoteModal(false)} onScreenRefresh={onScreenRefresh} />}
        </Fragment>
    );
};

const connectionStatusList = (t) => {
    return [
        { label: t("common.label.connected"), code: "connected" },
        { label: t("setup.troubleNotice.label.nosignal"), code: "connected_no_signal" },
        { label: t("player.label.energySaving.screenOff"), code: "disconnected_screen_off" },
        { label: t("common.label.disconnected"), code: "disconnected" },
    ];
};
