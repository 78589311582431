import React, { Fragment, useState, useContext } from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useCss from "@hook/useCss";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";

export default () => {
    useCss("/assets/css/sitemap.css");
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const navigate = useNavigate();

    const goPage = (url) => {
        if (url) {
            const paths = url.split("/");
            let role = 0;
            switch (paths[1]) {
                case "":
                    role = auth?.user_roles[0]?.role_permission?.dashboard;
                    break;
                case "devices":
                    role = auth?.user_roles[0]?.role_permission?.devices;
                    break;
                case "history":
                    role = auth?.user_roles[0]?.role_permission?.history;
                    break;
                case "report":
                    role = auth?.user_roles[0]?.role_permission?.report;
                    break;
                case "administration":
                    role = auth?.user_roles[0]?.role_permission?.administration;
                    break;
                case "schedule":
                    role = auth?.user_roles[0]?.role_permission?.schedule;
                    break;
                default:
                    break;
            }
            if (role > 0) {
                navigate(url);
            } else {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.msg.noAuthorized"),
                    button: t("common.ok"),
                }).then((id) => {
                    if (id) {
                    }
                });
            }
        }
    };
    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>{t("common.label.sitemap")}</h1>
                </div>
            </div>

            <div className="content-middle">
                <div className="content-middle-box">
                    <div className="site-container">
                        <div className="map-title">
                            <h2>{t("subtext.dashboard.menu")}</h2>
                        </div>
                        <div className="map-menu">
                            <div className="menu-list">
                                <ul className="menu-wrap">
                                    <li role="none presentation">
                                        <a href="#!" className="menu-title dashboard" onClick={() => goPage("/")}>
                                            {t("home.label.DashBoard")}
                                        </a>
                                    </li>
                                </ul>

                                <ul className="menu-wrap">
                                    <li role="none presentation">
                                        <a href="#!" className="menu-title device" onClick={() => goPage("/devices/list")}>
                                            {t("device.label.devices")}
                                        </a>
                                    </li>
                                </ul>

                                <ul className="menu-wrap">
                                    <li role="none presentation">
                                        <a href="#!" className="menu-title history" onClick={() => goPage("/history/issue")}>
                                            {t("device.label.history")}
                                        </a>
                                        <ul className="sub-page-wrap">
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/history/issue")}>
                                                    {t("history.issue")}
                                                </a>
                                            </li>
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/history/action")}>
                                                    {t("common.label.history.action")}
                                                </a>
                                            </li>
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/history/access")}>
                                                    {t("common.label.history.access")}
                                                </a>
                                            </li>
                                            {/* <li role="none presentation">
                                                <a href="#!" className="sub-title">
                                                    Device Deletion History
                                                </a>
                                            </li> */}
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/history/license")}>
                                                    {t("common.label.history.license")}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <ul className="menu-wrap">
                                    <li role="none presentation">
                                        <a href="#!" className="menu-title report" onClick={() => goPage("/report/list")}>
                                            {t("common.label.report")}
                                        </a>
                                    </li>
                                </ul>

                                <ul className="menu-wrap">
                                    <li role="none presentation">
                                        <a href="#!" className="menu-title admin" onClick={() => goPage("/administration/profile")}>
                                            {t("common.label.administration")}
                                        </a>
                                        <ul className="sub-page-wrap">
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/administration/profile")}>
                                                    {t("common.profile")}
                                                </a>
                                            </li>
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/administration/role")}>
                                                    {t("common.label.user.role")}
                                                </a>
                                            </li>
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/administration/license")}>
                                                    {t("common.label.WorkspaceLicense")}
                                                </a>
                                            </li>
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/administration/agent")}>
                                                    {t("common.agent")}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <ul className="menu-wrap">
                                    <li role="none presentation">
                                        <a href="#!" className="menu-title schedule" onClick={() => goPage("/schedule/broadcast")}>
                                            {t("common.schedule")}
                                        </a>
                                        <ul className="sub-page-wrap">
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/schedule/broadcast")}>
                                                    {t("popup.title.broadcast")}
                                                </a>
                                            </li>
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/schedule/command")}>
                                                    {t("device.label.command")}
                                                </a>
                                            </li>
                                            <li role="none presentation">
                                                <a href="#!" className="sub-title" onClick={() => goPage("/schedule/app-management")}>
                                                    {t("admin.label.appmanagement")}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
