import "chart.js/auto";
import React from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  canvas {
    width: 100% !important;
    max-height: 26.2rem;
  }

  @media (max-width: 720px) {
    canvas {
      height: 100% !important;
      max-height: unset;
    }
  }
`;

const LineChart = ({ chartData }) => {
  const labels = Array.from(
    { length: chartData.electric_energy.length },
    (_, index) => index
  );

  const data = {
    labels,
    datasets: [
      {
        label: "My First Dataset",
        data: chartData.electric_energy,
        fill: false,
        borderColor: "#5689CD",
        tension: 0.1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: () => "",
          label: (context) => {
            return context.parsed.y + chartData.unit;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        border: { display: false },
        ticks: {
          display: true,
        },
        grid: { display: false },
      },
      y: {
        ticks: {
          stepSize: 100,
        },
      },
    },
  };

  return (
    <ChartWrapper>
      <Line data={data} options={options} />
    </ChartWrapper>
  );
};

export default LineChart;
