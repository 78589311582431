import React, { Fragment, useRef, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";

import _U from "@util/utilities";
import _API from "@util/api";

import useCss from "@hook/useCss";
import { SideMenu, CreateModal, UserModal, EditModal, ViewModal } from "@component/Admin";
import { SelectBox, InputDatepicker } from "@component/UI/Form";
import { Paging, ToPage, GetParams } from "@component/UI/Paging";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";
import LastUpdate from "@component/UI/LastUpdate";

export default () => {
    useCss("/assets/css/administration.css");
    const { auth, workspace, rolePermission, lastUpdateTime } = useContext(AppContext);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const [createModal, setCreateModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const [sideMenu, setSideMenu] = useState(false);
    const [selectItem, setSelectItem] = useState(false);

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const onClickUser = (item) => {
        setUserModal(true);
        setSelectItem(item);
    };

    const onClickView = (item) => {
        setViewModal(true);
        setSelectItem(item);
    };

    const onClickEdit = (item) => {
        setCreateModal(true);
        setSelectItem(item);
    };

    const onClickDelete = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("device.msg.deletetheitem"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((id) => {
            if (id) {
                _API.delete({ path: `/user/roles?role-id=${item?.role_id}`, data: {} })
                    .then((res) => {
                        loadList();
                    })
                    .catch((e) => {
                        console.log("api error /user/roles", e);
                    });
            }
        });
    };

    const onClickModify = () => {
        setUserModal(false);
        setEditModal(true);
    };

    const loadList = () => {
        console.log("###1 loadlist");
        _API.get({ path: "/user/roles/property", data: {} })
            .then((res) => {
                if (res?.data?.result) {
                    console.log("res?.data?.result", res?.data?.result);
                    const roles = res?.data?.result?.roles;
                    setList(roles);
                    if (typeof resizeTable !== "undefined") {
                        resizeTable();
                    }
                } else {
                    setList([]);
                }
            })
            .catch((e) => {
                console.log("api error /user/roles/property", e);
                setList([]);
            });
    };

    useEffect(() => {
        document.title = t("common.label.user.role") + " | " + t("common.title.lgconnectedcare");
        workspace && loadList();
    }, [workspace, lastUpdateTime]);

    return (
        <Fragment>
            <div className="content-top">
                <div className="top-left">
                    <h1>{t("common.label.administration")}</h1>
                </div>

                <LastUpdate />
            </div>

            <div className="content-middle wide-padding">
                <SideMenu sideMenu={sideMenu} onClose={() => setSideMenu(false)} />

                <div className="content-middle-box">
                    <div className="content-middle-top">
                        <button type="button" className="btn btn-open-child" onClick={() => setSideMenu(true)}>
                            <span className="ir">{t("history.open_sub")}</span>
                        </button>
                        <h2 className="title-content">{t("common.label.user.role")}</h2>
                    </div>

                    <div className="search-and-filter-box">
                        <div className="filter-box">
                            <div className="left-side">
                                <button type="button" className="btn btn-create-role" onClick={() => setCreateModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                    {t("common.button.CreateRole")}
                                </button>
                            </div>

                            <div className="right-side">
                                <div className="selected-count">
                                    {t("common.total")} : <span className="count">{list?.length || 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-content">
                        <div className="white-area">
                            <div className="data-list-container">
                                <div className="table">
                                    <div className="table-wrapper horizontal-scroll select-mode">
                                        <div className="table-box">
                                            <table>
                                                <caption>
                                                    {t("common.label.user.role")} {t("subtext.list")}
                                                </caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="20%">
                                                            <div className="text-block">{t("common.label.role.name")}</div>
                                                        </th>
                                                        <th scope="col" width="10%">
                                                            <div className="text-block">{t("common.label.assigneduser")}</div>
                                                        </th>
                                                        <th scope="col" width="20%">
                                                            <div className="text-block">{t("common.createdDate")}</div>
                                                        </th>
                                                        <th scope="col" width="20%">
                                                            <div className="text-block">{t("common.label.LastUpdateDate")}</div>
                                                        </th>
                                                        <th scope="col" width="10%">
                                                            <div className="text-block">{t("common.label.ViewRole")}</div>
                                                        </th>
                                                        <th scope="col" width="10%">
                                                            <div className="text-block">{t("common.edit")}</div>
                                                        </th>
                                                        <th scope="col" width="10%">
                                                            <div className="text-block">{t("button.delete")}</div>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {list?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="width-20-percent" id={`role-td1-${index}`}>
                                                                {item?.role_name}
                                                            </td>
                                                            <td id={`role-td2-${index}`}>
                                                                <a href="#!" className="link-target" onClick={() => onClickUser(item)}>
                                                                    {item?.assigned_user || "0"}
                                                                </a>
                                                                {/* <!-- <span>Group</span> --> */}
                                                            </td>
                                                            <td className="width-20-percent" id={`role-td3-${index}`}>
                                                                {item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : "-"}
                                                            </td>
                                                            <td className="width-20-percent" id={`role-td4-${index}`}>
                                                                {item?.update_at ? _U.getOffsetTime(auth, workspace, item?.update_at) : "-"}
                                                            </td>
                                                            <td className="align-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-view"
                                                                    onClick={() => onClickView(item)}
                                                                    aria-describedby={`role-td1-${index} role-td2-${index} role-td3-${index} role-td4-${index}`}
                                                                >
                                                                    {t("button.show")}
                                                                </button>
                                                            </td>
                                                            <td className="align-center">
                                                                <button
                                                                    type="button"
                                                                    id=""
                                                                    className="btn btn-edit"
                                                                    onClick={() => onClickEdit(item)}
                                                                    aria-describedby={`role-td1-${index} role-td2-${index} role-td3-${index} role-td4-${index}`}
                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                >
                                                                    {t("common.edit")}
                                                                </button>
                                                            </td>
                                                            <td className="align-center">
                                                                <button
                                                                    type="button"
                                                                    id=""
                                                                    className="btn btn-delete"
                                                                    onClick={() => onClickDelete(item)}
                                                                    aria-describedby={`role-td1-${index} role-td2-${index} role-td3-${index} role-td4-${index}`}
                                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                >
                                                                    {t("button.delete")}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="convert-table-to-list">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="thead">{t("common.label.role.name")}</div>
                                            </div>
                                        </div>
                                        <ul className="table-body">
                                            {list?.map((item, index) => (
                                                <li className="table-row" key={`reflow-${index}`}>
                                                    <div className="summary-data">
                                                        <div className="row">
                                                            <div className="table-cell status">
                                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                    {item?.role_name}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="all-data-box">
                                                        <ul className="all-data">
                                                            <li>
                                                                <span className="field-label">{t("common.label.assigneduser")}</span>
                                                                <span className="field-content">
                                                                    <span className="link-target" onClick={() => onClickUser(item)}>
                                                                        {item?.assigned_user || "0"}
                                                                    </span>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("common.createdDate")}</span>
                                                                <span className="field-content">{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : "-"}</span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("common.label.LastUpdateDate")}</span>
                                                                <span className="field-content">{item?.update_at ? _U.getOffsetTime(auth, workspace, item?.update_at) : "-"}</span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("common.label.ViewRole")}</span>
                                                                <span className="field-content">
                                                                    <button type="button" className="btn btn-view" onClick={() => onClickView(item)}>
                                                                        {t("button.show")}
                                                                    </button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("common.edit")}</span>
                                                                <span className="field-content">
                                                                    <button
                                                                        type="button"
                                                                        id=""
                                                                        className="btn btn-edit"
                                                                        onClick={() => onClickEdit(item)}
                                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                    >
                                                                        {t("common.edit")}
                                                                    </button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("button.delete")}</span>
                                                                <span className="field-content">
                                                                    <button
                                                                        type="button"
                                                                        id=""
                                                                        className="btn btn-delete"
                                                                        onClick={() => onClickDelete(item)}
                                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                                    >
                                                                        {t("button.delete")}
                                                                    </button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {createModal && (
                <CreateModal
                    data={selectItem}
                    onClose={() => {
                        setSelectItem(null);
                        setCreateModal(false);
                        console.log("###2 loadlist");
                        loadList();
                    }}
                />
            )}
            {userModal && (
                <UserModal
                    data={selectItem}
                    onClose={() => {
                        setSelectItem(null);
                        setUserModal(false);
                        loadList();
                    }}
                    onClick={onClickModify}
                />
            )}
            {editModal && (
                <EditModal
                    data={selectItem}
                    onClose={() => {
                        setSelectItem(null);
                        setEditModal(false);
                        loadList();
                    }}
                />
            )}
            {viewModal && (
                <ViewModal
                    data={selectItem}
                    onClose={() => {
                        setSelectItem(null);
                        setViewModal(false);
                    }}
                />
            )}
        </Fragment>
    );
};
