import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import cx from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";

import useCss from "@hook/useCss";
import Modal from "@component/UI/Modal";
import { SelectBox } from "@component/UI/Form";
import _API from "@util/api";
import _U from "@util/utilities";
import { TooltipTop } from "@component/Tooltip";

import { Cell, Temperature, Cabinet } from "./Detail.Diagnostics";
import { ControlPower, ControlSupport, ControlUpdate, ControlDisplay, ControlAudio } from "./Detail.Control";
import { EZVideoWall, EZSi, EZFail, EZPlay, EZCloning } from "./Detail.EZSetting";
import { UsageControl, UsagePower, UsageCpu, UsageMemory, UsageTemperature, UsageStorage, UsageApp, UsageInput, UsageFan, UsageHistory } from "./Detail.Usage";
import { BroadcastResult } from "./Detail.Broadcast";
import Dialog from "@component/UI/Dialog";
import _Device from "@util/devices";
import { AppContext } from "@component/AppProvider";

import RegisterBroadcastPopup from "@component/Schedule/Popup/RegisterBroadcastPopup";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowWidth from "@hook/useWindowWidth";

export const Information = (props) => {
    useCss("/assets/css/device_manage_display.css");

    const { t } = useTranslation();
    const { saveInnerLoading, lastUpdateTime } = useContext(AppContext);

    const { idx, deviceInfo } = props;
    const [networkModal, setNetworkModal] = useState(false);
    const [data, setData] = useState([]);

    const onLoadData = () => {
        saveInnerLoading(true);
        _API.post({
            path: `/device/devices/read`,
            data: {
                device_id: `${idx}`,
                features: [
                    "input",
                    "pictureMode",
                    "aspectRatio",
                    "signal",
                    "energyConsumption",
                    "currentApp",
                    "memoryUsage",
                    "availableStorage",
                    "utt",
                    "networkType",
                    "macAddress",
                    "ipAddress",
                    "subnetMask",
                    "defaultGateway",
                    "primaryDns",
                    "responseTime",
                    "rssi",
                    "ipv6Address",
                    "agentVersion",
                    "version",
                    "processor",
                    "bios",
                    "temperature",
                    "fan",
                    "humidity",
                    "panelBlu",
                    "filterAlarm",
                    "impactSensor",
                    "door",
                    "pixelSensor",
                    "currentSensor",
                    "manufacturer",
                    "cpuUsage",
                ],
            },
        })
            .then((res) => {
                console.log("res?.data", res?.data?.result);
                if (res?.data?.result) {
                    // setData(JSON.parse(JSON.stringify(res?.data?.result)));
                    setData(res?.data?.result);
                }
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log(`api error /device/devices/read ${idx}`, e);
                saveInnerLoading(false);
            });
    };

    useEffect(() => {
        onLoadData();
    }, [idx]);

    useEffect(() => {
        onLoadData();
    }, [lastUpdateTime]);

    return (
        <div className="tablist-content panel-information" role="tabpanel">
            <div className="list-box">
                {/* {_Device.getSupported("screen", data) && (
                    <div className="field field-flex field-screen">
                        <span className="field-label">Screen</span>
                        <span className="field-content">{data?.filter((f) => f["screen"])[0]?.["screen"]?.data?.value}</span>
                    </div>
                )} */}
                {_Device.getSupported("input", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("player.label.input")}</span>
                        <span className="field-content">{_Device.getHexRangeValue(t, "input", data, null, null, null, deviceInfo)}</span>
                    </div>
                )}
                {_Device.getSupported("pictureMode", data) && (
                    <div className="field field-flex field-picture-mode">
                        <span className="field-label">{t("player.label.pictureMode")}</span>
                        <span className="field-content">{_Device.getHexRangeValue(t, "pictureMode", data, null, null, null, deviceInfo)}</span>
                        {/* <button type="button" className="btn btn-details" role="">
                Details
            </button> */}
                    </div>
                )}
                {_Device.getSupported("ipAddress", data) && (
                    <div className="field field-flex field-network">
                        <span className="field-label">{t("player.label.network")}</span>
                        <span className="field-content">{data?.filter((f) => f["ipAddress"])[0]?.["ipAddress"]?.data?.value}</span>
                        <button type="button" className="btn btn-details" role="" onClick={() => setNetworkModal(true)}>
                            {t("report.label.details")}
                        </button>
                    </div>
                )}
                {_Device.getSupported("utt", data) && (
                    <div className="field field-flex field-total-usage-time">
                        <span className="field-label">{t("common.label.TotalUsageTime")}</span>
                        <span className="field-content">{data?.filter((f) => f["utt"])[0]?.["utt"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("availableStorage", data) && (
                    <div className="field field-flex field-available-storage">
                        <span className="field-label">{t("distribution.label.storageFree")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["availableStorage"])[0]?.["availableStorage"]?.data?.value
                                ? data?.filter((f) => f["availableStorage"])[0]?.["availableStorage"]?.data?.value + " MB"
                                : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("version", data) && (
                    <div className="field field-flex field-fw-version">
                        <span className="field-label">{t("dv.firmware.version")}</span>
                        <span className="field-content">{data?.filter((f) => f["version"])[0]?.["version"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("energyConsumption", data) && (
                    <div className="field field-flex field-energy-consumption">
                        <span className="field-label">{t("common.label.EnergyConsumption")}</span>
                        <span className="field-content">{data?.filter((f) => f["energyConsumption"])[0]?.["energyConsumption"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("agentVersion", data) && (
                    <div className="field field-flex field-agent-version">
                        <span className="field-label">{t("dv.agent.version")}</span>
                        <span className="field-content">{data?.filter((f) => f["agentVersion"])[0]?.["agentVersion"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("currentApp", data) && (
                    <div className="field field-flex field-current-app">
                        <span className="field-label">{t("player.label.currentApp")}</span>
                        <span className="field-content">{data?.filter((f) => f["currentApp"])[0]?.["currentApp"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("processor", data) && (
                    <div className="field field-flex field-processor">
                        <span className="field-label">{t("device.label.processor")}</span>
                        <span className="field-content">{data?.filter((f) => f["processor"])[0]?.["processor"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("bios", data) && (
                    <div className="field field-flex field-processor">
                        <span className="field-label">{t("device.label.biosinfo")}</span>
                        <span className="field-content">{data?.filter((f) => f["bios"])[0]?.["bios"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("memoryUsage", data) && (
                    <div className="field field-flex field-processor">
                        <span className="field-label">{t("player.label.memory")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["memoryUsage"])[0]?.["memoryUsage"]?.data?.value ? data?.filter((f) => f["memoryUsage"])[0]?.["memoryUsage"]?.data?.value + " %" : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("manufacturer", data) && (
                    <div className="field field-flex field-processor">
                        <span className="field-label">{t("device.label.manufacturer")}</span>
                        <span className="field-content">{data?.filter((f) => f["manufacturer"])[0]?.["manufacturer"]?.data?.value}</span>
                    </div>
                )}
                {_Device.getSupported("aspectRatio", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("player.label.screenRatio")}</span>
                        <span className="field-content">
                            {_Device.getHexRangeValue(t, "aspectRatio", data, null, null, null, deviceInfo)}
                            {/* {data?.filter((f) => f["aspectRatio"])[0]?.["aspectRatio"]?.data?.value ? JSON.stringify(data?.filter((f) => f["aspectRatio"])[0]?.["aspectRatio"]?.data?.value) : "-"} */}
                        </span>
                    </div>
                )}
                {_Device.getSupported("signal", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("player.label.signal")}</span>
                        <span className="field-content">{data?.filter((f) => f["signal"])[0]?.["signal"]?.data?.value || "-"}</span>
                    </div>
                )}
                {/* {_Device.getSupported("oledPanel", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("device.label.oledpanel")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["oledPanel"])[0]?.["oledPanel"]?.data?.value ? JSON.stringify(data?.filter((f) => f["oledPanel"])[0]?.["oledPanel"]?.data?.value) : "-"}
                        </span>
                    </div>
                )} */}
                {_Device.getSupported("cpuUsage", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("dashboard.label.cpuusage")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["cpuUsage"])[0]?.["cpuUsage"]?.data?.value ? data?.filter((f) => f["cpuUsage"])[0]?.["cpuUsage"]?.data?.value + " %" : "-"}
                        </span>
                    </div>
                )}
                {/* {_Device.getSupported("runningTime", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("device.label.runningtime")}</span>
                        <span className="field-content">{JSON.stringify(data?.filter((f) => f["runningTime"])[0]?.["runningTime"]?.data?.value) || "-"}</span>
                    </div>
                )} */}
                {_Device.getSupported("timeZone", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("settings.label.timezone")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["timeZone"])[0]?.["timeZone"]?.data?.timezone
                                ? `(${data?.filter((f) => f["timeZone"])[0]?.["timeZone"]?.data?.timezone}) ${data?.filter((f) => f["timeZone"])[0]?.["timeZone"]?.data?.timezone_name}`
                                : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("itAssetNumber", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("devices.label.itassetnum")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["itAssetNumber"])[0]?.["itAssetNumber"]?.data?.value
                                ? JSON.stringify(data?.filter((f) => f["itAssetNumber"])[0]?.["itAssetNumber"]?.data?.value)
                                : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("temperature", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("player.label.temperature")}</span>
                        <div className="field-content field-type">
                            <div className="table">
                                <div className="table-wrapper no-scroll">
                                    <div className="table-box">
                                        <table className="height-33-5-rem">
                                            <caption>{t("player.label.temperature")}</caption>
                                            <tbody className="top-0-style">
                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("blu") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            {t("dv.status.blu")}
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.blu
                                                                ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.blu == -128
                                                                    ? "NG"
                                                                    : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.blu + "˚C"
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("external") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            {t("dv.status.external")}
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.external
                                                                ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.external == -128
                                                                    ? "NG"
                                                                    : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.external + "˚C"
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("front") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            {t("dv.status.front")}
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.front
                                                                ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.front == -128
                                                                    ? "NG"
                                                                    : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.front + "˚C"
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("main") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            {t("dv.status.main")}
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main
                                                                ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main == -128
                                                                    ? "NG"
                                                                    : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main + "˚C"
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("psu") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            {t("dv.status.psu")}
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.psu
                                                                ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.psu == -128
                                                                    ? "NG"
                                                                    : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.psu + "˚C"
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("normal") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            {t("device.label.normal")}
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.normal
                                                                ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.normal == -128
                                                                    ? "NG"
                                                                    : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.normal + "˚C"
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="convert-table-to-list">
                                    <div className="table-header">
                                        <div className="row">
                                            <div className="thead">{t("dv.status.main")}</div>
                                        </div>
                                    </div>

                                    <ul className="table-body">
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell issue-level" aria-label="issue level">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main
                                                                ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main == -128
                                                                    ? "NG"
                                                                    : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main + "˚C"
                                                                : "-"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("blu") && (
                                                        <li>
                                                            <span className="field-label">{t("dv.status.blu")}</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.blu
                                                                    ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.blu == -128
                                                                        ? "NG"
                                                                        : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.blu + "˚C"
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("external") && (
                                                        <li>
                                                            <span className="field-label">{t("dv.status.external")}</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.external
                                                                    ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.external == -128
                                                                        ? "NG"
                                                                        : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.external + "˚C"
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("front") && (
                                                        <li>
                                                            <span className="field-label">{t("dv.status.front")}</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.front
                                                                    ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.front == -128
                                                                        ? "NG"
                                                                        : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.front + "˚C"
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("main") && (
                                                        <li>
                                                            <span className="field-label">{t("dv.status.main")}</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main
                                                                    ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main == -128
                                                                        ? "NG"
                                                                        : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.main + "˚C"
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("psu") && (
                                                        <li>
                                                            <span className="field-label">{t("dv.status.psu")}</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.psu
                                                                    ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.psu == -128
                                                                        ? "NG"
                                                                        : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.psu + "˚C"
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.hasOwnProperty("normal") && (
                                                        <li>
                                                            <span className="field-label">{t("device.label.normal")}</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.normal
                                                                    ? data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.normal == -128
                                                                        ? "NG"
                                                                        : data?.filter((f) => f["temperature"])[0]?.["temperature"]?.data?.value?.normal + "˚C"
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {_Device.getSupported("fan", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("player.label.fan")}</span>
                        {/* <span className="field-content">{data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value ? JSON.stringify(data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value) : "-"}</span> */}
                        <div className="field-content field-type">
                            <div className="table">
                                <div className="table-wrapper no-scroll">
                                    <div className="table-box">
                                        <table className="height-33-5-rem">
                                            <caption>{t("player.label.fan")}</caption>
                                            <tbody className="top-0-style">
                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("CL1") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            CL1
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1 == 0
                                                                ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1?.status} (${
                                                                      data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1?.rpm
                                                                  } RPM)`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("CL2") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            CL2
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2 == 0
                                                                ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2?.status} (${
                                                                      data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2?.rpm
                                                                  } RPM)`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("OL1") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            OL1
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1 == 0
                                                                ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1?.status} (${
                                                                      data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1?.rpm
                                                                  } RPM)`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("OL2") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            OL2
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2 == 0
                                                                ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2?.status} (${
                                                                      data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2?.rpm
                                                                  } RPM)`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("NR1") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            NR1
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1 == 0
                                                                ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1?.status} (${
                                                                      data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1?.rpm
                                                                  } RPM)`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("NR2") && (
                                                    <tr>
                                                        <th scope="row" width="148">
                                                            NR2
                                                        </th>
                                                        <td>
                                                            {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2 == 0
                                                                ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2?.status} (${
                                                                      data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2?.rpm
                                                                  } RPM)`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="convert-table-to-list">
                                    <div className="table-header">
                                        <div className="row">
                                            <div className="thead">CL1</div>
                                        </div>
                                    </div>

                                    <ul className="table-body">
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell issue-level">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1
                                                                ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1?.status} (${
                                                                      data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1?.rpm
                                                                  } RPM)`
                                                                : "-"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("CL1") && (
                                                        <li>
                                                            <span className="field-label">CL1</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1 == 0
                                                                    ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1?.status} (${
                                                                          data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL1?.rpm
                                                                      } RPM)`
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("CL2") && (
                                                        <li>
                                                            <span className="field-label">CL2</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2 == 0
                                                                    ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2?.status} (${
                                                                          data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.CL2?.rpm
                                                                      } RPM)`
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("OL1") && (
                                                        <li>
                                                            <span className="field-label">OL1</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1 == 0
                                                                    ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1?.status} (${
                                                                          data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL1?.rpm
                                                                      } RPM)`
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("OL2") && (
                                                        <li>
                                                            <span className="field-label">OL2</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2 == 0
                                                                    ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2?.status} (${
                                                                          data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.OL2?.rpm
                                                                      } RPM)`
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("NR1") && (
                                                        <li>
                                                            <span className="field-label">NR1</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1 == 0
                                                                    ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1?.status} (${
                                                                          data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR1?.rpm
                                                                      } RPM)`
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.hasOwnProperty("NR2") && (
                                                        <li>
                                                            <span className="field-label">NR2</span>
                                                            <span className="field-content">
                                                                {data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2 || data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2 == 0
                                                                    ? `${data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2?.status} (${
                                                                          data?.filter((f) => f["fan"])[0]?.["fan"]?.data?.value?.NR2?.rpm
                                                                      } RPM)`
                                                                    : "-"}
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {_Device.getSupported("humidity", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("sensor.label.humidity")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["humidity"])[0]?.["humidity"]?.data?.value ? data?.filter((f) => f["humidity"])[0]?.["humidity"]?.data?.value : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("panelBlu", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("common.label.PanelBlu")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["panelBlu"])[0]?.["panelBlu"]?.data?.value ? data?.filter((f) => f["panelBlu"])[0]?.["panelBlu"]?.data?.value : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("filterAlarm", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("sensor.label.filteralarm")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["filterAlarm"])[0]?.["filterAlarm"]?.data?.value ? data?.filter((f) => f["filterAlarm"])[0]?.["filterAlarm"]?.data?.value : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("impactSensor", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("common.label.ImpactSeonsor")}</span>
                        <span className="field-content">
                            {data?.filter((f) => f["impactSensor"])[0]?.["impactSensor"]?.data?.value ? data?.filter((f) => f["impactSensor"])[0]?.["impactSensor"]?.data?.value : "-"}
                        </span>
                    </div>
                )}
                {_Device.getSupported("door", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("device.label.door")}</span>
                        <span className="field-content">{data?.filter((f) => f["door"])[0]?.["door"]?.data?.value ? data?.filter((f) => f["door"])[0]?.["door"]?.data?.value : "-"}</span>
                    </div>
                )}
                {_Device.getSupported("pixelSensor", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("device.label.pixelsensor")}</span>
                        {/* <span className="field-content">
                            {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value ? JSON.stringify(data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value) : "-"}
                        </span> */}
                        <div className="field-content field-type">
                            <div className="table">
                                <div className="table-wrapper no-scroll">
                                    <div className="table-box">
                                        <table className="height-18-5-rem">
                                            <caption>{t("device.label.pixelsensor")}</caption>
                                            <tbody className="top-0-style">
                                                <tr>
                                                    <th scope="row" width="148">
                                                        {t("dv.led.red")}
                                                    </th>
                                                    <td>
                                                        {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.red
                                                            ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.red
                                                            : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" width="148">
                                                        {t("dv.led.green")}
                                                    </th>
                                                    <td>
                                                        {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.green
                                                            ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.green
                                                            : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" width="148">
                                                        {t("dv.led.blue")}
                                                    </th>
                                                    <td>
                                                        {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.blue
                                                            ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.blue
                                                            : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" width="148">
                                                        {t("dv.led.white")}
                                                    </th>
                                                    <td>
                                                        {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.white
                                                            ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.white
                                                            : "-"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="convert-table-to-list">
                                    <div className="table-header">
                                        <div className="row">
                                            <div className="thead">{t("dv.led.red")}</div>
                                        </div>
                                    </div>

                                    <ul className="table-body">
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell issue-level" aria-label="issue level">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.red
                                                                ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.red
                                                                : "-"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li>
                                                        <span className="field-label">{t("dv.led.green")}</span>
                                                        <span className="field-content">
                                                            {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.green
                                                                ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.green
                                                                : "-"}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("dv.led.blue")}</span>
                                                        <span className="field-content">
                                                            {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.blue
                                                                ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.blue
                                                                : "-"}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("dv.led.white")}</span>
                                                        <span className="field-content">
                                                            {data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.white
                                                                ? data?.filter((f) => f["pixelSensor"])[0]?.["pixelSensor"]?.data?.value?.main?.white
                                                                : "-"}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {_Device.getSupported("currentSensor", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("common.label.CurrentSensor")}</span>
                        {/* <span className="field-content">
                            {data?.filter((f) => f["currentSensor"])[0]?.["currentSensor"]?.data?.value
                                ? JSON.stringify(data?.filter((f) => f["currentSensor"])[0]?.["currentSensor"]?.data?.value)
                                : "-"}
                        </span> */}
                        <div className="field-content field-type">
                            <div className="table">
                                <div className="table-wrapper no-scroll">
                                    <div className="table-box">
                                        <table className="height-7-5-rem">
                                            <caption>{t("common.label.CurrentSensor")}</caption>
                                            <tbody className="top-0-style">
                                                <tr>
                                                    <th scope="row" width="148">
                                                        {t("dv.status.main")}
                                                    </th>
                                                    <td>
                                                        {data?.filter((f) => f["currentSensor"])[0]?.["currentSensor"]?.data?.value?.main
                                                            ? data?.filter((f) => f["currentSensor"])[0]?.["currentSensor"]?.data?.value?.main
                                                            : "-"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="convert-table-to-list">
                                    <div className="table-header">
                                        <div className="row">
                                            <div className="thead">{t("dv.status.main")}</div>
                                        </div>
                                    </div>

                                    <ul className="table-body">
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell issue-level" aria-label="issue level">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                            {data?.filter((f) => f["currentSensor"])[0]?.["currentSensor"]?.data?.value?.main
                                                                ? data?.filter((f) => f["currentSensor"])[0]?.["currentSensor"]?.data?.value?.main
                                                                : "-"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {_Device.getSupported("led", data) && (
                    <div className="field field-flex field-input">
                        <span className="field-label">{t("common.code.device.type.led")}</span>
                        <span className="field-content">{data?.filter((f) => f["led"])[0]?.["led"]?.data?.value ? JSON.stringify(data?.filter((f) => f["led"])[0]?.["led"]?.data?.value) : "-"}</span>
                    </div>
                )}
            </div>
            {networkModal && <NetworkModal data={data} onClose={() => setNetworkModal(false)} />}
        </div>
    );
};

export const Issue = (props) => {
    useCss("/assets/css/device_manage_display.css");

    const { t } = useTranslation();
    const { auth, workspace, saveInnerLoading, lastUpdateTime } = useContext(AppContext);
    const [list, setList] = useState([]);

    const getIssueList = (device_id) => {
        saveInnerLoading(true);
        _API.get({ path: `/device/devices/${device_id}/issue`, data: { device_id } })
            .then((res) => {
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    setList(res?.data?.result);
                }
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log("api error ", e);
                saveInnerLoading(false);
            });
    };

    const getThreshold = (diagnosticsData) => {
        let result = "";
        try {
            if (diagnosticsData) {
                const d = JSON.parse(diagnosticsData);
                if (d?.data?.threshold_value) {
                    result = d?.data?.threshold_value;
                }
            }
        } catch (e) {}
        return result;
    };

    useEffect(() => {
        getIssueList(props?.idx);
    }, [props?.idx]);

    useEffect(async () => {
        getIssueList();
    }, [lastUpdateTime]);

    return (
        <div className="tablist-content panel-open-issue" role="tabpanel">
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="right-side">
                        <div className="total-count">
                            {t("common.total")} : <span className="count">{list?.length || 0}</span>
                        </div>
                    </div>
                </div>

                <div className="table">
                    <div className="table-wrapper device-issue-list horizontal-scroll no-scroll">
                        <div className="table-box">
                            <table>
                                <caption>{t("dv.issue.device issue list")}</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="text-block">{t("report.issuetype")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("im.issue.code")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("common.description")}</div>
                                        </th>
                                        {/* <th scope="col">
                                            <div className="text-block">Issue Level</div>
                                        </th> */}
                                        <th scope="col">
                                            <div className="text-block">{t("dv.issue.threshold")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("dv.issue.occurredTime")}</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.issue_type}</td>
                                            <td>{item?.issue_code}</td>
                                            <td>{item?.description}</td>
                                            <td>{getThreshold(item?.diagnostics_data)}</td>
                                            <td>{item?.occurred_time ? _U.getOffsetTime(auth, workspace, item?.occurred_time) : ""}</td>
                                        </tr>
                                    ))}
                                    {(!list || list?.length === 0) && (
                                        <tr>
                                            <td colSpan={5} className="align-center">
                                                {t("common.label.noitems")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <div className="table-header">
                            <div className="row">
                                <div className="thead">{t("report.issuetype")}</div>
                            </div>
                        </div>

                        <ul className="table-body">
                            {list?.map((item, index) => (
                                <li className="table-row" key={index}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell issue-level" aria-label="issue type">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                    {item?.issue_type}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">{t("im.issue.code")}</span>
                                                <span className="field-content">{item?.issue_code}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("common.description")}</span>
                                                <span className="field-content">{item?.description}</span>
                                            </li>
                                            {/* <li>
                                                    <span className="field-label">Issue Level</span>
                                                    <span className="field-content">{item?.code}</span>
                                                </li> */}
                                            <li>
                                                <span className="field-label">{t("dv.issue.threshold")}</span>
                                                <span className="field-content">{item?.threshold}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("dv.issue.occurredTime")}</span>
                                                <span className="field-content">{item?.occurred_time ? _U.getOffsetTime(auth, workspace, item?.occurred_time) : ""}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                            {list?.length === 0 && (
                                <li className="table-row">
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell issue-level">{t("common.label.noitems")}</div>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Controll = (props) => {
    useCss("/assets/css/device_manage_display.css");

    const { t } = useTranslation();
    const { idx, deviceInfo, uniControlDevice } = props;
    const { saveInnerLoading, lastUpdateTime } = useContext(AppContext);
    const [tab, setTab] = useState(null);
    const [checkTab, setCheckTab] = useState([]);
    const [tabOpen, setTabOpen] = useState(false);

    const getTitle = () => {
        switch (tab) {
            case "_power":
                return "Power";
            case "_support":
                return "Support";
            case "_update":
                return "Update";
            case "_display":
                return "Display";
            case "_audio":
                return "Audio";
        }
    };

    const onLoadMenuCheck = async () => {
        try {
            const res = await _API.post({
                path: `/device/devices/read`,
                data: {
                    device_id: `${idx}`,
                    features: ["_power", "_support", "_update", "_display", "_audio"],
                },
            });
            if (res) {
                setCheckTab(res?.data?.result);
                for (const f of res?.data?.result) {
                    const key = Object.keys(f)[0];
                    if (f[key]?.supported) {
                        setTab(key);
                        break;
                    }
                }
            }
        } catch (e) {
            console.log(`api error /device/devices/read ${idx}`, e);
        }
    };

    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowRight" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    useEffect(async () => {
        saveInnerLoading(true);
        await onLoadMenuCheck();
        saveInnerLoading(false);
    }, []);

    return (
        <Fragment>
            <div className="tablist-content panel-control" role="tabpanel">
                <div className="current-tab-menu">
                    <a href="#!" className="current-selected-tab" aria-expanded={tabOpen} onClick={() => setTabOpen(!tabOpen)}>
                        {getTitle()}
                    </a>
                </div>

                <div className={cx({ "tab-group": true, expand: tabOpen })} role="tablist" aria-labelledby="tab_control" ref={tabRef}>
                    {checkTab?.filter((f) => f["_power"]?.supported)?.length > 0 && (
                        <div className={cx({ tab: true, active: tab === "_power" })}>
                            <a
                                id="power"
                                href="#!"
                                role="tab"
                                aria-selected={tab === "_power" ? "true" : "false"}
                                onClick={() => {
                                    setTab("_power");
                                    setTabOpen(!tabOpen);
                                }}
                                onKeyDown={onTabAreaKeyDown}
                                tabIndex={tab === "_power" ? 0 : -1}
                            >
                                {t("player.label.power")}
                            </a>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_support"]?.supported)?.length > 0 && (
                        <div className={cx({ tab: true, active: tab === "_support" })}>
                            <a
                                id="support"
                                href="#!"
                                role="tab"
                                aria-selected={tab === "_support" ? "true" : "false"}
                                onClick={() => {
                                    setTab("_support");
                                    setTabOpen(!tabOpen);
                                }}
                                onKeyDown={onTabAreaKeyDown}
                                tabIndex={tab === "_support" ? 0 : -1}
                            >
                                {t("device.label.support")}
                            </a>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_update"]?.supported)?.length > 0 && (
                        <div className={cx({ tab: true, active: tab === "_update" })}>
                            <a
                                id="update"
                                href="#!"
                                role="tab"
                                aria-selected={tab === "_update" ? "true" : "false"}
                                onClick={() => {
                                    setTab("_update");
                                    setTabOpen(!tabOpen);
                                }}
                                onKeyDown={onTabAreaKeyDown}
                                tabIndex={tab === "_update" ? 0 : -1}
                            >
                                {t("device.button.update")}
                            </a>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_display"]?.supported)?.length > 0 && (
                        <div className={cx({ tab: true, active: tab === "_display" })}>
                            <a
                                id="display"
                                href="#!"
                                role="tab"
                                aria-selected={tab === "_display" ? "true" : "false"}
                                onClick={() => {
                                    setTab("_display");
                                    setTabOpen(!tabOpen);
                                }}
                                onKeyDown={onTabAreaKeyDown}
                                tabIndex={tab === "_display" ? 0 : -1}
                            >
                                {t("player.label.display")}
                            </a>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_audio"]?.supported)?.length > 0 && (
                        <div className={cx({ tab: true, active: tab === "_audio" })}>
                            <a
                                id="audio"
                                href="#!"
                                role="tab"
                                aria-selected={tab === "_audio" ? "true" : "false"}
                                onClick={() => {
                                    setTab("_audio");
                                    setTabOpen(!tabOpen);
                                }}
                                onKeyDown={onTabAreaKeyDown}
                                tabIndex={tab === "_audio" ? 0 : -1}
                            >
                                {t("player.label.audio")}
                            </a>
                        </div>
                    )}
                </div>
                {tab === "_power" && checkTab?.filter((f) => f["_power"]?.supported)?.length > 0 && <ControlPower idx={idx} uniControlDevice={uniControlDevice} />}
                {tab === "_support" && checkTab?.filter((f) => f["_support"]?.supported)?.length > 0 && <ControlSupport idx={idx} deviceInfo={deviceInfo} uniControlDevice={uniControlDevice} />}
                {tab === "_update" && checkTab?.filter((f) => f["_update"]?.supported)?.length > 0 && <ControlUpdate idx={idx} deviceInfo={deviceInfo} uniControlDevice={uniControlDevice} />}
                {tab === "_display" && checkTab?.filter((f) => f["_display"]?.supported)?.length > 0 && <ControlDisplay idx={idx} deviceInfo={deviceInfo} uniControlDevice={uniControlDevice} />}
                {tab === "_audio" && checkTab?.filter((f) => f["_audio"]?.supported)?.length > 0 && <ControlAudio idx={idx} deviceInfo={deviceInfo} uniControlDevice={uniControlDevice} />}
            </div>
        </Fragment>
    );
};

export const EZSettings = (props) => {
    useCss("/assets/css/device_manage_display.css");
    const { t } = useTranslation();
    const { idx, deviceInfo } = props;
    const { saveInnerLoading, lastUpdateTime } = useContext(AppContext);
    const [tab, setTab] = useState(null);
    const [checkTab, setCheckTab] = useState([]);
    const [tabOpen, setTabOpen] = useState(false);

    const getTitle = () => {
        switch (tab) {
            case "_videowall":
                return "Videowall";
            case "_siServerSetting":
                return "SI Server Setting";
            case "_failOver":
                return "Fail Over";
            case "_playViaURL":
                return "Play via URL";
            case "_settingDataCloning":
                return "Setting Data Cloning";
        }
    };

    const onLoadMenuCheck = async () => {
        try {
            const res = await _API.post({
                path: `/device/devices/read`,
                data: {
                    device_id: `${idx}`,
                    features: ["_videowall", "_siServerSetting", "_failOver", "_playViaURL", "_settingDataCloning"],
                },
            });
            if (res) {
                setCheckTab(res?.data?.result);
                for (const f of res?.data?.result) {
                    const key = Object.keys(f)[0];
                    if (f[key]?.supported) {
                        setTab(key);
                        break;
                    }
                }
            }
        } catch (e) {
            console.log(`api error /device/devices/read ${idx}`, e);
        }
    };

    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowRight" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    useEffect(async () => {
        saveInnerLoading(true);
        await onLoadMenuCheck();
        saveInnerLoading(false);
    }, []);

    return (
        <div className="tablist-content panel-ez-settings" role="tabpanel">
            <div className="current-tab-menu">
                <a href="#!" className="current-selected-tab" aria-expanded={tabOpen} onClick={() => setTabOpen(!tabOpen)}>
                    {getTitle()}
                </a>
            </div>

            <div className={cx({ "tab-group": true, expand: tabOpen })} role="tablist" aria-labelledby="tab_ezSetting" ref={tabRef}>
                {checkTab?.filter((f) => f["_videowall"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_videowall" })}>
                        <a
                            id="videowall"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_videowall" ? "true" : "false"}
                            onClick={() => {
                                setTab("_videowall");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                            tabIndex={tab === "_videowall" ? 0 : -1}
                        >
                            {t("dv.videowall.title")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_siServerSetting"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_siServerSetting" })}>
                        <a
                            id="si-server-setting"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_siServerSetting" ? "true" : "false"}
                            onClick={() => {
                                setTab("_siServerSetting");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                            tabIndex={tab === "_siServerSetting" ? 0 : -1}
                        >
                            {t("device.label.siserver")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_failOver"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_failOver" })}>
                        <a
                            id="fail-over"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_failOver" ? "true" : "false"}
                            onClick={() => {
                                setTab("_failOver");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                            tabIndex={tab === "_failOver" ? 0 : -1}
                        >
                            {t("device.label.failover")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_playViaURL"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_playViaURL" })}>
                        <a
                            id="play-via-url"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_playViaURL" ? "true" : "false"}
                            onClick={() => {
                                setTab("_playViaURL");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                            tabIndex={tab === "_playViaURL" ? 0 : -1}
                        >
                            {t("player.label.playviaurl")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_settingDataCloning"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_settingDataCloning" })}>
                        <a
                            id="setting-data-cloning"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_settingDataCloning" ? "true" : "false"}
                            onClick={() => {
                                setTab("_settingDataCloning");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                            tabIndex={tab === "_settingDataCloning" ? 0 : -1}
                        >
                            {t("common.label.SettingDataCloning")}
                        </a>
                    </div>
                )}
            </div>
            {tab === "_videowall" && checkTab?.filter((f) => f["_videowall"]?.supported)?.length > 0 && <EZVideoWall idx={idx} />}
            {tab === "_siServerSetting" && checkTab?.filter((f) => f["_siServerSetting"]?.supported)?.length > 0 && <EZSi idx={idx} />}
            {tab === "_failOver" && checkTab?.filter((f) => f["_failOver"]?.supported)?.length > 0 && <EZFail idx={idx} deviceInfo={deviceInfo} />}
            {tab === "_playViaURL" && checkTab?.filter((f) => f["_playViaURL"]?.supported)?.length > 0 && <EZPlay idx={idx} />}
            {tab === "_settingDataCloning" && checkTab?.filter((f) => f["_settingDataCloning"]?.supported)?.length > 0 && <EZCloning idx={idx} deviceInfo={deviceInfo} />}
        </div>
    );
};

export const Usage = (props) => {
    useCss("/assets/css/device_manage_display.css");

    const { t } = useTranslation();
    const { idx, deviceInfo } = props;
    const { saveInnerLoading, lastUpdateTime } = useContext(AppContext);
    const [tab, setTab] = useState("_usageControl");
    const [checkTab, setCheckTab] = useState([]);
    const [tabOpen, setTabOpen] = useState(false);

    const getTitle = () => {
        switch (tab) {
            case "_usageControl":
                return t("common.label.control");
            case "_usagePower":
                return t("player.label.power");
            case "_usageCPU":
                return t("player.label.cpu");
            case "_usageMemory":
                return t("player.label.memory");
            case "_usageDisplayTemperature":
                return t("player.label.temperature");
            case "_usageStorage":
                return t("common.label.storage");
            // case "APP":
            //     return t("common.label.app");
            case "_usageInput":
                return t("player.label.input");
            case "_usageFan":
                return t("player.label.fan");
        }
    };

    const onLoadMenuCheck = async () => {
        try {
            const res = await _API.post({
                path: `/device/devices/read`,
                data: {
                    device_id: `${idx}`,
                    // features: ["_usageControl", "_usagePower", "_usageCPU", "_usageMemory", "_usageDisplayTemperature", "_usageCPUTemperature", "_usageStorage", "_usageInput", "_usageFan"],
                    features: ["_usageControl", "_usagePower", "_usageCPU", "_usageMemory", "_usageTemperature", "_usageStorage", "_usageInput", "_usageFan"],
                },
            });
            if (res) {
                setCheckTab(res?.data?.result);
                for (const f of res?.data?.result) {
                    const key = Object.keys(f)[0];
                    if (f[key]?.supported) {
                        setTab(key);
                        break;
                    }
                }
            }
        } catch (e) {
            console.log(`api error /device/devices/read ${idx}`, e);
        }
    };

    const tabRef = useRef(null);
    const onTabAreaKeyDown = (e) => {
        // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    idx = i + (e.key === "ArrowRight" ? 1 : -1);
                    return true;
                }
            });

            if (idx >= tabList.length - 1) {
                idx = tabList.length - 1;
            } else if (idx < 0) {
                idx = 0;
            }
            tabList[idx].focus();
        }
    };

    const [viewMode, setViewMode] = useState(null);
    const goDetail = (code) => {
        setViewMode(code);
    };

    useEffect(async () => {
        saveInnerLoading(true);
        await onLoadMenuCheck();
        saveInnerLoading(false);
    }, [lastUpdateTime]);

    return (
        <Fragment>
            {/* <div className="tablist-content panel-usage" role="tabpanel">
            <div className="current-tab-menu">
                <a href="#!" className="current-selected-tab" aria-expanded={tabOpen} onClick={() => setTabOpen(!tabOpen)}>
                    {getTitle()}
                </a>
            </div>

            <div className={cx({ "tab-group": true, expand: tabOpen })} role="tablist" aria-labelledby="tab_usage" ref={tabRef}>
                {checkTab?.filter((f) => f["_usageControl"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usageControl" })}>
                        <a
                            id="ez-control"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usageControl" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usageControl");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("common.label.control")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_usagePower"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usagePower" })}>
                        <a
                            id="ez-power"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usagePower" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usagePower");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("player.label.power")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_usageCPU"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usageCPU" })}>
                        <a
                            id="ez-cpu"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usageCPU" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usageCPU");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("player.label.cpu")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_usageMemory"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usageMemory" })}>
                        <a
                            id="ez-memory"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usageMemory" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usageMemory");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("player.label.memory")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_usageDisplayTemperature"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usageDisplayTemperature" })}>
                        <a
                            id="ez-temperature"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usageDisplayTemperature" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usageDisplayTemperature");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("player.label.temperature")}
                        </a>
                    </div>
                )}

                {checkTab?.filter((f) => f["_usageStorage"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usageStorage" })}>
                        <a
                            id="ez-storage"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usageStorage" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usageStorage");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("common.label.storage")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_usageInput"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usageInput" })}>
                        <a
                            id="ez-input"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usageInput" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usageInput");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("player.label.input")}
                        </a>
                    </div>
                )}
                {checkTab?.filter((f) => f["_usageFan"]?.supported)?.length > 0 && (
                    <div className={cx({ tab: true, active: tab === "_usageFan" })}>
                        <a
                            id="ez-fan"
                            href="#!"
                            role="tab"
                            aria-selected={tab === "_usageFan" ? "true" : "false"}
                            onClick={() => {
                                setTab("_usageFan");
                                setTabOpen(!tabOpen);
                            }}
                            onKeyDown={onTabAreaKeyDown}
                        >
                            {t("player.label.fan")}
                        </a>
                    </div>
                )}
            </div>
            {tab === "_usageControl" && checkTab?.filter((f) => f["_usageControl"]?.supported)?.length > 0 && <UsageControl idx={idx} />}
            {tab === "_usagePower" && checkTab?.filter((f) => f["_usagePower"]?.supported)?.length > 0 && <UsagePower idx={idx} />}
            {tab === "_usageCPU" && checkTab?.filter((f) => f["_usageCPU"]?.supported)?.length > 0 && <UsageCpu idx={idx} />}
            {tab === "_usageMemory" && checkTab?.filter((f) => f["_usageMemory"]?.supported)?.length > 0 && <UsageMemory idx={idx} />}
            {tab === "_usageDisplayTemperature" && checkTab?.filter((f) => f["_usageDisplayTemperature"]?.supported)?.length > 0 && <UsageTemperature idx={idx} />}
            {tab === "_usageStorage" && checkTab?.filter((f) => f["_usageStorage"]?.supported)?.length > 0 && <UsageStorage idx={idx} />}
            {tab === "_usageInput" && checkTab?.filter((f) => f["_usageInput"]?.supported)?.length > 0 && <UsageInput idx={idx} />}
            {tab === "_usageFan" && checkTab?.filter((f) => f["_usageFan"]?.supported)?.length > 0 && <UsageFan idx={idx} />}
        </div> */}
            {!viewMode && (
                <div className="view-history-box">
                    {checkTab?.filter((f) => f["_usageControl"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("common.label.control")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usageControl")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_usagePower"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("player.label.power")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usagePower")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_usageCPU"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("player.label.cpu")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usageCPU")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_usageMemory"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("player.label.memory")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usageMemory")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_usageTemperature"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("player.label.temperature")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usageTemperature")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_usageStorage"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("common.label.storage")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usageStorage")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_usageInput"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("player.label.input")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usageInput")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                    {checkTab?.filter((f) => f["_usageFan"]?.supported)?.length > 0 && (
                        <div className="field field-flex">
                            <span className="field-label">{t("player.label.fan")}</span>
                            <div className="field-content">
                                <button type="button" className="btn btn-details" role="" onClick={() => goDetail("_usageFan")}>
                                    {t("dv.general.powerhistory")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {viewMode === "_usageControl" && <UsageControl idx={idx} checkTab={checkTab} onBack={() => setViewMode(null)} />}
            {viewMode === "_usagePower" && <UsagePower idx={idx} checkTab={checkTab} onBack={() => setViewMode(null)} />}
            {["_usageCPU", "_usageMemory", "_usageTemperature", "_usageStorage", "_usageInput"].includes(viewMode) && (
                <UsageHistory idx={idx} checkTab={checkTab} deviceInfo={deviceInfo} onBack={() => setViewMode(null)} />
            )}
            {viewMode === "_usageFan" && <UsageFan idx={idx} checkTab={checkTab} onBack={() => setViewMode(null)} />}
        </Fragment>
    );
};

// export const Diagnostics = (props) => {
//     useCss("/assets/css/device_manage_diagnostics_led.css");
//     const { t } = useTranslation();
//     const [tab, setTab] = useState({ label: "Cell / Line Cell / Line Defect", value: "CELL" });

//     const tabRef = useRef(null);
//     const onTabAreaKeyDown = (e) => {
//         // ArrowUp, ArrowDown, ArrowLeft, ArrowRight
//         if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
//             const tabList = tabRef.current.querySelectorAll("a");
//             let idx = 0;
//             _.some(tabList, (item, i) => {
//                 if (document.activeElement === item) {
//                     idx = i + (e.key === "ArrowRight" ? 1 : -1);
//                     return true;
//                 }
//             });

//             if (idx >= tabList.length - 1) {
//                 idx = tabList.length - 1;
//             } else if (idx < 0) {
//                 idx = 0;
//             }
//             tabList[idx].focus();
//         }
//     };

//     return (
//         <div className="tablist-content panel-diagnostics" role="tabpanel">
//             <div className="current-tab-menu">
//                 <a href="#!" className="current-selected-tab" aria-expanded="false">
//                     {tab.label}
//                 </a>
//             </div>
//             <div className="tab-group" role="tablist" aria-labelledby="tab_diagnostics" ref={tabRef}>
//                 <div className={cx({ tab: true, active: tab.value === "CELL" })}>
//                     {/* <!-- 활성화된 탭은 aria-selected 속성을 t 설정합니다. --> */}
//                     <a
//                         id="cell-line-defect"
//                         href="#none"
//                         role="tab"
//                         aria-selected={tab.value === "CELL" ? "true" : "false"}
//                         onClick={() => setTab({ label: "Cell / Line Cell / Line Defect", value: "CELL" })}
//                         onKeyDown={onTabAreaKeyDown}
//                     >
//                         Cell / Line Cell / Line Defect
//                     </a>
//                 </div>
//                 <div className={cx({ tab: true, active: tab.value === "TEMPERATURE" })}>
//                     <a
//                         id="anomaly-temperature"
//                         href="#none"
//                         role="tab"
//                         aria-selected={tab.value === "TEMPERATURE" ? "true" : "false"}
//                         onClick={() => setTab({ label: "Anomaly Temperature", value: "TEMPERATURE" })}
//                         onKeyDown={onTabAreaKeyDown}
//                     >
//                         Anomaly Temperature
//                     </a>
//                 </div>
//                 <div className={cx({ tab: true, active: tab.value === "CABINET" })}>
//                     <a
//                         id="cabinet-connection"
//                         href="#none"
//                         role="tab"
//                         aria-selected={tab.value === "CABINET" ? "true" : "false"}
//                         onClick={() => setTab({ label: "Cabinet Connection", value: "CABINET" })}
//                         onKeyDown={onTabAreaKeyDown}
//                     >
//                         Cabinet Connection
//                     </a>
//                 </div>
//             </div>

//             {tab.value === "CELL" && <Cell />}
//             {tab.value === "TEMPERATURE" && <Temperature />}
//             {tab.value === "CABINET" && <Cabinet />}
//         </div>
//     );
// };

export const Broadcast = (props) => {
    const { t } = useTranslation();
    const { saveInnerLoading, rolePermission, auth, workspace, lastUpdateTime } = useContext(AppContext);
    const [registModal, setRegistModal] = useState(false);
    const [data, setData] = useState({ total_count: 0, total_pages: 0, page: 0, items: [] });
    const [result, setResult] = useState(null);
    const windowWidth = useWindowWidth();

    const fetchList = (device_id, isInit = true) => {
        const path = "/content/schedule/broadcast";
        const per_page = 10;
        const is_group = false;
        saveInnerLoading(true);
        if (isInit) {
            const page = 1;
            _API.get({
                path,
                data: {
                    page,
                    per_page,
                    is_group,
                    device_id,
                },
            })
                .then((res) => {
                    if (res?.data?.result) {
                        let {
                            result: {
                                data: items,
                                paging: { total_count },
                            },
                        } = res?.data;
                        const total_pages = Math.ceil(total_count / per_page);
                        setData({
                            ...data,
                            total_count,
                            total_pages,
                            page: 1,
                            items,
                        });
                    }
                })
                .finally(() => saveInnerLoading(false));
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            _API.get({
                path,
                data: {
                    page,
                    per_page,
                    is_group,
                    device_id,
                    last_id_key: data?.items?.length > 0 ? "id" : null,
                    last_id_value: data?.items?.length > 0 ? data.items[data.items.length - 1]?.id : null,
                },
            })
                .then((res) => {
                    if (res?.data?.result) {
                        let {
                            result: {
                                data: items,
                                paging: { total_count },
                            },
                        } = res?.data;
                        const total_pages = Math.ceil(total_count / per_page);
                        setData({
                            ...data,
                            total_count,
                            total_pages,
                            page,
                            items: [...data.items, ...items],
                        });
                    }
                })
                .finally(() => saveInnerLoading(false));
        }
    };

    const onResult = (item) => {
        setResult(item);
    };

    const onStop = (item) => {
        Dialog({ title: t("player.button.stop"), text: t("common.message.broadcast.stop"), button: [t("button.cancel"), t("player.button.stop")] }).then((ok) => {
            if (ok) {
                _API.patch({ path: `/content/schedule/broadcast/${item?.id}`, data: { activate: false } })
                    .then((res) => {
                        fetchList(props?.idx, true);
                    })
                    .catch((e) => {
                        console.log("api error ", e);
                    });
            }
        });
    };

    const onDelete = (item) => {
        Dialog({ title: t("button.delete"), text: t("device.msg.deletetheitem"), button: [t("button.cancel"), t("button.delete")] }).then((ok) => {
            if (ok) {
                _API.delete({ path: `/content/schedule/broadcast/${item?.id}` })
                    .then((res) => {
                        console.log(res);
                        fetchList(props?.idx, true);
                    })
                    .catch((e) => {
                        console.log("api error ", e);
                    });
            }
        });
    };

    useEffect(() => {
        fetchList(props?.idx, true);
    }, [props?.idx, lastUpdateTime]);

    return (
        <Fragment>
            <div className="tablist-content panel-broadcast" role="tabpanel">
                <div className="data-list-container">
                    <button type="button" className="btn" onClick={() => setRegistModal(true)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                        {t("main.btnRegister.title")}
                    </button>

                    <div className="data-list-top broadcast-list-top">
                        <div className="left-side">
                            <div className="secondary-title">{t("subtext.table.caption.BroadcastList")}</div>
                        </div>
                        <div className="right-side">
                            <div className="total-count">
                                {t("common.total")} : <span className="count">{_U.number3Comma(data?.total_count)}</span>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <div className="table-wrapper horizontal-scroll no-scroll">
                            <div className="table-box">
                                <InfiniteScroll
                                    dataLength={data ? data?.items?.length : 0}
                                    next={() => fetchList(props?.idx, false)}
                                    hasMore={windowWidth > 991}
                                    loader={""}
                                    scrollableTarget="broadcast-list-tbody"
                                >
                                    <table>
                                        <caption>{t("subtext.table.caption.BroadcastList")}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.label.BroadcastType")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("commom.popup.title")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("common.label.Execution")}</div>
                                                </th>
                                                <th scope="col">
                                                    <div className="text-block">{t("table.head.registrationdate")}</div>
                                                </th>
                                                <th scope="col" className="width-75px">
                                                    <div className="text-block">{t("table.head.result")}</div>
                                                </th>
                                                <th scope="col" className="width-75px">
                                                    <div className="text-block">{t("player.button.stop")}</div>
                                                </th>
                                                <th scope="col" className="width-75px">
                                                    <div className="text-block">{t("button.delete")}</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="broadcast-list-tbody">
                                            {data?.items?.map((item, index) => (
                                                <tr key={index}>
                                                    <td id={`broadcast-td1-${index}`}>{item?.broadcast_type}</td>
                                                    <td id={`broadcast-td2-${index}`}>{item?.title}</td>
                                                    <td id={`broadcast-td3-${index}`}>{item?.execution?.execution_type}</td>
                                                    <td id={`broadcast-td4-${index}`}>{item?.created_at ? _U.getOffsetTime(auth, workspace, item?.created_at) : "-"}</td>
                                                    <td className="align-center width-75px">
                                                        <button
                                                            type="button"
                                                            id=""
                                                            className="btn btn-detail"
                                                            onClick={() => onResult(item)}
                                                            aria-describedby={`broadcast-td1-${index} broadcast-td2-${index} broadcast-td3-${index} broadcast-td4-${index}`}
                                                        >
                                                            {t("table.head.result")}
                                                        </button>
                                                    </td>
                                                    <td className="align-center width-75px">
                                                        <button
                                                            type="button"
                                                            id=""
                                                            className="btn btn-stop"
                                                            onClick={() => onStop(item)}
                                                            aria-describedby={`broadcast-td1-${index} broadcast-td2-${index} broadcast-td3-${index} broadcast-td4-${index}`}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        >
                                                            {t("player.button.stop")}
                                                        </button>
                                                    </td>
                                                    <td className="align-center width-75px">
                                                        <button
                                                            type="button"
                                                            id=""
                                                            className="btn btn-delete"
                                                            onClick={() => onDelete(item)}
                                                            aria-describedby={`broadcast-td1-${index} broadcast-td2-${index} broadcast-td3-${index} broadcast-td4-${index}`}
                                                            disabled={_U.getAuthWriteDisable(rolePermission)}
                                                        >
                                                            {t("button.delete")}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {!data?.total_count && (
                                                <tr>
                                                    <td colSpan={7} className="align-center">
                                                        {t("devicemanagement.search.noresult")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>

                        <div className="convert-table-to-list">
                            <div className="table-header">
                                <div className="row">
                                    <div className="thead">{t("common.label.BroadcastType")}</div>
                                </div>
                            </div>

                            <InfiniteScroll
                                style={{ overflow: "hidden" }}
                                dataLength={data ? data?.items?.length : 0}
                                next={() => fetchList(props?.idx, false)}
                                hasMore={windowWidth <= 991}
                                loader={""}
                            >
                                <ul className="table-body">
                                    {data?.items?.map((item, index) => (
                                        <li className="table-row" key={`reflow-${index}`}>
                                            <div className="summary-data">
                                                <div className="row">
                                                    <div className="table-cell issue-level" aria-label="issue level">
                                                        <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                            {item?.broadcast_type}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="all-data-box">
                                                <ul className="all-data">
                                                    <li id={`convert-broadcast-td1-${index}`}>
                                                        <span className="field-label">{t("commom.popup.title")}</span>
                                                        <span className="field-content">{item?.title}</span>
                                                    </li>
                                                    <li id={`convert-broadcast-td2-${index}`}>
                                                        <span className="field-label">{t("common.label.Execution")}</span>
                                                        <span className="field-content">{item?.execution?.execution_type}</span>
                                                    </li>
                                                    <li id={`convert-broadcast-td3-${index}`}>
                                                        <span className="field-label">{t("table.head.actiondate")}</span>
                                                        <span className="field-content">{item?.execution_date ? _U.getOffsetTime(auth, workspace, item?.execution_date) : "-"}</span>
                                                    </li>
                                                    <li id={`convert-broadcast-td4-${index}`}>
                                                        <span className="field-label">{t("table.head.result")}</span>
                                                        <span className="field-content">
                                                            <button
                                                                type="button"
                                                                id=""
                                                                className="btn btn-detail"
                                                                onClick={() => onResult(item)}
                                                                aria-describedby={`convert-broadcast-td1-${index} convert-broadcast-td2-${index} convert-broadcast-td3-${index} convert-broadcast-td4-${index}`}
                                                            >
                                                                {t("table.head.result")}
                                                            </button>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("player.button.stop")}</span>
                                                        <span className="field-content">
                                                            <button
                                                                type="button"
                                                                id=""
                                                                className="btn btn-stop"
                                                                onClick={() => onStop(item)}
                                                                aria-describedby={`convert-broadcast-td1-${index} convert-broadcast-td2-${index} convert-broadcast-td3-${index} convert-broadcast-td4-${index}`}
                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                            >
                                                                {t("player.button.stop")}
                                                            </button>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="field-label">{t("button.delete")}</span>
                                                        <span className="field-content">
                                                            <button
                                                                type="button"
                                                                id=""
                                                                className="btn btn-delete"
                                                                onClick={() => onDelete(item)}
                                                                aria-describedby={`convert-broadcast-td1-${index} convert-broadcast-td2-${index} convert-broadcast-td3-${index} convert-broadcast-td4-${index}`}
                                                                disabled={_U.getAuthWriteDisable(rolePermission)}
                                                            >
                                                                {t("button.delete")}
                                                            </button>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                    {!data.total_count && (
                                        <li className="table-row">
                                            <div className="summary-data">
                                                <div className="row">{t("devicemanagement.search.noresult")}</div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
            {registModal && (
                <RegisterBroadcastPopup
                    onClose={() => setRegistModal(false)}
                    onRegister={() => {
                        setRegistModal(false);
                        fetchList(props?.idx, true);
                    }}
                    deviceId={props?.idx}
                    deviceInfo={props?.deviceInfo}
                />
            )}
            {result && <BroadcastResult deviceId={props?.idx} broadcastId={result?.id} is_broadcast={true} typeTitle={t("common.label.BroadcastType")} onClose={() => setResult(null)} />}
        </Fragment>
    );
};
export const Apps = (props) => {
    const { t } = useTranslation();
    const { saveInnerLoading, rolePermission, auth, workspace, lastUpdateTime } = useContext(AppContext);

    const [list, setList] = useState([]);

    const onUninstall = (item) => {
        Dialog({ title: t("common.uninstall"), text: t("common.message.uninstall.app"), button: [t("button.cancel"), t("common.uninstall")] }).then(async (ok) => {
            if (ok) {
                const params = [
                    {
                        appUninstall: {
                            data: {
                                value: item?.package_name,
                            },
                        },
                    },
                ];
                let res = await _API.onDeviceControl2([props?.idx], params, saveInnerLoading, t);
                if (res && res.status === "true") {
                    loadList(props?.idx);
                }
            }
        });
    };

    const loadList = (device_id) => {
        saveInnerLoading(true);

        _API.post({ path: `/device/devices/read`, data: { device_id, features: ["appList"] } })
            .then((res) => {
                const { result } = res.data;
                setList(result[0]?.appList?.data?.value);
                if (typeof resizeTable !== "undefined") {
                    resizeTable();
                }
                saveInnerLoading(false);
            })
            .catch((e) => {
                console.log("api error ", e);
            });
    };

    useEffect(() => {
        loadList(props?.idx);
    }, [props?.idx, lastUpdateTime]);

    return (
        <div className="tablist-content panel-apps" role="tabpanel">
            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side">
                        <div className="secondary-title">{t("table.summary.appmanagement")}</div>
                    </div>
                    <div className="right-side">
                        <div className="total-count">
                            {t("common.total")} : <span className="count">{_U.number3Comma(list?.length || 0)}</span>
                        </div>
                    </div>
                </div>

                <div className="table">
                    <div className="table-wrapper device-issue-list horizontal-scroll no-scroll">
                        <div className="table-box">
                            <table>
                                <caption>{t("table.summary.appmanagement")}</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="text-block">{t("common.appname")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("table.head.version")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("apps.label.size")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("device.label.runningtime")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("table.head.date")}</div>
                                        </th>
                                        <th scope="col">
                                            <div className="text-block">{t("common.uninstall")}</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list?.map((item, index) => (
                                        <tr key={index}>
                                            <td id={`apps-td1-${index}`}>{item?.app_name}</td>
                                            <td id={`apps-td2-${index}`}>{item?.version}</td>
                                            <td id={`apps-td3-${index}`}>{item?.size ? _U.number3Comma(item?.size) : 0}</td>
                                            <td id={`apps-td4-${index}`}>{item?.running_time}</td>
                                            <td id={`apps-td5-${index}`}>{item?.first_install_time ? _U.getOffsetTime(auth, workspace, item?.first_install_time) : ""}</td>
                                            <td className="align-center">
                                                <button
                                                    type="button"
                                                    id=""
                                                    className="btn"
                                                    onClick={() => onUninstall(item)}
                                                    aria-describedby={`apps-td1-${index} apps-td2-${index} apps-td3-${index} apps-td4-${index} apps-td5-${index}`}
                                                    disabled={_U.getAuthWriteDisable(rolePermission)}
                                                >
                                                    {t("common.uninstall")}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {(!list || list.length === 0) && (
                                        <tr>
                                            <td colSpan={6} className="align-center">
                                                {t("devicemanagement.search.noresult")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <div className="table-header">
                            <div className="row">
                                <div className="thead">{t("common.appname")}</div>
                            </div>
                        </div>

                        <ul className="table-body">
                            {list?.map((item, index) => (
                                <li className="table-row" key={`reflow-${index}`}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell issue-level" aria-label="issue level">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                    {item?.app_name}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li id={`convert-apps-td1-${index}`}>
                                                <span className="field-label">{t("table.head.version")}</span>
                                                <span className="field-content">{item?.version}</span>
                                            </li>
                                            <li id={`convert-apps-td2-${index}`}>
                                                <span className="field-label">{t("apps.label.size")}</span>
                                                <span className="field-content">{item?.size ? _U.number3Comma(item?.size) : 0}</span>
                                            </li>
                                            <li id={`convert-apps-td3-${index}`}>
                                                <span className="field-label">{t("device.label.runningtime")}</span>
                                                <span className="field-content">{item?.running_time}</span>
                                            </li>
                                            <li id={`convert-apps-td4-${index}`}>
                                                <span className="field-label">{t("table.head.date")}</span>
                                                <span className="field-content">{item?.first_install_time ? _U.getOffsetTime(auth, workspace, item?.first_install_time) : ""}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("common.uninstall")}</span>
                                                <span className="field-content">
                                                    <button
                                                        type="button"
                                                        id=""
                                                        className="btn"
                                                        onClick={() => onUninstall(item)}
                                                        aria-describedby={`convert-apps-td1-${index} convert-apps-td2-${index} convert-apps-td3-${index} convert-apps-td4-${index}`}
                                                        disabled={_U.getAuthWriteDisable(rolePermission)}
                                                    >
                                                        {t("common.uninstall")}
                                                    </button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                            {(!list || list.length === 0) && (
                                <li className="table-row">
                                    <div className="summary-data">
                                        <div className="row">{t("devicemanagement.search.noresult")}</div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Display = (props) => {
    const { idx } = props;
    const { t } = useTranslation();
    const { saveInnerLoading, rolePermission } = useContext(AppContext);
    const [dataList, setDataList] = useState([]);
    const [data, setData] = useState();

    const onClickAdd = () => {
        props?.onSetAddModal({ open: true, callBack: onLoadData });
    };
    const onClickRemove = (item) => {
        Dialog({ title: t("mp.remove.button"), text: t("mp.remove.detail"), button: [t("button.cancel"), t("mp.remove.button")] }).then((ok) => {
            if (ok) {
                const params = {
                    set_type: data?.set_type,
                    device_id: idx,
                    child_device_ids: [item?.device_id],
                };
                _API.get({ path: `/device/devices/selective-allocation/delete`, data: params })
                    .then((res) => {
                        onLoadData();
                    })
                    .catch((e) => {
                        console.log("api error /business/groups", e);
                    });
            }
        });
    };
    const onClickRemoveAll = () => {
        if (dataList?.length > 0) {
            Dialog({ title: t("mp.removeAll.button"), text: t("mp.removeAll.detail"), button: [t("button.cancel"), t("mp.removeAll.button")] }).then((ok) => {
                if (ok) {
                    const params = {
                        set_type: data?.set_type,
                        device_id: idx,
                        child_device_ids: dataList?.map((m) => m.device_id),
                    };
                    _API.get({ path: `/device/devices/selective-allocation/delete`, data: params })
                        .then((res) => {
                            onLoadData();
                        })
                        .catch((e) => {
                            console.log("api error /business/groups", e);
                        });
                }
            });
        }
    };
    const onAction = (key, value) => {
        let message = (
            <Fragment>
                <div className="description">{t("common.message.control.after")}</div>
                <div className="inform-txt-box">
                    <div className="description">{t("common.message.control.after2")}</div>
                </div>
            </Fragment>
        );
        if (key === "powerOn") {
            message = (
                <Fragment>
                    <div className="description">{t("common.message.control.after")}</div>
                    <div className="info">{t("common.message.control.poweron.after")}</div>
                    <div className="inform-txt-box">
                        <div className="description">{t("common.message.control.after2")}</div>
                    </div>
                </Fragment>
            );
        }
        Dialog({
            title: t("popup.title.inform"),
            body: message,
            button: t("common.ok"),
            popupClassName: { "pop-middle": true, "popup-inform": true },
        }).then(async (id) => {
            if (id) {
                const params = [];
                params.push({ [key]: { data: { value: value } } });
                let res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
            }
        });
    };

    const onLoadData = () => {
        _API.get({ path: `/device/devices/${idx}/allocation`, data: {} })
            .then((res) => {
                setData(res?.data?.result);
                setDataList(res?.data?.result?.device_list);
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
            });
    };

    useEffect(() => {
        onLoadData();
    }, [idx]);

    return (
        <Fragment>
            <div className="connected-displays-container" aria-labelledby="popup-title" aria-modal="true">
                <div className="connected-displays-header">
                    <h2 id="popup-title" className="popup-title">
                        {t("device.label.connecteddisplay")}(n)
                    </h2>
                    <button type="button" className="btn-close" onClick={props?.onClose}>
                        <span className="ir">{t("button.close")}</span>
                    </button>
                </div>
                <div className="connected-displays-content">
                    <div className="button-box">
                        <button type="button" className="btn btn-power-on" onClick={() => onAction("powerOn", "01")}>
                            {t("device.label.poweron")}
                        </button>
                        <button type="button" className="btn btn-screenx-on" onClick={() => onAction("screenOnOff", true)}>
                            {t("common.screenon")}
                        </button>
                    </div>

                    <div className="display-list-box">
                        <ul className="display-list">
                            {dataList?.map((item, index) => (
                                <li className="active" key={index}>
                                    <div className="display">
                                        <div className="display-name">
                                            {item?.model} ({item?.alias})
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-delete" onClick={() => onClickRemove(item)} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                        {t("subtext.delete.display.device")}
                                    </button>
                                </li>
                            ))}
                        </ul>

                        <div className="add-displays-container">
                            <button type="button" className="btn btn-add-display" onClick={onClickAdd} disabled={_U.getAuthWriteDisable(rolePermission)}>
                                {t("subtext.add.display.device")}
                            </button>
                            <div className="label" onClick={onClickAdd}>
                                {t("common.add")}
                            </div>
                        </div>
                    </div>

                    <div className="btn-remove-container">
                        <button type="button" className="btn btn-remove-all" onClick={onClickRemoveAll} disabled={_U.getAuthWriteDisable(rolePermission)}>
                            {t("mp.removeAll.button")}
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export const AddDisplayModal = (props) => {
    const { idx } = props;
    const { t } = useTranslation();
    const { saveInnerLoading } = useContext(AppContext);

    const [type, setType] = useState(null);

    const onClickDone = async () => {
        if (type === 1) {
            const params = [{ connectDevice: { data: null } }];
            let res = await _API.onDeviceControl2([idx], params, saveInnerLoading, t);
            if (res && res.status === "true") {
                addAfterAlert();
            }
        }
    };

    const addAfterAlert = () => {
        Dialog({ title: t("popup.title.inform"), text: t("add.display.complete"), button: t("common.ok") }).then((ok) => {
            if (ok) {
            }
        });
        props?.onClose();
    };

    const onAddSelectModal = () => {
        props?.onAddSelectModal();
        props?.onClose();
    };

    return (
        <Modal title={t("mp.add.displays")} id="popup-select-file" className="popup-add-display popup-type02" onClose={props?.onClose} onClick={onClickDone}>
            <div className="field field-type-radio field-add-display">
                <label className="field-label">{t("mp.add.detail")}</label>
                <div className="field-form">
                    <div className="field-box not-flex">
                        <div className="radio pop-radio">
                            <input type="radio" name="radio-add-display" id="radio-add-display-1" value="1" checked={type === 1} onChange={() => setType(1)} />
                            <label htmlFor="radio-add-display-1">{t("mp.auto.title")}</label>
                            <span id="" className="field-description">
                                {t("subtext.device.message.mp.ipsearch")}
                            </span>
                        </div>
                        <div className="radio pop-radio">
                            <input type="radio" name="radio-add-display" id="radio-add-display-2" value="2" checked={type === 2} onChange={onAddSelectModal} />
                            <label htmlFor="radio-add-display-2">{t("mp.manual.title")}</label>
                            <span id="" className="field-description">
                                {t("subtext.device.message.mp.list")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const AddDisplaySelectModal = (props) => {
    const { deviceInfo } = props;
    const { t } = useTranslation();
    const [dataList, setDataList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const onClickDone = () => {
        if (dataList.filter((f) => f.checked)?.length > 0 && deviceInfo?.device_id) {
            const params = {
                set_type: "set_selective",
                device_id: deviceInfo.device_id,
                child_device_ids: dataList.filter((f) => f.checked).map((m) => m.device_id),
            };
            _API.post({ path: `/device/devices/selective-allocation`, data: params })
                .then((res) => {
                    Dialog({ title: t("popup.title.inform"), text: t("add.display.complete"), button: t("common.ok") }).then((ok) => {
                        props?.onClose();
                    });
                })
                .catch((e) => {
                    console.log("api error /business/groups", e);
                });
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("editor.msg.selectedElement", { 0: "player.label.physical" }),
                button: t("common.ok"),
            }).then((ok) => {
                if (ok) {
                }
            });
        }
    };

    const onLoadData = () => {
        _API.get({ path: `/device/devices/${deviceInfo.device_id}/group`, data: {} })
            .then((res) => {
                setDataList(res?.data?.result?.device_list);
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
            });
    };

    const onCheckAll = () => {
        const newList = dataList?.map((m) => ({ ...m, checked: !checkAll }));
        setDataList(newList);
        setCheckAll(!checkAll);
    };

    const onCheck = (item) => {
        const newList = dataList?.map((m) => (item && m.device_id === item?.device_id ? { ...m, checked: !item?.checked } : m));
        setDataList(newList);
        if (newList.filter((f) => f.checked === true)?.length === newList.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    };

    useEffect(() => {
        deviceInfo && onLoadData();
        console.log("deviceInfo", deviceInfo);
    }, [deviceInfo]);

    return (
        <Modal title={t("mp.manual.title")} id="popup-select-file" className="popup-select-device middle" onClose={props?.onClose} onClick={onClickDone}>
            <div className="txt-group">
                <p className="description">{t("mp.manual.confirm")} </p>
                <div className="info">* {deviceInfo?.hierarchy_group && deviceInfo?.hierarchy_group !== "Unassigned" ? deviceInfo?.hierarchy_group : deviceInfo?.group_name}</div>
            </div>

            <div className="data-list-container">
                <div className="data-list-top">
                    <div className="left-side"></div>
                    <div className="right-side">
                        <div className="selected-count">
                            {t("dv.table.selected")} <span className="count">{dataList?.filter((f) => f.checked)?.length || 0}</span>
                        </div>
                        <div className="total-count">
                            {t("common.total")} : <span className="count">{dataList?.length || 0}</span>
                        </div>
                    </div>
                </div>

                <div className="data-list-bottom">
                    <div className="table">
                        <div className="table-wrapper device-table no-scroll">
                            <div className="table-box">
                                <table className="">
                                    <caption>{t("subtext.table.caption.device.discovered.list")}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="check" width="50">
                                                <div className="field field-type-checkbox">
                                                    <div className="field-form">
                                                        <div className="checkbox">
                                                            <input type="checkbox" name="checkbox-select-all" id="checkbox-select-all" checked={checkAll} onChange={onCheckAll} />
                                                            <label htmlFor="checkbox-select-all">
                                                                <span className="ir">{t("common.selectAll")}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("deivcemanagement.title.connection")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("player.label.modelName")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("table.head.devicename")}</div>
                                            </th>
                                            <th scope="col">
                                                <div className="text-block">{t("common.label.serialnumber")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList?.map((item, index) => (
                                            <tr key={index}>
                                                <td className="center" width="50">
                                                    <div className="field field-type-checkbox field-select-device">
                                                        <div className="field-form">
                                                            <div className="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    name="addSelectDevice"
                                                                    id={`checkbox-${item?.device_id}`}
                                                                    aria-label={`${t("dashboard.check")} ${item?.alias}`}
                                                                    checked={item?.checked || false}
                                                                    onChange={(e) => onCheck(item)}
                                                                />
                                                                <label htmlFor={`checkbox-${item?.device_id}`}>
                                                                    <span className="ir">{t("select.row.desc")}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{_U.getConnectionStatusList(t).filter((f) => f.value === item?.connection_status)[0]?.label || ""}</td>
                                                <td>{item?.model}</td>
                                                <td>{item?.alias}</td>
                                                <td>{item?.serial_number}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="convert-table-to-list">
                        <div className="table-header">
                            <div className="row">
                                <div className="thead">
                                    <div className="field field-type-checkbox">
                                        <div className="field-form">
                                            <div className="checkbox">
                                                <input type="checkbox" name="checkbox-select-all-reflow" id="checkbox-select-all-reflow" checked={checkAll} onChange={onCheckAll} />
                                                <label htmlFor="checkbox-select-all-reflow">
                                                    <span className="ir">{t("common.selectAll")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="thead">{t("deivcemanagement.title.connection")}</div>
                            </div>
                        </div>

                        <ul className="table-body">
                            {dataList?.map((item, index) => (
                                <li className="table-row" key={`convert-${index}`}>
                                    <div className="summary-data">
                                        <div className="row">
                                            <div className="table-cell number center">
                                                <div className="field field-type-checkbox field-select-device">
                                                    <div className="field-form">
                                                        <div className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="convert-addSelectDevice"
                                                                id={`convert-checkbox-${item?.device_id}`}
                                                                aria-label={`${t("dashboard.check")} ${item?.alias}`}
                                                                checked={item?.checked || false}
                                                                onChange={(e) => onCheck(item)}
                                                            />
                                                            <label htmlFor={`convert-checkbox-${item?.device_id}`}>
                                                                <span className="ir">{t("select.row.desc")}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-cell issue-level">
                                                <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={reflowViewExpand}>
                                                    {_U.getConnectionStatusList(t).filter((f) => f.value === item?.connection_status)[0]?.label || ""}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="all-data-box">
                                        <ul className="all-data">
                                            <li>
                                                <span className="field-label">{t("player.label.modelName")}</span>
                                                <span className="field-content">{item?.model}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("table.head.devicename")}</span>
                                                <span className="field-content">{item?.alias}</span>
                                            </li>
                                            <li>
                                                <span className="field-label">{t("common.label.serialnumber")}</span>
                                                <span className="field-content">{item?.serial_number}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const GroupModal = (props) => {
    const { t } = useTranslation();

    const { checkList } = props;
    const [group, setGroup] = useState(null);
    const [groupList, setGroupList] = useState([]);

    const getGroupList = () => {
        _API.get({ path: "/business/groups", data: {} })
            .then((res) => {
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    const newGroupList = [];
                    res?.data?.result?.forEach((m) => convertGroupFormat(m, newGroupList));
                    setGroupList(newGroupList);
                    const currentGroup = newGroupList?.filter((f) => f.group_id == props?.currentGroupId)[0];
                    setGroup(currentGroup);
                }
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
            });
    };

    const convertGroupFormat = (item, newList) => {
        if (item) {
            newList.push({
                ...item,
                label: [...Array(item?.level)].join("ㅤ") + (item?.level > 1 ? "ㄴ" : "") + item?.group_name,
                value: item?.group_id,
            });

            item?.lower_groups?.forEach((f) => {
                convertGroupFormat(f, newList);
            });
        }
    };

    const changeGroup = (groupId, deviceIds) => {
        _API.put({
            path: `/business/groups/models/${props?.currentGroupId || ""}`,
            data: {
                group_id: group?.group_id,
                device_ids: deviceIds,
            },
        })
            .then((res) => {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("device.message.group.change.success"),
                    button: t("common.ok"),
                }).then((id) => {
                    props?.onClose && props?.onClose();
                    props?.onDone && props?.onDone();
                });
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
            });
    };

    const onDone = () => {
        // props?.onClose
        if (group && checkList?.length > 0) {
            changeGroup(
                group?.group_id,
                checkList?.map((m) => m.device_id)
            );
        }
    };

    useEffect(() => {
        getGroupList();
    }, []);

    return (
        <Modal title={t("common.button.change.group")} id="popup-change-group" className="popup-change-group popup-type no-scroll" onClose={props?.onClose} onClick={onDone}>
            <div className="field field-type-input field-group-name">
                <label htmlFor="name" className="field-label pop-label">
                    {t("admin.label.devicegroup")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <SelectBox items={groupList} value={group?.value} selectLabel={group?.label || ""} onChange={(v) => setGroup(v)} title={t("device.label.selectgroup")} />
                    </div>
                    {/* <span id="description-name" className="field-description">
                        사용할 그룹명을 입력해주세요.
                    </span> */}
                </div>
            </div>
        </Modal>
    );
};

export const NameModal = (props) => {
    const { t } = useTranslation();
    const { saveInnerLoading } = useContext(AppContext);
    const [deviceName, setDeviceName] = useState("");
    const onEdit = async () => {
        if (deviceName) {
            const params = [{ deviceName: { data: { value: deviceName } } }];
            let res = await _API.onDeviceControl2([props?.data?.device_id], params, saveInnerLoading, t);
            if (res && res.status === "true") {
                onSaveAfter();
            }
        } else {
            Dialog({
                title: t("popup.title.inform"),
                text: t("device.message.edit.device.name"),
                button: t("common.ok"),
            }).then((id) => {});
        }
    };

    const onSaveAfter = () => {
        props?.onLoadData && props?.onLoadData();
        props?.onClose && props?.onClose();
    };

    useEffect(() => {
        setDeviceName(props?.data?.device_name);
    }, [props?.data]);
    return (
        <Modal title={t("popup.title.edit.device.name")} id="popup-select-file" className="popup-edit-device-name popup-type no-scroll" onClose={props?.onClose} onClick={() => onEdit()}>
            <div className="form-guide algin-left">
                <span>*</span> {t("common.label.isrequired")}
            </div>
            <div className="field field-type-input field-unique-class required">
                <label htmlFor="name" className="field-label pop-label">
                    {t("devicemanagement.title.deviceName")}
                </label>
                <div className="field-form">
                    <div className="field-box">
                        <div className="input-box pop-input">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={deviceName}
                                autocomplete="false"
                                onChange={(e) => {
                                    let val = e?.target?.value?.trimStart();
                                    setDeviceName(val);
                                }}
                                // aria-invalid="true"
                                // aria-describedby="edit-device-name-description-name"
                                // aria-errormessage="edit-device-name-error-name"
                                aria-required="true"
                            />
                            <button type="button" className={cx({ "btn-input-remove": true, hide: deviceName ? false : true })} onClick={() => setDeviceName("")}>
                                <span className="ir">{t("alertmsg.template.popup.delete_input")}</span>
                            </button>
                            {/* <span id="edit-device-name-description-name" className="field-description hide">
                                {t("subtext.message.devicename")}
                            </span>
                            <span id="edit-device-name-error-name" role="alert" className="field-description error hide">
                                {t("subtext.message.Invalidformat")}
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const FullModal = (props) => {
    const { t } = useTranslation();
    const { checkTab, onRemoteControl } = props;
    return (
        <Modal
            title={
                <Fragment>
                    <span>{t("common.label.screenshot")}</span> {props?.deviceInfo?.device_name}
                </Fragment>
            }
            id="popup-select-file"
            className="popup-screenshot popup-fulll"
            onClose={props?.onClose}
            onClick={() => alert("save")}
            noFooter
            topClose
        >
            <div className="full-screen">
                <img
                    className="img"
                    src={props && props?.deviceInfo?.thumbnail_url?.large ? props?.deviceInfo?.thumbnail_url?.large : "/assets/images/sample/full_img.png"}
                    alt={t("subtext.device.monitor.image")}
                    onError={(e) => (e.target.src = "/assets/images/sample/full_img.png")}
                />
                <div className="control-memu">
                    {(checkTab?.filter((f) => f["__remoteControl"])[0]?.["__remoteControl"]?.supported || checkTab?.filter((f) => f["__remoteAccess"])[0]?.["__remoteAccess"]?.supported) && (
                        <button type="button" className="btn-icon btn-remote" role="" onClick={onRemoteControl}>
                            <span className="ir">{t("subtext.device.detail.remoteControl")}</span>
                        </button>
                    )}
                    <button type="button" className="btn-icon btn-refresh" role="list" onClick={props?.onScreenRefresh}>
                        <span className="ir">{t("common.button.screen.refresh")}</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const NetworkModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal title={t("common.label.NetworkDetails")} id="popup-select-file" className="popup-network-details" onClose={props?.onClose} noFooter ok>
            <div className="">
                <div className="list-box pop-list-box">
                    {_Device.getSupported("networkType", props?.data) && (
                        <div className="field field-flex field-input">
                            <span className="field-label">{t("common.type")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["networkType"])[0]?.["networkType"]?.data?.value}</span>
                        </div>
                    )}
                    {_Device.getSupported("macAddress", props?.data) && (
                        <div className="field field-flex field-mac-address">
                            <span className="field-label">{t("player.label.mac")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["macAddress"])[0]?.["macAddress"]?.data?.value}</span>
                        </div>
                    )}

                    {_Device.getSupported("ipAddress", props?.data) && (
                        <div className="field field-flex field-ip-address">
                            <span className="field-label">{t("table.head.ipaddress")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["ipAddress"])[0]?.["ipAddress"]?.data?.value}</span>
                        </div>
                    )}
                    {_Device.getSupported("subnetMask", props?.data) && (
                        <div className="field field-flex field-subnet-mask">
                            <span className="field-label">{t("player.label.subnet")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["subnetMask"])[0]?.["subnetMask"]?.data?.value}</span>
                        </div>
                    )}
                    {_Device.getSupported("defaultGateway", props?.data) && (
                        <div className="field field-flex field-default-gateway">
                            <span className="field-label">{t("player.label.gateway")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["defaultGateway"])[0]?.["defaultGateway"]?.data?.value}</span>
                        </div>
                    )}
                    {_Device.getSupported("primaryDns", props?.data) && (
                        <div className="field field-flex field-primary-dns">
                            <span className="field-label">{t("player.label.primaryDns")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["primaryDns"])[0]?.["primaryDns"]?.data?.value}</span>
                        </div>
                    )}
                    {_Device.getSupported("responseTime", props?.data) && (
                        <div className="field field-flex field-response-time">
                            <span className="field-label">{t("device.label.responsetime")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["responseTime"])[0]?.["responseTime"]?.data?.value}</span>
                        </div>
                    )}
                    {_Device.getSupported("macAddress", props?.data) && (
                        <div className="field field-flex field-input">
                            <span className="field-label">{t("common.label.Rssi")}</span>
                            <span className="field-content">
                                {props?.data?.filter((f) => f["rssi"])[0]?.["rssi"]?.data?.value ? JSON.stringify(props?.data?.filter((f) => f["rssi"])[0]?.["rssi"]?.data?.value) : "-"}
                            </span>
                        </div>
                    )}
                    {_Device.getSupported("ipv6Address", props?.data) && (
                        <div className="field field-flex field-ipv6-address">
                            <span className="field-label">{t("device.label.ipv6address")}</span>
                            <span className="field-content">{props?.data?.filter((f) => f["ipv6Address"])[0]?.["ipv6Address"]?.data?.value}</span>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export const RemoteModal = (props) => {
    const { deviceInfo } = props;
    useCss("/assets/css/device_manage_remote_control.css");
    const { t } = useTranslation();
    const { saveInnerLoading } = useContext(AppContext);
    const [hoverButtonId, setHoverButtonId] = useState("");

    const onControl = async (code) => {
        const params = [{ [code]: { data: { value: true } } }];
        let res = await _API.onDeviceControl2([deviceInfo?.device_id], params, saveInnerLoading, t);
        props?.onScreenRefresh && props?.onScreenRefresh();
    };

    const onRefresh = () => {
        saveInnerLoading && saveInnerLoading(true);
        props?.onScreenRefresh && props?.onScreenRefresh();
    };

    return (
        <Modal
            title={
                <Fragment>
                    <span>{t("common.label.screenshot")}</span> {props?.deviceInfo?.device_name}
                </Fragment>
            }
            id="popup-select-file"
            className="popup-screenshot popup-remote-control"
            onClose={props?.onClose}
            noFooter
            topClose
        >
            <div className="remote-control">
                <div className="remote-control-box">
                    <div className="display-current-device full-screen">
                        <img
                            className="img"
                            src={props?.deviceInfo?.thumbnail_url?.large ? props?.deviceInfo?.thumbnail_url?.large : "/assets/images/sample/full_img.png"}
                            alt={t("subtext.device.monitor.image")}
                            onError={(e) => (e.target.src = "/assets/images/sample/full_img.png")}
                        />
                        <div className="control-memu">
                            <button type="button" className="btn-icon btn-remote" role="list" onClick={props?.onClose}>
                                <span className="ir">{t("subtext.go.previous.screen")}</span>
                            </button>
                            <button type="button" className="btn-icon btn-refresh" role="list" onClick={onRefresh}>
                                <span className="ir">{t("common.button.screen.refresh")}</span>
                            </button>
                        </div>
                    </div>
                    <div id="remote-controller-id" className="ir">
                        {t("common.label.RemoteController")}
                    </div>
                    <div id="remote-controller-description" className="ir">
                        {t("subtext.remote.control")}
                    </div>

                    <div className="remote-controller" aria-labelledby="remote-controller-id" aria-describedby="remote-controller-description">
                        <div className="remote_top tooltip-container">
                            <button
                                type="button"
                                className={cx({ "btn-normal": true, "btn-power": true, "tooltip-label": true, hover: hoverButtonId === "btn-remotecontrol-power" })}
                                onMouseEnter={() => setHoverButtonId("btn-remotecontrol-power")}
                                onMouseLeave={() => setHoverButtonId("")}
                                onFocus={() => setHoverButtonId("btn-remotecontrol-power")}
                                onBlur={() => setHoverButtonId("")}
                            >
                                <TooltipTop txt={t("device.label.notsupported")} widthClass="width-23-rem" />
                                <span className="ir">{t("player.label.power")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-energy-saving" onClick={() => onControl("keyEnergySaving")}>
                                <span className="ir">{t("subtext.energy.saving")}</span>
                            </button>
                        </div>
                        <div className="monitor">
                            <button type="button" className="btn-normal btn-monitor-on" onClick={() => onControl("monitorOn")}>
                                <span className="ir">{t("subtext.monitor.on")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-monitor-off" onClick={() => onControl("monitorOff")}>
                                <span className="ir">{t("subtext.monitor.off")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-green" onClick={() => onControl("keyEnergySaving")}>
                                <span className="ir">{t("subtext.energy.saving")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-3d" onClick={() => onControl("key3d")}>
                                <span className="ir">{t("subtext.button.3d")}</span>
                            </button>
                        </div>
                        <div className="numeric">
                            <button type="button" className="btn-normal btn-one btn-J" onClick={() => onControl("key1")}>
                                <span className="ir">{t("subtext.number.one")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-two btn-abc" onClick={() => onControl("key2")}>
                                <span className="ir">{t("subtext.number.two")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-three btn-def" onClick={() => onControl("key3")}>
                                <span className="ir">{t("subtext.number.three")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-four btn-ghi" onClick={() => onControl("key4")}>
                                <span className="ir">{t("subtext.number.four")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-five btn-JKL" onClick={() => onControl("key5")}>
                                <span className="ir">{t("subtext.number.five")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-six btn-mno" onClick={() => onControl("key6")}>
                                <span className="ir">{t("subtext.number.six")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-seven btn-pqrs" onClick={() => onControl("key7")}>
                                <span className="ir">{t("subtext.number.seven")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-eight btn-tuv" onClick={() => onControl("key8")}>
                                <span className="ir">{t("subtext.number.eight")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-nine btn-wxyz" onClick={() => onControl("key9")}>
                                <span className="ir">{t("subtext.number.nine")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-change-character" onClick={() => onControl("key1aA")}>
                                <span className="ir">{t("subtext.char.conversion")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-zero" onClick={() => onControl("key0")}>
                                <span className="ir">{t("subtext.number.zero")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-clear" onClick={() => onControl("keyClear")}>
                                <span className="ir">{t("subtext.button.clear")}</span>
                            </button>
                        </div>
                        <div className="volume_and_channel">
                            <div className="column">
                                <button type="button" className="btn-normal btn-plus" onClick={() => onControl("keyPlus")}>
                                    <span className="ir">{t("subtext.volume.up")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-minus" onClick={() => onControl("keyMinus")}>
                                    <span className="ir">{t("subtext.volume.down")}</span>
                                </button>
                            </div>
                            <div className="column">
                                <button type="button" className="btn-normal btn-arc" onClick={() => onControl("keyArc")}>
                                    <span className="ir">{t("subtext.button.arc")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-auto" onClick={() => onControl("keyAuto")}>
                                    <span className="ir">{t("subtext.button.auto")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-mute" onClick={() => onControl("keyMute")}>
                                    <span className="ir">{t("subtext.button.mute")}</span>
                                </button>
                            </div>
                            <div className="column">
                                <button type="button" className="btn-normal btn-brightness" onClick={() => onControl("keyBrightnessUp")}>
                                    <span className="ir">{t("subtext.brightness.up")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-darkness" onClick={() => onControl("keyBrightnessDown")}>
                                    <span className="ir">{t("subtext.brightness.down")}</span>
                                </button>
                            </div>
                        </div>

                        <div className="features_and_arrow">
                            <div className="row row-1">
                                <button type="button" className="btn-normal btn-psm" onClick={() => onControl("keyPsm")}>
                                    <span className="ir">{t("subtext.button.psm")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-home" onClick={() => onControl("keyHome")}>
                                    <span className="ir">{t("subtext.button.home")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-w_bal" onClick={() => onControl("keyWBal")}>
                                    <span className="ir">{t("subtext.button.wbal")}</span>
                                </button>
                            </div>
                            <div className="row row-2">
                                <button type="button" className="btn-normal btn-setting" onClick={() => onControl("keySetting")}>
                                    <span className="ir">{t("subtext.button.setting")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-s_menu" onClick={() => onControl("keySMenu")}>
                                    <span className="ir">{t("subtext.button.smenu")}</span>
                                </button>
                            </div>
                            <div className="row row-3">
                                <button type="button" className="btn-normal btn-up" onClick={() => onControl("keyUp")}>
                                    <span className="ir">{t("subtext.move.up")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-left" onClick={() => onControl("keyLeft")}>
                                    <span className="ir">{t("subtext.move.left")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-ok" onClick={() => onControl("keyOk")}>
                                    <span className="ir">{t("subtext.button.ok")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-right" onClick={() => onControl("keyRight")}>
                                    <span className="ir">{t("subtext.move.right")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-down" onClick={() => onControl("keyDown")}>
                                    <span className="ir">{t("subtext.move.down")}</span>
                                </button>
                            </div>
                            <div className="row row-4">
                                <button type="button" className="btn-normal btn-go-back" onClick={() => onControl("keyBack")}>
                                    <span className="ir">{t("subtext.button.return")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-exit" onClick={() => onControl("keyExit")}>
                                    <span className="ir">{t("subtext.button.exit")}</span>
                                </button>
                            </div>
                            <div className="row row-5">
                                <button type="button" className="btn-normal btn-simplink" onClick={() => onControl("keySimpleLink")}>
                                    <span className="ir">{t("subtext.button.simplink")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-tile" onClick={() => onControl("keyTile")}>
                                    <span className="ir">{t("subtext.button.tile")}</span>
                                </button>
                                <button type="button" className="btn-normal btn-stop" onClick={() => onControl("keyStop")}>
                                    <span className="ir">{t("subtext.button.stop")}</span>
                                </button>
                            </div>
                        </div>
                        <div className="control">
                            <button type="button" className="btn-normal btn-rewind" onClick={() => onControl("keyRewind")}>
                                <span className="ir">{t("subtext.button.reverse")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-play" onClick={() => onControl("keyPlay")}>
                                <span className="ir">{t("subtext.button.play")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-pause" onClick={() => onControl("keyPause")}>
                                <span className="ir">{t("subtext.button.pause")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-forward" onClick={() => onControl("keyFastForward")}>
                                <span className="ir">{t("subtext.button.skip")}</span>
                            </button>
                        </div>
                        <div className="picture-id">
                            <button type="button" className="btn-normal btn-red" onClick={() => onControl("keyRed")}>
                                <span className="ir">{t("subtext.pictureID.on")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-green" onClick={() => onControl("keyGreen")}>
                                <span className="ir">{t("subtext.pictureID.off")}</span>
                            </button>
                            <button type="button" className="btn-normal btn-blue" onClick={() => onControl("keyBlue")}>
                                <span className="ir">{t("subtext.button.blue")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
