import React, { useContext, useEffect, useRef, useState } from "react";
import _CK from "@util/cookie";
import _API from "@util/api";
import cx from "classnames";
import Modal from "@component/UI/Modal";
import { AppContext } from "@component/AppProvider";
import axios from "axios";
import utils from "@util/utilities";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import Dialog from "@component/UI/Dialog";
import _U from "@util/utilities";

const ITEMS_PER_PAGE = 10;
const SERVICE_TYPE = "schedule";
const MAX_SIZE = 300; // MB 기준

const FileboxToolbar = ({ onClose }) => {
    const { t } = useTranslation();
    const { workspace, rolePermission, auth } = useContext(AppContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const [search, setSearch] = useState("");
    const [tab, setTab] = useState("IMAGE");
    const [fileList, setFileList] = useState({
        total: 0,
        items: [],
        totalSize: "0KB",
        totalSizeNumber: 0,
    });

    const [page, setPage] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const fileUpload = useRef(null);
    const tabRef = useRef(null);

    const onTabAreaKeyDown = (e) => {
        if (e.key === "Tab") {
            if (e.shiftKey) {
                e.preventDefault();
                document.getElementById("btn-fb-close").focus();
                return;
            }
            const tabList = tabRef.current.querySelectorAll("a");
            const index = 0;
            const nextTab = tabList[index + 4];
            if (nextTab) {
                nextTab.focus();
            }
        } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            const tabList = tabRef.current.querySelectorAll("a");
            let idx = 0;
            _.some(tabList, (item, i) => {
                if (document.activeElement === item) {
                    if (e.key === "ArrowLeft") {
                        idx = i - 1;
                    } else if (e.key === "ArrowRight") {
                        idx = i + 1;
                    }
                    return true;
                }
            });
            if (idx >= tabList.length) {
                idx = 0;
            } else if (idx < 0) {
                idx = tabList.length - 1;
            }
            tabList[idx].focus();
        }
    };

    const getTabText = () => {
        switch (tab) {
            case "IMAGE":
                return t("filebox.label.image");
            case "VIDEO":
                return t("filebox.label.video");
            case "AUDIO":
                return t("filebox.label.music");
            case "FILE":
                return t("filebox.label.file");
            case "APPS":
                return t("filebox.label.app");
        }
    };

    const onDownloadClick = async (item) => {
        const url = await utils.getFileDownloadUrl(item?.Key);
        if (url) window.open(url);
    };

    const onDeleteClick = (item) => {
        Dialog({
            title: t("button.delete"),
            text: t("common.msg.delete.file"),
            button: [t("button.cancel"), t("button.delete")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            const { Key } = item;
            _API.delete({
                path: `/content/filebox/files?file_path=${Key}`,
            }).then(() => {
                fetchFileData(tab);
            });
        });
    };

    const onUploadClick = () => fileUpload.current.click();

    const handleUploadFileChange = async (event) => {
        const allowedExtensions = {
            IMAGE: ["bmp", "jpeg", "jpg", "gif", "png", "raw"],
            VIDEO: ["mp4", "avi", "wmv", "mpeg", "mkv", "mov", "webm", "3gpp"],
            AUDIO: ["mp3", "flac", "wav", "aac", "wmv"],
            APPS: ["apk"],
        };
        const allowedExtensionsForTab = allowedExtensions[tab.toUpperCase()];
        const existingFiles = fileList.items.map((item) => item.Key.split("/").pop());
        const files = event.target.files;
        const fileNames = Array.from(files)?.map((file) => file.name);
        const duplicateFiles = fileNames.filter((fileName) => existingFiles.includes(fileName));
        let totalSize = fileList.totalSizeNumber;
        let isValid = true;
        let isFileExtensionValid = true;

        for (let i = 0; i < files?.length; i++) {
            totalSize += files[i]?.size;
            const fileExtension = files[i]?.name?.split(".")?.pop()?.toLowerCase();
            if (allowedExtensionsForTab && !allowedExtensionsForTab?.includes(fileExtension)) {
                isFileExtensionValid = false;
                break;
            }
            if (files[i]?.size > MAX_SIZE * 1024 * 1024) {
                isValid = false;
                break;
            }
            const check = _U.checkFileName(files[i]?.name);
            if (check === -2) {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.message.over100.filename"),
                    button: t("common.ok"),
                }).then((id) => {});
                return;
            } else if (check === -1) {
                Dialog({
                    title: t("popup.title.inform"),
                    text: t("common.message.NoSpecialCharacters"),
                    button: t("common.ok"),
                }).then((id) => {});
                return;
            }
        }
        if (duplicateFiles.length > 0) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("subtext.error.sameFileName"),
                button: t("common.ok"),
            }).then((id) => {});
            event.target.value = null;
            return;
        }
        if (!isFileExtensionValid) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("subtext.error.notSupportedFormat"),
                button: t("common.ok"),
            }).then((id) => {});
            event.target.value = null;
            return;
        }
        if (!isValid) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.label.FileSmallerThan", { 0: MAX_SIZE + "MB" }),
                button: t("common.ok"),
            }).then((id) => {});
            event.target.value = null;
            return;
        }
        // 만약 토탈 사이즈가 1GB 이상이라면
        if (totalSize >= 1073741824) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("common.msg.file.total.exceed"),
                button: t("common.ok"),
            }).then((id) => {});
            event.target.value = null;
            return;
        }
        if (isValid) {
            const file = files[0];
            const fileExtension = file.name.split(".").pop().toLowerCase();
            let type = "";
            Object.keys(allowedExtensions).forEach((key) => {
                if (allowedExtensions[key].includes(fileExtension)) {
                    type = key;
                }
            });
            if (!type) type = "file";
            type = type.toLowerCase();
            event.target.value = null;
            let data = {
                property_id: workspace?.property_id,
                file_name: file.name,
                type,
            };
            if (type === "apps") {
                const package_name = await _U.getPackageName(file);
                data.package_name = package_name;
            }
            _API.get({
                path: "/content/filebox/files/signed-url",
                data,
            })
                .then((response) => {
                    const { upload_url } = response?.data?.result?.data;
                    const dxpId = _CK.get("DXPID");
                    axios({
                        method: "put",
                        url: upload_url,
                        data: file, // 파일 자체를 데이터로 보냄
                        withCredentials: true,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Credentials": true,
                            "X-Frame-Options": "DENY",
                            "x-content-type-options": "nosniff",
                            "Cache-Control": "public, max-age=604800, immutable",
                        },
                    })
                        .then((res) => {
                            fetchFileData(tab);
                        })
                        .catch((err) => {
                            console.error("File S3 Upload failed", err);
                        });
                })
                .catch((e) => {
                    console.log("api error /content/filebox/files/signed-url", e);
                });
        }
    };

    const toggleShow = (index) => {
        setFileList((prevState) => {
            const newItems = [...prevState.items];
            newItems[index] = {
                ...newItems[index],
                isShow: !newItems[index].isShow,
            };
            return {
                ...prevState,
                items: newItems,
            };
        });
    };

    const resetFileList = () => {
        setFileList({
            ...fileList,
            total: 0,
            items: [],
            totalSize: "0KB",
            totalSizeNumber: 0,
        });
    };

    const fetchFileData = (type) => {
        type = type.toLowerCase();
        _API.get({
            path: "/content/filebox/files",
            data: { property_id: workspace?.property_id, type },
        }).then((res) => {
            let { result } = res?.data;
            let totalSize = 0;
            if (result?.Contents?.length) {
                const { Contents } = result;
                Contents.sort((a, b) => {
                    return new Date(b.LastModified) - new Date(a.LastModified);
                });
                Contents.forEach((content) => {
                    totalSize += content.Size || 0;
                    if (!content.hasOwnProperty("isShow")) {
                        content.isShow = false;
                    }
                });
                console.log(Contents);
                setFileList({
                    ...fileList,
                    total: Contents.length,
                    totalSize: utils.byteSizeParse(totalSize),
                    totalSizeNumber: totalSize,
                    items: Contents,
                });
            } else {
                resetFileList();
            }
        });
    };

    const getFileListPerPage = () => {
        const startIndex = 0;
        const endIndex = startIndex + ITEMS_PER_PAGE * page;
        if (!fileList) return [];
        const resultFileList = fileList.items
            .filter((file) => {
                const fileName = file?.Key?.split("/")?.pop();
                return fileName.toLowerCase().includes(search.toLowerCase());
            })
            .slice(startIndex, endIndex);

        return resultFileList;
    };

    const hasNext = () => {
        if (getFileListPerPage()?.length < fileList.items.length) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        resetFileList();
        fetchFileData(tab);
        if (ref1.current) ref1.current.scrollTop = 0;
        if (ref2.current) ref2.current.scrollTop = 0;
        setPage(1);
    }, [tab]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    return (
        <Modal title={t("admin.label.filebox")} id="popup-file-box" className="fullpopup" onClose={onClose} noFooter close>
            <div className="tablist-content is-tab" role="tabpanel">
                <div className="tab-group type-default pc-only" role="tablist" ref={tabRef}>
                    <div className={cx({ tab: true, active: tab === "IMAGE" })} onKeyDown={onTabAreaKeyDown}>
                        <a id="file-image" href="#!" role="tab" aria-selected={tab === "IMAGE" ? "true" : "false"} onClick={() => setTab("IMAGE")}>
                            {t("filebox.label.image")}
                        </a>
                    </div>
                    <div className={cx({ tab: true, active: tab === "VIDEO" })} onKeyDown={onTabAreaKeyDown}>
                        <a id="file-video" href="#!" role="tab" aria-selected={tab === "VIDEO" ? "true" : "false"} onClick={() => setTab("VIDEO")}>
                            {t("filebox.label.video")}
                        </a>
                    </div>
                    <div className={cx({ tab: true, active: tab === "AUDIO" })} onKeyDown={onTabAreaKeyDown}>
                        <a id="file-audio" href="#!" role="tab" aria-selected={tab === "AUDIO" ? "true" : "false"} onClick={() => setTab("AUDIO")}>
                            {t("filebox.label.music")}
                        </a>
                    </div>
                    <div className={cx({ tab: true, active: tab === "FILE" })} onKeyDown={onTabAreaKeyDown}>
                        <a id="file-File" href="#!" role="tab" aria-selected={tab === "FILE" ? "true" : "false"} onClick={() => setTab("FILE")}>
                            {t("filebox.label.file")}
                        </a>
                    </div>
                    <div className={cx({ tab: true, active: tab === "APPS" })} onKeyDown={onTabAreaKeyDown}>
                        <a id="file-apps" href="#!" role="tab" aria-selected={tab === "APPS" ? "true" : "false"} onClick={() => setTab("APPS")}>
                            {t("filebox.label.app")}
                        </a>
                    </div>
                </div>
                <div className="dropdown dropdown-type-a mobile-only" role="listbox">
                    <a href="#!" role="listbox" aria-haspopup="listbox" className="btn-dropdown" aria-selected="false" title="Please Select option">
                        {getTabText()}
                    </a>
                    <div className="dropdown-menu">
                        <ul className="lists" role="listbox" aria-expanded="true">
                            <li className="list" role="none presentation">
                                <a href="#!" role="option" aria-selected={tab === "IMAGE" ? "true" : "false"} onClick={() => setTab("IMAGE")}>
                                    {t("filebox.label.image")}
                                </a>
                            </li>
                            <li className="list" role="none presentation">
                                <a href="#!" role="option" aria-selected={tab === "VIDEO" ? "true" : "false"} onClick={() => setTab("VIDEO")}>
                                    {t("filebox.label.video")}
                                </a>
                            </li>
                            <li className="list" role="none presentation">
                                <a href="#!" role="option" aria-selected={tab === "AUDIO" ? "true" : "false"} onClick={() => setTab("AUDIO")}>
                                    {t("filebox.label.music")}
                                </a>
                            </li>
                            <li className="list" role="none presentation">
                                <a href="#!" role="option" aria-selected={tab === "FILE" ? "true" : "false"} onClick={() => setTab("FILE")}>
                                    {t("filebox.label.file")}
                                </a>
                            </li>
                            <li className="list" role="none presentation">
                                <a href="#!" role="option" aria-selected={tab === "APPS" ? "true" : "false"} onClick={() => setTab("APPS")}>
                                    {t("filebox.label.app")}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="tab-panel" role="tabpanel">
                    <div className="search-and-filter-box">
                        <div className="filter-box flex-none">
                            <div className="right-side">
                                <div className="search-box2">
                                    <div className="search-inner-box">
                                        <input
                                            type="text"
                                            id="search_filebox_file"
                                            className="search search_b"
                                            name="search"
                                            placeholder={t("common.searchforfile")}
                                            autocomplete="true"
                                            aria-label={t("button.search")}
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <button type="button" className="btn-search">
                                            <span className="ir">{t("button.search")}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data-state">
                        <div className="col">
                            <button type="button" className="btn btn-tertiary" onClick={onUploadClick} disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}>
                                {t("upload.label.upload")}
                            </button>
                            <input type="file" ref={fileUpload} className="display-none-style" onChange={handleUploadFileChange} />
                        </div>
                        <div className="col">
                            <div className="capacity-info">{fileList?.totalSize || 0}/1GB</div>
                            <div className="total-count">
                                {t("player.label.total")} <strong>{fileList?.total || 0}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="field field-type-table">
                        <div className="field-form">
                            <div className="field-box">
                                <div className="table">
                                    <div className="table-wrapper Image-table">
                                        <div className="table-box">
                                            <InfiniteScroll
                                                style={{ overflow: "hidden" }}
                                                dataLength={getFileListPerPage()?.length || 0}
                                                next={() => {
                                                    setPage(page + 1);
                                                }}
                                                hasMore={hasNext() && windowWidth > 991}
                                                loader={""}
                                                scrollableTarget="fileboxTbody"
                                            >
                                                <table>
                                                    <caption>{getTabText()}</caption>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">{t("remote.label.highlight")}</th>
                                                            <th scope="col">{t("table.head.filesize")}</th>
                                                            <th scope="col">{t("table.head.date")}</th>
                                                            <th scope="col" className="width-16-percent">
                                                                {t("remote.button.download")}
                                                            </th>
                                                            <th scope="col" className="width-12-percent">
                                                                {t("button.delete")}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="fileboxTbody" ref={ref1}>
                                                        {getFileListPerPage()?.map(
                                                            (v, i) =>
                                                                v && (
                                                                    <tr key={i}>
                                                                        <td id={`filebox-td1-${i}`}>{v?.Key?.split("/")?.pop()}</td>
                                                                        <td id={`filebox-td2-${i}`}>{v?.Size ? utils.byteSizeParse(v.Size) : ""}</td>
                                                                        <td id={`filebox-td3-${i}`}>{v?.LastModified ? utils.getOffsetTime(auth, workspace, v?.LastModified) : ""}</td>
                                                                        <td className="center width-16-percent">
                                                                            <button
                                                                                aria-describedby={`filebox-td1-${i} filebox-td2-${i} filebox-td3-${i}`}
                                                                                type="button"
                                                                                className="btn btn-data-download"
                                                                                onClick={() => onDownloadClick(v)}
                                                                            >
                                                                                {t("remote.button.download")}
                                                                            </button>
                                                                        </td>
                                                                        <td className="center width-12-percent">
                                                                            <button
                                                                                aria-describedby={`filebox-td1-${i} filebox-td2-${i} filebox-td3-${i}`}
                                                                                type="button"
                                                                                className="btn btn-data-delete"
                                                                                onClick={() => onDeleteClick(v)}
                                                                                disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                            >
                                                                                {t("button.delete")}
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                    <div className="convert-table-to-list">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="thead">{t("remote.label.highlight")}</div>
                                            </div>
                                        </div>
                                        <InfiniteScroll
                                            style={{ overflow: "hidden" }}
                                            dataLength={getFileListPerPage()?.length || 0}
                                            next={() => {
                                                setPage(page + 1);
                                            }}
                                            hasMore={hasNext() && windowWidth <= 991}
                                            loader={""}
                                            scrollableTarget="popup-file-box-container"
                                        >
                                            <ul className="table-body">
                                                {getFileListPerPage()?.map(
                                                    (v, i) =>
                                                        v && (
                                                            <li className={`table-row ${v?.isShow ? "expand" : ""}`} key={i}>
                                                                <div className="summary-data" onClick={() => toggleShow(i)}>
                                                                    <div className="row">
                                                                        <div className="table-cell alias">
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded={v.isShow ? "true" : "false"}>
                                                                                {v?.Key?.split("/")?.pop()}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li id={`filebox-li1-${i}`}>
                                                                            <span className="field-label">{t("table.head.filesize")}</span>
                                                                            <span className="field-content">{v?.Size ? utils.byteSizeParse(v.Size) : ""}</span>
                                                                        </li>
                                                                        <li id={`filebox-li2-${i}`}>
                                                                            <span className="field-label">{t("table.head.date")}</span>
                                                                            <span className="field-content">{v?.LastModified ? utils.getOffsetTime(auth, workspace, v?.LastModified) : ""}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("remote.button.download")}</span>
                                                                            <span className="field-content">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-data-download"
                                                                                    onClick={() => onDownloadClick(v)}
                                                                                    aria-describedby={`filebox-li1-${i} filebox-li2-${i}`}
                                                                                >
                                                                                    {t("remote.button.download")}
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("button.delete")}</span>
                                                                            <span className="field-content">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-data-delete"
                                                                                    onClick={() => onDeleteClick(v)}
                                                                                    aria-describedby={`filebox-li1-${i} filebox-li2-${i}`}
                                                                                    disabled={utils.getAuthWriteDisable(rolePermission, SERVICE_TYPE)}
                                                                                >
                                                                                    {t("alertmsg.stop.delete")}
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {tab === "IMAGE" && (
                            <div className="file-standard-info">
                                <div className="file-standard">* {t("filebox.label.format")} : BMP, JPEG, JPG, GIF, PNG, RAW</div>
                                <div className="file-standard">* {t("common.label.FileSmallerThan", { 0: MAX_SIZE + "MB" })}</div>
                            </div>
                        )}
                        {tab === "VIDEO" && (
                            <div className="file-standard-info">
                                <div className="file-standard">* {t("filebox.label.format")} : MP4, AVI, WMV, MPEG, MKV, MOV, WebM, 3GPP</div>
                                <div className="file-standard">* {t("common.label.FileSmallerThan", { 0: MAX_SIZE + "MB" })}</div>
                            </div>
                        )}
                        {tab === "AUDIO" && (
                            <div className="file-standard-info">
                                <div className="file-standard">* {t("filebox.label.format")} : MP3, FLAC, WAV, AAC, WMV</div>
                                <div className="file-standard">* {t("common.label.FileSmallerThan", { 0: MAX_SIZE + "MB" })}</div>
                            </div>
                        )}
                        {tab === "FILE" && (
                            <div className="file-standard-info">
                                <div className="file-standard">* {t("common.label.FileSmallerThan", { 0: MAX_SIZE + "MB" })}</div>
                            </div>
                        )}
                        {tab === "APPS" && (
                            <div className="file-standard-info">
                                <div className="file-standard">* {t("filebox.label.format")} : apk</div>
                                <div className="file-standard">* {t("common.label.FileSmallerThan", { 0: MAX_SIZE + "MB" })}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FileboxToolbar;
