import Modal from "@component/UI/Modal";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { list } from "./constants/constant";
import { InputCalendar, NumberInput, SelectBox, TextArea, TextInput, VolumeInput } from "@component/UI/Form";
import { AppContext } from "@component/AppProvider";
import _API from "@util/api";
import SelectFile from "@component/AlertMessage/TemplatePopup/SelectFile";
import Dialog from "@component/UI/Dialog";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import utils from "@util/utilities";

const maxTitleLength = 100;

const StyledLiveChannelDesc = styled.div`
    padding: 1rem 1.5rem;
    background-color: #f2f2f2;
    margin-top: 3px;
    .desc-detail {
        margin-top: 1.5rem;
    }
`;

const RegisterBroadcastPopup = ({ onClose, onRegister, deviceId, deviceInfo, totalCount = 0 }) => {
    const { targetModel, saveTargetModel, imageUrl, imagePolicy, imageSignature, imageKeyPairId, workspace } = useContext(AppContext);
    const { t } = useTranslation();
    console.log("##", deviceId);

    const executionDateRef = useRef(null);

    const [fileType, setFileType] = useState("video");
    const [isMultiple, setIsMultiple] = useState(false);
    const [showFileSelect, setShowFileSelect] = useState(false);
    const [isClickRegister, setIsClickRegister] = useState(false);
    const [imgFileLoading, setImgFileLoading] = useState(false);
    const [commonField, setCommonField] = useState({
        type: {
            label: list.typeDropdown[0].label,
            value: list.typeDropdown[0].value,
        },
        title: `Broadcast_${list.typeDropdown[0].label}_${totalCount + 1}`,
        execution: {
            label: list.executionDropdown[0].label,
            value: list.executionDropdown[0].value,
        },
        executionDate: "",
        executionTime: {
            type: list.executionType[0].value,
            hours: "09",
            minutes: "00",
        },
        checkedDays: [],
        model: null,
    });
    const [fileFilter, setFileFilter] = useState([]);
    const [videoField, setVideoField] = useState({
        playback: list.playback[0].name,
        volume: 0,
        file: null,
        fileName: "",
    });
    const [imgField, setImgField] = useState({
        slideTime: 5,
        fileList: [],
        playback: list.playback[0].name,
        playbackOption: list.imgPlayback[0].name,
    });

    const [audioField, setAudioField] = useState({
        volume: 0,
        file: null,
        fileName: "",
        playback: list.playback[0].name,
    });
    const [duration, setDuration] = useState(0); // 오다오 재생시간 길이

    const [msgField, setMsgField] = useState({
        type: list.msgType[0].name,
        retentionTime: 1,
        soundVolume: 0,
        message: "",
    });
    const [liveChannelField, setLiveChannelField] = useState({
        url: "",
        volume: 0,
    });

    const [selectedFiles, setSelectedFiles] = useState([]);

    const changeExecutionTimeState = (key, value) => {
        console.log({ key, value });
        setCommonField((prevCommonField) => {
            return {
                ...prevCommonField,
                executionTime: {
                    ...prevCommonField.executionTime,
                    [key]: value,
                },
            };
        });
    };

    const handleCheckedDays = (value) => {
        setCommonField((prevCommonField) => {
            const isChecked = prevCommonField.checkedDays.includes(value);

            if (isChecked) {
                const updatedCheckedDays = prevCommonField.checkedDays.filter((day) => day !== value);
                return {
                    ...prevCommonField,
                    checkedDays: updatedCheckedDays,
                };
            } else {
                const updatedCheckedDays = [...prevCommonField.checkedDays, value];
                return {
                    ...prevCommonField,
                    checkedDays: updatedCheckedDays,
                };
            }
        });
    };

    const removeFileAtIndex = (index) => {
        setImgField((prevImgField) => {
            const updatedFileList = [...prevImgField.fileList];
            updatedFileList.splice(index, 1); // 해당 index의 요소 제거
            return { ...prevImgField, fileList: updatedFileList };
        });
    };
    const changeFileOrder = (index, moveUp) => {
        setImgField((prevImgField) => {
            const updatedFileList = [...prevImgField.fileList];
            const targetIndex = index + (moveUp ? -1 : 1); // 이동할 인덱스 계산

            // 유효한 인덱스인지 확인
            if (targetIndex >= 0 && targetIndex < updatedFileList.length) {
                // 요소 교환
                const temp = updatedFileList[index];
                updatedFileList[index] = updatedFileList[targetIndex];
                updatedFileList[targetIndex] = temp;
            }

            return { ...prevImgField, fileList: updatedFileList };
        });
    };

    const togglefileListShow = (index) => {
        setImgField((prevImgField) => {
            const updatedFileList = [...prevImgField.fileList];
            updatedFileList[index] = {
                ...updatedFileList[index],
                isShow: !updatedFileList[index].isShow,
            };
            return { ...prevImgField, fileList: updatedFileList };
        });
    };

    const getFormByType = () => {
        const audioJsx = (
            <>
                <VolumeInput
                    isRequired
                    id="ip-message-type-fromtype"
                    descId="description-id-fromtype"
                    value={audioField.volume}
                    onChange={(value) => setAudioField({ ...audioField, volume: value })}
                />

                <div className="field field-type-file required">
                    <div className="field-header">
                        <label htmlFor="ip-sound-file" className="field-title">
                            {t("common.audio_file")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="field-box">
                            <input type="text" id="ip-sound-file" readOnly placeholder="Audio File" value={audioField.fileName} aria-required="true" />
                            <button type="button" className="btn btn-tertiary" onClick={() => showFileSelector("audio")}>
                                {t("device.label.select")}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );

        switch (commonField.type.label) {
            case "Video":
                return (
                    <>
                        <div className="field field-type-template">
                            <div className="field-header">
                                <label htmlFor="ip-template-type" className="field-title">
                                    {t("common.playback")}
                                </label>
                            </div>
                            <div className="field-form">
                                {list?.playback?.map((x, i) => (
                                    <div
                                        className="radio"
                                        key={i}
                                        onClick={() =>
                                            setVideoField({
                                                ...videoField,
                                                playback: x.name,
                                            })
                                        }
                                    >
                                        <input type="radio" name={x.name} checked={videoField.playback === x.name} readOnly />
                                        <label htmlFor={x.name}>{x.txt}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <VolumeInput
                            isRequired
                            id="ip-message-type-video"
                            value={videoField.volume}
                            onChange={(value) => setVideoField({ ...videoField, volume: value })}
                            descId="description-id-video"
                        />

                        <div className="field field-type-file required">
                            <div className="field-header">
                                <label htmlFor="ip-sound-file" className="field-title">
                                    {t("common.video_file")}
                                </label>
                            </div>
                            <div className="field-form">
                                <div className="field-box">
                                    <input type="text" id="ip-sound-file" readOnly placeholder={t("common.video_file")} value={videoField.fileName} aria-required="true" />
                                    <button
                                        type="button"
                                        className="btn btn-tertiary"
                                        onClick={() => {
                                            showFileSelector("video");
                                        }}
                                    >
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case "Image":
                return (
                    <>
                        <div className="field field-type-template">
                            <div className="field-header">
                                <label htmlFor="ip-template-type" className="field-title">
                                    {t("common.playback")}
                                </label>
                            </div>
                            <div className="field-form">
                                {list?.playback?.map((x, i) => (
                                    <div
                                        className="radio"
                                        key={i}
                                        onClick={() =>
                                            setImgField({
                                                ...imgField,
                                                playback: x.name,
                                            })
                                        }
                                    >
                                        <input type="radio" name={x.name} checked={imgField.playback === x.name} readOnly />
                                        <label htmlFor={x.name}>{x.txt}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="field field-type-message required">
                            <div className="field-header">
                                <label htmlFor="ip-message-type-image" className="field-title">
                                    {t("form.label.slidetime")}
                                </label>
                            </div>
                            <div className="field-form">
                                <div className="input-box">
                                    <SelectBox
                                        items={Array.from({ length: 12 }, (_, index) => {
                                            const minNumber = (index + 1) * 5;
                                            return {
                                                label: minNumber + " Seconds",
                                                value: minNumber,
                                            };
                                        })}
                                        value={imgField.slideTime}
                                        selectLabel={imgField.slideTime + " Seconds"}
                                        onChange={(item) => {
                                            setImgField({ ...imgField, slideTime: item.value });
                                        }}
                                        isRequired
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="field field-type-message required">
                            <div className="field-header">
                                <label htmlFor="ip-message-type-image" className="field-title">
                                    {t("filebox.label.image")}
                                </label>
                            </div>
                            <div className="field-form">
                                <div className="input-box">
                                    <button
                                        type="button"
                                        className="btn btn-tertiary"
                                        onClick={() => {
                                            showFileSelector("image");
                                        }}
                                    >
                                        {t("device.label.select")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="field field-type-message required">
                            <div className="field-header">
                                <label className="field-title">{t("common.selected_file")}</label>
                            </div>
                            <div className="field-form">
                                <div className="table">
                                    <div className={`table-wrapper no-scroll ${imgField?.fileList?.length ? "device-table" : "nodata-table"}`}>
                                        <div className="table-box">
                                            <table>
                                                <caption>{t("common.selected_file")}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <div className="text-block">{t("noticeBoard.label.no")}</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div className="text-block">{t("filebox.label.image")}</div>
                                                        </th>
                                                        <th scope="col" className="width-40-percent">
                                                            <div className="text-block">{t("remote.label.highlight")}</div>
                                                        </th>
                                                        <th scope="col" className="width-15-percent">
                                                            <div className="text-block">{t("button.delete")}</div>
                                                        </th>
                                                        <th scope="col" className="width-20-percent">
                                                            <div className="text-block">{t("table.head.sort")}</div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!imgField?.fileList?.length && (
                                                        <tr>
                                                            <td>
                                                                <div className="nodata-text">{t("common.file.noselect")}</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {imgField?.fileList?.map((v, i) => {
                                                        if (v)
                                                            return (
                                                                <tr key={i}>
                                                                    <td id={`rbp-td1-${i}`}>{i + 1}</td>
                                                                    <td id={`rbp-td2-${i}`}>
                                                                        <img src={v?.img_url} width="100%" alt="" />
                                                                    </td>
                                                                    <td className="width-40-percent" id={`rbp-td3-${i}`}>
                                                                        {v?.fileName || ""}
                                                                    </td>
                                                                    <td className="center width-15-percent">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-delete"
                                                                            onClick={() => removeFileAtIndex(i)}
                                                                            aria-describedby={`rbp-td1-${i} rbp-td2-${i} rbp-td3-${i}`}
                                                                        >
                                                                            {t("button.delete")}
                                                                        </button>
                                                                    </td>
                                                                    <td className="center width-20-percent">
                                                                        <button
                                                                            type="button"
                                                                            id=""
                                                                            className="btn btn-sort-up"
                                                                            onClick={() => changeFileOrder(i, true)}
                                                                            aria-describedby={`rbp-td1-${i} rbp-td2-${i} rbp-td3-${i}`}
                                                                        >
                                                                            {t("common.up")}
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            id=""
                                                                            className="btn btn-sort-down"
                                                                            onClick={() => changeFileOrder(i, false)}
                                                                            aria-describedby={`rbp-td1-${i} rbp-td2-${i} rbp-td3-${i}`}
                                                                        >
                                                                            {t("common.down")}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className={`convert-table-to-list ${!imgField?.fileList?.length ? "nodata-table" : ""}`}>
                                    <div className="table-header">
                                        <div className="row">
                                            <div className="thead">{t("filebox.label.image")}</div>
                                            <div className="thead">{t("remote.label.highlight")}</div>
                                        </div>
                                    </div>

                                    <ul className="table-body">
                                        {!imgField?.fileList?.length && (
                                            <li className="table-row">
                                                <div className="nodata-text">{t("common.file.noselect")}</div>
                                            </li>
                                        )}
                                        {imgField?.fileList?.map((v, i) => {
                                            const { file, fileName, isShow, img_url } = v;
                                            return (
                                                <li className="table-row" key={i}>
                                                    <div className="summary-data">
                                                        <div className="row">
                                                            <div className="table-cell issue-level">
                                                                <img src={img_url} width="100%" alt="" />
                                                            </div>
                                                            <div className="table-cell issue-level">
                                                                <button type="button" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                    {fileName || ""}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="all-data-box">
                                                        <ul className="all-data">
                                                            <li>
                                                                <span className="field-label">{t("button.delete")}</span>
                                                                <span className="field-content">
                                                                    <button type="button" className="btn btn-delete" onClick={() => removeFileAtIndex(i)}>
                                                                        {t("button.delete")}
                                                                    </button>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="field-label">{t("table.head.sort")}</span>
                                                                <span className="field-content">
                                                                    <button type="button" className="btn btn-sort-up" onClick={() => changeFileOrder(i, true)}>
                                                                        {t("common.up")}
                                                                    </button>
                                                                    <button type="button" className="btn btn-sort-down" onClick={() => changeFileOrder(i, false)}>
                                                                        {t("common.down")}
                                                                    </button>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="title-box">
                            <h2 className="section-title">{t("common.audio_playback_option")}</h2>
                        </div>

                        <div className="field field-type-template">
                            <div className="field-header">
                                <label htmlFor="ip-template-type" className="field-title">
                                    {t("common.playback")}
                                </label>
                            </div>
                            <div className="field-form">
                                {list?.imgPlayback.map((v, i) => (
                                    <div className="radio" key={i} onClick={() => setImgField({ ...imgField, playbackOption: v.name })}>
                                        <input type="radio" name={v.name} id={v.name} checked={v.name === imgField.playbackOption} readOnly />
                                        <label htmlFor={v.name}>{v.txt}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {imgField.playbackOption === "used" && audioJsx}
                    </>
                );
            case "Audio":
                return (
                    <>
                        <div className="field field-type-template">
                            <div className="field-header">
                                <label htmlFor="ip-template-type" className="field-title">
                                    {t("common.playback")}
                                </label>
                            </div>
                            <div className="field-form">
                                {list?.playback?.map((x, i) => (
                                    <div
                                        className="radio"
                                        key={i}
                                        onClick={() =>
                                            setAudioField({
                                                ...audioField,
                                                playback: x.name,
                                            })
                                        }
                                    >
                                        <input type="radio" name={x.name} checked={audioField.playback === x.name} readOnly />
                                        <label htmlFor={x.name}>{x.txt}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {audioJsx}
                    </>
                );
            case "Message":
                return (
                    <>
                        <div className="title-box">
                            <h2 className="section-title">{t("common.br.MessageOption")}</h2>
                        </div>

                        <div className="field field-type-template">
                            <div className="field-header">
                                <label htmlFor="ip-template-type" className="field-title">
                                    {t("common.type")}
                                </label>
                            </div>
                            <div className="field-form">
                                {list?.msgType?.map((v, i) => (
                                    <div className="radio" key={i} onClick={() => setMsgField({ ...msgField, type: v.name })}>
                                        <input type="radio" name={v.name} id={v.name} checked={v.name === msgField.type} readOnly />
                                        <label htmlFor={v.name}>{v.txt}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <NumberInput
                            isRequired
                            title={`${t("form.label.popuptime")}(${t("subtext.select.label.minute")})`}
                            desc={t("common.set_between_min", { 0: "1", 1: "120" })}
                            id="ip-message-type-message"
                            descId="description-id-1"
                            min={1}
                            max={120}
                            value={msgField.retentionTime}
                            onChange={(value) => setMsgField({ ...msgField, retentionTime: value })}
                        />

                        <VolumeInput
                            isRequired
                            title={t("common.noti_sound_volume")}
                            id="ip-message-type-message2"
                            descId="description-id-2"
                            value={msgField.soundVolume}
                            onChange={(value) => setMsgField({ ...msgField, soundVolume: value })}
                        />

                        <TextArea
                            isRequired
                            title={t("message.label.message")}
                            id="ip-textarea"
                            value={msgField.message}
                            onChange={(message) => setMsgField({ ...msgField, message })}
                            maxLength={maxTitleLength}
                            placeholder={t("message.label.message")}
                        />
                    </>
                );
            case "Live Streaming":
                return (
                    <>
                        <TextInput
                            isRequired
                            title={t("form.label.livechannelurl")}
                            id="live-channel-url"
                            value={liveChannelField.url}
                            onChange={(url) =>
                                setLiveChannelField({
                                    ...liveChannelField,
                                    url,
                                })
                            }
                            maxLength={maxTitleLength}
                            placeholder={t("form.label.livechannelurl")}
                            hideWordCount
                        >
                            <StyledLiveChannelDesc>
                                <div>{t("broadcast.description")}</div>
                                <div className="desc-detail">
                                    <div>[{t("dashboard.noresponse.android")}]</div>
                                    <div>- RTMP : rtmp://...</div>
                                    <div>- RTSP : rtsp://...</div>
                                    <div>- Apple HLS : http://… ({t("common.live_channel_last_desc")})</div>
                                </div>
                                <div className="desc-detail">
                                    <div>[{t("dv.device.webOS")}]</div>
                                    <div>- UDP : udp://...</div>
                                    <div>- RTP : rtp://...</div>
                                    <div>- RTSP : rtsp://...</div>
                                </div>
                            </StyledLiveChannelDesc>
                        </TextInput>
                        <VolumeInput
                            isRequired
                            id="live-volume"
                            descId="description-id-3"
                            value={liveChannelField.volume}
                            onChange={(value) => setLiveChannelField({ ...liveChannelField, volume: value })}
                        />
                    </>
                );
        }
    };

    const getExecutionJsx = () => {
        const time = (
            <div className="field field-type-dropdown field-repeat required">
                <label className="field-label">{t("common.time")}</label>
                <div className="field-form">
                    <div className="field-box">
                        <SelectBox
                            items={list?.executionType}
                            value={commonField.executionTime?.type}
                            selectLabel={commonField.executionTime?.type || ""}
                            onChange={(item) => {
                                const { value } = item;
                                changeExecutionTimeState("type", value);
                            }}
                            isRequired
                        />
                        <SelectBox
                            items={Array.from({ length: 12 }, (_, index) => {
                                const minNumber = (index + 1).toString().padStart(2, "0");
                                return { label: minNumber, value: minNumber };
                            })}
                            value={commonField.executionTime?.hours}
                            selectLabel={commonField.executionTime?.hours || ""}
                            onChange={(item) => {
                                const { value } = item;
                                changeExecutionTimeState("hours", value);
                            }}
                            isRequired
                        />
                        <span className="colon pc-only"> : </span>
                        <SelectBox
                            items={Array.from({ length: 60 }, (_, index) => {
                                const minuteNumber = index.toString().padStart(2, "0");
                                return { label: minuteNumber, value: minuteNumber };
                            })}
                            value={commonField.executionTime?.minutes}
                            selectLabel={commonField.executionTime?.minutes || ""}
                            onChange={(item) => {
                                const { value } = item;
                                changeExecutionTimeState("minutes", value);
                            }}
                            isRequired
                        />
                    </div>
                </div>
            </div>
        );
        switch (commonField.execution.label) {
            case "Specific":
                return (
                    <>
                        <div className="field field-type-message required">
                            <div className="field-header">
                                <label htmlFor="execution-date" className="field-title">
                                    {t("table.head.actiondate")}
                                </label>
                            </div>
                            <div className="field-form">
                                <InputCalendar
                                    name="executionDate"
                                    id="execution-date"
                                    className="execution-date"
                                    placeholder="YYYY-MM-DD"
                                    autoComplete="false"
                                    ariaLabel={t("validation.enter.date")}
                                    value={commonField.executionDate || null}
                                    onChange={({ executionDate }) => setCommonField({ ...commonField, executionDate })}
                                    ref={executionDateRef}
                                    isRequired
                                />
                            </div>
                        </div>
                        {time}
                    </>
                );
            case "Repeat":
                return (
                    <>
                        <div className="field field-type-checkbox required">
                            <label className="field-label">{t("device.label.dayofweek")}</label>
                            <div className="field-form">
                                <div className="field-box">
                                    {list?.executionDayOfWeek?.map((x, i) => (
                                        <div className="checkbox" key={i}>
                                            <input
                                                type="checkbox"
                                                name="checkbox-type"
                                                id={`checkbox-${i + 1}`}
                                                checked={commonField.checkedDays.includes(x)}
                                                onClick={() => handleCheckedDays(x)}
                                                readOnly
                                                aria-required="true"
                                            />
                                            <label htmlFor={`checkbox-${i + 1}`}>{x}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {time}
                    </>
                );
        }
    };

    const setDur = async (fileKey) => {
        const dur = await utils.getDuration(fileKey);
        setDuration(dur);
    };

    const showFileSelector = (fileType) => {
        switch (fileType) {
            case "image":
                setFileFilter(["bmp", "raw"]);
                break;
            case "video":
                setFileFilter(["avi", "wmv", "mpeg", "mkv", "mov", "webm", "3gpp"]);
                break;
            case "audio":
                setFileFilter(["flac", "wmv"]);
                break;
            default:
                setFileFilter([]);
                break;
        }
        if (fileType === "image") setIsMultiple(true);
        else setIsMultiple(false);
        setFileType(fileType);
        setShowFileSelect(true);
    };

    const onChangeFile = (fileData) => {
        setShowFileSelect(false);
        if (!fileData?.length) {
            switch (commonField.type.value) {
                case "video":
                    setVideoField({
                        ...videoField,
                        file: null,
                        fileName: "",
                    });
                    break;
                case "image":
                    setImgField({ ...imgField, fileList: [] });
                    break;
                case "audio":
                    setAudioField({
                        ...audioField,
                        file: null,
                        fileName: "",
                    });
                    break;
            }
            return;
        }

        const fileKeyParts = fileData[0].Key.split("/");
        switch (commonField.type.value) {
            case "video":
                const fileFormat = fileData[0].Key;
                const fileNameWithExtension = fileFormat.split("/").pop();
                const [fileName, fileExtension] = fileNameWithExtension.split(".");
                if (fileExtension.toLowerCase() === "avi") {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.popup.PleaseSelectSupportFile"),
                        button: t("common.label.confirm"),
                    });
                    return;
                }
                setVideoField({
                    ...videoField,
                    file: fileData[0].Key,
                    fileName: fileKeyParts[fileKeyParts.length - 1],
                });
                break;
            case "image":
                if (fileType === "audio") {
                    setAudioField({
                        ...audioField,
                        file: fileData[0].Key,
                        fileName: fileKeyParts[fileKeyParts.length - 1],
                    });
                    setDur(fileData[0].Key);
                    return;
                }
                const fileList = [];
                fileData.forEach((v) => {
                    const fileKeyParts = v.Key.split("/");
                    fileList.push({
                        file: v.Key,
                        fileName: fileKeyParts[fileKeyParts.length - 1],
                        img_url: "",
                        isShow: false,
                    });
                });
                if (fileList?.length >= 11) {
                    Dialog({
                        title: t("popup.title.inform"),
                        text: t("common.overSelect10", { 0: 10 }),
                        button: t("common.ok"),
                    });
                    return;
                }
                setImgField({ ...imgField, fileList });
                setImgFileLoading(true);
                break;
            case "audio":
                setAudioField({
                    ...audioField,
                    file: fileData[0].Key,
                    fileName: fileKeyParts[fileKeyParts.length - 1],
                });
                setDur(fileData[0].Key);
                break;
            case "message":
                break;
        }
    };

    const clickClose = () => {
        Dialog({
            title: t("button.cancel"),
            text: t("subtext.message.leavepage"),
            button: [t("common.button.stay"), t("common.button.leave")],
        }).then((isConfirm) => {
            if (!isConfirm) return;
            onClose();
        });
    };

    const dialogRequiredField = () => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("popup.inform.multiItem"),
            button: t("common.label.confirm"),
        });
    };

    const pleaseEnterDialog = (name) => {
        Dialog({
            title: t("popup.title.inform"),
            text: t("popup.inform.sigleItem", { n: name }),
            button: t("common.label.confirm"),
        });
    };

    const getCountCommonFieldNotEnter = () => {
        const { title, execution } = commonField;
        const group_id = targetModel?.groupList?.map((v) => v.group_id) || [];
        let count = 0;
        if (!title) count++;

        switch (execution.value) {
            case "specific":
                if (!commonField.executionDate) count++;
                break;
            case "repeat":
                if (!commonField.checkedDays?.length) count++;
                break;
        }

        if (!deviceId && !group_id?.length) count++;

        return count;
    };

    const clickRegister = () => {
        const { title, type, execution } = commonField;
        const group_id = targetModel?.groupList?.map((v) => v.group_id) || [];

        // 2개 이상 입력안한거 유효성 검사
        let count = getCountCommonFieldNotEnter();
        switch (type.value) {
            case "video":
                if (!videoField?.file) count++;
                if (!videoField?.playback) count++;
                if (!videoField?.volume?.toString()?.length) count++;
                break;
            case "image":
                const imgFile = imgField?.fileList?.map((v) => v?.file);
                if (!imgFile?.length) count++;
                if (imgField?.playbackOption === "used") {
                    if (!audioField?.file) count++;
                    if (!audioField?.volume?.toString()?.length) count++;
                }
                break;
            case "audio":
                if (!audioField?.file) count++;
                if (!audioField?.volume?.toString()?.length) count++;
                break;
            case "message":
                if (!msgField?.soundVolume?.toString()?.length) count++;
                if (!msgField?.message) count++;
                if (!msgField?.retentionTime?.toString()?.length) count++;
                if (!msgField?.type) count++;
                break;
            case "live streaming":
                if (!liveChannelField?.url) count++;
                if (!liveChannelField?.volume?.toString()?.length) count++;
                break;
        }
        if (count >= 2) {
            dialogRequiredField();
            return;
        }

        if (!title) {
            pleaseEnterDialog(t("form.label.title"));
            return;
        }
        if (!deviceId && !group_id?.length) {
            pleaseEnterDialog(t("common.target"));
            return;
        }
        let data = {
            title,
            broadcast_type: type.value,
            broadcast_value: { desc: { type: "", volume: 0, file: "" } },
            execution: { execution_type: execution.value },
            activate: true,
            device_id: deviceId,
        };
        if (group_id?.length && !deviceId) data = { ...data, group_id };
        if (targetModel?.model_name) data = { ...data, model: targetModel?.model_name };

        const setTime = (hours, minutes, type) => {
            const parseIntHours = type === "AM" ? parseInt(hours) : parseInt(hours) + 12;
            const formattedHours = String(parseIntHours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");
            return `${formattedHours}:${formattedMinutes}`;
        };

        switch (execution.value) {
            case "specific":
                data.execution.date = commonField?.executionDate;
                if (!commonField?.executionDate) {
                    pleaseEnterDialog(t("table.head.actiondate"));
                    return;
                }
                data.execution.time = setTime(commonField?.executionTime?.hours, commonField?.executionTime?.minutes, commonField?.executionTime?.type);
                break;
            case "repeat":
                data.execution.time = setTime(commonField?.executionTime?.hours, commonField?.executionTime?.minutes, commonField?.executionTime?.type);
                if (commonField?.checkedDays?.length) {
                    data.execution.day_of_week = commonField?.checkedDays;
                } else {
                    pleaseEnterDialog(t("device.label.dayofweek"));
                    return;
                }
                break;
        }

        switch (type.value) {
            case "video":
                if (!videoField?.file) {
                    pleaseEnterDialog(t("common.video_file"));
                    return;
                }
                if (!videoField?.volume?.toString()?.length) {
                    pleaseEnterDialog(t("schedule.commend.set_volume"));
                    return;
                }
                data.broadcast_value.desc = {
                    playback: videoField?.playback,
                    volume: Number(videoField?.volume),
                    file: [videoField?.file],
                };
                break;
            case "image":
                const file = imgField?.fileList?.map((v) => v.file);
                if (!file?.length) {
                    pleaseEnterDialog(t("filebox.label.image"));
                    return;
                }
                data.broadcast_value.desc = {
                    file,
                    slide_time: Number(imgField?.slideTime),
                    playback: imgField?.playback,
                };
                if (imgField?.playbackOption === "used") {
                    if (!audioField?.file) {
                        pleaseEnterDialog(t("common.audio_file"));
                        return;
                    }
                    if (!audioField?.volume?.toString()?.length) {
                        pleaseEnterDialog(t("player.label.volume"));
                        return;
                    }
                    data.broadcast_value.ext = {
                        file: audioField?.file,
                        volume: Number(audioField?.volume),
                        duration,
                    };
                }
                break;
            case "audio":
                if (!audioField?.file) {
                    pleaseEnterDialog(t("common.audio_file"));
                    return;
                }
                if (!audioField?.volume?.toString()?.length) {
                    pleaseEnterDialog(t("player.label.volume"));
                    return;
                }
                data.broadcast_value.desc = {
                    playback: audioField?.playback,
                    volume: Number(audioField?.volume),
                    file: [audioField?.file],
                    duration,
                };
                break;
            case "message":
                if (!msgField?.soundVolume?.toString()?.length) {
                    pleaseEnterDialog(t("player.label.volume"));
                    return;
                }
                if (!msgField?.message) {
                    pleaseEnterDialog(t("schedule.commend.broadcast_message"));
                    return;
                }
                if (!msgField?.retentionTime?.toString()?.length) {
                    pleaseEnterDialog(t("form.label.popuptime"));
                    return;
                }
                data.broadcast_value.desc = {
                    playback: msgField?.type,
                    volume: Number(msgField?.soundVolume),
                    message: msgField?.message,
                    retention_time: Number(msgField?.retentionTime),
                };
                break;
            case "live streaming":
                if (!liveChannelField?.url) {
                    pleaseEnterDialog(t("form.label.livechannelurl"));
                    return;
                }
                if (!liveChannelField?.volume?.toString()?.length) {
                    pleaseEnterDialog(t("issue.type.volume"));
                    return;
                }
                data.broadcast_value.desc = {
                    file: [liveChannelField?.url],
                    volume: Number(liveChannelField?.volume),
                };
                break;
        }

        if (isClickRegister) return;
        setIsClickRegister(true);
        let path = `/content/schedule/broadcast`;
        _API.post({
            path,
            data,
        })
            .then((res) => {
                onRegister();
            })
            .catch((e) => {
                console.log(`api error /content/schedule/broadcast`, e);
                let msg = "Server Error";
                if (e?.response?.data?.status?.code === "00-006" && e?.response?.data?.error === "duplicated title") {
                    msg = t("subtext.error.itemDupTitle");
                } else if (e?.response?.data?.error?.error) {
                    msg = e.response.data.error.error;
                } else if (e?.response?.data?.error) {
                    msg = e?.response?.data?.error;
                }
                Dialog({
                    title: t("popup.title.inform"),
                    text: msg,
                    button: t("common.label.confirm"),
                });
            })
            .finally(() => setIsClickRegister(false));
    };

    const getSelectedFiles = () => {
        switch (commonField?.type?.value) {
            case "image":
                const imgFile = imgField?.fileList?.map((v) => v?.file);
                if (imgFile?.length) {
                    setSelectedFiles(imgFile);
                    return;
                }
                break;
        }
        setSelectedFiles([]);
    };

    useEffect(() => {
        saveTargetModel({
            open: false,
        });
    }, []);

    useEffect(() => {
        setCommonField({
            ...commonField,
            title: `Broadcast_${commonField.type.label}_${totalCount + 1}`,
        });
        resizeTable();
    }, [commonField.type]);

    useEffect(() => {
        if (targetModel?.hasOwnProperty("model_name")) setCommonField({ ...commonField, model: targetModel?.model_name });
        else setCommonField({ ...commonField, model: null });
    }, [targetModel]);

    useEffect(() => {
        getSelectedFiles();
        resizeTable();
    }, [imgField.fileList]);

    useEffect(() => {
        if (imgFileLoading) {
            setImgFileLoading(false);
            const fileList = imgField.fileList.concat();
            fileList.forEach(async (v) => {
                if (v?.file) {
                    const img_url = await utils.getFileDownloadUrl(v.file);
                    v.img_url = img_url;
                }
            });
            setTimeout(() => {
                setImgField({ ...imgField, fileList });
                resizeTable();
            }, 600);
            setTimeout(() => {
                resizeTable();
            }, 700);
        }
    }, [imgFileLoading]);

    return (
        <Fragment>
            <Modal id="popup-add-template" className="popup-add-template" title={t("main.btnRegister.title")} buttonOkTitle={t("main.btnRegister.title")} onClose={clickClose} onClick={clickRegister}>
                <div className="form-guide algin-left">
                    <span>*</span> {t("common.label.isrequired")}
                </div>
                <div className="field field-type-message required">
                    <div className="field-header">
                        <label htmlFor="ip_broadcast_type" className="field-title">
                            {t("common.label.BroadcastType")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="input-box">
                            <SelectBox
                                id="ip_broadcast_type"
                                items={deviceInfo?.os_type === "WEBOS" ? list.typeDropdownWebOs : list.typeDropdown}
                                value={commonField.type.value}
                                selectLabel={commonField.type.label || ""}
                                onChange={(item) => {
                                    const { value, label } = item;
                                    setCommonField({
                                        ...commonField,
                                        type: { value, label },
                                    });
                                }}
                                title={t("subtext.please.select.option")}
                                isRequired
                                ariadescribedby="description-id"
                            />
                        </div>
                    </div>
                    {commonField.type.value !== "message" && (
                        <span id="description-id" className="field-description">
                            {t("common.only_files_uploaded")}
                        </span>
                    )}
                </div>

                <TextInput
                    isRequired
                    title={t("commom.popup.title")}
                    id="ip-broadcast-title"
                    value={commonField.title}
                    onChange={(val) => setCommonField({ ...commonField, title: val })}
                    maxLength={maxTitleLength}
                />

                <div className="field field-type-message required">
                    <div className="field-header">
                        <label htmlFor="ip-broadcast-execution" className="field-title">
                            {t("common.label.Execution")}
                        </label>
                    </div>
                    <div className="field-form">
                        <div className="input-box">
                            <SelectBox
                                id="ip-broadcast-execution"
                                items={deviceId ? list.executionDropdown : list.executionDropdown2}
                                value={commonField.execution.value}
                                selectLabel={commonField.execution.label || ""}
                                onChange={(item) => {
                                    const { value, label } = item;
                                    setCommonField({
                                        ...commonField,
                                        execution: { value, label },
                                    });
                                }}
                                title={t("subtext.please.select.option")}
                                isRequired
                            />
                        </div>
                    </div>
                </div>
                {getExecutionJsx()}
                {getFormByType()}

                {!deviceId && (
                    <div className="field field-type-message required">
                        <div className="field-header">
                            <label className="field-title">{t("common.label.target.group")}</label>
                        </div>
                        <div className="field-form">
                            <div className="message-target-wrap">
                                {!targetModel?.groupList?.length && <div className="empty-txt whitespace-pre-line">{t("common.desc.target.select.no")}</div>}
                                {targetModel?.groupList?.length && (
                                    <dl className="message-target-info">
                                        {/* <dt>Selected Model</dt>
                    <dd>{commonField.model}</dd> */}
                                        <dt>Group</dt>
                                        <dd className="message-target">
                                            {targetModel?.groupList?.map((item, index) => {
                                                return (
                                                    <div className="message-target-item" key={index}>
                                                        {item?.treeName?.join(" > ")}
                                                    </div>
                                                );
                                            })}
                                        </dd>
                                    </dl>
                                )}
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-tertiary btn-right-arrow btn-set-target"
                            onClick={() =>
                                saveTargetModel({
                                    ...targetModel,
                                    open: true,
                                    model: deviceId ? true : false,
                                    group: true,
                                    headLabel: deviceId ? "modelNgroup" : "group",
                                })
                            }
                        >
                            Set Target
                        </button>
                    </div>
                )}
            </Modal>
            {showFileSelect && (
                <SelectFile
                    onClose={() => {
                        setShowFileSelect(false);
                    }}
                    fileType={fileType}
                    onClick={onChangeFile}
                    isMultiple={isMultiple}
                    fileFilter={fileFilter}
                    selectedFiles={selectedFiles}
                    maxSelect={10}
                />
            )}
        </Fragment>
    );
};

export default RegisterBroadcastPopup;
