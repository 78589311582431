import React, { useState, useEffect, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import TargetModelnGroup from "@component/Device/ModelnGroup";
import _API from "@util/api";
import useCss from "@hook/useCss";
import { AppContext } from "@component/AppProvider";
import Dialog from "@component/UI/Dialog";
import utils from "@util/utilities";

let checkedData = {};

const InstallPopup = ({ onCancel, file, isUninstall = false }) => {
    useCss("/assets/css/popup_set_target.css");
    const { saveInnerLoading } = useContext(AppContext);

    const [targetModelProps, saveTargetModel] = useState({
        open: true,
        model: false,
        group: true,
    });

    const { t } = useTranslation();

    const [packageName, setPackageName] = useState("");
    const [targetModel, setTargetModel] = useState({});
    const [groupList, setGroupList] = useState([]);

    const onDone = async () => {
        const device_ids = [];
        let isChecked = false;
        groupList.forEach((v) => {
            if (v?.checked) isChecked = true;
        });
        if (!isChecked) {
            Dialog({
                title: t("popup.title.inform"),
                text: t("popup.inform.sigleItem", { n: t("table.head.instalcolgroup") }),
                button: t("common.label.confirm"),
            });
            return;
        }
        Object.keys(checkedData).forEach((key) => {
            const values = checkedData[key];
            values.forEach((val) => {
                device_ids.push(val);
            });
        });
        if (!device_ids.length) {
            onCancel();
            return;
        }
        const fileUrl = await utils.getFileDownloadUrl(file?.Key);
        onCancel();
        if (isUninstall) {
            const params = [
                {
                    appUninstall: {
                        data: {
                            value: packageName,
                        },
                    },
                },
            ];
            let res = await _API.onDeviceControl2([...device_ids], params, saveInnerLoading, t, null, true);
            // if (res?.status === "true") {
            //     Dialog({
            //         title: t("popup.title.inform"),
            //         text: t("common.message.RunUninstall"),
            //         button: t("common.ok"),
            //     }).then((id) => {});
            // } else {
            //     Dialog({
            //         title: t("popup.title.inform"),
            //         text: t("common.message.RunUninstall"),
            //         button: t("common.ok"),
            //     }).then((id) => {});
            // }
        } else {
            const params = [
                {
                    appInstall: {
                        data: {
                            value: fileUrl,
                        },
                    },
                },
            ];
            let res = await _API.onDeviceControl2([...device_ids], params, saveInnerLoading, t, null, true);
            // if (res?.status === "true") {
            //     Dialog({
            //         title: t("popup.title.inform"),
            //         text: t("common.message.RunInstall"),
            //         button: t("common.ok"),
            //     }).then((id) => {});
            // } else {
            //     Dialog({
            //         title: t("popup.title.inform"),
            //         text: t("common.message.RunInstall"),
            //         button: t("common.ok"),
            //     }).then((id) => {});
            // }
        }
    };

    const getGroupList = (checkList) => {
        _API.get({ path: "/business/groups", data: {} })
            .then((res) => {
                // console.log("api /business/groups", res);
                // setDataList(res?.data?.result);
                if (res?.data?.result && res?.data?.result?.length > 0) {
                    const newGroupList = res?.data?.result?.map((m) => convertGroupFormat(m, checkList));
                    setGroupList(newGroupList);
                } else {
                    setGroupList([]);
                }
            })
            .catch((e) => {
                console.log("api error /business/groups", e);
                setGroupList([]);
            });
    };

    const convertGroupFormat = (data, checkList) => {
        if (data && data?.group_id) {
            const m = {
                ...data,
                enabled: true,
                expanded: false,
                id: data.group_id,
                label: data.group_name,
                checked: checkList?.map((m) => m.group_id)?.indexOf(data.group_id) > -1,
                child: data?.lower_groups?.map((m) => convertGroupFormat(m, checkList) || []),
            };
            if (m.child?.filter((f) => f.checked)?.length > 0) {
                m.expanded = true;
            }
            return m;
        }
        return [];
    };

    const convertGroupFormat2 = (data, id, checked) => {
        if (data && data?.group_id) {
            return {
                ...data,
                checked: data.group_id === id ? checked : targetModel?.groupRadio ? false : data?.checked,
                child: data?.child?.map((m) => convertGroupFormat2(m, id, checked) || []),
            };
        }
        return [];
    };

    const selectGroupCheck = (data, checkList, treeName) => {
        treeName = treeName ? [...treeName, data?.group_name] : [data?.group_name];
        if (data?.checked) {
            checkList.push({ ...data, treeName: treeName });
        }
        data?.child?.forEach((f) => {
            selectGroupCheck(f, checkList, treeName);
        });
    };

    const onCheckGroup = (id, checked, items) => {
        const newGroupList = items?.map((m) => convertGroupFormat2(m, id, checked));
        setGroupList(newGroupList);
        const selectGroupList = [];
        newGroupList?.forEach((f) => {
            selectGroupCheck(f, selectGroupList);
        });
        if (!checked) {
            if (checkedData[id]) delete checkedData[id];
        }
        selectGroupList.forEach((x) => {
            const { group_id } = x;
            if (!checkedData[group_id]) {
                _API.get({ path: `/device/groups/${group_id}/devices`, data: {} }).then((res) => {
                    const { data } = res?.data?.result;
                    const deviceIds = data?.map((v) => v.device_id);
                    checkedData[group_id] = deviceIds;
                });
            }
        });
        setTargetModel({ ...targetModel, groupList: selectGroupList });
    };

    useEffect(() => {
        checkedData = {};
        if (file && isUninstall) {
            _API.get({ path: `/content/filebox/files/metadata`, data: { file_path: file?.Key } }).then((res) => {
                if (res?.data?.result) {
                    const { Metadata } = res?.data?.result;
                    Metadata ? setPackageName(Metadata) : setPackageName("");
                }
            });
        }
    }, []);

    useEffect(() => {
        if (targetModelProps?.model) {
            onLoadModels();
        }
        if (targetModelProps?.group) {
            getGroupList(targetModelProps?.groupList);
        }
        setTargetModel(targetModelProps);
    }, [targetModelProps]);

    useEffect(() => {
        // 모달 팝업 시 background scroll방지
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    return (
        <div id="popup-set-target" className="popup-wrapper show">
            <div className="dim">&nbsp;</div>
            <div className="popup popup-set-target overflow-y-auto" role="dialog" aria-labelledby="popup-label" aria-modal="true">
                <div className="popup-container max-height-unset">
                    <div className="popup-header">
                        <h1 id="popup-label">{isUninstall ? t("common.uninstall") : t("common.install")}</h1>
                    </div>

                    <div className="popup-content">
                        <div className="set-guide-txt">
                            {isUninstall ? t("common.uninstall.app") : t("common.install.app")}
                            {targetModel?.uniController && <span>{t("subtext.desc.unicontroller")}</span>}
                        </div>
                        <div className="target-select-wrap">
                            {targetModel?.group && (
                                <div className="row group-row">
                                    <h2 className="list-title">Group List</h2>
                                    <div className="site-group-list-container">
                                        <div className="child-menu-container">
                                            <Tree onClick={onCheckGroup} items={groupList} t={t} targetModel={targetModel} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <TargetModelnGroup targetInfo={{ ...targetModel, setTarget: false }} />
                    </div>
                </div>

                <div className="popup-footer">
                    <div className="button-box double-button">
                        <button type="button" id="btn-st-cancel" className="btn btn-huge btn-tertiary" onClick={onCancel}>
                            Cancel
                        </button>
                        <button type="button" id="btn-st-done" className="btn btn-huge btn-secondary " onClick={() => onDone()}>
                            {isUninstall ? t("common.uninstall") : t("common.install")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstallPopup;

const Tree = (props) => {
    const { items: itemsInProps, checkGroupList, current: currentInProps, onClick, t, targetModel } = props;

    const [items, setItems] = useState(itemsInProps || []);
    const [current, setCurrent] = useState(currentInProps || null);

    const handleItemClick = (id) => {
        const expand = (o) => {
            if (o.id === id) {
                if (o.child?.length > 0) {
                    o.expanded = !o.expanded;
                } else {
                    setCurrent(id);
                }
            }
            o.child?.map(expand);
        };
        const nl = [...items];
        nl.map(expand);
        setItems(nl);
    };

    const handleItemChange = (id, e) => {
        onClick && onClick(id, e.target.checked, items);
        // const expand = (o) => {
        //     if (o.id === id) {
        //         o.checked = !o.checked;
        //         onClick && onClick(id, !o.checked);
        //     }
        //     o.child?.map(expand);
        // };
        // const nl = [...items];
        // nl.map(expand);
        // setItems(nl);
    };

    useEffect(() => {
        setItems(itemsInProps);
    }, [itemsInProps]);

    useEffect(() => {
        setCurrent(currentInProps);
    }, [currentInProps]);

    return (
        <div className="devices-menu-site">
            <ul role="menu">
                {items.map((item, i) => (
                    <Branches key={i} {...item} checkGroupList={checkGroupList} onClick={handleItemClick} onChange={handleItemChange} current={current} t={t} targetModel={targetModel} />
                ))}
            </ul>
        </div>
    );
};

const Branches = ({ id, label, enabled, expanded, child, onClick, onChange, current, checked, t, checkGroupList, targetModel }) => {
    return (
        <li
            id={id}
            className={cx({
                "child-menu": true,
                "has-child": child?.length > 0,
                "open-menu": expanded,
                disabled: !enabled,
                active: false,
            })}
            role="none presentation"
        >
            <div className={cx({ "menu-box": true, active: current === id })}>
                <a href="#" role="menuitemradio" onClick={onClick?.bind(this, id)}>
                    {t("subtext.menu.open")}
                </a>
                <div className="checkbox">
                    <input type={targetModel?.groupRadio ? "radio" : "checkbox"} name="check-group" id={"check-group-" + id} value={""} onChange={onChange?.bind(this, id)} checked={checked} />
                    <label htmlFor={"check-group-" + id}>{label}</label>
                </div>
            </div>
            {expanded && child?.length > 0 && (
                <ul role="menu">
                    {child.map((item, i) => (
                        <Branches key={i} {...item} onClick={onClick} onChange={onChange} current={current} t={t} targetModel={targetModel} />
                    ))}
                </ul>
            )}
        </li>
    );
};
