import React, { Fragment, useEffect, useState, useContext } from "react";
import _API from "@util/api";
import _U from "@util/utilities";

import { SideMenu } from "@component/History";
import LastUpdate from "@component/UI/LastUpdate";
import { AppContext } from "@component/AppProvider";
import { useTranslation } from "react-i18next";
import Loading from "@component/UI/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import Period from "@component/History/Period";

export default () => {
    const {
        historyPeriod: { keyword, filter },
        workspace,
        periodStartDate,
        periodEndDate,
        auth,
    } = useContext(AppContext);
    const { t } = useTranslation();

    const [sideMenu, setSideMenu] = useState(false);
    const [loading, setLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [data, setData] = useState({
        total_count: 0,
        total_pages: 0,
        page: 0,
        items: [],
    });

    const onExcelDownload = () => {
        const path = "/issue/history/accesses";
        _API.get({
            path,
            data: {
                format: "excel",
                period_start: periodStartDate,
                period_end: periodEndDate,
            },
        }).then((res) => {
            const mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            const excel = res?.data;
            const downloadLink = document.createElement("a");
            const fileName = res?.headers["content-disposition"].split("filename=")[1];
            downloadLink.href = mediaType + excel;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
        });
    };

    const fetchList = (isInit = true) => {
        const path = "/issue/history/accesses";
        const perPage = 10;
        if (isInit) {
            setLoading(true);
            _API.get({
                path,
                data: {
                    page: 1,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword ? keyword : undefined,
                },
            })
                .then((res) => {
                    let total, resultData;
                    if (res && res.data) {
                        const {
                            result: {
                                paging: { total_count: summaryTotal },
                            },
                            result: resultObject,
                        } = res.data;

                        total = summaryTotal;
                        resultData = resultObject;
                    }

                    const total_pages = total ? Math.ceil(total / perPage) : 1;

                    setData((prevData) => ({
                        ...prevData,
                        total_count: total || 0,
                        total_pages,
                        page: 1,
                        items: resultData?.data || [],
                    }));
                })
                .finally(() => setLoading(false));
        } else {
            let { total_pages, page } = data;
            if (page >= total_pages) return;
            page += 1;
            setLoading(true);
            _API.get({
                path,
                data: {
                    page,
                    per_page: perPage,
                    period_start: periodStartDate,
                    period_end: periodEndDate,
                    filter,
                    search: keyword ? keyword : undefined,
                },
            })
                .then((res) => {
                    let total, resultData;
                    if (res && res.data) {
                        const {
                            result: {
                                paging: { total_count: summaryTotal },
                            },
                            result: resultObject,
                        } = res.data;
                        total = summaryTotal;
                        resultData = resultObject;
                    }
                    const total_pages = total ? Math.ceil(total / perPage) : 1;
                    setData((prevData) => ({
                        ...prevData,
                        total_count: total || 0,
                        total_pages,
                        page,
                        items: [...prevData.items, ...(resultData?.data || [])],
                    }));
                })
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        fetchList(true);
    }, [workspace]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    return (
        <Fragment>
            {loading && <Loading />}

            <div className="content-top">
                <div className="top-left">
                    <h1>History</h1>
                </div>

                <LastUpdate onRefresh={() => fetchList(true)} />
            </div>
            <div className="content-middle wide-padding">
                <SideMenu sideMenu={sideMenu} onClose={() => setSideMenu(false)} />

                <div className="content-middle-box">
                    <Period
                        title={t("admin.label.accesshistory")}
                        onSearch={() => {
                            fetchList(true);
                        }}
                        total={data?.total_count}
                        filterMenu={"ACCESS"}
                        onExcelDownload={onExcelDownload}
                        onOpenSideMenu={() => setSideMenu(true)}
                        searchPlaceholder={t("placeholder.SearchForIpAddress")}
                    />

                    <div className="inner-content">
                        <div className="white-area">
                            <div className="data-list-container">
                                <div className="table">
                                    <div className="table-wrapper horizontal-scroll select-mode">
                                        <div className="table-box">
                                            <InfiniteScroll
                                                style={{ overflow: "hidden" }}
                                                dataLength={data?.items?.length}
                                                next={() => fetchList(false)}
                                                hasMore={windowWidth > 991}
                                                loader={""}
                                                scrollableTarget="scrollableTbody"
                                            >
                                                <table>
                                                    <caption>{t("common.label.history.access.list")}</caption>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">
                                                                    {t("common.id")}({t("user.label.email")})
                                                                </div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("player.label.ip")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("table.head.os")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("admin.label.browser")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("am.user.access")}</div>
                                                            </th>
                                                            <th scope="col" width="10%">
                                                                <div className="text-block">{t("logreport.common.eventtype")}</div>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody id="scrollableTbody" className="height-50-rem">
                                                        {data?.items?.map(
                                                            (item, index) =>
                                                                item && (
                                                                    <tr key={index}>
                                                                        <td>{_U.hightlight(item?.email, keyword)}</td>
                                                                        <td>{_U.hightlight(item?.ip_address, keyword)}</td>
                                                                        <td>{_U.hightlight(item?.os, keyword)}</td>
                                                                        <td>{_U.hightlight(item?.browser, keyword)}</td>
                                                                        <td>{item?.access_time ? _U.getOffsetTime(auth, workspace, item?.access_time) : ""}</td>
                                                                        <td>{_U.hightlight(item?.event_type, keyword)}</td>
                                                                    </tr>
                                                                )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </InfiniteScroll>
                                        </div>
                                    </div>

                                    <div className="convert-table-to-list">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="thead">ID(E-mail)</div>
                                            </div>
                                        </div>
                                        <InfiniteScroll style={{ overflow: "hidden" }} dataLength={data?.items?.length} next={() => fetchList(false)} hasMore={windowWidth <= 991} loader={""}>
                                            <ul className="table-body">
                                                {data?.items?.map(
                                                    (item, index) =>
                                                        item && (
                                                            <li className="table-row" key={`reflow-${index}`}>
                                                                <div className="summary-data">
                                                                    <div className="row">
                                                                        <div className="table-cell status">
                                                                            <button type="button" role="listbox" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                                {_U.hightlight(item?.email, keyword)}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="all-data-box">
                                                                    <ul className="all-data">
                                                                        <li>
                                                                            <span className="field-label">{t("player.label.ip")}</span>
                                                                            <span className="field-content">{_U.hightlight(item?.ip_address, keyword)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("table.head.os")}</span>
                                                                            <span className="field-content">{_U.hightlight(item?.os, keyword)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("admin.label.browser")}</span>
                                                                            <span className="field-content">{_U.hightlight(item?.browser, keyword)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("am.user.access")}</span>
                                                                            <span className="field-content">{item?.access_time ? _U.getOffsetTime(auth, workspace, item.access_time) : ""}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="field-label">{t("logreport.common.eventtype")}</span>
                                                                            <span className="field-content">{_U.hightlight(item?.event_type, keyword)}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
