import { AppContext } from "@component/AppProvider";
import ScheduleWrapper from "@component/Schedule/Wrapper";
import _API from "@util/api";
import utils from "@util/utilities";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Loading from "@component/UI/Loading";
import InstallPopup from "@component/Schedule/App-Management/InstallPopup";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowWidth from "@hook/useWindowWidth";
import { TBody } from "@component/Schedule/TBody";

const ITEMS_PER_PAGE = 11;

export default () => {
    const { t } = useTranslation();
    const { auth, workspace, rolePermission } = useContext(AppContext);
    const windowWidth = useWindowWidth();

    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [appList, setAppList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [page, setPage] = useState(1);
    const [showInstall, setShowInstall] = useState(false);
    const [showUnInstall, setShowUnInstall] = useState(false);
    const [installFile, setInstallFile] = useState("");

    const getListPerPage = () => {
        const startIndex = 0;
        const endIndex = startIndex + ITEMS_PER_PAGE * page;
        return filterList.slice(startIndex, endIndex);
    };

    useEffect(() => {
        setFilterList(appList.filter((item) => utils.getFilename(item.Key).includes(searchKeyword)));
    }, [searchKeyword, appList]);

    const hasNext = () => {
        if (getListPerPage()?.length < filterList?.length) return true;
        else return false;
    };

    const toggleExecutionShow = (index) => {
        setAppList((prevState) => {
            const newList = [...prevState];
            newList[index] = {
                ...newList[index],
                isShow: !newList[index].isShow, // 현재 값의 반대로 변경
            };
            return newList;
        });
    };

    const clickToInstall = (index) => {
        const findOne = appList.find((_, i) => i === index);
        setInstallFile(findOne);
        setShowInstall(true);
    };

    const clickToUnInstall = (index) => {
        const findOne = appList.find((_, i) => i === index);
        setInstallFile(findOne);
        setShowUnInstall(true);
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const appPromise = _API
                .get({
                    path: "/content/filebox/files",
                    data: { property_id: workspace?.property_id, type: "apps" },
                })
                .then((res) => {
                    let { result } = res?.data;
                    let totalSize = 0;
                    if (result?.Contents?.length) {
                        const { Contents } = result;
                        Contents.forEach((content) => {
                            totalSize += content.Size || 0;
                            if (!content.hasOwnProperty("isShow")) {
                                content.isShow = false;
                            }
                        });
                        setAppList(Contents);
                    }
                });

            await Promise.all([appPromise]).finally(() => {
                setLoading(false);
                resizeTable();
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        document.title = t("common.schedule") + " | " + t("common.title.lgconnectedcare");
        fetchData();
    }, [workspace]);

    return (
        <Fragment>
            {loading && <Loading />}
            {showInstall && <InstallPopup file={installFile} onCancel={() => setShowInstall(false)} />}
            {showUnInstall && <InstallPopup file={installFile} onCancel={() => setShowUnInstall(false)} isUninstall={true} />}
            <ScheduleWrapper onRefresh={fetchData}>
                <div className="search-and-filter-box">
                    <div className="filter-box">
                        <div className="left-side">
                            <span className="info-text">{t("subtext.desc.appmanagement.fileboxuploading")}</span>
                        </div>

                        <div className="right-side">
                            <div className="selected-count">
                                {t("common.total")} : <span className="count">{appList?.length || 0}</span>
                            </div>

                            <div className="search-box2">
                                <div className="search-inner-box">
                                    <input
                                        type="text"
                                        id="search"
                                        className="search search_b"
                                        name="search"
                                        placeholder="Search"
                                        autoComplete="true"
                                        aria-label="Search"
                                        value={searchKeyword}
                                        onChange={(e) => setSearchKeyword(e.target.value)}
                                    />

                                    <button type="button" className="btn-search">
                                        <span className="ir">{t("button.search")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inner-content">
                    <div className="white-area">
                        <div className="data-list-container">
                            <div className="table">
                                <div className="table-wrapper horizontal-scroll select-mode">
                                    <div className="table-box">
                                        <InfiniteScroll
                                            style={{ overflow: "hidden" }}
                                            dataLength={getListPerPage()?.length}
                                            next={() => {
                                                setPage(page + 1);
                                            }}
                                            hasMore={hasNext() && windowWidth > 991}
                                            loader={""}
                                            scrollableTarget="app-management-tbody"
                                        >
                                            <table>
                                                <caption>{t("admin.label.appmanagement")}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="25%">
                                                            <div className="text-block">{t("common.appname")}</div>
                                                        </th>
                                                        <th scope="col" width="25%">
                                                            <div className="text-block">{t("table.head.filesize")}</div>
                                                        </th>
                                                        <th scope="col" width="30%">
                                                            <div className="text-block">{t("editor.label.date")}</div>
                                                        </th>
                                                        <th scope="col" width="10%">
                                                            <div className="text-block">{t("common.install")}</div>
                                                        </th>
                                                        <th scope="col" width="10%">
                                                            <div className="text-block">{t("common.uninstall")}</div>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <TBody id="app-management-tbody">
                                                    {getListPerPage()?.map((x, i) => {
                                                        if (x) {
                                                            const filename = utils.getFilename(x.Key);
                                                            return (
                                                                <tr key={i}>
                                                                    <td id={`td1-${i}`}>{filename}</td>
                                                                    <td id={`td2-${i}`}>{utils.byteSizeParse(x.Size)}</td>
                                                                    <td id={`td3-${i}`} className="width-30-percent">
                                                                        {utils.getOffsetTime(auth, workspace, x.LastModified)}
                                                                    </td>
                                                                    <td className="align-center width-10-percent">
                                                                        <button
                                                                            aria-describedby={`td1-${i} td2-${i} td3-${i}`}
                                                                            type="button"
                                                                            className="btn btn-download"
                                                                            onClick={() => clickToInstall(i)}
                                                                            disabled={utils.getAuthWriteDisable(rolePermission)}
                                                                        >
                                                                            {t("common.install")}
                                                                        </button>
                                                                    </td>
                                                                    <td className="align-center width-10-percent">
                                                                        <button
                                                                            aria-describedby={`td1-${i} td2-${i} td3-${i}`}
                                                                            type="button"
                                                                            className="btn btn-delete"
                                                                            onClick={() => clickToUnInstall(i)}
                                                                            disabled={utils.getAuthWriteDisable(rolePermission)}
                                                                        >
                                                                            {t("button.delete")}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    })}
                                                </TBody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>

                                <div className="convert-table-to-list">
                                    <div className="table-header">
                                        <div className="row">
                                            <div className="thead">{t("common.appname")}</div>
                                        </div>
                                    </div>
                                    <InfiniteScroll
                                        style={{ overflow: "hidden" }}
                                        dataLength={getListPerPage()?.length}
                                        next={() => {
                                            setPage(page + 1);
                                        }}
                                        hasMore={hasNext() && windowWidth <= 991}
                                        loader={""}
                                    >
                                        <ul className="table-body">
                                            {getListPerPage()?.map((x, i) => {
                                                if (x) {
                                                    const filename = utils.getFilename(x.Key);
                                                    return (
                                                        <li className="table-row" key={i}>
                                                            <div className="summary-data">
                                                                <div className="row">
                                                                    <div className="table-cell status">
                                                                        <button type="button" className="btn btn-expand" aria-expanded="false" onClick={(e) => reflowViewExpand(e)}>
                                                                            {filename}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="all-data-box">
                                                                <ul className="all-data">
                                                                    <li id={`cv1-${i}`}>
                                                                        <span className="field-label">{t("table.head.filesize")}</span>
                                                                        <span className="field-content">{utils.byteSizeParse(x.Size)}</span>
                                                                    </li>
                                                                    <li id={`cv2-${i}`}>
                                                                        <span className="field-label">{t("editor.label.date")}</span>
                                                                        <span className="field-content">{utils.getOffsetTime(auth, workspace, x.LastModified)}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("common.install")}</span>
                                                                        <span className="field-content">
                                                                            <button
                                                                                aria-describedby={`cv1-${i} cv2-${i}`}
                                                                                type="button"
                                                                                className="btn btn-download"
                                                                                onClick={() => clickToInstall(i)}
                                                                                disabled={utils.getAuthWriteDisable(rolePermission)}
                                                                            >
                                                                                {t("common.install")}
                                                                            </button>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="field-label">{t("common.uninstall")}</span>
                                                                        <span className="field-content">
                                                                            <button
                                                                                aria-describedby={`cv1-${i} cv2-${i}`}
                                                                                type="button"
                                                                                className="btn btn-delete"
                                                                                onClick={() => clickToUnInstall(i)}
                                                                                disabled={utils.getAuthWriteDisable(rolePermission)}
                                                                            >
                                                                                {t("button.delete")}
                                                                            </button>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScheduleWrapper>
        </Fragment>
    );
};
