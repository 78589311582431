import React from "react";
import LastUpdate from "@component/UI/LastUpdate";

const DashboardHeader = ({ onRefresh }) => {
    return (
        <div className="content-top">
            <div className="dashboard-bg">
                <span className="ir">background img</span>
            </div>
            <div className="top-left">
                <h1>Dashboard</h1>
            </div>

            <LastUpdate onRefresh={onRefresh} />
        </div>
    );
};

export default DashboardHeader;
